import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";

const Model = {
  name: "",
  status: "active",
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState(Model);
  const [record_id, setRecordId] = useState();

  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setState(Model);
        setViewMode(false);
        break;
      case "edit":
        setViewMode(false);
        loadDataById(props.id);
        break;
      case "view":
        setViewMode(true);
        loadDataById(props.id);
        break;
      default:
        setViewMode(true);
    }
  }

  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`designation/${id}`).then(
      async (result) => {
        const data = result.data;
        setState(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  function prepareData(data) {
    return data;
  }
  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });
    api
      .postService("designation", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });
    api
      .putService(`designation/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function changeInput(event) {
    const { name, value, checked } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // UI
  return (
    // <div onClick={(e) => e.stopPropagation()}>
    // <>
    <div
      key="designation_modal"
      id="designation_modal"
      // onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal {...props} scrollable={true} backdrop="static"
        keyboard={false} onHide={props.onHide} size="sm">
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            <div className="col-span-12">
              <label htmlFor="name" className="form-label">
                Designation
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter Designation"
                disabled={viewMode}
                onChange={changeInput}
                value={state.name}
                required
              />
            </div>

            <input type="submit" id="save_designation" className="hidden" />
            <input type="submit" id="update_designation" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_designation"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_designation"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
    // </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Designation",
  action: "view",
  onSave() {},
  callBack() {},
};
