import React, { useState, useEffect } from "react";
// HTML Components
import Error from "../../components/error/error";
import CustomDataTable from "../../components/tables/customDataTable";
import Form from "./form";
// Loaders
import Preloader from "../../components/preloader/preloader";
import ProcessLoader from "../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../components/notification/alert";
import { resp } from "../../functions/responseHandler";
// Functions
import * as api from "../../api_service/api";
import { htmlDateTime } from "../../functions/common";
import { storageServices } from "../../functions/storage";

export default function Master() {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));
  // HTML
  const [tab, setTab] = useState({
    currentTab: "available",
  });
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();
  const ModuleName = "Database";
  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    setIsLoaded(true);
    loadData(tab.currentTab);
  }, []);

  // Fetching Grid Data From API
  async function loadData(status) {
    setIsProcessing(true);
    let oldTab = { ...tab };
    oldTab.currentTab = status;
    setTab(oldTab);

    let queryStatus;
    if (status === "available") {
      queryStatus = false;
    }
    if (status === "in_use") {
      queryStatus = true;
    }

    api.getService(`db_list/active/${queryStatus}`).then(
      async (result) => {
        const data = result.data;
        console.log("result", result);
        let tableData = await mapTableData(data.data, status);
        setTableData(tableData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        setError(error);
      }
    );
  }
  // Mapping Grid Data From API
  function mapTableData(data, status) {
    const columns = [
      {
        name: "DB Id",
        selector: "db_id",
        sortable: true,
      },

      {
        name: " DB Name",
        selector: "name",
        cell: (row) => (
          <button
            className="btn btn-link"
            onClick={(e) => {
              viewRecord({ row });
            }}
          >
            {row.name}
          </button>
        ),
      },

      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
      },
      {
        name: "Created",
        selector: "createdAt",
        sortable: true,
        center: true,
        cell: (row) => <>{htmlDateTime(row.createdAt)}</>,
      },
      {
        name: "Updated",
        selector: "updatedAt",
        sortable: true,
        center: true,
        cell: (row) => <>{htmlDateTime(row.updatedAt)}</>,
      },
      {
        name: "",
        selector: "",
        cell: (row) => (
          <div className="dropdown">
            <button
              className="btn btn-default "
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="caret">
                <i className="fa fa-ellipsis-v text-muted" aria-hidden="true"></i>
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    editRecord({ row });
                  }}
                >
                  Edit{" "}
                  <i
                    className="fa fa-pencil text-primary float-right"
                    aria-hidden="true"
                  ></i>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteRecord({ row });
                  }}
                >
                  Delete{" "}
                  <i
                    className="fa fa-trash-o text-danger float-right"
                    aria-hidden="true"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        ),
        maxWidth: "5px",
      },
    ];
    let response = {
      header: columns,
      content: data,
      title: "",
    };
    return response;
  }
  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData(tab.currentTab);
  }
  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add ${ModuleName}`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(data) {
    let newState = {
      show: true,
      title: `View ${ModuleName}`,
      action: "view",
      id: data?.row?._id,
    };
    setModal(newState);
  }
  // Edit Record
  async function editRecord(data) {
    let newState = {
      show: true,
      title: `Edit ${ModuleName}`,
      action: "edit",
      id: data?.row?._id,
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(data) {
    alert.confirmDeletion({
      onConfirm: function () {
        onDelete(data);
      },
    });
  }
  ////// COMMON FUNCTIONS //////

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`db_list/${data.row._id}`)
      .then((response) => {
        console.log("response:", response);
        resp.Success(response?.data?.message);
        setIsProcessing(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <div className="content">
        {isProcessing && <ProcessLoader />}
        {modal.show && (
          <Form
            show={modal.show}
            action={modal.action}
            title={modal.title}
            onHide={closeModal}
            callBack={reloadData}
            id={modal.id}
          />
        )}

        <div className="row page-titles">
          <div className="col-md-5 align-self-center">
            <h4 className="text-primary">{ModuleName}</h4>{" "}
          </div>
          <div className="col-md-7 align-self-center">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={void 0}>Master</a>
              </li>
              <li className="breadcrumb-item active">{ModuleName}</li>
            </ol>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header border-bottom-0">
                  {/* Available - In Use Tab */}
                  <div className="float-left">
                    <ul className="nav nav-tabs customtab2" role="tablist">
                      <li className="nav-item">
                        {" "}
                        <a
                          className={
                            tab.currentTab === "available"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          name="available"
                          data-toggle="tab"
                          href={void 0}
                          onClick={(e) => loadData("available")}
                          role="tab"
                        >
                          <span className="hidden-sm-up">
                            <i className="ti-check"></i>
                          </span>{" "}
                          <span className="hidden-xs-down">Available</span>
                        </a>{" "}
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a
                          className={
                            tab.currentTab === "in_use"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          name="in_use"
                          data-toggle="tab"
                          href={void 0}
                          onClick={(e) => loadData("in_use")}
                          role="tab"
                        >
                          <span className="hidden-sm-up">
                            <i className="ti-close"></i>
                          </span>{" "}
                          <span className="hidden-xs-down">In Use</span>
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  {/* Available - In Use Tab */}
                  {/* Add New Button */}
                  <div className="float-right">
                    <button
                      className="btn btn-info d-inline"
                      onClick={addRecord}
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                      &nbsp;Add New
                    </button>
                  </div>
                  {/* Add New Button */}
                </div>
                <div className="card-body">
                  <div className="table-responsive ">
                    <CustomDataTable
                      header={tableData.header}
                      content={tableData.content}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
