import React, { useEffect, useState } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import ProcessLoader from "../../components/preloader/processLoader";
import { reverseGeocode } from "../../functions/common";

// const GOOGLEMAP_API_KEY = process.env.REACT_APP_GOOGLEMAP_KEY;
const GOOGLEMAP_API_KEY = "AIzaSyBfcKNyGB9Z0OxQSrAnWcL_nDzR_ec8xN8";

const Map = ({ props }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const refs = {};

  const [state, setState] = useState({
    latitude: "",
    longitude: "",
    lat: "",
    lng: "",
  });
  const [currentPosition, setCurrentPosition] = useState();

  useEffect(() => {
    loadInitData();
  }, []);

  async function loadInitData() {
    // Checking Props
    if (props?.latitude || props?.longitude) {
      setState({ lat: props.latitude, lng: props.longitude });
      setIsLoaded(true);
    } else {
      if (navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition(setGeoPosition);
      } else {
        console.log("Geolocation is not supported by this browser.");
        setIsLoaded(true);
      }
    }
  }

  async function setGeoPosition(position) {
    await setState({
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    });
    setIsLoaded(true);
  }

  function onMarkerMounted(ref) {
    refs["marker"] = ref;
  }

  function onPositionChanged() {
    const position = refs.marker.getPosition();
    var lat = position.lat();
    var lng = position.lng();
    setState({ lat: lat, lng: lng, latitude: lat, longitude: lng });
    handlePositionChange(lat, lng);
  }

  function handleMapClick(event) {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();
    setState({ lat: lat, lng: lng, latitude: lat, longitude: lng });
    handlePositionChange(lat, lng);
  }

  async function handlePositionChange(lat, lng) {
    // setState((prevState) => ({ ...prevState, address: address }));
    // prevState.address = latlng;
    let latlng = `${lat},${lng}`;
    setIsProcessing(true);
    await reverseGeocode(latlng)
      .then(async (data) => {
        setIsProcessing(false);
        props.callBack(data);
      })
      .catch((error) => {
        console.error("Error", error);
        setIsProcessing(false);
      });
  }

  if (!isLoaded) {
    return <ProcessLoader />;
  } else {
    return (
      <>
        {isProcessing && <ProcessLoader />}
        <GoogleMap
          defaultZoom={15}
          defaultCenter={state}
          onClick={handleMapClick}
          // defaultCenter={{ lat: -34.397, lng: 150.644 }}
        >
          <Marker
            position={state}
            draggable={true}
            ref={onMarkerMounted}
            onDragEnd={onPositionChanged}
          />
        </GoogleMap>
      </>
    );
  }
};

const MapComponent = withScriptjs(withGoogleMap(Map));

export function Component(props) {
  return (
    props?.show && (
      <MapComponent
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `75vh`, width: "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
        props={props}
      />
    )
  );
}

export default React.memo(Component);
