import BrokerForm from "./new-form-broker";
import TransporterForm from "./new-form";

export default function Component(props) {
  return props.biz_type === "transporter" ? (
    <TransporterForm
      show={props.show}
      action={props.action}
      onHide={props.onHide}
      callBack={props.callBack}
      id={props.id}
    />
  ) : (
    <BrokerForm
      show={props.show}
      action={props.action}
      onHide={props.onHide}
      callBack={props.callBack}
      id={props.id}
    />
  );
}
