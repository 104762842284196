import React, { useState, useEffect } from "react";
import ProcessLoader from "../../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../../functions/responseHandler";
import { matchData, toFloat } from "../../../functions/common";
import { Modal, Button } from "react-bootstrap";
import * as api from "../../../api_service/api";

import CurrencyInput from "../../htmlElements/currencyInput";

const Model = {
  name: "",
  opening_balance: 0,
  current_total: 0,
  current_balance: 0,
  old_current_balance: 0,
  status: "active",
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setState({ ...Model });
        setViewMode(false);
        break;
      case "edit":
        setViewMode(false);
        loadDataById(props.id);
        break;
      case "view":
        setViewMode(true);
        loadDataById(props.id);
        break;
      default:
        setViewMode(true);
    }
  }
  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`master/cash/${id}`).then(
      async (result) => {
        console.log("View Cash data:", result);
        const data = result.data;
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let prevState = await matchData(state, data);

    prevState.current_balance = toFloat(
      prevState?.opening_balance + prevState?.current_total
    );
    prevState.old_current_balance = prevState?.current_balance;
    await setState(prevState);
  }

  // Form Actions
  function onSubmit(event) {
    console.log("submit");
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };

    api
      .postService("master/cash", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .putService(`master/cash/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "opening_balance") {
      let currentBalance = toFloat(value) + toFloat(state?.current_total);
      setState((prevState) => ({
        ...prevState,
        current_balance: currentBalance,
      }));
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal {...props} scrollable={true} backdrop="static"
        keyboard={false} onHide={props.onHide}>
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            {isProcessing && <ProcessLoader />}
            <form
              onSubmit={
                userAction === "create"
                  ? onSubmit
                  : userAction === "edit"
                  ? onUpdate
                  : (e) => e.preventDefault()
              }
              id="cash-form"
            >
              <div className="form-group mb-3">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter master name"
                  disabled={viewMode}
                  onChange={changeInput}
                  value={state.name}
                  required
                />
              </div>

              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="form-group col-span-6">
                  <label htmlFor="opening_balance">Opening Balance</label>
                  <CurrencyInput
                    type="number"
                    className="form-control"
                    id="opening_balance"
                    name="opening_balance"
                    placeholder=""
                    disabled={viewMode}
                    onChange={changeInput}
                    value={state.opening_balance}
                    required
                  />
                </div>
                <div className="form-group col-span-6">
                  <label htmlFor="current_balance">Current Balance</label>

                  <CurrencyInput
                    type="number"
                    className="form-control"
                    id="current_balance"
                    name="current_balance"
                    placeholder=""
                    disabled={true}
                    value={state.current_balance}
                  />
                </div>
              </div>

              <input type="submit" id="save" className="hidden" />
              <input type="submit" id="update" className="hidden" />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary float-right mr-2 "
              htmlFor="save"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary float-right mr-2 "
              htmlFor="update"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Cash Master",
  action: "view",
  callBack() {},
};
