import React, { useState, useEffect } from "react";

// Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import StageTab from "../../../components/tabs/stageTab";
import AddNewButton from "../../../components/htmlElements/addNewButton";
// External Components
import TyreServiceEntryForm from "../../../components/repair/tyre";
// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import {
  formatCurrency,
  localDate,
  formatKm,
  navToPage,
} from "../../../functions/common";

// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";
export default function View(props) {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Modal Functions
  // <!-- BEGIN: Tyre Service Modal -->
  const [serviceEntryModal, setServiceEntryModal] = useState({
    show: false,
    id: "",
    action: "",
    tyre: {},
    vehicle: {},
    consignment: {},
  });
  function openTyreServiceEntry() {
    let prevState = { ...serviceEntryModal };
    prevState["tyre"] = "";
    prevState["action"] = "create";
    prevState["show"] = true;
    setServiceEntryModal(prevState);
  }

  function viewServiceEntry(id) {
    let newState = { ...serviceEntryModal };
    newState["id"] = id;
    newState["action"] = "edit";
    newState["show"] = true;
    setServiceEntryModal(newState);
  }
  const closeServiceEntryModal = () =>
    setServiceEntryModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function serviceEntryModalModalCallBack() {
    let newState = {
      show: false,
      id: "",
      action: "",
      tyre: {},
      vehicle: {},
      consignment: {},
    };
    setServiceEntryModal(newState);
    loadData(localUser);
  }
  // <!-- END: Tyre Service Modal -->

  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  /////////// FUNCTIONS DECLARATION ///////////

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      // Loading Data
      loadData(localUserData);
    };
    getUser();
  }, []);

  async function loadData(user = localUser) {
    setIsProcessing(true);
    api.getService(`tyre_service`).then(
      async (result) => {
        const data = result.data;
        console.log("api data", data);
        let tableData = await mapTableData(data.data, user);
        setTableData(tableData);
        setIsProcessing(false);
      },

      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data, user) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "date",
        vertAlign: "middle",
        headerSort: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Service",
        field: "service_type",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vendor",
        field: "supplier.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Amount",
        field: "amount",
        vertAlign: "middle",
        headerSort: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "odometer Reading",
        field: "odometer_reading",
        vertAlign: "middle",
        headerSort: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    };
    return response;
  }

  return (
    <>
      <>
        {/* <!-- BEGIN: Tyre Service Modal --> */}
        {serviceEntryModal.show && (
          <TyreServiceEntryForm
            show={serviceEntryModal.show}
            action={serviceEntryModal.action}
            tyre={serviceEntryModal.tyre}
            title=""
            onHide={closeServiceEntryModal}
            callBack={serviceEntryModalModalCallBack}
            id={serviceEntryModal.id}
            request_module="tyre-slip"
          />
        )}
        {/* <!-- END: Master Modal --> */}

        <Container>
          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title className="text-lg font-medium">
              Tyre Services
            </Container.Title>

            {/* <!-- BEGIN: Stage Tabs --> */}
            <div className="ml-auto flex">
              <StageTab>
                <StageTab.Tab
                  id="tyre-master-tab"
                  active={false}
                  onClick={(e) => navToPage("/admin#/tyre/master")}
                  width={"w-32"}
                >
                  Masters
                </StageTab.Tab>{" "}
                <StageTab.Tab
                  id="tyre-service-tab"
                  active={true}
                  onClick={(e) => navToPage("/admin#/tyre/services")}
                  width={"w-32"}
                >
                  Services
                </StageTab.Tab>
              </StageTab>
            </div>
            {/* <!-- END: Stage Tabs --> */}

            <Container.HeaderActions>
              <AddNewButton
                id="tyre-service-button"
                onClick={openTyreServiceEntry}
              >
                Service Entry
              </AddNewButton>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing && <ProcessLoader />}
            <CustomDataTable
              header={tableData.header}
              content={tableData.content}
              onRowClick={(e, row) => {
                viewServiceEntry(row._row.data._id);
              }}
              filter={true}
              filterFields={tableData.filterFields}
            />
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
