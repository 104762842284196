import PropTypes from "prop-types";

/* <!-- BEGIN: SimpleTab --> */
const SimpleTab = (props) => {
  const { className } = props;
  return (
    <div className={className} role="tablist">
      {props.children}
    </div>
  );
};
SimpleTab.propTypes = {
  className: PropTypes.string,
};
SimpleTab.defaultProps = {
  className:
    "nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start",
};

/* <!-- BEGIN: SimpleTab --> */

/* <!-- BEGIN: Tab --> */
const Tab = (props) => {
  const { active, className, id, onClick, width } = props;
  let modClass = className + width;
  return (
    <a
      data-toggle="tab"
      role="tab"
      href={void 0}
      onClick={onClick}
      className={active ? modClass + " active" : modClass}
      id={id}
      aria-selected={active}
    >
      {props.children}
    </a>
  );
};
Tab.propTypes = {
  id: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
};
Tab.defaultProps = {
  id: "simple-tab",
  active: false,
  width: "w-24",
  className: "cursor-pointer py-4 sm:mr-8 flex items-center",
  onClick() {},
};

/* <!-- BEGIN: Element --> */

SimpleTab.Tab = Tab;

export default SimpleTab;
