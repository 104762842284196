import React, { useState, useEffect } from "react";
// import Preloader from "../../components/preloader/preloader";
import ProcessLoader from "../../components/preloader/processLoader";
import Error from "../../components/error/error";
// HTML ELEMENTS
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import Widget from "../../components/dashboard/widget";
import WidgetOptions from "../../components/dashboard/widgetOptions";
// Widgets Contents
import WCClientReceipts from "../../components/dashboard/accounts_widgets_content/client_receipts";
import WCSupplierPayments from "../../components/dashboard/accounts_widgets_content/supplier_payments";
import WCOtherExpenses from "../../components/dashboard/accounts_widgets_content/other_expenses";
import WCOtherIncomes from "../../components/dashboard/accounts_widgets_content/other_incomes";
import WCIncomeExpense from "../../components/dashboard/accounts_widgets_content/income_expense";
import WCTotalIncome from "../../components/dashboard/accounts_widgets_content/total_income";
import WCTotalExpense from "../../components/dashboard/accounts_widgets_content/total_expense";
import WCCashBankBalance from "../../components/dashboard/accounts_widgets_content/cash_bank_balance";
import WCRecentTransactions from "../../components/dashboard/accounts_widgets_content/recent_transactions";
import WCPayables from "../../components/dashboard/accounts_widgets_content/payables";
import WCReceivables from "../../components/dashboard/accounts_widgets_content/receivables";

// Functions
import { storageServices } from "../../functions/storage";
import { resp } from "../../functions/responseHandler";
import { matchData, isDefined } from "../../functions/common";
import * as api from "../../api_service/api";

// Loadash / Underscore
const _ = require("lodash");

// Responsive Grid System
const initialLayouts = {
  lg: [
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "client_receipts",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 4,
      i: "supplier_payments",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 6,
      i: "other_expenses",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 2,
      i: "other_incomes",
    },
    {
      w: 4,
      h: 5,
      x: 2,
      y: 3,
      i: "income_expense",
    },
    {
      w: 2,
      h: 3,
      x: 2,
      y: 0,
      i: "total_income",
    },
    {
      w: 2,
      h: 3,
      x: 4,
      y: 0,
      i: "total_expense",
    },
    {
      w: 3,
      h: 8,
      x: 6,
      y: 2,
      i: "cash_bank_balance",
    },
    {
      w: 3,
      h: 8,
      x: 9,
      y: 2,
      i: "recent_transactions",
    },
    {
      w: 3,
      h: 2,
      x: 6,
      y: 0,
      i: "payables",
    },
    {
      w: 3,
      h: 2,
      x: 9,
      y: 0,
      i: "receivables",
    },
  ],
  xxs: [
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "client_receipts",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 2,
      i: "supplier_payments",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 4,
      i: "other_expenses",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 6,
      i: "other_incomes",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 8,
      i: "income_expense",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 12,
      i: "total_income",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 16,
      i: "total_expense",
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 20,
      i: "cash_bank_balance",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 25,
      i: "recent_transactions",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 29,
      i: "payables",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 31,
      i: "receivables",
    },
  ],
  xs: [
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "client_receipts",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 2,
      i: "supplier_payments",
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 2,
      i: "other_expenses",
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 0,
      i: "other_incomes",
    },
    {
      w: 4,
      h: 4,
      x: 0,
      y: 4,
      i: "income_expense",
    },
    {
      w: 2,
      h: 3,
      x: 0,
      y: 8,
      i: "total_income",
    },
    {
      w: 2,
      h: 3,
      x: 2,
      y: 8,
      i: "total_expense",
    },
    {
      w: 2,
      h: 8,
      x: 0,
      y: 13,
      i: "cash_bank_balance",
    },
    {
      w: 2,
      h: 8,
      x: 2,
      y: 13,
      i: "recent_transactions",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 11,
      i: "payables",
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 11,
      i: "receivables",
    },
  ],
  sm: [
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "client_receipts",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 4,
      i: "supplier_payments",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 6,
      i: "other_expenses",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 2,
      i: "other_incomes",
    },
    {
      w: 4,
      h: 5,
      x: 2,
      y: 3,
      i: "income_expense",
    },
    {
      w: 2,
      h: 3,
      x: 2,
      y: 0,
      i: "total_income",
    },
    {
      w: 2,
      h: 3,
      x: 4,
      y: 0,
      i: "total_expense",
    },
    {
      w: 3,
      h: 8,
      x: 3,
      y: 8,
      i: "cash_bank_balance",
    },
    {
      w: 3,
      h: 8,
      x: 3,
      y: 16,
      i: "recent_transactions",
    },
    {
      w: 3,
      h: 2,
      x: 3,
      y: 24,
      i: "payables",
    },
    {
      w: 3,
      h: 2,
      x: 3,
      y: 26,
      i: "receivables",
    },
  ],
};
const originalWidgetsList = [
  {
    id: "client_receipts",
    widget_id: "client_receipts",
    widget_name: "Client Receipts ",
  },
  {
    id: "supplier_payments",
    widget_id: "supplier_payments",
    widget_name: "Supplier Payments ",
  },
  {
    id: "other_expenses",
    widget_id: "other_expenses",
    widget_name: "Other Expenses ",
  },
  {
    id: "other_incomes",
    widget_id: "other_incomes",
    widget_name: "Other Incomes ",
  },
  {
    id: "income_expense",
    widget_id: "income_expense",
    widget_name: "Income Expense ",
  },
  {
    id: "total_income",
    widget_id: "total_income",
    widget_name: "Total Income ",
  },
  {
    id: "total_income",
    widget_id: "total_expense",
    widget_name: "Total Expense ",
  },
  {
    id: "cash_bank_balance",
    widget_id: "cash_bank_balance",
    widget_name: "Cash Bank Balance ",
  },
  {
    id: "recent_transactions",
    widget_id: "recent_transactions",
    widget_name: "Recent Transactions ",
  },
  {
    id: "payables",
    widget_id: "payables",
    widget_name: "Payables",
  },
  {
    id: "receivables",
    widget_id: "receivables",
    widget_name: "Receivables",
  },
];

const widgetComponents = {
  client_receipts: WCClientReceipts,
  supplier_payments: WCSupplierPayments,
  other_expenses: WCOtherExpenses,
  other_incomes: WCOtherIncomes,
  income_expense: WCIncomeExpense,
  total_income: WCTotalIncome,
  total_expense: WCTotalExpense,
  cash_bank_balance: WCCashBankBalance,
  recent_transactions: WCRecentTransactions,
  payables: WCPayables,
  receivables: WCReceivables,
};

function Component({ size: { width } }) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [localUser, setLocalUser] = useState();

  const [filterState, setFilterState] = useState({
    highlights_filter: "last_3_months",
    hf_custom_date: {
      type: "",
      start_date: "",
      end_date: "",
    },
  });
  const [state, setState] = useState({
    payables: 0,
    receivables: 0,
    client_receipts: 0,
    supplier_payments: 0,
    other_expenses: 0,
    other_incomes: 0,
    total_income: {
      client_receipts: 0,
      incomes_list: [],
    },
    total_expense: {
      supplier_payments: 0,
      expenses_list: [],
    },

    income_expense: {
      xAxis: [],
      income: [],
      expense: [],
      total_income: 0,
      total_expense: 0,
    },
    cash_bank_balance: {
      cash: [],
      bank: [],
    },
    recent_transactions: {
      operational: [],
      business: [],
    },
  });
  const [enableEdit, setEnableEdit] = useState(false);
  const [layouts, setLayouts] = useState();
  const [widgetsList, SetWidgetsList] = useState(originalWidgetsList);

  useEffect(() => {
    // loadLayout();
    // loadDashboardData();
    // setIsLoaded(true);

    const loadData = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      await loadDashboardData(localUserData, localUserData?.organization_id);
    };
    loadData();
  }, []);

  async function loadDashboardData(localUserData = localUser) {
    // Loading Data with default filter
    // This month
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let filter = { type: "date", start_date: firstDay, end_date: date };

    setFilterState((prevState) => ({
      ...prevState,
      highlights_filter: "this_month",
    }));

    // Last 3 months
    // var date = new Date();
    // var startDate = new Date(date.getFullYear(), date.getMonth() - 3, 1);
    // var endDate = new Date(date.getFullYear(), date.getMonth(), 0);
    // let filter = { type: "month", start_date: startDate, end_date: endDate };
    await loadHighlights(filter, false, localUserData);

    // await loadInsights();
  }

  // Dynamic Data
  async function loadHighlights(
    filter,
    reload = true,
    localUserData = localUser
  ) {
    setIsProcessing(true);
    await api
      .postService(`accounts/dashboard/highlights`, filter)
      .then(async (response) => {
        const data = response.data;

        console.log("highlights data:", data);
        let mappedData = await mapHighlightsData(data, filter);

        // Loading Next API and Setting State there
        if (!reload) {
          await loadStats(mappedData, localUserData);
        } else {
          setState(mappedData);
          setIsProcessing(false);
        }

        // await loadIncomeExpenseData("month");
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsLoaded(true);
        setIsProcessing(false);
        setError(true);
      });
  }

  async function mapHighlightsData(data, filter) {
    let prevState = { ...state };

    // TOTAL INCOME/EXPENSE
    // prevState.total_income = prevState.receipts + prevState.income;
    // prevState.total_expense = prevState.payments + prevState.expense;

    // Incomes / Expenses List
    let incomes_list = [];
    let expenses_list = [];
    // INCOME  EXPENSE CHART
    let income_expense_list = data?.income_expense_list;
    for (var i in income_expense_list) {
      if (income_expense_list[i]["amount_type"] === "expense") {
        expenses_list.push({
          value: income_expense_list[i]["amount"],
          name: income_expense_list[i]["_id"]["expense"],
        });
      }
      if (income_expense_list[i]["amount_type"] === "income") {
        incomes_list.push({
          value: income_expense_list[i]["amount"],
          name: income_expense_list[i]["_id"]["income"],
        });
      }
    }

    // INCOME VS EXPENSE CHART

    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // var chartData = [{ Jan: { income: 0, expense: 0 } }];
    var ieChartData = {};
    var income_expense = data.day_income_expense;
    var payments_receipts = data.day_payments_receipts;

    // MONTH FILTER
    if (filter.type == "month") {
      let startMonth = new Date(filter.start_date).getMonth();
      let endMonth = new Date(filter.end_date).getMonth();

      let monthDifference = Math.abs(startMonth - endMonth);

      let dataMonthList = getMonthsList(
        monthList,
        12,
        startMonth,
        monthDifference + 1
      );

      for (var i in dataMonthList) {
        ieChartData[dataMonthList[i]] = { income: 0, expense: 0, profit: 0 };
      }

      // for (var i = startMonth; i <= endMonth; i++) {
      //   ieChartData[monthList[i]] = { income: 0, expense: 0, profit: 0 };
      // }
      console.log("ieChartData:", ieChartData);
      // Calculating other income/ expense
      for (var i in income_expense) {
        let month = income_expense[i].month;
        let monthName = monthList[month - 1];
        ieChartData[monthName]["income"] += income_expense[i].income;
        ieChartData[monthName]["expense"] += income_expense[i].expense;
      }
      // Calculating other Payments/ Receipts
      for (var i in payments_receipts) {
        let month = payments_receipts[i].month;
        console.log("month:", month);
        let monthName = monthList[month - 1];
        ieChartData[monthName]["income"] += payments_receipts[i].receipts;
        ieChartData[monthName]["expense"] += payments_receipts[i].payments;
      }
    }

    // MONTH FILTER

    // Date FILTER
    if (filter.type == "date") {
      // Calculating other income/ expense
      for (var i in income_expense) {
        let month = income_expense[i].month;
        let monthName = monthList[month - 1];
        let key = income_expense[i].date + " " + monthName;
        if (typeof ieChartData[key] == "undefined") {
          ieChartData[key] = { income: 0, expense: 0, profit: 0, date: 0 };
        }
        // Initializing Date Object
        ieChartData[key]["date"] = income_expense[i]?.date;
        ieChartData[key]["income"] += income_expense[i].income;
        ieChartData[key]["expense"] += income_expense[i].expense;
      }
      // Calculating other Payments/ Receipts

      for (var i in payments_receipts) {
        let month = payments_receipts[i].month;
        let monthName = monthList[month - 1];
        let key = payments_receipts[i].date + " " + monthName;
        if (typeof ieChartData[key] == "undefined") {
          ieChartData[key] = { income: 0, expense: 0, profit: 0 };
        }
        // Initializing Date Object
        ieChartData[key]["date"] = payments_receipts[i]?.date;
        ieChartData[key]["income"] += payments_receipts[i].receipts;
        ieChartData[key]["expense"] += payments_receipts[i].payments;
      }
      // ieChartData = await _.sortBy(ieChartData, "date");
      // console.log("SSD:",ieChartData);
    }

    // Income Expense - Line Chart
    // Date FILTER

    var income = [];
    var expense = [];
    var profit = [];

    var total_income = 0;
    var total_expense = 0;

    // Constructing Chart Data
    // let xAxis = await Object.keys(ieChartData);
    // let xAxis = [];
    // for (const [key, value] of Object.entries(ieChartData)) {
    //   xAxis.push(key);
    // }

    let xAxis = _.keys(ieChartData);

    for (var key in ieChartData) {
      total_income += ieChartData[key]["income"];
      total_expense += ieChartData[key]["expense"];
      income.push(ieChartData[key]["income"]);
      expense.push(ieChartData[key]["expense"]);
      profit.push(ieChartData[key]["profit"]);
    }

    var income_expense = {
      xAxis: xAxis,
      income: income,
      expense: expense,
      total_income: total_income,
      total_expense: total_expense,
    };

    prevState.client_receipts = data?.client_receipts;
    prevState.supplier_payments = data?.supplier_payments;
    prevState.other_expenses = data?.other_expenses;
    prevState.other_incomes = data?.other_incomes;
    prevState.total_income = {
      client_receipts: data?.client_receipts,
      incomes_list: incomes_list,
    };
    prevState.total_expense = {
      supplier_payments: data?.supplier_payments,
      expenses_list: expenses_list,
    };
    prevState.income_expense = income_expense;

    return prevState;
  }

  function getMonthsList(array, arrayLength, startIndex, endIndex) {
    // Create an auxiliary array
    // of twice size.
    var temp = Array(2 * arrayLength);

    // Copy a to b two times
    for (var i = 0; i < arrayLength; i++)
      temp[i] = temp[arrayLength + i] = array[i];

    // print from ind-th index to
    // (n+i)th index.
    let result = [];
    for (var i = startIndex; i < endIndex + startIndex; i++)
      result.push(temp[i]);
    return result;
  }

  // Static Data - right side cards
  async function loadStats(prevState = state, localUserData) {
    await api
      .getService(`accounts/dashboard/stats`)
      .then(async (response) => {
        const data = response.data;
        console.log("stats data:", data);
        let mappedData = await mapStatsData(data, prevState);
        setState(mappedData);
        // setIsLoaded(true);
        // setIsProcessing(false);
        await loadLayout(localUserData);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsLoaded(true);
        setIsProcessing(false);
        setError(true);
      });
  }
  async function mapStatsData(data, prevState) {
    let matchedData = await matchData(prevState, data);
    matchedData["cash_bank_balance"] = {
      cash: data?.cash || [],
      bank: data?.bank || [],
    };

    return matchedData;
  }

  // ON CHANGE FUNCTIONS

  // Highlight Filter Changes
  function changeHighlightFilter(event) {
    const { name, value } = event.target;
    setFilterState((prevState) => ({ ...prevState, [name]: value }));

    let prevState = filterState;
    prevState.highlights_filter = value;
    var date = new Date();

    let startDate = date;
    let endDate = date;
    let filter = { start_date: startDate, end_date: endDate };

    switch (value) {
      case "this_month":
        startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        filter = { type: "date", start_date: startDate, end_date: endDate };
        loadHighlights(filter, true);
        break;

      case "last_month":
        startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        filter = { type: "date", start_date: startDate, end_date: endDate };
        loadHighlights(filter, true);
        break;

      case "last_3_months":
        startDate = new Date(date.getFullYear(), date.getMonth() - 3, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        filter = { type: "month", start_date: startDate, end_date: endDate };
        loadHighlights(filter, true);
        break;

      case "last_6_months":
        startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        filter = { type: "month", start_date: startDate, end_date: endDate };

        loadHighlights(filter, true);
        break;

      // case "last_year":
      //   startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
      //   endDate = new Date(date.getFullYear(), date.getMonth(), 0);
      //   filter = { start_date: startDate, end_date: endDate };
      //   loadHighlights(filter);

      case "custom_date":
        break;

      default:
        break;
    }
  }

  function changeFilterCustom(event) {
    const { name, value } = event.target;
    let prevState = { ...filterState };
    prevState.hf_custom_date[name] = value;
    setFilterState(prevState);
  }

  function loadHighlightsCustomDate(event) {
    event.preventDefault();
    let filter = {
      type: "date",
      start_date: filterState?.hf_custom_date?.start_date,
      end_date: filterState?.hf_custom_date?.end_date,
    };
    loadHighlights(filter, true);
  }

  async function loadLayout(localUserData) {
    // let savedLayout = await storageServices.getStoredData(
    //   "accounts_dash_layout"
    // );
    // console.log("savedLayout:", savedLayout);
    // if (savedLayout) {
    //   setLayouts(savedLayout);
    // } else {
    //   setLayouts(initialLayouts);
    // }
    // return;

    setIsProcessing(true);
    await api
      .getService(
        `user_preferences/${localUserData?.userId}/accounts_dashboard_layout`
      )
      .then(async (response) => {
        const data = response.data;

        if (data.data && typeof data?.data["value"] !== "undefined") {
          var prefData = data.data.value;
          // Setting Layouts
          if (isDefined(prefData["layouts"])) {
            setLayouts(prefData["layouts"]);
          } else {
            setLayouts(initialLayouts);
          }
          // Setting Widgets
          if (isDefined(prefData["widgets"])) {
            SetWidgetsList(prefData["widgets"]);
          } else {
            SetWidgetsList(originalWidgetsList);
          }
        } else {
          setLayouts(initialLayouts);
          SetWidgetsList(originalWidgetsList);
        }

        setIsLoaded(true);
        setIsProcessing(false);
        return;
      })
      .catch((error) => {
        setLayouts(initialLayouts);
        SetWidgetsList(originalWidgetsList);
        console.log(error);

        resp.Error("Error Loading Layout Preferences.");
        setIsLoaded(true);
        setIsProcessing(false);
        return;
      });
  }

  // Responsive Grid System

  function onEnableLayoutEdit() {
    setEnableEdit(true);
  }

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };
  const onLayoutSave = async () => {
    // console.log("layouts:", layouts);
    // storageServices.storeData("accounts_dash_layout", layouts);
    // setEnableEdit(false);

    let query = {
      client: "web",
      type: "accounts_dashboard_layout",
      value: { layouts: layouts, widgets: widgetsList },
    };
    setIsProcessing(true);
    await api
      .putService(
        `user_preferences/${localUser?.userId}/accounts_dashboard_layout`,
        query
      )
      .then(async (response) => {
        resp.Success("Layout Saved");
        setIsProcessing(false);
        setEnableEdit(false);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      });
  };

  const onRemoveWidget = (widgetId) => {
    setEnableEdit(true);
    SetWidgetsList(widgetsList.filter((i) => i?.widget_id !== widgetId));
  };
  const onAddWidget = async (itemId) => {
    setEnableEdit(true);
    let widObj = await originalWidgetsList.find((x) => x.widget_id === itemId);
    SetWidgetsList([...widgetsList, widObj]);
  };

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    // return <Preloader />;
    return <div className="font-medium">Loading Data...</div>;
  } else {
    return (
      <>
        {isProcessing && <ProcessLoader />}
        {/* <!-- BEGIN: CONTENT -- > */}

        {/* <!-- BEGIN: TopBar --> */}
        <div className="flex items-center justify-end -mt-9 mr-3">
          {enableEdit && (
            <div id="save_edit_widgets_option" className="mr-2">
              <button
                className="btn btn-success-soft px-2 py-1"
                type="button"
                onClick={onLayoutSave}
              >
                <i className="fa fa-check mr-1" aria-hidden="true"></i>Save
              </button>
            </div>
          )}

          {/* <!--BEGIN: Filter--> */}
          <div className="mr-2">
            <select
              className="form-select inline box pr-10 py-1"
              name="highlights_filter"
              value={filterState.highlights_filter}
              onChange={changeHighlightFilter}
            >
              <option value="this_month" defaultValue>
                This month
              </option>
              <option value="last_month">Last month</option>
              <option value="last_3_months">Last 3 months</option>
              <option value="last_6_months">Last 6 months</option>
              {/* <option value="last_year">Last year</option> */}
              <option value="custom_date">Custom date</option>
            </select>

            {/* Custom Date */}
            {filterState?.highlights_filter == "custom_date" && (
              <div id="filter" className="inline ml-2">
                <form id="filter-form" onSubmit={loadHighlightsCustomDate}>
                  <div className="input-group input-group-sm">
                    <input
                      type="date"
                      className="form-control"
                      id="start_date"
                      name="start_date"
                      onChange={changeFilterCustom}
                      value={filterState?.hf_custom_date?.start_date}
                      required
                    />
                    <input
                      type="date"
                      className="form-control"
                      id="end_date"
                      name="end_date"
                      onChange={changeFilterCustom}
                      value={filterState?.hf_custom_date?.end_date}
                      required
                    />
                    <div className="input-group-prepend">
                      <button
                        className="input-group-text btn-secondary"
                        type="submit"
                      >
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {/* Custom Date */}
          </div>
          {/* <!-- END: Filter -- > */}

          <WidgetOptions
            className="relative z-20"
            onSave={onLayoutSave}
            enableEdit={enableEdit}
            onEnableEdit={onEnableLayoutEdit}
            onAddItem={onAddWidget}
            onRemoveItem={onRemoveWidget}
            originalItems={originalWidgetsList}
            currentItems={widgetsList}
          />
        </div>
        {/* <!-- END: TopBar --> */}

        <ResponsiveGridLayout
          className="layout"
          layouts={layouts}
          onLayoutChange={onLayoutChange}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={60}
          width={width}
          isDraggable={enableEdit}
          isResizable={enableEdit}
          isDroppable={false}
          autoSize={true}
        >
          {widgetsList.map((item, index) => {
            let WidgetContent = widgetComponents[item?.widget_id];

            return (
              <div
                key={item?.widget_id}
                id={item?.widget_id}
                className="box shadow widget overflow-auto"
                data-grid={{ w: 3, h: 2, x: 0, y: 999999 }}
              >
                <Widget
                  key={item?.widget_id}
                  id={item?.widget_id}
                  enableEdit={enableEdit}
                  onRemove={onRemoveWidget}
                >
                  <WidgetContent
                    id={item?.widget_id}
                    data={state[item?.widget_id]}
                    enableEdit={enableEdit}
                  />
                </Widget>
              </div>
            );
          })}
        </ResponsiveGridLayout>
        {/* <!-- END: CONTENT -- > */}
      </>
    );
  }
}
// export default Component()
export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
  Component
);
