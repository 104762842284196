const Model = {
    name: "",
    email: "",
    address: "",
    
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",

    status: "active",
    phone: "",
    website: "",
    contact_person: "",
    connection_url: "",
    logo: "",
    gst: "",
    pan_number: "",
    activated: true,
    approved: true,
    use_parent:false,
  };
  const ValidationErrorModel = {
    name: "",
    email: "",
    address: "",
    phone: "",
    website: "",
    contact_person: "",
    connection_url: "",
    logo: "",
    gst: "",
    pan_number: "",
    activated: "",
    approved: "",
  };
  
  export { Model };
  export { ValidationErrorModel };
  