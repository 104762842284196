import React, { useState, useEffect } from "react";
import {
  formatCurrency,
  formatKm,
  formatLitres,
  formatMileage,
  localDateTime,
  toFloat,
  localDate,
  trimConsNum,
  navToPage,
} from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import { storageServices } from "../../functions/storage";

export default function Component(props) {
  const Title = "Vehicle Fuel History Report";
  const tableRef = React.createRef();
  const { data } = props;
  const [vehicleData, setVehicleData] = useState({});
  const [state, setState] = useState({
    total_amount: 0,
    total_paid: 0,
    total_balance: 0,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData(props.data);
  }, [props.data]);

  async function loadData(data) {
    setIsProcessing(true);
    setVehicleData(data?.vehicle);
    let tableData = await mapTableData(data.data);
    setTableData(tableData);

    setIsProcessing(false);
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Vehicle No.",
        field: "vehicle.registration_number",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Cons. Date",
        field: "consignment_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Cons. No.",
        field: "consignment_id",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return trimConsNum(cell?.getValue());
        },
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Origin",
        field: "origin.name",
        headerSort: true,
        vertAlign: "middle",

        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Destination",
        field: "destination.name",
        headerSort: true,
        vertAlign: "middle",

        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      // {
      //   title: "Start Date",
      //   field: "start_time",
      //   headerSort: true,
      //   vertAlign: "middle",
      //   formatter: function (cell) {
      //     return localDateTime(cell?.getValue());
      //   },
      // },
      // {
      //   title: "End Time",
      //   field: "end_time",
      //   headerSort: true,
      //   vertAlign: "middle",
      //   formatter: function (cell) {
      //     return localDateTime(cell?.getValue());
      //   },
      // },
      {
        title: "Trip Distance",
        field: "trip_distance",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
      },
      {
        title: "Fuel Filled",
        field: "fuel_filled",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatLitres(cell?.getValue());
        },
      },
      {
        title: "Fuel Cost",
        field: "fuel_cost",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Fuel Consumption",
        field: "fuel_used",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatLitres(cell?.getValue());
        },
      },
      {
        title: "Mileage",
        field: "mileage",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatMileage(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(id) {
    navToPage(`/admin#/consignment/view/${id}`);
  }

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Data Table --> */}
        <ReportsDataTable
          title={Title}
          header={tableData.header}
          content={tableData.content}
          // onRowClick={(e, row) => {
          //   viewRecord(row._row.data._id);
          // }}
          downloadFileName={Title}
          tableRef={tableRef}
        />
        {/* <!-- END: Data Table --> */}
      </div>
    </>
  );
}
