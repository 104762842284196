import React from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";

// export default function Component(props) {
const Component = React.memo((props) => {
  const { className, id, name, onClick, data, enableEdit, ...rest } = props;
  console.log("Consignemnt status");

  var isActive = false;
  if (!enableEdit) isActive = true;
  // Chart Data
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: 40,
    responsive: true,
    maintainAspectRatio: false,
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Consignments";
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  let chartData = mapData(data);

  function mapData(data) {
    let chart_data = {
      labels: ["Prepared", "In Transit", "Completed"],
      datasets: [
        {
          data: [
            data?.prepared || 0,
            data?.in_transit || 0,
            data?.acknowledged || 0,
          ],
          backgroundColor: [
            "#FFC533", // Yellow
            "#FF8B26", // Orange
            // "#CBD5E0", // Gray
            "#23608f", // Blue
            "#abd152", // Green
            "#FF8B26", // Orange
            // "#c4e6a8", //Light Green
          ],
          hoverBackgroundColor: [
            "#FFC533", // Yellow
            "#FF8B26", // Orange
            // "#CBD5E0", // Gray
            "#23608f", // Blue
            "#abd152", // Green
            "#FF8B26", // Orange
            // "#c4e6a8", //Light Green
          ],
          // borderWidth: 5,
          // borderColor: cash("html").hasClass("dark") ? "#303953" : "#e3eaf2",
        },
      ],
    };

    return chart_data;
  }

  function navToPage(url) {
    window.location.href = url;
  }
  function LengendText(props) {
    const { text, url, color, value, ...rest } = props;
    return (
      <div className="flex items-center mt-2">
        <div className={"w-2 h-2 rounded-full mr-3 " + color}></div>
        <a
          className="truncate cursor-pointer"
          href={void 0}
          onClick={enableEdit ? () => {} : (e) => navToPage(url)}
        >
          {text}
        </a>
        <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3"></div>
        <span className="font-medium xl:ml-auto">{value}</span>
      </div>
    );
  }
  return (
    <div id={id} className={className}>
      {/* <!BEGIN: CHART AREA --> */}
      <div
        style={{
          position: "relative",
          margin: "auto",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="w-full h-full flex items-center justify-center">
          {/* <!--BEGIN: Chart --> */}
          <div className="w-2/4 h-auto flex-none">
            <div
              style={{
                position: "relative",
                margin: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <Doughnut data={chartData} options={options} />
            </div>
          </div>
          {/* <!--END: Chart --> */}

          {/* <!--BEGIN: Text --> */}
          <div className="flex-none relative">
            <LengendText
              text="Prepared"
              url="/admin#/consignment/pending"
              color="bg-theme-12"
              value={data?.prepared}
            />

            <LengendText
              text="In Transit"
              url="/admin#/consignment/in_progress"
              color="bg-theme-11"
              value={data?.in_transit}
            />

            <LengendText
              text="Completed"
              url="/admin#/consignment/acknowledged"
              color="bg-theme-1"
              value={data?.acknowledged}
            />
          </div>
          {/* <!--END: Text --> */}
        </div>
      </div>
      {/* <!END: CHART AREA --> */}

      {/* <!BEGIN: CARD TITLE --> */}
      <div className="mt-auto">
        <div className="text-base md:text-sm font-medium truncate text-theme-1">
          Consignments
        </div>
      </div>
      {/* <!END: CARD TITLE --> */}
    </div>
  );
});

export default Component;

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  enableEdit: PropTypes.bool,
};

Component.defaultProps = {
  className: "w-full h-full flex flex-col items-center justify-center",
  id: "consignments_content",
  name: "",
  data: {
    prepared: 0,
    in_transit: 0,
    completed: 0,
    acknowledged: 0,
    invoiced: 0,
  },
  onClick() {},
  enableEdit: false,
};
