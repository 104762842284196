import React, { useState, useEffect } from "react";

// Toaster & Notification
import { resp } from "../../functions/responseHandler";
import { alertBox } from "../../components/notification/alert";

// External Components
import InLineLoader from "../../components/preloader/inLineLoader";
import ForgotPassword from "../../components/login/forgot_password";
import CreatePassword from "../../components/login/create_password";

// Functions
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";

export default function Login() {
  /////////// VARIABLES DECLARATION ///////////
  const [state, setState] = useState({
    email: "",
    password: "",
    remember_me: false,
    user_data: {},
  });
  // Loaders
  const [isProcessing, setIsProcessing] = useState(false);
  // Forgot Password Modal
  const [showForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const closeForgotPassworModal = () => setForgotPasswordModal(false);
  const openForgotPasswordModal = () => setForgotPasswordModal(true);

  // Create Password Modal
  const [showCreatePasswordModal, setCreatePasswordModal] = useState(false);
  const closeCreatePasswordModal = () => setCreatePasswordModal(false);
  const openCreatePasswordModal = () => setCreatePasswordModal(true);

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    checkRememberMe();
  }, []);
  // Checking Remember Me Option

  async function checkRememberMe() {
    let data = await storageServices.getStoredData("login");
    if (data && data?.remember_me) {
      let prevState = { ...state };
      prevState.email = data?.email;
      prevState.password = data?.password;
      prevState.remember_me = data?.remember_me;
      setState(prevState);
    }
  }
  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }
  function onChangeCheckbox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state.remember_me }));
  }

  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    if (state.remember_me && state.email) {
      await storageServices.storeData("login", state);
    } else {
      await localStorage.removeItem("login");
    }
    var query = {
      email: state.email.trim().toLowerCase(),
      password: state.password,
    };
    api
      .loginService(query)
      .then(async (response) => {
        console.log("response:", response);
        if (response.status == 200) {
          // processResponse(response);
        } else {
          setIsProcessing(false);
          resp.ErrorHandler(response);
        }
      })
      .catch((error) => {
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function processResponse(response) {
    var data = response.data;
    let authToken = data?.token;
    let userData = data?.data;

    userData["token"] = authToken;

    // To local format
    setState((prevState) => ({ ...prevState, user_data: userData }));

    await storageServices.setCurrentUser(userData);
    setIsProcessing(false);
    // Page Navigation

    if (!userData.activated) {
      openCreatePasswordModal();
    }
    // Checking User
    else if (userData.activated && userData.status === "active") {
      navToDashboard();
    } else {
      alertBox.Show({
        type: "error",
        title: "Account not active",
        text: "Please contact administrator.",
      });
    }
  }
  function navToDashboard() {
    closeCreatePasswordModal();
    window.location.href = "/ps_admin/admin#/home";
  }

  function passwordCreationCallBack() {
    closeCreatePasswordModal();
  }

  return (
    <>
      <div className="login">
        <div className="container sm:px-10">
          <div className="block xl:grid grid-cols-2 gap-4">
            {/* <!-- BEGIN: Info --> */}
            <div className="hidden xl:flex flex-col min-h-screen">
              {/* <!-- BEGIN: Logo --> */}
              <a href="" className="-intro-x flex items-center pt-5">
                <img
                  alt="CoreLogis"
                  className="w-48"
                  src="/images/logo-main.png"
                />
              </a>

              {/* <!-- END: Logo --> */}

              <div className="my-auto">
                <img
                  alt="Rubick Tailwind HTML Admin Template"
                  className="-intro-x w-1/2 -mt-16"
                  src="/images/truck-loading.png"
                />
                <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                  Optimum Platform to
                  <br />
                  track your logistics business.
                </div>
                <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">
                  Manage all your logistics works in one place
                </div>
              </div>
            </div>
            {/* <!-- END: Info --> */}

            {/* <!-- BEGIN: Login Form --> */}
            {/* <form onSubmit={onSubmit}> */}
            <form
              className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0"
              onSubmit={onSubmit}
            >
              <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                  Admin Sign In
                </h2>
                <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">
                  Manage Platform Customers.
                </div>
                <div className="intro-x mt-8">
                  <input
                    type="text"
                    className="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                    placeholder="Email"
                    name="email"
                    onChange={changeInput}
                    value={state.email}
                    required
                  />
                  <input
                    type="password"
                    className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                    placeholder="Password"
                    name="password"
                    onChange={changeInput}
                    value={state.password}
                    required
                  />
                </div>
                <div className="intro-x flex text-gray-700 dark:text-gray-600 sm:text-sm mt-4">
                  <div className="flex items-center mr-auto">
                    <input
                      id="remember-me"
                      type="checkbox"
                      className="form-check-input border mr-2"
                      checked={state.remember_me}
                      name="remember_me"
                      onChange={onChangeCheckbox}
                    />
                    <label
                      className="cursor-pointer select-none"
                      htmlFor="remember-me"
                    >
                      Remember me
                    </label>
                  </div>
                  <a
                    href={void 0}
                    className="cursor-pointer"
                    onClick={openForgotPasswordModal}
                  >
                    Forgot Password?
                  </a>
                </div>
                <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                  <button
                    className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                    type="submit"
                  >
                    Login {isProcessing ? <InLineLoader /> : null}
                  </button>
                </div>
              </div>
            </form>
            {/* </form> */}
            {/* <!-- END: Login Form --> */}

            {/*<!-- BEGIN: External Modals -- > */}

            {/*Forgot Password Modal */}

            {showForgotPasswordModal && (
              <ForgotPassword
                show={showForgotPasswordModal}
                onHide={closeForgotPassworModal}
                callBack={closeForgotPassworModal}
              />
            )}

            {/*Create Password Modal */}

            {showCreatePasswordModal && (
              <CreatePassword
                show={showCreatePasswordModal}
                onHide={closeCreatePasswordModal}
                email={state.email}
                callBack={passwordCreationCallBack}
              />
            )}

            {/*<!-- END: External Modals -- > */}
          </div>
        </div>
      </div>
    </>
  );
}
