import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";

import "./style.scss";

const Model = {
  branch_organizations: [],
  default_organization: "",
  selected_organization: "",
};

export default function Component(props) {
  const {
    branch_organizations,
    default_organization,
    selected_organization,
    localUserData,
    close_button,
  } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  // State
  const [state, setState] = useState(Model);
  useEffect(() => {
    let prevState = { ...state };
    prevState.branch_organizations = branch_organizations;
    prevState.default_organization = default_organization;
    prevState.selected_organization = selected_organization;
    setState(prevState);
  }, []);

  // Form Actions
  function onSubmit(selectedOrg, body) {
    setIsProcessing(true);
    api
      .postService(`organization/switchOrg/${selectedOrg}`, body)
      .then(async (response) => {
        // resp.Success(response?.data?.message);
        console.log("response:", response);

        const data = response.data;
        const authToken = data?.token;
        // Storing Token
        let currentUserData = await storageServices.getCurrentUser();
        currentUserData = { ...data.data };
        currentUserData["token"] = authToken;
        await storageServices.setCurrentUser(currentUserData);
        setIsProcessing(false);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    console.log("value:", value);
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (value) {
      onSubmit(value, localUserData);
    } else {
      resp.Error("Invalid Selection");
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        onHide={props.onHide}
        size="xl"
        backdrop="static"
        keyboard={false}
        centered={true}
        fullscreen={true}
      >
        {close_button && (
          <Modal.Header
            className="flex justify-end border-0"
            closeButton
          ></Modal.Header>
        )}

        <Modal.Body className="pb-12">
          <>
            {isProcessing && <ProcessLoader />}
            <div className="w-full mx-auto my-3 text-center">
              <h2 className="text-xl font-medium leading-none">Choose Branch</h2>
            </div>
            <form
              onSubmit={onSubmit}
              id="switch-org-form"
              className="grid grid-cols-12 gap-3 sm:gap-6 mt-5"
            >
              {state.branch_organizations.map((item, index) => {
                return (
                  <>
                    <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 xxl:col-span-3">
                      <label className="box-radio">
                        <input
                          type="radio"
                          name="selected_organization"
                          className="box-radio-input"
                          value={item?._id}
                          onChange={changeInput}
                          checked={state.selected_organization === item?._id}
                        />
                        <div
                          class={
                            state.selected_organization === item?._id
                              ? "file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in border border-theme-27 dark:border-dark-3 shadow-xl"
                              : "file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in shadow-xl"
                          }
                        >
                          {/* <!-- BEGIN: Default Button */}
                          {state.default_organization === item?._id && (
                            <div className="absolute left-0 top-0 mt-1 ml-1">
                              <div className="py-1 px-2 rounded-full  bg-gray-200 dark:bg-dark-5 text-gray-600 dark:text-gray-300 cursor-pointer ml-auto truncate">
                                Default
                              </div>
                            </div>
                          )}

                          {/* <!-- END: efault Button */}

                          <div className="w-3/5 file__icon file__icon--image mx-auto">
                            <div className="file__icon--image__preview image-fit">
                              <img alt="" src="images/office-building.png" />
                            </div>
                          </div>

                          <div className="block font-medium mt-4 text-center truncate">
                            {item?.name}
                          </div>
                        </div>
                      </label>
                    </div>
                  </>
                );
              })}
            </form>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  branch_organizations: PropTypes.array,
  default_organization: PropTypes.string,
  selected_organization: PropTypes.string,
  localUserData: PropTypes.object,
  close_button: PropTypes.bool,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Switch Organizations",
  close_button: false,
  default_organization: "",
  selected_organization: "",
  branch_organizations: [],
  localUserData: {},
  callBack() {},
};
