import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
export default function Component(props) {
  const {
    className,
    id,
    name,
    onClick,
    onRemoveItem,
    onAddItem,
    showRemove,
    onSave,
    enableEdit,
    onEnableEdit,
    originalItems,
    currentItems,
    ...rest
  } = props;

  var widgetsList = originalItems;
  var currentItemsList = [];
  for (var i in currentItems) {
    currentItemsList.push(currentItems[i]?.widget_id);
  }

  function handleCheckBoxChange(event) {
    const { name, value, checked } = event.target;
    console.log("value:", value, checked);
    if (checked) {
      onAddItem(value);
    } else {
      onRemoveItem(value);
    }
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href={void 0}
      className="cursor-pointer"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  return (
    <div className={className} id={id}>
      <div className="flex items-center">
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <div className="btn box text-gray-700 dark:text-gray-600">
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="box shadow p-3 w-48 border">
            <a
              className="flex items-center block p-2 bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
              href={void 0}
              onClick={onEnableEdit}
            >
              <i class="fa fa-pencil text-theme-1 mr-2" aria-hidden="true"></i>
              Edit Widgets
            </a>
            {/* <!-- BEGIN: Add Widget Option --> */}
            <div id="add_widgets_option" className="">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="add_widgets_dropdown_toggle"
                  variant="link"
                >
                  <a
                    className="flex items-center block p-2 bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
                    href={void 0}
                  >
                    <i
                      className="fa fa-plus text-theme-1 mr-2"
                      aria-hidden="true"
                    ></i>
                    Add Widgets
                  </a>
                </Dropdown.Toggle>

                <Dropdown.Menu className="box shadow p-3 w-48 border">
                  {widgetsList.map((item, index) => {
                    return (
                      <div
                        className="form-check mt-2"
                        id={"widget-list-" + index}
                      >
                        <input
                          id={item?.widget_id}
                          className="form-check-input"
                          type="checkbox"
                          name="widget_id"
                          value={item?.widget_id}
                          checked={currentItemsList.includes(item?.widget_id)}
                          onChange={handleCheckBoxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={item?.widget_id}
                        >
                          {item?.widget_name}
                        </label>
                      </div>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <!-- END: Add Widget Option --> */}
          </Dropdown.Menu>
        </Dropdown>

        {/* {!enableEdit && (
          <div id="edit_widgets_option">
            <button
              className="btn btn-primary-soft"
              type="button"
              onClick={onEnableEdit}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
          </div>
        )} */}

        {/* {enableEdit && (
          <div id="save_edit_widgets_option" className="ml-1">
            <button
              className="btn btn-success-soft"
              type="button"
              onClick={onSave}
            >
              <i className="fa fa-check mr-1" aria-hidden="true"></i>Save
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  showRemove: PropTypes.bool,
  onRemoveItem: PropTypes.func,
  onAddItem: PropTypes.func,
  onClick: PropTypes.func,
  onSave: PropTypes.func,
  onEnableEdit: PropTypes.func,
  originalItems: PropTypes.array,
  currentItems: PropTypes.array,
};

Component.defaultProps = {
  className: "z-10",
  id: "widget_options",
  name: "",
  enableEdit: false,
  showRemove: true,
  onRemoveItem() {},
  onAddItem() {},
  onEnableEdit() {},
  onSave() {},
  onClick() {},
  originalItems: [],
  currentItems: [],
};
