import React, { useState, useEffect } from "react";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
import PropTypes from "prop-types";
// HTML Components
import { resp } from "../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import ValidationError from "../../components/error/validationError";
// Functions
import * as api from "../../api_service/api";
import { matchData } from "../../functions/common";
import * as validation from "../../functions/validation";
// Models
import { Model, ValidationErrorModel } from "./model";

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({ view: false, edit: false, create: false });
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  const [validationError, setValidationError] = useState({
    ...ValidationErrorModel,
  });
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    switch (props.action) {
      case "create":
        setState({ ...Model });
        setMode((prevState) => ({ ...prevState, create: true }));
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }
  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`db_list/${id}`).then(
      async (result) => {
        console.log("result:", result);
        const data = result.data;
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let prevState = await matchData(state, data);
    await setState(prevState);
  }

  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = { ...state };
      api
        .postService("db_list", data)
        .then((response) => {
          console.log(response.status);
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = { ...state };
      api
        .putService(`db_list/${record_id}`, data)
        .then((response) => {
          console.log(response.status);
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = { ...validationError };
    switch (name) {
      case "name":
        errors[name] = validation.validateName(value);
        break;
      default:
        break;
    }
    setValidationError(errors);
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal {...props} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content">
            {isProcessing && <ProcessLoader />}
            <form
              onSubmit={
                mode.create
                  ? onSubmit
                  : mode.edit
                  ? onUpdate
                  : (e) => e.preventDefault()
              }
              id="driver-form"
            >
              <div className="form-group">
                <label className="control-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.name}
                  required
                />
                <ValidationError msg={validationError.name} />
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="control-label">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.username}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    name="password"
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.password}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label">Connection URL</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="connection_url"
                  name="connection_url"
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.connection_url}
                  required
                />
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="control-label">Status</label>
                  <select
                    id="status"
                    name="status"
                    className="form-control"
                    onChange={changeInput}
                    value={state.status}
                    required
                  >
                    <option value="">Choose...</option>
                    <option value="Ready">Ready</option>
                    <option value="Testing">Testing</option>
                    <option value="Await Launching">Await Launching</option>
                    <option value="Under Maintenance">Under Maintenance</option>
                    <option value="Suspended">Suspended</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">Remarks</label>
                  <input
                    type="text"
                    className="form-control"
                    id="remarks"
                    name="remarks"
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.remarks}
                  />
                </div>
              </div>

              {/* License Image */}

              <input type="submit" id="save" className="hidden" />
              <input type="submit" id="update" className="hidden" />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center mr-2">
            <ValidationError msg={!isValidForm.valid ? isValidForm.msg : ""} />
          </div>
          <label
            type="button"
            className="btn btn-secondary mr-2 "
            onClick={props.onHide}
          >
            Cancel
          </label>

          {mode.create && (
            <label
              type="button"
              className="btn btn-info float-right mr-2 "
              htmlFor="save"
            >
              Save
            </label>
          )}
          {mode.edit && (
            <label
              type="button"
              className="btn btn-info float-right mr-2 "
              htmlFor="update"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Database",
  action: "view",
  callBack() {},
};
