const Model = {
  date: "",
  consignment: "",
  vehicle: "",
  tyre: "",
  mode: "",
  service_type: "",
  supplier: "",
  odometer_reading: "",
  status: "",
  amount: "",
  is_paid_by_driver: false,
  payment_mode: "",
  cash: "",
  bank: "",
  payment_reference_number:"",
  remarks:""
};

const ValidationErrorModel = {
  date: "",
  consignment: "",
  vehicle: "",
  tyre: "",
  mode: "",
  service_type: "",
  supplier: "",
  odometer_reading: "",
  status: "",
  amount: "",
  is_paid_by_driver: "",
  payment_mode: "",
  cash: "",
  bank: "",
  payment_reference_number:"",
  remarks:""
};

export { Model };
export { ValidationErrorModel };
