const Model = {
  name: "",
  connection_url: "",
  logo: "",
  email: "",
  address: "",
  website: "",
  phone: "",
  contact_person: "",
  gst: "",
  pan_number: "",
  activated: "",
  approved: "",
  status: "",
};
const ValidationErrorModel = {
  name: "",
};

export { Model };
export { ValidationErrorModel };
