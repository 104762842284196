import axios from "axios";
import { storageServices } from "../functions/storage";

const REPORTS_URL = process.env.REACT_APP_REPORTS_URL;
const GPS_URL = process.env.REACT_APP_GPS_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const ifscURL = "https://ifsc.razorpay.com/";
const eWayBillApiUrl = "http://127.0.0.1:5000/";

// External API Calls
export async function getEWBService(routing) {
  var url = eWayBillApiUrl + routing;
  return axios.get(url);
}
export async function getEWBCaptcha(routing) {
  var url = eWayBillApiUrl + routing;
  return axios.get(url, { responseType: "arraybuffer" });
}

export async function getBankDetails(ifsc_code) {
  let url = ifscURL + ifsc_code;
  return axios.get(url);
}

/////////// Common API Calls ///////////

///////////////////<!--BEGIN: REPORTS SERVER -- >///////////////////////

export async function reportsGetService(routing) {
  let user = await storageServices.getCurrentUser();
  let headers = { headers: { Authorization: `Bearer ${user.token}` } };
  var url = REPORTS_URL + routing;
  return axios.get(url, headers);
}

export async function reportsPostService(routing, query, options) {
  // options must be object
  let user = await storageServices.getCurrentUser();
  var url = REPORTS_URL + routing;

  var params = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: `Bearer ${user.token}`,
    },
  };
  if (options) {
    params = { ...params, ...options };
  }

  return axios.post(url, query, params);
}

///////////////////<!--END: REPORTS SERVER -- >///////////////////////

// GET

export async function apiGetService(url) {
  return axios.get(url);
}

export async function getService(routing, params) {
  let user = await storageServices.getCurrentUser();
  let config = { headers: { Authorization: `Bearer ${user.token}` } };
  if (params) {
    config["params"] = params;
  }
  var url = apiUrl + routing;

  return axios.get(url, config);
}
export async function appGetService(routing) {
  let user = await storageServices.getCurrentUser();
  let headers = { headers: { Authorization: `Bearer ${user.token}` } };
  var url = apiUrl + routing;
  return axios.get(url, headers);
}

//
export async function noTokenpostService(routing, query) {
  var url = apiUrl + routing;

  return axios.post(url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function noTokenPatchService(routing, query) {
  var url = apiUrl + routing;

  return axios.patch(url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// POST
export async function gpsPostService(routing, query) {
  var url = GPS_URL + routing;

  return axios.post(url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function postService(routing, query) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;

  return axios.post(url, query, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function DatapostService(routing, formData) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;
  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.token}`,
    },
  });
}

// PUT
export async function DataputService(routing, formData) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;
  return axios.patch(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.token}`,
    },
  });
}
export async function putService(routing, query) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;
  return axios.patch(url, query, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

// DELETE
export async function deleteService(routing) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

/////////// Common API Calls ///////////

// Login & Other Calls
export async function updatePassword(routing, query) {
  var url = apiUrl + routing;
  return axios.patch(url, query);
}

export async function createPassword(query) {
  var url = apiUrl + "user/create_password";
  return axios.patch(url, query);
}

export async function loginService(query) {
  var url = apiUrl + "login";
  return axios.post(url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function commonLoginService(query) {
  var url = apiUrl + "login/google";
  return axios.post(url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function forgotPassword(query) {
  var url = apiUrl + "user/forgotPassword";
  return axios.post(url, query);
}
