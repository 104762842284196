import React, { useState, useEffect } from "react";
// Loaders
import ProcessLoader from "../../preloader/processLoader";
import PropTypes from "prop-types";
// HTML Components
import { resp } from "../../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import ImageNFileInput from "../../inputs/imagenfile";
import ValidationError from "../../error/validationError";
import DividerText from "../../htmlElements/dividerText";
import PhoneInput from "../../htmlElements/phoneInput";
import ProfileImage from "../../inputs/profileImage";

import SimTrackingModal from "./sim_tracking";
// Functions
import * as api from "../../../api_service/api";
import {
  matchData,
  JsonToFormData,
  htmlDate,
  getAdultMaxDate,
  isDefined,
} from "../../../functions/common";
import * as validation from "../../../functions/validation";
// Models
import { Model, ValidationErrorModel } from "./model";

const fileBaseURL = process.env.REACT_APP_DRIVER_URL;

export default function Component(props) {
  const [saveToDB, setSaveToDB] = useState(true);

  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [simTrackingModal, setSimTrackingModal] = useState({ show: false });
  const [mode, setMode] = useState({ view: false, edit: false, create: false });
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  const [validationError, setValidationError] = useState({
    ...ValidationErrorModel,
  });
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    // preventTabClosing();
    handleUserAction(props);
  }, []);

  function openSimTrackingModal() {
    setSimTrackingModal((prevState) => ({ ...prevState, show: true }));
  }
  function closeSimTrackingModal() {
    setSimTrackingModal((prevState) => ({ ...prevState, show: false }));
  }

  async function handleUserAction(props) {
    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    switch (props.action) {
      case "create":
        setState({ ...Model });
        setMode((prevState) => ({ ...prevState, create: true }));
        setDefaults(props);
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  function setDefaults() {
    setIsProcessing(true);
    let prevState = { ...Model };
    if (props?.name) prevState["name"] = props?.name;
    setState(prevState);
    setIsProcessing(false);
    return null;
  }
  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`driver/${id}`).then(
      async (result) => {
        console.log("result:", result);
        const data = result.data;
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let prevState = await matchData(state, data);
    prevState.dob = htmlDate(prevState?.dob);
    prevState.valid_from = htmlDate(prevState.valid_from);
    prevState.valid_to = htmlDate(prevState.valid_to);

    // Checking License Details
    if (
      isDefined(prevState?.license_no) ||
      isDefined(prevState?.valid_from) ||
      isDefined(prevState?.valid_to) ||
      isDefined(prevState?.license_proof)
    ) {
      prevState.license_details = true;
    }

    await setState(prevState);
  }

  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));

      let data = { ...state };
      data["isNew"] = true;

      if (saveToDB) {
        setIsProcessing(true);
        var query = await JsonToFormData(data);
        api
          .DatapostService("driver", query)
          .then((response) => {
            console.log(response.status);
            setIsProcessing(false);
            resp.Success(response?.data?.message);
            props.callBack(response?.data?.data);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        props.callBack(data);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = { ...state };
      var query = await JsonToFormData(data);
      api
        .DataputService(`driver/${record_id}`, query)
        .then((response) => {
          console.log(response.status);
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  async function changeInputFile(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImageNFile(files[0]);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  function deleteInputFile(event, name) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [name]: "" }));
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = { ...validationError };
    switch (name) {
      case "name":
        errors[name] = validation.validateName(value);
        break;
      case "contact_number":
        errors[name] = validation.validatePhoneNo(value);
        break;
      case "valid_from":
        errors[name] = validation.validateFromDate(value, state.valid_to);
        errors["valid_to"] = validation.validateToDate(state.valid_to, value);
        break;
      case "valid_to":
        errors[name] = validation.validateToDate(value, state.valid_from);
        errors["valid_from"] = validation.validateFromDate(
          state.valid_from,
          value
        );
        break;
      case "license_no":
        errors[name] = validation.validateLicenseNo(value);
        break;

      default:
        break;
    }
    setValidationError(errors);
  }

  function switchBoolean(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>
        <Modal.Body>
          {simTrackingModal?.show && (
            <SimTrackingModal
              show={simTrackingModal?.show}
              data={state}
              recordId={record_id}
              onHide={closeSimTrackingModal}
            />
          )}

          <div>
            {isProcessing && <ProcessLoader />}
            <form
              onSubmit={
                mode.create
                  ? onSubmit
                  : mode.edit
                  ? onUpdate
                  : (e) => e.preventDefault()
              }
              id="driver-form"
            >
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12">
                  <ProfileImage
                    mode={mode}
                    state={state?.display_picture}
                    name="display_picture"
                    onDelete={deleteInputFile}
                    onChange={changeInputFile}
                    className="text-center mb-2"
                    baseURL={fileBaseURL}
                  />
                </div>

                <div className="col-span-12">
                  <label className="form-label required">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.name}
                    required
                  />
                  <ValidationError msg={validationError.name} />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="phone" className="form-label">
                    D.O.B
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="dob"
                    name="dob"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.dob}
                    max={htmlDate(getAdultMaxDate())}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label className="form-label">Phone number</label>
                  <PhoneInput
                    type="number"
                    className="form-control"
                    id="contact_number"
                    name="contact_number"
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.contact_number}
                  />
                  <ValidationError msg={validationError.contact_number} />
                </div>
              </div>

              {/* <!--BEGIN: License Details --> */}
              {state?.license_details && (
                <>
                  <DividerText>License Information</DividerText>

                  <div className="form-group mb-3">
                    <label className="form-label">License number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="license_no"
                      name="license_no"
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.license_no}
                      required
                    />
                    <ValidationError msg={validationError.license_no} />
                  </div>

                  <div className="grid grid-cols-12 gap-4 gap-y-3 mb-3">
                    <div className="col-span-12 sm:col-span-6">
                      <label className="form-label">Valid From</label>
                      <input
                        type="date"
                        className="form-control"
                        id="valid_from"
                        name="valid_from"
                        disabled={mode.view}
                        onChange={changeInput}
                        value={state.valid_from}
                      />
                      <ValidationError msg={validationError.valid_from} />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label className="form-label">Valid To</label>
                      <input
                        type="date"
                        className="form-control"
                        id="valid_to"
                        name="valid_to"
                        disabled={mode.view}
                        onChange={changeInput}
                        value={state.valid_to}
                      />
                      <ValidationError msg={validationError.valid_to} />
                    </div>
                  </div>

                  {/* License Image */}
                  <div className="form-group w-full justify-center">
                    <label htmlFor="license_proof">License proof</label>
                    <ImageNFileInput
                      mode={mode}
                      state={state?.license_proof}
                      name="license_proof"
                      error_msg={validationError.license_proof}
                      onDelete={deleteInputFile}
                      onChange={changeInputFile}
                      baseURL={fileBaseURL}
                    />
                  </div>
                  {/* License Image */}
                </>
              )}
              {/* <!--END: License Details --> */}

              {/* Other Details Button */}
              {!mode?.view && (
                <>
                  <div className="col-span-12 mt-4">
                    <div className="preview">
                      {!state?.license_details && (
                        <button
                          className="btn btn-outline-primary inline-block mr-1"
                          name="license_details"
                          onClick={switchBoolean}
                          type="button"
                        >
                          <i className="fa fa-plus mr-1" aria-hidden="true"></i>{" "}
                          License Details
                        </button>
                      )}

                      {!mode?.create && (
                        <button
                          className="btn btn-outline-danger inline-block mr-1"
                          name="sim_tracking"
                          type="button"
                          onClick={(e) => openSimTrackingModal()}
                        >
                          <i
                            className="fa fa-map-marker mr-1"
                            aria-hidden="true"
                          ></i>{" "}
                          SIM Tracking
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* Other Details Button */}

              <input type="submit" id="save_driver" className="hidden" />
              <input type="submit" id="update_driver" className="hidden" />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center mr-2">
            <ValidationError msg={!isValidForm.valid ? isValidForm.msg : ""} />
          </div>
          <label
            type="button"
            className="btn btn-secondary mr-2 "
            onClick={props.onHide}
          >
            Cancel
          </label>

          {mode.create && (
            <label
              type="button"
              className="btn btn-primary mr-2 "
              htmlFor="save_driver"
            >
              Save
            </label>
          )}
          {mode.edit && (
            <label
              type="button"
              className="btn btn-primary mr-2 "
              htmlFor="update_driver"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Driver Master",
  action: "view",
  callBack() {},
};
