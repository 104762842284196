import React from "react";
import {
  formatCurrency,
  htmlDateTime,
  isDefined,
  localDate,
  toFloat,
  getAddressString,
} from "../../functions/common";

import "./style.css";
const baseURL = process.env.REACT_APP_FILE_URL;

export default function LRTemplate(props) {
  const { data, organization, customer_account } = props;
  const consignment = data?.consignment;
  // Hardcoded Data
  // const companyName = organization?.name;
  const companyName = customer_account?.name;
  const pan_number = organization?.pan_number;
  const service_tax_paid_by = "consigner";

  const address = getAddressString(customer_account);

  var logoURL = baseURL + organization?.logo;

  try {
    logoURL = logoURL.replace(/\\/g, "/");
  } catch {}

  // Logo
  var logoError = true;

  if (customer_account?._id === "61c0d49ab9af2b12f8b79422") {
    logoURL = "images/svrl-logo.jpg";
    logoError = false;
  }
  // const companyLogo = getBase64Image(organization?.logo);
  // console.log("companyLogo:", companyLogo);

  var logoContainer = <></>;

  if (!logoError) {
    logoContainer = <img src={logoURL} id="img-logo" />;
  } else {
    logoContainer = (
      <div className="font-bold text-theme-1 break-all text-xl">
        {companyName}
      </div>
    );
  }

  const css = `
  .border {
    border: 1px solid !important;
  }
  `

  return (
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style>{css}</style>
      </head>
      <body>
        <div
          id="invoice_report"
          className={data?.status === "cancelled" ? "cancelled-watermark" : ""}
        >
          <table className="table mb-3" id="header">
            <tbody>
              <tr>
                <td className="border-top-0">
                  {/* <img alt="" className="" crossorigin="*" src={logoURL} /> */}
                  {logoContainer}
                  {/* <span className="text-lg text-theme-1 break-all">
                    <strong>
                      {customer_account?.name} -{" "}
                      <small>{organization?.name}</small>
                    </strong>
                  </span> */}
                </td>
                <td className="text-center border-top-0">
                  <p>Consignment No:</p>
                  <span className="font-bold">
                    {consignment?.consignment_id}
                  </span>
                </td>
                <td className="text-right border-top-0">
                  <label>Invoice No:</label>&nbsp;
                  <span className="font-bold">{data?.invoice_number}</span>
                  <div className="clearfix"></div>
                  <label>Invoiced Date:</label>&nbsp;
                  <span className="font-bold">
                    {localDate(data?.invoiced_date)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          {/* Address Table */}
          <table className="table table-bordered mb-3" id="address">
            <thead>
              <tr>
                <th scope="col" className="border   whitespace-nowrap">
                  From
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  To
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="border  w-1/2">
                  {/* COMPANY ADDRESS */}
                  <label className="font-bold mb-2">{companyName}</label>
                  <div className="clearfix"></div>
                  <label className="break-all">{address}</label>
                  <div className="clearfix mb-2"></div>
                  <b>PAN:</b>
                  <label className="ml-2">{pan_number}</label>
                </td>
                {/* CLIENT ADDRESS */}
                <td className="border w-1/2">
                  <label className="font-bold mb-2">
                    {isDefined(data?.client_name)
                      ? data?.client_name
                      : data?.client?.name}
                  </label>
                  <div className="clearfix"></div>
                  <label className="break-all">{data?.address}</label>
                  <div className="clearfix mb-2"></div>
                  <b>GST:</b>
                  <span className="ml-2">{data?.client?.gst}</span>
                </td>
              </tr>
            </tbody>
          </table>

          {/* CONSIGNMENT DETAILS */}
          <table className="table table-bordered mb-3" id="consignment">
            <thead>
              <tr>
                <th scope="col" className="border   whitespace-nowrap">
                  Cons. Id
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Cons. Date
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Dispatch from
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Dispatch to
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border">{consignment?.consignment_id}</td>
                <td className="border">
                  {localDate(consignment?.consignment_date)}
                </td>
                <td className="border">{consignment?.origin?.name}</td>
                <td className="border">{consignment?.destination?.name}</td>
              </tr>
            </tbody>
          </table>

          {/* Load Receipts Table */}
          <label className="font-bold">Load Receipts</label>
          <table className="table table-bordered mb-3" id="load_receipts">
            <thead>
              <tr>
                <th scope="col" className="border   whitespace-nowrap">
                  #
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  LR No.
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Consigner
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Consignee
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  P.O.No
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  e-Way Bill No
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Goods Invoice No
                </th>
              </tr>
            </thead>
            <tbody className="text-dark">
              {consignment?.load_receipts.map((item, index) => {
                return (
                  <>
                    <tr>
                      <td className="border">{index + 1}</td>
                      <td className="border">{item?.lr_number}</td>
                      <td className="border">{item?.consigner?.name}</td>
                      <td className="border">{item?.consignee?.name}</td>
                      <td className="border">{item?.po_number}</td>
                      <td className="border">{item?.eway_bill_no}</td>
                      <td className="border">{item?.invoice_number}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>

          {/* Legal entities Table */}

          {/* Package DETAILS */}
          <table className="table table-bordered mb-3" id="package">
            <thead>
              <tr>
                <th scope="col" className="border   whitespace-nowrap">
                  Total No. of PKG
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Invoice weight
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Arrived Date
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Delivered Date
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border">{consignment?.total_no_Of_packages}</td>
                <td className="border">
                  {consignment?.total_invoice_weight} Kg
                </td>
                <td className="border">
                  {htmlDateTime(consignment?.loading_point?.in_time)}
                </td>
                <td className="border">
                  {htmlDateTime(consignment?.unloading_point?.out_time)}
                </td>
              </tr>
            </tbody>
          </table>

          {/* Vehicle DETAILS */}
          <table className="table table-bordered mb-3" id="vehicle">
            <thead>
              <tr>
                <th scope="col" className="border whitespace-nowrap">
                  Vehicle
                </th>
                <th scope="col" className=" border whitespace-nowrap">
                  Vehicle Type
                </th>
                <th scope="col" className="border whitespace-nowrap">
                  Billed Weight
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border">
                  {consignment?.vehicle?.registration_number}
                </td>
                <td className="border">
                  {consignment?.vehicle?.vehicle_type?.vehicle_type}
                </td>
                <td className="border">
                  {consignment?.total_invoice_weight} Kg
                </td>
              </tr>
            </tbody>
          </table>

          {/* Loading Point DETAILS */}

          <div className="preview mb-3">
            <span className="font-bold">Loading Point:</span>
            <span className="ml-2">
              In Time: {htmlDateTime(consignment?.loading_point?.in_time)}
            </span>
            <span className="ml-2">
              Out Time: {htmlDateTime(consignment?.loading_point?.out_time)}
            </span>
          </div>

          <div className="preview mb-3">
            <span className="font-bold">Unloading Point:</span>
            <span className="ml-2">
              In Time: {htmlDateTime(consignment?.unloading_point?.in_time)}
            </span>
            <span className="ml-2">
              Out Time: {htmlDateTime(consignment?.unloading_point?.out_time)}
            </span>
          </div>

          {/* AMOUNT DETAILS */}
          <table className="table table-bordered mb-3" id="amount">
            <thead>
              <tr>
                <th scope="col" className="border   whitespace-nowrap">
                  #
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Description
                </th>
                <th scope="col" className="border   whitespace-nowrap">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Freight Amount */}
              <tr>
                <td className="border">01</td>
                <td className="border">Freight charge</td>
                <td className="border">
                  {formatCurrency(data?.total_freight_amount)}
                </td>
              </tr>

              {/* <!-- BEGIN: Additions --> */}
              {data?.client_addition?.length > 0 && (
                <>
                  <tr>
                    <td colspan="3" className="font-medium border">
                      Other Charges
                    </td>
                  </tr>

                  {data?.client_addition?.map((item, index) => (
                    <tr>
                      <td className="border">{index + 1}</td>
                      <td className="border">{item?.type?.name}</td>
                      <td className="border">
                        + {formatCurrency(toFloat(item?.amount))}
                      </td>
                    </tr>
                  ))}
                </>
              )}
              {/* <!-- END: Additions --> */}

              {/* <!-- BEGIN: Deductions --> */}
              {data?.client_deduction?.length > 0 && (
                <>
                  <tr>
                    <td colspan="3" className="font-medium border">
                      Deductions
                    </td>
                  </tr>

                  {data?.client_deduction?.map((item, index) => (
                    <tr>
                      <td className="border">{index + 1}</td>
                      <td className="border">{item?.type?.name}</td>
                      <td className="border">
                        - {formatCurrency(toFloat(item?.amount))}
                      </td>
                    </tr>
                  ))}
                </>
              )}
              {/* <!-- END: Additions --> */}

              <tr>
                <td colSpan="2" className="text-right border">
                  Grand Total
                </td>
                <td className="border font-bold">
                  {formatCurrency(toFloat(data?.total))}
                </td>
              </tr>
            </tbody>
          </table>

          <label className="mb-5">
            Service tax paid by: {service_tax_paid_by}{" "}
          </label>
          {/* SIGNATURE */}
          <table className="table" id="signature">
            <thead>
              <tr>
                <th scope="col" className=" border-b-1 whitespace-nowrap">
                  Prepared by
                </th>
                <th scope="col" className="border-b-1  whitespace-nowrap">
                  Approved by
                </th>
                <th scope="col" className="border-b-1  whitespace-nowrap">
                  Authorized Signatory
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-top-0">{data?.creator_id?.fullname}</td>
                <td className="border-top-0">{data?.approver_id?.fullname}</td>
                <td className="border-top-0">
                  {" "}
                  <div className="clear-fix"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </body>
    </html>
  );
}
