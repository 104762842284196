import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
export default function ConsActionButtons(props) {
  const {
    callDriverButton,
    runningStatusButton,
    deleteButton,
    editButton,
    cloneButton,
    cell,
  } = props;

  useEffect(() => {});

  const css = `.action-button-display {
     position:fixed!important;
     z-index:99;
  }`;
  return (
    <div>
      <style>{css}</style>
      <Dropdown className="">
        <Dropdown.Toggle
          className="btn btn-default border-0"
          id="consignment_action_buttons"
          variant="link"
        >
          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="box shadow p-2 action-button-display w-48"
          style={{ position: "fixed!important" }}
          align="start"
          drop="start"
          flip={true}
        >
          {/* CALL DRIVER BUTTON */}
          {callDriverButton?.show ? (
            <button
              className="flex items-center mb-2 p-2 w-full"
              // onClick={(event) => {
              //   event.preventDefault();
              //   callDriverButton.onClick();
              // }}
              // data-toggle="tooltip"
              data-placement="top"
              title="Call Driver"
              href={`tel:${callDriverButton?.phone_number}`}
              disabled={callDriverButton?.disabled}
            >
              <i class="fa fa-phone text-theme-9 mr-2" aria-hidden="true"></i>
              Call Driver
            </button>
          ) : null}
          {/* CALL DRIVER BUTTON */}

          {/* Runnning Status BUTTON */}
          {runningStatusButton?.show ? (
            <button
              type="button"
              className="flex items-center mb-2 p-2 w-full"
              onClick={(event) => {
                event.preventDefault();
                runningStatusButton.onClick();
              }}
              // data-toggle="tooltip"
              data-placement="top"
              title="Running Status"
              href={void 0}
              disabled={runningStatusButton?.disabled}
            >
              <i
                class="fa fa-map-marker text-theme-1 mr-2"
                aria-hidden="true"
              ></i>
              Running Status
            </button>
          ) : null}
          {/* Running Status BUTTON */}

          {/* Edit BUTTON */}
          {editButton?.show ? (
            <button
              type="button"
              className="flex items-center mb-2 p-2 w-full"
              onClick={(event) => {
                event.preventDefault();
                editButton.onClick();
              }}
              data-placement="top"
              title="Edit Consignment"
              href={void 0}
              disabled={editButton?.disabled}
            >
              <i class="fa fa-pencil text-theme-1 mr-2" aria-hidden="true"></i>
              Edit
            </button>
          ) : null}
          {/* Edit Consignment BUTTON */}

          {/* Clone BUTTON */}
          {cloneButton?.show ? (
            <button
              type="button"
              className="flex items-center mb-2 p-2 w-full"
              onClick={(event) => {
                event.preventDefault();
                cloneButton.onClick();
              }}
              data-placement="top"
              title="Duplicate Consignment"
              href={void 0}
              disabled={cloneButton?.disabled}
            >
              <i class="fa fa-files-o text-theme-1 mr-2" aria-hidden="true"></i>
              Duplicate
            </button>
          ) : null}
          {/* Clone BUTTON */}

          {/* Delete BUTTON */}
          {deleteButton?.show ? (
            <button
              type="button"
              className="flex items-center mb-2 p-2 w-full"
              onClick={(event) => {
                event.preventDefault();
                deleteButton.onClick();
              }}
              data-placement="top"
              title="Delete Consignment"
              href={void 0}
              disabled={deleteButton?.disabled}
            >
              <i class="fa fa-trash text-theme-6 mr-2" aria-hidden="true"></i>
              Delete
            </button>
          ) : null}
          {/* Delete BUTTON */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

ConsActionButtons.propTypes = {
  callDriverButton: PropTypes.object,
  runningStatusButton: PropTypes.object,
  deleteButton: PropTypes.object,
  editButton: PropTypes.object,
};

ConsActionButtons.defaultProps = {
  callDriverButton: {
    show: true,
    disabled: false,
    onClick() {},
    phone_number: "",
  },
  runningStatusButton: {
    show: true,
    disabled: false,
    onClick() {},
  },
  deleteButton: {
    show: true,
    disabled: false,
    onClick() {},
  },
  editButton: {
    show: true,
    disabled: false,
    onClick() {},
  },
  cloneButton: {
    show: true,
    disabled: false,
    onClick() {},
  },
};
