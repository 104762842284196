import React, { useState, useEffect } from "react";
// Components
import AdminLayout from "../../layouts/admin";
import Container from "../../components/container";
import CustomDataTable from "../../components/tables/customDataTable";
import StatusBadge from "../../components/pageElements/statusBadge";
import ActionButtons from "../../components/pageElements/actionButtons";
import FormMaster from "../../components/branches"; // Form
// Functions
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";
import { htmlToString, localDate } from "../../functions/common";
import { reactFormatter } from "react-tabulator";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../components/notification/alert";
import { resp } from "../../functions/responseHandler";

export default function View(props) {
  const module_action = props.match.params?.action || "";

  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData();
    if (module_action === "initial_setup") {
      loadInitialSetup();
    }
  }, []);

  function loadInitialSetup() {
    addRecord();
  }

  // Fetching Grid Data From API
  async function loadData() {
    setIsProcessing(true);
    api.getService("organization").then(
      async (result) => {
        const data = result.data;
        console.log("user fetch", data);
        let tableData = await mapTableData(data.data);
        setTableData(tableData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      // {
      //   title: "Name",
      //   field: "fullname",
      //   responsive: 0,
      //           vertAlign: "middle",
      //   formatter(cell, formatterParams) {
      //     return `<div>
      //                       <div className="font-medium whitespace-nowrap">${
      //                         cell.getData().fullname
      //                       }</div>

      //                   </div>`;
      //   },
      // },
      {
        title: "Branch Name",
        field: "name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Primary Email",
        field: "email",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Contact",
        field: "phone",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Created At",
        field: "createdAt",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Updated At",
        field: "updatedAt",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },

      {
        title: "Status",
        field: "status",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",

        formatter: function (cell) {
          return htmlToString(<StatusBadge text={cell?.getValue()} />);
        },
      },

      {
        title: "Actions",
        field: "",
        vertAlign: "middle",

        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(<ActionButtonsLookup />),
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [{ label: "Name", value: "name" }],
    };
    return response;
  }

  function ActionButtonsLookup(data) {
    const cellData = data.cell._cell.row.data;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e) => {
            editRecord(cellData);
          },
        }}
        suspendButton={{
          show: cellData?.status == "active" ? true : false,
          onClick: (e) => {
            deleteRecord(cellData);
          },
        }}
        activateButton={{
          // show: false,
          show: cellData?.status == "inactive" ? true : false,
          onClick: (e) => {
            activateRecord(cellData);
          },
        }}
      />
    );
  }

  async function switchOrg(selectedOrg, body) {
    setIsProcessing(true);
    await api
      .postService(`organization/switchOrg/${selectedOrg}`, body)
      .then(async (response) => {
        // resp.Success(response?.data?.message);
        console.log("response:", response);

        const data = response.data;
        const authToken = data?.token;
        // Storing Token
        let currentUserData = { ...data.data };
        currentUserData["token"] = authToken;
        await storageServices.setCurrentUser(currentUserData);
        await setLocalUser(currentUserData);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  async function reloadData(newData) {
    // let newState = { show: false, title: "", action: "", id: "" };
    // setModal(newState);
    // loadData();
    // Reloading data due to branch update in local storage
    // window.location.reload();
    console.log("newData:", newData);

    if (module_action === "initial_setup") {
      await switchOrg(newData?._id, localUser);
      await reloadElements();
      window.location.href = "/admin#/user_management/users/initial_setup";
      window.location.reload();
    } else {
      window.location.reload();
    }
  }

  async function reloadElements() {
    return <AdminLayout reload={true} />;
  }
  // Create New Record
  async function addRecord() {
    console.log("add");
    let newState = {
      show: true,
      title: `Add Branch`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(data, row) {
    console.log("row data:", data);
    let newState = {
      show: true,
      title: `View  Branch`,
      action: "view",
      id: data?._row?.data?._id,
    };
    setModal(newState);
  }
  // Edit Record
  async function editRecord(data) {
    let newState = {
      show: true,
      title: `Edit Branch`,
      action: "edit",
      id: data?._id,
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to suspend this branch?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }
  // RE-ACTIVATION
  function activateRecord(data) {
    alert.confirmActivation({
      text: "Are you sure want to activate this branch ?",
      onConfirm: function () {
        onActivate(data);
      },
    });
  }
  ////// COMMON FUNCTIONS //////

  function onActivate(data) {
    setIsProcessing(true);
    var query = {
      status: "active",
    };
    api
      .putService(`organization/${data._id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`organization/${data?._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  return (
    <>
      <>
        <Container>
          {/* <!-- BEGIN: Modal --> */}
          {modal.show && (
            <FormMaster
              show={modal.show}
              action={modal.action}
              title={modal.title}
              onHide={closeModal}
              callBack={reloadData}
              id={modal.id}
            />
          )}
          {/* <!-- END: Modal --> */}

          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title>Manage Branches</Container.Title>
            <Container.HeaderActions>
              {" "}
              <a
                className="btn btn-primary shadow-md mr-2"
                href={void 0}
                onClick={addRecord}
              >
                Add New
              </a>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing ? (
              <ProcessLoader />
            ) : (
              <CustomDataTable
                header={tableData.header}
                content={tableData.content}
                onRowClick={(e, row) => {
                  viewRecord(row);
                }}
                filter={true}
                filterFields={tableData.filterFields}
              />
            )}
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
