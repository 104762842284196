import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
// HTML Components
import { Modal } from "react-bootstrap";
import ImageNFileInput from "../inputs/imagenfile";
import ProfileImage from "../inputs/profileImage";
import DividerText from "../htmlElements/dividerText";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

// Functions
import { resp } from "../../functions/responseHandler";
import { storageServices } from "../../functions/storage";
import {
  formatTypeSearch,
  toFloat,
  JsonToFormDataObject,
  matchData,
} from "../../functions/common";
import * as api from "../../api_service/api";

const fileBaseURL = process.env.REACT_APP_DRIVER_URL;

// State Model
const Model = {
  phone: "",
  fullname: "",
  email: "",
  role: [],
  organization: [],
  activated: false,
  application_user: false,
  designation: "",
  status: "active",
  display_picture: "",
  employee_type: "",

  dob: "",
  license_proof: "",
  valid_from: "",
  valid_to: "",
  license_no: "",
  approved: false,
  is_employee: true,
  new_user: false,

  preferences: {
    default_organization: "",
  },
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);

  const [mode, setMode] = useState({ view: false, edit: false, create: false });
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [originalData, setOriginalData] = useState();
  const [record_id, setRecordId] = useState();
  const [localUser, setLocalUser] = useState();
  // Default State
  const [defaultState, setDefaultState] = useState({
    role: [],
    organization: [],
    designation: [],
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    handleUserAction(props);
  }, []);

  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setState(Model);
        setMode((prevState) => ({ ...prevState, create: true }));
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  // Default Metrics

  async function loadOrganization() {
    // event.preventDefault();
    if (defaultState?.organization?.length == 0) {
      if (localUser?.role?.is_owner) {
        setIsProcessing(true);
        await api
          .getService("organization")
          .then(async (response) => {
            await setDefaultState((prevState) => ({
              ...prevState,
              organization: response?.data?.data,
            }));
            setIsProcessing(false);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        await setDefaultState((prevState) => ({
          ...prevState,
          organization: state?.organization,
        }));
      }
    }
  }

  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`user/${id}`).then(
      async (result) => {
        console.log("result:", result);
        const data = result.data;

        let matchedData = await matchData(state, { ...data.data });
        setState(matchedData);
        setOriginalData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  function mapData(data) {
    data["current_balance"] = toFloat(
      data?.opening_balance || 0 + data?.current_total || 0
    );
    return data;
  }

  async function prepareData(data, action) {
    data.designation = data?.designation?._id;
    data.organization = await data?.organization.map((a) => a?._id);

    data.role = await data?.role.map((a) => a?._id);

    // Deleting designation if not set
    if (typeof data?.designation === "undefined" || data?.designation === "") {
      delete data?.designation;
    }

    if (
      typeof data?.preferences?.default_organization !== "undefined" &&
      data.preferences.default_organization !== ""
    ) {
      data.preferences.default_organization =
        data?.preferences?.default_organization?._id;
    }
    return data;
  }
  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var data = await prepareData({ ...state }, "create");
    console.log("data:", data);

    var query = await JsonToFormDataObject(data);

    api
      .DatapostService("user/create", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var data = await prepareData({ ...state }, "update");

    console.log("data:", data);

    var query = await JsonToFormDataObject(data);

    api
      .DataputService(`user/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function changeInput(event) {
    const { name, value, checked } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name == "application_user") {
      setState((prevState) => ({ ...prevState, [name]: !state[name] }));
    }
  }

  // Input Change
  function changeDefaultOrganization(selected, name) {
    let prevState = { ...state };
    if (selected.length != 0) {
      prevState["preferences"]["default_organization"] = selected[0];
    } else {
      prevState["preferences"]["default_organization"] = "";
    }

    setState(prevState);
  }

  function changeTypeHeadInputMultiple(selected, name) {
    setState((prevState) => ({ ...prevState, [name]: selected }));
  }

  function deleteInputFile(event, name) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [name]: "" }));
  }

  async function changeInputFile(event) {
    const { name, files } = event.target;
    if (files[0]) {
      setState((prevState) => ({ ...prevState, [name]: files[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  // UI
  return (
    <>
      <div
        key="profile_modal"
        id="profile_modal"
        // onClick={(e) => e.stopPropagation()}
      >
        {/* <!-- BEGIN: Modal Content --> */}
        <Modal
          {...props}
          onHide={props.onHide}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <h2 className="font-medium text-base mr-auto">{props.title}</h2>
          </Modal.Header>

          <Modal.Body>
            {isProcessing && <ProcessLoader />}
            <form
              className="grid grid-cols-12 gap-4 gap-y-3"
              onSubmit={
                userAction === "create"
                  ? onSubmit
                  : userAction === "edit"
                  ? onUpdate
                  : (e) => e.preventDefault()
              }
            >
              <div className="col-span-12">
                <ProfileImage
                  mode={mode}
                  state={state?.display_picture}
                  name="display_picture"
                  onDelete={deleteInputFile}
                  onChange={changeInputFile}
                  className="text-center mb-2"
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="name" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  name="fullname"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.fullname}
                  required
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state.phone}
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="supplier_type" className="form-label">
                  Branch
                </label>

                <div className="form-control">
                  {Array.prototype.map
                    .call(state?.organization, function (item) {
                      return item?.name;
                    })
                    .join(",")}
                </div>
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="designation" className="form-label">
                  Designation
                </label>
                <div className="form-control">{state?.designation?.name}</div>
              </div>

              {state.application_user && (
                <>
                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.email}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="supplier_type" className="form-label">
                      User Role
                    </label>
                    <div className="form-control">
                      {Array.prototype.map
                        .call(state?.role, function (item) {
                          return item?.name;
                        })
                        .join(",")}
                    </div>
                  </div>
                </>
              )}

              {/* <!-- BEGIN: Driver details -- > */}
              {state?.designation?.employee_type === "driver" && (
                <>
                  <div className="col-span-12">
                    <DividerText>License Information</DividerText>
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label className="control-label">D.O.B</label>
                    <input
                      type="date"
                      className="form-control"
                      id="dob"
                      name="dob"
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.dob}
                      required
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label className="control-label">License number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="license_no"
                      name="license_no"
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.license_no}
                      required
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label className="control-label">Valid From</label>
                    <input
                      type="date"
                      className="form-control"
                      id="valid_from"
                      name="valid_from"
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.valid_from}
                      required
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label className="control-label">Valid To</label>
                    <input
                      type="date"
                      className="form-control"
                      id="valid_to"
                      name="valid_to"
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.valid_to}
                      required
                    />
                  </div>

                  {/* License Image */}
                  <div className="col-span-12 form-group w-full justify-center">
                    <label className="control-label" htmlFor="license_proof">
                      License proof
                    </label>
                    <ImageNFileInput
                      mode={mode}
                      state={state?.license_proof}
                      name="license_proof"
                      onDelete={deleteInputFile}
                      onChange={changeInputFile}
                      baseURL={fileBaseURL}
                    />
                  </div>
                  {/* License Image */}
                </>
              )}

              {/* <!-- END: Driver details -- > */}

              {/* <!-- BEGIN: Preferences -- > */}

              <div className="col-span-12">
                <DividerText>Preferences</DividerText>
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label className="form-label">Default Organization</label>
                <Typeahead
                  clearButton
                  id="default_organization"
                  name="default_organization"
                  onFocus={loadOrganization}
                  onChange={(selected) => {
                    changeDefaultOrganization(selected, "default_organization");
                  }}
                  labelKey={(option) => `${option?.name}`}
                  options={defaultState?.organization}
                  inputProps={{ required: true }}
                  selected={formatTypeSearch(
                    state?.preferences?.default_organization
                  )}
                  placeholder="Select Organization"
                  disabled={mode.view}
                />
              </div>

              {/* <!-- END: Preferences -- > */}

              <input type="submit" id="save_profile" className="hidden" />
              <input type="submit" id="update_profile" className="hidden" />
            </form>
          </Modal.Body>
          {/* <!-- END: Modal Body --> */}
          {/* <!-- BEGIN: Modal Footer --> */}
          <Modal.Footer>
            {" "}
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-outline-secondary w-20 mr-1"
              onClick={props.onHide}
            >
              Cancel
            </button>{" "}
            {userAction === "create" && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="save_profile"
              >
                Save
              </label>
            )}
            {userAction === "edit" && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="update_profile"
              >
                Update
              </label>
            )}
          </Modal.Footer>
          {/* <!-- END: Modal Footer --> */}
        </Modal>

        {/* <!-- END: Modal Content --> */}
      </div>
    </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "My Profile",
  action: "view",
  onSave() {},
  callBack() {},
};
