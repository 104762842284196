export function Model() {
  // Custom Model
  return {
    overdue_invoices: {
      count: 0,
      amount_received: "",
      outstanding_balance: "",
    },
    client_agreements: { expired: 0, expiring: 0 },
    driver_licenses: { expired: 0, expiring: 0 },
    vehicles_count: { own: 0, supplier: 0 },
    top_clients: [],
    vehicle_expiry: {
      fc: { "10days": 0, "30days": 0, "60days": 0, "90days": 0 },
      insurance: { "10days": 0, "30days": 0, "60days": 0, "90days": 0 },
      goods: { "10days": 0, "30days": 0, "60days": 0, "90days": 0 },
    },
    consignment_status: {
      pending: 0,
      in_progress: 0,
      completed: 0,
      acknowledged: 0,
      invoiced: 0,
    },
    invoice_status: {
      waiting_for_approval: 0,
      approved: 0,
      invoiced: 0,
      submitted: 0,
      acknowledged: 0,
    },

    in_transit_consignments: [],
    organization_id: "",
    // invoices: {
    //   // Consignment
    //   acknowledged: { count: 0, url: "" },
    //   // Invoices
    //   waiting_for_approval: { count: 0, url: "" },
    //   approved: { count: 0, url: "" },
    //   invoiced: { count: 0, url: "" },
    //   submitted: { count: 0, url: "" },
    // },
    // invoice_due: 0,
    // party_expired: 0,
    // party_expiring: 0,
    // expiring_driver_license: 0,
    // expired_driver_license: 0,
    // vehicle: [{ goods: 0, insurance: 0, fc: 0 }],
    // consignment_status_days: "90",
    // organization_id: "",
  };
}
