// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// LOADERS
import ProcessLoader from "../preloader/processLoader";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";
import CurrencyInput from "../htmlElements/currencyInput";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeAhAddNewButton from "../htmlElements/typeAhAddNewButton";

// External Components
import SupplierMasterForm from "../master/supplier";
import CashMasterForm from "../master/accounts/cash";
import BankMasterForm from "../master/accounts/bank";

// FUNCTIONS
import { resp } from "../../functions/responseHandler";
import {
  toFloat,
  htmlDate,
  matchData,
  isDefined,
  formatTypeSearch,
} from "../../functions/common";
import * as api from "../../api_service/api";

const Model = {
  _id: "",
  date: "",
  transaction_type: "",
  transaction_context: "direct",
  payment_mode: "",
  payment_cash: "",
  payment_bank: "",
  petrolbunk: "",

  payment_from_type: "",
  payment_to_type: "",
  consignment: "",
  supplier: "",
  client: "",
  transaction_amount: "",
  remarks: "",
  payment_reference_number: "",
  organization: "",
  //Flags
  isNew: false,
  isUpdated: false,
  isDeleted: false,
};

export default function Component(props) {
  // Defaults
  const [pageTitle, setPageTitle] = useState("Payment");
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });
  // State
  const [state, setState] = useState({ ...Model });
  const [defaultState, setDefaultState] = useState({
    petrol_bunk: [],
    bank: [],
    cash: [],
  });

  const [supplierMasterModal, setSupplierMasterModal] = useState({
    show: false,
    title: "Add Supplier",
    action: "create",
    id: "",
    supplier_type: "",
  });

  const [cashMasterModal, setCashMasterModal] = useState({
    show: false,
    title: "Add Cash Book",
    action: "create",
    id: "",
  });

  const [bankMasterModal, setBankMasterModal] = useState({
    show: false,
    title: "Add Bank Account",
    action: "create",
    id: "",
  });

  const [consData, setConsData] = useState();

  const [localState, setLocalState] = useState({
    //
    supplier_max_limit: 0,
    client_max_limit: 0,
    disableSupplierPayment: false,
    disableClientPayment: false,
  });

  const [record_id, setRecordId] = useState();

  // <!-- BEGIN: Petrol Master Modal -- >
  function addSupplierMaster(supplier_type) {
    let prevState = { ...supplierMasterModal };
    prevState.supplier_type = supplier_type;
    prevState.action = "create";
    prevState.show = true;
    setSupplierMasterModal(prevState);
  }
  const closeSupplierMasterModal = () =>
    setSupplierMasterModal((prevState) => ({ ...prevState, show: false }));

  function reloadSupplierMasterData(data, supplier_type) {
    closeSupplierMasterModal();
    let event = {};
    loadDefaultMetrics(event, supplier_type, true);
  }
  // <!-- END: Petrol Master Modal -- >

  // <!-- BEGIN: Cash Master Modal -- >
  const openCashMasterModal = () =>
    setCashMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeCashMasterModal = () =>
    setCashMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadCashMasterData(data) {
    closeCashMasterModal();
    let event = {};
    loadDefaultMetrics(event, "cash", true);
  }
  // <!-- END: Cash Master Modal -- >

  // <!-- BEGIN: Bank Master Modal -- >
  const openBankMasterModal = () =>
    setBankMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeBankMasterModal = () =>
    setBankMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadBankMasterData(data) {
    closeBankMasterModal();
    let event = {};
    loadDefaultMetrics(event, "bank", true);
  }
  // <!-- END: Bank Master Modal -- >

  useEffect(() => {
    handleUserAction(props);
  }, []);
  // <!--- BEGIN: FUNCTIONS -->
  async function handleUserAction(props) {
    setConsData(props?.consData);
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setPageTitle("Add Payment");
        setDefaults(props);
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        setPageTitle("Edit Payment");
        loadData(props);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        setPageTitle("View Payment");
        loadData(props);
        break;
      default:
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };
    prevState.date = htmlDate(new Date());
    prevState.payment_mode = "cash";
    prevState["isNew"] = true;
    prevState["organization"] = props?.organizationId;
    prevState["client"] = props?.consData?.client?._id;
    prevState["consignment"] = props?.consId;

    prevState["supplier"] = props?.consData?.supplier?._id || "";

    // Enable Supplier Payment
    if (!props?.disableSupplierPayment) {
      prevState.transaction_type = "payable";
      prevState.payment_from_type = "self";
      prevState.payment_to_type = "supplier";
      prevState.transaction_amount = toFloat(props?.supplier_max_limit || 0);
    }
    // Enable Client Payment
    else if (!props?.disableClientPayment) {
      prevState.transaction_type = "receivable";
      prevState.payment_from_type = "client";
      prevState.payment_to_type = "self";
      prevState.transaction_amount = toFloat(props?.client_max_limit || 0);
    }

    setState(prevState);

    // Setting Local State
    let prevLocalState = { ...localState };
    prevLocalState.supplier_max_limit = toFloat(props?.supplier_max_limit || 0);
    prevLocalState.client_max_limit = toFloat(props?.client_max_limit || 0);
    prevLocalState.disableSupplierPayment = props?.disableSupplierPayment;
    prevLocalState.disableClientPayment = props?.disableClientPayment;
    setLocalState(prevLocalState);

    setIsProcessing(false);
    return null;
  }

  function mapData(data) {
    return data;
  }

  function prepareData() {
    let data = {
      _id: state?._id,
      date: state?.date,
      consignment: state?.consignment,
      transaction_type: state?.transaction_type,
      transaction_context: state?.transaction_context,
      payment_mode: state?.payment_mode,
      payment_from_type: state?.payment_from_type,
      payment_to_type: state?.payment_to_type,
      transaction_amount: state?.transaction_amount,
      remarks: state?.remarks,
      payment_reference_number: state?.payment_reference_number,
      status: "active",

      isNew: state?.isNew,
      isUpdated: state?.isUpdated,
      isDeleted: state?.isDeleted,
    };

    // Adding Supplier
    if (data?.transaction_type === "payable") {
      data["supplier"] = consData?.supplier?._id;
    }
    // Adding Client
    if (data?.transaction_type === "receivable") {
      data["client"] = consData?.client?._id;
    }
    // Payment Mode
    // Cash
    if (data.payment_mode === "cash" && isDefined(state?.payment_cash)) {
      // data["payment_cash"] = state?.payment_cash?._id;
      data["payment_cash"] = state?.payment_cash;
    }
    // Bank
    if (data.payment_mode === "bank" && isDefined(state?.payment_bank)) {
      // data["payment_bank"] = state?.payment_bank?._id;
      data["payment_bank"] = state?.payment_bank;
    }
    // Petrol Bunk
    if (data.payment_mode === "journal" && isDefined(state?.petrolbunk)) {
      // data["petrolbunk"] = state?.petrolbunk?._id;
      data["petrolbunk"] = state?.petrolbunk;
    }

    return data;
  }

  async function loadDefaultMetrics(event, key, reload = false) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (defaultState[key].length === 0 || reload) {
      switch (key) {
        case "petrol_bunk":
          url = `master/supplier/type/petrol_bunk/active`;
          await fetchDefaultMetrics(url, key);
          break;

        case "cash":
          url = "master/cash";
          await fetchDefaultMetrics(url, key);
          break;

        case "bank":
          url = "master/bank";
          await fetchDefaultMetrics(url, key);
          break;

        default:
          break;
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: response?.data?.data,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // <!--- END: FUNCTIONS -->

  // <!--- BEGIN: FORM CHANGE FUNCTIONS -->
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function changePaymentTo(event, party) {
    let prevState = { ...state };
    if (party === "supplier") {
      prevState.transaction_type = "payable";
      prevState.payment_from_type = "self";
      prevState.payment_to_type = "supplier";
      prevState.transaction_amount = localState?.supplier_max_limit;
    }

    if (party === "client") {
      prevState.transaction_type = "receivable";
      prevState.payment_from_type = "client";
      prevState.payment_to_type = "self";
      prevState.transaction_amount = localState?.client_max_limit;
    }

    setState(prevState);
  }

  // <!--- END: FORM CHANGE FUNCTIONS -->

  // <!--- BEGIN: API CALLS -->

  async function loadExpenseType(event) {
    event.preventDefault();
  }

  async function loadData(props) {
    let data = props?.data;
    setRecordId(props?.index);

    let matchedData = await matchData(state, data);
    matchedData["date"] = htmlDate(matchedData["date"]);

    setState(matchedData);

    // Setting Local State
    let prevLocalState = { ...localState };
    prevLocalState.supplier_max_limit = toFloat(props?.supplier_max_limit || 0);
    prevLocalState.client_max_limit = toFloat(props?.client_max_limit || 0);
    prevLocalState.disableSupplierPayment = props?.disableSupplierPayment;
    prevLocalState.disableClientPayment = props?.disableClientPayment;
    setLocalState(prevLocalState);

    // api.getService(`transaction/${id}`).then(
    //   async (result) => {
    //     const data = result.data;
    //     let mappedData = await mapData(data.data);
    //     console.log("data:", data);
    //     setState(mappedData);
    //     setIsProcessing(false);
    //   },
    //   (error) => {
    //     console.log("error", error);
    //     resp.ErrorHandler(error);
    //     setIsProcessing(false);
    //   }
    // );
  }

  async function onSubmit(event) {
    event.preventDefault();

    var data = await prepareData({ ...state });

    props.callBack(data, state?.transaction_type, "create", record_id);

    // api
    //   .postService("transaction", query)
    //   .then((response) => {
    //     setIsProcessing(false);
    //     resp.Success(response?.data?.message);
    //     props.callBack();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setIsProcessing(false);
    //     resp.ErrorHandler(error);
    //   });
  }

  async function onUpdate(event) {
    event.preventDefault();

    var data = prepareData({ ...state });

    props.callBack(data, state?.transaction_type, "update", record_id);

    // api
    //   .putService(`transaction/${record_id}`, query)
    //   .then((response) => {
    //     setIsProcessing(false);
    //     resp.Success(response?.data?.message);
    //     props.callBack();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setIsProcessing(false);
    //     resp.ErrorHandler(error);
    //   });
  }

  // <!--- END: API CALLS -->

  return (
    <div
      key="consignment_payment_modal"
      id="consignment_payment_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
        </Modal.Header>

        <Modal.Body>
          {/* <!-- BEGIN: External Components */}
          {!mode.view && supplierMasterModal.show && (
            <SupplierMasterForm
              show={supplierMasterModal.show}
              action={supplierMasterModal.action}
              supplier_type={supplierMasterModal.supplier_type}
              title={supplierMasterModal.title}
              onHide={closeSupplierMasterModal}
              callBack={reloadSupplierMasterData}
            />
          )}

          {!mode.view && cashMasterModal.show && (
            <CashMasterForm
              show={cashMasterModal.show}
              action={cashMasterModal.action}
              title={cashMasterModal.title}
              onHide={closeCashMasterModal}
              callBack={reloadCashMasterData}
            />
          )}

          {!mode.view && bankMasterModal.show && (
            <BankMasterForm
              show={bankMasterModal.show}
              action={bankMasterModal.action}
              title={bankMasterModal.title}
              onHide={closeBankMasterModal}
              callBack={reloadBankMasterData}
            />
          )}
          {/* <!-- END: External Components */}
          {isProcessing && <ProcessLoader />}

          <form
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            {/* <!--BEGIN: Tab Select --> */}
            {mode?.create && (
              <div className="col-span-12">
                <div
                  className="nav nav-tabs flex-col sm:flex-row justify-center w-full"
                  role="tablist"
                >
                  {" "}
                  {!props?.disableClientPayment && (
                    <a
                      id="client_payment-tab"
                      data-toggle="tab"
                      data-target="#client_payment"
                      href={void 0}
                      class={
                        state?.payment_from_type === "client" &&
                        state?.payment_to_type === "self"
                          ? "mx-3 text-theme-1 active cursor-pointer"
                          : "mx-3 cursor-pointer"
                      }
                      role="tab"
                      aria-controls="client_payment"
                      aria-selected="false"
                      aria-current={localState?.disableClientPayment}
                      disabled={localState?.disableClientPayment}
                      onClick={(e) =>
                        localState?.disableClientPayment
                          ? e.preventDefault()
                          : changePaymentTo(e, "client")
                      }
                    >
                      Client Payment
                    </a>
                  )}
                  {!props?.disableSupplierPayment && (
                    <a
                      id="supplier_payment-tab"
                      data-toggle="tab"
                      data-target="#supplier_payment"
                      href={void 0}
                      class={
                        state?.payment_from_type === "self" &&
                        state?.payment_to_type === "supplier"
                          ? "mx-3 text-theme-1 active cursor-pointer"
                          : "mx-3 cursor-pointer"
                      }
                      role="tab"
                      aria-selected="false"
                      aria-current={localState?.disableSupplierPayment}
                      onClick={(e) =>
                        localState?.disableSupplierPayment
                          ? e.preventDefault()
                          : changePaymentTo(e, "supplier")
                      }
                      disabled={localState?.disableSupplierPayment}
                    >
                      Supplier Payment
                    </a>
                  )}
                </div>
              </div>
            )}
            {/* <!--END: Tab Select --> */}

            <div className="col-span-12">
              <label htmlFor="name" className="form-label required">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.date}
                defaultValue={htmlDate(new Date())}
                required
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="name" className="form-label required">
                Amount
              </label>
              <CurrencyInput
                type="number"
                className="form-control"
                id="transaction_amount"
                name="transaction_amount"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.transaction_amount}
                required
              />
            </div>
            <div className="col-span-12">
              <div className="mt-3">
                {" "}
                <label>Payment Mode</label>
                <div className="flex flex-col sm:flex-row mt-2">
                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="payment_mode_cash"
                      className="form-check-input"
                      type="radio"
                      name="payment_mode"
                      value="cash"
                      checked={state?.payment_mode === "cash"}
                      onChange={changeInput}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="payment_mode_cash"
                    >
                      Cash
                    </label>{" "}
                  </div>

                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="payment_mode_bank"
                      className="form-check-input"
                      type="radio"
                      name="payment_mode"
                      value="bank"
                      checked={state?.payment_mode === "bank"}
                      onChange={changeInput}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="payment_mode_bank"
                    >
                      Bank
                    </label>{" "}
                  </div>

                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="payment_mode_journal"
                      className="form-check-input"
                      type="radio"
                      name="payment_mode"
                      value="journal"
                      checked={state?.payment_mode === "journal"}
                      onChange={changeInput}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="payment_mode_journal"
                    >
                      Fuel
                    </label>{" "}
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- BEGIN: Payment Mode Options--> */}
            <>
              {state?.payment_mode === "cash" && (
                <div className="col-span-12">
                  <label htmlFor="cash_master" className="form-label">
                    Cash Book{" "}
                    <small className=" text-gray-500">(Optional)</small>
                  </label>
                  <Typeahead
                    id="cash_master"
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "cash");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "payment_cash");
                    }}
                    options={defaultState?.cash}
                    labelKey={(option) => `${option?.name}`}
                    selected={formatTypeSearch(state?.payment_cash)}
                    placeholder="Select Cash Book..."
                    disabled={mode.view}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        <TypeAhAddNewButton
                          onClick={openCashMasterModal}
                        ></TypeAhAddNewButton>
                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={index}
                          >
                            {result?.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />
                </div>
              )}

              {state?.payment_mode === "bank" && (
                <div className="col-span-12">
                  <label htmlFor="bank_master" className="form-label">
                    Bank Account{" "}
                    <span className=" text-gray-500">(Optional)</span>
                  </label>
                  <Typeahead
                    id="bank_master"
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "bank");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "payment_bank");
                    }}
                    options={defaultState?.bank}
                    labelKey={(option) => `${option?.name}`}
                    selected={formatTypeSearch(state?.payment_bank)}
                    placeholder="Select Bank A/C..."
                    disabled={mode.view}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        <TypeAhAddNewButton
                          onClick={openBankMasterModal}
                        ></TypeAhAddNewButton>
                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={index}
                          >
                            {result?.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />
                </div>
              )}

              {state?.payment_mode === "journal" && (
                <div className="col-span-12">
                  <label htmlFor="petrol_bunk" className="form-label">
                    Petrol Bunk{" "}
                    <span className=" text-gray-500">(Optional)</span>
                  </label>
                  <Typeahead
                    id="petrol_bunk"
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "petrol_bunk");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "petrolbunk");
                    }}
                    options={defaultState?.petrol_bunk}
                    labelKey={(option) => `${option?.name}`}
                    selected={formatTypeSearch(state?.petrolbunk)}
                    placeholder="Select Petrol Bunk..."
                    disabled={mode.view}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        <TypeAhAddNewButton
                          onClick={(e) => addSupplierMaster("petrol_bunk")}
                        ></TypeAhAddNewButton>
                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={index}
                          >
                            {result?.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />
                </div>
              )}
            </>
            {/* <!-- END: Payment Mode Options--> */}

            <div className="col-span-12">
              <label htmlFor="payment_reference_number" className="form-label">
                {state?.payment_mode === "cash"
                  ? "Voucher No."
                  : state.payment_mode === "bank"
                  ? "Transaction Id"
                  : "Reference No."}
              </label>
              <input
                type="text"
                className="form-control"
                id="payment_reference_number"
                name="payment_reference_number"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.payment_reference_number}
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="remarks" className="form-label">
                Remarks
              </label>
              <textarea
                type="text"
                className="form-control"
                id="remarks"
                name="remarks"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.remarks}
              />
            </div>

            <input type="submit" id="save_cons_payment" className="hidden" />
            <input type="submit" id="update_cons_payment" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" &&
            (!localState?.disableClientPayment ||
              !localState?.disableSupplierPayment) && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="save_cons_payment"
              >
                Save
              </label>
            )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_cons_payment"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Payment",
  action: "view",
  onSave() {},
  callBack() {},
};
