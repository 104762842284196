import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import options from "./data";
import "react-bootstrap-typeahead/css/Typeahead.css";

import Toaster from "../../components/notification/toaster";

export default function SamplePage() {
  const [selected, setSelected] = useState([]);

  function showToaster() {
    Toaster({
      type: "success",
      position: "topRight",
      text: "Testing message",
    });
  }


  return (
    <>
       <button
          className="btn btn-primary w-24 mr-1 mb-2"
          onClick={showToaster}
        >
          Show Toaster
        </button>
    <Typeahead
      id="basic-example"
      onChange={setSelected}
      options={options}
      placeholder="Choose a state..."
      selected={selected}
    />
    </>
  );
}
