import React, { useState, useEffect } from "react";
import { storageServices } from "../../functions/storage";
import { matchData } from "../../functions/common";
import * as api from "../../api_service/api";
import jwt_decode from "jwt-decode";

import { Dropdown } from "react-bootstrap";

import SwitchOrg from "../switchOrganization";
// import AppsSwitcher from "../appsSwitcher";
import ProfileForm from "../profile";
import { ReactComponent as IconHelp } from "../../assets/images/icons/help-circle.svg";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const fileURI = process.env.REACT_APP_FILE_URL;

export function TopBar(props) {
  const { localUser, handleToggleSidebar, menuLayout, onChangeMenuLayout } =
    props;
  const [nav, setNav] = useState([]);

  // Switch Org Modal
  const [switchOrgModal, setSwitchOrgModal] = useState(false);
  const closeSwitchOrgModal = () => setSwitchOrgModal(false);
  const openSwitchOrgModal = () => setSwitchOrgModal(true);

  // Profile Modal
  const [profileModal, setProfileModal] = useState(false);
  const closeProfileModal = () => setProfileModal(false);
  const openProfileModal = () => setProfileModal(true);

  useEffect(() => {}, []);

  function clock() {
    var clock = document.getElementById("live_clock");

    var h = new Date().getHours();
    var m = new Date().getMinutes();
    var s = new Date().getSeconds();
    var am = "AM";

    if (h > 12) {
      h = h - 12;
      var am = "PM";
    }

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    let currentTime = h + ":" + m + ":" + s + " " + am;

    try {
      clock.innerHTML = currentTime;
    } catch (e) {}

    return;
  }

  setInterval(clock, 1000);

  function switchOrganization(event) {
    event.preventDefault();
    const { name, value } = event.target;

    api.getService(`organization/switchOrg/${value}`).then(
      async (result) => {
        const data = result.data;
        let authToken = data?.token;
        var decoded = jwt_decode(data?.token);
        decoded["token"] = authToken;

        let currentUserData = await storageServices.getCurrentUser();
        let matchedData = await matchData(currentUserData, decoded);
        await storageServices.setCurrentUser(matchedData);
        window.location.reload();
      },

      (error) => {
        console.log("error loading branches.", error);
      }
    );
  }

  async function logOut() {
    cookies.remove("welcome_toast");
    await storageServices.resetCurrentUser();
    window.location.href = "login";
  }

  async function switchOrgCallback() {
    window.location.reload();
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href={void 0}
      className="cursor-pointer text-xl"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  return (
    <>
      {/* Switch Org Modal */}

      {switchOrgModal && (
        <SwitchOrg
          close_button={true}
          show={switchOrgModal}
          onHide={closeSwitchOrgModal}
          branch_organizations={localUser?.branch_organizations}
          default_organization={localUser?.default_organization}
          selected_organization={localUser?.organization_id}
          callBack={switchOrgCallback}
          localUserData={localUser}
        />
      )}

      {profileModal && (
        <ProfileForm
          show={profileModal}
          action="edit"
          title="My Profile"
          onHide={closeProfileModal}
          callBack={closeProfileModal}
          id={localUser?.userId}
        />
      )}

      {/* <!-- BEGIN: Top Bar --> */}
      {/* border-theme-29 */}
      <div className="px-3  md:pt-0 top-bar-bg">
        <div className="top-bar-boxed flex items-center">
          {/* <!-- BEGIN: Logo --> */}
          {/* Old Class: hidden md:flex */}
          <div className="flex items-center">
            <img alt="CoreLogis" className="w-28" src="/images/logo-main.png" />
            {menuLayout === "sidemenu" && (
              <a
                className="ml-3 cursor-pointer text-white"
                type="button"
                href={void 0}
                onClick={handleToggleSidebar}
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </a>
            )}

            {/* <img
              alt="Rubick Tailwind HTML Admin Template"
              className="w-6"
              src="images/logo.svg"
            />
            <span className="text-white text-lg ml-3">
              {" "}
              Core<span className="font-medium">Logis</span>{" "}
            </span> */}
          </div>
          {/* <!-- END: Logo --> */}
          {/* <!-- BEGIN: Breadcrumb --> */}
          <div className="hidden sm:flex breadcrumb breadcrumb--light mr-auto">
            {/* <img alt="" className="w-6" src="images/logo.svg" /> */}
            <div className="font-medium text-gray-100 truncate text-lg sm:text-base">
              {localUser?.customer_account?.name}
            </div>{" "}
            {/* {localUser?.organization?.name && (
              <span className="ml-2 text-gray-200 break-all">
                - {localUser?.organization?.name}
              </span>
            )} */}
            {/* <a href={void 0}>Application</a>{" "} */}
            {/* {nav.map((item, index) => {
              return (
                <>
                  <i
                    data-feather="chevron-right"
                    className="breadcrumb__icon"
                  ></i>{" "}
                  <a
                    href={item?.active ? void 0 : item?.href}
                    className={item?.active ? "breadcrumb--active" : ""}
                  >
                    {item?.title}
                  </a>{" "}
                </>
              );
            })} */}
            {/* <a href="" className="breadcrumb--active">
              Dashboard
            </a>{" "} */}
          </div>
          {/* <!-- END: Breadcrumb --> */}

          <div className="flex items-center sm:ml-auto">
            <a
              className="cursor-pointer flex items-center text-white mr-3"
              title={"Help & Support"}
              href="https://corelogis.in/docs-category/app-guide/"
              target="_blanks"
            >
              <IconHelp width="18" height="18" />
            </a>
            {/* <!-- BEGIN: Org Switch --> */}
            {localUser?.branch_organizations?.length > 0 && (
              <div className="mr-4 sm:mr-6">
                <button
                  className="truncate dropdown-toggle border-b font-normal text-white"
                  onClick={openSwitchOrgModal}
                >
                  {localUser?.organization?.name}
                  <i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                </button>
              </div>

              // <div className="intro-x dropdown mr-4 sm:mr-6">
              //   <form className="search hidden sm:block">
              //     <select
              //       className="text-white form-select-sm w-36 bg-transparent border-0 border-b"
              //       id="exampleFormControlSelect1"
              //       name="switch_org"
              //       onChange={switchOrganization}
              //       value={localUser?.organization_id}
              //     >
              //       {localUser?.branch_organizations.map((item, i) => {
              //         return (
              //           <option className="text-gray-600" value={item._id}>
              //             {item.name}
              //           </option>
              //         );
              //       })}
              //     </select>
              //   </form>
              // </div>
            )}
            {/* <!-- END: Org Switch --> */}

            {/* <!-- BEGIN: Notifications --> */}
            {/* <div className="intro-x dropdown mr-4 sm:mr-6">
            <div
              className="dropdown-toggle notification notification--light notification--bullet cursor-pointer"
              role="button"
              aria-expanded="false"
            >
              {" "}
              <i
                data-feather="bell"
                className="notification__icon dark:text-gray-300"
              ></i>{" "}
            </div>
            <div className="notification-content pt-2 dropdown-menu">
              <div className="notification-content__box dropdown-menu__content box dark:bg-dark-6">
                <div className="notification-content__title">Notifications</div>
                <div className="cursor-pointer relative flex items-center ">
                  <div className="w-12 h-12 flex-none image-fit mr-1">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      className="rounded-full"
                      src="images/profile-4.jpg"
                    />
                    <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            {/* <!-- END: Notifications --> */}

            {/* <!-- BEGIN: Clock --> */}
            <div id="time" className="mr-4 sm:mr-6 text-white  sm:text-sm">
              <div id="live_clock"></div>
            </div>

            {/* <!-- BEGIN: Dropdown --> */}
            <Dropdown className="w-8 h-8">
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                <div
                  className="w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit"
                  role="button"
                  aria-expanded="false"
                >
                  {/* <img alt="" src="images/profile-1.jpg" /> */}
                  <img
                    alt=""
                    src={
                      localUser.profile_picture || "images/user-avatar-icon.png"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "images/user-avatar-icon.png";
                    }}
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="w-56">
                  <div className="box bg-theme-26 dark:bg-dark-6 text-white">
                    {/* <!-- BEGIN: User Details --> */}
                    <div className="p-4 border-b border-theme-27 dark:border-dark-3 flex flex-wrap gap-3">
                      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-white dark:bg-dark-1 bg-opacity-20 hover:bg-opacity-30 text-white">
                        {" "}
                        {/* <img src="images/user-avatar-icon.png" alt="" /> */}
                        <img
                          alt=""
                          src={
                            localUser?.profile_picture ||
                            "images/user-avatar-icon.png"
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "images/user-avatar-icon.png";
                          }}
                        />
                      </div>
                      <div className="mr-auto">
                        <div className="font-medium">{localUser?.fullname}</div>
                        <div className=" text-theme-28 mt-0.5 dark:text-gray-600">
                          {localUser?.designation?.name}
                        </div>
                      </div>
                    </div>
                    {/* <!-- END: User Details --> */}
                    <div className="p-2">
                      <a
                        href={void 0}
                        onClick={openProfileModal}
                        className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                      >
                        {" "}
                        <i
                          className="fa fa-user-o w-4 h-4 mr-2"
                          aria-hidden="true"
                        ></i>
                        Profile{" "}
                      </a>

                      {(localUser?.role?.is_owner ||
                        localUser?.role?.is_admin) && (
                        <a
                          href={"/admin#/my_organization"}
                          className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                        >
                          {" "}
                          <i
                            className="fa fa-briefcase w-4 h-4 mr-2"
                            aria-hidden="true"
                          ></i>
                          My Organization{" "}
                        </a>
                      )}

                      {(localUser?.role?.is_owner ||
                        localUser?.role?.is_admin) && (
                        <a
                          href={"/admin#/business_rules"}
                          className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                        >
                          {" "}
                          <i
                            className="fa fa-sliders w-4 h-4 mr-2"
                            aria-hidden="true"
                          ></i>
                          Business Rules
                        </a>
                      )}

                      <a
                        href={void 0}
                        className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                      >
                        {" "}
                        <i
                          className="fa fa-bars w-4 h-4 mr-2"
                          aria-hidden="true"
                        ></i>
                        Top Menu
                        <input
                          className="form-check-switch ml-auto"
                          id="menu_layout"
                          name="menu_layout"
                          type="checkbox"
                          onChange={(e) => {
                            onChangeMenuLayout(
                              menuLayout === "topmenu" ? "sidemenu" : "topmenu"
                            );
                          }}
                          checked={menuLayout === "topmenu"}
                          value={menuLayout === "topmenu"}
                        />
                      </a>

                      {/* {(localUser?.role?.is_owner ||
                        localUser?.role?.is_admin) && (
                        <a
                          href={"/admin#/subscriptions"}
                          className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                        >
                          {" "}
                          <i
                            className="fa fa-laptop w-4 h-4 mr-2"
                            aria-hidden="true"
                          ></i>
                          My Subscriptions{" "}
                        </a>
                      )} */}

                      {/* Mmanage Branches */}
                      {/* {localUser?.role?.is_admin && (
                      <a
                        href={"/admin#/branch"}
                        className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                      >
                        <i
                          className="fa fa-code-fork w-4 h-4 mr-2"
                          aria-hidden="true"
                        ></i>
                        Manage Branches{" "}
                      </a>
                    )} */}
                      {/* Mmanage Branches */}
                    </div>
                    <div className="p-2 border-t border-theme-27 dark:border-dark-3">
                      <a
                        href={void 0}
                        onClick={logOut}
                        className="flex items-center cursor-pointer block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                      >
                        {" "}
                        <i className="fa fa-sign-out w-4 h-4 mr-2"></i> Logout{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {/* <!-- END: Dropdown --> */}
          </div>
        </div>
      </div>
      {/* <!-- END: Top Bar --> */}
    </>
  );
}

export function setBreadCrumb() {
  TopBar({ breadcrumb: [{ title: "", active: true, href: "" }] });
  return "";
}
