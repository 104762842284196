import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "react-tabulator/lib/styles.css"; // default theme
// import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import { ReactTabulator } from "react-tabulator";

export default function CustomDataTable(props) {
  const {
    header,
    content,
    onRowClick,
    options,
    className,
    printTable,
    printHeader,
    printFooter,
    exportTable,
    search,
    filter,
    filterFields,
    downloadFileName,
    title,
    selectableRows,
    selectableRowsHighlight,
    onSelectedRowsChange,
    tableRef,
  } = props;

  useEffect(() => {}, []);

  return (
    <div className="box mt-5 p-2">
      <div className="overflow-x-auto scrollbar-hidden">
        {/*  <!-- BEGIN: Data Table -->  */}
        <ReactTabulator
          ref={tableRef}
          columns={header}
          data={content}
          options={{
            //Selectable Rows
            selectable: selectableRows,
            selectableCheck: selectableRowsHighlight,
            // selectableCheck: true,
            rowSelectionChanged: onSelectedRowsChange,
            movableRows: false,
            movableColumns: true,
            placeholder: "No records found",
            layout: "fitColumns",
            // layout:"fitDataFill",
            responsiveLayout: "collapse",
            pagination: "local",
            paginationSize: 10,
            paginationSizeSelector: [10, 20, 30, 40],
            downloadDataFormatter: (data) => data,
            downloadReady: (fileContents, blob) => blob,
            printAsHtml: true,
            printCopyStyle: true,
            printStyled: true,
            printFooter: printFooter,
            printHeader: `<h3>${printHeader}</h3>`,
          }}
          rowClick={onRowClick}
          className={className}
          tooltips={true}
          placeholder={"No data found."}
        />

        {/*  <!-- END: Data Table -->  */}
      </div>
    </div>
  );
}

CustomDataTable.propTypes = {
  header: PropTypes.array,
  content: PropTypes.array,
  filter: PropTypes.bool,
  printTable: PropTypes.bool,
  downloadFileName: PropTypes.string,
  printHeader: PropTypes.string,
  printFooter: PropTypes.string,
  exportTable: PropTypes.bool,
  filterFields: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.object,
  onRowClick: PropTypes.func,
  tableRef: PropTypes.any,
  selectableRows: PropTypes.bool,
  selectableRowsHighlight: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
};

CustomDataTable.defaultProps = {
  header: [],
  content: [],
  filter: true,
  search: true,
  printTable: false,
  selectableRows: false,
  exportTable: false,
  filterFields: [],
  title: "",
  printHeader: "",
  downloadFileName: "data",
  printFooter: "",
  className: "",
  onRowClick() {},
  selectableRowsHighlight() {
    return true;
  },
  onSelectedRowsChange() {},
  tableRef: React.createRef(),
};
