import React, { useState, useEffect } from "react";
import { formatCurrency, toFloat, trimConsNum } from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import { storageServices } from "../../functions/storage";

export default function Template(props) {
  const Title = "Consignment Report";
  const tableRef = React.createRef();
  const { data, filter } = props;
  const [state, setState] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [rawData, setRawData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData(data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);
    setRawData(data);
    let mappedData = await mapData(data);
    let tableData = await mapTableData(mappedData);
    setTableData(tableData);
    setIsProcessing(false);
  }

  function mapData(data) {
    return data;
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "date",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Cons. No.",
        field: "consignment_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 120,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return trimConsNum(cell?.getValue());
        },
      },
      {
        title: "Client",
        field: "client",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter:"textarea"
      },
      {
        title: "Vehicle",
        field: "vehicle_number",
        vertAlign: "middle",
        headerSort: false,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter:"textarea"
      },
      {
        title: "Vehicle Ownership",
        field: "vehicle_ownership_type",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Origin",
        field: "origin",
        vertAlign: "middle",
        headerSort: false,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter:"textarea"
      },
      {
        title: "Destination",
        field: "destination",
        vertAlign: "middle",
        headerSort: false,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter:"textarea"
      },
      {
        title: "Freight Amount",
        field: "freight",
        vertAlign: "middle",
        headerSort: true,
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Additions",
        field: "charges_addition",
        vertAlign: "middle",
        headerSort: false,
        formatter: function (cell) {
          return formatCurrency(calcCharges(cell.getValue()));
        },
      },
      {
        title: "Deductions",
        field: "consignment_deduction",
        vertAlign: "middle",
        headerSort: false,
        formatter: function (cell) {
          return formatCurrency(calcCharges(cell.getValue()));
        },
      },

      {
        title: "Total",
        field: "total",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function calcCharges(charges) {
    let amount = 0;
    for (var i in charges) {
      amount += toFloat(charges[i]?.amount || 0);
    }
    return amount;
  }

  function viewRecord(row) {}

  return (
    <>
      {/* <!-- BEGIN: Body --> */}
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Sum Values --> */}
        <div id="sum_values" className="w-full flex justify-end space-x-4">
          {/* <!-- BEGIN: Total Payment --> */}
          <div class="box px-4 py-1 bg-blue-100 my-2 flex items-center w-1/4 mr-2">
            <div class="ml-4 mr-auto">
              <div class="font-medium">Number of Consignments</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {rawData.length}
            </div>
          </div>
          {/* <!-- END: Total payment --> */}
        </div>

        {/* <!-- END: Sum Values --> */}

        {/* <!-- BEGIN: Data Table --> */}
        <ReportsDataTable
          title={Title}
          header={tableData.header}
          content={tableData.content}
          onRowClick={(e, row) => {
            viewRecord(row);
          }}
          tableRef={tableRef}
        />
        {/* <!-- END: Data Table --> */}
      </div>
    </>
  );
}
