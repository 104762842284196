import React from "react";
import PropTypes from "prop-types";
export default function AddNewButton(props) {
  const { className, type, id, name, onClick, icon, text, ...rest } = props;

  let buttonText = props.children ? props.children : text;
  return (
    <button id={id} type="button" className={className} onClick={onClick}>
      <i className={icon + " mr-2"} aria-hidden="true"></i>
      {buttonText}
    </button>
  );
}

AddNewButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

AddNewButton.defaultProps = {
  className: "btn btn-primary",
  type: "",
  id: "add-new-button",
  name: "",
  icon: "fa fa-plus",
  text: "Add New",
  onClick() {},
};
