import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Loaders
import InLineLoader from "../../components/preloader/inLineLoader";
// HTML Components
import { resp } from "../../functions/responseHandler";
import PhoneInput from "react-phone-input-2";
import ValidationError from "../../components/error/validationError";
// CSS File
import "react-phone-input-2/lib/style.css";
// import "./style.css";
// Functions
import * as api from "../../api_service/api";
import * as validation from "../../functions/validation";
// Models
import { Model, ValidationErrorModel } from "./model";

export default function Component(props) {
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  // State
  const [state, setState] = useState({ ...Model });
  const [validationError, setValidationError] = useState({
    ...ValidationErrorModel,
  });
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  const [msg, setMsg] = useState({
    show: false,
    type: "",
    text: "",
    textClassName: "",
  });

  useEffect(() => {}, []);

  function goBack() {
    try {
      history.goBack();
    } catch (e) {
      console.error("Inavlid Routing.Couldn't go back.");
    }
  }

  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let query = { ...state };
      api
        .noTokenpostService("signup", query)
        .then((response) => {
          console.log(response.status);
          setIsProcessing(false);
          // resp.ResponseHandler(response);
          handleSuccessResponse(response);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          handleErrorResponse(error);
          // resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function handleSuccessResponse(response) {
    let currentMsg = { ...msg };
    let msgText = <></>;

    if (response?.status === 200) {
      currentMsg.type = "success";
      currentMsg.textClassName = "text-theme-9";
      msgText = response?.data?.message;
    }
    currentMsg.text = msgText;
    currentMsg.show = true;
    setMsg(currentMsg);
  }
  function handleErrorResponse(error) {
    const response = error.response;
    console.log("response:", response);
    let currentMsg = { ...msg };
    let msgText = <></>;
    // Account already exists!
    if (response?.status === 409) {
      msgText = (
        <>
          Account already exists! Try{" "}
          <a
            href={void 0}
            className="text-theme-1 cursor-pointer"
            onClick={navToLogin}
          >
            Log In
          </a>
        </>
      );
      currentMsg.type = "error";
      currentMsg.textClassName = "text-theme-6";
    } else if (response?.status === 402) {
      currentMsg.type = "warning";
      currentMsg.textClassName = "text-theme-12";
      msgText = response?.data?.message;
    } else {
      currentMsg.type = "error";
      currentMsg.textClassName = "text-theme-6";
      msgText = "Something went wrong. Please try again.";
    }

    currentMsg.text = msgText;
    currentMsg.show = true;
    setMsg(currentMsg);
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = { ...validationError };
    switch (name) {
      case "name":
        errors[name] = validation.validateName(value);
        break;
      case "phone":
        errors[name] = validation.validatePhoneNo(value);
        break;

      default:
        break;
    }
    setValidationError(errors);
  }

  function navToLogin() {
    window.location.href = "login";
  }

  return (
    <>
      <div className="">
        <div className="container sm:px-10">
          <div className="block grid-cols-2 gap-4">
            {/* <!-- BEGIN: Login Form --> */}
            {/* <form onSubmit={onSubmit}> */}
            <form className="h-screen flex py-5 my-10" onSubmit={onSubmit}>
              <div className="my-auto mx-auto bg-white dark:bg-dark-1 px-5 sm:px-8 py-8 rounded-md shadow-md  w-full sm:w-3/4 lg:w-2/4 xl:w-1/4">
                <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center">
                  Sign Up
                </h2>
                <div className="intro-x mt-2 text-gray-500 text-center">
                  A few more clicks to sign in to your account. Manage all your
                  logistics works in one place.
                </div>
                <div className="intro-x mt-8">
                  <input
                    type="text"
                    className="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                    placeholder="Name"
                    name="contact_person"
                    onChange={changeInput}
                    value={state.contact_person}
                    required
                  />
                  <input
                    type="email"
                    className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                    placeholder="Email Address"
                    name="email"
                    onChange={changeInput}
                    value={state.email}
                    required
                  />
                  <input
                    type="text"
                    className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                    placeholder="Organization name"
                    name="name"
                    onChange={changeInput}
                    value={state.name}
                    required
                  />
                  {/* <textarea
                    type="text"
                    className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                    placeholder="Address"
                    name="address"
                    onChange={changeInput}
                    value={state.address}
                    required
                  /> */}

                  {/* <PhoneInput
                    country={"in"}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    style={{
                      inputClass:
                        "intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4",
                    }}
                    onChange={changeInput}
                  /> */}

                  <input
                    type="number"
                    className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                    placeholder="Phone"
                    name="phone"
                    onChange={changeInput}
                    value={state.phone}
                    required
                  />
                  {/* <input
                    type="text"
                    className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                    placeholder="GST Number"
                    name="gst"
                    onChange={changeInput}
                    value={state.gst}
                    required
                  /> */}
                </div>

                {/* <div className="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
                  By signin up, you agree to our
                  <br />
                  <a className="text-theme-1 dark:text-theme-10" href="">
                    Terms and Conditions
                  </a>{" "}
                  &{" "}
                  <a className="text-theme-1 dark:text-theme-10" href="">
                    Privacy Policy
                  </a>
                </div> */}

                <div className="intro-x mt-5 text-center xl:text-left">
                  <button
                    className="btn btn-primary py-3 px-4 w-full align-top"
                    type="submit"
                  >
                    Create Account {isProcessing ? <InLineLoader /> : null}
                  </button>
                </div>

                {/* <!-- BEGIN: Message --> */}
                {msg?.show && (
                  <div
                    className={
                      "mt-3 text-center flex-none " + msg?.textClassName
                    }
                  >
                    {msg?.text}
                  </div>
                )}

                {/* <!-- END: Message --> */}

                {/* <!-- BEGIN: Login Link --> */}
                <div className="mt-8 text-center flex-none text-gray-500">
                  Existing User?{" "}
                  <a
                    href={void 0}
                    className="cursor-pointer text-theme-1 dark:text-theme-10"
                    onClick={navToLogin}
                  >
                    Login
                  </a>
                </div>
                {/* <!-- END: Login Link --> */}
              </div>
            </form>
            {/* </form> */}

            {/* <!-- END: Login Form --> */}
          </div>
        </div>
      </div>
    </>
  );
}
