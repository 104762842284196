import React, { useEffect } from "react";
import {
  htmlDateTime,
  formatCurrency,
  getAddressString,
  standardCase,
} from "../../functions/common";

import "./style.css";
const imageToBase64 = require("image-to-base64");

const baseURL = process.env.REACT_APP_FILE_URL;
export default function LRTemplate(props) {
  const { data, lr_index, organization } = props;
  var load_receipt = data.load_receipts[lr_index];

  //calcluating total pkg and amount
  let sum_of_pkg = 0;
  let sum_of_billable_amt = 0;
  for (var i in load_receipt.goods) {
    sum_of_pkg += parseInt(load_receipt.goods[i].number_of_unit);
    sum_of_billable_amt += parseFloat(load_receipt.goods[i].billable_value);
  }
  load_receipt["total_packages"] = sum_of_pkg;
  load_receipt["toal_billable_amount"] = sum_of_billable_amt;

  // Hardcoded Data

  const income_tax_no = organization?.pan_number;
  const service_tax_no = organization?.pan_number;

  const companyaddress = getAddressString(organization);
  const companyName = organization?.name;

  // Logo
  const getBase64Image = async () => {
    var image = "";
    await imageToBase64("http://localhost:9000/uploads/svrl-logo.jpg") // Path to the image
      .then(async (response) => {
        console.log("base64:", response); // "cGF0aC90by9maWxlLmpwZw=="
        image = response;
      })
      .catch((error) => {
        console.log(error); // Logs an error if there was one
        image = false;
      });
    return image;
  };

  var logoURL = baseURL + organization?.logo;
  var logoError = true;

  try {
    logoURL = logoURL.replace(/\\/g, "/");
  } catch {}

  if (organization?._id === "61c0d49ab9af2b12f8b79422") {
    logoURL = "images/svrl-logo.jpg";
    logoError = false;
  }
  // const companyLogo = getBase64Image(organization?.logo);
  // console.log("companyLogo:", companyLogo);

  var logoContainer = <></>;

  if (!logoError) {
    logoContainer = <img src={logoURL} id="img-logo" />;
  } else {
    logoContainer = (
      <div className="font-bold text-theme-1 break-all text-xl">
        {companyName}
      </div>
    );
  }

  const css = `{
    font-size:1rem;
    color:#000000;
 }`;

  return (
    <div className="report" id="load_receipt_report">
      <style>{css}</style>
      <table className="table mb-5" id="header">
        <tbody>
          <tr>
            <td>{logoContainer}</td>
            <td className="">
              <b>Truck No:</b>
              <div className="clearfix"></div>
              <span className="text-uppercase">
                {data?.vehicle?.registration_number}
              </span>
            </td>
            <td className="">
              <b>Consignment No:</b>&nbsp;<span>{data?.consignment_id}</span>
              <div className="clearfix"></div>
              <b>LR No:</b>&nbsp;<span>{load_receipt?.lr_number}</span>
            </td>
            <td className="">
              <b>LR Out Time:</b>
              <div className="clearfix"></div>
              <span>{htmlDateTime(load_receipt?.out_time)}</span>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Consigner Table */}
      <table className="table mb-3" id="consignment">
        <tbody>
          <tr>
            <td className="border-dark">
              <b>Vendor:</b>
              <span className="ml-2">{data?.client?.name}</span>
            </td>
            <td className="border-dark">
              <b>Vehicle Type:</b>
              <span className="ml-2">
                {data?.vehicle?.vehicle_type?.vehicle_type}
              </span>
            </td>
          </tr>

          <tr>
            <td className="border-dark">
              <b>Consigner:</b>
              <span className="ml-2">{load_receipt?.consigner?.name}</span>
            </td>
            <td className="border-dark">
              <b>Consignee:</b>
              <span className="ml-2">{load_receipt?.consignee?.name}</span>
            </td>
          </tr>

          <tr>
            <td className="border-dark">
              <b>From:</b>
              <span className="ml-2">{data?.origin?.name}</span>
            </td>
            <td className="border-dark">
              <b>To:</b>
              <span className="ml-2">{data?.destination?.name}</span>
            </td>
          </tr>
          <tr>
            <td className="border-dark">
              <b>Address:</b>
              <span className="ml-2">{load_receipt?.consigner?.address}</span>
            </td>
            <td className="border-dark">
              <b>Address:</b>
              <span className="ml-2">{load_receipt?.consignee?.address}</span>
            </td>
          </tr>
          <tr>
            <td className="border-dark">
              <b>GST:</b>
              <span className="ml-2">{load_receipt?.consigner?.gst}</span>
            </td>
            <td className="border-dark">
              <b>GST:</b>
              <span className="ml-2">{load_receipt?.consignee?.gst}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table table-bordered mb-3" id="info">
        <tbody>
          <tr>
            <td className="border-dark">
              <b>P.O.No:</b>
              <span className="ml-2">{load_receipt?.po_number}</span>
            </td>
            <td className="border-dark">
              <b>e-Way Bill No:</b>
              <span className="ml-2">{load_receipt?.eway_bill_no}</span>
            </td>

            <td className="border-dark">
              <b>Invoice No:</b>
              <span className="ml-2">{load_receipt?.invoice_number}</span>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Goods Table */}

      <table className="table table-bordered mb-5" id="goods_table">
        <thead>
          <tr>
            <th scope="col" className="border-dark whitespace-nowrap">
              #
            </th>
            <th scope="col" className="border-dark whitespace-nowrap">
              Description
            </th>
            <th scope="col" className="border-dark   whitespace-nowrap">
              Weight/Unit
            </th>
            <th scope="col" className="border-dark   whitespace-nowrap">
              Billable Weight
            </th>
            <th scope="col" className="border-dark   whitespace-nowrap">
              No. of Pkg
            </th>
            <th scope="col" className="border-dark   whitespace-nowrap">
              Billable Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {load_receipt.goods.map((item, index) => {
            return (
              <>
                <tr>
                  <td className="border-dark">{index + 1}</td>
                  <td className="border-dark">{item?.material_description}</td>
                  <td className="border-dark">{item?.weight_per_unit}</td>
                  <td className="border-dark">{item?.billable_weight} Kg</td>
                  <td className="border-dark">{item?.number_of_unit}</td>
                  <td className="border-dark">
                    {formatCurrency(item?.billable_value)}
                  </td>
                </tr>
              </>
            );
          })}
          <tr>
            <td colSpan="4" className="text-right font-weight-bold">
              Total
            </td>
            <td className="border-dark">{load_receipt?.total_packages}</td>
            <td className="border-dark">
              {formatCurrency(load_receipt?.toal_billable_amount)}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Legal entities Table */}

      <table className="table table-bordered mb-5" id="legal_entities">
        <tbody>
          <tr>
            <td colSpan="3" className="border-dark">
              <b>Our Income Tax Permanent Account No:</b>
              <div className="clearfix"></div>
              <span>{income_tax_no}</span>
            </td>
            <td colSpan="3" className="border-dark">
              <b>Service Tax Payable By:</b>
              <span className="ml-2">Consigner</span>
            </td>
          </tr>
          <tr>
            <td colSpan="3" className="border-dark">
              <p className="">Reporting at Loading Point Date/Time:</p>
              <div className="clearfix"></div>
              <span className="font-medium">
                {htmlDateTime(data?.loading_point?.in_time)}
              </span>
            </td>
            <td colSpan="3" className="border-dark">
              <p className="">Departure From Loading Point - Date/Time:</p>
              <div className="clearfix"></div>
              <span className="font-medium">
                {htmlDateTime(data?.loading_point?.out_time)}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="border-dark">
              <p className="mb-8">
                Having read and agreed to the conditions overleaf.Handed over
                the above goods and documents for transportation, as instructed
                above
              </p>
              <p className="text-sm font-bold">
                <small> Signature of Consigner</small>
              </p>
            </td>
            <td colSpan="2" className="border-dark">
              <p className="mb-8">
                Received the above goods and documents for transportation, as
                instructed above
              </p>
              <p className="text-sm font-bold">
                <small> Signature of the owner/driver on his behalf</small>
              </p>
            </td>

            <td colSpan="2" className="border-dark">
              <div className="mb-8">
                <p className="m-0">
                  Received the above goods and documents for transportation, as
                  instructed above
                </p>
                <p className="text-sm">For: {organization?.name}</p>
              </div>
              <p className="text-sm font-bold">
                <small>Authorised Representative</small>
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="6" className="">
              <span className="">
                {" "}
                {companyName}, {companyaddress}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
