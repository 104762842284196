import React from "react";
import PropTypes from "prop-types";

// import { ReactComponent as TyreImage } from "../../assets/images/svg/tyre-front-view.svg";
import TyreImage from "./tyreImage";
import { getAcronym } from "../../functions/common";  

import Draggable, { DraggableCore } from "react-draggable";

export default function Component(props) {
  const { id, className, data, onClick, isSpare, ...rest } = props;

  const activeClassName = "opacity-100";
  const inActiveClassName = "opacity-50";

  const active = data?.active || false;
  const label = getAcronym(data?.position || "");
  const position = data?.position || "";
  const spareCss = {
    transform: "rotate(90deg)",
    position: "relative",
  };
  const nonSpareCss = { position: "relative", "margin-left": "-64px" };

  return (
    <Draggable>
      <div
        className={
          "w-24 cursor-pointer z-50 " +
          (active ? activeClassName : inActiveClassName)
        }
        style={isSpare ? spareCss : nonSpareCss}
        onClick={(e) => onClick(data)}
      >
        <TyreImage />
        <div
          class="text-white shadow-lg opacity-75"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {label}
        </div>
      </div>
    </Draggable>
  );
}

Component.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  position: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  // Variables
  id: "tyre-item",
  isSpare: false,
  className: "",
  data: {
    _id: "",
    serial_no: "",
    active: false,
    position: "",
    type: "",
    kms_covered: "",
    max_running_limit: "",
  },
  // Functions
  onClick() {},
};
