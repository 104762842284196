import React from "react";
import PropTypes from "prop-types";
export default function DividerText(props) {
  const { className, ...rest } = props;
  return (
    <>
      <div
        className={
          "w-full flex justify-center border-t border-gray-200 dark:border-dark-5 my-5 " +
          className
        }
      >
        {" "}
        <div className="bg-white dark:bg-dark-3 px-5 -mt-3 text-gray-600">
          {props.children}
        </div>{" "}
      </div>
    </>
  );
}

DividerText.propTypes = {
  className: PropTypes.string,
};

DividerText.defaultProps = {
  className: "",
};
