import React, { useState, useEffect } from "react";
// Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import StatusBadge from "../../../components/pageElements/statusBadge";
import ActionButtons from "../../../components/pageElements/actionButtons";
import StageTab from "../../../components/tabs/stageTab";
import AddNewButton from "../../../components/htmlElements/addNewButton";
import FormMaster from "../../../components/master/client_agreements/master";
// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import { htmlToString, localDate, navToPage } from "../../../functions/common";
import { reactFormatter } from "react-tabulator";
// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";

export default function Client_Agreements() {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));

  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  const [tab, setTab] = useState({
    currentTab: "approved",
    key: "own",
    activeClass:
      "nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0 active",
    inActiveClass: "nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    setIsLoaded(true);
    loadData();
    // Loading Data
  }, []);

  async function loadData() {
    setIsProcessing(true);
    api.getService("client_agreements/master").then(
      async (result) => {
        const data = result.data;
        let tableData = await mapTableData(data.data);
        setTableData(tableData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Name",
        field: "name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Client",
        field: "client.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Agreement Start Date",
        field: "start_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },

      {
        title: "Agreement End Date",
        field: "end_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },

      {
        title: "Status",
        field: "status",
        headerSort: true,
        vertAlign: "right",

        formatter: function (cell) {
          return htmlToString(<StatusBadge text={cell?.getValue()} />);
        },
      },

      {
        title: "Actions",
        field: "",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",

        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(<ActionButtonsLookup />),
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [
        { label: "Client", value: "client.name" },
        { label: "Agreement Start Date", value: "agreement_start_date" },
        { label: "Agreement End Date", value: "agreement_end_date" },
      ],
    };
    return response;
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e, row) => {
            editRecord(cellData._id);
          },
        }}
        suspendButton={{
          show: cellData?.status == "active" ? true : false,
          onClick: (e) => {
            deleteRecord(cellData);
          },
        }}
        // activateButton={{
        //   // show: false,
        //   show: cellData?.status == "inactive" ? true : false,
        //   onClick: (e) => {
        //     // activateRecord(cellData);
        //   },
        // }}
      />
    );
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data

  // Reload Grid Data
  function reloadData(id) {
    if (modal?.action === "create") {
      viewRecord(id);
    } else {
      let newState = { show: false, title: "", action: "", id: "" };
      setModal(newState);
      loadData();
    }
  }

  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add Client Agreement`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(id) {
    window.location.href = `admin#/master/client/agreement/${id}`;
  }
  // Edit Record
  async function editRecord(id) {
    let newState = {
      show: true,
      title: `Edit Client Agreement`,
      action: "edit",
      id: id,
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to suspend this Agreement?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }
  // RE-ACTIVATION
  function activateRecord(data) {
    alert.confirmActivation({
      text: "Are you sure want to activate this Agreement?",
      onConfirm: function () {
        onActivate(data);
      },
    });
  }
  ////// COMMON FUNCTIONS //////

  function onActivate(data) {
    setIsProcessing(true);
    var query = {
      status: "active",
    };
    api
      .putService(`client_agreements/master/activate/${data._id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`client_agreements/master/${data?._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  return (
    <>
      <>
        <Container>
          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title className="text-lg font-medium">
              Client Agreements
            </Container.Title>

            {/* <!-- BEGIN: Stage Tabs --> */}
            <div className="ml-auto flex">
              <StageTab>
                <StageTab.Tab
                  id="client-stage-client"
                  active={false}
                  onClick={(e) => navToPage("/admin#/master/client")}
                  width={"w-32"}
                >
                  Client
                </StageTab.Tab>{" "}
                <StageTab.Tab
                  id="client-stage-client_agreements"
                  active={true}
                  onClick={(e) => navToPage("/admin#/master/client/agreement")}
                  width={"w-32"}
                >
                  Agreements
                </StageTab.Tab>
              </StageTab>
            </div>
            {/* <!-- END: Stage Tabs --> */}

            <Container.HeaderActions>
              <button
                id="view-price-history"
                className="btn btn-outline-primary mr-2"
                onClick={(e) => {
                  navToPage("admin#/price_history");
                }}
              >
                Price History
              </button>
              <AddNewButton id="add-new-button" onClick={addRecord}>
                Add New
              </AddNewButton>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {/* <!-- BEGIN: Modal --> */}
            {modal.show && (
              <FormMaster
                show={modal.show}
                action={modal.action}
                onHide={closeModal}
                callBack={reloadData}
                id={modal.id}
              />
            )}
            {/* <!-- END: Modal --> */}

            {isProcessing && <ProcessLoader />}

            <CustomDataTable
              header={tableData.header}
              content={tableData.content}
              onRowClick={(e, row) => {
                viewRecord(row._row.data._id);
              }}
              filter={true}
              filterFields={tableData.filterFields}
            />
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
