import React from "react";
import PropTypes from "prop-types";

import { formatCurrency } from "../../../functions/common";

// Chart
import { Line } from "react-chartjs-2";

export default function Component(props) {
  const { className, id, name, onClick, data, ...rest } = props;

  //   LINE CHART
  // Data
  const lineChartData = {
    labels: data?.xAxis,
    datasets: [
      {
        label: "Income",
        data: data?.income,
        fill: false,
        borderWidth: 2,
        borderColor: "#23608f",
        backgroundColor: "transparent",
        pointBorderColor: "transparent",
      },
      {
        label: "Expense",
        data: data?.expense,
        fill: false,
        borderWidth: 2,
        borderDash: [2, 2],
        borderColor: "#a0afbf",
        // borderColor: "#d3292980",
        backgroundColor: "transparent",
        pointBorderColor: "transparent",
      },
    ],
  };
  // Options
  const lineChartoptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: "12",
            fontColor: "#718096",
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontSize: "12",
            fontColor: "#718096",
            callback: function (value, index, values) {
              return "$" + value;
            },
          },
          gridLines: {
            color: "#718096",
            zeroLineColor: "#718096",
            borderDash: [2, 2],
            zeroLineBorderDash: [2, 2],
            drawBorder: false,
          },
        },
      ],
    },
  };

  return (
    <div className={className} id={id}>
      <div id="income_expense_line_chart" className="">
        <div className="flex flex-col xl:flex-row xl:items-center">
          <div className="flex">
            <div id="total_income">
              <div className="text-theme-19 dark:text-gray-300 text-lg xl:text-xl font-medium">
                {formatCurrency(data?.total_income)}
              </div>
              <div className="mt-0.5 text-gray-600 dark:text-gray-600">
                Income
              </div>
            </div>

            <div className="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-5"></div>
            <div id="total_expense">
              <div className="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium">
                {formatCurrency(data?.total_expense)}
              </div>
              <div className="mt-0.5 text-gray-600 dark:text-gray-600">
                Expense
              </div>
            </div>
          </div>
        </div>

        {/* <!--BEGIN: Chart Area--> */}
        <div className="mt-2">
          {/* <!--BEGIN: Line Chart --> */}
          <div
            style={{
              position: "relative",
              margin: "auto",
              width: "100%",
              height: "100%",
            }}
            id="line_chart"
          >
            <Line data={lineChartData} options={lineChartoptions} />
          </div>

          {/* <!--END: Line Chart --> */}
        </div>
        {/* <!--END: Chart Area--> */}
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "w-full h-full",
  id: "income_expense",
  name: "",
  data: {},
  onClick() {},
};
