import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

export default function Component(props) {
  const { className, id, name, onClick, data, ...rest } = props;

  useEffect(() => {}, []);
  // Chart Data
  const options = {
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },

      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    yAxes: [
      {
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
    ],
  };

  let chartData = mapData(data);

  function mapData(data) {
    let chart_data = {
      labels: ["FC", "Insurance", "Goods permit"],
      datasets: [
        {
          label: "In 10 days",
          barPercentage: 0.3,
          categoryPercentage: 1.0,
          backgroundColor: "#23608f",
          data: [
            data?.fc["10days"],
            data?.insurance["10days"],
            data?.goods["10days"],
          ],
        },
        {
          label: "In 30 days",
          barPercentage: 0.3,
          categoryPercentage: 1.0,
          backgroundColor: "#cbd5e0",
          data: [
            data?.fc["30days"],
            data?.insurance["30days"],
            data?.goods["30days"],
          ],
        },
        {
          label: "In 60 days",
          barPercentage: 0.3,
          categoryPercentage: 1.0,
          backgroundColor: "#FF8B26",
          data: [
            data?.fc["60days"],
            data?.insurance["60days"],
            data?.goods["60days"],
          ],
        },
        {
          label: "In 90 days",
          barPercentage: 0.3,
          categoryPercentage: 1.0,
          // barThickness: 6,
          // maxBarThickness: 8,
          // minBarLength: 2,
          backgroundColor: "#FFC533",
          data: [
            data?.fc["90days"],
            data?.insurance["90days"],
            data?.goods["90days"],
          ],
        },
      ],
    };

    return chart_data;
  }

  return (
    <>
      <div id={id} className={className}>
        {/* <!--BEGIN: Chart --> */}
        <div style={{ position: "relative", margin: "auto", width: "100%",height:"100%" }}>
          <Bar data={chartData} options={options} />
        </div>
        {/* <!--END: Chart --> */}

        <div className="mt-auto">
          <div className="text-base md:text-sm font-medium truncate text-theme-1">
            Vehicle Expiry
          </div>
        </div>
      </div>
    </>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "w-full h-full flex flex-col items-center justify-center",
  id: "vehicle_expiry_content",
  name: "",
  data: {
    fc: { "10days": 0, "30days": 0, "60days": 0, "90days": 0 },
    insurance: { "10days": 0, "30days": 0, "60days": 0, "90days": 0 },
    goods: { "10days": 0, "30days": 0, "60days": 0, "90days": 0 },
  },
  onClick() {},
};
