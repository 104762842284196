import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default function Component(props) {
  const { text, onClick, id, position, className,type, ...rest } = props;

  return (
    <div className={className}>
      <a className="cursor-pointer" data-tip data-for={id} href={void 0}>
        {props?.children}{" "}
      </a>

      <ReactTooltip id={id} place={position} aria-haspopup="true" role="tooltip" type={type}>
        {text}
      </ReactTooltip>
    </div>
  );
}

Component.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  id: "tooltip",
  className: "",
  position: "top",
  type: "dark",
  text: "",
  onClick() {},
};
