import React from "react";
import PropTypes from "prop-types";
export default function Input(props) {
  const {
    measure,
    className,
    type,
    id,
    name,
    onChange,
    value,
    required,
    size,
    placeholder,
    disabled,
    allowedValue,
    min,
    max,
    ...rest
  } = props;
  return (
    <div className="input-group">
      <input
        type={type}
        className={
          size === "sm" ? "form-control form-control-sm" : "form-control"
        }
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        min={min}
        max={max}
        step="any"
        oninput={
          allowedValue === "positive" ? "validity.valid||(value='');" : ""
        }
      />
      <div id={id} className="input-group-text">
        L
      </div>
    </div>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  currency: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  allowedValue: PropTypes.string,
};

Input.defaultProps = {
  className: "form-control",
  measure: "litre",
  placeholder: "",
  size: "",
  type: "number",
  id: "",
  name: "",
  onChange() {},
  value: "",
  required: false,
  min: "0",
  max: "",
  allowedValue: "positive",
};
