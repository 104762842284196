import axios from "axios";
import { storageServices } from "../functions/storage";
const apiUrl = process.env.REACT_APP_ADMIN_API_URL;

/////////// Common API Calls ///////////

// GET
export async function getService(routing) {
  let user = await storageServices.getCurrentUser();
  let headers = { headers: { Authorization: `Bearer ${user.token}` } };
  var url = apiUrl + routing;
  return axios.get(url, headers);
}
//
export async function noTokenpostService(routing, query) {
  var url = apiUrl + routing;

  return axios.post(url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// POST
export async function postService(routing, query) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;

  return axios.post(url, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function DatapostService(routing, formData) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;
  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.token}`,
    },
  });
}

// PUT
export async function DataputService(routing, formData) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;
  return axios.patch(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.token}`,
    },
  });
}
export async function putService(routing, query) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;
  return axios.patch(url, query, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

// DELETE
export async function deleteService(routing) {
  let user = await storageServices.getCurrentUser();
  var url = apiUrl + routing;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

/////////// Common API Calls ///////////

// Login & Other Calls
export async function updatePassword(routing, query) {
  var url = apiUrl + routing;
  return axios.patch(url, query);
}

export async function createPassword(query) {
  var url = apiUrl + "user/create_password";
  return axios.patch(url, query);
}


export async function loginService(query) {
  var url = apiUrl + "login";
  return axios.post(url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function forgotPassword(query) {
  var url = apiUrl + "user/forgotPassword";
  return axios.post(url, query);
}


