import React from "react";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
import { storageServices } from "./storage";
import { localDate, getTimeStamp } from "./common";
import Html2Pdf from "js-html2pdf";

import LRTemplate from "../templates/reports/load_receipt";
import InvoiceTemplate from "../templates/reports/invoice";
import { renderToString } from "react-dom/server";
import { multiply } from "lodash";
// TEMPLATES
import PdfWrapper from "../components/reportTemplates/pdf_wrapper";

import xlsx from "xlsx";

// Defaults
// const unit = "pt";
// const size = "A4"; // Use A1, A2, A3 or A4
// const orientation = "portrait"; // portrait or landscape
// const marginLeft = 40;
// const doc = new jsPDF(orientation, unit, size);
// doc.setFontSize(12);

const doc = "";
export async function payablesReport(filter_data, rawData) {
  return null;
}

export async function receivablesReport(filter_data, rawData) {
  return null;
}
// export async function payablesReport(filter_data, rawData) {
//   var accountsData = await storageServices.getStoredData("accounts");
//   const title = "Consignment List";
//   const headers = [
//     ["Id", "Consignment Id", "Date", "Actual amount", "Paid amount", "Balance"],
//   ];

//   var consignmentData = [];
//   for (var i in rawData) {
//     let consignment = rawData[i]?.consignment;
//     for (var j in consignment) {
//       let cons = [];
//       let balance =
//         parseFloat(consignment[j]?.consignment_id?.freight_amount) -
//         parseFloat(consignment[j]?.consignment_id?.total_amount_paid);
//       cons.push(rawData[i]._id);
//       cons.push(consignment[j]?.consignment_id?._id);
//       cons.push(localDate(rawData[i].createdAt));
//       cons.push(consignment[j]?.consignment_id?.freight_amount);
//       cons.push(consignment[j]?.consignment_transaction);
//       cons.push(balance);
//       consignmentData.push(cons);
//     }
//   }

//   let content = {
//     startY: 200,
//     head: headers,
//     body: consignmentData,
//   };
//   doc.text("Payments Report", 250, 80);

//   doc.text(`From :  ${localDate(filter_data?.startDate)}`, marginLeft, 120);
//   doc.text(`To :  ${localDate(filter_data?.endDate)}`, 250, 120);
//   doc.text(`Supplier : ${filter_data?.supplier}`, 400, 120);

//   doc.text(`Payments : ${accountsData?.payables}`, marginLeft, 150);
//   doc.text(`Receipts : ${accountsData?.receivables}`, 250, 150);
//   doc.text(`P&L : ${accountsData?.status}`, 400, 150);

//   doc.text(title, marginLeft, 180);
//   doc.autoTable(content);
//   return doc.save(`payments_report.pdf`);
// }

// export async function receivablesReport(filter_data, rawData) {
//   var accountsData = await storageServices.getStoredData("accounts");
//   const title = "Invoice List";
//   const headers = [
//     ["Id", "Invoice No.", "Date", "Actual amount", "Paid amount", "Balance"],
//   ];

//   var tableData = [];
//   for (var i in rawData) {
//     let invoice = rawData[i]?.invoice;
//     for (var j in invoice) {
//       let cons = [];
//       let balance =
//         parseFloat(invoice[j]?.invoice_id?.total) -
//         parseFloat(invoice[j]?.invoice_transaction);
//       cons.push(rawData[i]._id);
//       cons.push(invoice[j]?.invoice_id?.invoice_number);
//       cons.push(localDate(rawData[i].createdAt));
//       cons.push(invoice[j]?.invoice_id?.total);
//       cons.push(invoice[j]?.invoice_transaction);
//       cons.push(balance);
//       tableData.push(cons);
//     }
//   }

//   let content = {
//     startY: 200,
//     head: headers,
//     body: tableData,
//   };
//   doc.text("Receipts Report", 250, 80);

//   doc.text(`From :  ${localDate(filter_data?.startDate)}`, marginLeft, 120);
//   doc.text(`To :  ${localDate(filter_data?.endDate)}`, 250, 120);
//   doc.text(`Client : ${filter_data?.client}`, 400, 120);

//   doc.text(`Payments : ${accountsData?.payables}`, marginLeft, 150);
//   doc.text(`Receipts : ${accountsData?.receivables}`, 250, 150);
//   doc.text(`P&L : ${accountsData?.status}`, 400, 150);

//   doc.text(title, marginLeft, 180);
//   doc.autoTable(content);
//   return doc.save(`receipts_report.pdf`);
// }

const pdfFileOptions = {
  payables_report: { filename: "Payables report", title: "Payables report" },
  receivables_report: {
    filename: "Receivables report",
    title: "Receivables report",
  },
  daily_report: {
    filename: "Daily report",
    title: "Daily report",
  },
  revenue_report: {
    filename: "Revenue report",
    title: "Revenue report",
  },
};

const filePrefix = "CL";

export function exportExcel(tableRef, downloadFileName) {
  const table = tableRef.current.table;
  const fileName = filePrefix + "_" + downloadFileName + "_" + getTimeStamp();
  window.XLSX = xlsx;
  table.download("xlsx", `${fileName}.xlsx`);
}

export async function exportPDF(template_name, pdfTemplate, localUser) {
  let wrapper_template = <></>;

  const title = pdfFileOptions[template_name]["title"];
  const file_name =
    pdfFileOptions[template_name]["filename"] + `-${localDate(new Date())}`;

  wrapper_template = (
    <PdfWrapper
      organization={localUser?.customer_account}
      title={title}
      template={pdfTemplate}
    />
  );
  return generatePDF(wrapper_template, file_name);
}

// GENERAL REPORT FUNCTIONS
export async function generatePDF(template, filename) {
  let downloadTemplate = await renderToString(template);
  var options = {
    filename: `${filename}.pdf`,
    download: false,
  };
  // Create instance of html2pdf class
  try {
    var exporter = new Html2Pdf(downloadTemplate, options);
    var exporter = new Html2Pdf(downloadTemplate, options);

    Html2Pdf.getPdf(options).then((pdf) => {
      console.log(pdf.output("datauristring"));
    });

    // Download the PDF or...
    // exporter.getPdf(false).then((pdf) => {
    //   console.log("exporter:", exporter);
    //   console.log("pdf file downloaded");
    // });
  } catch (err) {
    console.log(err);
    alert("Couldn't dowload PDF!");
    return false;
  }

  return true;
}

// GENERAL REPORT FUNCTIONS

// export async function loadReceiptReport(data, lr_index, organization) {
//   return (
//     <LRTemplate data={data} lr_index={lr_index} organization={organization} />
//   );
// }

export async function loadReceiptReport(data, lr_index, organization) {
  let template = await renderToString(
    <LRTemplate data={data} lr_index={lr_index} organization={organization} />
  );
  let lr_number = data?.load_receipts[lr_index]?.lr_number;
  var options = {
    filename: `load_receipt_${organization?.name}_${lr_number}.pdf`,
    download: true,
  };

  // Create instance of html2pdf class
  try {
    var exporter = new Html2Pdf(template, options);

    // Download the PDF or...
    exporter.getPdf(true).then((pdf) => {
      console.log("pdf file downloaded");
    });
  } catch (err) {
    console.log(err);
    alert("Couldn't dowload PDF!");
  }

  return true;
}

export async function invoiceReport(data, localUserData) {
  var result = null;
  const organization = localUserData?.organization;

  let template = await renderToString(
    <InvoiceTemplate
      data={data}
      organization={localUserData?.organization}
      customer_account={localUserData?.customer_account}
    />
  );
  let invoice_number = data?.invoice_number || "";
  var options = {
    filename: `Invoice_${organization?.name}_${invoice_number}.pdf`,
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    download: true,
  };

  // Create instance of html2pdf class
  try {
    let new_options = {
      source: template,
      download: false,
    };
    await Html2Pdf.getPdf(new_options).then((pdf) => {
      // console.log("pdf:", pdf.output());
      result = pdf.output("datauristring");
      // console.log(pdf.output("datauristring"));
    });

    // var exporter = new Html2Pdf(template, options);
    // console.log("exporter:", exporter);
    // // Download the PDF or...
    // exporter.getPdf(true).then((pdf) => {

    //   console.log("pdf file downloaded:", pdf);
    // });
  } catch (err) {
    console.log(err);
    alert("Couldn't dowload PDF!");
  }

  return result;
}

export async function streamInvoiceReport(data, localUserData) {
  const organization = localUserData?.organization;
  let template = await renderToString(
    <InvoiceTemplate
      data={data}
      organization={localUserData?.organization}
      customer_account={localUserData?.customer_account}
    />
  );
  let invoice_number = data?.invoice_number || "";
  var options = {
    filename: `Invoice_${organization?.name}_${invoice_number}.pdf`,
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    download: true,
  };

  // Create instance of html2pdf class
  try {
    var exporter = new Html2Pdf(template, options);

    // Download the PDF or...
    exporter.getPdf(true).then((pdf) => {
      console.log("pdf file downloaded");
    });
  } catch (err) {
    console.log(err);
    alert("Couldn't dowload PDF!");
  }

  return true;
}

export async function getInvoiceReportPdf(data, localUserData) {
  const organization = localUserData?.organization;
  let template = await renderToString(
    <InvoiceTemplate
      data={data}
      organization={localUserData?.organization}
      customer_account={localUserData?.customer_account}
    />
  );
  let invoice_number = data?.invoice_number || "";
  var options = {
    filename: `Invoice_${organization?.name}_${invoice_number}.pdf`,
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    download: true,
  };

  // Create instance of html2pdf class
  // try {
  //   var exporter = new Html2Pdf(template, options);

  //   // Download the PDF or...
  //   exporter.getPdf(true).then((pdf) => {
  //     console.log("pdf file downloaded");
  //   });
  // } catch (err) {
  //   console.log(err);
  //   alert("Couldn't dowload PDF!");
  // }
  var exporter = new Html2Pdf(template, options);
  return exporter.getPdf(true);
}
