import React, { useState, useEffect } from "react";
// Page Defaults
import Preloader from "../../../components/preloader/preloader";
import ProcessLoader from "../../../components/preloader/processLoader";
import Error from "../../../components/error/error";
import ValidationError from "../../../components/error/validationError";
// Functions
import * as api from "../../../api_service/api";
import { resp } from "../../../functions/responseHandler";
import {
  formatCurrency,
  toFloat,
  localDate,
  htmlDate,
  formatTypeSearch,
  validateComplexForm,
  isDefined,
} from "../../../functions/common";
// Model
import { PayablesModel, ValidationErrorModel } from "./model";
// HTML ELEMENTS
import CurrencyInput from "../../../components/htmlElements/currencyInput";
import { Typeahead } from "react-bootstrap-typeahead";
import Container from "../../../components/container";
import Card from "../../../components/card/custom";
import Form from "../../../components/form/custom";
import DividerText from "../../../components/htmlElements/dividerText";
// Valiadtion
import * as validation from "../../../functions/validation";
// CSS
import "./form.css";

// Datatable
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import $ from "jquery";

export default function PayablesForm(props) {
  const [pageTitle, setPageTitle] = useState("New Payment");
  // Loaders
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  // Validation
  const [validationError, setValidationError] = useState(
    ValidationErrorModel()
  );
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });
  // States
  const [state, setState] = useState(PayablesModel());
  const [isSelectAll, setISSelectAll] = useState(false);
  const [defaultState, setDefaultState] = useState({
    bank: [],
    fuel: [],
    consignments: [],
    supplier: [],
    cash: [],
    vehicle: [],
  });
  //////// Consignment Table /////////
  const [consignmentList, setConsignmentList] = useState([]);
  const [selectedConsignment, setSelectedConsignment] = useState({
    count: 0,
    data: [],
  });
  // Consignment Payment

  useEffect(() => {
    loadDefaults();
    setIsLoaded(true);
  }, []);

  function loadDefaults() {
    setState((prevState) => ({ ...prevState, date: htmlDate(new Date()) }));
  }

  const selectRecord = async (event, array_name, array_index) => {
    const list = [...state[array_name]];

    let value = list[array_index]["balance"];
    if (value != 0) {
      let selected = !list[array_index]["selected"];
      if (selected) {
        list[array_index]["new_amount_paid"] = value;
      } else {
        list[array_index]["new_amount_paid"] = "";
      }
      list[array_index]["selected"] = selected;
      setState((prevState) => ({ ...prevState, [array_name]: list }));
      calcTotalTransaction(list);
    }
  };

  const selectAll = async (event, array_name) => {
    const list = [...state[array_name]];
    let selected = !isSelectAll;
    if (selected) {
      for (var i in list) {
        let balance = list[i]["balance"];
        if (balance != 0) {
          list[i]["selected"] = true;
          list[i]["new_amount_paid"] = balance;
        } else {
          list[i]["selected"] = false;
          list[i]["new_amount_paid"] = "";
        }
      }
    } else {
      for (var i in list) {
        list[i]["new_amount_paid"] = "";
        list[i]["selected"] = false;
      }
    }
    setISSelectAll(selected);
    setState((prevState) => ({ ...prevState, [array_name]: list }));
    calcTotalTransaction(list);
  };

  const changeInputArrayObject = async (event, array_name, array_index) => {
    const { name, value } = event.target;
    const list = [...state[array_name]];
    list[array_index][name] = value;
    if (name == "new_amount_paid") {
      if (value != 0) {
        list[array_index]["selected"] = true;
      } else {
        list[array_index]["selected"] = false;
      }
    }

    setState((prevState) => ({ ...prevState, [array_name]: list }));
    if (name == "new_amount_paid") {
      calcTotalTransaction(list);
      formValidation(
        name,
        value,
        array_name,
        array_index,
        list[array_index]["balance"]
      );
    }
  };

  // Input Changes
  function formValidation(name, value, array_name = "", index = 0, balance) {
    let errors = validationError;

    switch (name) {
      case "supplier":
        errors[name] = validation.validateTypeAhead(value);
        break;
      case "new_amount_paid":
        errors[array_name][index][name] =
          value > balance ? "Should not exceed balance." : "";
        break;
      default:
        break;
    }
  }

  function calcTotalTransaction(list) {
    let newAmount = 0;
    for (var i in list) {
      newAmount += toFloat(list[i]["new_amount_paid"]);
    }
    setState((prevState) => ({
      ...prevState,
      total_transaction_amount: newAmount,
    }));
  }

  function changeToPayCons(event, index) {
    const { name, value } = event.target;
    console.log("index:", index, name, value);
    consignmentList[index][name] = value;
    setConsignmentList();
  }
  async function calcBalanceCons(name, data, state_data) {
    let validationConsModel = [];
    let total_balance = 0;
    let total_amount_paid = 0;
    for (var i in data) {
      let balance = await calcTotalBalance(data[i]);
      let amount_payable = await calcAmountPayable(data[i]);
      // let amount_paid =
      //   toFloat(data[i]["total_amount_paid"]) + toFloat(data[i]["journal"]);

      data[i]["balance"] = balance;
      data[i]["total_amount"] = amount_payable;
      data[i]["new_amount_paid"] = "";
      data[i]["remarks"] = "";
      data[i]["selected"] = false;

      // Calculating total balance and total amount paid for supplier/vehicle
      total_balance += balance;
      total_amount_paid += toFloat(data[i]["total_amount_paid"]);

      // Setting Up Validation Model
      let validationObj = { new_amount_paid: "" };
      validationConsModel.push(validationObj);
    }
    await setValidationError((prevState) => ({
      ...prevState,
      consignments: validationConsModel,
    }));

    // Changing supplier / vehicle values
    // let newState = state[name];
    state_data["total_balance"] = total_balance;
    state_data["total_amount_paid"] = total_amount_paid;

    await setState((prevState) => ({
      ...prevState,
      [name]: state_data,
    }));

    return data;
  }

  function calcAmountPayable(data) {
    let totalAmount = 0;

    if (data?.status === "acknowledged" || data?.status === "invoiced") {
      totalAmount = toFloat(data?.charges?.consignment_total_amount || 0);
    } else {
      if (isDefined(data?.charges?.consignment_amount_payable)) {
        totalAmount = toFloat(data?.charges?.consignment_amount_payable || 0);
      } else {
        totalAmount = toFloat(data?.charges?.consignment_freight || 0);
      }
      // Adding addition amount
      for (var i in data?.charges?.consignment_addition) {
        totalAmount += toFloat(
          data?.charges?.consignment_addition[i]?.amount || 0
        );
      }
      // Subtracting Deduction amount
      for (var i in data?.charges?.consignment_deduction) {
        totalAmount += toFloat(
          data?.charges?.consignment_deduction[i]?.amount || 0
        );
      }
    }

    totalAmount = totalAmount.toFixed(2);

    return totalAmount;
  }

  function calcTotalBalance(data) {
    let totalAmount = 0;
    let balance = 0;

    if (data?.status === "acknowledged" || data?.status === "invoiced") {
      totalAmount = toFloat(data?.charges?.consignment_total_amount || 0);
    } else {
      if (isDefined(data?.charges?.consignment_amount_payable)) {
        totalAmount = toFloat(data?.charges?.consignment_amount_payable || 0);
      } else {
        totalAmount = toFloat(data?.charges?.consignment_freight || 0);
      }
      // Adding addition amount
      for (var i in data?.charges?.consignment_addition) {
        totalAmount += toFloat(
          data?.charges?.consignment_addition[i]?.amount || 0
        );
      }
      // Subtracting Deduction amount
      for (var i in data?.charges?.consignment_deduction) {
        totalAmount += toFloat(
          data?.charges?.consignment_deduction[i]?.amount || 0
        );
      }
    }

    totalAmount = totalAmount.toFixed(2);
    balance =
      totalAmount -
      (toFloat(data?.total_amount_paid || 0) + toFloat(data?.journal || 0));

    balance = balance.toFixed(2);

    return balance;
  }

  async function calcBalancePaymentJournal(name, data, state_data) {
    let validationConsModel = [];
    let total_amount_paid = 0;
    let total_balance = 0;

    for (var i in data) {
      let balance =
        toFloat(data[i]["transaction_amount"]) -
        toFloat(data[i]["total_amount_paid"]);

      data[i]["balance"] = balance;

      data[i]["new_amount_paid"] = "";
      data[i]["remarks"] = "";
      data[i]["selected"] = false;

      total_amount_paid += data[i]["total_amount_paid"];
      total_balance += balance;

      // Setting Up Validation Model
      let validationObj = { new_amount_paid: "" };
      validationConsModel.push(validationObj);
    }
    await setValidationError((prevState) => ({
      ...prevState,
      payment_journal: validationConsModel,
    }));

    state_data["total_balance"] = total_balance;
    state_data["total_amount_paid"] = total_amount_paid;

    await setState((prevState) => ({
      ...prevState,
      [name]: state_data,
    }));

    return data;
  }

  // async function onConsRowSelect(row) {
  //   console.log("row:", row);
  //   let selectedRows = row.selectedRows;
  //   let balanceAmount = 0;
  //   if (row.selectedCount > 0) {
  //     for (var i in selectedRows) {
  //       balanceAmount = balanceAmount + toFloat(selectedRows[i].balance);
  //     }
  //   } else {
  //     balanceAmount = 0;
  //   }
  //   setState((prevState) => ({ ...prevState, balance: balanceAmount }));
  //   setSelectedConsignment((prevState) => ({
  //     ...prevState,
  //     count: row.selectedCount,
  //     data: row.selectedRows,
  //   }));
  //   // calcConsignment(state.total_transaction_amount);
  // }

  function changeInput(event) {
    const { name, value } = event.target;
    if (name === "total_transaction_amount") {
      // calcConsignment(value);
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeAmount(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  async function changePayTo(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (value === "supplier") {
      await loadDefaultMetrics(event, "supplier");
    }
    if (value === "journal") {
      let prevState = { ...state };

      await setState((prevState) => ({ ...prevState, consignments: [] }));
      await setState((prevState) => ({ ...prevState, supplier: "" }));
      await setValidationError((prevState) => ({
        ...prevState,
        consignments: [],
      }));

      await loadDefaultMetrics(event, "fuel");
    }

    // if (value === "fuel") {
    //   await loadDefaultMetrics("fuel");
    //   await loadDefaultMetrics("supplier");
    // }
    // if (value === "bank") {
    //   loadDefaultMetrics("bank");
    // }
  }

  // async function changeJournalTo(event) {
  //   const { name, value } = event.target;
  //   setState((prevState) => ({ ...prevState, [name]: value }));
  //   if (value === "supplier") {
  //     loadDefaultMetrics("supplier");
  //   }
  // }

  async function changeJournalTo(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    loadDefaultMetrics(event, value);
  }

  function changePaymentMethod(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (value === "bank" || value === "fuel" || value === "cash") {
      loadDefaultMetrics(event, value);
    }
  }

  function changeInputPaymentMaster(event) {
    const { name, value } = event.target;
    let parsedValue = JSON.parse(value);
    setState((prevState) => ({ ...prevState, [name]: parsedValue }));

    // let totalBalance =
    //   toFloat(parsedValue?.opening_balance) +
    //   toFloat(parsedValue?.current_balance);
    // setState((prevState) => ({
    //   ...prevState,
    //   payment_bank: parsedValue._id,
    //   payment_bank_balance: parsedValue?.current_balance,
    // }));
  }

  function prepareConsignment() {
    let cons = selectedConsignment.data;
    let newCons = [];
    for (var i in cons) {
      let payablesStatus = "";
      if (cons[i]["balance"] === cons[i]["new_amount_paid"]) {
        // if (cons[i]["status"] === "acknowledged") {
        //   payablesStatus = "paid";
        // }
        // if (cons[i]["status"] != "acknowledged") {
        //   payablesStatus = "partial";
        // }
        payablesStatus = "paid";
      }

      if (cons[i]["balance"] != cons[i]["new_amount_paid"]) {
        payablesStatus = "partial";
        // if (cons[i]["status"] === "acknowledged") {
        //   payablesStatus = "partial";
        // }
        // if (cons[i]["status"] != "acknowledged") {
        //   payablesStatus = "pending";
        // }
      }
      let journal_status = "";
      if (state.payment_mode === "journal") {
        let journalSum =
          parseFloat(cons[i]["new_amount_paid"] || 0) +
          parseFloat(cons[i]["total_journal_paid"] || 0);
        if (journalSum === parseFloat(cons[i]["journal"])) {
          journal_status = "paid";
        }
        if (parseFloat(cons[i]["journal"]) > journalSum) {
          journal_status = "partial";
        }
      }

      newCons.push({
        _id: cons[i]["_id"],
        consignment_transaction: cons[i]["new_amount_paid"],
        supplier: cons[i]["supplier"],
        journal_status: journal_status,
        // total_amount_paid: cons[i]["total_amount_paid"],
        amount_paid: cons[i]["new_amount_paid"],
        payables_status: payablesStatus,
      });
    }
    return newCons;
  }

  function goBack() {
    window.location.href = "/admin#/accounts/payables";
  }

  async function validateConsignment(recData) {
    let response = { valid: false, msg: "Something went wrong", data: [] };
    let data = await recData.filter((x) => x.selected);
    console.log("data:", data);
    if (data.length > 0) {
      let totalAmount = 0;
      for (var i in data) {
        totalAmount =
          toFloat(totalAmount) + toFloat(data[i]["new_amount_paid"]);
        // Data Formatting for API Call
        let payablesStatus = "";
        let journal_status = "";
        data[i]["amount_paid"] = data[i]["new_amount_paid"];
        if (
          toFloat(data[i]["new_amount_paid"]) === toFloat(data[i]["balance"])
        ) {
          payablesStatus = "paid";
        } else {
          payablesStatus = "partial";
        }
        response.data.push({
          _id: data[i]["_id"],
          // consignment_transaction: data[i]["new_amount_paid"],
          // supplier: data[i]["supplier"]["_id"],
          journal_status: journal_status,
          // total_amount_paid: cons[i]["total_amount_paid"],
          transaction_amount: data[i]["new_amount_paid"],
          remarks: data[i]["remarks"],
          payables_status: payablesStatus,
        });
      }
      response.valid = true;
      response.msg = "";
      // if (state.total_transaction_amount == totalAmount) {
      //   response.valid = true;
      //   response.msg = "";
      // } else {
      //   response.valid = false;
      //   response.msg = "Transaction Amount Doesn't match.";
      // }
    } else {
      response.valid = false;
      response.msg = "No Data Selected.";
    }

    return response;
  }

  async function validatePaymentJournal(recData) {
    let response = { valid: false, msg: "Something went wrong", data: [] };
    let data = await recData.filter((x) => x.selected);
    if (data.length > 0) {
      let totalAmount = 0;
      for (var i in data) {
        totalAmount =
          toFloat(totalAmount) + toFloat(data[i]["new_amount_paid"]);
        // Data Formatting for API Call
        let journal_status = "";
        if (
          toFloat(data[i]["new_amount_paid"]) === toFloat(data[i]["balance"])
        ) {
          journal_status = "paid";
        } else {
          journal_status = "unpaid";
        }

        let query = {
          transaction_amount: data[i]["new_amount_paid"],
          remarks: data[i]["remarks"],
          journal_transaction_id: data[i]["_id"],
          journal_status: journal_status,
        };
        response.data.push(query);
      }
      response.valid = true;
      response.msg = "";

      // if (state.total_transaction_amount == totalAmount) {
      //   response.valid = true;
      //   response.msg = "";
      // } else {
      //   response.valid = false;
      //   response.msg = "Transaction Amount Doesn't match.";
      // }
    } else {
      response.valid = false;
      response.msg = "No Data Selected.";
    }

    return response;
  }

  // JOURNAL CREATION

  async function validateConsignmentJournal(recData) {
    let response = { valid: false, msg: "Something went wrong", data: [] };
    let data = await recData.filter((x) => x.selected);
    if (data.length > 0) {
      let totalAmount = 0;
      for (var i in data) {
        totalAmount =
          toFloat(totalAmount) + toFloat(data[i]["new_amount_paid"]);
        let payables_status = data[i]["payables_status"];
        if (
          toFloat(data[i]["balance"]) == toFloat(data[i]["new_amount_paid"])
        ) {
          payables_status = "paid";
        }
        // Data Formatting for API Call
        response.data.push({
          _id: data[i]["_id"],
          payables_status: payables_status,
          journal_status: "",
          petrol_bunk: state?.petrol_bunk?._id,
          transaction_amount: data[i]["new_amount_paid"],
          remarks: data[i]["remarks"],
        });
      }
      response.valid = true;
      response.msg = "";
      // if (state.total_transaction_amount == totalAmount) {
      //   response.valid = true;
      //   response.msg = "";
      // } else {
      //   response.valid = false;
      //   response.msg = "Transaction Amount Doesn't match.";
      // }
    } else {
      response.valid = false;
      response.msg = "No Data Selected.";
    }

    return response;
  }

  async function onSubmit(event) {
    event.preventDefault();
    if (state.payment_mode === "journal") {
      createJournal(event);
    }
    if (state.payment_to_type === "journal") {
      savePaymentJournal(event);
    }
    if (
      state.payment_mode !== "journal" &&
      state.payment_to_type === "supplier"
    ) {
      saveDirect(event);
    }
  }

  async function saveDirect(event) {
    event.preventDefault();
    if (validateComplexForm(validationError)) {
      let validation = await validateConsignment([...state.consignments]);
      if (validation.valid) {
        setIsProcessing(true);

        let query = {
          date: state?.date,
          payment_mode: state.payment_mode,
          transaction_type: "payable",
          transaction_context: "direct",
          payment_from_type: "self",
          payment_to_type: "supplier",
          supplier: state?.supplier?._id,
          payment_reference_number: state?.reference_number,
          consignments: validation.data,
        };

        if (query.payment_mode === "bank") {
          query["payment_bank"] = state?.payment_bank?._id;
        }

        if (query.payment_mode === "cash") {
          query["payment_cash"] = state?.payment_cash?._id;
        }

        console.log("query:", query);
        api
          .postService("accounts/transactions/payable", query)
          .then((response) => {
            console.log("response:", response);
            setIsProcessing(false);
            resp.ResponseHandler(response,'reload');
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        resp.Error(validation.msg);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function savePaymentJournal(event) {
    event.preventDefault();
    if (validateComplexForm(validationError)) {
      let validation = await validatePaymentJournal([...state.payment_journal]);
      if (validation.valid) {
        setIsProcessing(true);
        let query = {
          date: state?.date,
          transaction_type: "payable",
          transaction_context: "direct",

          payment_from_type: "self",
          payment_to_type: "petrolbunk", // Journal

          payment_mode: state?.payment_mode,

          petrolbunk: state.petrol_bunk?._id,

          payment_reference_number: state.reference_number,
          transaction_list: validation.data,
        };

        if (state.payment_mode === "bank") {
          query["payment_bank"] = state?.payment_bank?._id;
        }

        if (state.payment_mode === "cash") {
          query["payment_cash"] = state?.payment_cash?._id;
        }

        console.log("query:", query);

        api
          .postService("accounts/transactions/payable", query)
          .then((response) => {
            console.log("response:", response);
            setIsProcessing(false);
            resp.ResponseHandler(response,'reload');
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        resp.Error(validation.msg);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function createJournal(event) {
    console.log("create journal");
    event.preventDefault();
    if (validateComplexForm(validationError)) {
      let validation = await validateConsignmentJournal([
        ...state.consignments,
      ]);
      if (validation.valid) {
        setIsProcessing(true);

        let query = {
          date: state?.date,
          transaction_type: "payable",
          transaction_context: "journal",
          journal_status: "unpaid",
          payment_from_type: "petrolbunk",
          payment_to_type: state?.journal_to,
          payment_mode: state?.payment_mode,
          vehicle: state?.vehicle?._id,
          petrolbunk: state?.petrol_bunk?._id,
          total_transaction_amount: state.total_transaction_amount,
          journal_reference_number: state.reference_number,
          consignments: validation.data,
        };
        console.log("query:", query);

        api
          .postService("accounts/transactions/payable", query)
          .then((response) => {
            console.log("response:", response);
            setIsProcessing(false);
            resp.ResponseHandler(response,'reload');
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        resp.Error(validation.msg);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function changeAmountJournal(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    // calcConsignmentJournal(value);
  }

  function changeTypeHeadInput(selected, name, type) {
    if (selected.length != 0) {
      let selected_data = selected[0];
      setState((prevState) => ({ ...prevState, [name]: selected_data }));

      if (name == "vehicle" || name == "supplier") {
        let id = selected[0]._id;

        // $("#data-table").DataTable({
        //   destroy: true,
        //   // columnDefs: [
        //   //   {
        //   //     orderable: false,
        //   //     targets: [0, 8],
        //   //   },
        //   // ],
        // });

        loadConsignments(name, id, selected_data);
      }
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function changeTypeHeadPetrolBunk(selected, name, type) {
    if (selected.length != 0) {
      let selected_data = selected[0];
      setState((prevState) => ({ ...prevState, [name]: selected_data }));

      if (type === "journal") {
        let id = selected[0]._id;
        loadPaymentJournal(name, id, selected_data);
      }
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }

    // const { name, value } = event.target;
    // let parsedValue = JSON.parse(value);
    // setState((prevState) => ({ ...prevState, [name]: parsedValue }));
    // setState((prevState) => ({
    //   ...prevState,
    //   input_fuel_balance: parsedValue.current_balance,
    // }));
    // if (state.payment_to_type === "journal") {
    //   loadPaymentJournal(parsedValue?._id);
    // }
  }

  // Fetching Payment Journal
  async function loadPaymentJournal(name, petrol_bunk_id, selected_data) {
    console.log("petrol_bunk_id:", petrol_bunk_id);
    setIsProcessing(true);

    await api
      .getService(`accounts/transactions/journal/all/${petrol_bunk_id}`)
      .then(async (response) => {
        console.log(response);
        const data = response.data;
        console.log("data:", data);
        let processedData = await calcBalancePaymentJournal(
          name,
          [...data.data],
          selected_data
        );
        console.log("processedData:", processedData);
        await setState((prevState) => ({
          ...prevState,
          payment_journal: data.data,
        }));

        // $("#payable-journal-datatable").DataTable({
        //   deferRender: true,
        //   dom: "Bfrtip",
        //   columnDefs: [
        //     {
        //       orderable: false,
        //       targets: [0, 2, 3, 4, 8, 9],
        //     },
        //   ],
        // });

        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
      });
  }
  // Fetching Default Metrics

  async function loadDefaultMetrics(event, key, reload = false) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";
    if (defaultState[key].length === 0 || reload) {
      switch (key) {
        case "supplier":
          url = "master/supplier/type/vehicle_supplier/active";
          await fetchDefaultMetrics(url, key);
          break;

        case "fuel":
          url = "master/supplier/type/petrol_bunk/active";
          await fetchDefaultMetrics(url, key);
          break;

        case "vehicle":
          url = "vehicle";
          await fetchDefaultMetrics(url, key);
          break;

        default:
          loadCommonMetrics(key);
          break;
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        console.log("default_metrics:", response.data);
        // if (key === "cash") {
        //   const data = response.data;

        //   setState((prevState) => ({
        //     ...prevState,
        //     cash_balance: toFloat(data[key][0]?.opening_balance) + toFloat(data[key][0]?.current_balance),
        //   }));
        // }

        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: response?.data?.data,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        setError(error);
      });
  }

  async function loadCommonMetrics(metric) {
    if (defaultState[metric] && defaultState[metric].length < 1) {
      setIsProcessing(true);
      var query = { metrix: [metric] };
      await api
        .postService("common", query)
        .then((response) => {
          console.log(response);
          const data = response.data;

          if (metric === "cash") {
            // Setting default value if one record is found
            if (data[metric].length == 1) {
              setState((prevState) => ({
                ...prevState,
                payment_cash: data[metric][0],
              }));
            }
          }

          if (metric === "bank") {
            // Setting default value if one record is found
            if (data[metric].length == 1) {
              setState((prevState) => ({
                ...prevState,
                payment_bank: data[metric][0],
              }));
            }
          }

          setDefaultState((prevState) => ({
            ...prevState,
            [metric]: response?.data[metric],
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    }
  }

  // Fetching Consignments
  async function loadConsignments(attribute, attribute_id, state_data) {
    setIsProcessing(true);
    await api
      .getService(
        `accounts/transactions/consignments/unpaid/${attribute}/${attribute_id}`
      )
      .then(async (response) => {
        const data = response.data;
        console.log("data", data);

        let consignmentsData = await calcBalanceCons(
          attribute,
          data.data,
          state_data
        );
        await setState((prevState) => ({
          ...prevState,
          consignments: consignmentsData,
        }));
        // await setConsignmentJournalTableData(consignment);

        // var table = $("#payable-data-table").DataTable();
        // table.ajax.reload();
        // $('#payable-data-table').DataTable().ajax.reload();

        // var table = await $("#payable-data-table").DataTable({
        //   deferRender: true,
        //   dom: "Bfrtip",
        // });
        // await table.ajax.reload();
        // await dataTable.fnDestroy();
        // $("#payable-data-table").DataTable();

        // $("#payable-data-table").DataTable({
        //   deferRender: true,
        //   dom: "Bfrtip",
        //   columnDefs: [
        //     {
        //       orderable: false,
        //       targets: [0, 8, 9],
        //     },
        //   ],
        // });

        // $("#payable-data-table").DataTable().data.reload();

        // await $("#payable-data-table").DataTable({
        //   columnDefs: [
        //     {
        //       orderable: false,
        //       targets: [0, 8, 9],
        //     },
        //   ],
        // });

        // table.data.reload();

        // $('#payable-data-table').data.reload();

        // table.destroy();
        // // $('#payable-data-table').empty();
        // table = $('#payable-data-table').DataTable()

        // var table = $("#payable-data-table").DataTable();

        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
      });
  }

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <Container>
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title>{pageTitle}</Container.Title>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/* <!-- BEGIN: Container Body --> */}
        <Container.Body>
          {/* <!-- BEGIN: Card --> */}
          <Card className="mt-5">
            {isProcessing && <ProcessLoader />}
            {/* <!-- BEGIN: Card Body --> */}
            <Card.Body>
              {/* <!-- BEGIN: Form --> */}
              <Form id="payables-form" onSubmit={onSubmit}>
                <Form.Row className="justify-center">
                  <Form.Col>
                    <Form.Label>Date</Form.Label>
                    <Form.Input
                      type="date"
                      max={htmlDate(new Date())}
                      className="form-control"
                      id="date"
                      name="date"
                      onChange={changeInput}
                      value={state?.date}
                      required
                    />
                  </Form.Col>

                  <Form.Col>
                    <Form.Label>Payment mode</Form.Label>
                    <select
                      id="payment_mode"
                      name="payment_mode"
                      className="form-select"
                      onChange={changePaymentMethod}
                      value={state.payment_mode}
                      required
                    >
                      <option value="" defaultValue disabled>
                        Choose...
                      </option>
                      <option value="cash">Cash</option>
                      <option value="bank">Bank</option>
                      <option value="journal">Journal</option>
                    </select>
                  </Form.Col>

                  {/* Cash Payment Details */}
                  {state.payment_mode == "cash" && (
                    <Form.Col>
                      <Form.Label>Cash Book</Form.Label>
                      <select
                        id="payment_cash"
                        name="payment_cash"
                        className="form-select"
                        onChange={changeInputPaymentMaster}
                        value={JSON.stringify(state?.payment_cash)}
                        required
                      >
                        <option value="">Choose...</option>
                        {defaultState.cash.map((item, index) => {
                          return (
                            <option value={JSON.stringify(item)} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="mt-2">
                        <label>
                          Cash Balance :{" "}
                          {formatCurrency(
                            state?.payment_cash?.opening_balance +
                              state?.payment_cash?.current_total
                          )}{" "}
                        </label>
                      </div>
                    </Form.Col>
                  )}
                  {/* Cash Payment Details */}

                  {/* Bank Payment Details */}
                  {state.payment_mode == "bank" && (
                    <Form.Col>
                      <Form.Label>Bank Account</Form.Label>
                      <select
                        id="payment_bank"
                        name="payment_bank"
                        className="form-select"
                        onChange={changeInputPaymentMaster}
                        value={JSON.stringify(state?.payment_bank)}
                        required
                      >
                        <option value="">Choose...</option>
                        {defaultState.bank.map((item, index) => {
                          return (
                            <option value={JSON.stringify(item)} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="mt-2">
                        <label>
                          Bank Balance :{" "}
                          {formatCurrency(
                            state?.payment_bank?.opening_balance +
                              state?.payment_bank?.current_total
                          )}{" "}
                        </label>
                      </div>
                    </Form.Col>
                  )}
                  {/* Bank Payment Details */}
                  {/* /////////JOURNAL CREATION//////////// */}
                  {state.payment_mode == "journal" && (
                    <>
                      <Form.Col>
                        <Form.Label>From</Form.Label>
                        <select
                          id="journal_from"
                          name="journal_from"
                          className="form-select"
                          onChange={changePaymentMethod}
                          value={state.journal_from}
                          required
                        >
                          <option value="">Choose...</option>
                          <option value="fuel">Petrol Bunk</option>
                        </select>
                      </Form.Col>

                      {state.journal_from == "fuel" && (
                        <Form.Col>
                          <Form.Label>Petrol Bunks</Form.Label>
                          <Typeahead
                            id="petrol_bunk"
                            name="petrol_bunk"
                            onChange={(selected) => {
                              changeTypeHeadPetrolBunk(
                                selected,
                                "petrol_bunk",
                                "direct"
                              );
                            }}
                            options={defaultState?.fuel}
                            labelKey={(option) => `${option?.name}`}
                            selected={formatTypeSearch(state?.petrol_bunk)}
                            inputProps={{ required: true }}
                            placeholder="Search petrol bunks..."
                          />
                        </Form.Col>
                      )}

                      <Form.Col>
                        <Form.Label>To</Form.Label>
                        <select
                          id="journal_to"
                          name="journal_to"
                          className="form-select"
                          onChange={changeJournalTo}
                          value={state.journal_to}
                          required
                        >
                          <option value="">Choose...</option>
                          <option value="vehicle">Vehicle</option>
                        </select>
                      </Form.Col>

                      {state.journal_to === "vehicle" && (
                        <Form.Col>
                          <Form.Label>Vehicles</Form.Label>
                          <Typeahead
                            id="vehicle"
                            name="vehicle"
                            onChange={(selected) => {
                              changeTypeHeadInput(
                                selected,
                                "vehicle",
                                "journal"
                              );
                            }}
                            options={defaultState?.vehicle}
                            labelKey={(option) =>
                              `${option?.registration_number}`
                            }
                            selected={formatTypeSearch(state?.vehicle)}
                            inputProps={{ required: true }}
                            placeholder="Search vehicles..."
                            className="text-uppercase"
                          />
                          <div className="mt-2">
                            <div className="inline">
                              <label>
                                Paid :{" "}
                                {formatCurrency(
                                  state?.vehicle?.total_amount_paid
                                )}{" "}
                              </label>
                            </div>
                            <div className="inline ml-2">
                              <label>
                                Balance:{" "}
                                {formatCurrency(state?.vehicle?.total_balance)}{" "}
                              </label>
                            </div>
                          </div>
                        </Form.Col>
                      )}
                    </>
                  )}
                  {/* /////////JOURNAL CREATION//////////// */}

                  {/* ///////// PAY TO - CONSIGNMENT (or) JOURNAL //////////// */}
                  {state.payment_mode != "journal" && (
                    <Form.Col>
                      <Form.Label>Pay to</Form.Label>
                      <select
                        id="payment_to_type"
                        name="payment_to_type"
                        className="form-select"
                        onChange={changePayTo}
                        value={state.payment_to_type}
                        required
                      >
                        <option value="">Choose...</option>
                        <option value="supplier">Vechicle Supplier</option>
                        {state.payment_mode != "journal" && (
                          <>
                            <option value="journal">Journal</option>
                          </>
                        )}
                      </select>
                    </Form.Col>
                  )}

                  {state.payment_to_type === "supplier" && (
                    <Form.Col>
                      <Form.Label>Vechicle Supplier</Form.Label>
                      <Typeahead
                        id="supplier"
                        name="supplier"
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "supplier", "direct");
                        }}
                        options={defaultState?.supplier}
                        labelKey={(option) => `${option?.name}`}
                        selected={formatTypeSearch(state?.supplier)}
                        inputProps={{ required: true }}
                        placeholder="Search Supplier..."
                      />
                      <div className="mt-2">
                        <div className="inline">
                          <label>
                            Paid :{" "}
                            {formatCurrency(state?.supplier?.total_amount_paid)}{" "}
                          </label>
                        </div>
                        <div className="inline ml-3">
                          <label>
                            Balance :{" "}
                            {formatCurrency(state?.supplier?.total_balance)}{" "}
                          </label>
                        </div>
                      </div>
                    </Form.Col>
                  )}

                  {state.payment_to_type == "journal" && (
                    <Form.Col>
                      <Form.Label>Petrol Bunks</Form.Label>
                      <Typeahead
                        id="petrol_bunk"
                        name="petrol_bunk"
                        onChange={(selected) => {
                          changeTypeHeadPetrolBunk(
                            selected,
                            "petrol_bunk",
                            "journal"
                          );
                        }}
                        options={defaultState?.fuel}
                        labelKey={(option) => `${option?.name}`}
                        selected={formatTypeSearch(state?.petrol_bunk)}
                        inputProps={{ required: true }}
                        placeholder="Search petrol bunks..."
                      />
                      <div className="mt-2">
                        <div className="inline">
                          <label>
                            Paid :{" "}
                            {formatCurrency(
                              state?.petrol_bunk?.total_amount_paid
                            )}{" "}
                          </label>
                        </div>
                        <div className="inline ml-3">
                          <label>
                            Balance :{" "}
                            {formatCurrency(state?.petrol_bunk?.total_balance)}{" "}
                          </label>
                        </div>
                      </div>
                    </Form.Col>
                  )}

                  <Form.Col>
                    <Form.Label>
                      {state?.payment_mode === "cash"
                        ? "Voucher No."
                        : state.payment_mode === "bank"
                        ? "Transaction Id"
                        : "Reference No"}
                    </Form.Label>
                    <Form.Input
                      type="text"
                      className="form-control"
                      id="reference_number"
                      name="reference_number"
                      onChange={changeInput}
                      value={state.reference_number}
                      required
                    />
                  </Form.Col>

                  {/* ///////// PAY TO - CONSIGNMENT (or) JOURNAL //////////// */}
                </Form.Row>

                {/* <!-- BEGIN : JOURNAL CREATION Table */}
                {state.payment_mode === "journal" && (
                  <>
                    <div className="p-15">
                      <DividerText>Consignment details</DividerText>
                      {/* Total transaction amount display */}
                      <div className="w-100" id="amount-details">
                        <div className="float-right mb-3">
                          <div className="inline bg-theme-7 text-white px-3 py-1">
                            Total :{" "}
                            <span>
                              {formatCurrency(
                                state?.total_transaction_amount
                              ) || 0}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Total transaction amount display */}

                      <table id="data-table" className="table">
                        <thead className="bg-gray-200 text-gray-700">
                          <tr>
                            <th scope="col">
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  checked={isSelectAll}
                                  name="select_all"
                                  onChange={(event) => {
                                    selectAll(event, "consignments");
                                  }}
                                />
                              </div>
                            </th>
                            <th scope="col">Cons. Id</th>
                            <th scope="col">LR No.</th>
                            <th scope="col">Date</th>
                            <th scope="col">Amount Payable</th>
                            <th scope="col">Amount Paid</th>
                            <th scope="col">Journal</th>
                            <th scope="col">Balance</th>
                            <th scope="col">To Pay</th>
                            <th scope="col">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state?.consignments.length > 0 &&
                            state?.consignments?.map((row, index) => {
                              return (
                                <tr
                                  className={
                                    row?.selected ? "bg-light-success" : ""
                                  }
                                  key={index}
                                >
                                  {/* Check Box */}
                                  <td className="border-b dark:border-dark-5">
                                    <div className="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={row?.selected}
                                        name="check_record"
                                        onChange={(event) => {
                                          selectRecord(
                                            event,
                                            "consignments",
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                  </td>
                                  {/* Check Box */}

                                  {/* Consignment Id */}
                                  <td className="border-b dark:border-dark-5">
                                    {row?.consignment_id}
                                  </td>
                                  {/* LR Number */}
                                  <td className="border-b dark:border-dark-5">
                                    {row?.load_receipts?.map((item, index) => {
                                      return <>{item?.lr_number},</>;
                                    })}
                                  </td>

                                  {/* Date */}
                                  <td className="border-b dark:border-dark-5">
                                    {localDate(row?.consignment_date)}
                                  </td>

                                  {/* Amount Payable */}
                                  <td className="border-b dark:border-dark-5 ">
                                    {formatCurrency(row?.total_amount)}
                                  </td>
                                  {/* Amount Paid */}
                                  <td className="border-b dark:border-dark-5 ">
                                    {formatCurrency(row?.total_amount_paid)}
                                  </td>
                                  {/* Journal */}
                                  <td className="border-b dark:border-dark-5 ">
                                    {formatCurrency(row?.journal)}
                                  </td>

                                  {/* Balance */}
                                  <td className="border-b dark:border-dark-5 ">
                                    {formatCurrency(row?.balance)}
                                  </td>
                                  {/* To Pay */}
                                  <td className="border-b dark:border-dark-5">
                                    <CurrencyInput
                                      type="number"
                                      className="form-control"
                                      id="new_amount_paid"
                                      name="new_amount_paid"
                                      onChange={(event) => {
                                        changeInputArrayObject(
                                          event,
                                          "consignments",
                                          index
                                        );
                                      }}
                                      value={row?.new_amount_paid}
                                    />

                                    <ValidationError
                                      msg={
                                        validationError?.consignments[index]
                                          ?.new_amount_paid
                                      }
                                    />
                                  </td>

                                  {/* Remarks */}
                                  <td className="border-b dark:border-dark-5">
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      id="remarks"
                                      name="remarks"
                                      onChange={(event) => {
                                        changeInputArrayObject(
                                          event,
                                          "consignments",
                                          index
                                        );
                                      }}
                                      value={row?.remarks}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
                {/* <!-- END : JOURNAL CREATION Table */}

                {/* <!-- BEGIN : CONSIGNMENT Table */}
                {state.payment_to_type === "supplier" &&
                  state.payment_mode !== "journal" && (
                    <>
                      <div className="p-15">
                        <DividerText>Consignment details</DividerText>
                        {/* Total transaction amount display */}
                        <div className="w-100" id="amount-details">
                          <div className="float-right mb-3">
                            <div className="inline bg-theme-7 text-white px-3 py-1">
                              Total :{" "}
                              <span>
                                {formatCurrency(
                                  state?.total_transaction_amount
                                ) || 0}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* Total transaction amount display */}
                        <table id="payable-data-table" className="table">
                          <thead>
                            <tr className="bg-gray-200 text-gray-700">
                              <th scope="col">#</th>
                              <th scope="col">Cons. Id</th>
                              <th scope="col">LR No.</th>
                              <th scope="col">Date</th>
                              <th scope="col">Amount Payable</th>
                              {/* <th scope="col">Status</th> */}
                              <th scope="col">Amount Paid</th>
                              <th scope="col">Journal</th>
                              <th scope="col">Balance</th>
                              <th scope="col">To Pay</th>
                              <th scope="col">Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state?.consignments.length > 0 &&
                              state?.consignments?.map((row, index) => {
                                return (
                                  <tr
                                    className={
                                      row?.selected ? "bg-light-success" : ""
                                    }
                                    key={index}
                                  >
                                    {/* Check Box */}
                                    <td className="border-b dark:border-dark-5">
                                      <div className="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={row?.selected}
                                          name="check_record"
                                          onChange={(event) => {
                                            selectRecord(
                                              event,
                                              "consignments",
                                              index
                                            );
                                          }}
                                        />
                                      </div>
                                    </td>
                                    {/* Check Box */}
                                    {/* Consignment Id */}
                                    <td className="border-b dark:border-dark-5">
                                      {row?.consignment_id}
                                    </td>
                                    {/* LR Number */}
                                    <td className="border-b dark:border-dark-5">
                                      {row?.load_receipts?.map(
                                        (item, index) => {
                                          return <>{item?.lr_number},</>;
                                        }
                                      )}
                                    </td>
                                    {/* Date */}
                                    <td className="border-b dark:border-dark-5">
                                      {localDate(row?.consignment_date)}
                                    </td>

                                    {/* Amount Payable */}
                                    <td className="border-b dark:border-dark-5 ">
                                      {formatCurrency(row?.total_amount)}
                                    </td>

                                    {/* Amount Paid */}
                                    <td className="border-b dark:border-dark-5 ">
                                      {formatCurrency(row?.total_amount_paid)}
                                    </td>
                                    {/* Journal */}
                                    <td className="border-b dark:border-dark-5 ">
                                      {formatCurrency(row?.journal)}
                                    </td>
                                    {/* Balance */}
                                    <td className="border-b dark:border-dark-5 ">
                                      {formatCurrency(row?.balance)}
                                    </td>
                                    {/* To Pay */}
                                    <td className="border-b dark:border-dark-5">
                                      <CurrencyInput
                                        type="number"
                                        className="form-control"
                                        id="new_amount_paid"
                                        name="new_amount_paid"
                                        onChange={(event) => {
                                          changeInputArrayObject(
                                            event,
                                            "consignments",
                                            index
                                          );
                                        }}
                                        value={row?.new_amount_paid}
                                      />

                                      <ValidationError
                                        msg={
                                          validationError?.consignments[index]
                                            ?.new_amount_paid
                                        }
                                      />
                                    </td>

                                    {/* Remarks */}
                                    <td className="border-b dark:border-dark-5">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="remarks"
                                        name="remarks"
                                        onChange={(event) => {
                                          changeInputArrayObject(
                                            event,
                                            "consignments",
                                            index
                                          );
                                        }}
                                        value={row?.remarks}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                {/* <!-- END : CONSIGNMENT Table */}

                {/* <!-- BEGIN : Journal Table */}
                {state.payment_to_type === "journal" &&
                  state.payment_mode !== "journal" && (
                    <>
                      <div className="p-15">
                        <DividerText>Journal details</DividerText>

                        {/* Total transaction amount display */}
                        <div className="w-100" id="amount-details">
                          <div className="float-right mb-3">
                            <div className="inline bg-theme-7 px-3 py-1 text-white">
                              Total :{" "}
                              <span>
                                {formatCurrency(
                                  state?.total_transaction_amount
                                ) || 0}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* Total transaction amount display */}

                        <table id="payable-journal-datatable" className="table">
                          <thead>
                            <tr className="bg-gray-200 text-gray-700">
                              <th scope="col">#</th>
                              <th scope="col">Date</th>
                              <th scope="col">Ref. No.</th>
                              <th scope="col">Cons. Id</th>
                              <th scope="col">Vehicle Reg. No.</th>
                              <th scope="col">Transaction Amount</th>
                              <th scope="col">Amount Paid</th>
                              <th scope="col">Balance</th>
                              <th scope="col">To Pay</th>
                              <th scope="col">Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state?.payment_journal.length > 0 &&
                              state?.payment_journal?.map((row, index) => {
                                return (
                                  <tr
                                    className={
                                      row?.selected ? "bg-light-success" : ""
                                    }
                                    key={index}
                                  >
                                    {/* Check Box */}
                                    <td className="border-b dark:border-dark-5">
                                      <div className="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={row?.selected}
                                          name="check_record"
                                          onChange={(event) => {
                                            selectRecord(
                                              event,
                                              "payment_journal",
                                              index
                                            );
                                          }}
                                        />
                                      </div>
                                    </td>
                                    {/* Check Box */}
                                    {/* Date */}
                                    <td className="border-b dark:border-dark-5">
                                      {localDate(row?.date)}
                                    </td>
                                    {/* Ref No */}
                                    <td className="border-b dark:border-dark-5">
                                      {row?.journal_reference_number}
                                    </td>
                                    {/* Consignment Id */}
                                    <td className="border-b dark:border-dark-5">
                                      {row?.consignment?.consignment_id}
                                    </td>

                                    {/* Vehicle Reg. No. */}
                                    <td className="border-b dark:border-dark-5">
                                      {row?.vehicle?.registration_number}
                                    </td>

                                    {/* Transaction Amount */}
                                    <td className="border-b dark:border-dark-5 ">
                                      {formatCurrency(row?.transaction_amount)}
                                    </td>
                                    {/* Amount Paid */}
                                    <td className="border-b dark:border-dark-5 ">
                                      {formatCurrency(row?.total_amount_paid)}
                                    </td>
                                    {/* Amount Paid */}
                                    <td className="border-b dark:border-dark-5 ">
                                      {formatCurrency(row?.balance)}
                                    </td>

                                    {/* To Pay */}
                                    <td className="border-b dark:border-dark-5">
                                      <CurrencyInput
                                        type="number"
                                        className="form-control"
                                        id="new_amount_paid"
                                        name="new_amount_paid"
                                        onChange={(event) => {
                                          changeInputArrayObject(
                                            event,
                                            "payment_journal",
                                            index
                                          );
                                        }}
                                        value={row?.new_amount_paid}
                                      />

                                      <ValidationError
                                        msg={
                                          validationError?.payment_journal[
                                            index
                                          ]?.new_amount_paid
                                        }
                                      />
                                    </td>

                                    {/* Remarks */}
                                    <td className="border-b dark:border-dark-5">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="remarks"
                                        name="remarks"
                                        onChange={(event) => {
                                          changeInputArrayObject(
                                            event,
                                            "payment_journal",
                                            index
                                          );
                                        }}
                                        value={row?.remarks}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                {/* <!-- END : Journal Table */}
                <input type="submit" id="save_button" className="hidden" />
              </Form>
              {/* <!-- END: Form --> */}
            </Card.Body>
            {/* <!-- END: Card Body --> */}
            <Card.Footer className="justify-end">
              <div>
                <ValidationError
                  msg={!isValidForm.valid ? isValidForm.msg : ""}
                  notify={true}
                  className="mr-2"
                />
                <button className="btn btn-secondary mr-2" onClick={goBack}>
                  Cancel
                </button>

                <label
                  type="button"
                  className="btn btn-primary"
                  htmlFor="save_button"
                >
                  Create Transaction
                </label>
              </div>
            </Card.Footer>
          </Card>
          {/* <!-- END: Card --> */}
        </Container.Body>
        {/* <!-- END: Container Body --> */}
      </Container>
    );
  }
}
