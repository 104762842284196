import React, { useState, useEffect } from "react";
// Page Defaults
import Preloader from "../../components/preloader/preloader";
import Error from "../../components/error/error";

import { storageServices } from "../../functions/storage";
// Pages

export default function Home() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [localUser, setLocalUser] = useState();


  useEffect(() => {
    const loadData = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      await setLocalUser(localUserData);

      setIsLoaded(true);
    };
    loadData();
  }, []);

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <>
        <div className="content">
            Home page
        </div>
      </>
    );
  }
}
