import React from "react";
import PropTypes from "prop-types";
import { formatKm } from "../../functions/common";
export default function Component(props) {
  const { className, id, value, ...rest } = props;

  return (
    <div className="text-gray-500 mt-1">Current Odometer: {formatKm(value)}</div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
};

Component.defaultProps = {
  className: "",
  id: "",
  value: "",
};
