import React, { useState, useEffect } from "react";
// HTML Elements
import ProcessLoader from "../../preloader/processLoader";
import ImageNFileInput from "../../inputs/imagenfile";
import ValidationError from "../../error/validationError";
import { Modal } from "react-bootstrap";
import KmInput from "../../htmlElements/kmInput";
import TypeAhAddNewButton from "../../htmlElements/typeAhAddNewButton";
import TruckNumberDisplay from "../../consignment/truckNumberDisplay";
import CurrencyInput from "../../htmlElements/currencyInput";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

import Form from "../../form/custom";
import DividerText from "../../htmlElements/dividerText";

// Add-on Components
import VehicleMaster from "../../master/vehicle";
import SupplierMasterForm from "../../master/supplier";
import CashMasterForm from "../../master/accounts/cash";
import BankMasterForm from "../../master/accounts/bank";

// Functions
import { storageServices } from "../../../functions/storage";

import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";
import {
  htmlDate,
  matchData,
  JsonToFormData,
  formatTypeSearch,
  clearNullValues,
  isDefined,
} from "../../../functions/common";
import * as validation from "../../../functions/validation";

// Models
import { Model, ValidationErrorModel } from "./model";

const fileBaseURL = process.env.REACT_APP_VEHICLE_URL;
export default function TyreMasterForm(props) {
  const { request_module, vechicleData, tyre } = props;

  const [disabledFields, setDisabledFields] = useState({
    consignment: false,
    vehicle: false,
    tyre: false,
  });
  const [saveToDB, setSaveToDB] = useState(true);
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
    approve: false,
  });

  // State
  const [state, setState] = useState({ ...Model });
  const [validationError, setValidationError] = useState({
    ...ValidationErrorModel,
  });

  const [localUser, setLocalUser] = useState();

  //////////// External Modal States   ////////////

  //////////// External Modal States   ////////////

  const [record_id, setRecordId] = useState();
  const [defaultState, setDefaultState] = useState({
    vehicle: [],
    consignment: [],
    tyre: [],
    supplier: [],
    cash: [],
    bank: [],
  });

  const [typeAheadFields, setTypeAheadFields] = useState({
    vehicle: "",
  });

  const [pageTitle, setPageTitle] = useState("Tyre Service Entry Form");
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  const [supplierMasterModal, setSupplierMasterModal] = useState({
    show: false,
    title: "Add Supplier",
    action: "create",
    id: "",
    supplier_type: "",
  });

  const [cashMasterModal, setCashMasterModal] = useState({
    show: false,
    title: "Add Cash Book",
    action: "create",
    id: "",
  });

  const [bankMasterModal, setBankMasterModal] = useState({
    show: false,
    title: "Add Bank Account",
    action: "create",
    id: "",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction(props);
  }, []);

  // <!-- BEGIN: Supplier Master Modal -- >
  function addSupplierMaster(supplier_type) {
    let prevState = { ...supplierMasterModal };
    prevState.supplier_type = supplier_type;
    prevState.action = "create";
    prevState.show = true;
    setSupplierMasterModal(prevState);
  }
  const closeSupplierMasterModal = () =>
    setSupplierMasterModal((prevState) => ({ ...prevState, show: false }));

  function reloadSupplierMasterData(data, supplier_type) {
    closeSupplierMasterModal();
    let event = {};
    loadDefaultMetrics(event, supplier_type, true);
  }
  // <!-- END: Supplier Master Modal -- >

  // <!-- BEGIN: Cash Master Modal -- >
  const openCashMasterModal = () =>
    setCashMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeCashMasterModal = () =>
    setCashMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadCashMasterData(data) {
    closeCashMasterModal();
    let event = {};
    loadDefaultMetrics(event, "cash", true);
  }
  // <!-- END: Cash Master Modal -- >

  // <!-- BEGIN: Bank Master Modal -- >
  const openBankMasterModal = () =>
    setBankMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeBankMasterModal = () =>
    setBankMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadBankMasterData(data) {
    closeBankMasterModal();
    let event = {};
    loadDefaultMetrics(event, "bank", true);
  }
  // <!-- END: Bank Master Modal -- >

  async function handleUserAction(props) {
    let action = props.action;
    let id = props.id;

    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    switch (action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setDefaults(props);

        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(id);
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(id);
        break;

      default:
        setMode((prevState) => ({ ...prevState, view: true }));
        break;
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };
    prevState.status = "active";
    prevState.date = htmlDate(new Date());
    prevState.payment_mode = "cash";

    // Checking Requesting Module
    if (isDefined(tyre)) {
      prevState["tyre"] = tyre;
      setDisabledFields((prevState) => ({ ...prevState, tyre: true }));
      if (tyre["mode"] === "vehicle") {
        prevState["vehicle"] = tyre["vehicle"];
        setDisabledFields((prevState) => ({ ...prevState, vehicle: true }));
      }
    }

    setState(prevState);
    setIsProcessing(false);
    return null;
  }
  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`tyre_service/${id}`).then(
      async (result) => {
        const data = result.data;
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let matchedData = await matchData(state, data);
    matchedData = await clearNullValues(matchedData);
    matchedData.date = htmlDate(matchedData?.date);

    if (request_module === "tyre-view") {
      setDisabledFields((prevState) => ({ ...prevState, tyre: true }));
    }

    await setState(matchedData);
  }

  // Input Change
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    formValidation(name, selected);
  }

  function changeInput(event) {
    const { name, value, required } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  function changeCheckBox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  async function changeInputImage(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImage(files[0]);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  async function changeInputFile(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImageNFile(files[0], 5);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  function deleteInputImage(event, name) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [name]: "" }));
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = validationError;
    switch (name) {
      default:
        break;
    }
    // setValidationError(errors);
  }

  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  async function prepareData(data) {
    let newData = data;
    // checking vehicle
    if (isDefined(newData.vehicle)) {
      newData["vehicle"] = data?.vehicle?._id;
    } else {
      newData["vehicle"] = null;
    }
    // Consignment
    if (isDefined(newData.consignment)) {
      newData["consignment"] = data?.consignment?._id;
    } else {
      newData["consignment"] = null;
    }
    //Cash
    if (isDefined(newData.cash)) {
      newData["cash"] = data?.cash?._id;
    } else {
      newData["cash"] = null;
    }

    //Bank
    if (isDefined(newData.bank)) {
      newData["bank"] = data?.bank?._id;
    } else {
      newData["bank"] = null;
    }

    newData["supplier"] = data?.supplier?._id;
    newData["tyre"] = data?.tyre?._id;

    return newData;
  }
  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));

      let data = await prepareData({ ...state });

      if (saveToDB) {
        setIsProcessing(true);
        // var query = await JsonToFormData(data);
        var query = data;
        api
          .postService("tyre_service", query)
          .then((response) => {
            setIsProcessing(false);
            resp.Success(response?.data?.message);
            return props.callBack(data, event);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        return props.callBack(data, event);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = await prepareData({ ...state });

      api
        .putService(`tyre_service/${record_id}`, data)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function switchBoolean(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  async function loadDefaultMetrics(event, key) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (defaultState[key].length === 0) {
      switch (key) {
        case "vehicle":
          url = "vehicle/all/own";
          await fetchDefaultMetrics(url, key);
          break;

        case "consignment":
          url = "consignment/status/all/all";
          await fetchDefaultMetrics(url, key);
          break;

        case "tyre":
          url = "tyre";
          await fetchDefaultMetrics(url, key);
          break;

        case "cash":
          url = `master/cash`;
          await fetchDefaultMetrics(url, key);
          break;

        case "bank":
          url = `master/bank`;
          await fetchDefaultMetrics(url, key);
          break;

        case "supplier":
          url = `master/supplier/type/service_vendor/all`;
          await fetchDefaultMetrics(url, key);
          break;

        default:
          break;
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;

        console.log("data:", data);
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        setError(error);
      });
  }

  function mapDefaultStateData(key, data) {
    let result = [];
    if (key === "brand") {
      for (var i in data) {
        result.push(data[i]["name"]);
      }
    } else if (key === "sub_brand") {
      for (var i in data) {
        result.push(data[i]["sub_brand"]);
      }
    } else if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
      result = data;
    } else {
      result = data;
    }

    return result;
  }

  function changeInputTypeaheadFields(text, field) {
    setTypeAheadFields((prevState) => ({ ...prevState, [field]: text }));
  }

  return (
    <div
      key="tyre_repair_modal"
      id="tyre_repair_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Add on Components --> */}
      <>
        {!mode.view && supplierMasterModal.show && (
          <SupplierMasterForm
            show={supplierMasterModal.show}
            action={supplierMasterModal.action}
            supplier_type={supplierMasterModal.supplier_type}
            title={supplierMasterModal.title}
            onHide={closeSupplierMasterModal}
            callBack={reloadSupplierMasterData}
          />
        )}

        {!mode.view && cashMasterModal.show && (
          <CashMasterForm
            show={cashMasterModal.show}
            action={cashMasterModal.action}
            title={cashMasterModal.title}
            onHide={closeCashMasterModal}
            callBack={reloadCashMasterData}
          />
        )}

        {!mode.view && bankMasterModal.show && (
          <BankMasterForm
            show={bankMasterModal.show}
            action={bankMasterModal.action}
            title={bankMasterModal.title}
            onHide={closeBankMasterModal}
            callBack={reloadBankMasterData}
          />
        )}
      </>
      {/* <!-- END: Add on Components --> */}

      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="tyre_repair-modal"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">Tyre Service Entry</h2>
        </Modal.Header>

        {/* <!-- BEGIN: Modal Body --> */}
        <Modal.Body>
          {isProcessing && <ProcessLoader />}

          {/* <!-- BEGIN: Form --> */}
          <Form
            id="tyre_repair-form"
            name="tyre_repair-form"
            onSubmit={
              mode.create
                ? onSubmit
                : mode.edit
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            {/* <!-- BEGIN : Top Details  -- > */}
            <>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="">Service Date</Form.Label>
                  <Form.Input
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.date}
                  />
                </div>

                <div className="col-span-12 md:col-span-6">
                  <Form.Label>
                    Consignment{" "}
                    <small className="text-xs text-gray-500 ml-1">
                      (Optional)
                    </small>
                  </Form.Label>
                  <Typeahead
                    clearButton
                    id="consignment"
                    name="consignment"
                    filterBy={["consignment_id"]}
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "consignment");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "consignment");
                    }}
                    options={defaultState?.consignment}
                    labelKey={(option) => `${option?.consignment_id || ""}`}
                    selected={formatTypeSearch(state?.consignment || "")}
                    placeholder="Search Consignment..."
                    disabled={mode.view || disabledFields?.consignment}
                  />
                  <ValidationError msg={validationError.consignment} />
                </div>

                {/* <div className="col-span-12 md:col-span-6">
                  <Form.Label className="required">Vehicle</Form.Label>
                  <Typeahead
                    clearButton
                    id="vehicle"
                    name="vehicle"
                    filterBy={["registration_number", "vehicleType"]}
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "vehicle");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "vehicle");
                    }}
                    onInputChange={(text) => {
                      changeInputTypeaheadFields(text, "vehicle");
                    }}
                    options={defaultState?.vehicle}
                    labelKey={(option) =>
                      `${option?.registration_number || ""}`
                    }
                    selected={formatTypeSearch(state?.vehicle || "")}
                    inputProps={{ required: true }}
                    placeholder="Search vehicle..."
                    disabled={mode.view || disabledFields?.vehicle}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                    
                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={index}
                          >
                            <TruckNumberDisplay
                              data={result}
                              id={"vehicle_" + result?._id}
                            />
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />
                  <ValidationError msg={validationError.vehicle} />
                </div> */}

                <div className="col-span-12 md:col-span-6">
                  <Form.Label>Tyre</Form.Label>
                  <Typeahead
                    clearButton
                    id="tyre"
                    name="tyre"
                    filterBy={["serial_no"]}
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "tyre");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "tyre");
                    }}
                    options={defaultState?.tyre}
                    labelKey={(option) => `${option?.serial_no || ""}`}
                    selected={formatTypeSearch(state?.tyre || "")}
                    placeholder="Search Tyre..."
                    disabled={mode.view || disabledFields?.tyre}
                  />
                  <ValidationError msg={validationError.tyre} />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">Service Type</Form.Label>
                  <select
                    className="form-select"
                    onChange={changeInput}
                    name="service_type"
                    value={state.service_type}
                    disabled={mode.view}
                    required
                  >
                    <option value="" defaultValue></option>
                    <option value="Rotation">Rotation</option>
                    <option value="Puncture">Puncture</option>
                    <option value="Tube Replacement">Tube Replacement</option>
                    <option value="Flap">Flap</option>
                    <option value="Maintenance">Maintenance</option>
                  </select>
                  <ValidationError msg={validationError.service_type} />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">Service Vendor</Form.Label>
                  <Typeahead
                    id="supplier"
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "supplier");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "supplier");
                    }}
                    options={defaultState?.supplier}
                    labelKey={(option) => `${option?.name}`}
                    selected={formatTypeSearch(state?.supplier)}
                    placeholder="Select Vendor..."
                    disabled={mode.view}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        <TypeAhAddNewButton
                          onClick={(e) => addSupplierMaster("service_vendor")}
                        ></TypeAhAddNewButton>
                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={index}
                          >
                            {result?.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />

                  <ValidationError msg={validationError.supplier} />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="">Vehicle Odometer</Form.Label>
                  <KmInput
                    className="form-control"
                    id="odometer_reading"
                    name="odometer_reading"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.odometer_reading}
                  />
                </div>
              </div>
            </>
            {/* <!-- END :Top Details -- > */}
            {/* <!-- BEGIN : Payment Details  -- > */}
            <>
              <DividerText>Payment Details</DividerText>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12">
                  <div className="mt-3 text-center">
                    <label>Payment Mode</label>
                    <div className="flex flex-col sm:flex-row mt-2 items-center justify-center">
                      <div className="form-check mr-2">
                        {" "}
                        <input
                          id="payment_mode_cash"
                          className="form-check-input"
                          type="radio"
                          name="payment_mode"
                          value="cash"
                          checked={state?.payment_mode === "cash"}
                          onChange={changeInput}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="payment_mode_cash"
                        >
                          Cash
                        </label>{" "}
                      </div>

                      <div className="form-check mr-2">
                        {" "}
                        <input
                          id="payment_mode_bank"
                          className="form-check-input"
                          type="radio"
                          name="payment_mode"
                          value="bank"
                          checked={state?.payment_mode === "bank"}
                          onChange={changeInput}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="payment_mode_bank"
                        >
                          Bank
                        </label>{" "}
                      </div>

                      <div className="form-check mr-2">
                        {" "}
                        <input
                          id="payment_mode_credit"
                          className="form-check-input"
                          type="radio"
                          name="payment_mode"
                          value="credit"
                          checked={state?.payment_mode === "credit"}
                          onChange={changeInput}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="payment_mode_credit"
                        >
                          Credit
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- BEGIN: Payment Mode Options--> */}
                <>
                  {state?.payment_mode === "cash" && (
                    <div className="col-span-12 sm:col-span-6">
                      <label htmlFor="cash_master" className="form-label">
                        Cash Book{" "}
                        <small className="text-xs text-gray-500">
                          (Optional)
                        </small>
                      </label>
                      <Typeahead
                        id="cash_master"
                        onFocus={(event) => {
                          loadDefaultMetrics(event, "cash");
                        }}
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "cash");
                        }}
                        options={defaultState?.cash}
                        labelKey={(option) => `${option?.name}`}
                        selected={formatTypeSearch(state?.cash)}
                        placeholder="Select Cash Book..."
                        disabled={mode.view}
                        renderMenu={(results, menuProps) => (
                          <Menu {...menuProps}>
                            <TypeAhAddNewButton
                              onClick={openCashMasterModal}
                            ></TypeAhAddNewButton>
                            {results.map((result, index) => (
                              <MenuItem
                                option={result}
                                position={index}
                                key={index}
                              >
                                {result?.name}
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      />
                    </div>
                  )}

                  {state?.payment_mode === "bank" && (
                    <div className="col-span-12 sm:col-span-6">
                      <label htmlFor="bank_master" className="form-label">
                        Bank Account{" "}
                        <span className="text-xs text-gray-500">
                          (Optional)
                        </span>
                      </label>
                      <Typeahead
                        id="bank_master"
                        onFocus={(event) => {
                          loadDefaultMetrics(event, "bank");
                        }}
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "bank");
                        }}
                        options={defaultState?.bank}
                        labelKey={(option) => `${option?.name}`}
                        selected={formatTypeSearch(state?.bank)}
                        placeholder="Select Bank A/C..."
                        disabled={mode.view}
                        renderMenu={(results, menuProps) => (
                          <Menu {...menuProps}>
                            <TypeAhAddNewButton
                              onClick={openBankMasterModal}
                            ></TypeAhAddNewButton>
                            {results.map((result, index) => (
                              <MenuItem
                                option={result}
                                position={index}
                                key={index}
                              >
                                {result?.name}
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      />
                    </div>
                  )}
                </>
                {/* <!-- END: Payment Mode Options--> */}

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">Amount</Form.Label>
                  <CurrencyInput
                    className="form-control"
                    id="amount"
                    name="amount"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.amount}
                    required
                  />
                  <ValidationError msg={validationError.amount} />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label
                    htmlFor="payment_reference_number"
                    className="form-label"
                  >
                    {state?.payment_mode === "cash"
                      ? "Voucher No."
                      : state.payment_mode === "bank"
                      ? "Transaction Id"
                      : "Reference No."}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="payment_reference_number"
                    name="payment_reference_number"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.payment_reference_number}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="remarks" className="form-label">
                    Remarks
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="remarks"
                    name="remarks"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.remarks}
                  />
                </div>
              </div>
            </>

            {/* <!-- END : Payment Details  -- > */}

            <input
              type="submit"
              id="save_tyre_repair_slip"
              className="hidden"
            />
            <input
              type="submit"
              id="update_tyre_repair_slip"
              className="hidden"
            />
          </Form>
          {/* <!-- END: Form --> */}

          {/* <!-- END: Card --> */}
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}

        <Modal.Footer className="justify-end">
          <div className="">
            <div className="mr-2">
              <ValidationError
                msg={!isValidForm.valid ? isValidForm.msg : ""}
                notify={true}
              />
            </div>
            <label
              type="button"
              className="btn btn-secondary mr-2 "
              onClick={props.onHide}
            >
              Cancel
            </label>

            {mode.create && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="save_tyre_repair_slip"
              >
                Save
              </label>
            )}
            {mode.edit && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="update_tyre_repair_slip"
              >
                Update
              </label>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
