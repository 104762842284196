const countryListFile = require("../../utils/country_state_list.json");
const country_state_list = countryListFile.countries;
// const countryList = country_state_list.map((a) => a.country);
const countryList = ["India"];

const Model = {
  _id: "",
  name: "",
  email: "",
  address: "",
  phone: "",
  website: "",
  contact_person: "",
  logo: "",
  gst: "",
  pan_number: "",
  business_type: "",
  activated: true,
  approved: true,
  // branch_name: "",
  // branch_address: "",

  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",

  isLoaded: false,
  activeStep: 1,
  loading: false,
  error: false,
  stateList: [],
  countryList: countryList,
  countryStateList: country_state_list,
};
const ValidationErrorModel = {
  name: "",
  email: "",
  address: "",
  phone: "",
  website: "",
  business_type: "",
  contact_person: "",
  logo: "",
  gst: "",
  pan_number: "",
  activated: "",
  approved: "",
};

export { Model };
export { ValidationErrorModel };
