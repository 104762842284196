import React, { useState, useEffect } from "react";
import {
  formatCurrency,
  trimConsNum,
  localDate,
  formatKm,
  formatLitres,
  formatMileage,
} from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import SimpleTab from "../tabs/simpleTab";

export default function Template(props) {
  const Title = "Vehicle Card";
  const consTableRef = React.createRef();
  const tyresTableRef = React.createRef();
  const fuelTableRef = React.createRef();
  const { data, filter } = props;
  const [state, setState] = useState();
  const [isProcessing, setIsProcessing] = useState(false);

  // Data

  const [consignmentsTableData, setConsignmentsTableData] = useState([]);
  const [fuelTableData, setFuelTableData] = useState([]);
  const [tyresTableData, setTyresTableData] = useState([]);
  const [currentTab, setCurrentTab] = useState("consignments");

  useEffect(() => {
    loadData(data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);
    setState(data);
    let mappedData = await mapData(data);
    // Consignments
    let consTableData = await mapConsignmentsTableData(
      mappedData["consignments"]
    );
    setConsignmentsTableData(consTableData);
    // Tyres
    let tyresTableData = await mapTyresTableData(mappedData["tyres"]);
    setTyresTableData(tyresTableData);
    //

    setIsProcessing(false);
  }

  function mapData(data) {
    return data;
  }

  function mapConsignmentsTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "date",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Cons. No.",
        field: "consignment_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 120,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return trimConsNum(cell?.getValue());
        },
      },
      {
        title: "Client",
        field: "client",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Origin",
        field: "origin",
        vertAlign: "middle",
        headerSort: false,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Destination",
        field: "destination",
        vertAlign: "middle",
        headerSort: false,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Driver",
        field: "driver",
        vertAlign: "middle",
        headerSort: false,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Freight Amount",
        field: "consignment_freight",
        vertAlign: "middle",
        headerSort: true,
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Expenses",
        field: "consignment_addition",
        vertAlign: "middle",
        headerSort: false,
        formatter: function (cell) {
          return formatCurrency(cell.getValue());
        },
      },
      {
        title: "Deductions",
        field: "consignment_deduction",
        vertAlign: "middle",
        headerSort: false,
        formatter: function (cell) {
          return formatCurrency(cell.getValue());
        },
      },
      {
        title: "Total",
        field: "consignment_total_amount",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Fuel Filled",
        field: "fuel_filled",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatLitres(cell?.getValue());
        },
      },
      {
        title: "Fuel Cost",
        field: "fuel_cost",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Fuel Used",
        field: "fuel_used",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatLitres(cell?.getValue());
        },
      },
      {
        title: "Mileage",
        field: "mileage",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatMileage(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function mapTyresTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Serial No.",
        field: "tyre.serial_no",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Brand",
        field: "tyre.brand",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Position",
        field: "position",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Fitted On",
        field: "fitment_date",
        vertAlign: "middle",
        headerSort: true,
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Fitted Odometer",
        field: "fitment_odometer",
        vertAlign: "middle",
        headerSort: false,
        formatter: function (cell) {
          return formatKm(cell.getValue());
        },
      },
      {
        title: "Removed On",
        field: "removal_date",
        vertAlign: "middle",
        headerSort: true,
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Removed Odometer",
        field: "removal_odometer",
        vertAlign: "middle",
        headerSort: false,
        formatter: function (cell) {
          return formatKm(cell.getValue());
        },
      },
      {
        title: "Km Covered",
        field: "km_covered",
        vertAlign: "middle",
        headerSort: false,
        formatter: function (cell) {
          return formatKm(cell.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewConsignment(row) {}

  function onChangeTab(value) {
    setCurrentTab(value);
  }

  const DetailsListItem = (props) => {
    const { label, value } = props;

    return (
      <div className="truncate sm:whitespace-normal flex items-center mb-2">
        <span className="text-gray-700">{label}</span>
        <span className="ml-2 font-bold">{value}</span>
      </div>
    );
  };

  return (
    <>
      {/* <!-- BEGIN: Body --> */}
      <div className="box">
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Top Info --> */}
        <div className="box shadow px-5">
          <div className="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
            <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
              <div className="">
                <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg">
                  {state?.registration_number}
                </div>
                <div className="text-gray-600">
                  {state?.vehicle_type?.vehicle_type}
                </div>
              </div>
            </div>
            <div className="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
              <div className="flex flex-col justify-center items-center lg:items-start mt-4">
                <DetailsListItem
                  id="RC_chasisNo"
                  label="Chassis number"
                  value={state?.RC_chasisNo}
                />
                <DetailsListItem
                  id="RC_engineNo"
                  label="Engine number"
                  value={state?.RC_engineNo}
                />
                <DetailsListItem
                  id="odometer_reading"
                  label="Odometer Reading"
                  value={formatKm(state?.odometer_reading)}
                />
              </div>
            </div>
            <div className="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0">
              <div className="flex flex-col justify-center items-center lg:items-start mt-4">
                <DetailsListItem
                  id="no_of_axles"
                  label="No. of Axles"
                  value={state?.no_of_axles}
                />
                <DetailsListItem
                  id="no_of_tyres"
                  label="No. of Tyres"
                  value={state?.no_of_tyres}
                />
                <DetailsListItem
                  id="fuel_tank_capacity"
                  label="Fuel Tank Capacity"
                  value={formatLitres(state?.fuel_tank_capacity)}
                />
              </div>
            </div>
          </div>

          {/* <!-- BEGIN: Tab Switcher --> */}
          <SimpleTab>
            <SimpleTab.Tab
              id="consignments"
              active={currentTab === "consignments" ? true : false}
              onClick={(e) => onChangeTab("consignments")}
            >
              Consignments
            </SimpleTab.Tab>
            <SimpleTab.Tab
              id="tyres"
              active={currentTab === "tyres" ? true : false}
              onClick={(e) => onChangeTab("tyres")}
            >
              Tyres
            </SimpleTab.Tab>
            <SimpleTab.Tab
              id="fuel_history"
              active={currentTab === "fuel_history" ? true : false}
              onClick={(e) => onChangeTab("fuel_history")}
            >
              Fuel History
            </SimpleTab.Tab>
          </SimpleTab>
          {/* <!-- END: Tab Switcher --> */}
        </div>
        {/* <!-- END: Top Info --> */}

        {/* <!--BEGIIN : TABLES --> */}
        <div id="table-content" className="mt-3">
          {/* <!-- BEGIN: Consignment Table --> */}
          {currentTab === "consignments" && (
            <ReportsDataTable
              title={Title}
              header={consignmentsTableData.header}
              content={consignmentsTableData.content}
              onRowClick={(e, row) => {
                viewConsignment(row);
              }}
              tableRef={consTableRef}
            />
          )}
          {/* <!-- END: Consignment Table --> */}

          {/* <!-- BEGIN: Tyres Table --> */}
          {currentTab === "tyres" && (
            <ReportsDataTable
              title={Title}
              header={tyresTableData.header}
              content={tyresTableData.content}
              tableRef={tyresTableRef}
            />
          )}
          {/* <!-- END: Tyres Table --> */}

          {/* <!-- BEGIN: Tyres Table --> */}
          {currentTab === "fuel" && (
            <ReportsDataTable
              title={Title}
              header={fuelTableData.header}
              content={fuelTableData.content}
              tableRef={fuelTableRef}
            />
          )}
          {/* <!-- END: Tyres Table --> */}

          {/* <!--END : TABLES --> */}
        </div>
      </div>
    </>
  );
}
