import React from "react";
import Toastify from "toastify-js";
import { stringToHTML } from "../../functions/common";

export default async function Toaster(props) {
  var { type, position, text, subtext, timeout = 4000, node } = { ...props };

  var nodeElement;

  switch (type) {
    case "success":
      node = (
        <div
          id="success-notification-content"
          className="toastify-content flex"
        >
          {" "}
          {/* <i className="text-theme-9" data-feather="check-circle"></i>{" "} */}
          <i className="fa fa-check-square-o text-theme-9" aria-hidden="true"></i>
          <div className="ml-4 mr-4">
            {" "}
            <div className="font-medium">{text}</div>{" "}
            <div className="text-gray-600 mt-1">{subtext}</div>{" "}
          </div>{" "}
        </div>
      );
      nodeElement = stringToHTML(node);
      break;
    case "error":
      node = (
        <div
          id="error-notification-content"
          className="toastify-content flex"
        >
          {" "}
          {/* <i className="text-theme-9" data-feather="check-circle"></i>{" "} */}
          <i className="fa fa-times text-theme-6" aria-hidden="true"></i>
          <div className="ml-4 mr-4">
            {" "}
            <div className="font-medium">{text}</div>{" "}
            <div className="text-gray-600 mt-1">{subtext}</div>{" "}
          </div>{" "}
        </div>
      );
      nodeElement = stringToHTML(node);
      break;
    default:
      node = (
        <div className="toastify-content flex">
          <div className="ml-4 mr-4">
            {" "}
            <div className="font-medium">{text}</div>{" "}
            <div className="text-gray-600 mt-1">{subtext}</div>{" "}
          </div>{" "}
        </div>
      );
      nodeElement = stringToHTML(node);
      break;
  }

  return new Toastify({
    text: text,
    node: nodeElement,
    // duration: 3000,
    newWindow: true,
    close: true,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
  }).showToast();
}
