import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as TruckIcon } from "../../assets/images/svg/truck.svg";

export default function Timeline(props) {
  const { className, type, status, ...rest } = props;
  var stepper = 0;
  const activeClassName = "w-6 h-6 rounded-full btn btn-success";
  const inActiveClassName =
    "w-6 h-6 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1";

  // const locattionActiveClassName = "w-6 h-6 rounded-full btn btn-success ";

  switch (status) {
    case "pending":
      stepper = 1;
      break;

    case "in_progress":
      stepper = 2;
      break;

    case "completed":
      stepper = 3;
      break;
    case "acknowledged":
      stepper = 3;
      break;

    case "invoiced":
      stepper = 4;
      break;

    default:
      break;
  }

  const StepperButton = (props) => {
    let stage = props.stage;
    return (
      <button
        className={stepper >= stage ? activeClassName : inActiveClassName}
      >
        {stepper >= stage ? (
          <i className="fa fa-check" aria-hidden="true"></i>
        ) : null}
      </button>
    );
  };

  return (
    <>
      {/* <!BEGIN: Stepper--> */}
      <div className="wizard flex flex-col lg:flex-row justify-center">
        <div className="intro-x lg:text-center flex items-center lg:block flex-1 z-10">
          <StepperButton stage={1} />
          <div className="lg:w-24 lg:mt-1 ml-3 lg:mx-auto">
            <small>Prepared</small>
          </div>
        </div>

        <div className="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10">
          <StepperButton stage={2} />
          <div className="lg:w-24 lg:mt-1 ml-3 lg:mx-auto">
            <small>Started</small>
          </div>
        </div>

        {/* <div className="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10">
          <div>
            <TruckIcon width="32" height="32" className="inline" />
          </div>
          <button className={locattionActiveClassName}>
          <i className="fa fa-dot-circle-o" aria-hidden="true"></i>
          </button>
          <div className="lg:w-24 lg:mt-1 ml-3 lg:mx-auto">
            <small>Trichy</small>
          </div>
        </div> */}

        <div className="intro-x lg:text-center flex items-center lg:block flex-1 z-10">
          <StepperButton stage={3} />
          <div className="lg:w-24 lg:mt-1 ml-3 lg:mx-auto">
            <small>Completed</small>
          </div>
        </div>

        <div className="intro-x lg:text-center flex items-center lg:block flex-1 z-10">
          <StepperButton stage={4} />
          <div className="lg:w-24 lg:mt-1 ml-3 lg:mx-auto">
            <small>Invoiced</small>
          </div>
        </div>

        <div className="intro-x lg:text-center flex items-center lg:block flex-1 z-10">
          <StepperButton stage={5} />
          <div className="lg:w-24 lg:mt-1 ml-3 lg:mx-auto">
            <small>Closed</small>
          </div>
        </div>

        <div className="wizard__line hidden lg:block w-full bg-gray-200 dark:bg-dark-1 absolute mt-3"></div>
      </div>
      {/* <!END: Stepper--> */}
    </>
  );
}

Timeline.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
};

Timeline.defaultProps = {
  status: "",
  className: "",
  type: "",
};
