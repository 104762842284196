import React, { useState, useEffect } from "react";
// Page Defaults
import Preloader from "../../../components/preloader/preloader";
import ProcessLoader from "../../../components/preloader/processLoader";
import Error from "../../../components/error/error";
// HTML Components
import Container from "../../../components/container";
import StageTab from "../../../components/tabs/stageTab";
import CustomDataTable from "../../../components/tables/customDataTable";

// Functions
import FeatherIcons from "../../../assets/js/feather";
import * as api from "../../../api_service/api";
import { resp } from "../../../functions/responseHandler";
import { formatCurrency, localDate } from "../../../functions/common";

// View Form
import ViewTransaction from "./viewTransaction";

// Model
// import { Model } from "./model";
// Cookies & Storage
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function TransactionHistory(props) {
  const pageTitle = "Transaction History";
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [tab, setTab] = useState({
    currentTab: "approved",
    key: "payments",
    activeClass:
      "nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0 active",
    inActiveClass: "nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0",
  });

  // States
  const [state, setState] = useState({
    from_date: "",
    to_date: "",
  });

  const [modal, setModal] = useState({
    show: false,
    data: {},
  });

  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));

  const [tableData, setTableData] = useState([]);

  // const [state, setState] = useState(Model());

  // //Modal
  // const [show, setShow] = useState(false);
  // const closeModal = () => setShow(false);
  // const openModal = () => setShow(true);

  useEffect(() => {
    FeatherIcons();
    setIsLoaded(true);

    // Loading Data

    let prevType = cookies.get("transaction_history_tab");
    let prevContext = cookies.get("transaction_history_context_tab");
    if (typeof prevType !== "undefined" && typeof prevContext !== "undefined") {
      loadGridData(prevType, prevContext);
    } else {
      loadGridData("payable", "direct");
    }
    // loadDefaultMetrics();
  }, []);

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  async function loadGridData(type, context, startDate, endDate) {
    // Setting Cookies
    cookies.set("transaction_history_tab", type, { path: "/" });
    cookies.set("transaction_history_context_tab", context, { path: "/" });
    let query = {};

    let oldTab = { ...tab };
    oldTab.key = type;
    oldTab.currentTab = context;
    setTab(oldTab);

    setIsProcessing(true);
    if (typeof startDate != "undefined" && typeof endDate != "undefined") {
      query = {
        transaction_type: type,
        context: context,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      query = { transaction_type: type, context: context };
    }
    await api
      .postService(`accounts/transactions/history`, query)
      .then(async (response) => {
        var data = response?.data;

        await mapData(data?.data, type, context);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setError(error);
        setIsProcessing(false);
      });
  }

  function mapData(data, type, context) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "date",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },

      // RECEIVABLE ELEMENTS
      {
        title: "Client",
        field: "client.name",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        visible: type == "receivable" ? true : false,
      },

      {
        title: "Invoice No",
        field: "invoice.invoice_number",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        visible:
          type == "receivable" &&
          (context == "direct" ||
            context == "journal" ||
            context == "direct_memo")
            ? true
            : false,
      },

      {
        title: "Advance Ref No",
        field: "advance_id.payment_reference_number",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        visible: type == "receivable" && context == "journal" ? true : false,
      },
      // RECEIVABLE ELEMENTS

      // PAYABLE ELEMENTS
      {
        title: "To",
        field: "payment_to_type",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        visible: type == "payable" && context == "direct" ? true : false,
      },

      {
        title: "Name",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          const row = cell?._cell?.row?.data;
          var text =
            row?.payment_to_type === "supplier"
              ? row?.supplier?.name
              : row?.payment_to_type === "petrolbunk"
              ? row?.petrolbunk?.name
              : row?.payment_to_type === "vehicle"
              ? row?.vehicle?.registration_number
              : "";
          return text;
        },
      },

      // JOURNAL

      {
        title: "Vehicle",
        field: "vehicle.registration_number",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        visible: type == "payable" && context == "journal" ? true : false,
      },
      {
        title: "Petrolbunk",
        field: "petrolbunk.name",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        visible: type == "payable" && context == "journal" ? true : false,
      },

      //////////// PAYABLE ELEMENTS

      {
        title: "Mode",
        field: "payment_mode",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        visible: context == "debit" ? true : false,
      },

      {
        title: "Date",
        field: "transaction_amount",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
        visible: context == "debit" || context == "credit" ? true : false,
      },
      {
        title: "Debit",
        field: "debit",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
        visible:
          context == "direct_memo" || context == "indirect_memo" ? true : false,
      },

      {
        title: "Credit",
        field: "credit",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
        visible:
          context == "direct_memo" || context == "indirect_memo" ? true : false,
      },

      // RECEIVABLE DISCOUNT
      {
        title: "Debit",
        field: "debit",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
        visible: type == "receivable" && context == "direct" ? true : false,
      },
  
      {
        title: "Ref. No.",
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          const row = cell?._cell?.row?.data;
          var text =
            row?.payment_mode === "journal"
              ? row?.journal_reference_number
              : row?.payment_reference_number;
          return text;
        },
        visible: context == "direct_memo" ? true : false,
      },
    ];
    setTableData({
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    });
    setIsLoaded(true);
  }

  // View Record
  async function viewRecord(data) {
    let newState = {
      show: true,
      data: data?._row?.data,
    };
    setModal(newState);
  }

  function addFormPage(action) {
    window.location.href = `/admin#/accounts/recievables/form`;
  }

  function searchResult(event) {
    event.preventDefault();
    loadGridData(tab?.key, tab?.currentTab, state?.from_date, state?.to_date);
  }

  function changePaymentType(event) {
    const { name, value } = event.target;
    loadGridData(value, tab.currentTab);
  }

  return (
    <Container>
      {/* <!-- BEGIN: Header --> */}
      <Container.Header>
        <Container.Title className="text-lg font-medium">
          {pageTitle}
        </Container.Title>
        {/* <!-- BEGIN: Stage Tabs --> */}
        <div className="mx-auto flex">
          {/* <!-- BEGIN: Payments/Receipts Select --> */}
          <select
            className="form-select py-2 box w-auto mt-3 lg:mt-0 mr-2"
            aria-label="Consignment Type"
            name="cons_type"
            onChange={changePaymentType}
            value={tab.key}
          >
            <option value="payable">Payments</option>
            <option value="receivable">Receipts</option>
          </select>

          {/* <!-- END: Payments/Receipts Select --> */}

          <StageTab>
            <StageTab.Tab
              id="trans-stage-direct"
              active={tab.currentTab === "direct" ? true : false}
              onClick={(e) => loadGridData(tab.key, "direct")}
            >
              Direct
            </StageTab.Tab>{" "}
            {tab.key === "receivable" && (
              <StageTab.Tab
                id="trans-stage-advance"
                active={tab.currentTab === "advance" ? true : false}
                onClick={(e) => loadGridData(tab.key, "advance")}
              >
                Advance
              </StageTab.Tab>
            )}
            <StageTab.Tab
              id="trans-stage-journal"
              active={tab.currentTab === "journal" ? true : false}
              onClick={(e) => loadGridData(tab.key, "journal")}
            >
              Journal
            </StageTab.Tab>
            <StageTab.Tab
              id="trans-stage-direct_memo"
              active={tab.currentTab === "direct_memo" ? true : false}
              onClick={(e) => loadGridData(tab.key, "direct_memo")}
            >
              Dr/Cr Memo
            </StageTab.Tab>
            <StageTab.Tab
              id="trans-stage-indirect_memo"
              active={tab.currentTab === "indirect_memo" ? true : false}
              onClick={(e) => loadGridData(tab.key, "indirect_memo")}
            >
              Misc Memo
            </StageTab.Tab>
          </StageTab>
        </div>
        {/* <!-- END: Stage Tabs --> */}

        <Container.HeaderActions>
          {/* <!-- BEGIN: Date Filter --> */}
          <form id="filter-form" onSubmit={searchResult}>
            <div className="input-group input-group-sm">
              <input
                type="date"
                className="form-control"
                id="from_date"
                name="from_date"
                onChange={changeInput}
                value={state?.from_date}
                required
              />
              <input
                type="date"
                id="to_date"
                className="form-control"
                name="to_date"
                onChange={changeInput}
                value={state?.to_date}
                required
              />
              <div className="">
                <button className="btn btn-sm btn-secondary" type="submit">
                  <i data-feather="search" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </form>
          {/* <!-- END: Date Filter --> */}
        </Container.HeaderActions>
      </Container.Header>
      {/* <!-- END: Header --> */}

      {/*  <!-- BEGIN: Data Table -->  */}
      <Container.Body>
        {isProcessing && <ProcessLoader />}

        <CustomDataTable
          header={tableData.header}
          content={tableData.content}
          filter={true}
          filterFields={tableData.filterFields}
          onRowClick={(e, row) => {
            viewRecord(row);
          }}
        />
      </Container.Body>
      {/*  <!-- END: Data Table -->  */}

      {/* VIEW TRANSACTION MODAL */}
      {modal.show && (
        <ViewTransaction
          show={modal?.show}
          onHide={closeModal}
          data={modal?.data}
        />
      )}

      {/* VIEW TRANSACTION MODAL */}
    </Container>
  );
}
