import React from "react";
import PropTypes from "prop-types";
import Dot from "../htmlElements/dot";

const options = {
  drafted: { color: "bg-gray-600", text: "Drafted" },
  pending: { color: "bg-theme-11", text: "Prepared" },
  in_progress: { color: "bg-theme-9", text: "In Transit" },
  completed: { color: "bg-theme-12", text: "Completed" },
  acknowledged: { color: "bg-theme-12", text: "Acknowledged" },
  invoiced: { color: "bg-theme-1", text: "Invoiced" },
};

export default function Component(props) {
  const { className, id, size, status, module, ...rest } = props;
  let consStatus = "";
  if (module === "tabulator") {
    const cellData = props?.cell?._cell?.row?.data;
    consStatus = cellData?.status;
  } else {
    consStatus = status;
  }

  return (
    <div className="flex items-center">
      <Dot color={options[consStatus]?.color} className="mr-2" />
      {options[consStatus]?.text}
    </div>
  );
  // return isDefined(consStatus) && consStatus in options ? (
  //   <span className={"text-xs px-2 text-white" + options[consStatus]?.color}>
  //     {options[consStatus]?.text}
  //   </span>
  // ) : (
  //   ""
  // );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.string,
  module: PropTypes.string,
};

Component.defaultProps = {
  // Variables
  className: "",
  id: "",
  size: "", // sm,lg,xl
  status: "",
  module: "tabulator",
};
