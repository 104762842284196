import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const defButtonHtml = (
  <div className="text-theme-1">
    <i className="fa fa-info-circle" aria-hidden="true"></i>
  </div>
);
export default function Component(props) {
  // id should be unique and mandatory
  const { className, type, id, name, onClick, icon, buttonHtml, ...rest } =
    props;

  return (
    <div className={className}>
      <a className="cursor-pointer" data-tip data-for={id} href={void 0}>
        {buttonHtml}
      </a>

      <ReactTooltip id={id} aria-haspopup="true" role="example">
        {props.children}
      </ReactTooltip>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  buttonHtml: defButtonHtml,
  className: "ml-2",
  type: "",
  id: "info-button",
  name: "",
  icon: "fa fa-pencil",
  text: "",
  onClick() {},
};
