import React, { Component } from "react";

import "./processLoader.css";

class ProcessLoader extends Component {
  render() {
    return (
      <div className="overlay">
        <div className="dot-falling"></div>
      </div>
    );
  }
}

export default ProcessLoader;
