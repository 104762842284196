import React, { useState, useEffect } from "react";

// Components
import Container from "../../components/container";
import Preloader from "../../components/preloader/preloader";

import { storageServices } from "../../functions/storage";

export default function StartUp() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [localUser, setLocalUser] = useState();
  const [state, setState] = useState({ initial_setup: false });

  useEffect(() => {
    const loadData = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      await setLocalUser(localUserData);

      if (localUserData?.organization_id !== "") {
        setState((prevState) => ({ ...prevState, initial_setup: false }));
      } else if (localUserData.branch_organizations.length > 0) {
        setState((prevState) => ({ ...prevState, initial_setup: false }));
      } else {
        setState((prevState) => ({ ...prevState, initial_setup: true }));
      }

      setIsLoaded(true);
    };
    loadData();
  }, []);
  if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <>
        <Container>
          {state?.initial_setup && (
            <div className="intro-y flex items-center mt-16">
              <h2 className="text-2xl font-medium mx-auto text-gray-600">
                Setup Your Office
              </h2>
            </div>
          )}

          {/* <!-- BEGIN : Tiles */}
          {/* <div className="mt-16 flex items-center justify-center"> */}
          <div className="mt-16">
            <div className="flex justify-center items-center space-x-4">
              {/* <!-- BEGIN : Branch Managment Tile */}
              <a
                href="/admin#/branch/initial_setup"
                className="intro-y box rounded-md zoom-in w-auto mb-2"
              >
                {state?.initial_setup && (
                  <div className="p-3 border-b border-gray-200 dark:border-dark-5">
                    <div className="flex items-center justify-center">
                      <button className="w-8 h-8 rounded-full btn text-gray-700 bg-gray-200 dark:bg-dark-1">
                        1
                      </button>
                      <div className="font-bold ml-3">Setup Branch</div>
                    </div>
                  </div>
                )}

                <div className="py-10">
                  <div className="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto text-center">
                    <i className="fa fa-code-fork fa-3x" aria-hidden="true"></i>
                  </div>
                  <div className="block font-medium text-center break-all  text-base mt-3">
                    Branch Management
                  </div>

                  <div className="text-gray-600 mt-2 w-3/4 text-center mx-auto">
                    Setup Multiple Branches Of Your Organization
                  </div>
                </div>
              </a>
              {/* <!-- END : Branch Managment Tile */}

              {/* <!-- BEGIN : User Managment Tile */}
              <a
                href="/admin#/user_management/users/initial_setup"
                className="intro-y box rounded-md zoom-in w-auto mb-2"
              >
                {state?.initial_setup && (
                  <div className="p-3 border-b border-gray-200 dark:border-dark-5">
                    <div className="flex items-center justify-center">
                      <button className="w-8 h-8 rounded-full btn text-gray-700 bg-gray-200 dark:bg-dark-1">
                        2
                      </button>
                      <div className="font-bold ml-3">Create Users</div>
                    </div>
                  </div>
                )}

                <div className="py-10">
                  <div className="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto text-center">
                    <i className="fa fa-user-o fa-3x" aria-hidden="true"></i>
                  </div>
                  <div className="block font-medium text-center break-all  text-base mt-3">
                    User Management
                  </div>

                  <div className="text-gray-600 mt-2 w-3/4 text-center mx-auto">
                    Create Users, Assign Roles {"&"} Designation and more...
                  </div>
                </div>
              </a>
              {/* <!-- END : User Managment Tile */}
            </div>
          </div>

          {/* <!-- END : Tiles */}
        </Container>
      </>
    );
  }
}
