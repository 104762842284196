import React, { useState, useEffect } from "react";
import FeatherIcons from "../../assets/js/feather";
import { Dropdown } from "react-bootstrap";

import xlsx from "xlsx";
import PropTypes from "prop-types";
import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import { ReactTabulator } from "react-tabulator";
import cash from "cash-dom";
export default function CustomDataTable(props) {
  const tableRef = React.createRef();
  const [state, setState] = useState({
    field: "",
    type: "like",
    value: "",
    advance_search: false,
    advance_search_value: "",
  });
  const {
    header,
    content,
    onRowClick,
    options,
    className,
    printTable,
    printHeader,
    printFooter,
    exportTable,
    filter,
    filterFields,
    title,
    selectableRows,
    selectableRowsHighlight,
    onSelectedRowsChange,
  } = props;

  useEffect(() => {
    FeatherIcons();
    cash("#tabulator-export-csv").on("click", function (event) {
      const table = tableRef.current.table;
      table.download("csv", "data.csv");
    });
  }, []);

  // <!---FILTER-->
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }
  function filterTable(field, type, value) {
    const table = tableRef.current.table;
    table.setFilter(field, type, value);
  }

  function advanceFilterTable(value) {
    const table = tableRef.current.table;
    // let keys = Object.keys(table?.columnManager?.columnsByField);

    // let filterArray = [];
    // for (var i in keys) {
    //   filterArray.push({ field: keys[i], type: "like", value: value });
    // }

    // filterArray = [{ field: "*", type: "like", value: value }];
    // table.setFilter("*", "like", value);
    table.setFilter(matchAny, { value: value });
  }

  function matchAny(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter

    var match = false;
    // var keys = filterParams.keys;

    for (var key in data) {
      if (data[key] == filterParams.value) {
        match = true;
      }
    }

    return match;
  }

  function onAdvanceFilterSubmit(event) {
    event.preventDefault();
    advanceFilterTable(state.advance_search_value);
  }

  function onFilterSubmit(event) {
    event.preventDefault();
    filterTable(state.field, state.type, state.value);
  }

  function resetFilter(event) {
    event.preventDefault();
    const table = tableRef.current.table;
    let prevState = { ...state };
    prevState.field = "";
    prevState.value = "";
    setState(prevState);
    table.clearFilter();
  }

  function resetAdvancedFilter(event) {
    event.preventDefault();
    const table = tableRef.current.table;
    let prevState = { ...state };
    prevState.advance_search_value = "";
    setState(prevState);
    table.clearFilter();
  }

  // <!---FILTER-->

  // <!---PRINT TABLE-->
  function printTableData(event) {
    event.preventDefault();
    const table = tableRef.current.table;
    table.print(false, true, { printHeader: "SDSD" });
  }

  // <!---PRINT TABLE-->

  function test(event) {
    event.preventDefault();
    console.log("test export");
  }
  // <!---EXPORT TABLE-->
  function exportTableData(event, type) {
    event.preventDefault();

    const table = tableRef.current.table;
    switch (type) {
      case "csv":
        console.log("downloading csv");
        table.download("csv", "data.csv");
        break;
      case "json":
        console.log("downloading json");
        table.download("json", "data.json");
        break;
      case "xlsx":
        console.log("downloading xlsx");
        window.XLSX = xlsx;
        table.download("xlsx", "data.xlsx", {
          sheetName: "Data",
        });
        break;

      default:
        break;
    }
  }
  // <!---EXPORT TABLE-->

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      className="btn btn-outline-secondary w-full sm:w-auto"
      aria-expanded="false"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {" "}
      <i data-feather="file-text" className="w-4 h-4 mr-2"></i>Export{" "}
      <i data-feather="chevron-down" className="w-4 h-4 ml-auto sm:ml-2"></i>{" "}
    </button>
  ));

  function changeCheckBox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  return (
    <>
      {/* <!-- BEGIN: HTML Table Data --> */}
      <div className="intro-y box p-5 mt-5">
        {/* <!-- BEGIN: Header --> */}
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
          {/* <!-- BEGIN: Filter --> */}
          {filter && (
            <div className="flex mr-auto">
              {state?.advance_search ? (
                <>
                  <form
                    id="tabulator-html-filter-form"
                    className="xl:flex md:flex-auto"
                    onSubmit={onFilterSubmit}
                  >
                    <div className="sm:flex items-center sm:mr-4">
                      <label className="w-12 flex-none xl:w-auto xl:flex-initial mr-2">
                        Field
                      </label>
                      <select
                        id="tabulator-html-filter-field"
                        className="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                        name="field"
                        value={state?.field}
                        onChange={changeInput}
                        required
                      >
                        <option value="" selected></option>
                        {filterFields.map((obj, index) => {
                          return (
                            <option key={index} value={obj?.value}>
                              {obj?.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                      <label className="w-12 flex-none xl:w-auto xl:flex-initial mr-2">
                        Type
                      </label>
                      <select
                        id="tabulator-html-filter-type"
                        className="form-select w-full mt-2 sm:mt-0 sm:w-auto"
                        name="type"
                        value={state?.type}
                        onChange={changeInput}
                        required
                      >
                        <option value="like" selected>
                          like
                        </option>
                        <option value="=">=</option>
                        <option value="<">&lt;</option>
                        <option value="<=">&lt;{"="}</option>
                        <option value=">">{">"}</option>
                        <option value=">=">{">="}</option>
                        <option value="!=">!=</option>
                      </select>
                    </div>
                    <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                      <label className="w-12 flex-none xl:w-auto xl:flex-initial mr-2">
                        Value
                      </label>
                      <input
                        id="tabulator-html-filter-value"
                        type="text"
                        className="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                        placeholder="Search..."
                        name="value"
                        value={state?.value}
                        onChange={changeInput}
                        required
                      />
                    </div>
                    <div className="mt-2 xl:mt-0">
                      <button
                        id="tabulator-html-filter-go"
                        type="submit"
                        className="btn btn-primary w-full sm:w-16"
                      >
                        Go
                      </button>
                      <button
                        id="tabulator-html-filter-reset"
                        type="button"
                        className="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                        onClick={resetFilter}
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <form
                  id="tabulator-html-advance-filter-form"
                  className="xl:flex md:flex-auto"
                  onSubmit={onAdvanceFilterSubmit}
                >
                  <div className="sm:flex items-center sm:mr-4">
                    <input
                      id="tabulator-html-advance-filter-value"
                      type="text"
                      className="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                      placeholder="Search..."
                      name="advance_search_value"
                      value={state?.advance_search_value}
                      onChange={changeInput}
                      required
                    />
                  </div>
                  <div className="mt-2 xl:mt-0">
                    <button
                      id="tabulator-html-advance-filter-go"
                      type="submit"
                      className="btn btn-primary w-full sm:w-16"
                    >
                      Go
                    </button>
                    <button
                      id="tabulator-html-filter-reset"
                      type="button"
                      className="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                      onClick={resetAdvancedFilter}
                    >
                      Reset
                    </button>
                  </div>
                </form>
              )}

              <div className="form-check ml-3">
                <label
                  className="form-check-label ml-0 sm:ml-2"
                  htmlFor="advance_search"
                >
                  Advance Search
                </label>
                <input
                  id="advance_search"
                  className="form-check-switch mr-0 ml-3"
                  type="checkbox"
                  name="advance_search"
                  checked={state?.advance_search}
                  onChange={changeCheckBox}
                />
              </div>
            </div>
          )}
          {/* <!-- END: Filter --> */}

          <div className="flex mt-5 sm:mt-0">
            {/* <!-- BEGIN: Print Button --> */}
            {printTable && (
              <button
                id="tabulator-print"
                className="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                onClick={printTableData}
              >
                {" "}
                <i
                  data-feather="printer"
                  className="w-4 h-4 mr-2"
                ></i> Print{" "}
              </button>
            )}
            {/* <!-- BEGIN: Print Button --> */}

            {/* <button
              id="tabulator-export-csv"
              // href={void(0)}
              className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              name="xlsx"
              onClick={(event) => exportTable(event, "xlsx")}
            >
              {" "}
              <i data-feather="file-text" className="w-4 h-4 mr-2"></i> Export
              xlsx{" "}
            </button> */}

            {/* <!-- BEGIN: Export Button --> */}

            {exportTable && (
              <>
                <Dropdown className="w-1/2 sm:w-auto">
                  <Dropdown.Toggle
                    as={CustomToggle}
                    className="p-0 btn btn-outline-secondary"
                    id="export-dropdown"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="w-40">
                      <div className="box dark:bg-dark-1 p-2">
                        <a
                          id="tabulator-csv"
                          href={void 0}
                          className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          name="csv"
                          onClick={(event) => exportTableData(event, "csv")}
                        >
                          {" "}
                          <i
                            className="fa fa-file-text-o w-4 h-4 mr-2"
                            aria-hidden="true"
                          ></i>
                          Export CSV{" "}
                        </a>
                        <a
                          id="tabulator-export-json"
                          href={void 0}
                          onClick={(event) => exportTableData(event, "json")}
                          className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          name="json"
                        >
                          {" "}
                          <i
                            className="fa fa-file-text-o w-4 h-4 mr-2"
                            aria-hidden="true"
                          ></i>
                          Export JSON{" "}
                        </a>
                        <a
                          id="tabulator-export-xlsx"
                          href={void 0}
                          className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          name="xlsx"
                          onClick={(event) => exportTableData(event, "xlsx")}
                        >
                          {" "}
                          <i
                            className="fa fa-file-text-o w-4 h-4 mr-2"
                            aria-hidden="true"
                          ></i>
                          Export XLSX{" "}
                        </a>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}

            {/* {exportTable && (
              <div className="dropdown w-1/2 sm:w-auto">
                <button
                  className="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                  aria-expanded="false"
                >
                  {" "}
                  <i data-feather="file-text" className="w-4 h-4 mr-2"></i>{" "}
                  Export{" "}
                  <i
                    data-feather="chevron-down"
                    className="w-4 h-4 ml-auto sm:ml-2"
                  ></i>{" "}
                </button>

                <div className="dropdown-menu w-40">
                  <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      id="tabulator-csv"
                      href={void 0}
                      className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      name="csv"
                      onClick={(event) => exportTableData(event, "csv")}
                    >
                      {" "}
                      <i
                        data-feather="file-text"
                        className="w-4 h-4 mr-2"
                      ></i>{" "}
                      Export CSV{" "}
                    </a>
                    <a
                      id="tabulator-export-json"
                      href={void 0}
                      onClick={(event) => exportTableData(event, "json")}
                      className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      name="json"
                    >
                      {" "}
                      <i
                        data-feather="file-text"
                        className="w-4 h-4 mr-2"
                      ></i>{" "}
                      Export JSON{" "}
                    </a>
                    <a
                      id="tabulator-export-xlsx"
                      href={void 0}
                      className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      name="xlsx"
                      onClick={(event) => exportTableData(event, "xlsx")}
                    >
                      {" "}
                      <i
                        data-feather="file-text"
                        className="w-4 h-4 mr-2"
                      ></i>{" "}
                      Export XLSX{" "}
                    </a>
                  </div>
                </div>
              </div>
            )} */}
            {/* <!-- BEGIN: Export Button --> */}
          </div>
        </div>
        {/* <!-- END: Header --> */}
        <div className="overflow-x-auto scrollbar-hidden">
          {/*  <!-- BEGIN: Data Table -->  */}

          <ReactTabulator
            ref={tableRef}
            columns={header}
            data={content}
            options={{
              movableRows: true,
              movableColumns: true,
              placeholder: "No records found",
              layout: "fitColumns",
              // layout:"fitDataFill",
              responsiveLayout: "collapse",
              pagination: "local",
              paginationSize: 10,
              paginationSizeSelector: [10, 20, 30, 40],
              downloadDataFormatter: (data) => data,
              downloadReady: (fileContents, blob) => blob,
              printAsHtml: true,
              printCopyStyle: true,
              printStyled: true,
              printFooter: printFooter,
              printHeader: `<h3>${printHeader}</h3>`,
            }}
            rowClick={onRowClick}
            className={className}
            tooltips={true}
            placeholder={"No data found."}
          />

          {/*  <!-- END: Data Table -->  */}
        </div>
      </div>
      {/* <!-- END: HTML Table Data --> */}

      {/* <MaterialTable
        title=""
        columns={columns}
        data={data}
        options={{
          sorting: true,
        }}
      /> */}

      {/* <MaterialTable
      title="Basic Sorting Preview"
      columns={[
        { title: "Name", field: "name" },
        { title: "Surname", field: "surname" },
        { title: "Birth Year", field: "birthYear", type: "numeric" },
        {
          title: "Birth Place",
          field: "birthCity",
          lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
        },
      ]}
      data={[
        { name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 },
        {
          name: "Zerya Betül",
          surname: "Baran",
          birthYear: 2017,
          birthCity: 34,
        },
      ]}
      options={{
        sorting: true,
      }}
    /> */}
    </>
  );
}

CustomDataTable.propTypes = {
  header: PropTypes.array,
  content: PropTypes.array,
  filter: PropTypes.bool,
  printTable: PropTypes.bool,
  printHeader: PropTypes.string,
  printFooter: PropTypes.string,
  exportTable: PropTypes.bool,
  filterFields: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.object,
  onRowClick: PropTypes.func,
};

CustomDataTable.defaultProps = {
  header: [],
  content: [],
  filter: true,
  printTable: true,
  exportTable: true,
  filterFields: [],
  title: "",
  printHeader: "",
  printFooter: "",
  className: "mt-5",
  // className: "mt-5 table-report table-report--tabulator tabulator",
  // options: {
  //   movableRows: true,
  //   placeholder: "No records found",
  //   layout: "fitColumns",
  //   // layout:"fitDataFill",
  //   responsiveLayout: "collapse",
  //   pagination: "local",
  //   paginationSize: 10,
  //   paginationSizeSelector: [10, 20, 30, 40],
  //   downloadDataFormatter: (data) => data,
  //   downloadReady: (fileContents, blob) => blob,
  //   printAsHtml:true,
  //   printCopyStyle:true,
  //   printStyled:true,
  //   printFooter:printFooter,
  // },
  onRowClick() {},
};
