import * as React from "react";
import PropTypes from "prop-types";

import Thumbnail from "../image/thumbnail";
import ValidationError from "../error/validationError";

const ImageUrl = process.env.REACT_APP_IMAGE_URL;

export default function ImageInput(props) {
  const { mode, state, name, required, error_msg, onDelete, onChange } = props;
  return (
    <>
      {!mode?.view && (state?.name || !state) ? (
        <>
          <div className="custom-file">
            <label className="custom-file-label" htmlFor="customFile">
              {state?.name ? state?.name : "Choose file"}
            </label>
            <input
              type="file"
              className="custom-file-input"
              name={name}
              id={name}
              disabled={mode?.view}
              onChange={onChange}
              accept="image/*"
              required={required}
            />
          </div>
          <ValidationError msg={error_msg} />
        </>
      ) : (
        ""
      )}
      {state && typeof state === "string" ? (
        <Thumbnail
          src={state}
          onDelete={(event) => onDelete(event, name)}
          name={name}
          viewMode={mode?.view}
        />
      ) : (
        ""
      )}
      {!state  && mode?.view? (
        <>
          <div className="clearfix"></div>
          <img
            className="img-fluid img-thumbnail thumbnail-image"
            src="https://cel.ac/wp-content/uploads/2016/02/placeholder-img-1.jpg"
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

ImageInput.propTypes = {
  mode: PropTypes.object,
  state: PropTypes.object,
  name: PropTypes.string,
  required: PropTypes.bool,
  error_msg: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

ImageInput.defaultProps = {
  mode: { view: false },
  state: {},
  name: "",
  required: false,
  error_msg: "",
  onChange() {},
  onDelete() {},
};
