import React from "react";
import PropTypes from "prop-types";
export default function WeightInput(props) {
  const {
    type,
    id,
    name,
    className,
    onChange,
    onBlur,
    value,
    required,
    size,
    placeholder,
    disabled,
    measure,
    allowedValue,
    min,
    max,
    ...rest
  } = props;
  return (
    <>
      <div className="input-group">
        <input
          type={type}
          className={className}
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          min={min}
          max={max}
          step="any"
          // oninput={
          //   allowedValue === "positive" ? "validity.valid||(value='');" : ""
          // }
        />
        <div id={id} className="input-group-text">
          {measure}
        </div>
      </div>
    </>
  );
}

WeightInput.propTypes = {
  placeholder: PropTypes.string,
  measure: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  allowedValue: PropTypes.string,
  oninput: PropTypes.string,
};

WeightInput.defaultProps = {
  measure: "Kg",
  placeholder: "",
  size: "",
  type: "number",
  id: "",
  name: "",
  className: "form-control",
  onChange() {},
  onBlur() {},
  value: "",
  required: false,
  min: "0",
  max: "",
  allowedValue: "positive",
  oninput: "validity.valid||(value='');",
};
