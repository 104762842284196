import React, { useState, useEffect } from "react";
// HTML Components

import Container from "../../components/container";
import Card from "../../components/card/custom";

import Error from "../../components/error/error";
import ProcessLoader from "../../components/preloader/processLoader";
import InLineLoader from "../../components/preloader/inLineLoader";
// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { matchData, JsonToFormData } from "../../functions/common";
import { storageServices } from "../../functions/storage";

// Models
import { Model } from "./model";

export default function BusinessRules(props) {
  // Loaders
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
  });

  const [state, setState] = useState({ ...Model });
  const [recordId, setRecordId] = useState();

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    api
      .getService(`business_rules`)
      .then(async (result) => {
        const data = result?.data?.data;
        console.log("data:", data);
        if (data.length > 0) {
          let recordData = data[0];
          let matchedData = await matchData(state, recordData);

          setRecordId(recordData?._id);
          setState(matchedData);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaded(true);
        resp.ErrorHandler(error);
      });
  }

  function changeCheckBox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  function onSubmit(event) {
    event.preventDefault();
    console.log("state:", state);

    setIsProcessing(true);
    var query = { ...state };
    api
      .putService(`business_rules/${recordId}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        updateBizRulesLocal(query);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function updateBizRulesLocal(data) {
    var localUser = await storageServices.getCurrentUser();
    localUser["business_rules"] = data;
    await storageServices.setCurrentUser(localUser);
  }

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  if (error) {
    return <Error />;
  } else {
    return (
      <Container>
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title>Business Rules</Container.Title>
        </Container.Header>
        {/* <!-- END: Header --> */}
        {/* <!-- BEGIN: Container Body --> */}
        <Container.Body>
          {/* <!-- BEGIN: Card --> */}
          <Card className="mt-5">
            {!isLoaded && <ProcessLoader />}
            {/* <!-- BEGIN: Card Body --> */}
            <Card.Body>
              {/* <!-- BEGIN: Form --> */}
              <form onSubmit={onSubmit} className="p-10">
                <table className="table mt-5">
                  <thead>
                    <tr className="bg-gray-200 text-gray-700">
                      <th className="whitespace-nowrap">#</th>
                      <th className="whitespace-nowrap">Rule</th>
                      <th className="whitespace-nowrap">Description</th>
                      <th className="whitespace-nowrap">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <!-- BEGIN: Rule #1: Segment Type --> */}
                    <tr>
                      <td className="border-b dark:border-dark-5">1</td>
                      <td className="border-b dark:border-dark-5 font-medium">
                        Business Segment
                      </td>
                      <td className="border-b dark:border-dark-5">
                        Type of your Business
                      </td>
                      <td className="border-b dark:border-dark-5">
                        <select
                          id="business_segment_type"
                          className="form-select"
                          name="business_segment_type"
                          value={state?.business_segment_type}
                          disabled={mode?.view}
                          onChange={changeInput}
                          required
                        >
                          <option value=""></option>
                          <option value="transporter">Transporter</option>
                          <option value="broker">Broker</option>
                        </select>
                      </td>
                    </tr>
                    {/* <!-- END: Rule #0: Business Segment Type --> */}

                    {/* <!-- BEGIN: Rule #1: Cons Approval --> */}
                    <tr>
                      <td className="border-b dark:border-dark-5">2</td>
                      <td className="border-b dark:border-dark-5 font-medium">
                        Consignment Approval
                      </td>
                      <td className="border-b dark:border-dark-5">
                        Approval needed for consignment to move from prepared to
                        In Transit State
                      </td>
                      <td className="border-b dark:border-dark-5">
                        <input
                          id="consignment_approval"
                          name="consignment_approval"
                          className="form-check-switch "
                          type="checkbox"
                          onChange={changeCheckBox}
                          checked={state?.consignment_approval}
                          disabled={mode?.view}
                        />
                      </td>
                    </tr>
                    {/* <!-- END: Rule #1: Cons Approval --> */}

                    {/* <!-- BEGIN: Rule #2: Invoice Approval --> */}
                    <tr>
                      <td className="border-b dark:border-dark-5">3</td>
                      <td className="border-b dark:border-dark-5 font-medium">
                        Invoice Approval
                      </td>
                      <td className="border-b dark:border-dark-5">
                        Approval needed for Invoices after preparation
                      </td>
                      <td className="border-b dark:border-dark-5">
                        <input
                          id="invoice_approval"
                          name="invoice_approval"
                          className="form-check-switch "
                          type="checkbox"
                          onChange={changeCheckBox}
                          checked={state?.invoice_approval}
                          disabled={mode?.view}
                        />
                      </td>
                    </tr>
                    {/* <!-- END: Rule #2: Invoice Approval --> */}

                    {/* <!-- BEGIN: Rule #3: Driver Approval --> */}
                    <tr>
                      <td className="border-b dark:border-dark-5">4</td>
                      <td className="border-b dark:border-dark-5 font-medium">
                        Driver Approval
                      </td>
                      <td className="border-b dark:border-dark-5">
                        Drivers to be approved while creating new.
                      </td>
                      <td className="border-b dark:border-dark-5">
                        <input
                          id="driver_approval"
                          name="driver_approval"
                          className="form-check-switch "
                          type="checkbox"
                          onChange={changeCheckBox}
                          checked={state?.driver_approval}
                          disabled={mode?.view}
                        />
                      </td>
                    </tr>
                    {/* <!-- END: Rule #3: Driver Approval --> */}

                    {/* <!-- BEGIN: Rule #4: Vehicle Approval --> */}
                    <tr>
                      <td className="border-b dark:border-dark-5">5</td>
                      <td className="border-b dark:border-dark-5 font-medium">
                        Vehicle Approval
                      </td>
                      <td className="border-b dark:border-dark-5">
                        Vehicles to be approved while creating new.
                      </td>
                      <td className="border-b dark:border-dark-5">
                        <input
                          id="vehicle_approval"
                          name="vehicle_approval"
                          className="form-check-switch "
                          type="checkbox"
                          onChange={changeCheckBox}
                          checked={state?.vehicle_approval}
                          disabled={mode?.view}
                        />
                      </td>
                    </tr>
                    {/* <!-- END: Rule #4: Vehicle Approval --> */}

                    {/* <!-- BEGIN: Rule #6: Overdue Invoice Send To --> */}
                    <tr>
                      <td className="border-b dark:border-dark-5">5</td>
                      <td className="border-b dark:border-dark-5 font-medium">
                        Overdue Invoice
                      </td>
                      <td className="border-b dark:border-dark-5">
                        Send Overdue Invoice Notifications to
                      </td>
                      <td className="border-b dark:border-dark-5">
                        <select
                          id="overdue_invoice_send_to"
                          className="form-select"
                          name="overdue_invoice_send_to"
                          value={state?.overdue_invoice_send_to}
                          disabled={mode?.view}
                          onChange={changeInput}
                          required
                        >
                          <option value=""></option>
                          <option value="user">System Users</option>
                          <option value="client">Client</option>
                        </select>
                      </td>
                    </tr>
                    {/* <!-- END: Rule #6: Overdue Invoice Send To --> */}
                  </tbody>
                </table>

                <input type="submit" id="save_biz_rules" className="hidden" />
              </form>
              {/* <!-- END: Form --> */}
            </Card.Body>
            {/* <!-- END: Card Body --> */}

            {/* <!-- BEGIN: Card Footer --> */}

            <Card.Footer className="justify-center">
              <label
                type="button"
                htmlFor="save_biz_rules"
                className="btn btn-primary w-24 ml-2"
              >
                Save&nbsp; {isProcessing && <InLineLoader />}
              </label>
            </Card.Footer>
            {/* <!-- END: Card Footer --> */}
          </Card>
          {/* <!-- END: Card --> */}
        </Container.Body>
        {/* <!-- END: Container Body --> */}
      </Container>
    );
  }
}
