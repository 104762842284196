import React, { useState, useEffect } from "react";
// Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import StatusBadge from "../../../components/pageElements/statusBadge";
import ActionButtons from "../../../components/pageElements/actionButtons";
import AddNewButton from "../../../components/htmlElements/addNewButton";

import FormMaster from "../../../components/master/driver"; // Form
// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import { htmlToString, localDate } from "../../../functions/common";
import { reactFormatter } from "react-tabulator";
// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";

export default function Driver() {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  const [tab, setTab] = useState({
    currentTab: "approved",
  });

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData(tab.currentTab);
  }, []);

  // Fetching Grid Data From API
  async function loadData(status) {
    setIsProcessing(true);
    let oldTab = { ...tab };
    oldTab.currentTab = status;
    setTab(oldTab);

    api.getService(`driver/all/${status}`).then(
      async (result) => {
        const data = result.data;
        console.log("API data", data);
        let tableData = await mapTableData(data.data);
        setTableData(tableData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      // {
      //   title: "Id",
      //   field: "driver_id",
      //   vertAlign: "middle",
      //   headerFilter: true,
      //   headerFilterPlaceholder: "Search...",
      //   headerSort: true,
      // },
      {
        title: "Name",
        field: "name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: true,
      },

      {
        title: "Phone Number",
        field: "contact_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
      },
      {
        title: "License No.",
        field: "license_no",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
      },

      {
        title: "Status",
        field: "status",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return htmlToString(<StatusBadge text={cell?.getValue()} />);
        },
      },

      {
        title: "Actions",
        field: "",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",

        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(<ActionButtonsLookup />),
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [
        { label: "Driver Id", value: "driver_id" },
        { label: "Name", value: "fullname" },
        { label: "Phone No", value: "contact_number" },
      ],
    };
    return response;
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e) => {
            editRecord(cellData);
          },
        }}
        suspendButton={{
          show: cellData?.status == "active" ? true : false,
          onClick: (e) => {
            deleteRecord(cellData);
          },
        }}
        activateButton={{
          // show: false,
          show: cellData?.status == "inactive" ? true : false,
          onClick: (e) => {
            activateRecord(cellData);
          },
        }}
        approveButton={{
          show: cellData?.approved == false ? true : false,
          onClick: (e) => {
            approveRecord(cellData);
          },
        }}
      />
    );
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData(tab.currentTab);
  }
  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add Driver`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(data, row) {
    console.log("row data:", data);
    let newState = {
      show: true,
      title: `View Driver`,
      action: "view",
      id: data?._row?.data?._id,
    };
    setModal(newState);
  }
  // Edit Record
  async function editRecord(data) {
    let newState = {
      show: true,
      title: `Edit Driver`,
      action: "edit",
      id: data?._id,
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to suspend this Driver?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }
  // RE-ACTIVATION
  function activateRecord(data) {
    alert.confirmActivation({
      text: "Are you sure want to activate this Driver?",
      onConfirm: function () {
        onActivate(data);
      },
    });
  }

  // Approve Record
  async function approveRecord(data) {
    alert.confirmApproval({
      text: "Are you sure want to approve this Driver?",
      onConfirm: function () {
        onApprove(data);
      },
    });
  }

  ////// COMMON FUNCTIONS //////

  function onApprove(data) {
    setIsProcessing(true);
    api
      .getService(`driver/approve/${data?._id}`)
      .then((response) => {
        console.log("response:", response);
        setIsProcessing(false);
        resp.ResponseHandler(response);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onActivate(data) {
    setIsProcessing(true);
    var query = {
      status: "active",
    };
    api
      .putService(`driver/${data._id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData(tab.currentTab);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`driver/${data?._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData(tab.currentTab);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  return (
    <>
      <>
        <Container>
          {/* <!-- BEGIN: Modal --> */}
          {modal.show && (
            <FormMaster
              show={modal.show}
              action={modal.action}
              title={modal.title}
              onHide={closeModal}
              callBack={reloadData}
              id={modal.id}
            />
          )}
          {/* <!-- END: Modal --> */}

          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title>
              Driver
            </Container.Title>

            <Container.HeaderActions>
              {" "}
              <AddNewButton id="driver-button" onClick={addRecord}>
                Add New
              </AddNewButton>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing ? (
              <ProcessLoader />
            ) : (
              <CustomDataTable
                header={tableData.header}
                content={tableData.content}
                onRowClick={(e, row) => {
                  viewRecord(row);
                }}
                filter={true}
                filterFields={tableData.filterFields}
              />
            )}
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
