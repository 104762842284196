import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "../../functions/common";
export default function Component(props) {
  const {
    className,
    load_shortage_weight,
    load_shortage_weight_unit,
    load_shortage_amount,
    ...rest
  } = props;

  let showBadge = false;
  if (load_shortage_weight > 0) {
    showBadge = true;
  }

  return (
    showBadge && (
      <div className={className} id="load_exceed_alert">
        <div
          class="alert alert-danger-soft show flex items-center"
          role="alert"
        >
          <i class="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>

          <div id="load-shortage-weight">
            Load Shortage - {load_shortage_weight} Kg
          </div>

          {load_shortage_amount && (
            <div id="load_shortage_amount" className="ml-2">
              <label className="mr-2">|</label>Load Shortage Amount - {formatCurrency(load_shortage_amount)}{" "}
              <small className="ml-1">{"(Approx.)"}</small>
            </div>
          )}
        </div>
      </div>
    )
  );
}

Component.propTypes = {
  className: PropTypes.string,
  load_shortage_weight: PropTypes.number,
  load_shortage_weight_unit: PropTypes.string,
  load_shortage_amount: PropTypes.string,
};

Component.defaultProps = {
  className: "",
  load_shortage_weight: 0,
  load_shortage_weight_unit: "kg",
  load_shortage_amount: "",
};
