import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import Container from "../../components/container";

// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
// const mapStyles = require("./mapStyles.json");

// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { htmlDateTime } from "../../functions/common";

// Constants

const GOOGLEMAP_API_KEY = process.env.REACT_APP_GOOGLEMAP_KEY;
// const GOOGLEMAP_API_KEY = "AIzaSyBfcKNyGB9Z0OxQSrAnWcL_nDzR_ec8xN8";

var lastPosition = {};
const currentTime = new Date();

const ErrorHTML = () => {
  return <div className="font-medium">Oops! Something went wrong.</div>;
};

const NoDataHTML = () => {
  return <div className="font-medium">No Data Available.</div>;
};

const MarkerInfoHTML = (props) => {
  const { data } = props;

  return (
    <div className="">
      <p className="mb-2">
        Time :{" "}
        <span className="flex text-theme-1 font-bold">
          {htmlDateTime(data?.timestamp)}
        </span>
      </p>

      <p className="">
        Address : <span className="font-bold">{data?.address}</span>
      </p>
    </div>
  );
};

const Map = ({ query }) => {
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [lastPosition, setLastPosition] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState();
  const [activeMarker, setActiveMarker] = useState();
  const [gpsData, setGpsData] = useState([]);

  const [state, setState] = useState({
    lat: "",
    lng: "",
  });

  function handleActiveMarker(marker) {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  }
  async function loadData() {
    setIsProcessing(true);

    await api
      .postService(`sim_tracking/consignment`, query)
      .then(async (response) => {
        const data = response.data;
        let recData = await mapData(data.data);
        setGpsData(recData);
        setDefaultCenter({
          lat: parseFloat(recData?.latitude),
          lng: parseFloat(recData?.longitude),
        });

        setIsProcessing(false);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setError(true);
        setIsProcessing(false);
        setIsLoaded(true);
      });
  }

  function mapData(data) {
    return data;
  }

  useEffect(() => {
    // loadData();
  }, []);

  if (error) {
    return <ErrorHTML />;
  } else {
    return (
      <>
        {isProcessing && <ProcessLoader />}
        {noData ? (
          <NoDataHTML />
        ) : isLoaded ? (
          <>
            <GoogleMap
              defaultZoom={15}
              defaultCenter={defaultCenter}
              // defaultOptions={{
              //   // styles: mapStyles,
              //   fullscreenControl: false,
              //   mapTypeControl: false,
              //   streetViewControl: false,
              // }}
            >
              <>
                {/* <!--BEGIN : Marker --> */}
                <Marker
                  position={defaultCenter}
                  id="final_position"
                  onClick={(e) => handleActiveMarker("final_position")}
                >
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <MarkerInfoHTML data={gpsData} />
                  </InfoWindow>
                </Marker>
                {/* <!--END : Marker --> */}
              </>
              {/* )} */}
            </GoogleMap>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
};

const MapComponent = withScriptjs(withGoogleMap(Map));

export default function View(props) {
  const { query } = useParams();
  console.log("query:", query);
  return (
    <>
      <Container>
        <Container.Header>
          Driver SIM Tracking
          <Container.HeaderActions>
            <button className="btn btn-outline-primary" onClick={(e) => {}}>
              <i className="fa fa-refresh mr-2" aria-hidden="true"></i> Refresh
            </button>
          </Container.HeaderActions>
        </Container.Header>
        <Container.Body>
          <div className="flex flex-grow h-full">
            <MapComponent
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAP_API_KEY}&v=3.exp&libraries=geometry,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div style={{ height: `75vh`, width: "100%" }} />
              }
              // containerElement={
              //   <div
              //     style={{
              //       position: "relative",
              //       margin: "auto",
              //       width: "100%",
              //       height: "100%",
              //     }}
              //   />
              // }
              mapElement={<div style={{ height: `100%` }} />}
              query={query}
            />
          </div>
        </Container.Body>
      </Container>
    </>
  );
}
