import PropTypes from "prop-types";

/* <!-- BEGIN: CleanTab --> */
const CleanTab = (props) => {
  const { className } = props;
  return (
    <div className={className} role="tablist">
      {props.children}
    </div>
  );
};
CleanTab.propTypes = {
  className: PropTypes.string,
};
CleanTab.defaultProps = {
  className: "pos__tabs nav nav-tabs justify-center",
};

/* <!-- BEGIN: CleanTab --> */

/* <!-- BEGIN: Tab --> */
const Tab = (props) => {
  const { active, className, id, onClick, width } = props;
  let modClass = className + width;
  return (
    <a
      data-toggle="tab"
      role="tab"
      href={void 0}
      onClick={onClick}
      className={active ? modClass + " active" : modClass}
      id={id}
      aria-selected={active}
    >
      {props.children}
    </a>
  );
};
Tab.propTypes = {
  id: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
};
Tab.defaultProps = {
  id: "tab",
  active: false,
  width: "",
  className: "flex-1 py-2 rounded-md text-center cursor-pointer",
  onClick() {},
};

/* <!-- BEGIN: Element --> */

CleanTab.Tab = Tab;

export default CleanTab;
