import React, { useState, useEffect } from "react";

// Components
import Container from "../../components/container";
import Preloader from "../../components/preloader/preloader";

// Icons
import { ReactComponent as ReportTemplatesIcon } from "../../assets/images/svg/report-templates.svg";
import { ReactComponent as ReportDataExportIcon } from "../../assets/images/svg/report-data-export.svg";

export default function LandingPage() {
  const [localUser, setLocalUser] = useState();

  useEffect(() => {}, []);

  function TileCard(props) {
    return (
      <a
        href={props.url}
        className="intro-y box rounded-md zoom-in w-auto mb-2  shadow-lg"
      >
        <div className="py-10 w-80">
          <div className="block w-16 h-16 text-theme-1 dark:text-theme-10 mx-auto text-center">
            {props.icon}
          </div>
          <div className="block font-medium text-center break-all mt-3 text-lg">
            {props.title}
          </div>
        </div>
      </a>
    );
  }

  return (
    <>
      <Container>
        {/* <!-- BEGIN : Tiles */}
        <div className="mt-16">
          <div className="flex justify-center items-center space-x-4">
            {/* <!-- BEGIN : Report Templates --> */}
            <TileCard
              title="Report Templates"
              icon={<ReportTemplatesIcon />}
              url="/admin#/reports/workbench"
            />
            {/* <!-- END : Report Templates -->  */}

            {/* <!-- BEGIN : Data Export Tile --> */}

            <TileCard
              title="Data Export"
              icon={<ReportDataExportIcon />}
              url="/admin#/reports/data_export"
            />

            {/* <!-- END : Data Export Tile --> */}
          </div>
        </div>

        {/* <!-- END : Tiles */}
      </Container>
    </>
  );
}
