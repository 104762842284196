import React from "react";
import PropTypes from "prop-types";
export default function Component(props) {
  const { className, ...rest } = props;
  return (
    <>
      <div className={"flex w-2 h-2 " + className}>
        <div class="w-2 h-2 bg-theme-9 rounded-full"></div>
        <div class="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-green-400 opacity-75"></div>
      </div>
    </>
  );
}

Component.propTypes = {
  className: PropTypes.string,
};

Component.defaultProps = {
  className: "",
};
