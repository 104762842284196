// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// LOADERS
import ProcessLoader from "../../preloader/processLoader";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";
import ClientsInList from "../../../components/consignment/clientsInList";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

// FUNCTIONS
import { formatTypeSearch, htmlDate } from "../../../functions/common";
import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";

const Model = {
  name: "",
  client: "",
  start_date: "",
  end_date: "",
  status: "active",
};

export default function Component(props) {
  // Defaults
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });
  const [defaultState, setDefaultState] = useState({
    client: [],
  });

  // State
  const [state, setState] = useState(Model);
  const [record_id, setRecordId] = useState();

  useEffect(() => {
    handleUserAction(props);
  }, []);

  // <!--- BEGIN: FUNCTIONS -->
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setState({ ...Model });
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
    }
  }

  function mapData(data) {
    data["start_date"] = htmlDate(data["start_date"]);
    data["end_date"] = htmlDate(data["end_date"]);
    return data;
  }

  function prepareData(data) {
    data["client"] = data["client"]["_id"];
    return data;
  }
  // <!--- END: FUNCTIONS -->

  // <!--- BEGIN: FORM CHANGE FUNCTIONS -->
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // <!--- END: FORM CHANGE FUNCTIONS -->

  // <!--- BEGIN: API CALLS -->
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`client_agreements/master/${id}`).then(
      async (result) => {
        const data = result.data;
        let mappedData = await mapData(data.data);
        setState(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });

    api
      .postService("client_agreements/master", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        const createdId = response?.data?.id;
        props.callBack(createdId);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });
    api
      .putService(`client_agreements/master/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }
  // <!--- END: API CALLS -->

  function mapDefaultStateData(key, data) {
    return data;
  }

  async function loadDefaultMetrics(
    event,
    key,
    reload = false,
    data = {},
    state_key
  ) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      let mappedData = await mapDefaultStateData(key, prevDefState);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: mappedData,
      }));
      setState((prevState) => ({
        ...prevState,
        [state_key]: data,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "client":
            url = "client/status/active";
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.Error(error);
      });
  }

  // TYPE AHEAD CHANGES
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  return (
    <div
      key="vehicle_type_modal"
      id="vehicle_type_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            id="client-agreeement-master-form"
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="name" className="form-label required">
                Agreement Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.name}
                required
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="name" className="form-label required">
                Client
              </label>
              <Typeahead
                clearButton
                filterBy={["name", "contact_number"]}
                id="client"
                name="client"
                onFocus={(event) => {
                  loadDefaultMetrics(event, "client");
                }}
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "client");
                }}
                options={defaultState?.client}
                // labelKey={(option) => `${option?.name || ""}`}
                labelKey="name"
                selected={formatTypeSearch(state?.client || "")}
                inputProps={{ required: true }}
                placeholder="Search client..."
                disabled={mode.view || mode.acknowledge}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, index) => (
                      <MenuItem option={result} position={index} key={index}>
                        <ClientsInList
                          data={result}
                          id={"client_" + index}
                          display={["phone"]}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="start_date" className="form-label required">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="start_date"
                name="start_date"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.start_date}
                required
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="end_date" className="form-label required">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="end_date"
                name="end_date"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.end_date}
                required
              />
            </div>

            <input
              type="submit"
              id="save_client_agreement_master"
              className="hidden"
            />
            <input
              type="submit"
              id="update_client_agreement_master"
              className="hidden"
            />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_client_agreement_master"
            >
              Next
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_client_agreement_master"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Client Agreement",
  action: "view",
  onSave() {},
  callBack() {},
};
