import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// HTML Components
import Error from "../../../components/error/error";
import Container from "../../../components/container";
import BackButton from "../../../components/pageElements/backButton";
import SimpleTab from "../../../components/tabs/simpleTab";
import CustomDataTable from "../../../components/tables/customDataTable";
import EditButton from "../../../components/htmlElements/editButton";
import CreateNewButton from "../../../components/htmlElements/createNewButton";
import TyreModeBadge from "../../../components/pageElements/tyreModeBadge";
// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Functions
import * as api from "../../../api_service/api";
import { resp } from "../../../functions/responseHandler";
import {
  localDate,
  matchData,
  formatKm,
  formatCurrency,
} from "../../../functions/common";
import { storageServices } from "../../../functions/storage";
// External Components

import TyreMasterForm from "../../../components/master/tyre";
import TyreRemovalForm from "../../../components/master/tyre/removalForm";
import TyreFitmentForm from "../../../components/master/tyre/fitmentForm";
import TyrePositionChangeForm from "../../../components/master/tyre/positionChangeForm";
import TyreServiceEntryForm from "../../../components/repair/tyre";

// Model
import { Model } from "./model";

// Images
import { ReactComponent as VehicleDocumentIcon } from "../../../assets/images/svg/vehicle-document.svg";
import { ReactComponent as WheelIcon } from "../../../assets/images/svg/wheel.svg";
import { ReactComponent as TruckIcon } from "../../../assets/images/svg/truck.svg";

export default function View(props) {
  const { tyre_id } = useParams();

  // States
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [record_id, setRecordId] = useState();
  const [historyList, setHistoryList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [fuelHistoryList, setFuelHistoryList] = useState([]);
  const [state, setState] = useState({ ...Model });
  const [localUser, setLocalUser] = useState();
  const [currentTab, setCurrentTab] = useState("documents");

  // <!-- BEGIN: Tyre Master Modal -->

  const [tyreModal, setTyreModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openTyreModal = () =>
    setTyreModal((prevState) => ({ ...prevState, show: true }));
  const closeTyreModal = () =>
    setTyreModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function tyreModalCallBack() {
    let newState = { show: false, title: "", action: "", id: "" };
    setTyreModal(newState);
    loadData(record_id);
  }
  // <!-- END: Tyre Master Modal -->

  // <!-- BEGIN: Tyre Position Change Modal -->

  const [tyrePositionChangeModal, setTyrePositionChangeModal] = useState({
    show: false,
    id: "",
    tyre: {},
    vehicle: {},
  });

  function changeTyrePosition(tyre_data) {
    let newState = { ...tyrePositionChangeModal };
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;

    newState["id"] = tyre_data?._id;
    newState["tyre"] = tyre_data;
    newState["vehicle"] = vehicleData;
    newState["show"] = true;
    setTyrePositionChangeModal(newState);
  }
  const closeTyrePositionChangeModal = () =>
    setTyrePositionChangeModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function tyrePositionChangeModalCallBack() {
    let newState = { show: false, id: "", vehicle: {}, tyre: {} };
    setTyrePositionChangeModal(newState);
    loadData(record_id);
  }
  // <!-- END: Tyre Position Change Modal -->

  // <!-- BEGIN: Tyre Removal Modal -->

  const [tyreRemomvalModal, setTyreRemovalModal] = useState({
    show: false,
    id: "",
    tyre: {},
    vehicle: {},
  });

  function removeTyre(tyre_data) {
    let newState = { ...tyreRemomvalModal };
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;

    newState["id"] = tyre_data?._id;
    newState["tyre"] = tyre_data;
    newState["vehicle"] = vehicleData;
    newState["show"] = true;
    setTyreRemovalModal(newState);
  }
  const closeTyreRemovalModal = () =>
    setTyreRemovalModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function tyreRemovalModalCallBack() {
    let newState = { show: false, id: "", vehicle: {}, tyre: {} };
    setTyreRemovalModal(newState);
    loadData(record_id);
  }
  // <!-- END: Tyre Removal Modal -->

  // <!-- BEGIN: Tyre Fitment Modal -->
  const [tyreFitmentModal, setTyreFitmentModal] = useState({
    show: false,
    id: "",
    tyre: {},
    vehicle: {},
  });

  function openTyreFitmentModal() {
    let newState = { ...tyreRemomvalModal };
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;

    newState["id"] = "";
    newState["vehicle"] = vehicleData;
    newState["show"] = true;
    setTyreFitmentModal(newState);
  }
  const closeTyreFitmentModal = () =>
    setTyreFitmentModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function tyreFitmentModalCallBack() {
    let newState = { show: false, id: "", vehicle: {}, tyre: {} };
    setTyreFitmentModal(newState);
    loadData(record_id);
  }
  // <!-- END: Tyre Fitment Modal -->

  // <!-- BEGIN: Tyre Service Modal -->

  const [serviceEntryModal, setServiceEntryModal] = useState({
    show: false,
    id: "",
    action: "",
    tyre: {},
    vehicle: {},
    consignment: {},
  });
  function openTyreServiceEntry() {
    let prevState = { ...serviceEntryModal };
    let tyreData = { ...state };
    tyreData["_id"] = record_id;
    prevState["tyre"] = tyreData;
    prevState["action"] = "create";
    prevState["show"] = true;
    setServiceEntryModal(prevState);
  }

  function viewServiceEntry(id) {
    let newState = { ...serviceEntryModal };
    newState["id"] = id;
    newState["action"] = "edit";
    newState["show"] = true;
    setServiceEntryModal(newState);
  }
  const closeServiceEntryModal = () =>
    setServiceEntryModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function serviceEntryModalModalCallBack() {
    let newState = {
      show: false,
      id: "",
      action: "",
      tyre: {},
      vehicle: {},
      consignment: {},
    };
    setServiceEntryModal(newState);
    loadServices(record_id);
  }
  // <!-- END: Tyre Service Modal -->

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };

    if (tyre_id) {
      loadData(tyre_id);
      getUser();
    } else {
      // SetError("No Valid Vehicle Id Found.");
    }
  }, []);

  function loadData(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`tyre/${id}`).then(
      async (result) => {
        const data = result.data;

        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let matchedData = await matchData(state, data);
    await setState(matchedData);
  }

  const InVehicleItem = (props) => {
    const { mode, fitment_date, odometer_reading, position, vehicle } = props;

    return (
      <div>
        <div className="truncate sm:whitespace-normal flex items-center">
          <div className="w-8 h-8 rounded-full flex items-center justify-center border mr-2 text-gray-500">
            <i class="fa fa-truck" aria-hidden="true"></i>
          </div>
          <span className="font-bold">{vehicle?.registration_number}</span>
        </div>

        <div className="truncate sm:whitespace-normal flex items-center mt-2">
          <div className="w-8 h-8 rounded-full flex items-center justify-center border mr-2 text-gray-500">
            <i class="fa fa-thumb-tack" aria-hidden="true"></i>
          </div>
          <span className="font-bold">{position}</span>
        </div>
        <div className="truncate sm:whitespace-normal flex items-center mt-2">
          <div className="w-8 h-8 rounded-full flex items-center justify-center border mr-2 text-gray-500">
            <i class="fa fa-calendar-o" aria-hidden="true"></i>
          </div>
          <span className="font-bold">{localDate(fitment_date)}</span>
        </div>
      </div>
    );
  };

  const DetailsListItem = (props) => {
    const { label, value } = props;

    return (
      <div className="truncate sm:whitespace-normal flex items-center">
        <span className="text-gray-700">{label}</span>
        <span className="ml-2 font-bold">{value}</span>
      </div>
    );
  };

  // Edit Record
  async function editRecord() {
    let newState = {
      show: true,
      title: `Edit Tyre`,
      action: "edit",
      id: record_id,
    };
    setTyreModal(newState);
  }

  const ValidityItemDisplay = (props) => {
    const { id, label, value } = props;

    return (
      <div className="relative flex items-center p-5" id={id}>
        <div className="w-12 h-12 image-fit">
          <VehicleDocumentIcon
            // width="20"
            // height="20"
            className="inline"
            fill="currentColor"
          />
        </div>
        <div className="ml-4 mr-auto">
          <div className="font-medium text-base">{localDate(value)}</div>
          <div className="text-gray-600">{label}</div>
        </div>
      </div>
    );
  };

  /////////// FUNCTIONS DECLARATION ///////////

  // <!-- BEGIN : Tyre History Data //

  function mapHistoryData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Action",
        field: "action",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      // {
      //   title: "Mode",
      //   field: "mode",
      //   vertAlign: "middle",
      //   headerFilter: true,
      //   headerFilterPlaceholder: "Search...",
      // },
      {
        title: "Vehicle",
        field: "vehicle.registration_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Position",
        field: "position",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Fitted Date",
        field: "fitment_date",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Removal Date",
        field: "removal_date",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Odometer",
        field: "odometer_reading",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    };
    return response;
  }

  function loadHistory(id) {
    setIsProcessing(true);
    api.getService(`tyre/history/${id}`).then(
      async (result) => {
        const data = result.data;
        console.log("hsi data:", data);
        let mappedData = await mapHistoryData(data.data);

        setHistoryList(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  function mapServicesData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "date",
        vertAlign: "middle",
        headerSort: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Service",
        field: "service_type",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vendor",
        field: "supplier.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Amount",
        field: "amount",
        vertAlign: "middle",
        headerSort: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "odometer Reading",
        field: "odometer_reading",
        vertAlign: "middle",
        headerSort: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    };
    return response;
  }

  // Edit Record
  async function editTyre(id) {
    let newState = {
      show: true,
      title: `Edit Tyre`,
      action: "edit",
      id: id,
    };
    setTyreModal(newState);
    return null;
  }

  // <!-- END : Tyre Data //

  function onChangeTab(value) {
    setCurrentTab(value);

    switch (value) {
      case "documents":
        break;
      case "history":
        loadHistory(record_id);
        break;
      case "services":
        loadServices(record_id);
        break;

      default:
        break;
    }
  }

  // Trips Data

  function loadServices(id) {
    setCurrentTab("services");
    setIsProcessing(true);
    api.getService(`tyre_service/tyre/${id}`).then(
      async (result) => {
        const data = result.data;
        let mappedData = await mapServicesData(data.data);
        setServicesList(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  if (error) {
    return <Error />;
  } else {
    return (
      <Container>
        {/* <!-- BEGIN: Tyre Service Modal --> */}
        {serviceEntryModal.show && (
          <TyreServiceEntryForm
            show={serviceEntryModal.show}
            action={serviceEntryModal.action}
            tyre={serviceEntryModal.tyre}
            title=""
            onHide={closeServiceEntryModal}
            callBack={serviceEntryModalModalCallBack}
            id={serviceEntryModal.id}
            request_module="tyre-view"
          />
        )}
        {/* <!-- END:Tyre Service Modal --> */}

        {/* <!-- BEGIN: Tyre Master Modal --> */}
        {tyreModal.show && (
          <TyreMasterForm
            show={tyreModal.show}
            action={tyreModal.action}
            title={tyreModal.title}
            onHide={closeTyreModal}
            callBack={tyreModalCallBack}
            id={tyreModal.id}
          />
        )}
        {/* <!-- END:Tyre Master Modal --> */}

        {/* <!-- BEGIN: Tyre Removal Modal --> */}
        {tyreRemomvalModal.show && (
          <TyreRemovalForm
            show={tyreRemomvalModal.show}
            id={tyreRemomvalModal.id}
            action="create"
            tyre={tyreRemomvalModal.tyre}
            vehicle={tyreRemomvalModal.vehicle}
            onHide={closeTyreRemovalModal}
            callBack={tyreRemovalModalCallBack}
          />
        )}
        {/* <!-- END:Tyre Removal Modal --> */}

        {/* <!-- BEGIN: Tyre Position Change Modal --> */}
        {tyrePositionChangeModal.show && (
          <TyrePositionChangeForm
            show={tyrePositionChangeModal.show}
            id={tyrePositionChangeModal.id}
            action="create"
            tyre={tyrePositionChangeModal.tyre}
            vehicle={tyrePositionChangeModal.vehicle}
            onHide={closeTyrePositionChangeModal}
            callBack={tyrePositionChangeModal}
          />
        )}
        {/* <!-- END:Tyre Position Change Modal --> */}

        {/* <!-- BEGIN: Tyre Fitment Modal --> */}
        {tyreFitmentModal.show && (
          <TyreFitmentForm
            show={tyreFitmentModal.show}
            id={tyreFitmentModal.id}
            action="create"
            vehicle={tyreFitmentModal.vehicle}
            onHide={closeTyreFitmentModal}
            callBack={tyreFitmentModalCallBack}
          />
        )}
        {/* <!-- END:Tyre Fitment Modal --> */}

        {isProcessing ? <ProcessLoader /> : null}

        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title>
            {state?.serial_no}
            <EditButton className="ml-2" onClick={editRecord} />
          </Container.Title>

          <div className="ml-auto">
            <TyreModeBadge mode={state?.mode} />
          </div>

          <Container.HeaderActions>
            <CreateNewButton
              id="add-tyre-service-button"
              onClick={openTyreServiceEntry}
            >
              Service Entry
            </CreateNewButton>
          </Container.HeaderActions>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/* <!-- BEGIN: Body --> */}
        <Container.Body>
          {/* <!-- BEGIN: Top Info --> */}
          <div className="box px-5 pt-5 mt-5">
            <div className="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
              <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                <div className="">
                  {state?.mode === "vehicle" && (
                    <InVehicleItem id="tyre-mode" {...state} />
                  )}
                  {state?.mode !== "vehicle" && (
                    <>
                      <DetailsListItem
                        id="brand"
                        label="Brand"
                        value={state?.brand}
                      />

                      <DetailsListItem
                        id="sub_brand"
                        label="Sub Brand"
                        value={state?.sub_brand}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
                <div className="flex flex-col justify-center items-center lg:items-start mt-4">
                  {state?.mode === "vehicle" && (
                    <>
                      <DetailsListItem
                        id="brand"
                        label="Brand"
                        value={state?.brand}
                      />

                      <DetailsListItem
                        id="sub_brand"
                        label="Sub Brand"
                        value={state?.sub_brand}
                      />
                    </>
                  )}

                  <DetailsListItem
                    id="classification"
                    label="Classification"
                    value={state?.classification}
                  />
                  <DetailsListItem id="type" label="Type" value={state?.type} />
                </div>
              </div>
              <div className="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0">
                <div className="w-1/2 text-center rounded-md py-3">
                  <div className="font-medium text-theme-1 dark:text-theme-10 text-lg">
                    {formatKm(state?.kms_covered)}
                  </div>
                  <div className="text-gray-600">Kms Covered</div>
                </div>

                <div className="w-1/2 text-center rounded-md py-3">
                  <div className="font-medium text-theme-1 dark:text-theme-10 text-lg">
                    {formatKm(state?.max_running_limit)}
                  </div>
                  <div className="text-gray-600">Max. Kimit</div>
                </div>
              </div>
            </div>

            {/* <!-- BEGIN: Tab Switcher --> */}
            <SimpleTab>
              <SimpleTab.Tab
                id="documents"
                active={currentTab === "documents" ? true : false}
                onClick={(e) => onChangeTab("documents")}
              >
                Documents
              </SimpleTab.Tab>
              <SimpleTab.Tab
                id="history"
                active={currentTab === "history" ? true : false}
                onClick={(e) => onChangeTab("history")}
              >
                History
              </SimpleTab.Tab>
              <SimpleTab.Tab
                id="services"
                active={currentTab === "services" ? true : false}
                onClick={(e) => onChangeTab("services")}
              >
                Services
              </SimpleTab.Tab>
            </SimpleTab>
            {/* <!-- END: Tab Switcher --> */}
          </div>
          {/* <!-- END: Top Info --> */}

          <div className="chat__box box mt-5">
            {/* <!-- BEGIN: Documents List --> */}
            <div className="chat grid grid-cols-12 gap-5 ">
              {currentTab === "documents" && (
                <>
                  <div className="md:col-span-4 sm:col-span-6">
                    <ValidityItemDisplay
                      id="warranty_end_date"
                      label="Warranty Expiry"
                      value={state?.warranty_end_date}
                    />
                  </div>

                  <div className="md:col-span-4 sm:col-span-6">
                    <ValidityItemDisplay
                      id="warranty_kms"
                      label="Warranty Km"
                      value={state?.warranty_kms}
                    />
                  </div>

                  <div className="md:col-span-4 sm:col-span-6">
                    <ValidityItemDisplay
                      id="warranty_docs"
                      label="Warranty Docs"
                      value={state?.warranty_docs}
                    />
                  </div>
                </>
              )}
            </div>
            {/* <!-- END: Documents List  --> */}

            {/* <!-- BEGIN: History List --> */}
            {currentTab === "history" && (
              <div className="chat grid grid-cols-12 gap-5 p-3">
                <div className="col-span-12">
                  <CustomDataTable
                    header={historyList.header}
                    content={historyList.content}
                    // onRowClick={(e, row) => {
                    //   viewHistory(row._row.data._id);
                    // }}
                    filter={true}
                    filterFields={historyList.filterFields}
                  />
                </div>
              </div>
            )}
            {/* <!-- END: History List  --> */}

            {/* <!-- BEGIN: Services List --> */}
            {currentTab === "services" && (
              <div className="chat grid grid-cols-12 gap-5 p-3">
                <div className="col-span-12">
                  <CustomDataTable
                    header={servicesList.header}
                    content={servicesList.content}
                    onRowClick={(e, row) => {
                      viewServiceEntry(row._row.data._id);
                    }}
                    filter={true}
                    filterFields={servicesList.filterFields}
                  />
                </div>
              </div>
            )}
            {/* <!-- END: Fuel History List  --> */}
          </div>
        </Container.Body>
        {/* <!-- END: Body --> */}
      </Container>
    );
  }
}
