// REACT
import React from "react";

import { useHistory } from "react-router-dom";

export default function Layout(props) {
  const history = useHistory();
  function goBack(event) {
    // event.preventDefault();
    try {
      history.goBack();
    } catch (e) {
      console.error("Inavlid Routing.Couldn't go back.");
    }
  }

  function goHome(event) {
    window.location.href = "login";
  }
  return (
    <>
      <div className="container">
        {/* <!-- BEGIN: Error Page --> */}
        <div className="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left">
          <div className="-intro-x lg:mr-20">
            <img
              alt="Rubick Tailwind HTML Admin Template"
              className="h-48 lg:h-auto"
              src="images/error-illustration.svg"
            />
          </div>
          <div className="text-white mt-10 lg:mt-0">
            <div className="intro-x text-8xl font-medium">404</div>
            <div className="intro-x text-xl lg:text-3xl font-medium mt-5">
              Oops. This page has gone missing.
            </div>
            <div className="intro-x text-lg mt-3">
              You may have mistyped the address or the page may have moved.
            </div>
            <button
              onClick={goBack}
              className="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10"
            >
              Go Back
            </button>

            <button
              onClick={goHome}
              className="ml-3 intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10"
            >
              Back to Home
            </button>
          </div>
        </div>
        {/* <!-- END: Error Page --> */}
      </div>
    </>
  );
}
