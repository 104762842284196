import React from "react";
import PropTypes from "prop-types";

import { isDefined, isDateExpired } from "../../functions/common";

export default function Component(props) {
  const { data, className, id, image, email, display, ...rest } = props;

  return (
    <div className={className} id={id}>
      {/* <div className="w-8 h-8 flex-none image-fit mr-2">
        <img alt="" className="rounded-full" src={image} />
      </div> */}
      <div className="overflow-hidden">
        <div className="flex items-center">
          <div className="font-medium">{data?.name}</div>
          {display?.includes("license_expiry") &&
            isDefined(data?.valid_to) &&
            isDateExpired(data?.valid_to) && (
              <small className="ml-2 bg-theme-6 text-white rounded text-center px-1">
                License Expired
              </small>
            )}
        </div>
        <div className="flex space-x-2 mt-0.5">
          {display?.includes("phone") && isDefined(data?.contact_number) && (
            <div className="truncate text-gray-600 " id="driver_contact_number">
              <i class="fa fa-phone text-blue-400 mr-1" aria-hidden="true"></i>
              {data?.contact_number}
            </div>
          )}

          {display?.includes("license_no") && isDefined(data?.license_no) && (
            <div className="truncate text-gray-600 " id="driver_license_no">
              <i
                class="fa fa-id-card-o text-green-400 mr-1"
                aria-hidden="true"
              ></i>
              {data?.license_no}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  image: PropTypes.string,
  display: PropTypes.array, // Entities to be shown : email,phone
};

Component.defaultProps = {
  className: "cursor-pointer relative flex items-center",
  data: { name: "", contact_number: "", email: "" },
  image: "images/profile-pic-default.png",
  display: ["phone", "license_no"],
};
