import React, { useEffect, useState } from "react";
// Components
import Container from "../../components/container";
import { Modal } from "react-bootstrap";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker,
  InfoWindow,
} from "react-google-maps";
// const mapStyles = require("./mapStyles.json");

// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import {
  isDefined,
  htmlDateTime,
  metreToKm,
  formatHours,
} from "../../functions/common";
import { localeData } from "moment";

// Constants

const GOOGLEMAP_API_KEY = process.env.REACT_APP_GOOGLEMAP_KEY;

const sampleData = [
  { lat: 28.539533333333335, lng: 77.05334444444445 },
  { lat: 28.539581666666667, lng: 77.05323333333334 },
  { lat: 28.539614999999998, lng: 77.05313333333334 },
  { lat: 28.539766666666665, lng: 77.05258166666667 },
  { lat: 28.539884444444443, lng: 77.05252666666667 },
  { lat: 28.539884444444443, lng: 77.05252666666667 },
  { lat: 28.542425, lng: 77.05253666666667 },
  { lat: 28.544408333333333, lng: 77.05254333333333 },
  { lat: 28.544445, lng: 77.052655 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544439999999998, lng: 77.05512 },
  { lat: 28.544561666666667, lng: 77.055295 },
  { lat: 28.546363333333336, lng: 77.05680833333334 },
  { lat: 28.54712166666667, lng: 77.05741277777777 },
  { lat: 28.547226666666667, lng: 77.05737 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.547706666666667, lng: 77.05692833333333 },
  { lat: 28.548081666666665, lng: 77.05644666666666 },
  { lat: 28.548235000000002, lng: 77.05629 },
  { lat: 28.548235000000002, lng: 77.05629 },
  { lat: 28.548571666666668, lng: 77.05574333333333 },
  { lat: 28.548655, lng: 77.05571166666667 },
  { lat: 28.548745, lng: 77.05563666666667 },
  { lat: 28.55049, lng: 77.05438 },
  { lat: 28.550714999999997, lng: 77.05413666666666 },
  { lat: 28.55175, lng: 77.05356833333333 },
  { lat: 28.553496666666668, lng: 77.05223166666667 },
  { lat: 28.553915, lng: 77.05173833333333 },
];

var count = 1;
var lastPosition = {};
const currentTime = new Date();

const ErrorHTML = () => {
  return <div class="font-medium">Oops! Something went wrong.</div>;
};

const NoDataHTML = () => {
  return <div class="font-medium">No Data Available.</div>;
};

const MarkerInfoHTML = (props) => {
  const { data } = props;
  return (
    <div className="">
      <p className="mb-2">
        Time :{" "}
        <span className="flex text-theme-1 font-bold">
          {htmlDateTime(data?.time)}

          {props?.isExpectedTime && (
            <div class="bg-theme-17 text-theme-11 rounded px-2 ml-2">
              Expected
            </div>
          )}
        </span>
      </p>

      <p className="">
        Address : <span className="font-bold">{data?.address}</span>
      </p>
    </div>
  );
};

const Map = ({ vehicle, consData, consStage }) => {
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [lastPosition, setLastPosition] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState();
  const [activeMarker, setActiveMarker] = useState();
  const [gpsData, setGpsData] = useState([]);
  const [progress, setProgress] = useState([]);

  const [state, setState] = useState({
    total_distance: 0,
    distance_covered: 0,
    expected_arrival: "",
    isTripCompleted: false,
    initial_position: { lat: "", lng: "" },
    final_position: { lat: "", lng: "" },
  });

  // const [count, setCount] = useState(1);
  const path = [];

  function handleActiveMarker(marker) {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  }
  async function loadData() {
    setIsProcessing(true);
    let query = await getFilterQuery();
    await api
      .gpsPostService(`gps/get_location_data`, query)
      .then(async (response) => {
        const data = response.data;
        // Checking If Data Exists
        if (data.data.length > 1) {
          let recData = await mapData(data.data);
          console.log("mapData:", recData);
          lastPosition = recData[recData.length - 1];
          setDefaultCenter({ lat: lastPosition.lat, lng: lastPosition.lng });
          setGpsData(recData);
        } else {
          setNoData(true);
        }
        setIsProcessing(false);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setError(true);
        setIsProcessing(false);
        setIsLoaded(true);
      });
  }

  function getFilterQuery() {
    let query = {};
    query["vehicle_number"] = vehicle?.registration_number || "";
    query["start_time"] = isDefined(consData?.start_time)
      ? new Date(consData?.start_time)
      : new Date();

    query["end_time"] = isDefined(consData?.end_time)
      ? new Date(consData?.end_time)
      : new Date();
    return query;
  }

  function mapData(data) {
    let locationData = [];
    let totalDistance = 0;
    let totalDistanceCovered = 0;
    let estimatedTimeArrival = "";
    let speed = 0;
    const initialPosition = {
      lat: "",
      lng: "",
      time: "",
      isExpectedTime: false,
    };
    const finalPosition = { lat: "", lng: "", time: "", isExpectedTime: false };

    const origin = consData?.origin;
    const destination = consData?.destination;
    // Calculating Distance Between Origin and Destination

    if (
      origin?.latitude &&
      origin.longitude &&
      destination?.latitude &&
      destination.longitude
    ) {
      const { latitude: originlat, longitude: originlng } = origin;
      const originLatLng = new window.google.maps.LatLng(originlat, originlng);

      initialPosition["lat"] = originlat;
      initialPosition["lng"] = originlng;
      initialPosition["address"] = `${origin?.name}`;

      const { latitude: destinationlat, longitude: destinationlng } =
        destination;
      const destinationLatLng = new window.google.maps.LatLng(
        destinationlat,
        destinationlng
      );

      finalPosition["lat"] = destinationlat;
      finalPosition["lng"] = destinationlng;
      finalPosition["address"] = `${destination?.place}, ${destination?.name}`;
      // in meters:
      totalDistance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          originLatLng,
          destinationLatLng
        );

      totalDistance = metreToKm(totalDistance);
    }

    for (var i in data) {
      for (var j in data[i]["LocationData"]) {
        // Renaming Fields
        data[i]["LocationData"][j]["lat"] =
          data[i]["LocationData"][j]["latitude"];
        data[i]["LocationData"][j]["lng"] =
          data[i]["LocationData"][j]["longitude"];

        data[i]["LocationData"][j]["time"] =
          data[i]["LocationData"][j]["gps_datetime"];

        data[i]["LocationData"][j]["address"] =
          data[i]["LocationData"][j]["addressLineOne"];

        locationData.push(data[i]["LocationData"][j]);
      }
    }

    // Calculating Distance Covered So Far

    for (var i in locationData) {
      if (i === 0) {
        locationData[i]["distance"] = 0;
      } else {
        const { lat: lat1, lng: lng1 } = locationData[i];
        const latLong1 = new window.google.maps.LatLng(lat1, lng1);

        const { lat: lat2, lng: lng2 } = locationData[0];
        const latLong2 = new window.google.maps.LatLng(lat2, lng2);

        // in meters:
        const distance =
          window.google.maps.geometry.spherical.computeDistanceBetween(
            latLong1,
            latLong2
          );
        locationData[i]["distance"] = distance;

        // Getting Distance covered so far - last data

        // if (i === locationData.length - 1) {
        //   console.log("distance:", distance);
        //   speed = locationData[i]["speed"];
        //   totalDistanceCovered = distance;
        // }
      }
    }

    speed = locationData[locationData.length - 1]["speed"];

    totalDistanceCovered = metreToKm(
      locationData[locationData.length - 1]["distance"]
    );
    // totalDistanceCovered = metreToKm(totalDistanceCovered);

    // Calculating Estimated Time Arrival

    estimatedTimeArrival = (totalDistance - totalDistanceCovered) / speed;

    console.log("estimatedTimeArrival:", estimatedTimeArrival);

    estimatedTimeArrival = formatHours(estimatedTimeArrival);

    // Adding destination Value
    let isExpectedTime = false;
    let arrival_time = "";
    // Checking If trip is completed
    if (consStage > 2) {
      arrival_time = consData?.end_time;
    } else {
      arrival_time = estimatedTimeArrival;
      finalPosition["isExpectedTime"] = true;
    }

    finalPosition["time"] = arrival_time;

    let prevState = { ...state };
    prevState.total_distance = totalDistance;
    prevState.distance_covered = totalDistanceCovered;
    prevState.expected_arrival = estimatedTimeArrival;
    prevState.initial_position = initialPosition;
    prevState.final_position = finalPosition;
    setState(prevState);

    return locationData;
  }

  const getPositions30Seconds = async () => {
    // let from = new Date();
    // let milliseconds = Date.parse(from);
    // milliseconds = milliseconds - 0.5 * 60 * 1000;
    // from = new Date(milliseconds).toISOString();
    // console.log(from);
    // const to = new Date().toISOString();
    // console.log(to);
    // const response = await fetch(
    //   `/api/positions/?deviceId=14&from=${from}&to=${to}`,
    //   {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //   }
    // );
    // const items = await response.json();
    // console.log(items);
    // items.map((item) => {
    //   path.push({ lat: item.latitude, lng: item.longitude });
    //   return path;
    // });
    // var tempCount = count;
    if (typeof sampleData[count] !== "undefined") {
      path.push(sampleData[count]);
      // progress.push(sampleData[count]);

      // Progress
      console.log("sampleData[count]['lat']:", sampleData[count]["lng"]);

      const position = window.google.maps.geometry.spherical.interpolate(
        sampleData[count]["lat"],
        sampleData[count]["lng"],
        2
      );

      let prevProgress = [...progress];
      prevProgress.concat(position);
      setProgress(prevProgress);
    }
    // setCount(tempCount + 1);

    count++;
    console.log(count);
  };

  const getDistance = (velocity = 30) => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - currentTime) / 8000; // pass to seconds
    return differentInTime * velocity;
  };

  function startTracking() {
    const interval = window.setInterval(moveObject, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }

  function moveObject() {
    const distance = getDistance();
    if (!distance) {
      return;
    }

    let progressData = gpsData.filter(
      (coordinates) => coordinates.distance < distance
    );

    const nextLine = gpsData.find(
      (coordinates) => coordinates.distance > distance
    );
    if (!nextLine) {
      setProgress(progressData);
      return; // it's the end!
    }
    const lastLine = progressData[progressData.length - 1];

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    progressData = progressData.concat(position);
    setProgress(progressData);

    // Calculating Angle

    const angle = window.google.maps.geometry.spherical.computeHeading(
      lastLineLatLng,
      nextLineLatLng
    );

    // const actualAngle = angle - 35;
    const actualAngle = angle;

    const markerUrl = "/images/truck-top.png";
    const item = document.querySelector(`[src="${markerUrl}"]`);

    if (item) {
      // when it hasn't loaded, it's null
      item.style.transform = `rotate(${actualAngle}deg)`;
    }
  }

  useEffect(() => {
    // const interval = window.setInterval(moveObject, 1000);
    // return () => {
    //   window.clearInterval(interval);
    // };
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const icon = {
    url: "/images/truck-top.png",
    scaledSize: new window.google.maps.Size(30, 30),
    anchor: { x: 10, y: 10 },
  };

  if (error) {
    return <ErrorHTML />;
  } else {
    return (
      <>
        {isProcessing && <ProcessLoader />}
        {noData ? (
          <NoDataHTML />
        ) : isLoaded ? (
          <>
            <GoogleMap
              defaultZoom={15}
              // defaultCenter={path[path.length - 1]}
              // defaultCenter={{
              //   lat: path[path.length - 1]?.lat,
              //   lng: path[path.length - 1]?.lng,
              // }}
              defaultCenter={defaultCenter}
              // defaultOptions={{
              //   // styles: mapStyles,
              //   fullscreenControl: false,
              //   mapTypeControl: false,
              //   streetViewControl: false,
              // }}
            >
              {/* {progress && ( */}
              <>
                <Polyline
                  path={gpsData}
                  geodesic={true}
                  options={{
                    strokeColor: "#2196f3",
                    strokeOpacity: 0.5,
                    strokeWeight: 8,
                  }}
                />

                {/* <Marker
                  position={gpsData[0]}
                  id="starting_position"
                  onClick={(e) => handleActiveMarker("starting_position")}
                >
                  {activeMarker === "starting_position" ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <MarkerInfoHTML data={gpsData[0]} />
                    </InfoWindow>
                  ) : null}
                </Marker> */}

                {/* <!--BEGIN : Last Known Poisition of Truck --> */}
                <Marker
                  icon={icon}
                  position={gpsData[gpsData.length - 1]}
                  onClick={(e) => handleActiveMarker("last_position")}
                  id="last_position"
                >
                  {activeMarker === "last_position" ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <MarkerInfoHTML data={gpsData[gpsData.length - 1]} />
                    </InfoWindow>
                  ) : null}
                </Marker>
                {/* <!--END : Last Known Poisition of Truck --> */}

                {/* <!--BEGIN : Destination --> */}
                <Marker
                  position={state?.final_position}
                  id="final_position"
                  onClick={(e) => handleActiveMarker("final_position")}
                >
                  {activeMarker === "final_position" ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <MarkerInfoHTML data={state?.final_position} />
                    </InfoWindow>
                  ) : null}
                </Marker>
                {/* <!--END : Destination --> */}
              </>
              {/* )} */}
            </GoogleMap>

            <div className="grid grid-cols-12 gap-3">
              {/* <!-- BEGIN: Element 1 --> */}
              <div className="box col-span-12 lg:col-span-3 p-5">
                <div className="flex flex-col sm:flex-row">
                  <div className="mr-auto">
                    <span className="text-gray-800">Total Distance:</span>
                  </div>
                  <div className="font-medium">{state?.total_distance} Km</div>
                </div>
              </div>
              {/* <!-- END: Element 1 --> */}

              {/* <!-- BEGIN: Element 2 --> */}
              <div className="box col-span-12 lg:col-span-3 p-5">
                <div className="flex flex-col sm:flex-row">
                  <div className="mr-auto">
                    <span className="text-gray-800">Distance Covered:</span>
                  </div>
                  <div className="font-medium">
                    {state?.distance_covered} Km
                  </div>
                </div>
              </div>
              {/* <!-- END: Element 2 --> */}

              {/* <!-- BEGIN: Element 2 --> */}
              <div className="box col-span-12 lg:col-span-3 p-5">
                <div className="flex flex-col sm:flex-row">
                  <div className="mr-auto">
                    <span className="text-gray-800">Estimated Arrival:</span>
                  </div>
                  <div className="font-medium">
                    {state?.expected_arrival} Hr
                  </div>
                </div>
              </div>
              {/* <!-- END: Element 2 --> */}
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
};

const MapComponent = withScriptjs(withGoogleMap(Map));

export default function View(props) {
  return (
    <>
      <div
        key="gps_map_form_modal"
        id="gps_map_form_modal"
        onClick={(e) => e.stopPropagation()}
      >
        <Modal
          {...props}
          onHide={props.onHide}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-xxl"
          centered
        >
          <Modal.Header closeButton>
            <h2 className="font-medium text-base mr-auto">Map View</h2>
          </Modal.Header>

          <Modal.Body className="p-5">
            <div className="flex flex-grow h-full">
              <MapComponent
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAP_API_KEY}&v=3.exp&libraries=geometry,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div style={{ height: `75vh`, width: "100%" }} />
                }
                // containerElement={
                //   <div
                //     style={{
                //       position: "relative",
                //       margin: "auto",
                //       width: "100%",
                //       height: "100%",
                //     }}
                //   />
                // }
                mapElement={<div style={{ height: `100%` }} />}
                vehicle={props.vehicle}
                consData={props.consData}
                consStage={props.consStage}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-outline-secondary w-20 mr-1"
              onClick={props.onHide}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
