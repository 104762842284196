import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Components
import { Modal } from "react-bootstrap";
import ProcessLoader from "../preloader/processLoader";
import ValidationError from "../../components/error/validationError";
import DividerText from "../htmlElements/dividerText";
import PhoneInput from "../htmlElements/phoneInput";
import { Typeahead } from "react-bootstrap-typeahead";
// Functions
import { resp } from "../../functions/responseHandler";
import {
  formatTypeaheadCommon,
  toFloat,
  isDefined,
} from "../../functions/common";
import * as api from "../../api_service/api";
import * as validation from "../../functions/validation";

const Model = {
  name: "",
  contact_person: "",
  contact_number: "",
  address: "",

  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",

  gst: "",
  cutoff_days: "",
  status: "",
  other_details: false,
  address_details: false,
  isNew: false,
};

const countryListFile = require("../../utils/country_state_list.json");
const countryStateList = countryListFile.countries;
// const countryList = countryStateList.map((a) => a.country);
const countryList = ["India"];
const defaultCountry = "India";

export default function Component(props) {
  const [saveToDB, setSaveToDB] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [mode, setMode] = useState({ view: false, edit: false, create: false });
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  // Errors
  const [validationError, setValidationError] = useState({
    ...Model,
  });
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  // Default State
  const [defaultState, setDefaultState] = useState({
    role: [],
    organization: [],
    state: [],
    country: countryList,
  });
  useEffect(() => {
    loadDefaults();
    handleUserAction(props);
  }, []);

  async function loadDefaults() {
    let obj = await countryStateList.find((o) => o.country === defaultCountry);
    if (typeof obj !== "undefined" && obj.states.length > 0) {
      setDefaultState((prevState) => ({
        ...prevState,
        state: obj.states,
      }));
    }
  }
  async function setDefaults() {
    let prevState = { ...state };

    if (props?.name) prevState["name"] = props?.name;

    prevState.country = defaultCountry;
    prevState.cutoff_days = 7;
    setState(prevState);
  }

  async function handleUserAction(props) {
    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setState(Model);
        setDefaults();
        setMode((prevState) => ({ ...prevState, create: true }));
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`client/${id}`).then(
      async (result) => {
        const data = result.data;
        console.log("result:", result);
        let mappedData = await mapData(data.data);
        console.log("data:", data);

        // Checking Address / Other Details

        if (
          isDefined(mappedData?.address_line_1) ||
          isDefined(mappedData?.city)
        ) {
          mappedData.address_details = true;
        }

        if (
          isDefined(mappedData?.gst) ||
          isDefined(mappedData?.contact_person) ||
          isDefined(mappedData?.email_invoice)
        ) {
          mappedData.other_details = true;
        }

        setState(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  function mapData(data) {
    data["current_balance"] = toFloat(
      data?.opening_balance || 0 + data?.current_total || 0
    );
    return data;
  }

  function prepareData(data) {
    data["type"] = data?.type?._id;
    return data;
  }

  async function onSubmit(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));

      var query = prepareData({ ...state });
      query["status"] = "active";
      query["isNew"] = true;

      if (saveToDB) {
        setIsProcessing(true);
        api
          .postService("client", query)
          .then((response) => {
            setIsProcessing(false);
            resp.Success(response?.data?.message);
            props.callBack(response?.data?.data);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        props.callBack(query);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      var query = prepareData({ ...state });
      api
        .putService(`client/${record_id}`, query)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === "opening_balance") {
      let currentBalance =
        parseFloat(value) + parseFloat(state?.current_total || 0);
      setState((prevState) => ({
        ...prevState,
        current_balance: currentBalance,
      }));
    }
    formValidation(name, value);
  }

  function changeInputPhoneNumber(value) {
    setState((prevState) => ({ ...prevState, contact_number: value }));
  }

  function switchBoolean(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  async function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));

      // ------ Loading States -------
      if (name === "country") {
        let obj = await countryStateList.find((o) => o.country === selected[0]);
        if (typeof obj !== "undefined" && obj.states.length > 0) {
          setDefaultState((prevState) => ({
            ...prevState,
            state: obj.states,
          }));
        }
      }
      // ------ Loading States -------
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function formValidation(name, value) {
    let errors = { ...validationError };
    switch (name) {
      case "name":
        errors[name] = validation.validateName(value);
        break;
      case "phone":
        errors[name] = validation.validatePhoneNo(value);
        break;

      case "gst":
        errors[name] = validation.validateGST(value);
        break;

      case "zip_code":
        errors[name] = validation.validatePinCode(value);
        break;

      default:
        break;
    }
    setValidationError(errors);
  }

  return (
    // <div onClick={(e) => e.stopPropagation()}>
    // <>
    <div
      key="client_modal"
      id="client_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
        size="lg"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="name" className="form-label required">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.name}
                required
              />
              <ValidationError msg={validationError?.name} />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="contact_number" className="form-label">
                Phone Number
              </label>
              <PhoneInput
                type="number"
                className="form-control"
                id="contact_number"
                name="contact_number"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.contact_number}
              />
              <ValidationError msg={validationError?.contact_number} />
            </div>

            {/* <!--BEGIN: Other Details --> */}

            {state?.other_details && (
              <>
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="gst" className="form-label">
                    GST Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="gst"
                    name="gst"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.gst}
                  />
                  <ValidationError msg={validationError?.gst} />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="contact_person" className="form-label">
                    Contact Person
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact_person"
                    name="contact_person"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.contact_person}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="cutoff_days" className="form-label">
                    Payment Cut off Days
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="cutoff_days"
                    name="cutoff_days"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.cutoff_days}
                    min={0}
                    oninput={"validity.valid||(value='');"}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="email_invoice" className="form-label">
                    Email{" "}
                    <small className="text-gray-500">
                      - Invoice Notification
                    </small>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email_invoice"
                    name="email_invoice"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.email_invoice}
                  />
                </div>
              </>
            )}
            {/* <!--END: Other Details --> */}

            {/* <!--BEGIN: Address Details --> */}
            {state?.address_details && (
              <>
                {/* <!--BEGIN: Address Details Fields */}
                <div className="col-span-12">
                  <DividerText>Address Details</DividerText>
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="address_line_1" className="form-label">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address_line_1"
                    name="address_line_1"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.address_line_1}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="address_line_2" className="form-label">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address_line_2"
                    name="address_line_2"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.address_line_2}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.city}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <Typeahead
                    id="city"
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "state");
                    }}
                    options={defaultState.state}
                    labelKey={(option) => `${option}`}
                    selected={formatTypeaheadCommon(state?.state)}
                    // inputProps={{ required: true }}
                    disabled={mode.view}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <Typeahead
                    id="country"
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "country");
                    }}
                    options={defaultState.country}
                    labelKey={(option) => `${option}`}
                    selected={formatTypeaheadCommon(state?.country)}
                    // inputProps={{ required: true }}
                    disabled={mode.view}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="zip_code" className="form-label">
                    Pin Code
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="zip_code"
                    name="zip_code"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.zip_code}
                  />
                  <ValidationError msg={validationError?.zip_code} />
                </div>
                {/* <!--END: Address Details Fields*/}
              </>
            )}
            {/* <!--END: Address Details --> */}

            {/* Other Details Button */}
            {!mode?.view && (
              <div className="col-span-12 mt-2">
                <div className="preview">
                  {!state?.other_details && (
                    <button
                      className="btn btn-outline-primary inline-block mr-1 mb-2"
                      name="other_details"
                      onClick={switchBoolean}
                    >
                      <i className="fa fa-plus mr-1" aria-hidden="true"></i>Add
                      Other Details
                    </button>
                  )}
                  {!state?.address_details && (
                    <button
                      className="btn btn-outline-primary inline-block mr-1 mb-2"
                      name="address_details"
                      onClick={switchBoolean}
                    >
                      <i className="fa fa-plus mr-1" aria-hidden="true"></i>Add
                      Address
                    </button>
                  )}
                </div>
              </div>
            )}
            {/* Other Details Button */}

            <input type="submit" id="save_client" className="hidden" />
            <input type="submit" id="update_client" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {/* <!-- BEGIN: Validation error msg --> */}
          <div className="inline-flex mr-2 float-left">
            <ValidationError msg={!isValidForm.valid ? isValidForm.msg : ""} />
          </div>{" "}
          {/* <!-- END: Validation error msg --> */}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_client"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_client"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
    // </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,

  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Client",
  action: "view",

  callBack() {},
};
