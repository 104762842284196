import React, { useState, useEffect } from "react";
// HTML Elements
import ProcessLoader from "../../preloader/processLoader";
import ImageNFileInput from "../../inputs/imagenfile";
import ValidationError from "../../error/validationError";
import { Modal } from "react-bootstrap";
import KmInput from "../../htmlElements/kmInput";
import TypeAhAddNewButton from "../../htmlElements/typeAhAddNewButton";
import TruckNumberDisplay from "../../consignment/truckNumberDisplay";

import TyrePositionDropdown from "../../pageElements/tyrePositionDropdown";
import CurrentOdometerLabel from "../../pageElements/currentOdometerLabel";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

import Form from "../../form/custom";
import DividerText from "../../htmlElements/dividerText";

// Add-on Components
import VehicleMaster from "../vehicle";

// Functions
import { storageServices } from "../../../functions/storage";

import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";
import {
  htmlDate,
  matchData,
  toFloat,
  formatTypeSearch,
  formatTypeaheadCommon,
  isDefined,
} from "../../../functions/common";
import * as validation from "../../../functions/validation";

// Models
import { Model, ValidationErrorModel } from "./model";

const fileBaseURL = process.env.REACT_APP_VEHICLE_URL;
export default function TyreMasterForm(props) {
  const { request_module, vechicleData } = props;

  const [disabledFields, setDisabledFields] = useState({
    vehicle: false,
    position: false,
  });
  const [saveToDB, setSaveToDB] = useState(true);
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
    approve: false,
  });

  // State
  const [state, setState] = useState({ ...Model });
  const [validationError, setValidationError] = useState({
    ...ValidationErrorModel,
  });

  const [localUser, setLocalUser] = useState();

  //////////// External Modal States   ////////////
  const [vehicleMasterModal, setVehicleMasterModal] = useState({
    show: false,
    title: "Add Vehicle",
    action: "create",
    id: "",
  });

  //////////// External Modal States   ////////////

  const [record_id, setRecordId] = useState();
  const [defaultState, setDefaultState] = useState({
    vehicle: [],
    brand: [],
    sub_brand: [],
  });

  const [typeAheadFields, setTypeAheadFields] = useState({
    vehicle: "",
  });

  const [pageTitle, setPageTitle] = useState("Vehicle Form");
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction(props);
  }, []);

  // <!-- BEGIN: Vehicle Master Modal -- >
  const openVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadVehicleMasterData(data) {
    closeVehicleMasterModal();
    let event = {};
    loadDefaultMetrics(event, "vehicle", true, data, "vehicle");
  }
  // <!-- END: Vehicle Master Modal -- >

  async function handleUserAction(props) {
    let action = props.action;
    let id = props.id;

    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    switch (action) {
      case "create":
        setPageTitle("Add Tyre");
        setMode((prevState) => ({ ...prevState, create: true }));
        setDefaults(props);
        // await loadDefaultMetrics();

        break;
      case "view":
        setPageTitle("View Tyre");
        setMode((prevState) => ({ ...prevState, view: true }));
        // await loadDefaultMetrics();
        loadDataById(id);
        break;
      case "edit":
        setPageTitle("Edit Tyre");
        setMode((prevState) => ({ ...prevState, edit: true }));
        // await loadDefaultMetrics();
        loadDataById(id);
        break;

      default:
        setMode((prevState) => ({ ...prevState, view: true }));
        break;
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };
    prevState.status = "active";
    prevState["mode"] = "vehicle";

    // Checking Requesting Module
    if (request_module === "fitment_form") {
      prevState["vehicle"] = vechicleData;
      setDisabledFields((prevState) => ({ ...prevState, vehicle: true }));
    }

    setState(prevState);
    setIsProcessing(false);
    return null;
  }
  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`tyre/${id}`).then(
      async (result) => {
        const data = result.data;
        console.log("data:", data);
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let matchedData = await matchData(state, data);
    if (
      matchedData["mode"] === "vehicle" &&
      isDefined(matchedData["vehicle"])
    ) {
      matchedData["vehicle_odometer_reading"] =
        matchedData?.vehicle?.odometer_reading || 0;
    }

    matchedData.fitment_date = htmlDate(matchedData?.fitment_date);
    matchedData.manufactured_date = htmlDate(matchedData?.manufactured_date);
    matchedData.purchase_date = htmlDate(matchedData?.purchase_date);
    matchedData.warranty_start_date = htmlDate(
      matchedData?.warranty_start_date
    );
    matchedData.warranty_end_date = htmlDate(matchedData?.warranty_end_date);
    await setState(matchedData);
  }

  // Input Change
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
      if (name === "vehicle") {
        setState((prevState) => ({
          ...prevState,
          vehicle_odometer_reading: selected[0]["odometer_reading"] || 0,
        }));
        setState((prevState) => ({
          ...prevState,
          odometer_reading: selected[0]["odometer_reading"] || 0,
        }));
      }
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
      setState((prevState) => ({
        ...prevState,
        vehicle_odometer_reading: 0,
      }));
    }
    formValidation(name, selected);
  }

  function changeInput(event) {
    const { name, value, required } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    // formValidation(name, value);
  }

  function changeCheckBox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  async function changeInputImage(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImage(files[0]);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  async function changeInputFile(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImageNFile(files[0], 5);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  function deleteInputImage(event, name) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [name]: "" }));
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = validationError;
    switch (name) {
      // case "odometer_reading":
      //   errors[name] = validateNewOdoReading(
      //     value,
      //     state?.vehicle_odometer_reading
      //   );
      // break;
      default:
        break;
    }
    // setValidationError(errors);
  }

  function validateNewOdoReading(new_value, old_value) {
    const error_msg = "Value cant be less than current reading.";
    if (toFloat(new_value) < toFloat(old_value)) {
      return error_msg;
    } else {
      return "";
    }
  }

  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  async function prepareData(data) {
    let newData = data;
    // checking vehicle
    if (newData["mode"] === "vehicle" && isDefined(newData.vehicle)) {
      newData["vehicle"] = data?.vehicle?._id;
    } else {
      newData["vehicle"] = null;
    }

    // Checking Brand & Sub-brand Typeahead
    if (
      typeof newData["brand"] === "object" &&
      isDefined(newData["brand"]["customOption"])
    ) {
      newData["brand"] = newData["brand"]["label"];
    }
    if (
      typeof newData["sub_brand"] === "object" &&
      isDefined(newData["sub_brand"]["customOption"])
    ) {
      newData["sub_brand"] = newData["sub_brand"]["label"];
    }

    return newData;
  }
  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));

      let data = await prepareData({ ...state });

      console.log("data:", data);

      if (saveToDB) {
        setIsProcessing(true);
        // var query = await JsonToFormData(data);
        var query = data;
        api
          .postService("tyre", query)
          .then((response) => {
            setIsProcessing(false);
            resp.Success(response?.data?.message);
            return props.callBack(data, event);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        return props.callBack(data, event);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = await prepareData({ ...state });
      // var query = await JsonToFormData(data);

      data["_id"] = record_id;
      let apiData = { tyre_information: [data] };
      api
        .putService(`tyre`, apiData)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function switchBoolean(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  async function loadDefaultMetrics(
    event,
    key,
    reload = false,
    data = {},
    state_key
  ) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      let mappedData = await mapDefaultStateData(key, prevDefState);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: mappedData,
      }));
      setState((prevState) => ({
        ...prevState,
        [state_key]: data,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "vehicle":
            url = "vehicle/all/own";
            await fetchDefaultMetrics(url, key);
            break;

          case "brand":
            url = "tyre/brands";
            await fetchDefaultMetrics(url, key);
            break;

          case "sub_brand":
            let brand = state?.brand || "";
            url = `tyre/sub_brands/brand/${brand}`;
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;

        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        setError(error);
      });
  }

  function mapDefaultStateData(key, data) {
    let result = [];
    if (key === "brand") {
      for (var i in data) {
        result.push(data[i]["name"]);
      }
    } else if (key === "sub_brand") {
      for (var i in data) {
        result.push(data[i]["sub_brand"]);
      }
    } else if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
      result = data;
    } else {
      result = data;
    }

    return result;
  }

  function changeInputTypeaheadFields(text, field) {
    setTypeAheadFields((prevState) => ({ ...prevState, [field]: text }));
  }

  return (
    <div
      key="tyre_master_modal"
      id="tyre_master_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {!mode.view && vehicleMasterModal.show && (
        <VehicleMaster
          show={vehicleMasterModal.show}
          action={vehicleMasterModal.action}
          title={vehicleMasterModal.title}
          onHide={closeVehicleMasterModal}
          callBack={reloadVehicleMasterData}
          saveToDB={false}
          registration_number={typeAheadFields?.vehicle}
        />
      )}

      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="xl"
        id="tyre_master-modal"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
        </Modal.Header>

        {/* <!-- BEGIN: Modal Body --> */}
        <Modal.Body>
          {isProcessing && <ProcessLoader />}

          {/* <!-- BEGIN: Form --> */}
          <Form
            id="tyre_master-form"
            name="tyre_master-form"
            onSubmit={
              mode.create
                ? onSubmit
                : mode.edit
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            {/* <!-- BEGIN : Top Details  -- > */}
            <>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="required">Serial Number </Form.Label>
                  <Form.Input
                    type="text"
                    className="form-control text-uppercase"
                    id="serial_no"
                    name="serial_no"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.serial_no}
                    required
                  />
                  <ValidationError msg={validationError.serial_no} />
                </div>
                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="required">Tyre Mode</Form.Label>

                  <select
                    className="form-select"
                    onChange={changeInput}
                    name="mode"
                    value={state.mode}
                    disabled={mode?.view || mode?.edit}
                    required
                  >
                    <option value="" defaultValue></option>
                    <option value="vehicle">Vehicle</option>
                    <option value="inventory">Inventory</option>
                    <option value="scrap">Scrap</option>
                  </select>

                  <ValidationError msg={validationError.mode} />
                </div>
                {/* <!-- Vehicle Details --> */}
                {state?.mode === "vehicle" && (
                  <>
                    <div className="col-span-12 md:col-span-4 sm:col-span-6">
                      <Form.Label className="required">Vehicle</Form.Label>
                      <Typeahead
                        clearButton
                        id="vehicle"
                        name="vehicle"
                        filterBy={["registration_number", "vehicleType"]}
                        onFocus={(event) => {
                          loadDefaultMetrics(event, "vehicle");
                        }}
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "vehicle");
                        }}
                        onInputChange={(text) => {
                          changeInputTypeaheadFields(text, "vehicle");
                        }}
                        options={defaultState?.vehicle}
                        labelKey={(option) =>
                          `${option?.registration_number || ""}`
                        }
                        selected={formatTypeSearch(state?.vehicle || "")}
                        inputProps={{ required: true }}
                        placeholder="Select vehicle"
                        disabled={
                          mode.view || disabledFields?.vehicle || mode?.edit
                        }
                        renderMenu={(results, menuProps) => (
                          <Menu {...menuProps}>
                            {localUser?.module_access?.master &&
                              localUser?.access?.approve_master && (
                                <TypeAhAddNewButton
                                  onClick={openVehicleMasterModal}
                                ></TypeAhAddNewButton>
                              )}

                            {results.map((result, index) => (
                              <MenuItem
                                option={result}
                                position={index}
                                key={index}
                              >
                                <TruckNumberDisplay
                                  data={result}
                                  id={"vehicle_" + result?._id}
                                  hideOwnership={true}
                                />
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      />
                      <ValidationError msg={validationError.vehicle} />
                    </div>

                    <div className="col-span-12 md:col-span-4 sm:col-span-6">
                      <Form.Label className="">Fitted Date</Form.Label>
                      <Form.Input
                        type="date"
                        className="form-control"
                        id="fitment_date"
                        name="fitment_date"
                        placeholder=""
                        disabled={mode?.view || mode?.edit}
                        onChange={changeInput}
                        value={state?.fitment_date}
                      />
                    </div>

                    <div className="col-span-12 md:col-span-4 sm:col-span-6">
                      <Form.Label className="">
                        Fitted Vehicle Odometer
                      </Form.Label>
                      <KmInput
                        className="form-select"
                        onChange={changeInput}
                        name="odometer_reading"
                        value={state.odometer_reading}
                        disabled={mode?.view || mode?.edit}
                      />
                      <CurrentOdometerLabel
                        value={state?.vehicle_odometer_reading}
                      />
                      <ValidationError msg={validationError.odometer_reading} />
                    </div>
                    <div className="col-span-12 md:col-span-4 sm:col-span-6">
                      <Form.Label className="required">
                        Tyre Position
                      </Form.Label>

                      <TyrePositionDropdown
                        className="form-select"
                        onChange={changeInput}
                        name="position"
                        value={state.position}
                        disabled={mode?.view || mode?.edit}
                        required={true}
                      />

                      <ValidationError msg={validationError.position} />
                    </div>
                  </>
                )}
                {/* <!-- Vehicle Details --> */}

                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="required">Type</Form.Label>
                  <select
                    className="form-select"
                    onChange={changeInput}
                    name="type"
                    value={state.type}
                    disabled={mode.view}
                    required
                  >
                    <option value="" defaultValue></option>
                    <option value="New">New</option>
                    <option value="Retread 1">Retread 1</option>
                    <option value="Retread 2">Retread 2</option>
                    <option value="Regroove">Regroove</option>
                  </select>
                  <ValidationError msg={validationError.type} />
                </div>

                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="">Kms Covered</Form.Label>
                  <KmInput
                    className="form-control"
                    id="kms_covered"
                    name="kms_covered"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.kms_covered}
                  />
                </div>

                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="">Max running limit</Form.Label>
                  <KmInput
                    className="form-control"
                    id="max_running_limit"
                    name="max_running_limit"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.max_running_limit}
                  />
                </div>

                {/* <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="required">Status</Form.Label>
                  <select
                    className="form-select"
                    onChange={changeInput}
                    name="status"
                    value={state.status}
                    disabled={mode.view}
                    required
                  >
                    <option value="" defaultValue></option>
                    <option value="active">Active</option>
                    <option value="scrap">Scrap</option>
                  </select>

                  <ValidationError msg={validationError.brand} />
                </div> */}
              </div>
            </>
            {/* <!-- END :Top Details -- > */}

            {/* <!-- BEGIN : Basic Specs Details  -- > */}
            <>
              <DividerText>Tyre Specs</DividerText>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label>Brand</Form.Label>
                  <Typeahead
                    clearButton
                    allowNew
                    newSelectionPrefix="Add new : "
                    id="brand"
                    name="brand"
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "brand");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "brand");
                    }}
                    options={defaultState?.brand}
                    selected={formatTypeaheadCommon(state?.brand || "")}
                    placeholder=""
                    disabled={mode.view}
                  />
                </div>

                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label>Sub Brand</Form.Label>
                  <Typeahead
                    clearButton
                    allowNew
                    newSelectionPrefix="Add new : "
                    id="sub_brand"
                    name="sub_brand"
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "sub_brand");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "sub_brand");
                    }}
                    options={defaultState?.sub_brand}
                    selected={formatTypeaheadCommon(state?.sub_brand || "")}
                    placeholder=""
                    disabled={mode.view}
                  />
                </div>

                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="">Classification</Form.Label>
                  <select
                    className="form-select"
                    onChange={changeInput}
                    name="classification"
                    value={state.classification}
                    disabled={mode.view}
                  >
                    <option value="" defaultValue></option>
                    <option value="Nylon">Nylon</option>
                    <option value="Nylon And Iron">Nylon An Iron</option>
                    <option value="Rubber">Rubber</option>
                  </select>
                  <ValidationError msg={validationError.classification} />
                </div>

                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="">Manufactured Date</Form.Label>
                  <Form.Input
                    type="date"
                    className="form-control"
                    id="manufactured_date"
                    name="manufactured_date"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.manufactured_date}
                  />
                </div>
              </div>
            </>

            {/* <!-- END : Basic Specs Details  -- > */}

            {/* <!-- BEGIN: Warranty Details --> */}
            {/* {state?.warranty_details && ( */}
            <>
              <DividerText>Warranty Details</DividerText>

              {/* <!-- BEGIN : 2nd Row -- > */}
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="">Warranty Expires On</Form.Label>
                  <Form.Input
                    type="date"
                    className="form-control"
                    id="warranty_end_date"
                    name="warranty_end_date"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.warranty_end_date}
                  />
                </div>

                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label className="">Warranty Km</Form.Label>
                  <KmInput
                    className="form-control"
                    id="warranty_kms"
                    name="warranty_kms"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.warranty_kms}
                  />
                </div>

                <div className="col-span-12 md:col-span-4 sm:col-span-6">
                  <Form.Label>Warranty Documents</Form.Label>

                  <ImageNFileInput
                    mode={mode}
                    state={state?.warranty_docs}
                    name="warranty_docs"
                    error_msg={validationError.warranty_docs}
                    onDelete={deleteInputImage}
                    onChange={changeInputFile}
                    baseURL={fileBaseURL}
                  />
                </div>
              </div>
            </>
            {/* )} */}
            {/* <!-- END: Warranty Details --> */}

            <input type="submit" id="save_tyre_master" className="hidden" />
            <input type="submit" id="update_tyre_master" className="hidden" />
            <input type="submit" id="approve_tyre_master" className="hidden" />
          </Form>
          {/* <!-- END: Form --> */}

          {/* <!-- END: Card --> */}
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}

        <Modal.Footer className="justify-end">
          <div className="">
            <div className="mr-2">
              <ValidationError
                msg={!isValidForm.valid ? isValidForm.msg : ""}
                notify={true}
              />
            </div>
            <label
              type="button"
              className="btn btn-secondary mr-2 "
              onClick={props.onHide}
            >
              Cancel
            </label>

            {mode.create && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="save_tyre_master"
              >
                Save
              </label>
            )}
            {mode.edit && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="update_tyre_master"
              >
                Update
              </label>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
