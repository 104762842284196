import React, { useState, useEffect } from "react";

// Components
import Container from "../../components/container";
// Functions
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";
import { matchData } from "../../functions/common";

// Loaders
import ProcessLoader from "../../components/preloader/processLoader";

// Toaster & Notification

import { resp } from "../../functions/responseHandler";

export default function View() {
  const [localUser, setLocalUser] = useState();

  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Modal Functions
  const [state, setState] = useState({
    sim_tracking_limit: 0,
    sim_tracking_used: 0,
    customer: "",
    plan_code: "",
    plan_description: "",
    plan_name: "",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      loadData(localUserData);
    };
    getUser();
  }, []);

  // Fetching Grid Data From API
  async function loadData(localUserData) {
    setIsProcessing(true);
    api.getService(`subscriptions/${localUserData?.customer_id}`).then(
      async (result) => {
        const data = result.data;

        let matchedData = await matchData(state, data?.data);

        matchedData["sim_tracking_balance"] =
          matchedData?.sim_tracking_limit - matchedData?.sim_tracking_used;
        console.log("matchData fetch", matchedData);
        // setState(matchedData);

        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  const LineElement = (data) => {
    return (
      <div class="relative flex items-center my-3">
        <div class="mr-auto">
          <a href="" class="font-medium">
            {data?.title}
          </a>
          {data?.sub_title && (
            <div class="text-gray-600 mr-5 sm:mr-5">{data?.sub_titl}</div>
          )}
        </div>
        <div class="font-medium text-gray-700 dark:text-gray-500">
          {data?.value}
        </div>
      </div>
    );
  };

  return (
    <>
      <Container>
        {isProcessing && <ProcessLoader />}
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title>My Subscription</Container.Title>
        </Container.Header>
        {/* <!-- END: Header --> */}

        <Container.Body>
          {/* <!-- BEGIN : Tiles */}
          <div className="mt-4 flex justify-center">
            <div className="box col-span-6 sm:w-1/2">
              <div class="p-5">
                <LineElement title="Plan" value={state?.plan_name} />
                <LineElement
                  title="SIM Tracking Credits"
                  value={state?.sim_tracking_limit}
                />
                <LineElement
                  title="SIM Tracking Balance"
                  value={state?.sim_tracking_balance}
                />
              </div>
            </div>
          </div>

          {/* <!-- END : Tiles */}
        </Container.Body>
      </Container>
    </>
  );
}
