import React, { useState, useEffect } from "react";
// External Modules
import CreatePassword from "../../components/login/create_password";

// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { isDefined } from "../../functions/common";

export default function EmailVerification(props) {
  const [msg, setMsg] = useState("Processing....");
  const [isProcessing, setIsProcessing] = useState(false);

  const [state, setState] = useState({
    email: "",
    reset_password_code: "",
  });

  const [user_data, setUserData] = useState();

  // Create Password Modal
  const [showCreatePasswordModal, setCreatePasswordModal] = useState(false);
  const closeCreatePasswordModal = () => setCreatePasswordModal(false);
  const openCreatePasswordModal = () => setCreatePasswordModal(true);

  function passwordCreationCallBack() {
    closeCreatePasswordModal();
    window.location.href = "/login";
  }

  useEffect(() => {
    const email = props.match.params?.email;
    const reset_password_code = props.match.params?.reset_password_code;

    if (isDefined(email) && isDefined(reset_password_code)) {
      const data = {
        email: email,
        reset_password_code: reset_password_code,
      };

      verifyEmail(data);
      setState(data);
    } else {
      setMsg("Invalida URL. Please check the url and try again!");
    }
  }, []);

  //   <!-- BEGIN : Verify Email -->
  function verifyEmail(data) {
    setMsg("Verifiying the link.Please wait....");
    const query = {
      email: data?.email.trim().toLowerCase(),
      reset_password_code: data?.reset_password_code,
    };

    api
      .noTokenpostService("user/check_reset_password_code", query)
      .then(async (response) => {
        if (response.status === 200) {
          setMsg("Please stay on this page.");
          openCreatePasswordModal();
        } else {
          setIsProcessing(false);
          setMsg(response.response?.data?.message);
        }
      })
      .catch((error) => {
        setIsProcessing(false);
        // resp.ErrorHandler(error);
        setMsg("This link is expired!");
      });
  }
  //   <!-- END : Verify Email -->

  //   <!-- BEGIN : LOGIN FUNCTIONS -->

  return (
    <>
      {/* <!-- BEGIN: Content --> */}
      {/*Create Password Modal */}

      {showCreatePasswordModal && (
        <CreatePassword
          show={showCreatePasswordModal}
          onHide={closeCreatePasswordModal}
          email={state.email}
          reset_password_code={state?.reset_password_code}
          action="reset_password"
          callBack={passwordCreationCallBack}
        />
      )}

      <div className="w-3/4 mx-auto my-auto">
        {/* <!-- BEGIN: Logo --> */}
        <a href="" className="intro-x flex justify-center items-center my-5">
          <img alt="CoreLogis" className="w-48" src="/images/logo-main.png" />
        </a>

        {/* <!-- END: Logo --> */}
        {/* <!-- BEGIN: Card content --> */}
        <div className="intro-y box py-10 sm:py-20 mt-5">
          <div className="px-5 mt-10">
            <div className="text-center mt-2 text-lg font-bold">{msg}</div>
          </div>
        </div>
        {/* <!-- END: Card Content --> */}
      </div>
      {/* <!-- END: Content --> */}
    </>
  );
}
