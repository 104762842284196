import React from "react";
import PropTypes from "prop-types";
export default function ArrayAddRemoveButtons(props) {
  const { addFunc, removeFunc, id, array, index, showAdd, ...rest } = props;
  return (
    <>
      <div className="flex" id={id}>
        {array.length !== 1 && (
          <button
            type="button"
            className="btn   btn-danger mr-2"
            onClick={removeFunc}
          >
            <i className="fa fa-trash w-3 h-3" aria-hidden="true"></i>
          </button>
        )}
        {showAdd && array.length - 1 === index && (
          <button
            type="button"
            className="btn  btn-primary"
            onClick={addFunc}
          >
            <i className="fa fa-plus w-3 h-3" aria-hidden="true"></i>
          </button>
        )}
      </div>
    </>
  );
}

ArrayAddRemoveButtons.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  array: PropTypes.array,
  addFunc: PropTypes.func,
  removeFunc: PropTypes.func,
  showAdd: PropTypes.bool,
};

ArrayAddRemoveButtons.defaultProps = {
  id: "",
  index: 0,
  array: [],
  addFunc() {},
  removeFunc() {},
  showAdd: true,
};
