import React from "react";
import PropTypes from "prop-types";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default function Component(props) {
  const {
    country,
    type,
    id,
    name,
    onChange,
    value,
    required,
    size,
    placeholder,
    disabled,
    allowedValue,
    min,
    max,
    ...rest
  } = props;

  function onChangeValue(value) {
    let event = { target: { name: name, value: value } };
    return onChange(event);
  }
  return (
    <>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="IN"
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        name={name}
        id={id}
        onChange={onChangeValue}
        value={value}
        className="form-control"
      />

      {/* <div className="input-group">
        <div id={id} className="input-group-text">
          +91
        </div>
        <input
          type={type}
          className="form-control"
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          min={min}
          max={max}
          oninput={"validity.valid||(value='');"}
        />
      </div> */}
    </>
  );
}

Component.propTypes = {
  country: PropTypes.string,
  placeholder: PropTypes.string,

  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  disabled: PropTypes.bool,
};

Component.defaultProps = {
  placeholder: "",
  size: "",
  type: "number",
  id: "",
  name: "",
  onChange() {},
  value: "",
  required: false,
  disabled: false,
  min: "0",
  max: "",
};
