import React, { useState, useEffect } from "react";
// HTML Elements
import ProcessLoader from "../../preloader/processLoader";
import { Modal } from "react-bootstrap";
import KmInput from "../../htmlElements/kmInput";
import ValidationError from "../../error/validationError";
import TruckNumberDisplay from "../../consignment/truckNumberDisplay";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import Form from "../../form/custom";

// Functions
import { storageServices } from "../../../functions/storage";

import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";
import {
  formatTypeSearch,
  isDefined,
  toFloat,
  formatKm,
  mongoUTC,
} from "../../../functions/common";
import * as validation from "../../../functions/validation";

const Model = {
  vehicle: { _id: "", registration_number: "" },
  current_odometer_reading: "",
  new_odometer_reading: "",
  remarks: "",
};

export default function OdometerUpdateForm(props) {
  const { id, vehicle, request_module } = props;
  const [disbledFields, setDisabledFields] = useState({ vehicle: false });
  const [saveToDB, setSaveToDB] = useState(true);

  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });

  // State
  const [state, setState] = useState({ ...Model });
  const [validationError, setValidationError] = useState({
    ...Model,
  });

  const [localUser, setLocalUser] = useState();

  const [defaultState, setDefaultState] = useState({
    vehicle: [],
  });

  const [typeAheadFields, setTypeAheadFields] = useState({
    vehicle: "",
  });

  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction(props);
  }, []);

  async function handleUserAction(props) {
    let action = props.action;
    let id = props.id;

    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    switch (action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setDefaults(props);
        // await loadDefaultMetrics();

        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
        break;
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };
    if (isDefined(vehicle)) {
      prevState["vehicle"] = vehicle;
      prevState["current_odometer_reading"] = vehicle["odometer_reading"];
      prevState["new_odometer_reading"] = vehicle["odometer_reading"];
      setDisabledFields((prevState) => ({ ...prevState, vehicle: true }));
    }

    setState(prevState);
    setIsProcessing(false);
    return null;
  }

  // Input Change
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    formValidation(name, selected);
  }

  function changeInput(event) {
    const { name, value, required } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  // FORM VALIDATION

  function validateNewOdoReading(new_value, old_value) {
    const error_msg = "Should be greater than current reading.";
    if (toFloat(new_value) < toFloat(old_value)) {
      return error_msg;
    } else {
      return "";
    }
  }

  function formValidation(name, value) {
    let errors = validationError;
    switch (name) {
      case "new_odometer_reading":
        errors[name] = validateNewOdoReading(
          value,
          state?.current_odometer_reading
        );
        break;
      default:
        break;
    }
    // setValidationError(errors);
  }

  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  async function prepareData(data) {
    let newData = {
      date: mongoUTC(new Date()),
      odometer_reading: data?.new_odometer_reading,
      remarks: data?.remarks,
      action: "odometer_reading_update",
    };

    return newData;
  }
  // Form Actions

  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = await prepareData({ ...state });
      api
        .putService(`vehicle/${state?.vehicle?._id}`, data)
        .then((response) => {
          setIsProcessing(false);
          resp.Success("Odometer Reading Updated.");
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function loadDefaultMetrics(
    event,
    key,
    reload = false,
    data = {},
    state_key
  ) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      let mappedData = await mapDefaultStateData(key, prevDefState);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: mappedData,
      }));
      setState((prevState) => ({
        ...prevState,
        [state_key]: data,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "vehicle":
            url = "vehicle/all/own";
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        setError(error);
      });
  }

  function mapDefaultStateData(key, data) {
    let result = [];

    if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
      result = data;
    } else {
      result = data;
    }

    return result;
  }

  return (
    <div
      key="odometer-update"
      id="odometer-update"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="odometer-update-modal"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">Update Odometer</h2>
        </Modal.Header>

        {/* <!-- BEGIN: Modal Body --> */}
        <Modal.Body>
          {isProcessing && <ProcessLoader />}

          {/* <!-- BEGIN: Form --> */}
          <Form
            id="odometer-update-form"
            name="odometer-update-form"
            onSubmit={onSubmit}
          >
            {/* <!-- BEGIN : Top Details  -- > */}
            <>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 my-3 text-center flex items-center justify-center">
                  <div id="tyre_display" className="text-gray-700">
                    Current Odometer Reading
                  </div>
                  <div
                    id="tyre_display"
                    className="ml-2 text-theme-1 font-medium"
                  >
                    {formatKm(state?.current_odometer_reading)}
                  </div>
                </div>

                {/* <!-- Vehicle Details --> */}
                <>
                  <div className="col-span-12 md:col-span-6">
                    <Form.Label className="required">Vehicle</Form.Label>
                    <Typeahead
                      clearButton
                      id="vehicle"
                      name="vehicle"
                      filterBy={["registration_number", "vehicleType"]}
                      onFocus={(event) => {
                        loadDefaultMetrics(event, "vehicle");
                      }}
                      onChange={(selected) => {
                        changeTypeHeadInput(selected, "vehicle");
                      }}
                      options={defaultState?.vehicle}
                      labelKey={(option) =>
                        `${option?.registration_number || ""}`
                      }
                      selected={formatTypeSearch(state?.vehicle || "")}
                      inputProps={{ required: true }}
                      placeholder="Search vehicle..."
                      disabled={disbledFields?.vehicle}
                      renderMenu={(results, menuProps) => (
                        <Menu {...menuProps}>
                          {results.map((result, index) => (
                            <MenuItem
                              option={result}
                              position={index}
                              key={index}
                            >
                              <TruckNumberDisplay
                                data={result}
                                id={"vehicle_" + result?._id}
                              />
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    />
                    <ValidationError msg={validationError.vehicle} />
                  </div>
                </>
                {/* <!-- Vehicle Details --> */}

                <div className="col-span-12 md:col-span-6">
                  <Form.Label>New Odometer Reading</Form.Label>
                  <KmInput
                    className="form-control"
                    id="new_odometer_reading"
                    name="new_odometer_reading"
                    placeholder=""
                    disabled={mode?.view}
                    value={state?.new_odometer_reading}
                    onChange={changeInput}
                  />

                  <ValidationError msg={validationError.new_odometer_reading} />
                </div>

                <div className="col-span-12">
                  <Form.Label>Notes</Form.Label>
                  <textarea
                    type="text"
                    className="form-control text-uppercase"
                    id="remarks"
                    name="remarks"
                    placeholder=""
                    disabled={mode?.view}
                    value={state?.remarks}
                    onChange={changeInput}
                  />
                </div>
              </div>
            </>
            {/* <!-- END :Top Details -- > */}

            <input
              type="submit"
              id="save_odometer_reading_form"
              className="hidden"
            />
          </Form>
          {/* <!-- END: Form --> */}

          {/* <!-- END: Card --> */}
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}

        <Modal.Footer className="justify-end">
          <div className="">
            <div className="mr-2">
              <ValidationError
                msg={!isValidForm.valid ? isValidForm.msg : ""}
                notify={true}
              />
            </div>
            <label
              type="button"
              className="btn btn-secondary mr-2 "
              onClick={props.onHide}
            >
              Cancel
            </label>

            {mode.create && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="save_odometer_reading_form"
              >
                Save
              </label>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
