import React, { useState, useEffect } from "react";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
import InLineLoader from "../../components/preloader/inLineLoader";
// HTML Elements
import { Modal } from "react-bootstrap";
import Form from "../../components/form/custom";
import CurrencyInput from "../../components/htmlElements/currencyInput";
import WeightInput from "../../components/htmlElements/weightInput";
import PercentageInput from "../../components/htmlElements/percentageInput";
import BillingTypeButton from "../../components/htmlElements/billingTypeButton";
import TruckNumberDisplay from "../../components/consignment/truckNumberDisplay";
import ClientsInList from "../../components/consignment/clientsInList";
import SuppliersInList from "../../components/consignment/suppliersInList";
import DriversInList from "../../components/consignment/driversInList";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeAhAddNewButton from "../../components/htmlElements/typeAhAddNewButton";

import ValidationError from "../../components/error/validationError";

// Add-on Components
import CityMaster from "../../components/master/location";
import ClientMaster from "../../components/master/client";
import VehicleMaster from "../../components/master/vehicle";
import DriverMaster from "../../components/master/driver";
import SupplierMaster from "../../components/master/supplier";
import PaymentForm from "../../components/consignment/paymentForm";
import AdvanceForm from "../../components/consignment/advanceForm";
// Functions
import * as api from "../../api_service/api";
import { alertBox } from "../../components/notification/alert";
import { resp } from "../../functions/responseHandler";
import {
  localTime,
  getDateDiff,
  matchData,
  htmlDate,
  htmlInputDateTime,
  toFloat,
  mongoUTC,
  formatTypeSearch,
  formatCurrency,
  navToPage,
  randomNumber,
  isDefined,
  clearNullValues,
  getConsignmentStage,
} from "../../functions/common";

import { storageServices } from "../../functions/storage";
import * as validation from "../../functions/validation";
import FeatherIcons from "../../assets/js/feather";

// Models
import { Model, ValidationErrorModel } from "./model";

const fieldLabels = {
  unit_measure: {
    fixed: "",
    per_ton: "MT",
    per_kg: "Kg",
    per_km: "Km",
    per_trip: "Trip",
    per_day: "Day",
    per_hour: "Hour",
    per_litre: "Litres",
    per_bag: "Bags",
  },
  billing_unit: {
    fixed: "Fixed",
    per_ton: "Per Ton",
    per_kg: "Per Kg",
    per_km: "Per Km",
    per_trip: "Per Trip",
    per_day: "Per Day",
    per_hour: "Per Hour",
    per_litre: "Per Litre",
    per_bag: "Per Bag",
  },
  unit_price: {
    fixed: "",
    per_ton: "Rate/Ton",
    per_kg: "Rate/Kg",
    per_km: "Rate/Km",
    per_trip: "Rate/Trip",
    per_day: "Rate/Day",
    per_hour: "Rate/Hour",
    per_litre: "Rate/Litre",
    per_bag: "Rate/Bag",
  },

  total_units: {
    fixed: "",
    per_ton: "Total Weight",
    per_kg: "Total Weight",
    per_km: "Total Km",
    per_trip: "Total Trips",
    per_day: "Total Days",
    per_hour: "Total Hours",
    per_litre: "Total Litres",
    per_bag: "Total Bags",
  },
};

export default function ConsignmentForm(props) {
  const [pageTitle, setPageTitle] = useState("Consignment");
  const [consStage, setConsStage] = useState(1);
  // Loaders
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [typeAheadFields, setTypeAheadFields] = useState({
    client: "",
    origin: "",
    destination: "",
    location: "",
    vehicle: "",
    driver: "",
    supplier: "",
  });

  const [loader, setLoader] = useState({
    create: false,
    save_and_create_new: false,
    update: false,
    submit: false,
    acknowledge: false,
    update_and_create: false,
  });
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
    submit: false,
    acknowledge: false,
    acknowledge_edit: false,
  });
  // State
  const [activePage, setActivePage] = useState(0);
  const [localUser, setLocalUser] = useState();
  const [state, setState] = useState(Model());
  const [reportData, setReportData] = useState(Model());
  const [recordId, SetRecordId] = useState();
  // const [state, setState] = useState(Object.assign({}, Model));

  //////////// External Modal States   ////////////
  const [cityMasterModal, setCityMasterModal] = useState({
    show: false,
    title: "Add Place",
    action: "create",
    id: "",
    state_key: "",
  });

  const [clientMasterModal, setClientMasterModal] = useState({
    show: false,
    title: "Add Client",
    action: "create",
    id: "",
  });

  const [vehicleMasterModal, setVehicleMasterModal] = useState({
    show: false,
    title: "Add Vehicle",
    action: "create",
    id: "",
  });

  const [driverMasterModal, setDriverMasterModal] = useState({
    show: false,
    title: "Add Driver",
    action: "create",
    id: "",
  });

  const [supplierMasterModal, setSupplierMasterModal] = useState({
    show: false,
    title: "Add Vehicle Supplier",
    action: "create",
    id: "",
  });

  const [expenseFormModal, setExpenseFormModal] = useState({
    show: false,
    action: "",
    data: {},
    id: "",
  });

  const [paymentFormModal, setPaymentFormModal] = useState({
    show: false,
    action: "create",
    data: "",
    index: "",
    supplier_max_limit: 0,
    client_max_limit: 0,
    disableSupplierPayment: true,
    disableClientPayment: true,
  });

  const [advanceFormModal, setAdvanceFormModal] = useState({
    show: false,
    action: "create",
    id: "",
    disableSupplierPayment: true,
    disableClientPayment: true,
    supplier_amount: 0,
    client_amount: 0,
  });

  //////////// External Modal States   ////////////

  // Validation
  const [validationError, setValidationError] = useState(
    ValidationErrorModel()
  );
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  const [defaultState, setDefaultState] = useState({
    city: [],
    location: [],
    user: [],
    client: [],
    driver: [],
    vehicle: [],
    supplier: [],
  });

  // Modal Functions
  const [show, setShow] = useState(false);
  const closeModal = () => setShow(false);
  const openModal = () => setShow(true);

  ////////// FUNCTIONS  //////////
  useEffect(() => {
    FeatherIcons();
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    setIsLoaded(true);
    handleUserAction(props);

    const handleTabClose = (event) => {
      event.preventDefault();
      console.log("beforeunload event triggered");
      return (event.returnValue = "Are you sure you want to exit?");
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  // <!-- BEGIN: City Master Modal -- >
  function openCityMasterModal(event, state_key) {
    let prevState = { ...cityMasterModal };
    prevState.show = true;
    prevState.state_key = state_key;
    setCityMasterModal(prevState);
  }

  const closeCityMasterModal = () =>
    setCityMasterModal((prevState) => ({ ...prevState, show: false }));

  function reloadCityMasterData(data) {
    closeCityMasterModal();
    let event = {};
    loadDefaultMetrics(
      event,
      "location",
      true,
      data,
      cityMasterModal?.state_key
    );
  }
  // <!-- END: City Master Modal -- >

  // <!-- BEGIN: Client Master Modal -- >

  const openClientMasterModal = () =>
    setClientMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeClientMasterModal = () =>
    setClientMasterModal((prevState) => ({ ...prevState, show: false }));

  function reloadClientMasterData(data) {
    closeClientMasterModal();
    let event = {};
    loadDefaultMetrics(event, "client", true, data, "client");
  }

  // <!-- END: City Master Modal -- >

  // <!-- BEGIN: Vehicle Master Modal -- >
  const openVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadVehicleMasterData(data) {
    closeVehicleMasterModal();
    let event = {};
    loadDefaultMetrics(event, "vehicle", true, data, "vehicle");
  }
  // <!-- END: Vehicle Master Modal -- >

  // <!-- BEGIN: Driver Master Modal -- >
  const openDriverMasterModal = () =>
    setDriverMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeDriverMasterModal = () =>
    setDriverMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadDriverMasterData(data) {
    closeDriverMasterModal();
    let event = {};
    loadDefaultMetrics(event, "driver", true, data, "driver");
  }
  // <!-- END: Driver Master Modal -- >

  // <!-- BEGIN: Supplier Master Modal -- >
  const openSupplierMasterModal = () =>
    setSupplierMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeSupplierMasterModal = () =>
    setSupplierMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadSupplierMasterData(data) {
    closeSupplierMasterModal();
    let event = {};
    loadDefaultMetrics(event, "supplier", true, data, "supplier");
  }
  // <!-- END: Supplier Master Modal -- >

  // <!-- BEGIN: Payment Form Modal -- >

  const closePaymentFormModal = () =>
    setPaymentFormModal((prevState) => ({ ...prevState, show: false }));

  function paymentFormCallBack(data, type, action, index) {
    closePaymentFormModal();
    paymentFormCallBackFunction(data, type, action, index);
  }

  function addPayment(event) {
    event.preventDefault();
    let prevState = { ...paymentFormModal };
    prevState.action = "create";
    prevState.data = {};
    prevState.index = 0;
    prevState.supplier_max_limit = toFloat(state?.supplier_balance || 0);
    prevState.client_max_limit = toFloat(state?.client_balance || 0);

    // Checking Supplier Balance
    if (state?.supplier_balance === 0) {
      prevState.disableSupplierPayment = true;
    } else {
      prevState.disableSupplierPayment = false;
    }

    // Checking Client  Balance
    if (state?.client_balance === 0) {
      prevState.disableClientPayment = true;
    } else {
      prevState.disableClientPayment = false;
    }

    prevState.show = true;

    setPaymentFormModal(prevState);
  }

  function editPaymentItem(event, data, index) {
    event.preventDefault();
    let prevState = { ...paymentFormModal };
    prevState.action = "edit";
    prevState.data = data;
    prevState.index = index;
    prevState.supplier_max_limit = toFloat(state?.supplier_balance || 0);
    prevState.client_max_limit = toFloat(state?.client_balance || 0);

    prevState.disableSupplierPayment = true;
    prevState.disableClientPayment = true;
    prevState.show = true;
    setPaymentFormModal(prevState);
  }

  function deletePaymentItem(event, item, index) {
    event.preventDefault();
    alertBox.confirmation({
      type: "info",
      title: "Delete Payment",
      text: "Are you sure want to delete this Payment?",
      onConfirm: function () {
        confirmDeletePaymentItem(item, index);
      },
    });
  }

  async function confirmDeletePaymentItem(item, index) {
    setIsProcessing(true);
    // Updating Flag
    item["isDeleted"] = true;

    let prevState = { ...state };
    let payablesArray = prevState?.transactions?.payables;
    let receivablesArray = prevState?.transactions?.receivables;

    // Payables
    if (item?.transaction_type === "payable") {
      payablesArray[index] = item;
      prevState.transactions.payables = payablesArray;
      prevState["supplier_balance"] = await calcSupplierBalance(prevState);
      setState(prevState);
    }

    // Receivable
    if (item?.transaction_type === "receivable") {
      receivablesArray[index] = item;
      prevState.transactions.receivables = receivablesArray;
      prevState["client_balance"] = await calcClientBalance(prevState);
      setState(prevState);
    }
    setIsProcessing(false);
    return null;
  }

  // <!-- END: Payment Form Modal -- >

  // <!-- BEGIN: Advance Form Modal -- >
  const openAdvanceFormModal = () =>
    setAdvanceFormModal((prevState) => ({ ...prevState, show: true }));

  const closeAdvanceFormModal = () =>
    setAdvanceFormModal((prevState) => ({ ...prevState, show: false }));

  function advanceFormCallBack(data, type, action, index) {
    closeAdvanceFormModal();
    paymentFormCallBackFunction(data, type, action, index);
  }

  function addAdvance(event) {
    event.preventDefault();
    let prevState = { ...advanceFormModal };
    prevState.action = "create";
    prevState.data = {};
    prevState.index = 0;
    prevState.supplier_max_limit = toFloat(state?.supplier_balance || 0);
    prevState.client_max_limit = toFloat(state?.client_balance || 0);

    // Checking Supplier Balance
    if (state?.supplier_balance === 0) {
      prevState.disableSupplierPayment = true;
    } else {
      prevState.disableSupplierPayment = false;
    }

    // Checking Client  Balance
    if (state?.client_balance === 0) {
      prevState.disableClientPayment = true;
    } else {
      prevState.disableClientPayment = false;
    }

    prevState.show = true;

    setAdvanceFormModal(prevState);
  }

  function editAdvanceItem(event, data, index) {
    event.preventDefault();
    let prevState = { ...advanceFormModal };
    prevState.action = "edit";
    prevState.data = data;
    prevState.index = index;
    prevState.supplier_max_limit = toFloat(state?.supplier_balance || 0);
    prevState.client_max_limit = toFloat(state?.client_balance || 0);

    prevState.disableSupplierPayment = true;
    prevState.disableClientPayment = true;
    prevState.show = true;
    setAdvanceFormModal(prevState);
  }

  function deleteAdvanceItem(event, item, index) {
    event.preventDefault();
    alertBox.confirmation({
      type: "info",
      title: "Delete Advance",
      text: "Are you sure want to delete this Advance?",
      onConfirm: function () {
        confirmDeletePaymentItem(item, index);
      },
    });
  }

  // <!-- END: Advance Form Modal -- >

  async function paymentFormCallBackFunction(data, type, action, index) {
    setIsProcessing(true);

    let prevState = { ...state };
    let payablesArray = prevState?.transactions?.payables;
    let receivablesArray = prevState?.transactions?.receivables;

    // Payables
    if (type === "payable") {
      // New Payment
      if (action === "create") {
        payablesArray.push(data);
      }
      // Updating Payment
      else if (action === "update") {
        data["isUpdated"] = true;
        payablesArray[index] = data;
      }

      prevState.transactions.payables = payablesArray;
      prevState["supplier_balance"] = await calcSupplierBalance(prevState);
      setState(prevState);
    }

    // Receivable
    if (type === "receivable") {
      // New Payment
      if (action === "create") {
        receivablesArray.push(data);
      }
      // Updating Payment
      else if (action === "update") {
        data["isUpdated"] = true;
        receivablesArray[index] = data;
      }
      prevState.transactions.receivables = receivablesArray;
      prevState["client_balance"] = await calcClientBalance(prevState);
      setState(prevState);
    }
    setIsProcessing(false);

    // // Payables
    // if (type === "payable") {
    //   if (action === "create") {
    //     payablesArray.push(data);
    //   } else if (action === "update") {
    //     payablesArray = upsertObjInArray(payablesArray, data, "_id");
    //   }

    //   prevState.transactions.payables = payablesArray;
    //   setState(prevState);
    //   calcSupplierBalance(prevState);
    // }

    // // Receivable
    // if (type === "receivable") {
    //   if (action === "create") {
    //     receivablesArray.push(data);
    //   } else if (action === "update") {
    //     receivablesArray = upsertObjInArray(receivablesArray, data, "_id");
    //   }

    //   prevState.transactions.receivables = receivablesArray;
    //   setState(prevState);
    //   calcClientBalance(prevState);
    // }

    return null;
  }

  function calcSupplierBalance(currentState) {
    let payablesArray = currentState?.transactions?.payables || [];
    let supplier_balance = toFloat(
      currentState?.charges?.consignment_total_amount || 0
    );

    for (var i in payablesArray) {
      if (payablesArray[i]?.isDeleted) {
        supplier_balance = supplier_balance;
      } else {
        supplier_balance =
          supplier_balance - toFloat(payablesArray[i]?.transaction_amount || 0);
      }
    }

    // setState((prevState) => ({
    //   ...prevState,
    //   supplier_balance: supplier_balance,
    // }));
    return supplier_balance;
  }

  function calcClientBalance(currentState) {
    let receivablesArray = currentState?.transactions?.receivables || [];
    let total_amount = currentState?.charges?.client_total_amount || 0;
    let client_balance = total_amount;

    for (var i in receivablesArray) {
      if (receivablesArray[i]?.isDeleted) {
        client_balance = client_balance;
      } else {
        client_balance =
          client_balance -
          toFloat(receivablesArray[i]?.transaction_amount || 0);
      }
    }

    // setState((prevState) => ({
    //   ...prevState,
    //   client_balance: client_balance,
    // }));
    return client_balance;
  }

  function setDefaults() {
    let newState = Model();
    newState.consignment_date = htmlDate(new Date());
    newState.charges.client_billing_unit = "fixed";
    newState.charges.consignment_billing_unit = "fixed";
    newState.primary_lr_number = "LR-" + randomNumber(4);
    newState.created_business_type = "transporter";

    setState(newState);
  }

  // Restoring Data from crash
  async function initCreate() {
    setIsProcessing(true);
    let prevData = await storageServices.getStoredData("new_consignment_data");
    if (prevData) {
      setState(prevData);
    } else {
      setDefaults();
    }
    setMode((prevState) => ({ ...prevState, create: true }));
    setIsProcessing(false);
  }
  async function handleUserAction(props) {
    let action = props?.action;
    let id = props?.id;

    switch (action) {
      case "create":
        setPageTitle("Create Consignment");
        initCreate();
        break;
      case "clone":
        setPageTitle("Duplicate Consignment");
        setMode((prevState) => ({ ...prevState, create: true }));
        loadCloneDataById(id);
        break;
      case "edit":
        setPageTitle("Edit Consignment");
        setMode((prevState) => ({ ...prevState, edit: true }));
        await loadDataById(id);
        break;
      case "view":
        setPageTitle("View Consignment");
        setMode((prevState) => ({ ...prevState, view: true }));
        await loadDataById(id);
        break;
      case "submit":
        setPageTitle("Start Consignment");
        setMode((prevState) => ({ ...prevState, submit: true }));
        await loadDataById(id);
        break;
      case "acknowledge":
        setPageTitle("Complete Consignment");
        setMode((prevState) => ({ ...prevState, acknowledge: true }));
        await loadDataById(id);
        break;
      case "acknowledge_edit":
        setPageTitle("Edit Consignment");
        setMode((prevState) => ({ ...prevState, acknowledge_edit: true }));
        await loadDataById(id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  ///////////////// Handling Input Changes //////////////////////
  function loadCloneDataById(id) {
    SetRecordId("");
    setIsProcessing(true);
    api.getService(`consignment/${id}`).then(
      async (result) => {
        const data = result.data?.consignment;
        await mapCloneData(data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setError(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  function loadDataById(id) {
    SetRecordId(id);
    setIsProcessing(true);
    api.getService(`consignment/${id}`).then(
      async (result) => {
        const data = result.data?.consignment;

        await mapData(data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setError(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  function changeInputClientUnitFields(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState["charges"][name] = value;

    let total =
      toFloat(prevState?.charges?.client_unit_price || 0) *
      toFloat(prevState?.charges?.client_total_units || 0);
    total = total.toFixed(2);

    prevState["charges"]["client_freight"] = total;
    setState(prevState);
    calcClientTotalAmount(prevState);
  }

  function changeInputSupplierUnitFields(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState["charges"][name] = value;
    let total =
      toFloat(prevState?.charges?.consignment_unit_price || 0) *
      toFloat(prevState?.charges?.consignment_total_units || 0);
    total = total.toFixed(2);

    prevState["charges"]["consignment_freight"] = total;
    setState(prevState);
    calcConsignmentTotalAmount(prevState);
  }

  // function calcTotalAmountByUnits(unit_price, total_units, field_to_set) {
  //   let total = toFloat(unit_price || 0) * toFloat(total_units || 0);
  //   total = total.toFixed(2);

  //   setState((prevState) => ({
  //     ...prevState,
  //     charges: {
  //       ...prevState.charges,
  //       [field_to_set]: total,
  //     },
  //   }));
  // }

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === "vehicle_ownership_type") {
      if (state?.vehicle?.customOption) {
        let vehicleData = { ...state?.vehicle };
        vehicleData["ownership_type"] = value;
        setState((prevState) => ({ ...prevState, vehicle: vehicleData }));
      }
    }
  }

  function changeInputTypeaheadFields(text, field) {
    setTypeAheadFields((prevState) => ({ ...prevState, [field]: text }));
  }

  function changeInputUnitButton(event, value) {
    const { name } = event.target;
    setState((prevState) => ({
      ...prevState,
      charges: {
        ...prevState.charges,
        [name]: value,
      },
    }));
  }

  function changeChargesInput(event) {
    const { name, value } = event.target;

    setState((prevState) => ({
      ...prevState,
      charges: {
        ...prevState.charges,
        [name]: value,
      },
    }));

    if (
      name === "consignment_freight" ||
      name === "consignment_tds_percentage"
    ) {
      let currentState = state;
      currentState["charges"][name] = value;
      calcConsignmentTotalAmount(currentState);
      calcAmountPayable(currentState);
    }
    if (name === "consignment_hold_amount") {
      let currentState = state;
      currentState["charges"][name] = value;
      calcAmountPayable(currentState);
    }
    if (name === "consignment_amount_payable") {
      let currentState = state;
      currentState["charges"][name] = value;
      calcHoldAmount(currentState);
    }
    formValidation(name, value);
  }

  function calcHoldAmount(currentState) {
    let holdAmount =
      toFloat(currentState?.charges?.consignment_freight) -
      toFloat(currentState?.charges?.consignment_amount_payable);
    setState((prevState) => ({
      ...prevState,
      charges: { ...prevState.charges, consignment_hold_amount: holdAmount },
    }));
  }

  function calcAmountPayable(currentState) {
    let amountPayable =
      toFloat(currentState?.charges?.consignment_freight) -
      toFloat(currentState?.charges?.consignment_hold_amount);

    setState((prevState) => ({
      ...prevState,
      charges: {
        ...prevState.charges,
        consignment_amount_payable: amountPayable,
      },
    }));
  }

  function calcClientTotalAmount(currentState) {
    let totalAmount = toFloat(currentState?.charges?.client_freight);
    let tdsAmount = 0;

    // Adding additions items - Expenses / Other
    for (var i in currentState?.charges?.client_addition) {
      totalAmount += toFloat(currentState.charges?.client_addition[i]?.amount);
    }

    // Subtracting deductions items - Deductions / Other
    for (var i in currentState?.charges?.client_deduction) {
      totalAmount =
        totalAmount -
        toFloat(currentState?.charges?.client_deduction[i]?.amount);
    }

    setState((prevState) => ({
      ...prevState,
      charges: {
        ...prevState.charges,
        client_total_amount: totalAmount.toFixed(2),
      },
    }));
  }

  function calcConsignmentTotalAmount(currentState) {
    let totalAmount = toFloat(currentState?.charges?.consignment_freight);
    let tdsAmount = 0;

    // Adding additions items - Expenses / Other
    for (var i in currentState?.charges?.consignment_addition) {
      totalAmount += toFloat(
        currentState.charges?.consignment_addition[i]?.amount
      );
    }

    // Subtracting deductions items - Deductions / Other
    for (var i in currentState?.charges?.consignment_deduction) {
      totalAmount =
        totalAmount -
        toFloat(currentState?.charges?.consignment_deduction[i]?.amount);
    }

    if (currentState?.charges?.consignment_tds) {
      tdsAmount = toFloat(
        (currentState?.charges?.consignment_tds_percentage / 100) *
          currentState?.charges?.consignment_freight
      );
      totalAmount = totalAmount - tdsAmount;
      setState((prevState) => ({
        ...prevState,
        charges: {
          ...prevState.charges,
          consignment_tds_amount: tdsAmount.toFixed(2),
        },
      }));
    }

    setState((prevState) => ({
      ...prevState,
      charges: {
        ...prevState.charges,
        consignment_total_amount: totalAmount.toFixed(2),
      },
    }));
  }

  function calcTdsAmount(currentState) {
    let tdsAmount = 0;
    tdsAmount = toFloat(
      ((currentState?.charges?.consignment_tds_percentage | 0) / 100) *
        currentState?.charges?.consignment_total_amount
    );

    return tdsAmount;
  }

  function sumTotalPackages(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].goods) {
        sum += parseInt(load_receipts_list[i].goods[j].number_of_unit || 0);
      }
    }
    setState((prevState) => ({ ...prevState, total_no_Of_packages: sum }));
  }

  function sumTotalInvoiceWeight(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].goods) {
        sum += parseInt(load_receipts_list[i].goods[j].billable_weight || 0);
      }
    }
    setState((prevState) => ({ ...prevState, total_invoice_weight: sum }));
  }

  // Calclutation Total Packages & Invoice - Unloading Point
  function sumTotalPackagesReceived(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].unloading_goods) {
        sum += parseInt(
          load_receipts_list[i].unloading_goods[j].number_of_unit || 0
        );
      }
    }
    setState((prevState) => ({
      ...prevState,
      total_no_Of_packages_received: sum,
    }));
  }

  function sumTotalInvoiceWeightReceived(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].unloading_goods) {
        sum += parseInt(
          load_receipts_list[i].unloading_goods[j].billable_weight || 0
        );
      }
    }
    setState((prevState) => ({
      ...prevState,
      total_invoice_weight_received: sum,
    }));
  }

  function calcInTime(load_receipts_list) {
    let newInTime = new Date(
      Math.min.apply(
        null,
        load_receipts_list.map(function (e) {
          return new Date(e.in_time);
        })
      )
    );
    let obj = { ...state.loading_point };
    obj["in_time"] = localTime(newInTime);
    setState((prevState) => ({ ...prevState, loading_point: obj }));
    calcNoOfDays(obj);
  }

  function calcOutTime(load_receipts_list) {
    let newInTime = new Date(
      Math.max.apply(
        null,
        load_receipts_list.map(function (e) {
          return new Date(e.out_time);
        })
      )
    );
    let obj = { ...state.loading_point };
    obj["out_time"] = localTime(newInTime);
    setState((prevState) => ({ ...prevState, loading_point: obj }));
    calcNoOfDays(obj);
  }
  function calcNoOfDays(loading_point) {
    if (loading_point.in_time && loading_point.out_time) {
      let days = getDateDiff(loading_point.in_time, loading_point.out_time);
      loading_point["no_of_days"] = days;
      setState((prevState) => ({ ...prevState, loading_point: loading_point }));
    }
  }

  function calcNoOfDaysUnloading(unloading_point) {
    if (unloading_point.in_time && unloading_point.out_time) {
      let days = getDateDiff(unloading_point.in_time, unloading_point.out_time);
      unloading_point["no_of_days"] = days;
      setState((prevState) => ({
        ...prevState,
        unloading_point: unloading_point,
      }));
    }
  }

  // TYPE AHEAD CHANGES
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    formValidation(name, selected);
  }

  async function changeTypeHeadInputDriver(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
      // setState((prevState) => ({
      //   ...prevState,
      //   driver_name: selected[0]?.name,
      // }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
      // setState((prevState) => ({ ...prevState, driver_name: "" }));
    }
    formValidation(name, selected);
  }

  function changeTypeHeadInputVehicle(selected, name) {
    let prevState = { ...state };
    if (selected.length != 0) {
      let selected_vehicle = selected[0];
      if (
        typeof selected_vehicle !== "undefined" &&
        typeof selected_vehicle.tds !== "undefined" &&
        selected_vehicle.tds &&
        selected_vehicle.tds_percentage > 0
      ) {
        prevState.charges["consignment_tds"] = selected_vehicle?.tds;
        prevState.charges["consignment_tds_percentage"] =
          selected_vehicle?.tds_percentage;
        prevState.charges["consignment_tds_amount"] = calcTdsAmount(prevState);
      } else {
        prevState.charges["consignment_tds"] = false;
        prevState.charges["consignment_tds_percentage"] = 0;
        prevState.charges["consignment_tds_amount"] = 0;
      }
      prevState[name] = selected_vehicle;

      // IF Custome Option
      if (selected_vehicle?.customOption) {
        prevState[name]["ownership_type"] =
          prevState?.vehicle_ownership_type || "own";
      }
      prevState["vehicle_ownership_type"] = prevState[name]?.ownership_type;
      setState(prevState);
    } else {
      prevState.charges["consignment_tds"] = false;
      prevState.charges["consignment_tds_percentage"] = 0;
      prevState.charges["consignment_tds_amount"] = 0;
      prevState[name] = "";
      prevState["vehicle_ownership_type"] = "";
      setState(prevState);
    }
    calcConsignmentTotalAmount(prevState);
    formValidation(name, selected);
  }

  ///////////////// Handling Input Changes //////////////////////

  async function mapCloneData(data) {
    let newState = { ...state };
    newState.consignment_date = htmlDate(new Date());
    newState["client"] = data["client"];
    newState["origin"] = data["origin"];
    newState["destination"] = data["destination"];
    newState["vehicle"] = data["vehicle"];
    newState["vehicle_type"] = data?.vehicle?.vehicle_type?.vehicle_type || "";
    newState["material"] = data["material"];
    newState.primary_lr_number = "LR-" + randomNumber(4);
    newState.created_business_type = "transporter";

    // Driver
    if (isDefined(data["driver"])) {
      newState["driver"] = data["driver"];
    }

    // Supplier
    if (isDefined(data["supplier"])) {
      newState["supplier"] = data["supplier"];
    }
    // Billing
    newState["charges"] = data["charges"];
    if (isDefined(newState["charges"]["_id"])) {
      newState["charges"]["_id"] = "";
    }

    await setState(newState);
  }

  async function mapData(data) {
    let prevState = { ...state };
    let newState = await matchData(prevState, data);
    newState = await clearNullValues(newState);

    newState.consignment_date = htmlDate(newState?.consignment_date);

    let loadReceipts = newState?.load_receipts || [];
    // Error Model
    var errorList = [...validationError["load_receipts"]];
    let new_error_model = ValidationErrorModel();
    let lr_error_model = new_error_model["load_receipts"][0];

    for (var i in loadReceipts) {
      // Adding ValidationErrorModel
      for (var j in loadReceipts[i].goods) {
        lr_error_model.goods.push(lr_error_model.goods);
      }
      // Adding ValidationErrorModel

      loadReceipts[i].in_time = htmlInputDateTime(loadReceipts[i]?.in_time);
      loadReceipts[i].out_time = htmlInputDateTime(loadReceipts[i]?.out_time);
      if (newState.status === "completed") {
        for (var j in loadReceipts[i]?.goods) {
          loadReceipts[i].unloading_goods[j] = { ...loadReceipts[i].goods[j] };
          // Adding ValidationErrorModel
          lr_error_model.unloading_goods.push(lr_error_model.goods);
        }
      }

      errorList.push(lr_error_model);
    }

    newState.load_receipts = loadReceipts;
    // Error Validation
    setValidationError((prevState) => ({
      ...prevState,
      load_receipts: errorList,
    }));

    newState.loading_point.in_time = localTime(
      newState?.loading_point?.in_time
    );
    newState.loading_point.out_time = localTime(
      newState.loading_point.out_time
    );

    newState.unloading_point.in_time = htmlInputDateTime(
      newState.unloading_point.in_time
    );
    newState.unloading_point.out_time = htmlInputDateTime(
      newState.unloading_point.out_time
    );

    // await setReportData(newState);

    // Obj to String

    newState["charges"].consignment_tds = data?.charges?.consignment_tds || 0;
    newState["charges"].consignment_tds_percentage =
      data?.charges?.consignment_tds_percentage || 0;
    // Calculating TDS Amount
    newState["charges"]["consignment_tds_amount"] = 0;
    if (newState["charges"]?.tds) {
      newState["charges"]["consignment_tds_amount"] = calcTdsAmount(newState);
    }

    //
    // newState.origin_details = data.origin;

    // newState.destination_details = data.destination;

    // newState.vehicle_details = data?.vehicle;
    newState["vehicle_type"] = data?.vehicle?.vehicle_type?.vehicle_type || "";
    // newState.driver_name = data.driver?.name;
    newState.total_amount_paid = data?.total_amount_paid || 0;

    // Calculating Supplier Balance & Client Balance
    newState["supplier_balance"] = calcSupplierBalance(newState);
    newState["client_balance"] = calcClientBalance(newState);

    // Consignment Stage
    let cons_stage = getConsignmentStage(newState?.status);
    await setConsStage(cons_stage);

    await setState(newState);
  }

  async function loadDefaultMetrics(
    event,
    key,
    reload = false,
    data = {},
    state_key
  ) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      let mappedData = await mapDefaultStateData(key, prevDefState);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: mappedData,
      }));
      setState((prevState) => ({
        ...prevState,
        [state_key]: data,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "client":
            url = "client/status/active";
            await fetchDefaultMetrics(url, key);
            break;
          case "location":
            url = "master/location/status/active";
            await fetchDefaultMetrics(url, key);
            break;
          case "vehicle":
            url = "vehicle";
            await fetchDefaultMetrics(url, key);
            break;
          case "driver":
            url = "driver/valid";
            await fetchDefaultMetrics(url, key);
            break;
          case "supplier":
            url = "master/supplier/type/vehicle_supplier/active";
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        setError(error);
      });
  }

  function mapDefaultStateData(key, data) {
    // Re-constructing Vehicle Data
    if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
    }
    return data;
  }
  async function prepareData() {
    var query = { ...state };
    query.client = query?.client;
    query.origin = query?.origin;
    query.destination = query?.destination;

    // Driver Optional
    if (isDefined(query.driver)) {
      query.driver = query?.driver;
    } else {
      delete query.driver;
    }

    // Setting consingment type

    if (query.load_receipts.length > 1) {
      query.consignment_type = "multiple";
    } else {
      query.consignment_type = "single";
    }

    // Vehicle - Custom Option
    if (query?.vehicle?.customOption) {
      query.vehicle["ownership_type"] =
        query["vehicle_ownership_type"] || "own";
    }

    // Checking vehicle type
    if (query?.vehicle?.ownership_type === "supplier") {
      query.supplier = query?.supplier;
    } else {
      delete query.supplier;
    }

    query.vehicle = query?.vehicle;

    // Converting Time to UTC
    let loadReceipts = query.load_receipts;
    for (var i in loadReceipts) {
      loadReceipts[i].in_time = mongoUTC(loadReceipts[i].in_time);
      loadReceipts[i].out_time = mongoUTC(loadReceipts[i].out_time);
    }

    query.load_receipts = loadReceipts;

    query.loading_point.in_time = mongoUTC(query?.loading_point?.in_time);
    query.loading_point.out_time = mongoUTC(query?.loading_point?.out_time);

    query.unloading_point.in_time = mongoUTC(query?.unloading_point?.in_time);
    query.unloading_point.out_time = mongoUTC(query?.unloading_point?.out_time);

    // Additions
    for (var i in query?.additions) {
      query.additions[i]["type"] = query.additions[i]?.type?._id;
    }

    // Deductions
    for (var i in query?.deductions) {
      query.deductions[i]["type"] = query.deductions[i]?.type?._id;
    }

    //TDS
    if (query?.tds && query?.tds_percentage > 0) {
      query.tds = true;
    } else {
      query.tds = false;
      query.tds_percentage = 0;
    }

    return query;
  }

  function createNewConsignment() {
    setDefaults();
  }

  async function onCreate(
    event,
    cons_status,
    loader_field = "create",
    createNew = false
  ) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      setLoader((prevState) => ({ ...prevState, [loader_field]: true }));
      var query = await prepareData();
      query.status = cons_status;
      query.created_by = localUser.userId;
      query.consignment_date = await mongoUTC(query.consignment_date);
      delete query.load_receipts;

      console.log("query:", query);

      api
        .postService("consignment", query)
        .then(async (response) => {
          setLoader((prevState) => ({ ...prevState, [loader_field]: false }));
          resp.SuccessResponse(response);
          // Clearing Local Storage
          await storageServices.removeData("new_consignment_data");
          if (createNew) {
            createNewConsignment();
          } else {
            const data = response?.data;
            if (data?.id && data?.id.length > 0) {
              let createdId = data?.id[0];
              props.callBack(
                createdId,
                state.created_business_type,
                props?.action
              );
            } else {
              props.callBack("", state.created_business_type, "close");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader((prevState) => ({ ...prevState, [loader_field]: false }));
          if (error?.response?.status === 409) {
            console.log("lr number:", error.response?.data);
            let errors = error.response?.data;
            validateLRNumber(errors.lr_numbers);
          }

          resp.ErrorHandler(error);
        });
    } else {
      console.log("erros:", validationError);
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdateAndCreate(event) {
    event.preventDefault();

    await finalValidation();

    if (validation.validateComplexForm(validationError)) {
      setLoader((prevState) => ({ ...prevState, update_and_create: true }));
      var query = await prepareData();
      query["action"] = "create_consignment"; // Important - Generates No.
      query["status"] = "pending";
      for (var i in query?.load_receipts) {
        delete query?.load_receipts[i]?._id;
      }
      for (var i in query?.transits) {
        delete query?.transits[i]?._id;
      }

      api
        .putService(`consignment/${recordId}`, query)
        .then((response) => {
          setLoader((prevState) => ({ ...prevState, update: false }));
          resp.SuccessResponse(response);
          props.callBack(
            response?.data?.id || "",
            state.created_business_type,
            props?.action
          );
        })
        .catch((error) => {
          console.log(error);
          setLoader((prevState) => ({ ...prevState, update: false }));
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }
  async function onUpdate(event) {
    event.preventDefault();

    if (consStage > 0) {
      await finalValidation();
    }

    if (consStage === 0 || validation.validateComplexForm(validationError)) {
      setLoader((prevState) => ({ ...prevState, update: true }));
      var query = await prepareData();
      for (var i in query?.load_receipts) {
        delete query?.load_receipts[i]?._id;
      }
      for (var i in query?.transits) {
        delete query?.transits[i]?._id;
      }

      api
        .putService(`consignment/${recordId}`, query)
        .then((response) => {
          setLoader((prevState) => ({ ...prevState, update: false }));
          resp.SuccessResponse(response);
          props.callBack(
            response?.data?.id || "",
            state.created_business_type,
            props?.action
          );
        })
        .catch((error) => {
          console.log(error);
          setLoader((prevState) => ({ ...prevState, update: false }));
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onAcknowledgeEdit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      setIsProcessing(true);
      var query = await prepareData();

      for (var i in query?.load_receipts) {
        delete query?.load_receipts[i]?._id;
      }
      for (var i in query?.transits) {
        delete query?.transits[i]?._id;
      }

      api
        .putService(`consignment/${recordId}`, query)
        .then((response) => {
          setIsProcessing(false);
          resp.SuccessResponse(response);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onSubmitEnd(event) {
    setLoader((prevState) => ({ ...prevState, submit: true }));
    var query = await prepareData();

    query.status = "in_progress";
    api
      .postService(`consignment/${recordId}`, query)
      .then((response) => {
        console.log("response:", response);
        const data = response?.data;
        setLoader((prevState) => ({ ...prevState, submit: false }));
        resp.Success(data?.message);
        navToPage("/admin#/consignment");
      })
      .catch((error) => {
        console.log(error);
        setLoader((prevState) => ({ ...prevState, submit: false }));
        resp.ErrorHandler(error);
      });
  }

  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      // Checking Business Rule
      if (localUser?.business_rules?.consignment_approval) {
        // Checking Approval Access
        if (localUser?.access?.approve_consignment) {
          if (mode?.create) {
            onCreate(event, "in_progress");
          } else {
            onSubmitEnd(event);
          }
        } else {
          resp.Information("Consignment submission needs approval.");
        }
      } else {
        onSubmitEnd();
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function validateLRNumber(lr_numbers) {
    const lr_error_msg = "LR Number already exists!";
    let newValidationError = { ...validationError };
    for (var i in lr_numbers) {
      let index = parseInt(lr_numbers[i]);
      newValidationError.load_receipts[index].lr_number = lr_error_msg;
    }
    setValidationError(newValidationError);
  }

  // FORM VALIDATION
  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  // Input Changes
  function formValidation(name, value, array = "", index = 0, object = "") {
    let errors = validationError;

    if (!isDefined(value)) value = "";

    switch (name) {
      case "client":
        errors[name] = validation.validateTypeAhead(value);
        break;
      case "origin":
        errors[name] = validation.validateTypeAhead(value);
        break;
      case "destination":
        errors[name] = validation.validateTypeAhead(value);
        break;
      case "vehicle":
        errors[name] = validation.validateTypeAhead(value);
        break;
      case "consignment_freight":
        errors["charges"][name] =
          toFloat(value) <
          toFloat(
            toFloat(state?.charges?.consignment_amount_payable) +
              toFloat(state?.charges?.consignment_hold_amount)
          )
            ? "Amount should be greater than Hold & Payable."
            : "";
        break;
      case "consignment_hold_amount":
        errors["charges"][name] =
          toFloat(value) > toFloat(state?.charges?.consignment_freight)
            ? "Amount should be lesser than Freight amount."
            : "";
        break;

      case "consignment_amount_payable":
        errors["charges"][name] =
          toFloat(value) > toFloat(state?.charges?.consignment_freight)
            ? "Amount should be lesser than Freight amount."
            : "";
        break;
      case "in_time":
        errors[array][index][name] = validation.validateInTime(
          value,
          state[array][index]["out_time"]
        );
        errors[array][index]["out_time"] = validation.validateOutTime(
          state[array][index]["out_time"],
          value
        );
        break;
      case "out_time":
        errors[array][index][name] = validation.validateOutTime(
          value,
          state[array][index]["in_time"]
        );
        errors[array][index]["in_time"] = validation.validateInTime(
          state[array][index]["in_time"],
          value
        );
        break;
      default:
        break;
    }
  }

  function saveForm(event) {
    event.preventDefault();
    let submit_action = event.nativeEvent.submitter.name;

    switch (submit_action) {
      case "create_consignment_form":
        onCreate(event, "pending", "create", false);
        break;

      case "save_and_create_new_consignment":
        onCreate(event, "pending", "save_and_create_new", true);
        break;

      case "update_consignment_form":
        onUpdate(event);
        break;
      case "update_and_create_consignment_form":
        onUpdateAndCreate(event);
        break;

      case "submit_consignment_form":
        onSubmit(event);
        break;

      case "acknowledge_consignment_form":
        console.log("state:", state);
        // onAcknowledge(event);
        break;

      case "acknowledge_save_consignment_form":
        onAcknowledgeEdit(event);
        break;

      default:
        break;
    }
  }

  function enableEdit(event) {
    event.preventDefault();
    setPageTitle("Edit Consignment");
    let prevMode = { ...mode };
    prevMode.view = false;
    prevMode.edit = true;
    setMode(prevMode);
  }

  const MasterAddButton = (props) => {
    return (
      <button
        id="input-group"
        className="input-group-text btn btn-outline-primary"
        onClick={props.onClick}
        title={props.title}
      >
        <i class="fa fa-plus" aria-hidden="true"></i>
      </button>
    );
  };

  function storeDataLocal() {
    storageServices.storeData("new_consignment_data", state);
  }
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {/* <!--BEGIN:EXTERNAL COMPONENTS -->*/}
      <>
        {!mode.view && cityMasterModal.show && (
          <CityMaster
            show={cityMasterModal.show}
            action={cityMasterModal.action}
            title={cityMasterModal.title}
            onHide={closeCityMasterModal}
            // callBack={(e, data) => reloadCityMasterData(e, data)}
            callBack={reloadCityMasterData}
            saveToDB={false}
            name={typeAheadFields?.location}
          />
        )}

        {!mode.view && clientMasterModal.show && (
          <ClientMaster
            show={clientMasterModal.show}
            action={clientMasterModal.action}
            title={clientMasterModal.title}
            onHide={closeClientMasterModal}
            callBack={reloadClientMasterData}
            name={typeAheadFields?.client}
            saveToDB={false}
          />
        )}

        {!mode.view && vehicleMasterModal.show && (
          <VehicleMaster
            show={vehicleMasterModal.show}
            action={vehicleMasterModal.action}
            title={vehicleMasterModal.title}
            onHide={closeVehicleMasterModal}
            callBack={reloadVehicleMasterData}
            saveToDB={false}
            registration_number={typeAheadFields?.vehicle}
          />
        )}

        {!mode.view && driverMasterModal.show && (
          <DriverMaster
            show={driverMasterModal.show}
            action={driverMasterModal.action}
            title={driverMasterModal.title}
            onHide={closeDriverMasterModal}
            callBack={reloadDriverMasterData}
            saveToDB={false}
            name={typeAheadFields?.driver}
          />
        )}

        {!mode.view && supplierMasterModal.show && (
          <SupplierMaster
            show={supplierMasterModal.show}
            action={supplierMasterModal.action}
            title={supplierMasterModal.title}
            supplier_type={"vehicle_supplier"}
            onHide={closeSupplierMasterModal}
            callBack={reloadSupplierMasterData}
            saveToDB={false}
            name={typeAheadFields?.supplier}
          />
        )}

        {paymentFormModal.show && (
          <PaymentForm
            show={paymentFormModal.show}
            action={paymentFormModal.action}
            data={paymentFormModal?.data}
            index={paymentFormModal?.index}
            supplier_max_limit={paymentFormModal?.supplier_max_limit}
            client_max_limit={paymentFormModal?.client_max_limit}
            disableSupplierPayment={paymentFormModal?.disableSupplierPayment}
            disableClientPayment={paymentFormModal?.disableClientPayment}
            onHide={closePaymentFormModal}
            callBack={paymentFormCallBack}
            saveToDB={false}
            consData={state}
            consId={recordId}
            organizationId={localUser?.organization_id}
          />
        )}

        {advanceFormModal.show && (
          <AdvanceForm
            show={advanceFormModal.show}
            action={advanceFormModal.action}
            data={advanceFormModal?.data}
            index={advanceFormModal?.index}
            supplier_max_limit={advanceFormModal?.supplier_max_limit}
            client_max_limit={advanceFormModal?.client_max_limit}
            disableSupplierPayment={advanceFormModal?.disableSupplierPayment}
            disableClientPayment={advanceFormModal?.disableClientPayment}
            onHide={closeAdvanceFormModal}
            callBack={advanceFormCallBack}
            saveToDB={false}
            consData={state}
            consId={recordId}
            organizationId={localUser?.organization_id}
          />
        )}
      </>
      {/* <!--END:EXTERNAL COMPONENTS -->*/}

      {/* <!--BEGIN:Modal -->*/}
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
        size="xl"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
        </Modal.Header>

        <Modal.Body className="scroll-box">
          {isProcessing && <ProcessLoader />}
          {/* <!BEGIN: Form --> */}
          <Form
            id="consignment-form"
            className="grid grid-cols-12 gap-3 gap-y-3"
            onSubmit={saveForm}
          >
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <Form.Label className="required">Consignment date</Form.Label>
              <Form.Input
                className="form-control"
                onBlur={storeDataLocal}
                type="date"
                name="consignment_date"
                id="consignment_date"
                onChange={changeInput}
                value={state.consignment_date}
                required
                disabled={mode.view || mode.acknowledge}
              />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <Form.Label className={consStage > 0 ? "required" : ""}>
                Client
              </Form.Label>
              <div class="input-group">
                <Typeahead
                  onBlur={storeDataLocal}
                  allowNew
                  newSelectionPrefix="Add new: "
                  clearButton
                  filterBy={["name", "contact_number"]}
                  id="client"
                  name="client"
                  onFocus={(event) => {
                    loadDefaultMetrics(event, "client");
                  }}
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "client");
                  }}
                  onInputChange={(text) => {
                    changeInputTypeaheadFields(text, "client");
                  }}
                  options={defaultState?.client}
                  // labelKey={(option) => `${option?.name || ""}`}
                  labelKey="name"
                  selected={formatTypeSearch(state?.client || "")}
                  inputProps={{ required: consStage > 0 ? true : false }}
                  placeholder="Search client..."
                  disabled={mode.view || mode.acknowledge}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                          <ClientsInList
                            data={result}
                            id={"client_" + index}
                            display={["phone"]}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                />

                {localUser?.module_access?.business_party && (
                  <MasterAddButton
                    id="input-group-client"
                    title="Add New Client Master"
                    onClick={openClientMasterModal}
                    disabled={mode.view || mode.acknowledge}
                  />
                )}
              </div>
              <ValidationError msg={validationError.client} />
            </div>
            {/* <!-- BEGIN: Truck Details -- > */}
            <>
              <div className="col-span-12 md:col-span-6 lg:col-span-4">
                <Form.Label className={consStage > 0 ? "required" : ""}>
                  Vehicle
                </Form.Label>
                <div className="input-group">
                  <Typeahead
                    onBlur={storeDataLocal}
                    allowNew
                    newSelectionPrefix="Add new: "
                    clearButton
                    id="vehicle"
                    name="vehicle"
                    filterBy={[
                      "registration_number",
                      "vehicleType",
                      "ownership_type",
                    ]}
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "vehicle");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInputVehicle(selected, "vehicle");
                    }}
                    onInputChange={(text) => {
                      changeInputTypeaheadFields(text, "vehicle");
                    }}
                    options={defaultState?.vehicle}
                    // labelKey={(option) => `${option?.registration_number || ""}`}
                    labelKey="registration_number"
                    selected={formatTypeSearch(state?.vehicle || "")}
                    inputProps={{ required: consStage > 0 ? true : false }}
                    placeholder="Search vehicle..."
                    disabled={mode.view || mode.acknowledge}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={index}
                          >
                            <TruckNumberDisplay
                              data={result}
                              id={"vehicle_" + result?._id}
                            />
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />
                  {localUser?.module_access?.master && (
                    <MasterAddButton
                      id="input-group-vehicle"
                      title="Add New Vehicle Master"
                      onClick={openVehicleMasterModal}
                      disabled={mode.view || mode.acknowledge}
                    />
                  )}
                </div>
                <ValidationError msg={validationError.vehicle} />
              </div>

              {state?.vehicle?.customOption && (
                <div className="col-span-12 md:col-span-6 lg:col-span-4">
                  <div className="mt-3">
                    {" "}
                    <label>Vehicle Ownership</label>
                    <div className="flex flex-col sm:flex-row mt-2">
                      <div className="form-check mr-2">
                        {" "}
                        <input
                          id="vehicle_ownership_type_own"
                          className="form-check-input"
                          type="radio"
                          name="vehicle_ownership_type"
                          value="own"
                          checked={state?.vehicle_ownership_type === "own"}
                          onChange={changeInput}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="vehicle_ownership_type_own"
                        >
                          Own
                        </label>{" "}
                      </div>

                      <div className="form-check mr-2">
                        {" "}
                        <input
                          id="vehicle_ownership_type_supplier"
                          className="form-check-input"
                          type="radio"
                          name="vehicle_ownership_type"
                          value="supplier"
                          checked={state?.vehicle_ownership_type === "supplier"}
                          onChange={changeInput}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="vehicle_ownership_type"
                        >
                          Supplier
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Vehicle Ownership - If Custom Vehicle */}
              {(state?.vehicle?.ownership_type === "supplier" ||
                state?.vehicle_ownership_type === "supplier") && (
                <div className="col-span-12 md:col-span-6 lg:col-span-4">
                  <Form.Label className={consStage > 0 ? "required" : ""}>
                    Vehicle Supplier
                  </Form.Label>
                  <div className="input-group">
                    <Typeahead
                      onBlur={storeDataLocal}
                      allowNew
                      newSelectionPrefix="Add new: "
                      clearButton
                      filterBy={["name", "contact_number"]}
                      id="supplier"
                      name="supplier"
                      onFocus={(event) => {
                        loadDefaultMetrics(event, "supplier");
                      }}
                      onChange={(selected) => {
                        changeTypeHeadInput(selected, "supplier");
                      }}
                      onInputChange={(text) => {
                        changeInputTypeaheadFields(text, "supplier");
                      }}
                      options={defaultState?.supplier}
                      // labelKey={(option) => `${option?.name || ""}`}
                      labelKey="name"
                      selected={formatTypeSearch(state?.supplier || "")}
                      inputProps={{ required: consStage > 0 ? true : false }}
                      placeholder="Search..."
                      disabled={mode.view || mode.acknowledge}
                    />
                    <MasterAddButton
                      id="input-group-supplier"
                      title="Add New Supplier Master"
                      onClick={openSupplierMasterModal}
                      disabled={mode.view || mode.acknowledge}
                    />
                  </div>
                  <ValidationError msg={validationError.supplier} />
                </div>
              )}
              {/* Vehicle Ownership - If Custom Vehicle */}
            </>
            {/* <!-- END: Truck Details -- > */}
            {/* <!-- BEGIN: Route -- > */}
            <>
              <div className="col-span-12 md:col-span-6 lg:col-span-4">
                <Form.Label className={consStage > 0 ? "required" : ""}>
                  Origin
                </Form.Label>
                <div class="input-group">
                  <Typeahead
                    onBlur={storeDataLocal}
                    allowNew
                    clearButton
                    id="origin"
                    filterBy={["name"]}
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "location");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "origin");
                    }}
                    onInputChange={(text) => {
                      changeInputTypeaheadFields(text, "location");
                    }}
                    options={defaultState?.location}
                    // labelKey={(option) => `${option?.place}, ${option?.name}`}
                    // labelKey={(option) => `${option?.name || ""}`}
                    labelKey="name"
                    selected={formatTypeSearch(state?.origin || "")}
                    inputProps={{ required: consStage > 0 ? true : false }}
                    placeholder="Search origin..."
                    newSelectionPrefix="Add new: "
                    disabled={mode.view || mode.acknowledge}
                  />
                  {localUser?.module_access?.master && (
                    <MasterAddButton
                      id="input-group-origin"
                      title="Add New Location Master"
                      onClick={(e) => openCityMasterModal(e, "origin")}
                      disabled={mode.view || mode.acknowledge}
                    />
                  )}
                </div>
                <ValidationError msg={validationError.origin} />
              </div>

              <div className="col-span-12 md:col-span-6 lg:col-span-4">
                <Form.Label className={consStage > 0 ? "required" : ""}>
                  Destination
                </Form.Label>
                <div className="input-group">
                  <Typeahead
                    onBlur={storeDataLocal}
                    allowNew
                    clearButton
                    id="destination"
                    filterBy={["name"]}
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "location");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "destination");
                    }}
                    onInputChange={(text) => {
                      changeInputTypeaheadFields(text, "location");
                    }}
                    options={defaultState?.location}
                    // labelKey={(option) => `${option?.place}, ${option?.name}`}
                    // labelKey={(option) => `${option?.name || ""}`}
                    labelKey="name"
                    selected={formatTypeSearch(state?.destination || "")}
                    inputProps={{ required: consStage > 0 ? true : false }}
                    placeholder="Search destination..."
                    disabled={mode.view || mode.acknowledge}
                    // allowNew
                    newSelectionPrefix="Add new: "
                  />
                  {localUser?.module_access?.master && (
                    <MasterAddButton
                      id="input-group-origin"
                      title="Add New Location Master"
                      onClick={(e) => openCityMasterModal(e, "destination")}
                      disabled={mode.view || mode.acknowledge}
                    />
                  )}
                </div>
                <ValidationError msg={validationError.destination} />
              </div>
            </>
            {/* <!-- END: Route -- > */}
            {/* <!-- BEGIN: Driver Details -- > */}
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <Form.Label>Driver Info</Form.Label>
              <div className="input-group">
                <Typeahead
                  allowNew
                  onBlur={storeDataLocal}
                  newSelectionPrefix="Add new: "
                  clearButton
                  filterBy={["name", "license_no", "contact_number"]}
                  id="driver"
                  onFocus={(event) => {
                    loadDefaultMetrics(event, "driver");
                  }}
                  onChange={(selected) => {
                    changeTypeHeadInputDriver(selected, "driver");
                  }}
                  onInputChange={(text) => {
                    changeInputTypeaheadFields(text, "driver");
                  }}
                  labelKey="name"
                  // labelKey={(option) => `${option?.name || ""}`}
                  options={defaultState?.driver}
                  // inputProps={{ required: consStage > 0 ? true : false }}
                  selected={formatTypeSearch(state?.driver || "")}
                  placeholder="Search driver..."
                  className="text-uppercase"
                  disabled={mode.view || mode.acknowledge}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                          <DriversInList
                            id={"driver_" + index}
                            data={result}
                            display={["phone", "license_no", "license_expiry"]}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                />
                {localUser?.module_access?.master && (
                  <MasterAddButton
                    id="input-group-driver"
                    title="Add New Driver Master"
                    onClick={openDriverMasterModal}
                    disabled={mode.view || mode.acknowledge}
                  />
                )}
              </div>
              <ValidationError msg={validationError?.driver} />
            </div>
            {/* <!-- END: Driver Details -- > */}
            {/* <div className="col-span-12">
              <div className="w-full border-t border-gray-200 dark:border-dark-5 m-3"></div>
            </div> */}
            {/* <!-- BEGIN: Freight Charges Details -- > */}
            <>
              {/* {state?.charges?.consignment_tds && (
                <div className="col-span-12 sm:col-span-4">
                  <label
                    htmlFor="consignment_tds_percentage"
                    className="form-label"
                  >
                    TDS
                  </label>
                  <PercentageInput
                    type="number"
                    className="form-control"
                    id="consignment_tds_percentage"
                    name="consignment_tds_percentage"
                    placeholder=""
                    disabled={mode?.view}
                    onChange={changeChargesInput}
                    value={state?.charges?.consignment_tds_percentage}
                    defaultValue={0}
                  />
                </div>
              )} */}
            </>
            {/* <!-- END: Freight Charges Details -- > */}
            {/* <!-- BEGIN: Consignment Billing Type -- > */}
            <div className="col-span-12">
              <label htmlFor="consignment_billing_unit" className="form-label">
                Consignment Billing Type
              </label>

              {/* <!-- BEGIN: Supplier Billing Type Buttons-- > */}
              <div className="" id="consignment_billing_unit">
                <BillingTypeButton
                  id="consignment_billing_unit_fixed"
                  name="consignment_billing_unit"
                  value="fixed"
                  selected={
                    state?.charges?.consignment_billing_unit === "fixed"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "fixed")}
                >
                  Fixed
                </BillingTypeButton>
                <BillingTypeButton
                  id="consignment_billing_unit_per_ton"
                  name="consignment_billing_unit"
                  value="per_ton"
                  selected={
                    state?.charges?.consignment_billing_unit === "per_ton"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "per_ton")}
                >
                  Per Ton
                </BillingTypeButton>

                <BillingTypeButton
                  id="consignment_billing_unit_per_kg"
                  name="consignment_billing_unit"
                  value="per_kg"
                  selected={
                    state?.charges?.consignment_billing_unit === "per_kg"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "per_kg")}
                >
                  Per Kg
                </BillingTypeButton>

                <BillingTypeButton
                  id="consignment_billing_unit_per_km"
                  name="consignment_billing_unit"
                  value="per_km"
                  selected={
                    state?.charges?.consignment_billing_unit === "per_km"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "per_km")}
                >
                  Per Km
                </BillingTypeButton>

                <BillingTypeButton
                  id="consignment_billing_unit_per_trip"
                  name="consignment_billing_unit"
                  value="per_trip"
                  selected={
                    state?.charges?.consignment_billing_unit === "per_trip"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "per_trip")}
                >
                  Per Trip
                </BillingTypeButton>

                <BillingTypeButton
                  id="consignment_billing_unit_per_day"
                  name="consignment_billing_unit"
                  value="per_day"
                  selected={
                    state?.charges?.consignment_billing_unit === "per_day"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "per_day")}
                >
                  Per Day
                </BillingTypeButton>

                <BillingTypeButton
                  id="consignment_billing_unit_per_hour"
                  name="consignment_billing_unit"
                  value="per_hour"
                  selected={
                    state?.charges?.consignment_billing_unit === "per_hour"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "per_hour")}
                >
                  Per Hour
                </BillingTypeButton>

                <BillingTypeButton
                  id="consignment_billing_unit_per_litre"
                  name="consignment_billing_unit"
                  value="per_litre"
                  selected={
                    state?.charges?.consignment_billing_unit === "per_litre"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "per_litre")}
                >
                  Per Litre
                </BillingTypeButton>

                <BillingTypeButton
                  id="consignment_billing_unit_per_bag"
                  name="consignment_billing_unit"
                  value="per_bag"
                  selected={
                    state?.charges?.consignment_billing_unit === "per_bag"
                      ? true
                      : false
                  }
                  onClick={(e) => changeInputUnitButton(e, "per_bag")}
                >
                  Per Bag
                </BillingTypeButton>
              </div>
            </div>
            {/* <!-- BEGIN: Supplier Billing Type Buttons-- > */}
            {/* <!-- BEGIN: Supplier Billing Type Amount Fields-- > */}
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-3 gap-y-3">
                {state?.charges?.consignment_billing_unit !== "" &&
                  state?.charges?.consignment_billing_unit !== "fixed" && (
                    <>
                      <div className="col-span-12 sm:col-span-4">
                        <label htmlFor="unit_price" className="form-label">
                          {
                            fieldLabels?.unit_price[
                              state?.charges?.consignment_billing_unit
                            ]
                          }
                        </label>
                        <CurrencyInput
                          type="number"
                          className="form-control"
                          id="consignment_unit_price"
                          name="consignment_unit_price"
                          placeholder=""
                          disabled={mode?.view}
                          onChange={changeInputSupplierUnitFields}
                          onBlur={storeDataLocal}
                          value={state?.charges?.consignment_unit_price || ""}
                        />
                      </div>

                      <div className="col-span-12 sm:col-span-4">
                        <label
                          htmlFor="consignment_total_units"
                          className="form-label"
                        >
                          {
                            fieldLabels?.total_units[
                              state?.charges?.consignment_billing_unit
                            ]
                          }
                        </label>
                        <WeightInput
                          measure={
                            fieldLabels?.unit_measure[
                              state?.charges?.consignment_billing_unit
                            ]
                          }
                          type="number"
                          className="form-control"
                          id="consignment_total_units"
                          name="consignment_total_units"
                          placeholder=""
                          disabled={mode?.view}
                          onChange={changeInputSupplierUnitFields}
                          onBlur={storeDataLocal}
                          value={state?.charges?.consignment_total_units}
                        />
                        {/* <input
                            type="number"
                            className="form-control"
                            id="consignment_total_units"
                            name="consignment_total_units"
                            placeholder=""
                            disabled={mode?.view}
                            onChange={changeInputSupplierUnitFields}
                            value={state?.charges?.consignment_total_units}
                          /> */}
                      </div>
                    </>
                  )}

                <div className="col-span-12 sm:col-span-4">
                  <label htmlFor="consignment_freight" className="form-label">
                    Freight Amount
                  </label>
                  <CurrencyInput
                    type="number"
                    className="form-control"
                    id="consignment_freight"
                    name="consignment_freight"
                    placeholder=""
                    disabled={
                      mode?.view ||
                      (state?.charges?.consignment_billing_unit &&
                        state?.charges?.consignment_billing_unit !== "fixed")
                    }
                    onChange={changeChargesInput}
                    onBlur={storeDataLocal}
                    value={state?.charges?.consignment_freight || ""}
                  />
                </div>

                {/* <!-- BEGIN: Amount Payable--> */}
                {state?.vehicle?.ownership_type === "supplier" && (
                  <>
                    <div className="col-span-12 sm:col-span-4">
                      <Form.Label>Hold Amount</Form.Label>
                      <CurrencyInput
                        type="number"
                        name="consignment_hold_amount"
                        onChange={changeChargesInput}
                        onBlur={storeDataLocal}
                        value={state?.charges?.consignment_hold_amount || 0}
                        disabled={
                          mode.view || mode.acknowledge || mode.acknowledge_edit
                        }
                      />
                      <ValidationError
                        msg={validationError?.charges?.consignment_hold_amount}
                      />
                    </div>

                    <div className="col-span-12 sm:col-span-4">
                      <Form.Label>Amount Payable</Form.Label>
                      <CurrencyInput
                        type="number"
                        name="consignment_amount_payable"
                        onChange={changeChargesInput}
                        onBlur={storeDataLocal}
                        value={state?.charges?.consignment_amount_payable || 0}
                        disabled={
                          mode.view || mode.acknowledge || mode.acknowledge_edit
                        }
                      />
                      <ValidationError
                        msg={
                          validationError?.charges?.consignment_amount_payable
                        }
                      />
                    </div>
                  </>
                )}
                {/* <!-- END: Amount Payable--> */}

                <div className="col-span-12 md:col-span-6 lg:col-span-4">
                  <Form.Label>Remarks</Form.Label>
                  <textarea
                    className="form-control"
                    type="text"
                    name="remarks"
                    id="remarks"
                    onChange={changeInput}
                    onBlur={storeDataLocal}
                    value={state.remarks}
                    disabled={mode.view}
                  />
                </div>
              </div>
            </div>
            {/* <!-- END: Client Billing Type Amount Fields-- > */}
            {/* <!-- END: Consignment Billing Type -- > */}
            <div id="hidden_input_elements">
              <input
                type="submit"
                id="create_consignment_form"
                name="create_consignment_form"
                className="hidden"
              />

              <input
                type="submit"
                id="save_and_create_new_consignment"
                name="save_and_create_new_consignment"
                className="hidden"
              />

              <input
                type="submit"
                id="update_consignment_form"
                name="update_consignment_form"
                className="hidden"
              />
              <input
                type="submit"
                id="update_and_create_consignment_form"
                name="update_and_create_consignment_form"
                className="hidden"
              />
            </div>
          </Form>
          {/* <!END: Form --> */}
        </Modal.Body>

        <Modal.Footer className="flex justify-start">
          {/* <div className="text-gray-700">
            Total :{" "}
            <strong className="text-lg text-theme-1 font-medium">
              {formatCurrency(state?.charges?.consignment_total_amount)}
            </strong>
          </div> */}

          <label
            type="button"
            className="btn btn-secondary mr-2 "
            onClick={props.onHide}
          >
            Cancel
          </label>

          <div id="buttons" className="ml-auto">
            {mode?.create && (
              <label
                type="button"
                className="btn btn-outline-primary mr-2"
                htmlFor="save_and_create_new_consignment"
                name="save_and_create_new_consignment"
              >
                Save {"&"} Create New{" "}
                {loader?.save_and_create_new && <InLineLoader />}
              </label>
            )}

            {mode?.create && (
              <label
                type="button"
                className="btn btn-primary mr-2"
                htmlFor="create_consignment_form"
                name="create_consignment_form"
              >
                Create {loader?.create && <InLineLoader />}
              </label>
            )}

            {mode?.edit && (
              <>
                <label
                  type="button"
                  className="btn btn-primary mr-2"
                  htmlFor="update_consignment_form"
                  name="update_consignment_form"
                >
                  Update {loader?.update && <InLineLoader />}
                </label>
              </>
            )}
            {mode?.edit && state?.status === "drafted" && (
              <>
                <label
                  type="button"
                  className="btn btn-success mr-2"
                  htmlFor="update_and_create_consignment_form"
                  name="update_and_create_consignment_form"
                >
                  Create Consignment{" "}
                  {loader?.update_and_create && <InLineLoader />}
                </label>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {/* <!--END:Modal -->*/}
    </div>
  );
}
