import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { useEffect } from "react";

// // Theme
// import { keepTheme } from "./utils/theme";
// // Components  
// import DarkModeSwitcher from "./components/dark-mode-switcher";

// CSS
import "./assets/sass/app.scss";
// import 'bootstrap/dist/css/bootstrap.min.css';

// // JS
// import LoadDefaults from "./assets/js/app";
// import "./assets/js/app";
import FeatherIcons from "./assets/js/feather";

// Router
import PSAdminRouter from "./ps_admin/router";
import SignUp from "./layouts/signup";
import EmailVerification from "./layouts/verification/email";
import ResetPassword from "./layouts/reset_password/index";

import Admin from "./layouts/admin";
// import Home from "./view/Home/index";
import NotFound from "./layouts/error/notFound";
// // Module Imports
// import Landing from "./layouts/landing";

import SetupWizard from "./layouts/setup";
import Login from "./layouts/login";
// import SamplePage from "./view/Sample";

function App() {
  useEffect(() => {
    // LoadDefaults();
    FeatherIcons();
    // keepTheme();
  });
  return (
    <>
      <Router>
        <Switch>
          <Route path="/ps_admin" exact component={PSAdminRouter} />
          <Route path="/" exact component={Login} />
          {/* <Route path="/admin" component={Admin} /> */}
          <Route
            path="/admin"
            render={(props) => <Admin {...props} reload={false} />}
          />

          <Route path="/login" component={Login} />
          {/* <Route path="/home" component={Home} /> */}
          <Route
            path="/setup_wizard/:customer_id"
            exact
            component={SetupWizard}
          />
          <Route
            path="/verify_email/:email_activation_code/:email/:password/:customer_id"
            component={EmailVerification}
            exact
          />
          <Route
            path="/reset_password/:email/:reset_password_code"
            component={ResetPassword}
            exact
          />

          <Route path="/signup" component={SignUp} />
          {/* <Route path="/sample_page" component={SamplePage} /> */}
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
      {/* <DarkModeSwitcher /> */}
    </>
  );
}

export default App;
