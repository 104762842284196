class storageServiceClass {
  constructor() {}

  async getCurrentUser() {
    let res = localStorage.getItem("user");
    try {
      res = JSON.parse(res);
    } catch (e) {}
    return res;
  }

  async setCurrentUser(user) {
    try {
      localStorage.setItem("user", JSON.stringify(user));
    } catch (e) {
      console.log("Error:", e);
    }
  }

  async resetCurrentUser() {
    localStorage.removeItem("user");
  }

  async storeData(key, data) {
    try {
      await localStorage.setItem(key, JSON.stringify(data));
      return true;
    } catch (e) {
      console.log("Error:", e);
      return;
    }
  }

  async getStoredData(key) {
    try {
      return JSON.parse(localStorage.getItem(key) || {});
    } catch (e) {
      return false;
    }
  }

  async removeData(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {}
    return;
  }
}
const storageServices = new storageServiceClass();
export { storageServices };
