import React, { useState, useEffect } from "react";
import {
  formatCurrency,
  formatKm,
  formatLitres,
  formatMileage,
  localDateTime,
  toFloat,
  localDate,
  trimConsNum,
} from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import { storageServices } from "../../functions/storage";



export default function Component(props) {
  const Title = "Tyre History Report";
  const tableRef = React.createRef();
  const { data } = props;
  const [vehicleData, setVehicleData] = useState({});
  const [state, setState] = useState({
    total_amount: 0,
    total_paid: 0,
    total_balance: 0,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData(props.data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);

    let tableData = await mapTableData(data);
    setTableData(tableData);

    setIsProcessing(false);
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Tyre Serial No.",
        field: "tyre.serial_no",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vehicle Reg. No",
        field: "vehicle.registration_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Tyre Position",
        field: "position",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Fitment Date",
        field: "fitment_date",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Removal Date",
        field: "removal_date",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Fitment Odometer",
        field: "fitment_odometer",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Removal Odometer",
        field: "removal_odometer",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Kms Covered",
        field: "km_covered",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(row) {}

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Data Table --> */}
        <ReportsDataTable
          title={Title}
          header={tableData.header}
          content={tableData.content}
          onRowClick={(e, row) => {
            viewRecord(row);
          }}
          downloadFileName={Title}
          tableRef={tableRef}
        />
        {/* <!-- END: Data Table --> */}
      </div>
    </>
  );
}
