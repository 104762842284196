import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// HTML Components
import CurrencyInput from "../htmlElements/currencyInput";
import PhoneInput from "../htmlElements/phoneInput";

// Components
import ProcessLoader from "../preloader/processLoader";
import { Modal } from "react-bootstrap";
import ValidationError from "../error/validationError";
import DividerText from "../htmlElements/dividerText";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeAhAddNewButton from "../htmlElements/typeAhAddNewButton";
// Functions
import { resp } from "../../functions/responseHandler";
import {
  isDefined,
  formatTypeSearch,
  toFloat,
  formatTypeaheadCommon,
} from "../../functions/common";
import * as api from "../../api_service/api";
import * as validation from "../../functions/validation";
// Add-on Components
import SupplierTypeForm from "../master/supplier_type";

const countryListFile = require("../../utils/country_state_list.json");

const Model = {
  isNew: false,

  address_details: false,
  bank_details: false,

  name: "",
  type: "",
  contact_number: "",
  contact_person: "",
  address: "",

  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",

  account_number: "",
  bank_name: "",
  ifsc_code: "",
  branch_name: "",
  opening_balance: 0,
  current_balance: 0,
  current_total: 0,
  pan_number: "",
  status: "active",
};

const ValidationErrorModel = {
  name: "",
  contact_number: "",
  pan_number: "",
  zip_code: "",
};

const countryStateList = countryListFile.countries;
// const countryList = countryStateList.map((a) => a.country);
const countryList = ["India"];
const defaultCountry = "India";

export default function Component(props) {
  const [saveToDB, setSaveToDB] = useState(true);
  // Loaders
  const [isProcessing, setIsProcessing] = useState(false);
  const [inlineSpinnerIfsc, setInlineSpinnerIfsc] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
    approve: false,
  });

  const [userAction, setUserAction] = useState();
  // State
  const [pageTitle, setPageTitle] = useState("Supplier");
  const [state, setState] = useState({ ...Model });
  const [validationError, setValidationError] = useState({
    ...ValidationErrorModel,
  });
  const [record_id, setRecordId] = useState();

  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  // Default State
  const [defaultState, setDefaultState] = useState({
    type: [],
    state: [],
    country: countryList,
  });

  //////////// External Modal States   ////////////
  const [supplierTypeMasterModal, setSupplierTypeMasterModal] = useState({
    show: false,
    title: "Add Supplier Type",
    action: "create",
    id: "",
  });

  //////////// External Modal States   ////////////

  useEffect(() => {
    loadDefaults();
    handleUserAction(props);
  }, []);

  async function loadDefaults() {
    let obj = await countryStateList.find((o) => o.country === defaultCountry);
    if (typeof obj !== "undefined" && obj.states.length > 0) {
      setDefaultState((prevState) => ({
        ...prevState,
        state: obj.states,
      }));
    }
  }

  async function setDefaults() {
    setIsProcessing(true);
    let prevState = { ...Model };
    prevState.country = defaultCountry;

    if (props?.name) prevState["name"] = props?.name;
    setState(prevState);

    // Loading Supplier Type If Defined
    if (isDefined(props?.supplier_type)) {
      let supplier_type_array = await loadSupplierType();

      for (var i in supplier_type_array) {
        if (supplier_type_array[i]?.type === props?.supplier_type) {
          setState((prevState) => ({
            ...prevState,
            type: supplier_type_array[i],
          }));
        }
      }
    }

    setIsProcessing(false);
  }

  // <!-- BEGIN: Supplier Type Master Modal -- >
  const openSupplierMasterModal = () =>
    setSupplierTypeMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeSupplierMasterModal = () =>
    setSupplierTypeMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadSupplierTypeData(event) {
    closeSupplierMasterModal();
    loadSupplierType(true);
  }
  // <!-- END: Supplier Type Master Modal -- >

  async function handleUserAction(props) {
    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setPageTitle("Add Supplier");
        setDefaults();
        setMode((prevState) => ({ ...prevState, create: true }));
        break;
      case "edit":
        setPageTitle("Edit Supplier");
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setPageTitle("View Supplier");
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  // Default Metrics
  async function loadSupplierType(reload = false) {
    // event.preventDefault();
    let type_array = defaultState?.type;
    if (defaultState?.type?.length === 0 || reload) {
      setIsProcessing(true);
      await api
        .getService("master/supplier_type/status/active")
        .then(async (response) => {
          console.log("response:", response);
          type_array = response?.data?.data;
          await setDefaultState((prevState) => ({
            ...prevState,
            type: type_array,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    }
    return type_array;
  }

  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`master/supplier/${id}`).then(
      async (result) => {
        const data = result.data;
        let mappedData = await mapData(data.data);
        console.log("data:", data);
        setState(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  function mapData(data) {
    data["current_balance"] = toFloat(
      data?.opening_balance || 0 + data?.current_total || 0
    );

    if (isDefined(data?.address_line_1) || isDefined(data?.city)) {
      data["address_details"] = true;
    }

    if (isDefined(data?.account_number) || isDefined(data?.ifsc_code)) {
      data["bank_details"] = true;
    }

    return data;
  }

  function prepareData(data) {
    data["type"] = data?.type?._id;
    return data;
  }
  async function onSubmit(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));

      var query = prepareData({ ...state });
      query["isNew"] = true;
      if (saveToDB) {
        setIsProcessing(true);
        api
          .postService("master/supplier", query)
          .then((response) => {
            setIsProcessing(false);
            resp.Success(response?.data?.message);
            props.callBack(response?.data?.data, props?.supplier_type);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        props.callBack(query, props?.supplier_type);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      var query = prepareData({ ...state });
      api
        .putService(`master/supplier/${record_id}`, query)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === "opening_balance") {
      let currentBalance =
        parseFloat(value) + parseFloat(state?.current_total || 0);
      setState((prevState) => ({
        ...prevState,
        current_balance: currentBalance,
      }));
    }
    formValidation(name, value);
  }

  function formValidation(name, value) {
    let errors = validationError;
    switch (name) {
      case "name":
        errors[name] = validation.validateName(value);
        break;
      case "pan_number":
        errors[name] = validation.validatePAN(value);
        break;
      default:
        break;
    }
  }

  function changeIFSC(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (value.length === 11) {
      fetchBankDetails(value);
    }
  }
  async function fetchBankDetails(ifsc_code) {
    setInlineSpinnerIfsc(true);
    await api.getBankDetails(ifsc_code).then(
      async (result) => {
        const data = result.data;
        setState((prevState) => ({ ...prevState, branch_name: data?.BRANCH }));
        setState((prevState) => ({ ...prevState, bank_name: data?.BANK }));
        setInlineSpinnerIfsc(false);
      },
      (error) => {
        console.log("error", error);
        setInlineSpinnerIfsc(false);
      }
    );
  }

  // Input Change
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function switchBoolean(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  return (
    <>
      {/* EXTERNAL COMPONENTS */}
      {!mode?.view && supplierTypeMasterModal.show && (
        <SupplierTypeForm
          show={supplierTypeMasterModal.show}
          action={supplierTypeMasterModal.action}
          title={supplierTypeMasterModal.title}
          onHide={closeSupplierMasterModal}
          callBack={reloadSupplierTypeData}
        />
      )}
      {/* EXTERNAL COMPONENTS */}
      <div
        key="supplier_modal"
        id="supplier_modal"
        onClick={(e) => e.stopPropagation()}
      >
        {/* <!-- BEGIN: Modal Content --> */}
        <Modal
          {...props}
          scrollable={true}
          backdrop="static"
          keyboard={false}
          onHide={props.onHide}
          size="lg"
        >
          <Modal.Header closeButton>
            <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
          </Modal.Header>

          <Modal.Body>
            {isProcessing && <ProcessLoader />}
            <form
              className="grid grid-cols-12 gap-4 gap-y-3"
              onSubmit={
                userAction === "create"
                  ? onSubmit
                  : userAction === "edit"
                  ? onUpdate
                  : (e) => e.preventDefault()
              }
            >
              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="name" className="form-label required">
                  Supplier Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder=""
                  disabled={mode?.view}
                  onChange={changeInput}
                  value={state.name}
                  required
                />
                <ValidationError msg={validationError?.name} />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="supplier_type" className="form-label required">
                  Supplier Type
                </label>

                <Typeahead
                  clearButton
                  id="supplier_type"
                  name="supplier_type"
                  key="supplier_type"
                  onFocus={(e) => loadSupplierType(false)}
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "type");
                  }}
                  labelKey={(option) => `${option?.name}`}
                  options={defaultState?.type}
                  inputProps={{ required: true }}
                  selected={formatTypeSearch(state?.type)}
                  placeholder="Search Supplier Type..."
                  disabled={mode?.view}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                          {result?.name}
                        </MenuItem>
                      ))}
                      <TypeAhAddNewButton
                        onClick={openSupplierMasterModal}
                      ></TypeAhAddNewButton>
                    </Menu>
                  )}
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="contact_number" className="form-label">
                  Phone Number
                </label>
                <PhoneInput
                  type="number"
                  className="form-control"
                  id="contact_number"
                  name="contact_number"
                  placeholder=""
                  disabled={mode?.view}
                  onChange={changeInput}
                  value={state.contact_number}
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="contact_person" className="form-label">
                  Contact Person
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_person"
                  name="contact_person"
                  placeholder=""
                  disabled={mode?.view}
                  onChange={changeInput}
                  value={state.contact_person}
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="pan_number" className="form-label">
                  PAN Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pan_number"
                  name="pan_number"
                  placeholder=""
                  maxLength="10"
                  disabled={mode?.view}
                  onChange={changeInput}
                  value={state.pan_number}
                />
                <ValidationError msg={validationError?.pan_number} />
              </div>

              {/* <!--BEGIN: Address Details --> */}
              {state?.address_details && (
                <>
                  {/* <!--BEGIN: Address Details Fields */}
                  <div className="col-span-12">
                    <DividerText>Address Details</DividerText>
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="address_line_1" className="form-label">
                      Address Line 1
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address_line_1"
                      name="address_line_1"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.address_line_1}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="address_line_2" className="form-label">
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address_line_2"
                      name="address_line_2"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.address_line_2}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.city}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <Typeahead
                      id="state"
                      onChange={(selected) => {
                        changeTypeHeadInput(selected, "state");
                      }}
                      options={defaultState.state}
                      labelKey={(option) => `${option}`}
                      selected={formatTypeaheadCommon(state?.state)}
                      // inputProps={{ required: true }}
                      disabled={mode.view}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <Typeahead
                      id="country"
                      onChange={(selected) => {
                        changeTypeHeadInput(selected, "country");
                      }}
                      options={defaultState.country}
                      labelKey={(option) => `${option}`}
                      selected={formatTypeaheadCommon(state?.country)}
                      // inputProps={{ required: true }}
                      disabled={mode.view}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="zip_code" className="form-label">
                      Pin Code
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="zip_code"
                      name="zip_code"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state.zip_code}
                    />
                    <ValidationError msg={validationError?.zip_code} />
                  </div>
                  {/* <!--END: Address Details Fields*/}
                </>
              )}
              {/* <!--END: Address Details --> */}

              {/* BANK DETAILS */}
              {state?.bank_details && (
                <>
                  <div className="col-span-12">
                    <div className="w-full flex justify-center border-t border-gray-200 dark:border-dark-5 mt-2">
                      {" "}
                      <div className="bg-white dark:bg-dark-3 px-5 -mt-3 text-gray-600">
                        BANK DETAILS
                      </div>{" "}
                    </div>
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="account_number" className="form-label">
                      Account Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="account_number"
                      name="account_number"
                      placeholder=""
                      minLength="9"
                      maxLength="18"
                      disabled={mode?.view}
                      onChange={changeInput}
                      value={state.account_number}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="ifsc_code" className="form-label">
                      IFSC
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="ifsc_code"
                        name="ifsc_code"
                        minLength="11"
                        maxLength="11"
                        pattern="[A-Za-z]{4}0[A-Z0-9a-z]{6}"
                        placeholder=""
                        disabled={mode?.view}
                        onChange={changeIFSC}
                        value={state.ifsc_code}
                      />
                      {inlineSpinnerIfsc && (
                        <div className="input-group-append ml-2 d-flex align-items-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="branch_name" className="form-label">
                      Branch Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="branch_name"
                      name="branch_name"
                      placeholder=""
                      disabled={mode?.view}
                      onChange={changeInput}
                      value={state.branch_name}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="bank_name" className="form-label">
                      Bank Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bank_name"
                      name="bank_name"
                      placeholder=""
                      disabled={mode?.view}
                      onChange={changeInput}
                      value={state.bank_name}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="opening_balance" className="form-label">
                      Opening Balance
                    </label>

                    <CurrencyInput
                      type="number"
                      id="opening_balance"
                      name="opening_balance"
                      placeholder=""
                      disabled={mode?.view}
                      onChange={changeInput}
                      value={state.opening_balance}
                      required={false}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="current_balance" className="form-label">
                      Current Balance
                    </label>
                    <CurrencyInput
                      type="number"
                      id="current_balance"
                      name="current_balance"
                      placeholder=""
                      disabled={true}
                      value={state.current_balance}
                    />
                  </div>
                </>
              )}
              {/* BANK DETAILS */}

              {/* Other Details Button */}
              {!mode?.view && (
                <div className="col-span-12 mt-2">
                  <div className="preview">
                    {!state?.address_details && (
                      <button
                        className="btn btn-outline-primary inline-block mr-1 mb-2"
                        name="address_details"
                        onClick={switchBoolean}
                      >
                        <i className="fa fa-plus mr-1" aria-hidden="true"></i>{" "}
                        Address
                      </button>
                    )}
                    {!state?.bank_details && (
                      <button
                        className="btn btn-outline-primary inline-block mr-1 mb-2"
                        name="bank_details"
                        onClick={switchBoolean}
                      >
                        <i className="fa fa-plus mr-1" aria-hidden="true"></i>
                        Bank Details
                      </button>
                    )}
                  </div>
                </div>
              )}
              {/* Other Details Button */}

              <input type="submit" id="save_supplier" className="hidden" />
              <input type="submit" id="update_supplier" className="hidden" />
            </form>
          </Modal.Body>
          {/* <!-- END: Modal Body --> */}
          {/* <!-- BEGIN: Modal Footer --> */}
          <Modal.Footer>
            {" "}
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-secondary w-20 mr-2"
              onClick={props.onHide}
            >
              Cancel
            </button>{" "}
            {userAction === "create" && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="save_supplier"
              >
                Save
              </label>
            )}
            {userAction === "edit" && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="update_supplier"
              >
                Update
              </label>
            )}
          </Modal.Footer>
          {/* <!-- END: Modal Footer --> */}
        </Modal>

        {/* <!-- END: Modal Content --> */}
      </div>
    </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Supplier",
  action: "view",
  onSave() {},
  callBack() {},
};
