import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Components
import { Modal } from "react-bootstrap";
import ProcessLoader from "../../preloader/processLoader";
import ValidationError from "../../../components/error/validationError";
// Functions
import { resp } from "../../../functions/responseHandler";
import {
  formatCurrency,
  matchData,
  htmlDate,
  toFloat,
} from "../../../functions/common";
import * as api from "../../../api_service/api";
import * as validation from "../../../functions/validation";
import CurrencyInput from "../../htmlElements/currencyInput";

const Model = {
  name: "",
  account_number: "",

  opening_balance: 0,
  current_total: 0,
  current_balance: 0,
  old_current_balance: 0,
  opening_balance_date: "",
  bank_name: "",
  branch: "",
  ifsc_code: "",
  status: "",
};

export default function Component(props) {
  const [saveToDB, setSaveToDB] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [mode, setMode] = useState({ view: false, edit: false, create: false });
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  // Errors
  const [validationError, setValidationError] = useState({
    ...Model,
  });
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    handleUserAction(props);
  }, []);

  async function setDefaults() {
    let prevState = { ...Model };
    prevState.opening_balance_date = htmlDate(new Date());
    prevState.status = "active";
    setState(prevState);
  }

  async function handleUserAction(props) {
    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setDefaults();
        setMode((prevState) => ({ ...prevState, create: true }));
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`master/bank/${id}`).then(
      async (result) => {
        const data = result.data;
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  async function mapData(data) {
    let matchedData = await matchData(state, data);
    matchedData["opening_balance_date"] = htmlDate(
      matchedData?.opening_balance_date
    );
    // Current Balance Calculation
    matchedData["current_balance"] =
      toFloat(matchedData?.opening_balance || 0) +
      toFloat(matchedData?.current_total || 0);

    setState(matchedData);
  }

  function prepareData(data) {
    return data;
  }

  async function fetchBankDetails(ifsc_code) {
    setIsProcessing(true);
    await api.getBankDetails(ifsc_code).then(
      async (result) => {
        console.log("resp", result);
        const data = result.data;

        setState((prevState) => ({ ...prevState, branch: data?.BRANCH }));
        setState((prevState) => ({ ...prevState, bank_name: data?.BANK }));
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  async function onSubmit(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));

      var query = prepareData({ ...state });
      query["status"] = "active";
      query["isNew"] = true;
      if (saveToDB) {
        setIsProcessing(true);
        api
          .postService("master/bank", query)
          .then((response) => {
            setIsProcessing(false);
            resp.Success(response?.data?.message);
            props.callBack(response?.data?.data);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        props.callBack(query);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      var query = prepareData({ ...state });
      api
        .putService(`master/bank/${record_id}`, query)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack(query);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === "opening_balance") {
      let currentBalance =
        parseFloat(value || 0) + parseFloat(state?.current_total || 0);
      setState((prevState) => ({
        ...prevState,
        current_balance: currentBalance,
      }));
    }
    //IFSC
    if (name === "ifsc_code" && value.length === 11) {
      fetchBankDetails(value);
    }

    formValidation(name, value);
  }

  function formValidation(name, value) {
    let errors = { ...validationError };
    switch (name) {
      default:
        break;
    }
    setValidationError(errors);
  }

  return (
    // <div onClick={(e) => e.stopPropagation()}>
    // <>
    <div
      key="bank_master_modal"
      id="bank_master_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            {!mode?.create && (
              <div className="col-span-12">
                <div className="text-center">
                  <span className="text-gray-500">Current Balance</span>
                  <p
                    className={
                      state?.current_balance < 0
                        ? "text-theme-6 font-bold text-xl"
                        : "text-theme-9 font-bold text-xl"
                    }
                  >
                    {formatCurrency(state?.current_balance || 0)}
                  </p>
                </div>
              </div>
            )}
            <div className="col-span-12">
              <label htmlFor="name" className="form-label required">
                Account holder name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.name}
                required
              />
              <ValidationError msg={validationError?.name} />
            </div>

            <div className="col-span-12">
              <label htmlFor="account_number" className="form-label required">
                A/C Number
              </label>
              <input
                type="text"
                className="form-control"
                id="account_number"
                name="account_number"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.account_number}
                required
              />
              <ValidationError msg={validationError?.account_number} />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="ifsc_code" className="form-label">
                IFSC Code
              </label>
              <input
                type="text"
                className="form-control"
                id="ifsc_code"
                name="ifsc_code"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.ifsc_code}
              />
              <ValidationError msg={validationError?.ifsc_code} />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="bank_name" className="form-label">
                Bank Name
              </label>
              <input
                type="text"
                className="form-control"
                id="bank_name"
                name="bank_name"
                placeholder="eg: HDFC"
                disabled={mode.view}
                onChange={changeInput}
                value={state?.bank_name}
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="branch" className="form-label">
                Branch Name
              </label>
              <input
                type="text"
                className="form-control"
                id="branch"
                name="branch"
                placeholder="eg: Anna Nagar"
                disabled={mode.view}
                onChange={changeInput}
                value={state?.branch}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="opening_balance" className="form-label">
                Opening Balance
              </label>
              <CurrencyInput
                type="number"
                className="form-control"
                id="opening_balance"
                name="opening_balance"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.opening_balance}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="opening_balance_date" className="form-label">
                Opening Balance Date
              </label>
              <input
                type="date"
                className="form-control"
                id="opening_balance_date"
                name="opening_balance_date"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.opening_balance_date}
                max={htmlDate(new Date())}
              />
            </div>

            <input type="submit" id="save_bank_master" className="hidden" />
            <input type="submit" id="update_bank_master" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {/* <!-- BEGIN: Validation error msg --> */}
          <div className="inline-flex mr-2 float-left">
            <ValidationError msg={!isValidForm.valid ? isValidForm.msg : ""} />
          </div>{" "}
          {/* <!-- END: Validation error msg --> */}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_bank_master"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_bank_master"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
    // </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Bank Account",
  action: "view",
  onSave() {},
  callBack() {},
};
