import React, { useState, useEffect } from "react";
// Page Defaults
import ProcessLoader from "../../../components/preloader/processLoader";
// Page Elements
import ValidationError from "../../../components/error/validationError";
// HTML ELEMENTS
import CurrencyInput from "../../../components/htmlElements/currencyInput";
import Container from "../../../components/container";
import Card from "../../../components/card/custom";
import Form from "../../../components/form/custom";
import DividerText from "../../../components/htmlElements/dividerText";
import InfoButton from "../../../components/htmlElements/infoButton";

// Functions
import * as api from "../../../api_service/api";
import { resp } from "../../../functions/responseHandler";
import {
  formatCurrency,
  toFloat,
  htmlDate,
  formatTypeSearch,
  validateComplexForm,
} from "../../../functions/common";
// Model
import { Model, ValidationErrorModel } from "./model";
// HTML ELEMENTS
import { Collapse } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
// External Componenets
import CreditDebitMemoForm from "../../../components/Accounts/credit_debit_memo";

// Valiadtion
import * as validation from "../../../functions/validation";

// CSS
import "./form.css";

// // Datatable
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import $ from "jquery";

export default function RecievablesForm(props) {
  const [pageTitle, setPageTitle] = useState("New Receipt");
  // Loaders
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  // Validation
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [validationError, setValidationError] = useState(
    ValidationErrorModel()
  );
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  const [state, setState] = useState(Model());
  const [isSelectAll, setISSelectAll] = useState(false);

  const [defaultState, setDefaultState] = useState({
    bank: [],
    receivable: [],
    client: [],
    cash: [],
    advance_reference: [],
  });

  //////// INVOICE TABLE /////////
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState({
    count: 0,
    data: [],
  });
  //////// INVOICE TABLE /////////

  // Modal Functions
  const [creditDebitMemoModal, setCreditDebitMemoModal] = useState({
    show: false,
    data: {},
  });

  useEffect(() => {
    loadDefaults();
    setIsLoaded(true);
  }, []);

  function loadDefaults() {
    setState((prevState) => ({ ...prevState, date: htmlDate(new Date()) }));
    loadDefaultMetrics("client");
  }

  async function openCreditDebitMemoModal(event, row, arrayIndex) {
    event.preventDefault();
    setIsProcessing(true);
    var invoicesData = [...state.invoices];

    let usedClientCredits = await invoicesData
      .filter(
        (doc, index) => index != arrayIndex && doc.apply_client_credits == true
      )
      .reduce(
        (sum, record) =>
          toFloat(sum) + toFloat(record.new_client_credits_amount),
        0
      );

    // let data = {
    //   new_debit: row?.new_debit,
    //   new_debit_note: row?.new_debit_note,
    //   new_debit_ref_no: row?.new_debit_ref_no,

    //   new_credit: row?.new_credit,
    //   new_credit_note: row?.new_credit_note,
    //   new_credit_ref_no: row?.new_credit_ref_no,
    //   arrayIndex: arrayIndex,
    //   available_client_credits: state?.available_client_credits,

    //   original_available_client_credits: state?.available_client_credits,
    //   used_client_credits: usedClientCredits,

    //   apply_client_credits: row?.apply_client_credits,
    //   new_client_credits_amount: row?.new_client_credits_amount,
    // };
    let data = row;
    data["arrayIndex"] = arrayIndex;
    data["original_available_client_credits"] = state?.available_client_credits;
    data["used_client_credits"] = usedClientCredits;

    let prevState = { ...creditDebitMemoModal };
    prevState.data = data;
    prevState.show = true;
    setIsProcessing(false);
    setCreditDebitMemoModal(prevState);
  }

  function closeCreditDebitMemoModal() {
    setCreditDebitMemoModal((prevState) => ({ ...prevState, show: false }));
  }

  const selectRecord = async (event, array_name, array_index) => {
    const list = [...state[array_name]];

    let value =
      toFloat(list[array_index]["balance"]) -
      toFloat(list[array_index]["new_debit"] || 0) -
      toFloat(list[array_index]["new_tds"] || 0) -
      toFloat(list[array_index]["new_discount"] || 0) -
      toFloat(list[array_index]["client_credits_amount"] || 0);

    if (value != 0) {
      let selected = !list[array_index]["selected"];
      if (selected) {
        list[array_index]["new_amount_received"] = toFloat(value);
      } else {
        list[array_index]["new_amount_received"] = 0;
      }
      list[array_index]["selected"] = selected;
      setState((prevState) => ({ ...prevState, [array_name]: list }));
      calcTotalTransaction(list);

      // Validating available balance
      if (state?.transaction_context == "journal") {
        formValidation("available_advance_amount", "", "", "", "", state);
      }
    }
  };

  const changeInputArrayObject = async (event, array_name, array_index) => {
    const { name, value } = event.target;
    const prevState = { ...state };
    prevState[array_name][array_index][name] = value;
    if (name == "new_amount_received") {
      if (value != 0) {
        prevState[array_name][array_index]["selected"] = true;
      } else {
        prevState[array_name][array_index]["selected"] = false;
      }
    }
    setState(prevState);
    if (
      name == "new_amount_received" ||
      name == "new_tds" ||
      name == "new_discount"
    ) {
      calcTotalTransaction(prevState[array_name]);
      formValidation(
        name,
        value,
        array_name,
        array_index,
        prevState[array_name][array_index],
        prevState
      );
    }
  };

  // Input Changes

  function formValidation(
    name,
    value,
    array_name = "",
    index = 0,
    data,
    state
  ) {
    let errors = validationError;
    let total = 0;
    switch (name) {
      case "client":
        errors[name] = validation.validateTypeAhead(value);
        break;
      case "new_amount_received":
        total =
          toFloat(value) +
          toFloat(data?.new_tds) +
          toFloat(data?.new_discount) +
          toFloat(data?.new_client_credits_amount);
        errors[array_name][index][name] =
          total > data?.balance ? "Should not exceed balance." : "";
        break;

      case "new_tds":
        total =
          toFloat(data?.new_amount_received) +
          toFloat(value) +
          toFloat(data?.new_discount) +
          +toFloat(data?.new_client_credits_amount);
        errors[array_name][index][name] =
          total > data?.balance ? "Should not exceed balance." : "";
        break;

      case "new_discount":
        total =
          toFloat(data?.new_amount_received) +
          toFloat(data?.tds) +
          toFloat(value) +
          toFloat(data?.new_client_credits_amount);
        errors[array_name][index][name] =
          total > data?.balance ? "Should not exceed balance." : "";
        break;

      default:
        break;
    }
  }

  //////////////// CALCULATIONS ////////////////////

  function calcTotalCreditDebit(list) {
    let totalCredit = 0;
    let totalDebit = 0;
    for (var i in list) {
      totalCredit += toFloat(list[i]["new_credit"]);
      totalDebit += toFloat(list[i]["new_debit"]);
    }
    setState((prevState) => ({
      ...prevState,
      total_credit_amount: totalCredit,
      total_debit_amount: totalDebit,
    }));
  }

  function calcTotalTransaction(list, advance_amount) {
    let newAmount = 0;
    for (var i in list) {
      newAmount += toFloat(list[i]["new_amount_received"]);
    }
    setState((prevState) => ({
      ...prevState,
      total_transaction_amount: newAmount,
    }));

    if (state?.transaction_context === "journal") {
      if (typeof advance_amount == "undefined") {
        advance_amount = state?.advance_reference?.available_balance;
      }
      let availableAmount = toFloat(advance_amount || 0) - newAmount;
      setState((prevState) => ({
        ...prevState,
        available_advance_amount: availableAmount,
      }));
    }
  }

  function calcTotalBalance(data) {
    let balance = 0;
    let total_received =
      (data?.total_amount_received || 0) +
      (data?.total_debit || 0) +
      (data?.total_tds || 0) +
      (data?.total_discount || 0);

    balance = data?.total - total_received;
    return balance;
  }

  async function calcBalanceInvoices(data) {
    let validationModel = [];
    let total_balance = 0;
    let total_amount_received = 0;
    for (var i in data) {
      let balance = await calcTotalBalance(data[i]);
      let amount_received = toFloat(data[i]["total_amount_received"]);
      data[i]["balance"] = toFloat(balance);
      data[i]["total_amount"] = data[i].total;
      data[i]["new_amount_received"] = 0;
      data[i]["new_tds"] = 0;
      data[i]["new_discount"] = 0;
      data[i]["new_debit"] = 0;
      data[i]["new_debit_note"] = "";
      data[i]["new_debit_ref_no"] = "";
      data[i]["new_credit"] = 0;
      data[i]["new_credit_note"] = "";
      data[i]["new_credit_ref_no"] = "";
      data[i]["remarks"] = "";
      data[i]["selected"] = false;
      data[i]["apply_client_credits"] = false;
      data[i]["new_client_credits_amount"] = 0;

      data[i]["collapse"] = false;

      total_balance += balance;
      total_amount_received += amount_received;

      // Setting Up Validation Model
      let validationObj = { new_amount_received: "", new_debit: "" };
      validationModel.push(validationObj);
    }
    await setValidationError((prevState) => ({
      ...prevState,
      invoices: validationModel,
    }));

    await setState((prevState) => ({
      ...prevState,
      total_balance: total_balance,
    }));
    await setState((prevState) => ({
      ...prevState,
      total_amount_received: total_amount_received,
    }));

    return data;
  }
  //////////////// CALCULATIONS ////////////////////

  function changeInput(event) {
    const { name, value } = event.target;
    if (name === "total_transaction_amount") {
      // calcInvoice(value);
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeInputType(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    switch (value) {
      case "direct":
        loadInvoice(state?.client);
        getClientCredits();
        break;
      case "advance":
        break;
      case "journal":
        loadAdvanceReferences(state?.client);
        loadInvoice(state?.client);
        getClientCredits();
        break;
      default:
        break;
    }
  }

  function changePaymentMethod(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (value === "bank" || value === "cash") {
      loadDefaultMetrics(value);
    }
  }

  function changeInputPaymentMaster(event) {
    const { name, value } = event.target;
    let parsedValue = JSON.parse(value);
    setState((prevState) => ({ ...prevState, [name]: parsedValue }));
  }

  function finalValidation() {
    var result = true;

    return result;
  }
  async function onSubmit(event) {
    event.preventDefault();
    if (validateComplexForm(validationError) && finalValidation()) {
      let data = await prepareData({ ...state });
      if (data) {
        setIsProcessing(true);
        api
          .postService("accounts/transactions/receivable", data)
          .then((response) => {
            console.log("response:", response);
            setIsProcessing(false);
            resp.ResponseHandler(response, "reload");
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function getSelectedInvoices(recData) {
    let data = await recData.filter((x) => x.selected);
    for (var i in data) {
      let transaction_amount = parseFloat(data[i]["new_amount_received"] || 0);
      let tds = parseFloat(data[i]["new_tds"] || 0);
      let discount = parseFloat(data[i]["new_discount"] || 0);
      let credit = parseFloat(data[i]["new_credit"] || 0);
      let debit = parseFloat(data[i]["new_debit"] || 0);
      let client_credits_amount = parseFloat(
        data[i]["new_client_credits_amount"] || 0
      );

      data[i]["transaction_amount"] = transaction_amount;
      data[i]["tds"] = tds;
      data[i]["discount"] = discount;
      data[i]["debit"] = debit;
      data[i]["debit_note"] = data[i]["new_debit_note"];
      data[i]["debit_ref_no"] = data[i]["new_debit_ref_no"];

      data[i]["credit"] = credit;
      data[i]["credit_note"] = data[i]["new_credit_note"];
      data[i]["credit_ref_no"] = data[i]["new_credit_ref_no"];

      data[i]["apply_client_credits"] = data[i]["apply_client_credits"];
      data[i]["client_credits_amount"] = client_credits_amount;

      let receivables_status = "";
      let original_transaction_amount =
        transaction_amount + tds + discount + debit + client_credits_amount;

      let balance = parseFloat(data[i]["balance"] || 0);

      if (original_transaction_amount >= balance) {
        receivables_status = "paid";
      } else {
        receivables_status = "partial";
      }
      data[i]["receivables_status"] = receivables_status;
    }
    return data;
  }

  async function prepareData(data) {
    let response = {};
    let invoices = [];
    switch (data?.transaction_context) {
      case "direct":
        invoices = await getSelectedInvoices(data?.invoices);
        if (invoices.length > 0) {
          response = {
            transaction_type: "receivable",
            payment_from_type: "client",
            payment_to_type: "self",
            date: data?.date,
            transaction_context: data?.transaction_context,
            payment_mode: data?.payment_mode,
            payment_reference_number: data?.payment_reference_number,
            client: data?.client?._id,
            invoices: invoices,
          };
          if (data?.payment_mode === "bank") {
            response["payment_bank"] = data?.payment_bank?._id;
          }

          if (data?.payment_mode === "cash") {
            response["payment_cash"] = data?.payment_cash?._id;
          }
        } else {
          resp.Error("No Invoices Selected");
          response = false;
        }
        break;
      case "journal":
        invoices = await getSelectedInvoices(data?.invoices);
        if (invoices.length > 0) {
          response = {
            transaction_type: "receivable",
            payment_mode: "journal",
            payment_from_type: "client",
            payment_to_type: "self",
            date: data?.date,
            transaction_context: data?.transaction_context,
            // payment_mode: data?.payment_mode,
            // payment_reference_number: data?.payment_reference_number,
            advance_id: data?.advance_reference?._id,
            advance_status: "open",
            client: data?.client?._id,
            invoices: invoices,
          };

          // Checking if advance amount is fully utilized
          if (state?.available_advance_amount == 0) {
            response["advance_status"] = "closed";
          }
        } else {
          resp.Error("No Invoices Selected");
          response = false;
        }
        break;
      case "advance":
        response = {
          date: data?.date,
          transaction_type: "receivable",
          transaction_context: "advance",
          payment_mode: data?.payment_mode,
          payment_from_type: "client",
          payment_to_type: "self",
          payment_reference_number: data?.payment_reference_number,
          client: data?.client?._id,
          transaction_amount: data?.transaction_amount,
          advance_status: "open",
          remarks: data?.remarks,
        };
        if (data?.payment_mode === "bank") {
          response["payment_bank"] = data?.payment_bank?._id;
        }
        if (data?.payment_mode === "cash") {
          response["payment_cash"] = data?.payment_cash?._id;
        }

        break;
      default:
        break;
    }
    return response;
  }

  function changeTypeHeadInputAdavnce(selected, name, type) {
    let prevState = { ...state };
    if (selected.length != 0) {
      let selected_data = selected[0];
      prevState[name] = selected_data;
      prevState["available_advance_amount"] = selected_data?.available_balance;
      setState(prevState);

      // Calculating total amount and available balance
      calcTotalTransaction(
        { ...prevState["invoices"] },
        selected_data?.available_balance
      );
    } else {
      prevState[name] = "";
      prevState["available_advance_amount"] = "";
      setState(prevState);

      calcTotalTransaction({ ...prevState["invoices"] }, 0);
    }
  }

  function changeClient(selected, name, type) {
    if (selected.length != 0) {
      let selected_data = selected[0];
      setState((prevState) => ({ ...prevState, [name]: selected_data }));
      // Loading Invoices if Type is Set
      if (
        state?.transaction_context == "direct" ||
        state?.transaction_context == "journal"
      ) {
        loadInvoice(selected_data);
      }
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  async function getClientCredits() {
    if (state.client !== "" && typeof state.client?._id !== "undefined") {
      setIsProcessing(true);
      const client_id = state.client._id;
      await api
        .getService(`accounts/transactions/client_credits/${client_id}`)
        .then((response) => {
          const data = response.data;
          let clientCredits = data.data.client_credits;
          console.log(data);
          setState((prevState) => ({
            ...prevState,
            available_client_credits: clientCredits,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    } else {
      resp.Error("Please select client.");
    }
  }

  async function loadDefaultMetrics(metric) {
    if (defaultState[metric] && defaultState[metric].length < 1) {
      setIsProcessing(true);
      var query = { metrix: [metric] };
      await api
        .postService("common", query)
        .then((response) => {
          console.log(response);
          const data = response.data;
          if (metric === "cash") {
            // Setting default value if one record is found
            if (data[metric].length == 1) {
              console.log("One cash");
              setState((prevState) => ({
                ...prevState,
                payment_cash: data[metric][0],
              }));
            }
          }

          if (metric === "bank") {
            // Setting default value if one record is found
            if (data[metric].length == 1) {
              setState((prevState) => ({
                ...prevState,
                payment_bank: data[metric][0],
              }));
            }
          }

          setDefaultState((prevState) => ({
            ...prevState,
            [metric]: response?.data[metric],
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    }
  }

  async function loadAdvanceReferences(client) {
    if (client !== "" && typeof client?._id !== "undefined") {
      setIsProcessing(true);
      await api
        .getService(`accounts/transactions/advance/${client?._id}/open`)
        .then(async (response) => {
          const data = response.data;
          console.log("data::", data.data);
          setDefaultState((prevState) => ({
            ...prevState,
            advance_reference: data.data,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    } else {
      resp.Error("Please select client.");
    }
  }

  async function loadInvoice(client) {
    if (client !== "" && typeof client?._id !== "undefined") {
      setIsProcessing(true);
      await api
        .getService(`accounts/transactions/invoices/unpaid/${client?._id}`)
        .then(async (response) => {
          const data = response.data;
          console.log("data", data);
          let invoice = await calcBalanceInvoices(data.data);
          await setState((prevState) => ({
            ...prevState,
            invoices: invoice,
          }));

          //Datatable Function
          // $("#receivable-data-table").DataTable({
          //   deferRender: true,
          //   dom: "Bfrtip",
          //   columnDefs: [
          //     {
          //       orderable: false,
          //       targets: [0, 2, 3, 4, 5],
          //     },
          //   ],
          // });

          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    } else {
      resp.Error("Please select client.");
    }
  }

  function goBack() {
    window.location.href = "/admin#/accounts/recievables";
  }

  // Credit / Debit Memo

  function processCreditDebitMemo(data) {
    console.log("data:", data);
    const prevState = { ...state };

    prevState["invoices"][data.arrayIndex]["new_debit"] = data?.new_debit;
    prevState["invoices"][data.arrayIndex]["new_debit_note"] =
      data?.new_debit_note;

    prevState["invoices"][data.arrayIndex]["new_debit_ref_no"] =
      data?.new_debit_ref_no;

    prevState["invoices"][data.arrayIndex]["new_credit"] = data?.new_credit;
    prevState["invoices"][data.arrayIndex]["new_credit_note"] =
      data?.new_credit_note;

    prevState["invoices"][data.arrayIndex]["new_credit_ref_no"] =
      data?.new_credit_ref_no;

    if (data?.new_debit > 0 || data?.new_credit > 0) {
      prevState["invoices"][data.arrayIndex]["selected"] = true;
    }

    // Client Credits
    prevState["invoices"][data.arrayIndex]["apply_client_credits"] =
      data?.apply_client_credits;

    prevState["invoices"][data.arrayIndex]["new_client_credits_amount"] =
      data?.new_client_credits_amount;

    setState(prevState);
    closeCreditDebitMemoModal();
    calcTotalCreditDebit(prevState["invoices"]);
  }

  function mouseOverCollapse(event, index) {
    event.preventDefault();
    collapseSummary(event, index);
  }

  function collapseSummary(
    event,
    index,
    value = !state.invoices[index].collapse
  ) {
    event.preventDefault();
    let prevState = { ...state };
    prevState.invoices[index].collapse = value;
    setState(prevState);
  }

  return (
    <>
      <Container>
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title>{pageTitle}</Container.Title>
        </Container.Header>
        {/* <!-- END: Header --> */}
        {/* <!-- BEGIN: Container Body --> */}
        <Container.Body>
          {/* <!-- BEGIN: Modals -->  */}

          {/* Credit Debit Memo */}
          {creditDebitMemoModal.show && (
            <CreditDebitMemoForm
              show={creditDebitMemoModal.show}
              data={creditDebitMemoModal.data}
              onHide={closeCreditDebitMemoModal}
              callBack={processCreditDebitMemo}
            />
          )}

          {/* <!-- END: Modals -->  */}

          {/* <!-- BEGIN: Card --> */}
          <Card className="mt-5">
            {isProcessing && <ProcessLoader />}
            {/* <!-- BEGIN: Card Body --> */}
            <Card.Body>
              {/* <!-- BEGIN: Form --> */}
              <Form id="recievables-form" onSubmit={onSubmit}>
                {/* <!-- BEGIN: Input Fields */}
                <Form.Row className="justify-center">
                  <Form.Col>
                    <Form.Label>Date</Form.Label>
                    <Form.Input
                      type="date"
                      max={htmlDate(new Date())}
                      className="form-control"
                      id="date"
                      name="date"
                      onChange={changeInput}
                      value={state?.date}
                      required
                    />
                  </Form.Col>
                  <Form.Col>
                    <Form.Label>Client</Form.Label>
                    <Typeahead
                      id="client"
                      name="client"
                      onChange={(selected) => {
                        changeClient(selected, "client");
                      }}
                      options={defaultState?.client}
                      labelKey={(option) => `${option?.name}`}
                      selected={formatTypeSearch(state?.client)}
                      inputProps={{ required: true }}
                      placeholder="Search client..."
                    />
                  </Form.Col>
                  <Form.Col>
                    <Form.Label>Type</Form.Label>
                    <select
                      id="transaction_context"
                      name="transaction_context"
                      className="form-control"
                      onChange={changeInputType}
                      value={state.transaction_context}
                      required
                    >
                      <option value="" defaultValue disabled>
                        Choose...
                      </option>
                      <option value="direct">Direct</option>
                      <option value="advance">Advance</option>
                      <option value="journal">Journal</option>
                    </select>
                  </Form.Col>

                  {/* BEGIN: Not Journal Specific fields */}
                  {state?.transaction_context !== "journal" && (
                    <>
                      <Form.Col>
                        <Form.Label>Payment mode</Form.Label>
                        <select
                          id="payment_mode"
                          name="payment_mode"
                          className="form-control"
                          onChange={changePaymentMethod}
                          value={state.payment_mode}
                          required
                        >
                          <option value="" defaultValue="" disabled>
                            Choose...
                          </option>
                          <option value="cash">Cash</option>
                          <option value="bank">Bank</option>
                        </select>
                      </Form.Col>
                      {/* CASH MASTER */}
                      {state.payment_mode == "cash" && (
                        <Form.Col>
                          <Form.Label>Cash Book</Form.Label>
                          <select
                            id="payment_cash"
                            name="payment_cash"
                            className="form-control"
                            onChange={changeInputPaymentMaster}
                            value={JSON.stringify(state.payment_cash)}
                            required
                          >
                            <option value={"{}"} defaultValue={true} disabled>
                              Choose Cash Book...
                            </option>
                            {defaultState.cash.map((item, index) => {
                              return (
                                <option
                                  value={JSON.stringify(item)}
                                  key={index}
                                  id={`cash_book_${index}`}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>

                          <div className="mt-2">
                            <label>
                              Cash Balance :{" "}
                              {formatCurrency(
                                state?.payment_cash?.opening_balance +
                                  state?.payment_cash?.current_total
                              )}{" "}
                            </label>
                          </div>
                        </Form.Col>
                      )}
                      {/* CASH MASTER */}

                      {/* BANK MASTER */}
                      {state.payment_mode == "bank" && (
                        <Form.Col>
                          <Form.Label>Bank Account</Form.Label>
                          <select
                            id="payment_bank"
                            name="payment_bank"
                            className="form-control"
                            onChange={changeInputPaymentMaster}
                            value={JSON.stringify(state.payment_bank)}
                            required
                          >
                            <option value="{}" defaultValue disabled>
                              Choose Bank A/C...
                            </option>
                            {defaultState.bank.map((item, index) => {
                              return (
                                <option
                                  value={JSON.stringify(item)}
                                  key={index}
                                  id={`bank_account_${index}`}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          <div className="mt-2">
                            <label>
                              Bank Balance :{" "}
                              {formatCurrency(
                                state?.payment_bank?.opening_balance +
                                  state?.payment_bank?.current_total
                              )}{" "}
                            </label>
                          </div>
                        </Form.Col>
                      )}
                      {/* BANK MASTER */}
                    </>
                  )}
                  {/* END: Not Journal Specific fields */}

                  {/* BEGIN: Journal Specific fields */}
                  {state?.transaction_context === "journal" && (
                    <>
                      <Form.Col>
                        <Form.Label>Advance References</Form.Label>
                        <Typeahead
                          id="advance_reference"
                          name="advance_reference"
                          onChange={(selected) => {
                            changeTypeHeadInputAdavnce(
                              selected,
                              "advance_reference"
                            );
                          }}
                          options={defaultState?.advance_reference}
                          labelKey={(option) =>
                            `${option?.payment_reference_number}`
                          }
                          selected={formatTypeSearch(state?.advance_reference)}
                          inputProps={{ required: true }}
                          placeholder="Search..."
                        />

                        <div className="mt-2">
                          <div className="inline mr-3">
                            <label>
                              <span className="text-muted">Amount :</span>{" "}
                              {formatCurrency(
                                state?.advance_reference?.transaction_amount
                              )}{" "}
                            </label>
                          </div>

                          <div className="inline mr-3">
                            <label>
                              <span className="text-muted">Used :</span>{" "}
                              {formatCurrency(
                                state?.advance_reference?.used_amount
                              )}{" "}
                            </label>
                          </div>

                          <div className="inline">
                            <span className="text-muted">
                              Remarks / Notes :
                            </span>
                            {state?.advance_reference?.remarks}
                          </div>
                        </div>
                      </Form.Col>
                    </>
                  )}
                  {/* END: Journal Specific fields */}

                  {state?.transaction_context === "advance" && (
                    <Form.Col>
                      <Form.Label>Amount</Form.Label>
                      <Form.Input
                        type="number"
                        className="form-control"
                        id="transaction_amount"
                        name="transaction_amount"
                        onChange={changeInput}
                        value={state?.transaction_amount}
                        required
                      />
                    </Form.Col>
                  )}

                  {state?.transaction_context !== "journal" && (
                    <Form.Col>
                      <Form.Label>
                        {" "}
                        {state?.payment_mode === "cash"
                          ? "Voucher No."
                          : state.payment_mode === "bank"
                          ? "Transaction Id"
                          : "Reference No"}
                      </Form.Label>
                      <Form.Input
                        type="text"
                        className="form-control"
                        id="payment_reference_number"
                        name="payment_reference_number"
                        onChange={changeInput}
                        value={state.payment_reference_number}
                        required
                      />
                    </Form.Col>
                  )}

                  {state?.transaction_context === "advance" && (
                    <Form.Col>
                      <Form.Label>Remarks / Notes</Form.Label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="remarks"
                        name="remarks"
                        onChange={changeInput}
                        value={state?.remarks}
                      ></textarea>
                    </Form.Col>
                  )}
                </Form.Row>
                {/* <!-- END: Input Fields */}

                {/* <!-- BEGIN: Invoice Table */}
                {(state?.transaction_context === "direct" ||
                  state?.transaction_context === "journal") && (
                  <>
                    <div className="p-15">
                      <DividerText>Invoice details</DividerText>

                      {/* <!-- BEGIN: Transaction summary display--> */}
                      <div className="w-100" id="amount-details">
                        <div className="float-right mb-3">
                          <div className="d-inline mr-2">
                            <ValidationError
                              msg={validationError?.available_advance_amount}
                            />
                          </div>

                          <div className="inline bg-theme-1 text-white px-3 py-1 mr-2">
                            Total Amount :
                            <span className="ml-1 font-semibold">
                              {formatCurrency(
                                state?.total_transaction_amount
                              ) || 0}
                            </span>
                          </div>

                          <div className="inline bg-theme-6 text-white px-3 py-1 mr-2">
                            Total Debit :
                            <span className="ml-1 font-semibold">
                              {formatCurrency(state?.total_debit_amount) || 0}
                            </span>
                          </div>

                          <div className="inline bg-theme-9 text-white px-3 py-1 mr-2">
                            Total Credit :
                            <span className="ml-1 font-semibold">
                              {formatCurrency(state?.total_credit_amount) || 0}
                            </span>
                          </div>

                          {state?.transaction_context === "journal" && (
                            <div className="inline bg-theme-4 text-white px-3 py-1 mr-2">
                              Avail Bal. :
                              <span className="ml-1 font-semibold">
                                {formatCurrency(
                                  state?.available_advance_amount
                                ) || 0}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <!-- END: Transaction summary display--> */}

                      <table id="data-table" className="table">
                        <thead className="bg-gray-200 text-gray-700">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Invoice No.</th>
                            <th scope="col" colSpan="3">
                              Summary
                            </th>
                            <th scope="col">Amount</th>
                            <th scope="col">TDS</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Remarks</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {state?.invoices?.map((row, index) => {
                            return (
                              <tr
                                className={
                                  row?.selected ? "bg-light-success" : ""
                                }
                                key={index}
                                id={`invoice_${index}`}
                              >
                                {/* Check Box */}
                                <td className="border-b dark:border-dark-5">
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      checked={row?.selected}
                                      name="check_record"
                                      onChange={(event) => {
                                        selectRecord(event, "invoices", index);
                                      }}
                                    />
                                  </div>
                                </td>

                                {/* Check Box */}

                                {/* Invoice No */}
                                <td className="border-b dark:border-dark-5">
                                  {row?.invoice_number}
                                </td>
                                {/*SUMMARY */}
                                <td
                                  colSpan="3"
                                  className="border-b dark:border-dark-5"
                                >
                                  <div className="">
                                    <p
                                      className={
                                        row?.selected
                                          ? "m-0 text-dark"
                                          : "m-0 text-secondary"
                                      }
                                    >
                                      Billed :
                                      <strong className="">
                                        {formatCurrency(
                                          toFloat(row?.total_amount)
                                        )}{" "}
                                      </strong>
                                    </p>
                                    <p
                                      className={
                                        row?.selected
                                          ? "m-0 text-dark"
                                          : "m-0 text-secondary"
                                      }
                                    >
                                      Balance :
                                      <strong>
                                        {formatCurrency(row?.balance)}{" "}
                                      </strong>
                                    </p>
                                  </div>

                                  <InfoButton
                                    id={`invoice_breakup_${index}`}
                                    className="mt-1"
                                    buttonHtml={
                                      <div className="text-theme-1 ">
                                        <i
                                          className="fa fa-info-circle mr-l"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        More Info
                                      </div>
                                    }
                                  >
                                    <div className="px-2 shadow">
                                      <div id="group1">
                                        <div className="d-inline mr-3">
                                          <label className="m-0">
                                            Received :{" "}
                                            <strong className="text-info">
                                              {formatCurrency(
                                                row?.total_amount_received
                                              )}{" "}
                                            </strong>
                                          </label>
                                        </div>
                                      </div>
                                      <div id="group2">
                                        <div className="d-inline mr-3">
                                          <label className="m-0">
                                            Debited by client :{" "}
                                            <strong className="text-danger">
                                              {"-"}&nbsp;
                                              {formatCurrency(
                                                row?.total_debit
                                              )}{" "}
                                            </strong>
                                          </label>
                                        </div>

                                        <div className="d-inline mr-3">
                                          <label className="m-0">
                                            TDS :{" "}
                                            <strong className="text-danger">
                                              {"-"}&nbsp;
                                              {formatCurrency(
                                                row?.total_tds
                                              )}{" "}
                                            </strong>
                                          </label>
                                        </div>

                                        <div className="d-inline mr-3">
                                          <label className="m-0">
                                            Discount :{" "}
                                            <strong className="text-danger">
                                              {"-"}&nbsp;
                                              {formatCurrency(
                                                row?.total_discount
                                              )}{" "}
                                            </strong>
                                          </label>
                                        </div>
                                      </div>

                                      <div>
                                        <div className="d-inline">
                                          <label className="m-0 text-muted">
                                            Credits given by client :{" "}
                                            <strong className="text-success">
                                              {formatCurrency(
                                                row?.total_credit
                                              )}{" "}
                                            </strong>
                                            <small>(* excluded)</small>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </InfoButton>
                                </td>
                                {/* SUMMARY */}

                                {/* Amount Received */}
                                <td className="border-b dark:border-dark-5 align-top">
                                  <CurrencyInput
                                    id="new_amount_received"
                                    name="new_amount_received"
                                    onChange={(event) => {
                                      changeInputArrayObject(
                                        event,
                                        "invoices",
                                        index
                                      );
                                    }}
                                    value={row?.new_amount_received}
                                    // required={row?.selected}
                                    // size="sm"
                                  />
                                  <ValidationError
                                    msg={
                                      validationError?.invoices[index]
                                        ?.new_amount_received
                                    }
                                  />

                                  {/* Applied Client Credits */}
                                  {row?.apply_client_credits && (
                                    <p className="m-0">
                                      <small className="m-0 text-sm">
                                        Applied Client credits :{" "}
                                        <strong className="text-info">
                                          {formatCurrency(
                                            row?.new_client_credits_amount || 0
                                          )}{" "}
                                        </strong>
                                      </small>
                                    </p>
                                  )}
                                  {/* Applied Client Credits */}
                                  {/* New DEBIT / Credit Memo */}
                                  {(row?.new_debit > 0 ||
                                    row?.new_credit > 0) && (
                                    <div id="new_credit_debit_memo">
                                      <div className="d-inline mr-3">
                                        <small className="m-0 text-sm">
                                          Debit :{" "}
                                          <strong className="text-danger">
                                            {"-"}&nbsp;
                                            {formatCurrency(
                                              row?.new_debit || 0
                                            )}{" "}
                                          </strong>
                                        </small>
                                      </div>

                                      <div className="d-inline">
                                        <small className="m-0 text-sm">
                                          Credit :{" "}
                                          <strong className="text-success">
                                            {"+"}&nbsp;
                                            {formatCurrency(
                                              row?.new_credit || 0
                                            )}{" "}
                                          </strong>
                                        </small>
                                      </div>
                                    </div>
                                  )}

                                  {/* New DEBIT / Credit Memo */}
                                </td>
                                {/* Amount Received */}

                                {/* TDS */}
                                <td className="border-b dark:border-dark-5 align-top">
                                  <CurrencyInput
                                    id="new_tds"
                                    name="new_tds"
                                    onChange={(event) => {
                                      changeInputArrayObject(
                                        event,
                                        "invoices",
                                        index
                                      );
                                    }}
                                    value={row?.new_tds}
                                  />

                                  <ValidationError
                                    msg={
                                      validationError?.invoices[index]?.new_tds
                                    }
                                  />
                                </td>
                                {/* TDS */}

                                {/* Discoount */}
                                <td className="border-b dark:border-dark-5 align-top">
                                  <CurrencyInput
                                    id="new_discount"
                                    name="new_discount"
                                    onChange={(event) => {
                                      changeInputArrayObject(
                                        event,
                                        "invoices",
                                        index
                                      );
                                    }}
                                    value={row?.new_discount}
                                  />

                                  <ValidationError
                                    msg={
                                      validationError?.invoices[index]
                                        ?.new_discount
                                    }
                                  />
                                </td>
                                {/* Discoount */}

                                {/* REMARKS */}
                                <td className="border-b dark:border-dark-5 align-top">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="remarks"
                                    name="remarks"
                                    onChange={(event) => {
                                      changeInputArrayObject(
                                        event,
                                        "invoices",
                                        index
                                      );
                                    }}
                                    value={row?.remarks}
                                  />
                                </td>
                                {/* Credit / Debit Button */}
                                <td className="border-b dark:border-dark-5 align-top">
                                  <button
                                    type="button"
                                    className="btn  btn-secondary"
                                    onClick={(event) =>
                                      openCreditDebitMemoModal(
                                        event,
                                        row,
                                        index
                                      )
                                    }
                                  >
                                    Debit / Credit
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
                {/* <!-- END: Invoice Table */}
                <input type="submit" id="save_button" className="hidden" />
              </Form>
              {/* <!-- END: Form --> */}
            </Card.Body>
            {/* <!-- END: Card Body --> */}

            {/* <!-- BEGIN: Card Footer --> */}
            <Card.Footer className="justify-end">
              <button className="btn btn-secondary mr-2" onClick={goBack}>
                Cancel
              </button>

              <label
                type="button"
                className="btn btn-primary"
                htmlFor="save_button"
              >
                Create Transaction
              </label>
            </Card.Footer>
            {/* <!-- END: Card Footer --> */}
          </Card>
          {/* <!-- END: Card --> */}
        </Container.Body>
        {/* <!-- END: Container Body --> */}
      </Container>
    </>
  );
}
