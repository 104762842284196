import React, { useState, useEffect } from "react";
import { formatCurrency, toFloat } from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import { storageServices } from "../../functions/storage";

import { ReactComponent as OwnTruckIcon } from "../../assets/images/svg/truck.svg";
import { ReactComponent as SupplierTruckIcon } from "../../assets/images/svg/supplier_truck.svg";

export default function Template(props) {
  const Title = "Report";
  const tableRef = React.createRef();
  const { data } = props;
  const [state, setState] = useState({
    own_vehicle_profit: 0,
    supplier_vehicle_profit: 0,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData(data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);
    let mappedData = await mapData(data);
    let tableData = await mapTableData(mappedData);
    setTableData(tableData);
    setIsProcessing(false);
  }

  function mapData(data) {
    let ownVehicleProfit = 0;
    let supplierVehicleProfit = 0;
    for (var i in data) {
      let profit =
        toFloat(data[i]?.client_total) - toFloat(data[i]?.consignment_total);

      data[i]["profit"] = profit;

      if (data[i]["vehicle_ownership_type"] === "own") {
        ownVehicleProfit += profit;
      }
      if (data[i]["vehicle_ownership_type"] === "supplier") {
        supplierVehicleProfit += profit;
      }
    }

    let prevState = { ...state };
    prevState.own_vehicle_profit = ownVehicleProfit;
    prevState.supplier_vehicle_profit = supplierVehicleProfit;
    setState(prevState);
    return data;
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Vehicle",
        field: "vehicle_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vehicle Ownership",
        field: "vehicle_ownership_type",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Supplier",
        field: "supplier",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Client",
        field: "client",
        headerSort: false,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Consignment Freight",
        field: "consignment_total",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Client Billed Freight",
        field: "client_total",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Profit",
        field: "profit",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(row) {}

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Sum Values --> */}
        <div id="sum_values" className="w-full flex justify-end space-x-4">
          {/* <!-- BEGIN: Own Vehicle Profit --> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-green-200 w-1/3">
            <div class="flex-none image-fit rounded-md overflow-hidden">
              <OwnTruckIcon width="36" height="36" className="inline" />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Own Vehicle Profit</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.own_vehicle_profit)}
            </div>
          </div>
          {/* <!-- END: Own Vehicle Profit --> */}

          {/* <!-- BEGIN: Supplier Vehicle Profit--> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-yellow-200 w-1/3">
            <div class="flex-none image-fit rounded-md overflow-hidden">
              <SupplierTruckIcon width="42" height="42" className="inline" />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Supplier Vehicle Profit</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.supplier_vehicle_profit)}
            </div>
          </div>
          {/* <!-- END: Supplier Vehicle Profit --> */}
        </div>

        {/* <!-- END: Sum Values --> */}

        {/* <!-- BEGIN: Data Table --> */}
        <ReportsDataTable
          title={Title}
          header={tableData.header}
          content={tableData.content}
          onRowClick={(e, row) => {
            viewRecord(row);
          }}
          downloadFileName={Title}
          tableRef={tableRef}
          groupBy="vehicle_ownership_type"
        />
        {/* <!-- END: Data Table --> */}
      </div>
    </>
  );
}
