const Model = {
  date: "",
  slip_no: "",

  bill_ref_no: "",
  billed_date: "",

  fuel_type: "",
  filling_type: "",
  consignment: "",
  vehicle: "",
  odometer_reading: "",
  fuel_filling: "",
  supplier: "",
  quantity: "",
  unit_price: "",
  amount: "",
  payment_mode: "",
  cash: "",
  bank: "",
  is_paid_by_driver: false,
  images: [],
  status: "active",
  remarks: "",
  payment_reference_number: "",
  // created_by: "",
  // updated_by: "",
  // organization: "",
  current_odometer_reading: "",
};

const ValidationErrorModel = {
  date: "",
  slip_no: "",

  bill_ref_no: "",
  billed_date: "",

  fuel_type: "",
  filling_type: "",
  consignment: "",
  vehicle: "",
  odometer_reading: "",
  fuel_filling: "",
  supplier: "",
  quantity: "",
  unit_price: "",
  amount: "",
  payment_mode: "",
  cash: "",
  bank: "",
  is_paid_by_driver: "",
  payment_reference_number: "",
  current_odometer_reading: "",
};

export { Model };
export { ValidationErrorModel };
