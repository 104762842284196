import React from "react";
import PropTypes from "prop-types";

import {
  getColorPalettes,
  getHoverColorPalettes,
  formatCurrency,
  toFloat,
} from "../../../functions/common";

// Chart
import { Pie } from "react-chartjs-2";

export default function Component(props) {
  const { className, id, name, onClick, data, ...rest } = props;
  let total_amount = 0;
  // Pie Chart - Income

  let list = data?.expenses_list;

  var labels = [];
  var dataset = [];

  // Pushing Supplier Payment
  labels.push("Supplier Payments");
  dataset.push(data?.supplier_payments || 0);
  total_amount += data?.supplier_payments || 0;

  for (var i in list) {
    labels.push(list[i]?.name);
    dataset.push(list[i]?.value);
    total_amount += list[i]?.value || 0;
  }

  var chartData = {
    labels: labels,
    datasets: [
      {
        data: dataset,
        backgroundColor: getColorPalettes(),
        hoverBackgroundColor: getHoverColorPalettes(),
        // borderWidth: 5,
        // borderColor: "#303953",
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className={className} id={id}>
      <div class="flex">
        <h2 class="font-medium truncate">Total Expense</h2>
        <h2 class="font-medium truncate text-theme-1 ml-auto">
          {formatCurrency(toFloat(total_amount, 0))}
        </h2>
      </div>

      {/* <!--BEGIN: Chart --> */}
      <div
        id="expense_chart"
        style={{
          position: "relative",
          margin: "auto",
          width: "100%",
          height: "90%",
        }}
      >
        <Pie data={chartData} options={options} />
      </div>
      {/* <!--END: Chart --> */}
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "w-full h-full",
  id: "total_expense",
  name: "",
  data: {},
  onClick() {},
};
