import React from "react";
import PropTypes from "prop-types";

export default function Component(props) {
  const { data, className, id, inlineDisplay, displayBadge, size, ...rest } =
    props;

  const TruckTypeBadge = (props) => {
    const { data, id, className, displayBadge, size, textColor, ...rest } =
      props;

    // const localClassName = displayBadge
    //   ? "py-1 px-1 rounded-full text-sm  bg-gray-200 text-gray-600 items-center"
    //   : "text-sm text-gray-600";

    const localClassName = "text-sm text-gray-300";

    return (
      <p className={localClassName} id={"vehicle_type_" + id}>
        {data?.vehicle_type}
      </p>
    );
  };

  return (
    <div className={className} id={id}>
      <div className="font-medium leading-none break-all font-medium text-white">
        {data?.registration_number}
      </div>

      {data?.vehicle_type && (
        <TruckTypeBadge
          data={data?.vehicle_type}
          id={id}
          displayBadge={displayBadge}
        />
      )}
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  inlineDisplay: PropTypes.bool,
  displayBadge: PropTypes.bool,
  size: PropTypes.string,
  // Functions
  onClick: PropTypes.func,
};

Component.defaultProps = {
  // Variables
  className: "",
  inlineDisplay: false,
  displayBadge: false,
  size: "", // sm,lg,xl
  // Functions
  onClick() {},
};
