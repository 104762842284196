import React, { useState, useEffect } from "react";
import { formatCurrency, toFloat } from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import { storageServices } from "../../functions/storage";

export default function Payables(props) {
  const Title = "Payables Report";
  const tableRef = React.createRef();
  const { data,onRowClick } = props;
  const [state, setState] = useState({
    total_amount: 0,
    total_paid: 0,
    total_balance: 0,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData(data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);
    let tableData = await mapTableData(data);
    setTableData(tableData);
    await calculateTotal(data);
    setIsProcessing(false);
  }

  function calculateTotal(data) {
    //Calculations
    let totalAmount = 0;
    let totalPaid = 0;
    let totalBalance = 0;

    for (var i in data) {
      totalAmount = totalAmount + toFloat(data[i]?.total);
      totalPaid = totalPaid + toFloat(data[i]?.amount_paid);
      totalBalance = totalBalance + toFloat(data[i]?.balance_amount);
    }
    console.log("totalAmount:", totalAmount);
    let prevState = { ...state };
    prevState.total_amount = totalAmount;
    prevState.total_paid = totalPaid;
    prevState.total_balance = totalBalance;
    setState(prevState);
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Supplier",
        field: "supplier.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Payable",
        field: "total",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Paid",
        field: "amount_paid",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Balance",
        field: "balance_amount",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(row) {}

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Sum Values --> */}
        <div id="sum_values" className="w-full flex justify-between space-x-4">
          {/* <!-- BEGIN: Total Payable --> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-blue-100 w-1/3">
            <div class="flex-none image-fit rounded-md overflow-hidden">
              <i
                class="fa fa-shopping-cart text-theme-1 text-xl"
                aria-hidden="true"
              ></i>
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Total Payable</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.total_amount)}
            </div>
          </div>
          {/* <!-- END: Total Payable --> */}

          {/* <!-- BEGIN: Total Paid --> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-green-100 w-1/3">
            <div class="flex-none image-fit rounded-md overflow-hidden">
              <i
                class="fa fa-check text-theme-9 text-xl"
                aria-hidden="true"
              ></i>
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Total Paid</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.total_paid)}
            </div>
          </div>
          {/* <!-- END: Total Paid --> */}

          {/* <!-- BEGIN: Total Balance --> */}
          <div class="box px-4 py-4 mb-3 flex items-center bg-red-100 w-1/3">
            <div class="flex-none image-fit rounded-md overflow-hidden">
              <i
                class="fa fa-google-wallet text-theme-6 text-xl"
                aria-hidden="true"
              ></i>
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Total Balance</div>
            </div>
            <div class="py-1 px-2 rounded-full text-xl font-medium">
              {formatCurrency(state?.total_balance)}
            </div>
          </div>
          {/* <!-- END: Total Balance --> */}
        </div>

        {/* <!-- END: Sum Values --> */}

        {/* <!-- BEGIN: Data Table --> */}
        <ReportsDataTable
          title={Title}
          header={tableData.header}
          content={tableData.content}
          onRowClick={(e, row) => {
            onRowClick(row?._row?.data);
          }}
          downloadFileName={Title}
          tableRef={tableRef}
        />
        {/* <!-- END: Data Table --> */}
      </div>
    </>
  );
}
