import React, { useState } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";

const Model = { email: "" };

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  // State
  const [state, setState] = useState(Model);

  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .forgotPassword(query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
        size="lg"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            {isProcessing && <ProcessLoader />}
            <form
              onSubmit={onSubmit}
              id="forget_password-form"
              className="grid grid-cols-12 gap-4 gap-y-3"
            >
              <div className="col-span-12">
                <label htmlFor="email" className="form-label">
                  Email Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder=""
                  onChange={changeInput}
                  value={state.email}
                  required
                />
              </div>
              <div className="col-span-12 text-gray-600">
                <p>
                  * Please enter the email address registered with the
                  application.
                </p>
              </div>
              <input
                type="submit"
                id="submit-forgot-password-form"
                className="hidden"
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          <label
            type="button"
            className="btn btn-primary"
            htmlFor="submit-forgot-password-form"
          >
            Reset Password
          </label>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Forgot Password",
  callBack() {},
};
