import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "react-tabulator/lib/styles.css"; // default theme
// import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import { ReactTabulator } from "react-tabulator";

const _ = require("lodash");

export default function CustomDataTable(props) {
  const [state, setState] = useState({
    field: "",
    type: "like",
    value: "",
    advance_search: false,
    advance_search_value: "",
  });
  const {
    header,
    content,
    onRowClick,
    options,
    className,
    printTable,
    printHeader,
    printFooter,
    exportTable,
    search,
    filter,
    filterFields,
    downloadFileName,
    title,
    selectableRows,
    selectableRowsHighlight,
    onSelectedRowsChange,
    tableRef,
    groupBy,
  } = props;

  useEffect(() => {}, []);

  // <!---FILTER-->
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }
  function filterTable(field, type, value) {
    const table = tableRef.current.table;
    table.setFilter(field, type, value);
  }

  function advanceFilterTable(value) {
    const table = tableRef.current.table;
    table.setFilter(matchAny, { value: value });
  }

  function matchAny(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter

    var match = false;
    // var keys = filterParams.keys;

    for (var key in data) {
      // if (data[key] == filterParams.value) {

      let dataString = data[key];
      // var bool  = _.strContains(dataString, filterParams.value);
      try {
        match = _.includes(
          data[key].toLowerCase(),
          filterParams.value.toLowerCase()
        );
      } catch (e) {}

      // if (match) {
      //   match = true;
      //   console.log("match:", match, dataString);
      // }

      // if (data[key] == filterParams.value) {
      //   match = true;
      // }
    }

    return match;
  }

  function onAdvanceFilterSubmit(event) {
    event.preventDefault();
    advanceFilterTable(state.advance_search_value);
  }

  function onFilterSubmit(event) {
    event.preventDefault();
    filterTable(state.field, state.type, state.value);
  }

  function resetFilter(event) {
    event.preventDefault();
    const table = tableRef.current.table;
    let prevState = { ...state };
    prevState.field = "";
    prevState.value = "";
    setState(prevState);
    table.clearFilter();
  }

  function resetAdvancedFilter(event) {
    event.preventDefault();
    const table = tableRef.current.table;
    let prevState = { ...state };
    prevState.advance_search_value = "";
    setState(prevState);
    table.clearFilter();
  }

  // <!---FILTER-->

  return (
    <div className="overflow-x-auto overflow-y-auto scrollbar-hidden border">
      <ReactTabulator
        ref={tableRef}
        columns={header}
        data={content}
        options={{
          movableRows: false,
          movableColumns: true,
          groupBy: groupBy,
          placeholder: "No records found",
          layout: "fitColumns",
          // layout:"fitDataFill",
          responsiveLayout: "collapse",
          pagination: "local",
          paginationSize: 10,
          paginationSizeSelector: [10, 30, 40],
          downloadDataFormatter: (data) => data,
          downloadReady: (fileContents, blob) => blob,
          printAsHtml: true,
          printCopyStyle: true,
          printStyled: true,
          printFooter: printFooter,
          printHeader: `<h3>${printHeader}</h3>`,
        }}
        rowClick={onRowClick}
        className={className}
        tooltips={true}
        placeholder={"No data found."}
      />
    </div>
  );
}

CustomDataTable.propTypes = {
  header: PropTypes.array,
  content: PropTypes.array,
  filter: PropTypes.bool,
  printTable: PropTypes.bool,
  downloadFileName: PropTypes.string,
  printHeader: PropTypes.string,
  printFooter: PropTypes.string,
  exportTable: PropTypes.bool,
  filterFields: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  groupBy: PropTypes.string,
  options: PropTypes.object,
  onRowClick: PropTypes.func,
  tableRef: PropTypes.any,
};

CustomDataTable.defaultProps = {
  header: [],
  content: [],
  filter: true,
  search: true,
  printTable: false,
  exportTable: true,
  filterFields: [],
  title: "",
  printHeader: "",
  downloadFileName: "data",
  printFooter: "",
  groupBy: "",
  className: "",
  onRowClick() {},
  tableRef: React.createRef(),
};
