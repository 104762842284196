import React from "react";
import PropTypes from "prop-types";

export default function TyreModeBadge(props) {
  const { mode } = props;
  const className = {
    "": "",
    inventory:
      "px-3 py-2 bg-theme-14 dark:bg-dark-5 dark:text-gray-300 text-theme-10 rounded font-medium",
    vehicle:
      "px-3 py-2 bg-theme-9 dark:bg-dark-5 dark:text-gray-300 text-white rounded font-medium mr-3",
    scrap:
      "px-3 py-2 bg-theme-11 dark:bg-dark-5 dark:text-gray-300 text-dark rounded font-medium mr-3",
  };

  const title = {
    "": "",
    inventory: "In Inventory",
    vehicle: "In Vehicle",
    scrap: "Scrapped",
  };
  return <div className={className[mode]}>{title[mode]}</div>;
}

TyreModeBadge.propTypes = {
  //Variables
  mode: PropTypes.string,
  // Functions
  onClick: PropTypes.func,
};

TyreModeBadge.defaultProps = {
  // Variables
  mode: "",
  // Functions
  onClick() {},
};
