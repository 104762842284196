import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// HTML Components
import Error from "../../../components/error/error";
import Container from "../../../components/container";
import Card from "../../../components/card/custom";
import BackButton from "../../../components/pageElements/backButton";
import SimpleTab from "../../../components/tabs/simpleTab";
import EditIconButton from "../../../components/htmlElements/editIconButton";
import EditButton from "../../../components/htmlElements/editButton";
import CreateNewButton from "../../../components/htmlElements/createNewButton";
import CustomDataTable from "../../../components/tables/customDataTable";
import ConsStatusBadge from "../../../components/consignment/statusBadge";
import InLineLabel from "../../../components/htmlElements/inLineLabel";
import Dot from "../../../components/htmlElements/dot";
import AddNewButton from "../../../components/htmlElements/addNewButton";
// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Functions
import * as api from "../../../api_service/api";
import { resp } from "../../../functions/responseHandler";
import {
  localDate,
  formatOdoReading,
  formatLitres,
  matchData,
  isDefined,
  formatKm,
  formatCurrency,
  trimConsNum,
  formatMileage,
} from "../../../functions/common";
import { storageServices } from "../../../functions/storage";
import { reactFormatter } from "react-tabulator";
// External Components
import FormMaster from "../../../components/master/vehicle";
import TyreMasterForm from "../../../components/master/tyre";
import TyreRemovalForm from "../../../components/master/tyre/removalForm";
import TyreFitmentForm from "../../../components/master/tyre/fitmentForm";
import TyrePositionChangeForm from "../../../components/master/tyre/positionChangeForm";
import FuelSlipForm from "../../../components/master/fuel_slip";
import OdometerUpdateForm from "../../../components/master/vehicle/odometerUpdateForm";
import FuelUpdateForm from "../../../components/master/vehicle/fuelUpdateForm";

// Graphical View

// import VehicleTyreView from "../../../components/tyre/vehicleTyreView";
import VehicleTyreView from "../../../components/tyre/vehicleTyreView";
// Model
import { Model } from "./model";
import { Model as TyreModel } from "../../Fleet/Tyre/model";

// Images
import { ReactComponent as VehicleDocumentIcon } from "../../../assets/images/svg/vehicle-document.svg";
import { ReactComponent as WheelIcon } from "../../../assets/images/svg/wheel.svg";
import { ReactComponent as TruckIcon } from "../../../assets/images/svg/truck.svg";

export default function View(props) {
  // States
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [record_id, setRecordId] = useState();
  const [tyresList, setTyresList] = useState([]);
  const [tyreData, setTyreData] = useState({ ...TyreModel });
  const [tripsList, setTripsList] = useState([]);
  const [fuelHistoryList, setFuelHistoryList] = useState([]);
  const [state, setState] = useState({ ...Model });
  const [localUser, setLocalUser] = useState();
  const [currentTab, setCurrentTab] = useState("documents");

  const { vehicle_id } = useParams();

  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));

  // <!-- BEGIN: Odometer Update Modal -->

  const [odometerUpdateModal, setOdometerUpdateModal] = useState({
    show: false,
    id: "",
    vehicle: {},
  });

  function openOdometerUpdateModal() {
    let newState = { ...odometerUpdateModal };
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;
    newState["id"] = vehicleData;
    newState["vehicle"] = vehicleData;
    newState["show"] = true;
    setOdometerUpdateModal(newState);
  }
  const closeOdometerUpdateModal = () =>
    setOdometerUpdateModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function odometerUpdateModalCallBack() {
    let newState = { show: false, id: "", vehicle: {} };
    setOdometerUpdateModal(newState);
    loadData(record_id);
  }
  // <!-- END: Odometer Update Modal -->

  // <!-- BEGIN: Fuel Update Modal -->

  const [fuelUpdateModal, setFuelUpdateModal] = useState({
    show: false,
    id: "",
    vehicle: {},
  });

  function openFuelUpdateModal() {
    let newState = { ...fuelUpdateModal };
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;
    newState["id"] = vehicleData;
    newState["vehicle"] = vehicleData;
    newState["show"] = true;
    setFuelUpdateModal(newState);
  }
  const closeFuelUpdateModal = () =>
    setFuelUpdateModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function fuelUpdateModalCallBack() {
    let newState = { show: false, id: "", vehicle: {} };
    setFuelUpdateModal(newState);
    loadData(record_id);
  }
  // <!-- END: Fuel Update Modal -->

  // <!-- BEGIN: Fuel Slip Modal -->

  const [fuelSlipModal, setFuelSlipModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openFuelSlipModal = () =>
    setFuelSlipModal((prevState) => ({ ...prevState, show: true }));
  const closeFuelSlipModal = () =>
    setFuelSlipModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function fuelSlipModalCallBack() {
    let newState = { show: false, title: "", action: "", id: "" };
    setFuelSlipModal(newState);
    loadFuelHistory(record_id);
  }
  // <!-- END: Fuel Slip Modal -->

  // <!-- BEGIN: Tyre Master Modal -->

  const [tyreModal, setTyreModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openTyreModal = () =>
    setTyreModal((prevState) => ({ ...prevState, show: true }));
  const closeTyreModal = () =>
    setTyreModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function tyreModalCallBack() {
    let newState = { show: false, title: "", action: "", id: "" };
    setTyreModal(newState);
    loadTyres(record_id);
  }
  // <!-- END: Tyre Master Modal -->

  // <!-- BEGIN: Tyre Position Change Modal -->

  const [tyrePositionChangeModal, setTyrePositionChangeModal] = useState({
    show: false,
    id: "",
    tyre: {},
    vehicle: {},
  });

  function changeTyrePosition(tyre_data) {
    let newState = { ...tyrePositionChangeModal };
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;

    newState["id"] = tyre_data?._id;
    newState["tyre"] = tyre_data;
    newState["vehicle"] = vehicleData;
    newState["show"] = true;
    setTyrePositionChangeModal(newState);
  }
  const closeTyrePositionChangeModal = () =>
    setTyrePositionChangeModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function tyrePositionChangeModalCallBack() {
    let newState = { show: false, id: "", vehicle: {}, tyre: {} };
    setTyrePositionChangeModal(newState);
    loadTyres(record_id);
  }
  // <!-- END: Tyre Position Change Modal -->

  // <!-- BEGIN: Tyre Removal Modal -->

  const [tyreRemomvalModal, setTyreRemovalModal] = useState({
    show: false,
    id: "",
    tyre: {},
    vehicle: {},
  });

  function removeTyre(tyre_data) {
    let newState = { ...tyreRemomvalModal };
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;

    newState["id"] = tyre_data?._id;
    newState["tyre"] = tyre_data;
    newState["vehicle"] = vehicleData;
    newState["show"] = true;
    setTyreRemovalModal(newState);
  }
  const closeTyreRemovalModal = () =>
    setTyreRemovalModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function tyreRemovalModalCallBack() {
    let newState = { show: false, id: "", vehicle: {}, tyre: {} };
    setTyreRemovalModal(newState);
    loadTyres(record_id);
  }
  // <!-- END: Tyre Removal Modal -->

  // <!-- BEGIN: Tyre Fitment Modal -->
  const [tyreFitmentModal, setTyreFitmentModal] = useState({
    show: false,
    id: "",
    tyre: {},
    vehicle: {},
  });

  function openTyreFitmentModal(tyrePosition) {
    let newState = { ...tyreRemomvalModal };
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;

    newState["id"] = "";
    newState["vehicle"] = vehicleData;
    newState["position"] = tyrePosition;
    newState["show"] = true;
    setTyreFitmentModal(newState);
  }
  const closeTyreFitmentModal = () =>
    setTyreFitmentModal((prevState) => ({ ...prevState, show: false }));

  // Reload Data
  function tyreFitmentModalCallBack() {
    let newState = { show: false, id: "", vehicle: {}, tyre: {} };
    setTyreFitmentModal(newState);
    loadTyres(record_id);
  }
  // <!-- END: Tyre Fitment Modal -->

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };

    if (vehicle_id) {
      loadData(vehicle_id);
      getUser();
    } else {
      // SetError("No Valid Vehicle Id Found.");
    }
  }, []);

  function loadData(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`vehicle/${id}`).then(
      async (result) => {
        const data = result.data;

        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let matchedData = await matchData(state, data);

    // * Engine Details
    if (
      isDefined(matchedData?.RC_chasisNo) ||
      isDefined(matchedData?.RC_engineNo)
    ) {
      matchedData["engine_details"] = true;
    }
    await setState(matchedData);
  }

  const DetailsListItem = (props) => {
    const { label, value } = props;

    return (
      <div className="truncate sm:whitespace-normal flex items-center mb-2">
        {/* <i
          className="fa fa-circle mr-2 text-gray-500 text-sm"
          aria-hidden="true"
        ></i> */}
        <span className="text-gray-700">{label}</span>
        <span className="ml-2 font-bold">{value}</span>
      </div>
    );
  };

  // Edit Record
  async function editRecord() {
    let newState = {
      show: true,
      title: `Edit Vehicle`,
      action: "edit",
      id: record_id,
    };
    setModal(newState);
  }

  function modalCallBack() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData(record_id);
  }

  const ValidityItemDisplay = (props) => {
    const { id, label, value } = props;

    return (
      <div className="relative flex items-center p-5" id={id}>
        <div className="w-8 h-8 image-fit">
          <VehicleDocumentIcon
            // width="20"
            // height="20"
            className="inline"
            fill="currentColor"
          />
        </div>
        <div className="ml-4 mr-auto">
          <div className="font-medium text-base">{localDate(value)}</div>
          <div className="text-gray-600">{label}</div>
        </div>
      </div>
    );
  };

  /////////// FUNCTIONS DECLARATION ///////////

  // <!-- BEGIN : Tyre Data //
  function loadTyres(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`tyre/vehicle/${id}`).then(
      async (result) => {
        const data = result.data;
        console.log("data:", data);
        let mappedData = await mapTyresData(data.data);

        setTyresList(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  function mapTyresData(data) {
    return data;
  }

  // Create New Record
  async function addTyre() {
    let newState = {
      show: true,
      title: `Add Tyre`,
      action: "create",
      id: "",
    };
    setTyreModal(newState);
  }

  // View Record
  async function viewTyre(id) {
    let newState = {
      show: true,
      title: `View Tyre`,
      action: "edit",
      id: id,
    };
    setTyreModal(newState);
  }

  // Edit Record
  async function editTyre(id) {
    let newState = {
      show: true,
      title: `Edit Tyre`,
      action: "edit",
      id: id,
    };
    setTyreModal(newState);
    return null;
  }

  // <!-- END : Tyre Data //

  const TyreListItemRow = (props) => {
    const { _id, serial_no, brand, sub_brand, type, position, kms_covered } =
      props.data;

    return (
      <tr id={_id} key={_id}>
        <td className="">
          <div className="flex items-center">
            <WheelIcon
              width="32"
              height="32"
              className="inline"
              fill="currentColor"
            />
            <div className="ml-2 font-medium whitespace-nowrap">{position}</div>
          </div>
        </td>

        <td className="">
          <a
            href={void 0}
            className="edit text-theme-1 font-medium whitespace-nowrap"
          >
            {serial_no}
          </a>
          <div className="text-gray-600 whitespace-nowrap mt-0.5">{type}</div>
        </td>
        <td>
          <a href={void 0} className="font-medium whitespace-nowrap">
            {brand}
          </a>
          <div className="text-gray-600  whitespace-nowrap mt-0.5">
            {sub_brand}
          </div>
        </td>

        <td className="w-40">
          <div className="flex items-center justify-center">
            {formatKm(kms_covered)}
          </div>
        </td>
        <td className="table-report__action w-56">
          {isDefined(_id) ? (
            <div
              className="flex justify-center items-center"
              id="valid-action-buttons"
            >
              <a
                type="button"
                className="cursor-pointer flex items-center text-theme-1 btn btn-outline-secondary"
                href={void 0}
                onClick={(event) => {
                  event.preventDefault();
                  editTyre(_id);
                }}
              >
                <i className="fa fa-pencil mr-1" aria-hidden="true"></i>
                Edit
              </a>

              <a
                type="button"
                className="cursor-pointer flex items-center text-theme-11 ml-2 btn btn-outline-secondary"
                href={void 0}
                onClick={(event) => {
                  event.preventDefault();
                  changeTyrePosition(props.data);
                }}
              >
                <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                Roatate
              </a>

              <a
                type="button"
                className="cursor-pointer flex items-center text-theme-6 ml-2 btn btn-outline-secondary"
                href={void 0}
                onClick={(event) => {
                  event.preventDefault();
                  removeTyre(props.data);
                }}
              >
                <i className="fa fa-times mr-1" aria-hidden="true"></i>
                Remove
              </a>
            </div>
          ) : (
            <a
              type="button"
              className="cursor-pointer flex items-center text-theme-1 btn btn-outline-secondary"
              href={void 0}
              onClick={(event) => {
                event.preventDefault();
                openTyreFitmentModal();
              }}
            >
              <i className="fa fa-plus mr-1" aria-hidden="true"></i>
              Fit Tyre
            </a>
          )}
        </td>
      </tr>
    );
  };

  function onChangeTab(value) {
    setCurrentTab(value);

    switch (value) {
      case "documents":
        break;
      case "tyres":
        loadTyres(record_id);
        break;
      case "trips":
        loadTrips(record_id);
        break;
      case "fuel_history":
        loadFuelHistory(record_id);
        break;
      default:
        break;
    }
  }

  function viewTripRecord(id) {
    var url = `/admin#/consignment/view/edit/${id}`;
    window.location.href = url;
  }

  // Trips Data
  function loadTrips(id) {
    setIsProcessing(true);
    api.getService(`consignment/vehicle/${id}`).then(
      async (result) => {
        const data = result.data;
        let mappedData = await mapTripData(data.data);
        setTripsList(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  // <!-- BEGIN : Fuel History Data //
  function loadFuelHistory(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`fuel/slip/vehicle/${id}`).then(
      async (result) => {
        const data = result.data;
        let mappedData = await mapFuelHistoryData(data.data);

        setFuelHistoryList(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  function mapTripData(data, user) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        hozAlign: "center",
        vertAlign: "middle",
        resizable: false,
        headerSort: false,
        print: false,
        download: false,
      },
      {
        title: "Date",
        field: "consignment_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
        width: 120,
        sorter: "date",
      },
      {
        title: "Cons. No.",
        field: "consignment_id",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 120,

        cellClick: function (e, cell) {
          var rowData = cell.getData();
          viewTripRecord(rowData._id);
        },

        formatter: function (cell) {
          return `<div class="text-theme-1 font-medium">${trimConsNum(
            cell?.getValue()
          )}</div>
         `;
        },
      },

      {
        title: "Client",
        field: "client.name",
        vertAlign: "middle",
        headerFilter: true,
        headerSort: false,
        headerFilterPlaceholder: "Search...",
        formatter: "textarea",
      },

      {
        title: "Origin",
        field: "origin.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        formatter: "textarea",
      },
      {
        title: "Destination",
        field: "destination.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        // width: 150,
        formatter: "textarea",
      },
      {
        title: "Driver",
        field: "driver.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        // width: 150,
        formatter: "textarea",
      },
      {
        title: "Status",
        field: "status",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        download: false,
        formatter: reactFormatter(<ConsStatusBadge module="tabulator" />),
        reponsive: 2,
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    };
    return response;
  }

  function mapFuelHistoryData(data, user) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "date",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Slip No.",
        field: "slip_no",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Odo Reading",
        field: "odometer_reading",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
      },
      {
        title: "Fuel Station",
        field: "supplier.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Litres Filled",
        field: "quantity",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return `<p>${cell?.getValue()} L</p>`;
        },
      },
      {
        title: "Rate/Litre",
        field: "unit_price",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Amount",
        field: "amount",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    };
    return response;
  }

  // Create New Record
  async function addFuelSlip() {
    let vehicleData = { ...state };
    vehicleData["_id"] = record_id;
    let newState = {
      show: true,
      title: `Fuel Slip`,
      action: "create",
      id: "",
      vehicle: vehicleData,
    };
    setFuelSlipModal(newState);
  }

  // View Record
  async function viewFuelSlip(id) {
    let newState = {
      show: true,
      title: `Fuel Slip`,
      action: "edit",
      id: id,
    };
    setFuelSlipModal(newState);
  }

  // <!-- END : Fuel History Data //

  function handleTyreOnClick(data) {
    if (data?.active) {
      setTyreData(data);
    } else {
      openTyreFitmentModal(data?.position);
    }
  }

  if (error) {
    return <Error />;
  } else {
    return (
      <Container>
        {/* <!-- BEGIN: Fuel Update Modal --> */}
        {fuelUpdateModal.show && (
          <FuelUpdateForm
            show={fuelUpdateModal.show}
            id={fuelUpdateModal.id}
            action={"create"}
            vehicle={fuelUpdateModal.vehicle}
            onHide={closeFuelUpdateModal}
            callBack={fuelUpdateModalCallBack}
            request_module="vehicle-view"
          />
        )}
        {/* <!-- END: Fuel Update Modal --> */}

        {/* <!-- BEGIN: Odometer Update Modal --> */}
        {odometerUpdateModal.show && (
          <OdometerUpdateForm
            show={odometerUpdateModal.show}
            id={odometerUpdateModal.id}
            action={"create"}
            vehicle={odometerUpdateModal.vehicle}
            onHide={closeOdometerUpdateModal}
            callBack={odometerUpdateModalCallBack}
            request_module="vehicle-view"
          />
        )}
        {/* <!-- END: Odometer Update Modal --> */}

        {/* <!-- BEGIN: Fuel Slip Modal --> */}
        {fuelSlipModal.show && (
          <FuelSlipForm
            show={fuelSlipModal.show}
            id={fuelSlipModal.id}
            action={fuelSlipModal?.action}
            vehicle={fuelSlipModal.vehicle}
            onHide={closeFuelSlipModal}
            callBack={fuelSlipModalCallBack}
            request_module="vehicle-view"
          />
        )}
        {/* <!-- END: Fuel Slip Modal --> */}

        {/* <!-- BEGIN: Tyre Master Modal --> */}
        {tyreModal.show && (
          <TyreMasterForm
            show={tyreModal.show}
            action={tyreModal.action}
            title={tyreModal.title}
            onHide={closeTyreModal}
            callBack={tyreModalCallBack}
            id={tyreModal.id}
          />
        )}
        {/* <!-- END:Tyre Master Modal --> */}

        {/* <!-- BEGIN: Tyre Removal Modal --> */}
        {tyreRemomvalModal.show && (
          <TyreRemovalForm
            show={tyreRemomvalModal.show}
            id={tyreRemomvalModal.id}
            action="create"
            tyre={tyreRemomvalModal.tyre}
            vehicle={tyreRemomvalModal.vehicle}
            onHide={closeTyreRemovalModal}
            callBack={tyreRemovalModalCallBack}
          />
        )}
        {/* <!-- END:Tyre Removal Modal --> */}

        {/* <!-- BEGIN: Tyre Position Change Modal --> */}
        {tyrePositionChangeModal.show && (
          <TyrePositionChangeForm
            show={tyrePositionChangeModal.show}
            id={tyrePositionChangeModal.id}
            action="create"
            tyre={tyrePositionChangeModal.tyre}
            vehicle={tyrePositionChangeModal.vehicle}
            onHide={closeTyrePositionChangeModal}
            callBack={tyrePositionChangeModalCallBack}
          />
        )}
        {/* <!-- END:Tyre Position Change Modal --> */}

        {/* <!-- BEGIN: Tyre Fitment Modal --> */}
        {tyreFitmentModal.show && (
          <TyreFitmentForm
            show={tyreFitmentModal.show}
            id={tyreFitmentModal.id}
            action="create"
            vehicle={tyreFitmentModal.vehicle}
            position={tyreFitmentModal.position}
            onHide={closeTyreFitmentModal}
            callBack={tyreFitmentModalCallBack}
          />
        )}
        {/* <!-- END:Tyre Fitment Modal --> */}

        {isProcessing ? <ProcessLoader /> : null}

        {/* <!-- BEGIN: Modal --> */}
        {modal.show && (
          <FormMaster
            show={modal.show}
            action={modal.action}
            title={modal.title}
            onHide={closeModal}
            callBack={modalCallBack}
            id={modal.id}
          />
        )}
        {/* <!-- END: Modal --> */}

        {/* <!-- BEGIN: Header --> */}

        <Container.Header>
          <Container.Title>
            {state?.registration_number}
            <EditButton className="ml-2" onClick={editRecord} />
          </Container.Title>
        </Container.Header>

        {/* <!-- END: Header --> */}

        {/* <!-- BEGIN: Body --> */}
        <Container.Body>
          {/* <!-- BEGIN: Top Info --> */}
          <div className="intro-y box px-5 pt-5 mt-5">
            <div className="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
              <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                <div className="w-20 h-20 flex-none image-fit relative">
                  <TruckIcon />

                  {/* <img
                    alt="Vehicle Image"
                    className=""
                    src="dist/images/profile-14.jpg"
                  /> */}
                </div>
                <div className="ml-5">
                  <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg">
                    {state?.registration_number}
                  </div>
                  <div className="text-gray-600">
                    {state?.vehicle_type?.vehicle_type}
                  </div>
                </div>
              </div>
              <div className="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
                <div className="flex flex-col justify-center items-center lg:items-start mt-4">
                  <DetailsListItem
                    id="no_of_tyres"
                    label="No. of Tyres"
                    value={state?.no_of_tyres}
                  />
                  <DetailsListItem
                    id="fuel_tank_capacity"
                    label="Fuel Tank"
                    value={`${formatLitres(state?.fuel_tank_capacity)}`}
                  />

                  <DetailsListItem
                    id="RC_chasisNo"
                    label="Chassis number"
                    value={state?.RC_chasisNo}
                  />
                  <DetailsListItem
                    id="RC_engineNo"
                    label="Engine number"
                    value={state?.RC_engineNo}
                  />
                </div>
              </div>
              <div className="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0">
                <div className="w-1/3 text-center rounded-md py-3">
                  <div className="font-medium text-theme-1 dark:text-theme-10 text-lg flex items-center justify-center">
                    {formatLitres(state?.fuel_balance)}
                    <EditIconButton
                      id="fuel_update_button"
                      onClick={openFuelUpdateModal}
                    />
                  </div>
                  <div className="text-gray-600">Fuel Balance</div>
                </div>

                <div className="w-1/3 text-center rounded-md py-3">
                  <div className="font-medium text-theme-1 dark:text-theme-10 text-lg">
                    {formatOdoReading(state?.odometer_reading)}
                    <EditIconButton
                      id="odometer_update_button"
                      onClick={openOdometerUpdateModal}
                    />
                  </div>
                  <div className="text-gray-600">Odometer</div>
                </div>
                {state?.avg_mileage && (
                  <div className="w-1/3 text-center rounded-md py-3">
                    <div className="font-medium text-theme-1 dark:text-theme-10 text-lg">
                      {formatMileage(state?.avg_mileage)}
                    </div>
                    <div className="text-gray-600">
                      Mileage <small>(avg.)</small>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <!-- BEGIN: Tab Switcher --> */}
            <SimpleTab>
              <SimpleTab.Tab
                id="documents"
                active={currentTab === "documents" ? true : false}
                onClick={(e) => onChangeTab("documents")}
              >
                Documents
              </SimpleTab.Tab>
              <SimpleTab.Tab
                id="trips"
                active={currentTab === "trips" ? true : false}
                onClick={(e) => onChangeTab("trips")}
              >
                Trips
              </SimpleTab.Tab>
              <SimpleTab.Tab
                id="tyres"
                active={currentTab === "tyres" ? true : false}
                onClick={(e) => onChangeTab("tyres")}
              >
                Tyres
              </SimpleTab.Tab>
              <SimpleTab.Tab
                id="fuel_history"
                active={currentTab === "fuel_history" ? true : false}
                onClick={(e) => onChangeTab("fuel_history")}
              >
                Fuel History
              </SimpleTab.Tab>
            </SimpleTab>
            {/* <!-- END: Tab Switcher --> */}
          </div>
          {/* <!-- END: Top Info --> */}

          <div className="chat__box box mt-5">
            {/* <!-- BEGIN: Documents List --> */}
            <div className="chat grid grid-cols-12 gap-5 ">
              {currentTab === "documents" && (
                <>
                  <div className="md:col-span-4 sm:col-span-6">
                    <ValidityItemDisplay
                      id="fc_expiry"
                      label="FC Expiry"
                      value={state?.FC_valid_to}
                    />
                  </div>

                  <div className="md:col-span-4 sm:col-span-6">
                    <ValidityItemDisplay
                      id="insurance_expiry"
                      label="Insurance Expiry"
                      value={state?.Insurance_valid_to}
                    />
                  </div>

                  <div className="md:col-span-4 sm:col-span-6">
                    <ValidityItemDisplay
                      id="goods_permit_expiry"
                      label="Goods Permit Expiry"
                      value={state?.Goods_valid_to}
                    />
                  </div>
                </>
              )}
            </div>
            {/* <!-- END: Documents List  --> */}

            {/* <!-- BEGIN: Tyres List --> */}
            {currentTab === "tyres" && (
              <div className="chat grid grid-cols-12 gap-x-5 p-3">
                <div className="col-span-12 mb-1 flex">
                  <button
                    id="fitment_button"
                    className="btn btn-primary ml-auto"
                    onClick={(event) => {
                      event.preventDefault();
                      openTyreFitmentModal();
                    }}
                  >
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i> Fit
                    Tyre
                  </button>
                </div>

                <div className="col-span-12">
                  <table className="table table-report sm:mt-2">
                    <tbody>
                      {tyresList.map((item, index) => {
                        return <TyreListItemRow data={item} />;
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {/* <!-- END: Tyres List --> */}

            {/* <!-- BEGIN: Fuel History List --> */}
            {currentTab === "fuel_history" && (
              <div className="chat grid grid-cols-12 gap-5 p-3">
                <div className="col-span-12 flex">
                  <button
                    id="fuel_slip_button"
                    className="btn btn-primary ml-auto"
                    onClick={(event) => {
                      event.preventDefault();
                      addFuelSlip();
                    }}
                  >
                    <i className="fa fa-plus mr-1" aria-hidden="true"></i>{" "}
                    Create Fuel Slip
                  </button>
                </div>

                <div className="col-span-12">
                  <CustomDataTable
                    header={fuelHistoryList.header}
                    content={fuelHistoryList.content}
                    onRowClick={(e, row) => {
                      viewFuelSlip(row._row.data._id);
                    }}
                    filter={true}
                    filterFields={fuelHistoryList.filterFields}
                  />
                </div>
              </div>
            )}
            {/* <!-- END: Fuel History List  --> */}

            {/* <!-- BEGIN: Trips List --> */}
            {currentTab === "trips" && (
              <div className="chat grid grid-cols-12 gap-5 p-3">
                <div className="col-span-12">
                  <CustomDataTable
                    header={tripsList.header}
                    content={tripsList.content}
                    onRowClick={(e, row) => {
                      viewTripRecord(row._row.data._id);
                    }}
                    filter={true}
                    filterFields={tripsList.filterFields}
                  />
                </div>
              </div>
            )}
            {/* <!-- END: Trips List  --> */}
          </div>
        </Container.Body>
        {/* <!-- END: Body --> */}
      </Container>
    );
  }
}
