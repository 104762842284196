import React, { useState, useEffect } from "react";
import download from "downloadjs";

// HTML Components
import Container from "../../components/container";
import Card from "../../components/card/custom";
import Form from "../../components/form/custom";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";

// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { getFileName } from "../../functions/common";
import { storageServices } from "../../functions/storage";

const templateOptions = {
  consignment_report: {
    dateFilter: false,
    fileName: "Consingment Data",
    url: "report/consignment/generate/dump/excel",
  },
};

export default function Grid() {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [isProcessing, setIsProcessing] = useState(false);
  const [pageTitle, setPageTitle] = useState();
  //State variables
  const [showOnScreenTemplate, setShowOnScreenTemplate] = useState();

  const [state, setState] = useState({
    date_filter: "",
    date_type: "",
    start_date: "",
    end_date: "",
    template: "",
    show_report: false,
    download_option: "",
  });
  const [localUser, setLocalUser] = useState();
  const [reportData, setReportData] = useState();

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    setDefaults();
  }, []);

  function setDefaults() {
    let prevState = { ...state };
    prevState.date_filter = "today";
    prevState.start_date = new Date();
    prevState.end_date = new Date();
    prevState.template = "consignment_report";
    prevState.download_option = "show_on_screen";
    setState(prevState);
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // Date Filter Changes
  function changeDateFilter(event) {
    const { name, value } = event.target;

    let prevState = { ...state };
    prevState[name] = value;
    // setState((prevState) => ({ ...prevState, [name]: value }));

    // let prevState = state;
    // prevState.date_filter = value;
    var date = new Date();

    let startDate = date;
    let endDate = date;
    let dateType = "date";

    switch (value) {
      case "today":
        startDate = date;
        endDate = date;
        dateType = "date";
        break;

      case "yesterday":
        startDate = new Date(date.getDate() - 1);
        endDate = new Date(date.getDate() - 1);
        dateType = "date";
        break;

      case "this_month":
        startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        endDate = date;
        dateType = "date";
        break;

      case "last_month":
        startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "date";
        break;

      case "last_3_months":
        startDate = new Date(date.getFullYear(), date.getMonth() - 3, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "month";
        break;

      case "last_6_months":
        startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        dateType = "month";
        break;

      // case "last_year":
      //   startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
      //   endDate = new Date(date.getFullYear(), date.getMonth(), 0);
      //   filter = { start_date: startDate, end_date: endDate };
      //   loadHighlights(filter);

      case "custom_date":
        break;

      default:
        break;
    }

    prevState.start_date = startDate;
    prevState.end_date = endDate;
    prevState.date_type = dateType;

    setState(prevState);
  }

  // Form Actions

  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    let url = templateOptions[state?.template]?.url;
    let query = { start_date: state?.start_date, end_date: state?.end_date };
    const option = { responseType: "blob" };
    api
      .reportsPostService(url, query, option)
      .then(async (response) => {
    
        let fileName = getFileName(
          templateOptions[state?.template]?.fileName,
          "xlsx"
        );
        await downloadFile(response, fileName);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function downloadFile(response, fileName, type) {
    const content = response.headers["content-type"];
    return download(response.data, fileName, content);
  }

  /////////// FUNCTIONS DECLARATION ///////////

  return (
    <>
      <Container>
        {isProcessing ? <ProcessLoader /> : null}
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title backButton={true}>Data Export</Container.Title>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/*  <!-- BEGIN: Body -->  */}
        <Container.Body>
          {/* <!-- BEGIN: Card --> */}
          <Card className="mt-5">
            {/* <!-- BEGIN: Card Body --> */}
            <Card.Body>
              {/* <!-- BEGIN: Form --> */}
              <Form onSubmit={onSubmit}>
                {/* <!-- BEGIN: 1st Row --> */}
                <Form.Row className="justify-center">
                  {/* <!-- BEGIN: Date Field --> */}
                  <Form.Col>
                    <Form.Label>Date</Form.Label>
                    <select
                      className="form-select"
                      name="date_filter"
                      value={state.date_filter}
                      onChange={changeDateFilter}
                    >
                      <option value="today" defaultValue>
                        Today
                      </option>
                      <option value="yesterday">Yesterday</option>

                      <option value="this_month">This month</option>
                      <option value="last_month">Last month</option>
                      <option value="last_3_months">Last 3 months</option>
                      <option value="last_6_months">Last 6 months</option>
                      {/* <option value="last_year">Last year</option> */}
                      <option value="custom_date">Custom date</option>
                    </select>
                  </Form.Col>

                  {/* <!-- BEGIN:  Custom Date -->*/}
                  {state?.date_filter === "custom_date" && (
                    <>
                      <Form.Col>
                        <Form.Label>From</Form.Label>
                        <input
                          type="date"
                          className="form-control"
                          id="start_date"
                          name="start_date"
                          onChange={changeInput}
                          value={state?.start_date}
                          required
                        />
                      </Form.Col>

                      <Form.Col>
                        <Form.Label>To</Form.Label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_date"
                          name="end_date"
                          onChange={changeInput}
                          value={state?.end_date}
                          required
                        />
                      </Form.Col>
                    </>
                  )}
                  {/* <!-- END:  Custom Date -->*/}
                  {/* <!-- END: Date Field --> */}

                  {/* <!-- BEGIN: Template Field --> */}
                  <Form.Col>
                    <Form.Label>Category</Form.Label>
                    <select
                      className="form-select"
                      name="template"
                      value={state.template}
                      onChange={changeInput}
                    >
                      <option value="consignment_report">
                        Consignment Report
                      </option>
                    </select>
                  </Form.Col>
                  {/* <!-- END: Template Field --> */}

                  <div className="inline-block align-baseline mt-6">
                    <label
                      type="button"
                      className="btn btn-lg btn-primary"
                      htmlFor="export_data"
                    >
                      Export
                      <i
                        class="fa fa-chevron-circle-right ml-2"
                        aria-hidden="true"
                      ></i>{" "}
                    </label>
                  </div>
                </Form.Row>
                {/* <!-- END: 1st Row --> */}
                <input type="submit" id="export_data" className="hidden" />
              </Form>
              {/* <!-- END: Form --> */}
            </Card.Body>
            {/* <!-- END: Card Body --> */}
          </Card>
          {/* <!-- END: Card --> */}
        </Container.Body>
        {/*  <!-- END: Body -->  */}
      </Container>
    </>
  );
}
