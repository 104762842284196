// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// LOADERS
import ProcessLoader from "../../preloader/processLoader";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

// FUNCTIONS
import { formatTypeSearch, htmlDate,mongoUTC } from "../../../functions/common";
import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";
import CurrencyInput from "../../htmlElements/currencyInput";


const Model = {
  agreement: "", //Agreement Id
  origin: "",
  destination: "",
  vehicle_type: "",
  effect_from: "",
  effect_end: "",
  cost: "",
  unit_type: "", // Vehicle Type | KG based | MT Based
  status: "active",
};

export default function Component(props) {
  // Defaults
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });
  const [defaultState, setDefaultState] = useState({
    location: [],
    vehicle_type: [],
  });

  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();

  useEffect(() => {
    handleUserAction(props);
  }, []);

  function getDefaults(props) {
    let newState = { ...Model };
    newState["agreement"] = props?.agreement_id;
    newState["effect_from"] = htmlDate(props?.agreement_data?.start_date) || "";
    newState["unit_type"] = "vehicle_type";
    newState["status"] = "active";
    return newState;
  }

  // <!--- BEGIN: FUNCTIONS -->
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        let newState = getDefaults(props);
        setState(newState);
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
    }
  }

  function mapData(data) {
    data["effect_from"] = htmlDate(data["effect_from"]);
    data["effect_end"] = htmlDate(data["effect_end"]);
    return data;
  }

  function prepareData(data) {
    data["origin"] = data["origin"]["_id"];
    data["destination"] = data["destination"]["_id"];
    data["vehicle_type"] = data["vehicle_type"]["_id"];
    data["effecitve_from"] = mongoUTC(data["effecitve_from"]);
    return data;
  }
  // <!--- END: FUNCTIONS -->

  // <!--- BEGIN: FORM CHANGE FUNCTIONS -->
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // <!--- END: FORM CHANGE FUNCTIONS -->

  // <!--- BEGIN: API CALLS -->
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`client_agreements/transaction/${id}`).then(
      async (result) => {
        const data = result.data;
        let mappedData = await mapData(data.data);
        setState(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });

    api
      .postService("client_agreements/transaction", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        const createdId = response?.data?.id;
        props.callBack(createdId);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });
    api
      .putService(`client_agreements/transaction/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }
  // <!--- END: API CALLS -->

  function mapDefaultStateData(key, data) {
    return data;
  }

  async function loadDefaultMetrics(
    event,
    key,
    reload = false,
    data = {},
    state_key
  ) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      let mappedData = await mapDefaultStateData(key, prevDefState);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: mappedData,
      }));
      setState((prevState) => ({
        ...prevState,
        [state_key]: data,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "location":
            url = "master/location/status/active";
            await fetchDefaultMetrics(url, key);
            break;

          case "vehicle_type":
            url = "master/vehicle";
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.Error(error);
      });
  }

  // TYPE AHEAD CHANGES
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  return (
    <div
      key="vehicle_type_modal"
      id="vehicle_type_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            id="client-agreeement-transaction-form"
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="origin" className="form-label required">
                Origin
              </label>
              <Typeahead
                clearButton
                filterBy={["name"]}
                id="origin"
                name="origin"
                onFocus={(event) => {
                  loadDefaultMetrics(event, "location");
                }}
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "origin");
                }}
                options={defaultState?.location}
                // labelKey={(option) => `${option?.name || ""}`}
                labelKey="name"
                selected={formatTypeSearch(state?.origin || "")}
                inputProps={{ required: true }}
                placeholder="Select Origin"
                disabled={mode.view}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="destination" className="form-label required">
                Destination
              </label>
              <Typeahead
                clearButton
                filterBy={["name"]}
                id="destination"
                name="destination"
                onFocus={(event) => {
                  loadDefaultMetrics(event, "location");
                }}
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "destination");
                }}
                options={defaultState?.location}
                // labelKey={(option) => `${option?.name || ""}`}
                labelKey="name"
                selected={formatTypeSearch(state?.destination || "")}
                inputProps={{ required: true }}
                placeholder="Select Destination"
                disabled={mode.view}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="vehicle_type" className="form-label required">
                Vehicle Type
              </label>
              <Typeahead
                clearButton
                filterBy={["vehicle_type"]}
                id="vehicle_type"
                name="vehicle_type"
                onFocus={(event) => {
                  loadDefaultMetrics(event, "vehicle_type");
                }}
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "vehicle_type");
                }}
                options={defaultState?.vehicle_type}
                // labelKey={(option) => `${option?.name || ""}`}
                labelKey="vehicle_type"
                selected={formatTypeSearch(state?.vehicle_type || "")}
                inputProps={{ required: true }}
                placeholder="Select Vehicle Type"
                disabled={mode.view}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="cost" className="form-label required">
                Cost
              </label>
              <CurrencyInput
                className="form-control"
                id="cost"
                name="cost"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.cost}
                required
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="effect_from" className="form-label required">
                Effective From
              </label>
              <input
                type="date"
                className="form-control"
                id="effect_from"
                name="effect_from"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state.effect_from}
                required
              />
            </div>

            <input
              type="submit"
              id="save_client_agreement_transaction"
              className="hidden"
            />
            <input
              type="submit"
              id="update_client_agreement_transaction"
              className="hidden"
            />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_client_agreement_transaction"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_client_agreement_transaction"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Client Agreement - Item",
  action: "view",
  callBack() {},
};
