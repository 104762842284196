import React, { useState } from "react";
import PropTypes from "prop-types";

import { formatCurrency, localDate } from "../../../functions/common";

//Components
import StageTab from "../../tabs/stageTab";

export default function Component(props) {
  const { className, id, name, onClick, data, ...rest } = props;
  const [currentTab, setCurrentTab] = useState("business");

  return (
    <div className={className} id={id}>
      <div class="flex items-center">
        <h2 class="font-medium truncate">Recent Transactions</h2>
      </div>

      <div className="mx-auto flex">
        <StageTab>
          <StageTab.Tab
            id="recent-transactions-business"
            active={currentTab === "business" ? true : false}
            onClick={(e) => setCurrentTab("business")}
          >
            Business
          </StageTab.Tab>{" "}
          <StageTab.Tab
            id="recent-transactions-operational"
            active={currentTab === "operational" ? true : false}
            onClick={(e) => setCurrentTab("operational")}
          >
            Operational
          </StageTab.Tab>
        </StageTab>
      </div>

      <div id="transactions_list">
        {/* <!BEGIN: Business Transactions --> */}
        {currentTab === "business" && (
          <>
            {/* <!-- BEGIN: Transaction Card -- > */}
            {data?.operational.map((item, index) => {
              return (
                <div className="intro-x" key={index} index={index}>
                  <div className="box shadow px-5 py-3 mb-3 flex items-center zoom-in">
                    <div className="w-10 h-10 flex-none image-fit rounded-full overflow-hidden">
                      <img alt="" src="images/profile-pic-default.png" />
                    </div>
                    <div className="ml-4 mr-auto">
                      <div className="font-medium">
                        {item?.payment_to_type === "supplier"
                          ? item?.supplier?.name
                          : item?.payment_to_type === "petrolbunk"
                          ? item?.petrolbunk?.name
                          : item?.payment_to_type === "vehicle"
                          ? item?.vehicle?.registration_number
                          : item?.payment_from_type === "client"
                          ? item?.client?.name
                          : ""}
                      </div>
                      <div className="text-gray-600  mt-0.5">
                        {localDate(item?.createdAt)}
                      </div>
                    </div>

                    {item?.transaction_type == "payable" && (
                      <div className="text-theme-6">
                        -&nbsp;
                        {formatCurrency(item?.transaction_amount)}
                      </div>
                    )}

                    {item?.transaction_type == "receivable" && (
                      <div className="text-theme-9">
                        +&nbsp;
                        {formatCurrency(item?.transaction_amount)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {/* <!-- END: Transaction Card -- > */}
            {data?.operational?.length > 0 && (
              <a
                href="/admin#/accounts/transaction_history"
                target="blank"
                className="intro-x w-full block text-center rounded-md py-3 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
              >
                View More
              </a>
            )}
          </>
        )}
        {/* <!END: Business Transactions --> */}

        {/* <!BEGIN: Operational Transactions --> */}
        {currentTab === "operational" && (
          <>
            {/* <!-- BEGIN: Transaction Card -- > */}
            {data?.business.map((item, index) => {
              return (
                <div className="intro-x" key={index} index={index}>
                  <div className="box px-5 py-3 mb-3 flex items-center zoom-in">
                    <div className="w-10 h-10 flex-none image-fit rounded-full overflow-hidden">
                      <img alt="" src="images/profile-pic-default.png" />
                    </div>
                    <div className="ml-4 mr-auto">
                      <div className="font-medium">
                        {item?.amount_type === "income"
                          ? item?.income_type?.name
                          : item?.amount_type === "expense"
                          ? item?.expense_type?.name
                          : ""}
                      </div>
                      <div className="text-gray-600  mt-0.5">
                        {localDate(item?.createdAt)}
                      </div>
                    </div>

                    {item?.amount_type == "expense" && (
                      <div className="text-theme-6">
                        -&nbsp;
                        {formatCurrency(item?.amount)}
                      </div>
                    )}

                    {item?.amount_type == "income" && (
                      <div className="text-theme-9">
                        +&nbsp;
                        {formatCurrency(item?.amount)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {/* <!-- END: Transaction Card -- > */}
            {data?.business?.length > 0 && (
              <a
                href="/admin#/accounts/income_expense"
                target="blank"
                className="intro-x w-full block text-center rounded-md py-3 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
              >
                View More
              </a>
            )}
          </>
        )}
        {/* <!END: Operational Transactions --> */}
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.number,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "",
  id: "recent_transactions",
  name: "",
  data: 0,
  onClick() {},
};
