import React, { useState, useEffect } from "react";
import { Route, HashRouter } from "react-router-dom";

import { TopBar } from "../components/topbar";
import MenuBar from "../components/menubar";

//Pages
import Home from "../view/home";
import Databases from "../view/databases/grid";
import Organizations from "../view/organizations/grid";

// Functions
import { storageServices } from "../../functions/storage";
async function logOut() {
  await storageServices.resetCurrentUser();
  window.location.href = "login";
}

async function checkLogin() {
  var user = await storageServices.getCurrentUser();
  if (user === null || typeof user === "undefined") {
    logOut();
  }
}

export default function Admin() {
  checkLogin();
  const [localUser, setLocalUser] = useState();
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
  }, []);

  return (
    <HashRouter>
      <div className="body-content main">
        <TopBar localUser={localUser} />
        <MenuBar localUser={localUser} />
      </div>
      <Route path="/home" component={Home} />
      <Route path="/organizations" component={Organizations} />
      <Route exact path="/databases" component={Databases} />
      <Route exact path="/dashboard" component={Databases} />
    </HashRouter>
  );
}
