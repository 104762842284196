import { Route, HashRouter } from "react-router-dom";
import React, { useEffect } from "react";

// Layouts
import Login from "./layouts/login";
import Admin from "./layouts/admin";
import NotFound from "./layouts/error/notFound";

function App() {
  useEffect(() => {});
  return (
    <>
      <HashRouter>
        <Route path="/" exact component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/admin" render={(props) => <Admin {...props} />} />
        <Route path="*" component={NotFound} />
      </HashRouter>
    </>
  );
}

export default App;
