import React, { useState, useEffect } from "react";
// Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import StatusBadge from "../../../components/pageElements/statusBadge";
import ActionButtons from "../../../components/pageElements/actionButtons";
import FormMaster from "../../../components/user_management/user"; // Form
import StageTab from "../../../components/tabs/stageTab";
// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import { htmlToString, navToPage } from "../../../functions/common";
import { reactFormatter } from "react-tabulator";
// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";

export default function Master() {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders

  const [isProcessing, setIsProcessing] = useState(false);
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });

  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  const [state, setState] = useState({
    application_user_switch: false,
    status: "all",
  });

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      loadData(localUserData, "all");
    };
    getUser();
  }, []);

  // Fetching Grid Data From API
  async function loadData(localUserData = localUser, status = state.status) {
    setIsProcessing(true);
    api.getService(`user/status/${status}`).then(
      async (result) => {
        const data = result.data;
        console.log("user fetch", data);
        let tableData = await mapTableData(data.userAll, localUserData);
        setTableData(tableData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data, localUserData) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Name",
        field: "fullname",
        responsive: 0,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter(cell, formatterParams) {
          return `<div>
                            <div className="font-medium whitespace-nowrap">${
                              cell.getData().fullname
                            }</div>
                         
                        </div>`;
        },
      },
      {
        title: "Designation",
        field: "designation.name",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Email",
        field: "email",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Phone",
        field: "phone",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      // {
      //   title: "Created At",
      //   field: "createdAt",
      //   headerSort: true,
      //           vertAlign: "middle",
      //   formatter: function (cell) {
      //     return localDate(cell?.getValue());
      //   },
      // },
      // {
      //   title: "Updated At",
      //   field: "updatedAt",
      //   headerSort: true,
      //           vertAlign: "middle",
      //   formatter: function (cell) {
      //     return localDate(cell?.getValue());
      //   },
      // },

      {
        title: "Status",
        field: "status",
        headerSort: true,
        vertAlign: "right",

        formatter: function (cell) {
          return htmlToString(<StatusBadge text={cell?.getValue()} />);
        },
      },

      {
        title: "Actions",
        field: "",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",

        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(<ActionButtonsLookup user={localUserData} />),
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [{ label: "Name", value: "name" }],
    };
    return response;
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    const user = props.user;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e) => {
            editRecord(cellData);
          },
          disabled: cellData?.default ? true : false,
        }}
        suspendButton={{
          show: cellData?.status === "active" ? true : false,
          disabled: cellData?._id === user?.userId ? true : false,
          onClick: (e) => {
            deleteRecord(cellData);
          },
        }}
        activateButton={{
          // show: false,
          show: cellData?.status === "inactive" ? true : false,
          onClick: (e) => {
            activateRecord(cellData);
          },
        }}
      />
    );
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData();
  }
  // Create New Record
  async function addRecord() {
    console.log("add");
    let newState = {
      show: true,
      title: `Add User`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(data, row) {
    console.log("row data:", data);
    let newState = {
      show: true,
      title: `View User`,
      action: "view",
      id: data?._row?.data?._id,
    };
    setModal(newState);
  }
  // Edit Record
  async function editRecord(data) {
    let newState = {
      show: true,
      title: `Edit User`,
      action: "edit",
      id: data?._id,
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to suspend this user?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }
  // RE-ACTIVATION
  function activateRecord(data) {
    alert.confirmActivation({
      text: "Are you sure want to activate this user?",
      onConfirm: function () {
        onActivate(data);
      },
    });
  }
  ////// COMMON FUNCTIONS //////

  function onActivate(data) {
    setIsProcessing(true);
    var query = {
      status: "active",
    };
    api
      .putService(`user/${data._id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`user/${data?._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onChangeCheckbox(event) {
    const { name } = event.target;
    let newValue = !state[name];
    setState((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    if (newValue) {
      let status = "application_user";

      setState((prevState) => ({
        ...prevState,
        status: status,
      }));

      loadData(localUser, status);
    } else {
      let status = "all";
      loadData(localUser, status);
    }
  }

  return (
    <>
      <>
        <Container>
          {/* <!-- BEGIN: Modal --> */}
          {modal.show && (
            <FormMaster
              show={modal.show}
              action={modal.action}
              title={modal.title}
              onHide={closeModal}
              callBack={reloadData}
              id={modal.id}
            />
          )}
          {/* <!-- END: Modal --> */}

          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title className="text-lg font-medium">
              Users
            </Container.Title>

            {/* <!-- BEGIN: Stage Tabs --> */}
            <div className="ml-auto flex">
              <StageTab>
                <StageTab.Tab
                  id="user-stage-users"
                  active={true}
                  onClick={(e) => navToPage("/admin#/user_management/users")}
                >
                  Users
                </StageTab.Tab>{" "}
                <StageTab.Tab
                  id="user-stage-user_roles"
                  active={false}
                  onClick={(e) =>
                    navToPage("/admin#/user_management/user_roles")
                  }
                >
                  Roles
                </StageTab.Tab>
                <StageTab.Tab
                  id="user-stage-user_roles"
                  active={false}
                  onClick={(e) =>
                    navToPage("/admin#/user_management/designation")
                  }
                >
                  Designation
                </StageTab.Tab>
              </StageTab>
            </div>
            {/* <!-- END: Stage Tabs --> */}
            <Container.HeaderActions>
              {" "}
              <div className="form-check mr-2">
                <label
                  className="form-check-label ml-0 sm:ml-2 mr-2"
                  htmlFor="application_users_switch"
                >
                  Application Users
                </label>
                <input
                  id="application_users_switch"
                  name="application_users_switch"
                  data-target="#input"
                  className="form-check-switch mr-3"
                  type="checkbox"
                  checked={state.apply_client_credits}
                  onChange={onChangeCheckbox}
                />
              </div>
              <a
                className="btn btn-primary shadow-md mr-2"
                href={void 0}
                onClick={addRecord}
              >
                Add New
              </a>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing ? (
              <ProcessLoader />
            ) : (
              <CustomDataTable
                header={tableData.header}
                content={tableData.content}
                onRowClick={(e, row) => {
                  viewRecord(row);
                }}
                filter={true}
                filterFields={tableData.filterFields}
              />
            )}
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
