import React, { useState, useEffect } from "react";
// Components
// import Error from "../../components/error/error";
import Container from "../../components/container";
// Functions
import * as api from "../../api_service/api";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../components/notification/alert";
import { resp } from "../../functions/responseHandler";
// Components
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { isDefined } from "../../functions/common";

export default function Component() {
  const ModuleName = "FAQ";
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [state, setState] = useState();
  const [accordionContent, setAccordionContent] = useState([]);
  const [currentMenu, setCurrentMenu] = useState({ name: "", category: "" });

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    loadData();
  }, []);

  // <!--- BEGIN: API CALLS -->

  // Fetching Grid Data From API
  async function loadData() {
    setIsProcessing(true);
    api.appGetService("app/help_guide").then(
      async (result) => {
        const data = result.data;
        console.log("data:", data);
        await mapData(data.data);

        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // <!--- END: API CALLS -->

  // <!--- BEGIN: FUNCTIONS -->
  function mapData(data) {
    let sideMenuItems = [];
    let obj = {};
    for (var i in data) {
      if (!isDefined(obj[data[i].category])) {
        obj[data[i].category] = [];
        sideMenuItems.push({ name: data[i].name, category: data[i].category });
      }
      obj[data[i].category].push(data[i]);
    }

    // Setting Initial Data
    if (sideMenuItems.length > 0) {
      let initialMenuItem = sideMenuItems[0];
      setCurrentMenu(initialMenuItem);
      setAccordionContent(obj[initialMenuItem?.category]);
    }
    setState(obj);
    setMenuItems(sideMenuItems);
    return null;
  }
  // <!--- END: FUNCTIONS -->

  function onChangeMenu(item) {
    setCurrentMenu({ name: item?.name, category: item?.category });
    setAccordionContent(state[item?.category]);
  }

  const MenuItemComponent = (props) => {
    const { id, active, item } = props;
    var varClassName = active
      ? "rounded-lg bg-theme-1 text-white font-medium"
      : "";
    return (
      <a
        id={id}
        className={"cursor-pointer flex items-center px-4 py-2 " + varClassName}
        href={void 0}
        onClick={(e) => {
          onChangeMenu({ name: item?.name, category: item?.category });
        }}
      >
        {props.children}
      </a>
    );
  };

  return (
    <>
      {isProcessing && <ProcessLoader />}
      <Container>
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title>FAQ</Container.Title>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/*  <!-- BEGIN: Body -->  */}
        <Container.Body>
          <div className="grid grid-cols-12 gap-3 ">
            {/*  <!-- BEGIN: FAQ Menu-->  */}
            <div className="col-span-12 lg:col-span-4 xl:col-span-3">
              <div className="box px-4 pb-3 pt-5 mt-3">
                {menuItems.map((item, index) => {
                  return (
                    <MenuItemComponent
                      id={item?.category}
                      key={item?.category}
                      active={item?.category === currentMenu?.category}
                      item={item}
                    >
                      {item?.name}
                    </MenuItemComponent>
                  );
                })}
              </div>
            </div>
            {/*  <!-- END:FAQ Menu-->  */}

            {/*  <!-- BEGIN:FAQ Content -->  */}
            <div className="col-span-12 lg:col-span-8 xl:col-span-9">
              <div className="box lg:mt-3">
                <div className="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 className="font-medium text-base mr-auto">
                    {currentMenu?.name}
                  </h2>
                </div>

                {/* <!-- BEGIN: Accordion --> */}

                <div
                  id="faq-accordion"
                  className="accordion accordion-boxed p-5"
                >
                  <Accordion>
                    {accordionContent.map((item, index) => {
                      return (
                        <AccordionItem key={item?.category + index}>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {item?.question}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>{item?.answer}</p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                </div>
                {/* <!-- END: Accordion --> */}
              </div>
            </div>
            {/*  <!-- END:FAQ Content -->  */}
          </div>
        </Container.Body>

        {/*  <!-- END: Body -->  */}
      </Container>
    </>
  );
}
