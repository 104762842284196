import React, { useState, useEffect } from "react";
// LAYOUTS
import CustomDataTable from "../../../components/tables/customDataTable";
import Preloader from "../../../components/preloader/preloader";
import ProcessLoader from "../../../components/preloader/processLoader";
import Error from "../../../components/error/error";
import { resp } from "../../../functions/responseHandler";
import { htmlDateTime } from "../../../functions/common";
import * as api from "../../../api_service/api";
import { Modal } from "react-bootstrap";
export default function Journal() {
  const [data_list, setDataList] = useState({
    header: [],
    content: [],
    title: "",
  });
  const [modal_html_data, setModalHtmlData] = useState({
    title: "",
    content: <></>,
  });
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
  };
  const [tab, setTab] = useState({
    key: "payables",
    activeClass:
      "nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0 active",
    inActiveClass: "nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0",
  });

  useEffect(() => {
    setIsLoaded(true);
    loadData("payables");
  }, []);

  async function loadData(type = "payables") {
    setIsProcessing(true);
    let oldTab = { ...tab };
    oldTab.key = type;
    setTab(oldTab);
    api.getService(`${type}/journal/all`).then(
      async (result) => {
        console.log("result", result);
        const data = result.data;
        if (type === "payables") {
          await setPayablesTableData(data.data);
        }
        if (type === "receivables") {
          await seReceivablesTableData(data.data);
        }
      },

      (error) => {
        console.log("error", error);
        setIsLoaded(true);
        setError(error);
      }
    );
  }

  async function setPayablesTableData(data) {
    setIsProcessing(true);
    const columns = [
      {
        name: "S.no",
        selector: "count",
        sortable: true,
        center: true,
        cell: (row, index) => <>{index + 1}</>,
      },

      {
        name: "From",
        selector: "payment_from",
        sortable: true,
        center: true,
        cell: (row) => <>{row?.payment_from?.name}</>,
      },
      {
        name: "To",
        selector: "payment_to",
        sortable: true,
        center: true,
        cell: (row) => <>{row?.payment_to?.name}</>,
      },
      {
        name: "Amount",
        selector: "total_transaction_amount",
        sortable: true,
        center: true,
        cell: (row) => (
          <>₹ {(row?.total_transaction_amount).toLocaleString()}</>
        ),
      },
      // {
      //   name: "Voucher No.",
      //   selector: "payment_reference_number",
      //   sortable: true,
      //   center: true,
      // },
      {
        name: "Created at",
        selector: "created_at",
        sortable: true,
        center: true,
        cell: (row) => <>{htmlDateTime(row.createdAt)}</>,
      },

      {
        name: "",
        selector: "",
        cell: (row) => (
          <div className="dropdown">
            <button
              className="btn btn-default "
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="caret">
                <i className="fa fa-ellipsis-v text-muted" aria-hidden="true"></i>
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={(e) => {
                    viewJournal(row, "payables");
                  }}
                >
                  View{" "}
                  <i
                    className="fa fa-eye text-secondary float-right"
                    aria-hidden="true"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        ),
        maxWidth: "5px",
      },
    ];

    await setDataList({
      header: columns,
      content: data,
      title: "Payables Journal",
    });
    setIsProcessing(false);
  }

  async function seReceivablesTableData(data) {
    setIsProcessing(true);
    const columns = [
      {
        name: "S.no",
        sortable: true,
        center: true,
        cell: (row, index) => <>{index + 1}</>,
      },
      {
        name: "Id",
        selector: "_id",
        sortable: true,
        center: true,
      },

      {
        name: "Received from",
        selector: "received_from",
        sortable: true,
        center: true,
        cell: (row) => <>{row?.received_from?.name}</>,
      },
      {
        name: "Received as",
        selector: "received_as",
        sortable: true,
        center: true,
        cell: (row) => <>{row?.received_as?.name}</>,
      },
      {
        name: "Amount",
        selector: "total_transaction_amount",
        sortable: true,
        center: true,
        cell: (row) => (
          <>₹ {(row?.total_transaction_amount).toLocaleString()}</>
        ),
      },
      {
        name: "Voucher No.",
        selector: "payment_reference_number",
        sortable: true,
        center: true,
      },

      {
        name: "Created at",
        selector: "created_at",
        sortable: true,
        center: true,
        cell: (row) => <>{htmlDateTime(row.createdAt)}</>,
      },

      {
        name: "",
        selector: "",
        cell: (row) => (
          <div className="dropdown">
            <button
              className="btn btn-default "
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="caret">
                <i className="fa fa-ellipsis-v text-muted" aria-hidden="true"></i>
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={(e) => {
                    viewJournal(row , "receivables");
                  }}
                >
                  View{" "}
                  <i
                    className="fa fa-eye text-primary float-right"
                    aria-hidden="true"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        ),
        maxWidth: "5px",
      },
    ];

    await setDataList({
      header: columns,
      content: data,
      title: "Receivables Journal",
    });
    setIsProcessing(false);
  }

  async function viewJournal(row, type) {
    console.log("row",row)
    setIsProcessing(true);
    api.getService(`${type}/journal/${row._id}`).then(
      async (result) => {
        console.log("result", result);
        const data = result.data;
        if (type === "payables") {
          await viewPayablesJournal(data.data);
        }
        if (type === "receivables") {
          await viewReceivablesJournal(data.data);
        }
        setIsProcessing(false);
      },

      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }
  async function viewPayablesJournal(data) {
    let html_data = (
      <>
        <form>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-4">Id</div>
              <div className="col-4">{data?._id}</div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">Date</div>
              <div className="col-4">{htmlDateTime(data?.createdAt)}</div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">From</div>
              <div className="col-4">
                {data?.payment_from?.name}({data?.payment_from?.address})
              </div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">To</div>
              <div className="col-4">{data?.payment_to?.name}</div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">Amount</div>
              <div className="col-4">
                {(data?.total_transaction_amount).toLocaleString()}
              </div>
            </div>
            <hr />

            <h4>Consignment List</h4>
            {data?.consignment && (
              <table className="table">
                <thead className="thead-dark">
                  <tr className="text-center">
                    <th scope="col">#</th>
                    <th scope="col">Id</th>
                    {/* <th scope="col">Agent</th> */}
                    <th scope="col">Journal</th>
                    <th scope="col">Amount paid</th>
                    <th scope="col">Freight</th>
                  </tr>
                </thead>
                <tbody>
                  {data.consignment.map((item, index) => {
                    return (
                      <tr className="text-center">
                        <td>{index + 1}</td>
                        <td>{item?.consignment_id?._id}</td>
                        <td>
                          ₹ {(item?.consignment_id?.journal)}
                        </td>
                        <td>
                          ₹ {(item?.amount_paid)}
                        </td>

                        <td>
                          ₹{" "}
                          {(item?.consignment_id?.freight_amount)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </form>
      </>
    );
    setModalHtmlData({ title: "View Payables Journal", content: html_data });
    openModal();
  }

  async function viewReceivablesJournal(data) {
    let html_data = (
      <>
        <form>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-4">Id</div>
              <div className="col-4">{data?._id}</div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">Date</div>
              <div className="col-4">{htmlDateTime(data?.createdAt)}</div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">Recievable type</div>
              <div className="col-4">{data?.receivable_from_type}</div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">Payment mode</div>
              <div className="col-4">{data?.payment_mode}</div>
            </div>
            <hr />
            {data?.payment_mode === "bank" && (
              <>
                <div className="row justify-content-center">
                  <div className="col-4">Payment Bank</div>
                  <div className="col-4">
                    {data?.payment_bank?.name} ({data?.payment_bank?.bank_name})
                  </div>
                </div>
                <hr />
              </>
            )}
            <div className="row justify-content-center">
              <div className="col-4">Received from</div>
              <div className="col-4">{data?.received_from?.name}</div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">Received as</div>
              <div className="col-4">{data?.received_as?.name}</div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">Amount</div>
              <div className="col-4">
              ₹ {(data?.total_transaction_amount).toLocaleString()}
              </div>
            </div>
            <hr />
            <div className="row justify-content-center">
              <div className="col-4">Voucher No.</div>
              <div className="col-4">{data?.payment_reference_number}</div>
            </div>
          </div>
        </form>
      </>
    );
    setModalHtmlData({ title: "View Receivables Journal", content: html_data });
    openModal();
  }

  function payablesFormPage() {
    window.location.href = `/admin#/accounts/payables/form/add`;
  }
  function receivablesFormPage() {
    window.location.href = `/admin#/accounts/recievables/form`;
  }

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <div className="content">
        {isProcessing && <ProcessLoader />}
        <div className="row page-titles">
          <div className="col-md-5 align-self-center">
            <h4 className="text-primary">Journal</h4>
          </div>
          <div className="col-md-7 align-self-center">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:void(0);">Accounts</a>
              </li>
              <li className="breadcrumb-item active">Journal</li>
            </ol>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card scroll">
                <div className="card-body ">
                  <div className="row w-100">
                    {tab.key === "payables" && (
                      <div className="col m-t-10 m-b-10">
                        <button
                          className="m-l-30 btn btn-outline-info float-right"
                          onClick={payablesFormPage}
                        >
                          <i className="fa fa-plus" aria-hidden="true" />
                          &nbsp;Add Payable
                        </button>
                      </div>
                    )}
                    {tab.key === "receivables" && (
                      <div className="col m-t-10 m-b-10">
                        <button
                          className="m-l-30 btn btn-outline-info float-right"
                          onClick={receivablesFormPage}
                        >
                          <i className="fa fa-plus" aria-hidden="true" />
                          &nbsp;Add Receivable
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row w-100 m-0">
                    <div className="col m-t-10">
                      <div className="p-5 bg-white rounded  mb-2">
                        <ul
                          id="myTab2"
                          role="tablist"
                          className="nav nav-tabs nav-pills with-arrow lined flex-column flex-sm-row text-center"
                        >
                          <li className="nav-item flex-sm-fill">
                            <a
                              id="payables-tab"
                              data-toggle="tab"
                              href="javascript:void(0);"
                              role="tab"
                              aria-controls="payables"
                              aria-selected="true"
                              className={
                                tab.key === "payables"
                                  ? tab.activeClass
                                  : tab.inActiveClass
                              }
                              onClick={(e) => loadData("payables")}
                            >
                              Payables
                            </a>
                          </li>
                          <li className="nav-item flex-sm-fill">
                            <a
                              id="receivables-tab"
                              data-toggle="tab"
                              href="javascript:void(0);"
                              role="tab"
                              aria-controls="receivables"
                              aria-selected="false"
                              className={
                                tab.key === "receivables"
                                  ? tab.activeClass
                                  : tab.inActiveClass
                              }
                              onClick={(e) => loadData("receivables")}
                            >
                              Receivables
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive ">
                    <CustomDataTable
                      header={data_list.header}
                      content={data_list.content}
                    />
                  </div>
                  {/* MODAL */}
                  <Modal
                    show={showModal}
                    onHide={closeModal}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{modal_html_data?.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal_html_data?.content}</Modal.Body>
                  </Modal>
                  {/* MODAL */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
