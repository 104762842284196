export function Model() {
  return {
    _id: "",
    client: "",
    client_name: "",
    cutoff_days: 0,
    approved_date: "",
    submitted_date: "",
    invoice_number: "",
    status: "",
    payment_status: "",
    address: "",
    email: "",
    invoiced_date: "",
    due_date: "",
    total: "",
    creator_id: "",
    submitter_id: "",
    approver_id: "",
    consignment: "",
    actual_weight: "",
    billable_weight: "",
    total_freight_amount: "",
    max_vehicle_capacity: "",
    additions: [],
    deductions: [],

    loading_point: {
      in_time: "",
      out_time: "",
      no_of_days: "",
      loading_charges: "",
      other_charges: "",
      remarks: "",
    },
    unloading_point: {
      in_time: "",
      out_time: "",
      no_of_days: "",
      unloading_charges: "",
      other_charges: "",
      remarks: "",
    },

    // New Fields
    client_billing_unit: "",
    client_unit_price: "",
    client_total_units: "",
    client_freight: "",
    client_addition: [],
    client_deduction: [],
    client_total_amount: 0,

    regenerate: false,
    regenerated_date: "",
    regenerated_by: "",
    cancel_notes: "",
    cancelled_date: "",
    cancelled_amount: 0,
    cancelled_by: "",
  };
}
