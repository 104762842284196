// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// LOADERS
import ProcessLoader from "../preloader/processLoader";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";
import CurrencyInput from "../htmlElements/currencyInput";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import TypeAhAddNewButton from "../htmlElements/typeAhAddNewButton";
import PercentageInput from "../../components/htmlElements/percentageInput";

// FUNCTIONS
import { resp } from "../../functions/responseHandler";
import {
  toFloat,
  htmlDate,
  matchData,
  formatTypeSearch,
} from "../../functions/common";
import * as api from "../../api_service/api";
// External Components
import ChargesTypeForm from "../master/accounts/charges";

const Model = {
  charge_type: "", // addition or deduction
  date: "",
  type: "", // charge type master
  amount: "",
  no_of_days: "",
  rate_per_day: "",
  no_of_units: "",
  rate_per_unit: "",
  unit_measure: "",
  percentage: "",
  remarks: "",
};

const fieldLabels = {
  addition_tab: {
    consignment: "Add to Freight",
    client: "Add to Bill",
    invoice: "Add to Bill",
  },
  deduction_tab: {
    consignment: "Reduce From Freight",
    client: "Reduce From Bill",
    invoice: "Reduce From Bill",
  },
};

export default function Component(props) {
  // Defaults
  const [chargeType, setChargeType] = useState("");
  const [pageTitle, setPageTitle] = useState("Charges");
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });
  // State
  const [state, setState] = useState({ ...Model });

  const [defaultState, setDefaultState] = useState({
    addition_type: [],
    deduction_type: [],
  });

  const [consData, setConsData] = useState();

  const [localState, setLocalState] = useState({
    //
    deduction_limit: 0,
    addition_limit: 0,
    disableAddition: false,
    disableDeduction: false,
  });

  const [record_id, setRecordId] = useState();

  const [chargesTypeModal, setChargesTypeModal] = useState({
    show: false,
    title: "Add Charge Type",
    action: "create",
    type: "",
    id: "",
  });

  useEffect(() => {
    handleUserAction(props);
  }, []);

  // <!-- BEGIN: Charges Type Master Modal -- >

  function addChargesType(type) {
    let prevState = { ...chargesTypeModal };
    prevState.type = type;
    prevState.action = "create";
    prevState.show = true;
    setChargesTypeModal(prevState);
  }

  const closeChargesTypeModal = () =>
    setChargesTypeModal((prevState) => ({ ...prevState, show: false }));

  function chargesFormCallBackFunction(data) {
    closeChargesTypeModal();
    let event = {};
    if (chargeType === "addition") {
      loadChargesType(event, "addition", "addition_type", true);
    }

    if (chargeType === "deduction") {
      loadChargesType(event, "deduction", "deduction_type", true);
    }
  }

  // <!-- END: Expense Type Master Modal -- >

  // <!--- BEGIN: FUNCTIONS -->
  async function handleUserAction(props) {
    let chargeType = props?.charge_type || "addition";
    let title = "Charge";
    if (chargeType === "deduction") {
      title = "Deduction";
    }
    setChargeType(chargeType);
    setConsData(props?.consData);
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setPageTitle(`Add ${title}`);
        setDefaults(props);
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        setPageTitle(`Edit ${title}`);
        loadData(props);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        setPageTitle(`View ${title}`);
        loadData(props);
        break;
      default:
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };
    prevState.charge_type = props?.charge_type || "addition";
    prevState.date = htmlDate(new Date());
    setState(prevState);

    // Setting Local State
    let prevLocalState = { ...localState };
    prevLocalState.expense_limit = toFloat(props?.expense_limit || 0);
    prevLocalState.deduction_limit = toFloat(props?.deduction_limit || 0);
    prevLocalState.disableExpense = props?.disableExpense;
    prevLocalState.disableDeduction = props?.disableDeduction;
    setLocalState(prevLocalState);

    setIsProcessing(false);
    return null;
  }

  function mapData(data) {
    return data;
  }

  function prepareData(data) {
    return data;
  }
  // <!--- END: FUNCTIONS -->

  // <!--- BEGIN: FORM CHANGE FUNCTIONS -->
  function changeInput(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState[name] = value;

    setState(prevState);
    // For Commision
    if (state?.type?.require_percentage_field && name === "amount") {
      calcPercentageFromAmount(prevState);
    }

    if (state?.type?.require_percentage_field && name === "percentage") {
      calcAmountFromPercentage(prevState);
    }

    if (
      state?.type?.require_no_of_days_field &&
      state?.type?.require_rate_per_day_field &&
      (name === "no_of_days" || name === "rate_per_day")
    ) {
      calcAmountRatePerDay(prevState);
    }

    if (
      state?.type?.no_of_units_field &&
      state?.type?.rate_per_unit_field &&
      (name === "no_of_units" || name === "rate_per_unit")
    ) {
      calcRatePerUnit(prevState);
    }
  }

  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function changeChargeType(event, type) {
    setChargeType(type);
    let prevState = { ...state };
    prevState.type = "";
    prevState.no_of_days = "";
    prevState.rate_per_day = "";
    prevState.percentage = "";
    setState(prevState);
  }

  function calcAmountFromPercentage(currentState) {
    let freight_amount = 0;
    if (props?.module === "consignment") {
      freight_amount = toFloat(consData?.charges?.consignment_freight || 0);
    }

    if (props?.module === "client") {
      freight_amount = toFloat(consData?.charges?.client_freight || 0);
    }

    // Calculating %
    let rate =
      toFloat(freight_amount) * (toFloat(currentState?.percentage) / 100);

    setState((prevState) => ({
      ...prevState,
      amount: rate,
    }));
  }

  function calcRatePerUnit(currentState) {
    let amount = currentState?.amount || 0;
    let no_of_units = currentState?.no_of_units || 0;
    let rate_per_unit = currentState?.rate_per_unit || 0;
    let unit_measure = currentState?.unit_measure || "";

    amount = no_of_units * rate_per_unit;

    try {
      amount = amount.toFixed(2);
    } catch (e) {}

    setState((prevState) => ({
      ...prevState,
      amount: amount,
    }));

    setState((prevState) => ({
      ...prevState,
      unit_measure: unit_measure,
    }));
  }

  function calcAmountRatePerDay(currentState) {
    let amount = currentState?.amount || 0;
    let no_of_days = currentState?.no_of_days || 0;
    let rate_per_day = currentState?.rate_per_day || 0;

    amount = no_of_days * rate_per_day;

    try {
      amount = amount.toFixed(2);
    } catch (e) {}

    setState((prevState) => ({
      ...prevState,
      amount: amount,
    }));
  }

  function calcPercentageFromAmount(currentState) {
    let freight_amount = 0;
    if (props?.module === "consignment") {
      freight_amount = toFloat(consData?.charges?.consignment_freight || 0);
    }

    if (props?.module === "client") {
      freight_amount = toFloat(consData?.charges?.client_freight || 0);
    }
    // Calculating %
    let percentage =
      (toFloat(currentState?.amount) / toFloat(freight_amount)) * 100;

    try {
      percentage = percentage.toFixed(2);
    } catch (e) {}

    setState((prevState) => ({
      ...prevState,
      percentage: percentage,
    }));
  }

  // <!--- END: FORM CHANGE FUNCTIONS -->

  // <!--- BEGIN: API CALLS -->

  async function loadChargesType(event, type, key, reload = false) {
    if (defaultState[key].length === 0 || reload) {
      setIsProcessing(true);
      api.getService(`master/charges/type/${type}`).then(
        async (result) => {
          const data = result.data;
          setDefaultState((prevState) => ({ ...prevState, [key]: data?.data }));
          setIsProcessing(false);
        },
        (error) => {
          console.log("error", error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        }
      );
    }
  }

  async function loadData(props) {
    let data = props?.data;
    setRecordId(props?.index);
    let matchedData = await matchData(state, data);
    matchedData["date"] = htmlDate(matchedData["date"]);
    setState(matchedData);

    // Setting Local State
    let prevLocalState = { ...localState };
    prevLocalState.addition_limit = toFloat(props?.addition_limit || 0);
    prevLocalState.deduction_limit = toFloat(props?.deduction_limit || 0);
    prevLocalState.disableAddition = props?.disableAddition;
    prevLocalState.disableDeduction = props?.disableDeduction;
    setLocalState(prevLocalState);
  }

  async function onSubmit(event) {
    event.preventDefault();
    var data = prepareData({ ...state });

    props.callBack(data, "create", record_id, chargeType, props?.module);
  }

  async function onUpdate(event) {
    event.preventDefault();
    var data = prepareData({ ...state });
    props.callBack(data, "update", record_id, chargeType, props?.module);
  }

  // <!--- END: API CALLS -->

  return (
    <div
      key="charges_form_modal"
      id="charges_form_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {chargesTypeModal?.show && (
        <ChargesTypeForm
          callBack={chargesFormCallBackFunction}
          onHide={closeChargesTypeModal}
          action={chargesTypeModal?.action}
          show={chargesTypeModal?.show}
          type={chargesTypeModal?.type}
          id={chargesTypeModal?.id}
          disableType={true}
        />
      )}
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            {/* <!--BEGIN: Tab Select --> */}
            {/* {mode?.create && (
              <div className="col-span-12">
                <div
                  className="nav nav-tabs flex-col sm:flex-row justify-center w-full"
                  role="tablist"
                >
                  {" "}
                  <a
                    id="addition-tab"
                    data-toggle="tab"
                    data-target="#addition"
                    href={void 0}
                    class={
                      chargeType === "addition"
                        ? "mx-3 text-theme-1 active cursor-pointer"
                        : "mx-3 cursor-pointer"
                    }
                    role="tab"
                    aria-controls="addition"
                    aria-selected="false"
                    aria-current={localState?.disableAddition}
                    onClick={(e) =>
                      localState?.disableAddition
                        ? e.preventDefault()
                        : changeChargeType(e, "addition")
                    }
                  >
                    {fieldLabels.addition_tab[props?.module]}
                  </a>{" "}
                  <a
                    id="deduction-tab"
                    data-toggle="tab"
                    data-target="#deduction"
                    href={void 0}
                    class={
                      chargeType === "deduction"
                        ? "mx-3 text-theme-1 active cursor-pointer"
                        : "mx-3 cursor-pointer"
                    }
                    role="tab"
                    aria-selected="false"
                    aria-current={localState?.disableDeduction}
                    onClick={(e) =>
                      localState?.disableDeduction
                        ? e.preventDefault()
                        : changeChargeType(e, "deduction")
                    }
                    disabled={localState?.disableDeduction}
                  >
                    {fieldLabels.deduction_tab[props?.module]}
                  </a>{" "}
                </div>
              </div>
            )} */}
            {/* <!--END: Tab Select --> */}

            <div className="col-span-12">
              <label htmlFor="date" className="form-label required">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.date}
                defaultValue={htmlDate(new Date())}
                required
              />
            </div>

            {chargeType === "addition" && (
              <div className="col-span-12">
                <label htmlFor="type" className="form-label required">
                  Charge Type
                </label>
                <Typeahead
                  clearButton
                  id="type"
                  name="type"
                  onFocus={(event) => {
                    loadChargesType(event, "addition", "addition_type");
                  }}
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "type");
                  }}
                  options={defaultState?.addition_type}
                  labelKey={(option) => `${option?.name}`}
                  selected={formatTypeSearch(state?.type)}
                  inputProps={{ required: true }}
                  placeholder="Charge Type"
                  disabled={mode.view}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      <TypeAhAddNewButton
                        onClick={(e) => addChargesType("addition")}
                      ></TypeAhAddNewButton>
                      {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                          {result?.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                />
              </div>
            )}

            {chargeType === "deduction" && (
              <div className="col-span-12">
                <label htmlFor="type" className="form-label required">
                  Deduction Type
                </label>
                <Typeahead
                  clearButton
                  id="type"
                  name="type"
                  onFocus={(event) => {
                    loadChargesType(event, "deduction", "deduction_type");
                  }}
                  onChange={(selected) => {
                    changeTypeHeadInput(selected, "type");
                  }}
                  options={defaultState?.deduction_type}
                  labelKey={(option) => `${option?.name}`}
                  selected={formatTypeSearch(state?.type)}
                  inputProps={{ required: true }}
                  placeholder="Deduction Type"
                  disabled={mode.view}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      <TypeAhAddNewButton
                        onClick={(e) => addChargesType("deduction")}
                      ></TypeAhAddNewButton>
                      {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                          {result?.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                />
              </div>
            )}

            {/* <!-- BEGIN : No. Of Units Calculation --> */}
            {state?.type?.no_of_units_field && (
              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="no_of_units" className="form-label">
                  {state?.type?.no_of_units_field_label}
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="no_of_units"
                  name="no_of_units"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state?.no_of_units}
                  defaultValue={0}
                />
              </div>
            )}

            {state?.type?.rate_per_unit_field && (
              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="rate_per_unit" className="form-label">
                  {state?.type?.rate_per_unit_field_label}
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="rate_per_unit"
                  name="rate_per_unit"
                  placeholder=""
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state?.rate_per_unit}
                  defaultValue={0}
                />
              </div>
            )}
            {/* <!-- END : No. Of Units Calculation --> */}

            {/*  No of Days */}

            {state?.type?.require_no_of_days_field && (
              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="no_of_days" className="form-label">
                  No. of days
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="no_of_days"
                  name="no_of_days"
                  placeholder="Halt Days"
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state?.no_of_days}
                  defaultValue={0}
                />
              </div>
            )}

            {state?.type?.require_rate_per_day_field && (
              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="rate_per_day" className="form-label">
                  Rate per Day
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="rate_per_day"
                  name="rate_per_day"
                  placeholder="Per Day Rate"
                  disabled={mode.view}
                  onChange={changeInput}
                  value={state?.rate_per_day}
                  defaultValue={0}
                />
              </div>
            )}

            {/*  Percentage */}

            {state?.type?.require_percentage_field && (
              <div className="col-span-12">
                <label htmlFor="percentage" className="form-label">
                  Percentage
                </label>

                <PercentageInput
                  className="form-control"
                  type="number"
                  name="percentage"
                  id="percentage"
                  onChange={changeInput}
                  value={state?.percentage}
                  defaultValue={0}
                  disabled={mode.view}
                />
              </div>
            )}

            <div className="col-span-12">
              <label htmlFor="name" className="form-label required">
                Amount
              </label>
              <CurrencyInput
                type="number"
                className="form-control"
                id="amount"
                name="amount"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.amount}
                required
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="remarks" className="form-label">
                Remarks
              </label>
              <textarea
                type="text"
                className="form-control"
                id="remarks"
                name="remarks"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.remarks}
              />
            </div>

            <input type="submit" id="save_charge" className="hidden" />
            <input type="submit" id="update_charge" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" &&
            (!localState?.disableExpense || !localState?.disableDeduction) && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="save_charge"
              >
                Save
              </label>
            )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_charge"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Charge",
  action: "view",
  onSave() {},
  callBack() {},
};
