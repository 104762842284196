import React, { useState, useEffect } from "react";
import { MenuList } from "./list";

import FeatherIcons from "../../assets/js/feather";
import { storageServices } from "../../functions/storage";
import { matchData, isEmpty } from "../../functions/common";

export default function MenuBar(props) {
  const parentActiveClassName = "top-menu top-menu--active";
  const parentInActiveClassName = "top-menu";

  const childActiveClassName = "top-menu";
  const childInActiveClassName = "top-menu";

  // const { localUser } = props;
  const [state, setState] = useState({
    main_menu: "",
    sub_menu1: "",
    sub_menu2: "",
    sub_menu3: "",
  });

  const [menuAccess, setMenuAccess] = useState({
    business_party: false,
    consignments: false,
    invoices: false,
    master: false,
    accounts: false,
    branch_management: false,
    user_management: false,
  });

  const [isLoaded, setIsLoaded] = useState(false);

  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    FeatherIcons();
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      await loadMenu(localUserData);
      setIsLoaded(true);
    };
    getUser();

    const location = window.location.pathname + window.location.hash;
    let item = {};

    // setState(item);
  }, []);

  async function loadMenu(localUserData) {
    let userAccess = { ...menuAccess };

    // Checking for organization
    if (!isEmpty(localUserData.organization_id)) {
      userAccess = await matchData(menuAccess, localUserData?.module_access);
    } else if (localUserData?.role?.is_admin || localUserData?.role?.is_owner) {
      userAccess.branch_management = true;
      userAccess.user_management = true;
    }
    setMenuAccess(userAccess);
  }

  function activateMenu(main_menu, sub_menu1, sub_menu2, sub_menu3) {
    let prevState = { ...state };
    prevState.main_menu = main_menu;
    prevState.sub_menu1 = sub_menu1;
    prevState.sub_menu2 = sub_menu2;
    prevState.sub_menu3 = sub_menu3;

    setState(prevState);
  }

  if (!isLoaded || typeof localUser === "undefined") {
    return <></>;
  } else {
    return (
      <>
        {/* <!-- BEGIN: Top Menu --> */}
        <nav className="top-nav">
          <ul>
            {/* <!-- BEGIN: Home */}
            <li>
              <a
                href={"/admin#/home"}
                className={
                  state?.main_menu === "home"
                    ? parentActiveClassName
                    : parentInActiveClassName
                }
                onClick={(e) => activateMenu("home", "", "", "")}
              >
                <div className="top-menu__icon">
                  <i data-feather={"home"}></i>
                </div>
                <div className="top-menu__title">Home</div>
              </a>
            </li>
            {/* <!-- END: Home */}

            {/* <!-- BEGIN: Consignments */}
            {menuAccess?.consignments && (
              <li>
                <a
                  href={"/admin#/consignment"}
                  className={
                    state?.main_menu === "consignments"
                      ? parentActiveClassName
                      : parentInActiveClassName
                  }
                  onClick={(e) => activateMenu("consignments", "", "", "")}
                >
                  <div className="top-menu__icon">
                    <i data-feather={"box"}></i>
                  </div>
                  <div className="top-menu__title">Consignments</div>
                </a>
              </li>
            )}
            {/* <!-- END: Consignments */}

            {/* <!-- BEGIN: Invoices */}
            {menuAccess?.invoices && (
              <li>
                <a
                  href={"/admin#/invoices/view"}
                  className={
                    state?.main_menu === "invoices"
                      ? parentActiveClassName
                      : parentInActiveClassName
                  }
                  onClick={(e) => activateMenu("invoices", "", "", "")}
                >
                  <div className="top-menu__icon">
                    <i data-feather={"file-text"}></i>
                  </div>
                  <div className="top-menu__title">Invoices</div>
                </a>
              </li>
            )}
            {/* <!-- END: Invoices */}

            {/* <!-- BEGIN: Master */}
            {menuAccess?.master && (
              <li>
                <a
                  href={void 0}
                  className={
                    state?.main_menu === "master"
                      ? parentActiveClassName
                      : parentInActiveClassName
                  }
                >
                  <div className="top-menu__icon">
                    <i data-feather={"list"}></i>
                  </div>
                  <div className="top-menu__title">
                    Master
                    <i
                      data-feather="chevron-down"
                      className="top-menu__sub-icon"
                    ></i>
                  </div>
                </a>
                {/* <!-- BEGIN: Submenu1*/}
                <ul>
                  {/* <!--BEGIN : Vehicle -- > */}
                  <li>
                    <a
                      href={"/admin#/master/vehicle"}
                      className={
                        state?.submenu1 === "vehicle"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) => activateMenu("master", "vehicle", "", "")}
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"truck"}></i>
                      </div>
                      <div className="top-menu__title">Vehicle</div>
                    </a>
                  </li>
                  {/* <!--END : Vehicle -- > */}

                  {/* <!--BEGIN : Driver -- > */}
                  <li>
                    <a
                      href={"/admin#/master/driver"}
                      className={
                        state?.submenu1 === "driver"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) => activateMenu("master", "driver", "", "")}
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"user"}></i>
                      </div>
                      <div className="top-menu__title">Driver</div>
                    </a>
                  </li>
                  {/* <!--END : Driver -- > */}

                  {/* <!--BEGIN : Location -- > */}
                  <li>
                    <a
                      href={"/admin#/master/location"}
                      className={
                        state?.submenu1 === "location"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) =>
                        activateMenu("master", "location", "", "")
                      }
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"map-pin"}></i>
                      </div>
                      <div className="top-menu__title">Location</div>
                    </a>
                  </li>
                  {/* <!--END : Location -- > */}

                  {/* <!--BEGIN : Accounts -- > */}

                  <li>
                    <a
                      href={void 0}
                      className={
                        state?.submenu1 === "accounting"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"dollar-sign"}></i>
                      </div>
                      <div className="top-menu__title">
                        Accounting
                        <i
                          data-feather="chevron-down"
                          className="top-menu__sub-icon"
                        ></i>
                      </div>
                    </a>
                    {/* <!-- BEGIN: Submenu2*/}
                    <ul>
                      {/* <!--BEGIN : Cash Master -- > */}
                      <li>
                        <a
                          href={"/admin#/master/cash"}
                          className={
                            state?.submenu2 === "cash"
                              ? childActiveClassName
                              : childInActiveClassName
                          }
                          onClick={(e) =>
                            activateMenu("master", "accounting", "cash", "")
                          }
                        >
                          <div className="top-menu__icon">
                            <i data-feather={"activity"}></i>
                          </div>
                          <div className="top-menu__title">Cash</div>
                        </a>
                      </li>
                      {/* <!--END : Cash Master -- > */}

                      {/* <!--BEGIN : Bank Master -- > */}
                      <li>
                        <a
                          href={"/admin#/master/bank"}
                          className={
                            state?.submenu2 === "bank"
                              ? childActiveClassName
                              : childInActiveClassName
                          }
                          onClick={(e) =>
                            activateMenu("master", "accounting", "bank", "")
                          }
                        >
                          <div className="top-menu__icon">
                            <i data-feather={"activity"}></i>
                          </div>
                          <div className="top-menu__title">Bank</div>
                        </a>
                      </li>
                      {/* <!--END : Bank Master -- > */}

                      {/* <!--BEGIN : Income Type -- > */}
                      <li>
                        <a
                          href={"/admin#/master/income"}
                          className={
                            state?.submenu2 === "income"
                              ? childActiveClassName
                              : childInActiveClassName
                          }
                          onClick={(e) =>
                            activateMenu("master", "accounting", "income", "")
                          }
                        >
                          <div className="top-menu__icon">
                            <i data-feather={"activity"}></i>
                          </div>
                          <div className="top-menu__title">Income Type</div>
                        </a>
                      </li>
                      {/* <!--END : Income Type  -- > */}

                      {/* <!--BEGIN : Expense Type -- > */}
                      <li>
                        <a
                          href={"/admin#/master/expense"}
                          className={
                            state?.submenu2 === "expense"
                              ? childActiveClassName
                              : childInActiveClassName
                          }
                          onClick={(e) =>
                            activateMenu("master", "accounting", "expense", "")
                          }
                        >
                          <div className="top-menu__icon">
                            <i data-feather={"activity"}></i>
                          </div>
                          <div className="top-menu__title">Expense Type</div>
                        </a>
                      </li>
                      {/* <!--END : Expense Type  -- > */}
                    </ul>
                    {/* <!-- END: Submenu2 -- >*/}
                  </li>
                  {/* <!--END : Accounts -- > */}
                </ul>
                {/* <!-- END: Submenu1*/}
              </li>
            )}
            {/* <!-- END: Master */}

            {/* <!-- BEGIN: Business Party -->*/}
            {menuAccess?.business_party && (
              <li>
                <a
                  href={void 0}
                  className={
                    state?.main_menu === "business_party"
                      ? parentActiveClassName
                      : parentInActiveClassName
                  }
                >
                  <div className="top-menu__icon">
                    <i data-feather={"link"}></i>
                  </div>
                  <div className="top-menu__title">
                    Business Parties
                    <i
                      data-feather="chevron-down"
                      className="top-menu__sub-icon"
                    ></i>
                  </div>
                </a>
                {/* <!-- BEGIN: Submenu1*/}
                <ul>
                  {/* <!--BEGIN : Suppliers -- > */}
                  <li>
                    <a
                      href={"/admin#/master/supplier"}
                      className={
                        state?.submenu1 === "suppliers"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) =>
                        activateMenu("business_party", "suppliers", "", "")
                      }
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"zap"}></i>
                      </div>
                      <div className="top-menu__title">Vehicle Suppliers</div>
                    </a>
                  </li>
                  {/* <!--END : Suppliers -- > */}

                  {/* <!--BEGIN : Clients -- > */}
                  <li>
                    <a
                      href={"/admin#/master/client"}
                      className={
                        state?.submenu1 === "clients"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) =>
                        activateMenu("business_party", "clients", "", "")
                      }
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"briefcase"}></i>
                      </div>
                      <div className="top-menu__title">Clients</div>
                    </a>
                  </li>
                  {/* <!--END : Clients -- > */}
                </ul>
                {/* <!-- END: Submenu1*/}
              </li>
            )}
            {/* <!-- END: Business Party -->*/}

            {/* <!-- BEGIN: Accounts -->*/}
            {menuAccess?.accounts && (
              <li>
                <a
                  href={void 0}
                  className={
                    state?.main_menu === "accounts"
                      ? parentActiveClassName
                      : parentInActiveClassName
                  }
                >
                  <div className="top-menu__icon">
                    <i data-feather={"dollar-sign"}></i>
                  </div>
                  <div className="top-menu__title">
                    Accounting
                    <i
                      data-feather="chevron-down"
                      className="top-menu__sub-icon"
                    ></i>
                  </div>
                </a>
                {/* <!-- BEGIN: Submenu1*/}
                <ul>
                  {/* <!--BEGIN : Payables -- > */}
                  <li>
                    <a
                      href={"/admin#/accounts/payables"}
                      className={
                        state?.submenu1 === "payables"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) =>
                        activateMenu("accounts", "payables", "", "")
                      }
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"trending-up"}></i>
                      </div>
                      <div className="top-menu__title">Manage Payables</div>
                    </a>
                  </li>
                  {/* <!--END : Payables -- > */}

                  {/* <!--BEGIN : Recievables -- > */}
                  <li>
                    <a
                      href={"/admin#/accounts/recievables"}
                      className={
                        state?.submenu1 === "recievables"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) =>
                        activateMenu("accounts", "recievables", "", "")
                      }
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"trending-down"}></i>
                      </div>
                      <div className="top-menu__title">Manage Recievables</div>
                    </a>
                  </li>
                  {/* <!--END : Recievables -- > */}

                  {/* <!--BEGIN : Income Expense -- > */}
                  <li>
                    <a
                      href={"/admin#/accounts/income_expense"}
                      className={
                        state?.submenu1 === "income_expense"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) =>
                        activateMenu("accounts", "income_expense", "", "")
                      }
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"dollar-sign"}></i>
                      </div>
                      <div className="top-menu__title">Income / Expense</div>
                    </a>
                  </li>
                  {/* <!--END : Income Expense -- > */}

                  {/* <!--BEGIN : Transaction History -- > */}
                  <li>
                    <a
                      href={"/admin#/accounts/transaction_history"}
                      className={
                        state?.submenu1 === "transaction_history"
                          ? childActiveClassName
                          : childInActiveClassName
                      }
                      onClick={(e) =>
                        activateMenu("accounts", "transaction_history", "", "")
                      }
                    >
                      <div className="top-menu__icon">
                        <i data-feather={"file-text"}></i>
                      </div>
                      <div className="top-menu__title">Transaction History</div>
                    </a>
                  </li>
                  {/* <!--END : Transaction History  -- > */}
                </ul>
                {/* <!-- END: Submenu1*/}
              </li>
            )}
            {/* <!-- END: Accounts -->*/}

            {/* <!-- BEGIN: User Management -- > */}
            {menuAccess.user_management && (
              <li>
                <a
                  href={"/admin#/user_management/users"}
                  className={
                    state?.main_menu === "user_management"
                      ? parentActiveClassName
                      : parentInActiveClassName
                  }
                  onClick={(e) => activateMenu("user_management", "", "", "")}
                >
                  <div className="top-menu__icon">
                    <i data-feather={"users"}></i>
                  </div>
                  <div className="top-menu__title">User Management</div>
                </a>
              </li>
            )}
            {/* <!-- END: User Management -- > */}

            {/* <!-- BEGIN: Branch Management -- > */}
            {menuAccess.branch_management && (
              <li>
                <a
                  href={"/admin#/branch"}
                  className={
                    state?.main_menu === "branch_management"
                      ? parentActiveClassName
                      : parentInActiveClassName
                  }
                  onClick={(e) => activateMenu("branch_management", "", "", "")}
                >
                  <div className="top-menu__icon">
                    <i data-feather={"git-merge"}></i>
                  </div>
                  <div className="top-menu__title">Branch Management</div>
                </a>
              </li>
            )}
            {/* <!-- END: Branch Management -- > */}
          </ul>
        </nav>
        {/* <!-- END: Top Menu --> */}
      </>
    );
  }
}
