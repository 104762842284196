import PropTypes from "prop-types";

/* <!-- BEGIN: InlineLabel --> */
const InlineLabel = (props) => {
  const { className, id } = props;
  return (
    <div
      id=""
      className={"flex items-center p-3 bg-white dark:bg-dark-3 " + className}
    >
      {props.children}
    </div>
  );
};
InlineLabel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};
InlineLabel.defaultProps = {
  id: "inline-label",
  className: "",
};

/* <!-- END: InlineLabel --> */

/* <!-- BEGIN: Title --> */
const Title = (props) => {
  const { className } = props;
  return <h2 className={className}>{props.children}</h2>;
};
Title.propTypes = {
  className: PropTypes.string,
};
Title.defaultProps = {
  className: "truncate ",
};

/* <!-- END: Title --> */

/* <!-- BEGIN: Value --> */
const Value = (props) => {
  const { className } = props;
  const defaultClass = "ml-auto font-medium ";
  return <div className={defaultClass + className}>{props.children}</div>;
};
Value.propTypes = {
  className: PropTypes.string,
};
Value.defaultProps = {
  className: "",
};
/* <!-- END: Value --> */

InlineLabel.Title = Title;
InlineLabel.Value = Value;

export default InlineLabel;
