import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { resp } from "../../../functions/responseHandler";
import ProcessLoader from "../../../components/preloader/processLoader";
import { Modal, Button } from "react-bootstrap";
import {
  formatCurrency,
  localDate,
  matchData,
} from "../../../functions/common";

const Model = {
  _id: "",
  date: "",
  transaction_type: "",
  transaction_context: "",
  payment_mode: "",
  payment_from_type: "",
  payment_to_type: "",
  payment_bank: "",
  payment_reference_number: "",
  journal_reference_number: "",
  journal_transaction_id: "",
  transaction_amount: "",
  debit: "",
  credit: "",
  consignment: "",
  vehicle: "",
  supplier: "",
  petrolbunk: "",
  invoice: "",
  client: "",
  journal_status: "",
  advance_status: "",
  advance_id: "",
  remarks: "",
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();

  useEffect(() => {
    mapData(props?.data);
  }, []);

  // Data Mapping
  async function mapData(data) {
    setIsProcessing(true);
    let matchedData = await matchData(state, data);
    await setState(matchedData);
    setIsProcessing(false);
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal {...props} scrollable={true} backdrop="static"
        keyboard={false} onHide={props.onHide} size="xl">
    
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
          {/* <span className="ml-2 badge badge-info text-sm text-uppercase">
              {state?.transaction_context}
            </span> */}
        </Modal.Header>
        <Modal.Body>
          <div>
            {isProcessing && <ProcessLoader />}
            <form id="transaction-view-form">
              {/* ID */}
              <div className="grid grid-cols-12 gap-4 gap-y-3 mb-3">
                <div className="col-span-12">
                  <label className="flex justify-center">
                    ID. : <b>{state?._id}</b>
                  </label>
                </div>
              </div>
              <hr />

              {/* 1st Row */}
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                {/* col - 1 */}
                <div className="form-group col-span-6">
                  <label htmlFor="transaction_type">Transaction Type</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    id="transaction_type"
                    name="transaction_type"
                    value={state?.transaction_type}
                    readonly
                  />
                </div>
                {/* col - 2 */}
                <div className="form-group col-span-6">
                  <label htmlFor="date">Date</label>
                  <input
                    type="text"
                    className="form-control"
                    id="date"
                    name="date"
                    value={localDate(state?.date)}
                    readonly
                  />
                </div>
              </div>
              {/* 1st Row */}

              {/* PAYMENTS SPECIFIC */}
              {state?.transaction_type === "payable" && (
                <>
                  <div className="grid grid-cols-12 gap-4 gap-y-3">
                    {/* PETROL BUNK DETAILS */}
                    {state?.payment_mode === "journal" && (
                      <>
                        <div className="form-group col-span-6">
                          <label htmlFor="payment_from_type">
                            Payment From
                          </label>
                          <input
                            type="text"
                            className="form-control text-uppercase"
                            id="payment_from_type"
                            name="payment_from_type"
                            value={state?.payment_from_type}
                            readonly
                          />
                        </div>

                        <div className="form-group col-span-6">
                          <label htmlFor="petrolbunk">Petrolbunk</label>
                          <input
                            type="text"
                            className="form-control"
                            id="petrolbunk"
                            name="petrolbunk"
                            value={state?.petrolbunk?.name}
                            readonly
                          />
                        </div>
                      </>
                    )}
                    {/* PETROL BUNK DETAILS */}

                    <div className="form-group col-span-6">
                      <label htmlFor="payment_to_type">Payment To</label>
                      <input
                        type="text"
                        className="form-control text-uppercase"
                        id="payment_to_type"
                        name="payment_to_type"
                        value={state?.payment_to_type}
                        readonly
                      />
                    </div>

                    <div className="form-group col-span-6">
                      {/* Supplier */}
                      {state?.payment_to_type === "supplier" && (
                        <>
                          <label htmlFor="supplier">Vehicle Supplier</label>
                          <input
                            type="text"
                            className="form-control"
                            id="supplier"
                            name="supplier"
                            value={state?.supplier?.name}
                            readonly
                          />
                        </>
                      )}
                      {/* Petrol Bunk */}

                      {state?.payment_to_type === "petrolbunk" && (
                        <>
                          <label htmlFor="petrolbunk">Petrol bunk</label>
                          <input
                            type="text"
                            className="form-control"
                            id="petrolbunk"
                            name="petrolbunk"
                            value={state?.petrolbunk?.name}
                            readonly
                          />
                        </>
                      )}

                      {/* Vehicle */}

                      {state?.payment_to_type === "vehicle" && (
                        <>
                          <label htmlFor="vehicle">Vehicle Reg No</label>
                          <input
                            type="text"
                            className="form-control"
                            id="vehicle"
                            name="vehicle"
                            value={state?.vehicle?.registration_number}
                            readonly
                          />
                        </>
                      )}
                    </div>

                    {/* PAID AGAINST JOURNAL - PETROL BUNK */}
                    {state?.payment_to_type == "petrolbunk" && (
                      <div className="form-group col-span-6">
                        <label htmlFor="journal_transaction_id">
                          Journal Ref No
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="journal_transaction_id"
                          name="journal_transaction_id"
                          value={
                            state?.journal_transaction_id
                              ?.journal_reference_number
                          }
                          readonly
                        />
                      </div>
                    )}
                  </div>

                  {(state?.payment_to_type === "supplier" ||
                    state?.payment_to_type === "vehicle") && (
                    <div className="form-group col-span-6">
                      <label htmlFor="consignment">Consignment Id</label>
                      <input
                        type="text"
                        className="form-control"
                        id="consignment"
                        name="consignment"
                        value={state?.consignment?.consignment_id}
                        readonly
                      />
                    </div>
                  )}
                </>
              )}
              {/* PAYMENTS SPECIFIC */}

              {/* RECEIVABLE SPECIFIC */}
              {state?.transaction_type === "receivable" && (
                <div className="grid grid-cols-12 gap-4 gap-y-3">
                  <div className="form-group col-span-6">
                    <label htmlFor="client">Client</label>
                    <input
                      type="text"
                      className="form-control"
                      id="client"
                      name="client"
                      value={state?.client?.name}
                      readonly
                    />
                  </div>

                  {state?.transaction_context !== "advance" && (
                    <div className="form-group col-span-6">
                      <label htmlFor="invoice">Invoice No</label>
                      <input
                        type="text"
                        className="form-control"
                        name="invoice"
                        value={state?.invoice?.invoice_number}
                        readonly
                      />
                    </div>
                  )}
                  {state?.transaction_context === "advance" && (
                    <div className="form-group col-span-6">
                      <label htmlFor="advance_status">Advance Status</label>
                      <input
                        type="text"
                        className="form-control text-uppercase"
                        id="advance_status"
                        name="advance_status"
                        value={state?.advance_status}
                        readonly
                      />
                    </div>
                  )}
                </div>
              )}

              {/* RECEIVABLE SPECIFIC */}

              {/* Payment Mode */}
              {state?.transaction_context !== "debit" && (
                <div className="grid grid-cols-12 gap-4 gap-y-3">
                  {/* col - 1 */}
                  <div className="form-group col-span-6">
                    <label htmlFor="payment_mode">Payment Mode</label>
                    <input
                      type="text"
                      className="form-control text-uppercase"
                      id="payment_mode"
                      name="payment_mode"
                      value={state?.payment_mode}
                      readonly
                    />
                  </div>

                  {state?.payment_mode === "bank" && (
                    <div className="form-group col-span-6">
                      <label htmlFor="payment_mode">Bank</label>
                      <input
                        type="text"
                        className="form-control text-uppercase"
                        id="payment_bank"
                        name="payment_bank"
                        value={state?.payment_bank?.name}
                        readonly
                      />
                    </div>
                  )}

                  {/* col - 2 */}
                  <div className="form-group col-span-6">
                    <label htmlFor="payment_reference_number">
                      {state?.payment_mode === "cash"
                        ? "Voucher No."
                        : state.payment_mode === "bank"
                        ? "Transaction Id"
                        : "Reference No"}
                    </label>
                    <input
                      type="text"
                      className="form-control text-uppercase"
                      id="payment_reference_number"
                      name="payment_reference_number"
                      value={
                        state?.payment_mode === "journal"
                          ? state?.journal_reference_number
                          : state?.payment_reference_number
                      }
                      readonly
                    />
                  </div>
                </div>
              )}

              <div className="grid grid-cols-12 gap-4 gap-y-3">
                {/* col - 1 */}
                {state?.transaction_context != "debit" &&
                  state?.transaction_context != "credit" && (
                    <div className="form-group col-span-4">
                      <label htmlFor="transaction_amount">Amount</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text bg-dark">
                            <i className="fa fa-inr" aria-hidden="true"></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="transaction_amount"
                          name="transaction_amount"
                          value={state?.transaction_amount}
                          readonly
                        />
                      </div>
                    </div>
                  )}

                {/* Debit */}
                {((state?.transaction_type === "receivable" &&
                  state?.transaction_context !== "advance" &&
                  state?.transaction_context !== "credit") ||
                  state?.transaction_context === "debit") && (
                  <div className="form-group col-span-4">
                    <label htmlFor="debit">Debit</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text bg-dark">
                          <i className="fa fa-inr" aria-hidden="true"></i>
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="debit"
                        name="debit"
                        value={state?.debit}
                        readonly
                      />
                    </div>
                  </div>
                )}

                {/* Credit */}
                {((state?.transaction_type === "receivable" &&
                  state?.transaction_context !== "advance" &&
                  state?.transaction_context !== "debit") ||
                  state?.transaction_context === "credit") && (
                  <div className="form-group col-span-4">
                    <label htmlFor="credit">Credit</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text bg-dark">
                          <i className="fa fa-inr" aria-hidden="true"></i>
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="credit"
                        name="credit"
                        value={state?.credit}
                        readonly
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* Row */}

              {/* Row */}
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                {/* col*/}
                <div className="form-group col-span-12">
                  <label htmlFor="remarks">Remarks</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="remarks"
                    name="remarks"
                    value={state?.remarks}
                    readonly
                  />
                </div>
              </div>
              {/* Row */}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "View Transaction",
  action: "view",
  callBack() {},
};
