import React from "react";
import PropTypes from "prop-types";

export default function Component(props) {
  const { id, name, className, value, disabled, onChange, required, ...rest } =
    props;

  const positionList = [
    { label: "Left Front", value: "Left Front", short_text: "LF" },
    { label: "Right Front", value: "Right Front", short_text: "RF" },
    { label: "Left Front Outer", value: "Left Front Outer", short_text: "LFO" },
    { label: "Left Front Inner", value: "Left Front Inner", short_text: "LFI" },
    { label: "Left Front Inner", value: "Left Front Inner", short_text: "LFI" },
    {
      label: "Right Front Outer",
      value: "Right Front Outer",
      short_text: "RFO",
    },
    {
      label: "Right Front Inner",
      value: "Right Front Inner",
      short_text: "RFI",
    },
    { label: "2 Left Front", value: "2 Left Front", short_text: "2LF" },
    { label: "2 Right Front", value: "2 Right Front", short_text: "2RF" },
    {
      label: "2 Left Front Outer",
      value: "2 Left Front Outer",
      short_text: "2LFO",
    },

    {
      label: "2 Left Front Inner",
      value: "2 Left Front Inner",
      short_text: "2LFI",
    },
    {
      label: "2 Right Front Outer",
      value: "2 Right Front Outer",
      short_text: "2RFO",
    },

    {
      label: "2 Right Front Inner",
      value: "2 Right Front Inner",
      short_text: "2RFI",
    },
    {
      label: "3 Left Front Outer",
      value: "3 Left Front Outer",
      short_text: "3LFO",
    },
    {
      label: "3 Left Front Inner",
      value: "3 Left Front Inner",
      short_text: "3LFI",
    },
    {
      label: "3 Right Front Outer",
      value: "3 Right Front Outer",
      short_text: "3RFO",
    },
    {
      label: "3 Right Front Inner",
      value: "3 Right Front Inner",
      short_text: "3RFI",
    },
    { label: "Left Rear", value: "Left Rear", short_text: "LR" },
    { label: "Right Rear", value: "Right Rear", short_text: "RR" },
    { label: "Right Rear", value: "Right Rear", short_text: "RR" },
    { label: "Left Rear Outer", value: "Left Rear Outer", short_text: "LRO" },
    { label: "Left Rear Inner", value: "Left Rear Inner", short_text: "LRI" },
    { label: "Right Rear Outer", value: "Right Rear Outer", short_text: "RRO" },
    { label: "Right Rear Inner", value: "Right Rear Inner", short_text: "RRI" },
    {
      label: "2 Left Rear Outer",
      value: "2 Left Rear Outer",
      short_text: "2LFO",
    },
    {
      label: "2 Left Rear Inner",
      value: "2 Left Rear Inner",
      short_text: "2LRI",
    },
    {
      label: "2 Right Rear Outer",
      value: "2 Right Rear Outer",
      short_text: "2RRO",
    },
    {
      label: "2 Right Rear Inner",
      value: "2 Right Rear Inner",
      short_text: "2RRI",
    },
    {
      label: "3 Left Rear Outer",
      value: "3 Left Rear Outer",
      short_text: "3LFO",
    },
    {
      label: "3 Left Rear Inner",
      value: "3 Left Rear Inner",
      short_text: "3LRI",
    },
    {
      label: "3 Right Rear Outer",
      value: "3 Right Rear Outer",
      short_text: "3RRO",
    },
    {
      label: "3 Right Rear Inner",
      value: "3 Right Rear Inner",
      short_text: "3RRI",
    },
    {
      label: "4 Left Rear Outer",
      value: "4 Left Rear Outer",
      short_text: "4LFO",
    },
    {
      label: "4 Left Rear Inner",
      value: "4 Left Rear Inner",
      short_text: "4LRI",
    },
    {
      label: "4 Right Rear Outer",
      value: "4 Right Rear Outer",
      short_text: "4RRO",
    },
    {
      label: "4 Right Rear Inner",
      value: "4 Right Rear Inner",
      short_text: "4RRI",
    },
  ];
  return (
    <select
      className={className}
      onChange={onChange}
      name={name}
      value={value}
      disabled={disabled}
      required={required}
    >
      <option value="" defaultValue></option>

      {positionList.map((item, index) => {
        return (
          <option value={item?.value} key={"tyre-" + index}>
            {item?.short_text} - {item?.label}
          </option>
        );
      })}
      <option value="Spare">Spare</option>
    </select>
  );
}

Component.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  // Functions
  onChange: PropTypes.func,
};

Component.defaultProps = {
  // Variables
  id: "position",
  name: "position",
  className: "form-select",
  value: "",
  disabled: false,
  required: false,
  // Functions
  onChange() {},
};
