export function PayablesModel() {
  return {
    // _id: "",
    date: "",
    transaction_type: "",
    payment_mode: "",
    payment_from_type: "",
    payment_to_type: "",
    payment_cash: "",

    payment_bank: "", // ID
    payment_bank_balance: "",

    payment_reference_number: "",
    journal_reference_number: "",

    supplier: "",
    supplier_data: { _id: "", current_balance: "", total_paid: "" },

    vehicle: "",
    vehicle_data: { _id: "", current_balance: "", total_paid: "" },

    income_total: 0,
    expense_total: 0,

    journal_type: "payable",
    payment_type: "payable",

    journal_from: "",
    journal_to: "",
    cash: "",
    cash_balance: "",
    pay_to: "",
    balance: "",
    total_transaction_amount: "",

    input_bank: {},
    input_bank_balance: "",
    input_fuel: {},
    input_fuel_balance: "",
    payment_from: "",
    payment_to: "",

    consignments: [],
    fuel: [],
    payment_journal: [],
    petrol_bunk: "",
  };
}

export function ValidationErrorModel() {
  return {
    supplier: "",
    consignments: [],
    fuel: [],
    payment_journal: [],
    total_transaction_amount: "",
  };
}
