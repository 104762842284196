import React from "react";
import PropTypes from "prop-types";

export default function Component(props) {
  const { className, id, name, onClick, data, enableEdit, ...rest } = props;
  var isExpiredActive = false;
  var isExpiringActive = false;
  if (!enableEdit && data?.expired > 0) isExpiredActive = true;
  if (!enableEdit && data?.expiring > 0) isExpiringActive = true;

  function onClickExpiring(event) {
    event.preventDefault();
    window.location.href = "/admin#/master/client/agreement";
  }

  function onClickExpired(event) {
    event.preventDefault();
    window.location.href = "/admin#/master/client/agreement";
  }
  return (
    <div id={id} className={className}>
      <div className="flex items-center justify-center w-full h-full">
        {/* <!--BEGIN : EXPIRING --> */}
        <div
          className={
            isExpiringActive ? "text-right cursor-pointer" : "text-right"
          }
          onClick={isExpiringActive ? onClickExpiring : () => {}}
        >
          <div className="dark:text-gray-300 text-3xl font-medium">
            {data?.expiring}
          </div>
          <div className="truncate  text-gray-600 dark:text-gray-600">
            <small>EXPIRING</small>
          </div>
        </div>
        {/* <!--END : EXPIRING --> */}
        {/* <!--BEGIN : Seperator Line --> */}
        <div className="w-px h-12 border border-r border-dashed border-gray-400 dark:border-dark-5 mx-4 xl:mx-6"></div>
        {/* <!--END : Seperator Line --> */}
        {/* <!--BEGIN : EXPIRED --> */}
        <div
          className={isExpiredActive ? "cursor-pointer" : ""}
          onClick={isExpiredActive ? onClickExpired : () => {}}
        >
          <div className="dark:text-gray-300 text-3xl font-medium">
            {data?.expired}
          </div>
          <div className="truncate  text-gray-600 dark:text-gray-600">
            <small>EXPIRED</small>
          </div>
        </div>
        {/* <!--END : EXPIRED --> */}
      </div>

      <div className="text-center mt-auto">
        <div className="text-base md:text-sm font-medium truncate text-theme-1">
          Client Agreements
        </div>
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  enableEdit: PropTypes.bool,
};

Component.defaultProps = {
  className: "w-full h-full flex flex-col items-center justify-center",
  id: "client_agreements_content",
  name: "",
  data: { expired: 0, expiring: 0 },
  onClick() {},
  enableEdit: false,
};
