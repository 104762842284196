import Toaster from "../components/notification/toaster";
import { storageServices } from "../functions/storage";
class responseHandlerClass {
  constructor() {}

  async ResponseHandler(response, action = "reload") {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    if (response?.status == 201 || response?.status == 200) {
      await Toaster({
        type: "success",
        position: "topRight",
        text: response?.data?.message,
      });
      await sleep(2000);
      if (action != "reload") {
        window.location.href = action;
      } else {
        window.location.reload();
      }
    }
    //  else if (response?.status == 200) {
    //   await Toaster({
    //    type: "error",
    //    position: "topRight",
    //    text: response?.data?.message,
    //  });
    //  window.location.reload();
    // }
    else {
      await Toaster({
        type: "error",
        position: "topRight",
        text: "Something went wrong. Please try again!",
      });
    }
  }

  SuccessResponse(response) {
    Toaster({
      type: "success",
      position: "topRight",
      text: response?.data?.message,
    });
  }

  TestResponse(text) {
    // Toaster({ type: "success", position: "topRight", text: text });
    return  Toaster({ type: "success", position: "topRight", text: text });
    // return <Toaster text="text" />;
  }

  async logOut() {
    await storageServices.resetCurrentUser();
    window.location.href = "/login";
  }

  ErrorHandler(error) {
    let err_msg = "Something went wrong!";
    console.log("error", error.response);
    if (typeof error.response?.data?.message !== "undefined") {
      err_msg = error.response?.data?.message;
    }
    if (
      typeof error.response?.data?.error !== "undefined" &&
      typeof error.response?.data?.error !== "object"
    ) {
      err_msg = error.response?.data?.error;
    }

    Toaster({ type: "error", position: "topRight", text: err_msg });
    try {
      if (error.response.status === 401) {
        this.logOut();
      }
    } catch (e) {
      console.log(e);
    }
  }

  Information(msg) {
    Toaster({ type: "info", position: "topRight", text: msg });
  }

  Success(msg) {
    Toaster({ type: "success", position: "topRight", text: msg });
  }

  Error(msg) {
    Toaster({ type: "error", position: "topRight", text: msg });
  }
}
const resp = new responseHandlerClass();
export { resp };
