class storageServiceClass {
    constructor() {}

    async getCurrentUser() {
        let res = localStorage.getItem("user");
        try {
          res = JSON.parse(res);
        } catch (e) {
            console.log("Invalid format:",e)
        }
        return res;
      }

      async setCurrentUser(user) {
        try {
          localStorage.setItem('user', JSON.stringify(user));
        } catch (e) {
            console.log("Invalid format:",e)
        }
      }

      async resetCurrentUser() {
        localStorage.removeItem("user");
      }
      


      async storeData(key,data) {
        try {
          await localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.log("Invalid format:",e)
        }
      }

      async getStoredData(key) {
        let res = await localStorage.getItem(key);
        try {
          res = JSON.parse(res);
        } catch (e) {
            console.log("Invalid format:",e)
        }
        return res;
      }






}
const storageServices = new storageServiceClass()
export { storageServices };
