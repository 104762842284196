import React from "react";
import PropTypes from "prop-types";

// const orgCodeRegexFormula = /CMT[0-9]{4}[A-Z]{2}19/g;
const orgCodeRegexFormula = /CMT(.*?)[A-Z]{2}19[0-9]{5}/i;
const branchCodeRegexFormula = /CMT[0-9]{4}(.*?)19[0-9]{5}/i;
const consNumberRegexFormula = /CMT[0-9]{4}[A-Z]{2}19(.*)/i;
//(.*?)

export default function Component(props) {
  const { data, className, ...rest } = props;

  const BulletPoint = () => {
    return <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>;
  };

  const Element = (props) => {
    const { title, description, id, ...rest } = props;
    return (
      <div className="flex items-center" id={id}>
        <BulletPoint />
        <span>{title} -</span>
        <span className="ml-2">{description}</span>
      </div>
    );
  };

  // Splitting String
  // ORG CODE
  let org_code = data.match(orgCodeRegexFormula);
  org_code = org_code?.[1];

  // BRANCH CODE
  let branch_code = data.match(branchCodeRegexFormula);
  branch_code = branch_code?.[1];

  // CONS NUMBER
  let cons_number = data.match(consNumberRegexFormula);

  cons_number = cons_number?.[1];

  return (
    <div className={className}>
      <Element
        title="CMT"
        description="Entity Identifier"
        id="entity_identifier"
      />

      <Element title={org_code} description="Organization Code" id="org_code" />
      <Element title={branch_code} description="Branch Code" id="branch_code" />
      <Element
        title={"19"}
        description="Consignment Identification No."
        id="consignment_identifier"
      />

      <Element
        title={cons_number}
        description="Consignment Number"
        id="cons_number"
      />
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  // Functions
  onClick: PropTypes.func,
};

Component.defaultProps = {
  // Variables
  className: "",
  // Functions
  onClick() {},
};
