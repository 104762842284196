import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// HTML Elements
import ProcessLoader from "../../preloader/processLoader";
import { Modal } from "react-bootstrap";
import KmInput from "../../htmlElements/kmInput";
import TypeAhAddNewButton from "../../htmlElements/typeAhAddNewButton";
import ValidationError from "../../error/validationError";
import TruckNumberDisplay from "../../consignment/truckNumberDisplay";
import CurrentOdometerLabel from "../../pageElements/currentOdometerLabel";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

import Form from "../../form/custom";
// Add-on Components
import VehicleMaster from "../vehicle";

// Functions
import { storageServices } from "../../../functions/storage";

import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";
import {
  htmlDate,
  matchData,
  formatTypeSearch,
  formatTypeaheadCommon,
  mongoUTC,
} from "../../../functions/common";
import * as validation from "../../../functions/validation";

const Model = {
  removal_date: "",
  mode: "",
  current_odometer_reading: "",
  new_odometer_reading: "",
  remarks: "",
  status: "",
  action: "",
};

const fileBaseURL = process.env.REACT_APP_VEHICLE_URL;
export default function TyreRemovalForm(props) {
  const { id, vehicle, tyre } = props;

  const [tyreData, setTyreData] = useState(tyre || { _id: "", serial_no: "" });

  const [vehicleData, setVehicleData] = useState(
    vehicle || { _id: "", registration_number: "" }
  );
  const [saveToDB, setSaveToDB] = useState(true);
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
    approve: false,
  });

  // State
  const [state, setState] = useState({ ...Model });
  const [validationError, setValidationError] = useState({
    ...Model,
  });

  const [localUser, setLocalUser] = useState();

  //////////// External Modal States   ////////////
  const [vehicleMasterModal, setVehicleMasterModal] = useState({
    show: false,
    title: "Add Vehicle",
    action: "create",
    id: "",
  });

  //////////// External Modal States   ////////////

  const [record_id, setRecordId] = useState(id || "");
  const [defaultState, setDefaultState] = useState({
    vehicle: [],
    brand: [],
    sub_brand: [],
  });

  const [typeAheadFields, setTypeAheadFields] = useState({
    vehicle: "",
  });

  const [pageTitle, setPageTitle] = useState("Vehicle Form");
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction(props);
  }, []);

  // <!-- BEGIN: Vehicle Master Modal -- >
  const openVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadVehicleMasterData(data) {
    closeVehicleMasterModal();
    let event = {};
    loadDefaultMetrics(event, "vehicle", true, data, "vehicle");
  }
  // <!-- END: Vehicle Master Modal -- >

  async function handleUserAction(props) {
    let action = props.action;
    let id = props.id;

    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    switch (action) {
      case "create":
        setPageTitle("Remove Tyre");
        setMode((prevState) => ({ ...prevState, create: true }));
        setDefaults(props);
        // await loadDefaultMetrics();

        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
        break;
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };
    prevState.status = "active";
    prevState.removal_date = htmlDate(new Date());
    prevState.mode = "inventory";
    prevState.action = "removal";
    prevState.position = tyre?.position;

    let current_odo = vehicle?.odometer_reading || "";
    prevState["current_odometer_reading"] = current_odo;
    prevState["new_odometer_reading"] = current_odo;

    setState(prevState);
    setIsProcessing(false);
    return null;
  }

  // Input Change
  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    formValidation(name, selected);
  }

  function changeInput(event) {
    const { name, value, required } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  function changeCheckBox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state[name] }));
  }

  async function changeInputImage(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImage(files[0]);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  async function changeInputFile(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImageNFile(files[0], 5);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  function deleteInputImage(event, name) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [name]: "" }));
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = validationError;
    switch (name) {
      default:
        break;
    }
    // setValidationError(errors);
  }

  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  async function prepareData(data) {
    let newData = {
      _id: record_id,
      tyre: record_id,
      removal_date: mongoUTC(data?.removal_date),
      remarks: data?.remarks,
      mode: data?.mode,
      odometer_reading: data?.new_odometer_reading,
      vehicle: vehicle._id,
      action: "removal",
      position: data?.position,
    };
    // checking vehicle
    // if (data["mode"] === "vehicle") {
    //   newData["vehicle"] = data?.vehicle?._id;
    //   newData["position"] = data?.position;
    // } else {
    //   newData["vehicle"] = null;
    //   newData["position"] = "";
    // }

    return newData;
  }
  // Form Actions

  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = await prepareData({ ...state });

      console.log("data:", data);

      let apiData = { vehicle: vehicle?._id, tyre_information: [data] };
      api
        .postService(`tyre/position`, apiData)
        .then((response) => {
          setIsProcessing(false);
          resp.Success("Tyre Removed Successfully.");
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function loadDefaultMetrics(
    event,
    key,
    reload = false,
    data = {},
    state_key
  ) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      let mappedData = await mapDefaultStateData(key, prevDefState);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: mappedData,
      }));
      setState((prevState) => ({
        ...prevState,
        [state_key]: data,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "vehicle":
            url = "vehicle/all/own";
            await fetchDefaultMetrics(url, key);
            break;

          case "brand":
            url = "tyre/brands";
            await fetchDefaultMetrics(url, key);
            break;

          case "sub_brand":
            let brand = state?.brand || "";
            url = `tyre/sub_brands/brand/${brand}`;
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        setError(error);
      });
  }

  function mapDefaultStateData(key, data) {
    let result = [];
    if (key === "brand") {
      for (var i in data) {
        result.push(data[i]["name"]);
      }
    } else if (key === "sub_brand") {
      for (var i in data) {
        result.push(data[i]["sub_brand"]);
      }
    } else if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
      result = data;
    } else {
      result = data;
    }

    return result;
  }

  function changeInputTypeaheadFields(text, field) {
    setTypeAheadFields((prevState) => ({ ...prevState, [field]: text }));
  }

  return (
    <div
      key="tyre_removal_modal"
      id="tyre_removal_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {!mode.view && vehicleMasterModal.show && (
        <VehicleMaster
          show={vehicleMasterModal.show}
          action={vehicleMasterModal.action}
          title={vehicleMasterModal.title}
          onHide={closeVehicleMasterModal}
          callBack={reloadVehicleMasterData}
          saveToDB={false}
          registration_number={typeAheadFields?.vehicle}
        />
      )}

      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="tyre_removal-modal"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">Remove Tyre</h2>
        </Modal.Header>

        {/* <!-- BEGIN: Modal Body --> */}
        <Modal.Body>
          {isProcessing && <ProcessLoader />}

          {/* <!-- BEGIN: Form --> */}
          <Form
            id="tyre_removal-form"
            name="tyre_removal-form"
            onSubmit={onSubmit}
          >
            {/* <!-- BEGIN : Top Details  -- > */}
            <>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 my-3 text-center flex items-center justify-center">
                  <div id="tyre_display" className="text-gray-700">
                    Tyre No.
                  </div>
                  <div
                    id="tyre_display"
                    className="ml-2 text-theme-1 font-medium"
                  >
                    {tyreData?.serial_no}
                  </div>
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">Removal Date</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    id="removal_date"
                    name="removal_date"
                    placeholder=""
                    value={state?.removal_date}
                    onChange={changeInput}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">To</Form.Label>
                  <select
                    className="form-select"
                    onChange={changeInput}
                    name="mode"
                    value={state.mode}
                    disabled={mode.view}
                    required
                  >
                    {/* <option value="vehicle">Vehicle</option> */}
                    <option value="inventory">Inventory</option>
                    <option value="scrap">Scrap</option>
                  </select>

                  <ValidationError msg={validationError.mode} />
                </div>
                {/* <!-- Vehicle Details --> */}
                {state?.mode === "vehicle" && (
                  <>
                    <div className="col-span-12 md:col-span-6">
                      <Form.Label className="required">Vehicle</Form.Label>
                      <Typeahead
                        clearButton
                        id="vehicle"
                        name="vehicle"
                        filterBy={["registration_number", "vehicleType"]}
                        onFocus={(event) => {
                          loadDefaultMetrics(event, "vehicle");
                        }}
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "vehicle");
                        }}
                        onInputChange={(text) => {
                          changeInputTypeaheadFields(text, "vehicle");
                        }}
                        options={defaultState?.vehicle}
                        labelKey={(option) =>
                          `${option?.registration_number || ""}`
                        }
                        selected={formatTypeSearch(state?.vehicle || "")}
                        inputProps={{ required: true }}
                        placeholder="Search vehicle..."
                        disabled={mode.view || mode.acknowledge}
                        renderMenu={(results, menuProps) => (
                          <Menu {...menuProps}>
                            {localUser?.module_access?.master &&
                              localUser?.access?.approve_master && (
                                <TypeAhAddNewButton
                                  onClick={openVehicleMasterModal}
                                ></TypeAhAddNewButton>
                              )}

                            {results.map((result, index) => (
                              <MenuItem
                                option={result}
                                position={index}
                                key={index}
                              >
                                <TruckNumberDisplay
                                  data={result}
                                  id={"vehicle_" + result?._id}
                                />
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      />
                      <ValidationError msg={validationError.vehicle} />
                    </div>

                    <div className="col-span-12 sm:col-span-6">
                      <Form.Label className="required">
                        Tyre Position
                      </Form.Label>
                      <select
                        className="form-select"
                        onChange={changeInput}
                        name="position"
                        value={state.position}
                        disabled={mode.view}
                        required
                      >
                        <option value="" defaultValue></option>
                        <option value="Front Left">FL - Front Left</option>
                        <option value="Front Right">FR - Front Right</option>
                        <option value="Rear Left">RL - Rear Left</option>
                        <option value="Rear Right">RR - Rear Right</option>
                        <option value="spare">Spare</option>
                      </select>
                      <ValidationError msg={validationError.position} />
                    </div>
                  </>
                )}
                {/* <!-- Vehicle Details --> */}

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="">Vehicle Odometer</Form.Label>
                  <KmInput
                    className="form-control"
                    id="new_odometer_reading"
                    name="new_odometer_reading"
                    placeholder=""
                    value={state?.new_odometer_reading}
                    onChange={changeInput}
                  />
                  <CurrentOdometerLabel
                    value={state?.current_odometer_reading}
                    id="current_odometer_reading"
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label>Notes</Form.Label>
                  <textarea
                    type="text"
                    className="form-control text-uppercase"
                    id="remarks"
                    name="remarks"
                    placeholder=""
                    disabled={mode?.view}
                    value={state?.remarks}
                    onChange={changeInput}
                  />
                </div>
              </div>
            </>
            {/* <!-- END :Top Details -- > */}

            <input
              type="submit"
              id="save_tyre_removal_form"
              className="hidden"
            />
            <input
              type="submit"
              id="update_tyre_removal_form"
              className="hidden"
            />
          </Form>
          {/* <!-- END: Form --> */}

          {/* <!-- END: Card --> */}
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}

        <Modal.Footer className="justify-end">
          <div className="">
            <div className="mr-2">
              <ValidationError
                msg={!isValidForm.valid ? isValidForm.msg : ""}
                notify={true}
              />
            </div>
            <label
              type="button"
              className="btn btn-secondary mr-2 "
              onClick={props.onHide}
            >
              Cancel
            </label>

            {mode.create && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="save_tyre_removal_form"
              >
                Save
              </label>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
