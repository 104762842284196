import * as React from "react";
import PropTypes from "prop-types";
import FeatherIcons from "../../assets/js/feather";
export default function ActionButtons(props) {
  const {
    editButton,
    deleteButton,
    suspendButton,
    activateButton,
    approveButton,
    depositCashButton,
    cell,
  } = props;

  const rowData = cell?._cell?.row?.data;
  // FeatherIcons();

  return (
    <div
      // className="flex lg:justify-center items-center"
      className="flex"
      aria-label="Action Buttons"
    >
      {/* EDIT BUTTON */}
      {editButton?.show ? (
        <a
          type="button"
          className="edit flex items-center mr-2"
          onClick={(event) => {
            event.preventDefault();
            editButton.onClick(rowData);
          }}
          // data-toggle="tooltip"
          data-placement="top"
          title="Edit"
          href={void 0}
          disabled={editButton?.disabled}
          data-toggle="modal"
          data-target="#supplier-modal"
        >
          {/* <i data-feather="edit" className="mr-1"></i> Edit */}
          <i
            className="fa fa-pencil-square-o mr-1"
            aria-hidden="true"
          ></i>{" "}
          Edit
        </a>
      ) : null}
      {/* EDIT BUTTON */}

      {/* APPROVE BUTTON */}
      {approveButton?.show ? (
        <button
          type="button"
          className="edit flex items-center text-theme-9 mr-2"
          onClick={(event) => {
            event.preventDefault();
            approveButton.onClick();
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Approve"
          disabled={approveButton?.disabled}
        >
          <i className="fa fa-check-circle-o mr-1" aria-hidden="true"></i>{" "}
          Approve
        </button>
      ) : null}
      {/* APPROVE BUTTON */}

      {/* ACTIVATE BUTTON */}
      {activateButton?.show ? (
        <button
          type="button"
          className="delete flex items-center text-theme-9"
          onClick={(event) => {
            event.preventDefault();
            activateButton.onClick();
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Activate"
          disabled={activateButton?.disabled}
        >
          {/* <i data-feather="check-square" className="mr-1"></i> Activate */}
          <i className="fa fa-check-circle-o mr-1" aria-hidden="true"></i>{" "}
          Activate
        </button>
      ) : null}
      {/* ACTIVATE BUTTON */}

      {/* Deposit Cash BUTTON */}
      {depositCashButton?.show ? (
        <button
          type="button"
          className="edit flex items-center text-theme-9 mr-2"
          onClick={(event) => {
            event.preventDefault();
            depositCashButton.onClick();
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Deposit Cash"
          disabled={depositCashButton?.disabled}
        >
          <i data-feather="check-square" className="mr-1"></i> Deposit
          cash
        </button>
      ) : null}
      {/* Deposit Cash BUTTON */}

      {/* Suspend BUTTON */}
      {suspendButton?.show ? (
        <button
          type="button"
          className="delete flex items-center text-theme-6"
          onClick={(event) => {
            event.preventDefault();
            suspendButton.onClick();
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Suspend"
          disabled={suspendButton?.disabled}
        >
          {/* <i data-feather="trash-2" className="mr-1"></i> Suspend */}
          <i className="fa fa-trash-o mr-1" aria-hidden="true"></i> Suspend
        </button>
      ) : null}
      {/* Suspend BUTTON */}

      {/* DELETE BUTTON */}
      {deleteButton?.show ? (
        <button
          type="button"
          className="delete flex items-center text-theme-6"
          onClick={(event) => {
            event.preventDefault();
            deleteButton.onClick();
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Delete"
          disabled={deleteButton?.disabled}
        >
          {/* <i data-feather="trash-2" className="mr-1"></i> Delete */}
          <i className="fa fa-trash-o mr-1" aria-hidden="true"></i> Delete
        </button>
      ) : null}
      {/* DELETE BUTTON */}
    </div>
  );
}

ActionButtons.propTypes = {
  editButton: PropTypes.object,
  suspendButton: PropTypes.object,
  deleteButton: PropTypes.object,
  activateButton: PropTypes.object,
  depositCashButton: PropTypes.object,
};

ActionButtons.defaultProps = {
  editButton: { show: false, disabled: false, onClick() {} },
  suspendButton: { show: false, disabled: false, onClick() {} },
  deleteButton: { show: false, disabled: false, onClick() {} },
  activateButton: { show: false, disabled: true, onClick() {} },
  depositCashButton: { show: false, disabled: true, onClick() {} },
};
