import React from "react";
import PropTypes from "prop-types";
export default function Component(props) {
  const {
    className,
    id,
    name,
    onClick,
    onRemove,
    showRemove,
    enableEdit,
    ...rest
  } = props;
  return (
    <div className={className} id={id}>
      {enableEdit && (
        <a
          className="flex items-center cursor-pointer -mt-2"
          href={void 0}
          name={id}
          onClick={(e) => onRemove(id)}
        >
          <i className="fa fa-times ml-auto text-gray-500" aria-hidden="true"></i>
        </a>
      )}

      {props?.children}
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  enableEdit: PropTypes.bool,
  showRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "p-3 w-full h-full flex flex-col",
  id: "draggable_card",
  name: "",
  enableEdit: false,
  showRemove: true,
  onRemove() {},
  onClick() {},
};
