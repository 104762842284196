import * as React from "react";
import PropTypes from "prop-types";
import ValidationError from "../error/validationError";
import "./profileImage.css";
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

export default function ProfileImage(props) {
  const {
    mode,
    state,
    name,
    required,
    error_msg,
    onDelete,
    onChange,
    baseURL,
  } = props;
  return (
    <>
      {!mode.view && (state?.name || !state) ? (
        <>
          {/* <div className="picture-container"> */}
          <div className="">
            <div className="picture">
              <img
                src={
                  state
                    ? URL.createObjectURL(state)
                    : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
                }
                className="picture-src"
                id="wizardPicturePreview"
                title=""
              />
              <input
                className="file-upload"
                type="file"
                accept="image/*"
                name={name}
                id={name}
                onChange={onChange}
              />
            </div>
          </div>
          <ValidationError msg={error_msg} />
        </>
      ) : (
        ""
      )}
      {state && typeof state === "string" ? (
        <>
          <div className="picture-container">
            <div className="picture">
              <img
                src={
                  state
                    ? baseURL + state
                    : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
                }
                className="picture-src"
                id="wizardPicturePreview"
                title=""
              />
              <input
                className="file-upload"
                type="file"
                accept="image/*"
                name={name}
                id={name}
                disabled={mode?.view}
                onChange={onChange}
              />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {!state && mode?.view ? (
        <>
          <div className="picture-container">
            <div className="picture">
              <img
                src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
                className="picture-src"
                id="wizardPicturePreview"
                title=""
              />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

ProfileImage.propTypes = {
  mode: PropTypes.object,
  state: PropTypes.object,
  name: PropTypes.string,
  baseURL: PropTypes.string,
  required: PropTypes.bool,
  error_msg: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

ProfileImage.defaultProps = {
  mode: { view: true },
  state: {},
  name: "",
  required: false,
  baseURL: ImageUrl,
  error_msg: "",
  onChange() {},
  onDelete() {},
};
