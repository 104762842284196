import React, { useState, useEffect } from "react";
// HTML Elements
import ProcessLoader from "../../preloader/processLoader";
import { Modal } from "react-bootstrap";
import ValidationError from "../../error/validationError";
import TyrePositionDropdown from "../../pageElements/tyrePositionDropdown";
import KmInput from "../../htmlElements/kmInput";
import CurrentOdometerLabel from "../../pageElements/currentOdometerLabel";

import Form from "../../form/custom";
// Add-on Components

// Functions
import { storageServices } from "../../../functions/storage";

import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";
import { htmlDate, mongoUTC } from "../../../functions/common";
import * as validation from "../../../functions/validation";

// Models
// import { Model, ValidationErrorModel } from "./model";

const Model = {
  tyre: "",
  vehicle: "",
  current_position_removal_date: "",
  new_position_fitment_date: "",
  new_position: "",
  current_odometer_reading: "",
  new_odometer_reading: "",
  remarks: "",
};

export default function TyreFitmentForm(props) {
  const { id, vehicle, tyre } = props;

  const [vehicleData, setVehicleData] = useState(
    vehicle || { _id: "", registration_number: "", odometer_reading: "" }
  );

  const [tyreData, setTyreData] = useState(tyre || { _id: "", serial_no: "" });

  const [saveToDB, setSaveToDB] = useState(true);
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });

  // State
  const [state, setState] = useState(Model);
  const [validationError, setValidationError] = useState(Model);

  const [localUser, setLocalUser] = useState();

  const [record_id, setRecordId] = useState(id || "");

  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction(props);
  }, []);

  async function handleUserAction(props) {
    let action = props.action;
    let id = props.id;

    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    switch (action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setDefaults(props);

        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
        break;
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...state };
    prevState.current_position_removal_date = htmlDate(new Date());
    prevState.new_position_fitment_date = htmlDate(new Date());
    let current_odo = vehicle?.odometer_reading || "";
    prevState["current_odometer_reading"] = current_odo;
    prevState["new_odometer_reading"] = current_odo;

    setState(prevState);
    setIsProcessing(false);
    return null;
  }

  function changeInput(event) {
    const { name, value, required } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = validationError;
    switch (name) {
      default:
        break;
    }
    // setValidationError(errors);
  }

  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  async function prepareData(data) {
    // data["_id"] = record_id;
    // data["tyre"] = record_id;
    // data["vehicle"] = vehicle?._id;
    // data["current_position_removal_date"] = data?.current_position_removal_date;
    // data["new_position"] = data?.tyre_new_position;
    // data["new_position_fitment_date"] = data?.new_position_fitment_date;
    // data["odometer_reading"] = data?.new_odometer_reading;
    // data["remarks"] = data?.remarks;

    let response = {
      vehicle: vehicle._id,
      tyre_information: [
        {
          // _id: record_id,
          tyre: record_id,
          action: "removal",
          removal_date: mongoUTC(data?.current_position_removal_date),
          remarks: data?.remarks,
          odometer_reading: data?.new_odometer_reading,
          vehicle: vehicle._id,
          position: tyreData?.position,
          mode:"inventory"
        },
        {
          // _id: record_id,
          tyre: record_id,
          action: "fitment",
          fitment_date: mongoUTC(data?.new_position_fitment_date),
          odometer_reading: data?.new_odometer_reading,
          vehicle: vehicle._id,
          position: data?.new_position,
          mode:"vehicle"
        },
      ],
    };

    return response;
  }
  // Form Actions

  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = await prepareData({ ...state });

      // let apiData = { tyre_information: [data] };
      api
        .postService(`tyre/position`, data)
        .then((response) => {
          setIsProcessing(false);
          resp.Success("Tyre Position Changed Successfully.");
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  return (
    <div
      key="position_change_modal"
      id="position_change_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="position_change-modal"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">
            Tyre Position Change
          </h2>
        </Modal.Header>

        {/* <!-- BEGIN: Modal Body --> */}
        <Modal.Body>
          {isProcessing && <ProcessLoader />}

          {/* <!-- BEGIN: Form --> */}
          <Form
            id="position_change-form"
            name="position_change-form"
            onSubmit={onSubmit}
          >
            {/* <!-- BEGIN : Top Details  -- > */}
            <>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 my-2 text-center flex items-center justify-center">
                  <div id="tyre_display" className="text-gray-700">
                    Tyre No.
                  </div>
                  <div
                    id="tyre_display"
                    className="ml-2 text-theme-1 font-medium"
                  >
                    {tyreData?.serial_no}
                  </div>
                </div>

                <div className="col-span-12 my-2 text-center flex items-center justify-center">
                  <div id="tyre_current_position" className="text-gray-700">
                    Current Position
                  </div>
                  <div
                    id="tyre_display"
                    className="ml-2 text-theme-1 font-medium"
                  >
                    {tyreData?.position}
                  </div>
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">Removal Date</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    id="current_position_removal_date"
                    name="current_position_removal_date"
                    placeholder=""
                    value={state?.current_position_removal_date}
                    onChange={changeInput}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">
                    Tyre New Position
                  </Form.Label>

                  <TyrePositionDropdown
                    className="form-select"
                    onChange={changeInput}
                    name="new_position"
                    value={state.new_position}
                    disabled={mode.view}
                    required={true}
                  />

                  <ValidationError msg={validationError.new_position} />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">Fitted Date</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    id="new_position_fitment_date"
                    name="new_position_fitment_date"
                    placeholder=""
                    value={state?.new_position_fitment_date}
                    onChange={changeInput}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Form.Label className="required">Vehicle Odometer</Form.Label>
                  <KmInput
                    className="form-control"
                    id="new_odometer_reading"
                    name="new_odometer_reading"
                    placeholder=""
                    value={state?.new_odometer_reading}
                    onChange={changeInput}
                  />
                  <CurrentOdometerLabel
                    value={state?.current_odometer_reading}
                    id="current_odometer_reading"
                  />
                </div>

                <div className="col-span-12">
                  <Form.Label>Notes</Form.Label>

                  <textarea
                    id="remarks"
                    className="form-control"
                    onChange={changeInput}
                    name="remarks"
                    value={state.remarks}
                    disabled={mode.view}
                  />
                </div>
              </div>
            </>
            {/* <!-- END :Top Details -- > */}

            <input
              type="submit"
              id="save_position_change_form"
              className="hidden"
            />
          </Form>
          {/* <!-- END: Form --> */}

          {/* <!-- END: Card --> */}
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}

        <Modal.Footer className="justify-end">
          <div className="">
            <div className="mr-2">
              <ValidationError
                msg={!isValidForm.valid ? isValidForm.msg : ""}
                notify={true}
              />
            </div>
            <label
              type="button"
              className="btn btn-secondary mr-2 "
              onClick={props.onHide}
            >
              Cancel
            </label>

            {mode.create && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="save_position_change_form"
              >
                Save
              </label>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
