import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
// Typeahead Component
import ClientsInList from "../../components/consignment/clientsInList";
import PercentageInput from "../../components/htmlElements/percentageInput";
// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";
import {
  formatTypeSearch,
  formatTypeaheadCommon,
} from "../../functions/common";

const Model = {
  client: "",
  material: "",
  unit_of_measure: "kg",
  threshold_percentage: "",
  exceed_deduction_per_unit: "",
  status: "active",
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [defaultState, setDefaultState] = useState({
    client: [],
    material: [],
  });

  const [record_id, setRecordId] = useState();
  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setState(Model);
        setViewMode(false);
        break;
      case "edit":
        setViewMode(false);
        loadDataById(props.id);
        break;
      case "view":
        setViewMode(true);
        loadDataById(props.id);
        break;
      default:
        setViewMode(true);
    }
  }
  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`load_material/threshold/${id}`).then(
      async (result) => {
        const data = result.data;
        await setState(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  function prepareData(data) {
    let result = data;
    result["client"] = result["client"]["_id"];
    if (result["material"]?.customOption) {
      result["material"] = result["material"]?.label;
    }

    return result;
  }

  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });

    api
      .postService("load_material/threshold", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });
    api
      .putService(`load_material/threshold/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  //<!--BEGIN: Typeahead Functions -->

  async function loadDefaultMetrics(event, key, reload = false, data = {}) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: prevDefState,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "client":
            url = "client/status/active";
            await fetchDefaultMetrics(url, key);
            break;
          case "material":
            url = "load_material/master";
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultState(data, key);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function mapDefaultState(data, key) {
    let result = [];
    if (key === "material") {
      for (var i in data) {
        result.push(data[i]["name"]);
      }
    } else {
      result = data;
    }
    return result;
  }

  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  //<!--END: Typeahead Functions -->

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>
        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
            id="load-material-threshold-form"
            className="grid grid-cols-12 gap-4 gap-y-3"
          >
            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="client" className="form-label required">
                Client
              </label>
              <Typeahead
                clearButton
                filterBy={["name", "contact_number"]}
                id="client"
                name="client"
                onFocus={(event) => {
                  loadDefaultMetrics(event, "client");
                }}
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "client");
                }}
                options={defaultState?.client}
                // labelKey={(option) => `${option?.name || ""}`}
                labelKey="name"
                selected={formatTypeSearch(state?.client || "")}
                inputProps={{ required: true }}
                placeholder="Search client..."
                disabled={viewMode}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, index) => (
                      <MenuItem option={result} position={index} key={index}>
                        <ClientsInList
                          data={result}
                          id={"client_" + index}
                          display={["phone"]}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="material" className="form-label required">
                Material
              </label>
              <Typeahead
                allowNew
                newSelectionPrefix="Add new: "
                clearButton
                id="material"
                name="material"
                onFocus={(event) => {
                  loadDefaultMetrics(event, "material");
                }}
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "material");
                }}
                options={defaultState?.material}
                // labelKey={(option) => `${option?.name || ""}`}
                // filterBy={() => true}
                selected={formatTypeaheadCommon(state?.material || "")}
                inputProps={{ required: true }}
                placeholder="Select material"
                disabled={viewMode}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="threshold_percentage"
                className="form-label required"
              >
                Threshold %
              </label>
              <PercentageInput
                id="threshold_percentage"
                name="threshold_percentage"
                value={state?.threshold_percentage}
                onChange={changeInput}
                disabled={viewMode}
                required={true}
              />
            </div>

            <input
              type="submit"
              id="save_load_material_threshold"
              className="hidden"
            />
            <input
              type="submit"
              id="update_load_material_threshold"
              className="hidden"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>

          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary"
              htmlFor="save_load_material_threshold"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary"
              htmlFor="update_load_material_threshold"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Load Material Threshold",
  action: "view",
  callBack() {},
};
