const Model = {
  isNew: false,
  owner_details: false,
  engine_details: false,
  validity_details: false,
  documents_details: false,
  gps_details: false,

  registration_number: "",
  ownership_type: "",
  approved: false,
  owner_name: "",
  owner_contactNo: "",
  vehicle_type: "",
  status: "",
  FC_valid_from: "",
  FC_valid_to: "",
  FC_image: "",
  RC_chasisNo: "",
  RC_engineNo: "",
  RC_image: "",
  Insurance_valid_from: "",
  Insurance_valid_to: "",
  Insurance_image: "",
  Goods_valid_from: "",
  Goods_valid_to: "",
  permit_image: "",
  pan_number: "",
  pan_image: "",
  tds: false,
  tds_percentage: 0,
  tds_doc: "",
  vehicle_docs: "",
  gps_enabled: false,
  gps_service_provider: "",
  no_of_tyres: "",
  no_of_axles:"",
  fuel_tank_capacity: "",
  fuel_balance: "",
  odometer_reading: "",
};

const ValidationErrorModel = {
  registration_number: "",
  ownership_type: "",
  owner_name: "",
  vehicle_type: "",
  FC_valid_from: "",
  FC_valid_to: "",
  FC_image: "",
  RC_chasisNo: "",
  RC_engineNo: "",
  RC_image: "",
  Insurance_valid_from: "",
  Insurance_valid_to: "",
  Insurance_image: "",
  Goods_valid_from: "",
  Goods_valid_to: "",
  permit_image: "",
  pan_number: "",
  pan_image: "",
  registration_number: "",
  FC_valid_from: "",
  FC_valid_to: "",
  FC_image: "",
  RC_chasisNo: "",
  RC_engineNo: "",
  RC_image: "",
  Insurance_valid_from: "",
  Insurance_valid_to: "",
  Insurance_image: "",
  Goods_valid_from: "",
  Goods_valid_to: "",
  permit_image: "",
  pan_number: "",
  pan_image: "",
  tds_doc: "",
  vehicle_docs: "",
  gps_enabled: "",
  gps_service_provider: "",
  no_of_tyres: "",
  no_of_axles: "",
  fuel_tank_capacity: "",
  fuel_balance: "",
  odometer_reading: "",
  avg_mileage: "",
};

export { Model };
export { ValidationErrorModel };
