import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const defButtonHtml = (
  <div className="text-theme-3">
    <i className="fa fa-question-circle" aria-hidden="true"></i>
  </div>
);
export default function Component(props) {
  // id should be unique and mandatory
  const {
    className,
    type,
    id,
    name,
    onClick,
    icon,
    buttonHtml,
    href,
    text,
    ...rest
  } = props;

  return (
    <div className={className}>
      <a
        className="cursor-pointer text-sm"
        data-tip
        data-for={id}
        href={href}
        target="_blank"
      >
        {buttonHtml}
      </a>

      <ReactTooltip id={id} aria-haspopup="true" role="example">
        {text}
      </ReactTooltip>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
};

Component.defaultProps = {
  buttonHtml: defButtonHtml,
  className: "ml-2",
  type: "",
  id: "info-button",
  name: "",
  icon: "fa-question-circle",
  text: "Click for help & guide.",
  href: void 0,
  onClick() {},
};
