import React from "react";
import PropTypes from "prop-types";

import { formatCurrency, navToPage } from "../../../functions/common";

//Icons
import { ReactComponent as Icon } from "../../../assets/images/icons/bag.svg";

export default function Component(props) {
  const { className, id, name, onClick, data, enableEdit, ...rest } = props;

  function handleOnClick() {
    if (!enableEdit) {
      navToPage("/admin#/accounts/income_expense/income");
    }
  }

  return (
    <div className={className} id={id}>
      <Icon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-credit-card report-box__icon text-theme-9"
      />

      <div
        className="text-3xl font-medium leading-8 mt-6 cursor-pointer"
        onClick={(e) => handleOnClick(e)}
      >
        {formatCurrency(data)}
      </div>
      <div
        className="text-base text-gray-600 mt-1 cursor-pointer"
        onClick={(e) => handleOnClick(e)}
      >
        Other Incomes
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.number,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "",
  id: "other_incomes",
  name: "",
  data: 0,
  onClick() {},
};
