import React from "react";
import moment from "moment";
import ReactDOMServer from "react-dom/server";

// export function formatElementTH(inputList,inputObject, key) {
//   console.log("format",inputList,inputObject,key)
//   let obj = inputList.find((o) => o[key] === inputObject[key]);
//   let response = [];
//   if (typeof obj !== "undefined") {
//     response.push({ id: obj._id, label: obj[key] });
//   }
//   return response;
// }

export function getAdultMaxDate() {
  return new Date().setFullYear(new Date().getFullYear() - 18);
}

export function preventTabClosing(msg) {
  return window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    return (ev.returnValue = "Are you sure you want to close?");
  });
}

export function isDefined(data) {
  if (typeof data !== "undefined" && data !== null) {
    return true;
  } else {
    return false;
  }
}

export function isEmpty(data) {
  if (
    typeof data === "undefined" ||
    data === "" ||
    data === " " ||
    data === null
  ) {
    return true;
  } else {
    return false;
  }
}

export function commaList(array, key) {
  let result = "";
  try {
    result = Array.prototype.map
      .call(array, function (item) {
        return item[key];
      })
      .join(",");
  } catch (e) {}
  return result;
}

export function navToPage(page) {
  window.location.href = page;
}

export function getColorPalettes() {
  const colors = ["#FF8B26", "#FFC533", "#285FD3"];
  return colors;
}

export function getHoverColorPalettes() {
  const colors = ["#FF8B26", "#FFC533", "#285FD3"];
  return colors;
}

export function stringToHTML(str) {
  var dom = document.createElement("div");
  dom.innerHTML = ReactDOMServer.renderToStaticMarkup(str);
  return dom;
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export function htmlToString(element) {
  return ReactDOMServer.renderToStaticMarkup(element);
}
export function kgToMTStr(string) {
  try {
    string = string / 1000;
    string = string + " MT";
  } catch (e) {}
  return string;
}

export function kgToMT(string) {
  try {
    string = string / 1000;
  } catch (e) {}
  return string;
}

export function capitalizeRmScore(string) {
  return string.toLowerCase().replace(/_/g, " ").capitalize();
}

export function toUpperRmScore(string) {
  return string.toUpperCase().replace(/_/g, " ");
}
export function formatConsStatus(data) {
  let output = <></>;
  let class_name = "";

  switch (data) {
    case "pending":
      class_name = "badge-secondary";
      break;
    case "in_progress":
      class_name = "badge-warning";
      break;

    case "completed":
      class_name = "badge-primary";
      break;

    case "acknowledged":
      class_name = "badge-success";
      break;

    default:
      class_name = "badge-dark";
  }
  output = (
    <span className={"badge " + class_name}>{toUpperRmScore(data)}</span>
  );
  return output;
}

export function toFloat(value, round = 2) {
  if (isNaN(value) || typeof value == "undefined") {
    value = 0;
  }
  let response = parseFloat(value || 0);
  // response = response.toFixed(2);
  // response = Math.round(response * 1e12) / 1e12;
  try {
    response = response.toFixed(round);
    response = Math.round(response * 1e12) / 1e12;
  } catch (e) {}

  return response;
}

export function formatUserAccess(access) {
  let accessData = {
    delete_consignment: false,
    create_consignment: false,
    approve_consignment: false,
    acknowledge_consignment: false,
    create_invoice: false,
    approve_invoice: false,
    generate_invoice: false,
    sumbit_invoice: false,
    cancel_invoice: false,
    approve_master: false,
  };
  for (var i in access) {
    accessData[access[i]] = true;
  }
  return accessData;
}

export function formatModuleAccess(access) {
  let accessData = {
    master: false,
    accounts: false,
    business_party: false,
    user_management: false,
    branch_management: false,
    consignments: false,
    invoices: false,
  };
  for (var i in access) {
    accessData[access[i]] = true;
  }
  return accessData;
}

// TypeAhead

// Selection

export function formatTypeahead(inputList, key, value) {
  let obj = inputList.find((o) => o[key] === value);
  let response = [];
  if (typeof obj !== "undefined") {
    response.push({ id: obj._id, label: obj[key] });
  }
  return response;
}

export function formatTypeaheadCommon(value) {
  let response = [];
  if (typeof value !== "undefined" && value !== "") {
    response.push(value);
  }
  return response;
}

export function formatTypeSearch(data) {
  let response = [];
  if (typeof data == "object") {
    response.push(data);
  } else {
    response = [];
  }
  return response;
}

export function formatTypeaheadString(obj) {
  let response = [];
  if (typeof obj === "string") {
    response.push(obj);
  } else {
    response = [];
  }
  return response;
}

export function formatTypeahead2(obj) {
  let response = [];
  if (typeof obj !== "undefined") {
    response.push(obj);
  } else {
    response.push(obj);
  }
  return response;
}

export function toTypeAheadFormat(data, key) {
  let response = { id: "", label: "" };
  if (typeof data._id !== "undefined" && typeof data[key] !== "undefined") {
    response = { id: data?._id, label: data[key] };
  }
  return response;
}

//////////// VALIDATION ////////////
export function validateComplexForm(errors) {
  let valid = true;
  let keys = Object.keys(errors);
  for (var i in keys) {
    if (Array.isArray(errors[keys[i]])) {
      let array = errors[keys[i]];
      for (var i in array) {
        Object.values(array[i]).forEach(
          (val) => val.length > 0 && (valid = false)
        );
      }
    } else {
      errors[keys[i]].length > 0 && (valid = false);
    }
  }
  return valid;
}

export function validateForm(errors) {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
}

//////////// VALIDATION ////////////

export function checkUndefined(object, key) {
  if (object && object[key] && typeof object[key] !== "undefined") {
    return object[key];
  } else {
    return "";
  }
}

export function formatCurrency(data) {
  data = isNaN(data) ? 0 : data;
  const currency = "₹";
  var output = "";
  try {
    output = currency + data.toLocaleString();
  } catch {
    output = currency + data;
  }
  return output;
}

export function formatElementTH(object, key) {
  let response = [{ id: object._id, name: object[key] }];
  return response;
}

export function matchData(state, data) {
  let keys = Object.keys(state);
  for (var i in keys) {
    if (Array.isArray(data[keys[i]])) {
      let arr = data[keys[i]];
      if (arr.length !== 0) {
        state[keys[i]] = arr;
      }
    } else if (typeof data[keys[i]] !== "undefined") {
      state[keys[i]] = data[keys[i]];
    }
  }
  return state;
}

// export function matchData(state, data) {
//   let keys = Object.keys(state);
//   for (var i in keys) {
//     if (Array.isArray(data[keys[i]])) {
//       let arr = data[keys[i]];
//       if (arr.length !== 0) {
//         state[keys[i]] = data[keys[i]];
//       }
//     } else if (typeof data[keys[i]] !== "undefined") {
//       state[keys[i]] = data[keys[i]];
//     }
//   }
//   return state;
// }

export async function matchArrayObjects(state, data) {
  let keys = Object.keys(state[0]);

  for (var i in data) {
    if (typeof state[i] === "undefined") {
      const obj = await keys.reduce(
        (o, key) => Object.assign(o, { [key]: "" }),
        {}
      );
      state.push(obj);
    }

    for (var j in keys) {
      if (typeof data[i][keys[j]] !== "undefined") {
        if (Array.isArray(data[i][keys[j]])) {
          state[i][keys[j]] = await matchArrayObjects(
            state[i][keys[j]],
            data[i][keys[j]]
          );
        } else {
          state[i][keys[j]] = data[i][keys[j]];
        }
      }
    }
  }

  return state;
}

function getKey(item) {
  return Object.keys(item)[0];
}
export function convertUTC(date) {
  var local_date = date + " UTC";
  local_date = localTime(local_date);
  return local_date;
}

export function localTime(string) {
  var d = new Date(string);
  d.setMilliseconds(0);
  d.setSeconds(0);
  var n = d.toLocaleString([], { dateStyle: "short", timeStyle: "short" });
  return n;
  // return moment(string).format('DD-MM-YYYY, h:mm a');
}

export function localDate(string) {
  let response = "";
  if (
    string != "" &&
    typeof string != "undefined" &&
    string != null &&
    string != undefined
  ) {
    response = moment(string).format("DD-MM-YYYY");
  }
  return response;
}

export function localDateTime(string) {
  return moment(string).format("DD-MM-YYYYTHH:mm");
}

export function htmlDate(string) {
  if (typeof string !== "undefined") {
    return moment(string).format("YYYY-MM-DD");
  } else {
    return "";
  }
}

export function htmlInputDateTime(string) {
  return moment(string).format("YYYY-MM-DDTHH:mm");
}

export function htmlDateTime(string) {
  if (typeof string !== "undefined") {
    return moment(string).format("DD-MM-YYYY h:mm a");
  } else {
    return "";
  }
}

export function mongoUTC(string) {
  return moment(string).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
}

export function getDateDiff(date1, date2) {
  let result = "";
  try {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    var Difference_In_Time = d2.getTime() - d1.getTime();
    result = Difference_In_Time / (1000 * 3600 * 24);
  } catch {
    console.log("Couldn't fidn difference between two days.");
  }
  return Math.round(result);
}

export function checkRunTime(date) {
  var current = new Date();
  current.setMinutes(current.getMinutes() - 1);
  var run_time = new Date(date);
  if (run_time > current) {
    return true;
  } else {
    return false;
  }
}

export function getTodayDate() {
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  today = mm + "/" + dd + "/" + yyyy;
  return today;
}

export function addDate(days) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  date = mm + "/" + dd + "/" + yyyy;
  return date;
}
export const convertArrayToObject = (array) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    var key = getKey(item);
    //var json_item = JSON.parse(item);
    return {
      ...obj,
      //[item[key]]: item,
      [key]: item[key],
    };
  }, initialValue);
};

export function extractDate(date) {
  if (typeof date != "undefined") {
    try {
      var pattern = /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/gi;
      var ext_date = [];
      ext_date = date.match(pattern);
      var new_date = "";
      if (ext_date) {
        new_date = ext_date[0];
      }
      return new_date;
    } catch (e) {
      console.log("exception:", e);
    }
  }
}

export function removePath(fullPath) {
  return fullPath.replace(/^.*[\\\/]/, "");
}

function isObject(item) {
  return typeof item === "object" && !Array.isArray(item) && item !== null;
}

export function JsonToFormData(json) {
  var form_data = new FormData();
  for (var key in json) {
    if (Array.isArray(json[key])) {
      let element = json[key];
      element.forEach((item) => form_data.append(`${key}[]`, item));

      // form_data.append(`${key}[]`, json[key]);
    } else {
      form_data.append(key, json[key]);
    }
  }
  return form_data;
}

export function JsonToFormDataCustom(json) {
  var form_data = new FormData();
  for (var key in json) {
    if (Array.isArray(json[key])) {
      let element = json[key];
      element.forEach((item) => form_data.append(`${key}[]`, item));
      // form_data.append(`${key}[]`, json[key]);
    } else {
      form_data.append(key, json[key]);
    }
  }
  return form_data;
}

// export function JsonToFormData(json) {
//   var form_data = new FormData();
//   for (var key in json) {
//     if (Array.isArray(json[key])) {
//       form_data.append(`${key}[]`, json[key]);
//     } else if (isObject(json[key])) {
//       for (let previewKey in json[key]) {
//         form_data.append(`${key}[${previewKey}]`, json[key][previewKey]);
//       }
//     } else {
//       form_data.append(key, json[key]);
//     }
//   }
//   return form_data;
// }

export function JsonToFormDataObject(json) {
  var form_data = new FormData();
  for (var key in json) {
    if (Array.isArray(json[key])) {
      form_data.append(`${key}[]`, json[key]);
    } else if (isObject(json[key])) {
      for (let previewKey in json[key]) {
        form_data.append(`${key}[${previewKey}]`, json[key][previewKey]);
      }
    } else {
      form_data.append(key, json[key]);
    }
  }
  return form_data;
}

export const getFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export function timeConversion(millisec) {
  var seconds = (millisec / 1000).toFixed(1);

  var minutes = (millisec / (1000 * 60)).toFixed(1);

  var hours = (millisec / (1000 * 60 * 60)).toFixed(1);

  var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

  if (seconds < 60) {
    return seconds + " Sec";
  } else if (minutes < 60) {
    return minutes + " Min";
  } else if (hours < 24) {
    return hours + " Hrs";
  } else {
    return days + " Days";
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function preparePiechartData(array, key_field) {
  var counts_data = {};
  var response = [];

  for (var i in array) {
    let attribute = array[i][key_field].split(" ", 1);
    counts_data[attribute] = counts_data[attribute] || {};
    counts_data[attribute].count = counts_data[attribute].count || 0;

    let old_value = counts_data[attribute].count || 0;
    counts_data[attribute].count = old_value + 1;
  }

  // Creating response
  for (var key in counts_data) {
    response.push({ name: key, value: counts_data[key].count });
  }

  return response;
}

export async function getId(inputList, key, value) {
  let obj = await inputList.find((o) => o[key] === value);
  return obj?._id;
}

export function randomNumber(number = 8) {
  //return Math.random().toString(number).slice(2);
  return Math.random().toFixed(number).split(".")[1];
}

export function Modal() {
  return (
    <div
      className="modal show"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Modal title
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">...</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
