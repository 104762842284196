const Model = {
  isNew: false,
  license_details: false,
  name: "",
  approved: true,
  is_employee: false,
  dob: "",
  contact_number: "",
  license_no: "",
  status: "active",
  valid_from: "",
  valid_to: "",
  license_proof: "",
  display_picture: "",
  sim_tracking_status: "",
};
const ValidationErrorModel = {
  name: "",
  dob: "",
  contact_number: "",
  license_no: "",
  valid_from: "",
  valid_to: "",
  license_proof: "",
  display_picture: "",
  sim_tracking_status: "",
};

export { Model };
export { ValidationErrorModel };
