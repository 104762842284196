import React, { useState, useEffect } from "react";
// Page Defaults
import ProcessLoader from "../../components/preloader/processLoader";
// HTML Components
import Filter from "./filter";
import Container from "../../components/container";
import LRNumbersDisplay from "../../components/htmlElements/lrNumbersDisplay";
import InvoiceActionButtons from "../../components/pageElements/invoiceActionButtons";
import CustomDataTable from "../../components/tables/customDataTable";
import { pdfjs } from "react-pdf";
import PDFViewerModal from "../../components/modal/pdf_viewer";
import HelpLinkButton from "../../components/htmlElements/helpLinkButton";
// Functions
import { alertBox } from "../../components/notification/alert";
import { reactFormatter } from "react-tabulator";
import { resp } from "../../functions/responseHandler";
import {
  randomNumber,
  localDate,
  commaList,
  formatCurrency,
  getDateDiff,
  trimInvoiceNum,
  trimConsNum,
  isDefined,
} from "../../functions/common";
import * as api from "../../api_service/api";
// Report
import { streamInvoiceReport } from "../../functions/reports";
import { storageServices } from "../../functions/storage";

// External Components
import SubmitInvoice from "../../components/messenger/submit_invoice";
import EmailPreview from "../../components/messenger/email_preview";

// Cookies & Storage
import Cookies from "universal-cookie";
const cookies = new Cookies();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const printHeaderTitle = {
  waiting_for_approval: "Prepared Invoices",
  approved: "Approved Invoices",
  invoiced: "Generated Invoices",
  submitted: "Submitted Invoices",
  cancelled: "Cancelled Invoices",
  overdue: "Overdue Invoices",
  "": "",
};

export default function Invoices(props) {
  const tableRef = React.createRef();
  // Page Defaults
  const [isProcessing, setIsProcessing] = useState(false);
  // States
  const [pdfModal, setPdfModal] = useState({
    show: false,
    title: "View Invoice",
    pdfFile: "",
  });

  const closePdfModal = () => {
    let prevState = { ...pdfModal };
    pdfModal["show"] = false;
    pdfModal["pdfFile"] = "";
    setPdfModal(prevState);
  };

  const [state, setState] = useState({ channels: [], selected_invoices: [] });
  const [filters, setFilters] = useState();
  const [invStatus, setInvStatus] = useState();
  const [emailPreview, setEmailPreview] = useState({
    show: false,
    data: {},
    subject: "",
    message: "",
  });

  const [localUser, setLocalUser] = useState();
  const [tableData, setTableData] = useState([]);

  const [submitInvoiceModal, setSubmitInvoiceModal] = useState({
    show: false,
    data: {},
    subject: "",
    message: "",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      await setLocalUser(localUserData);
      // let prevTab = await cookies.get("invoice_tab");
      let inv_status = props.match.params?.status;
      var filters = {};
      if (inv_status) {
        filters["status"] = inv_status;
      } else {
        filters["status"] = "waiting_for_approval";
      }
      loadInvoices(filters, localUserData);
    };
    getUser();
  }, []);

  // <!-- BEGIN: Submit Invoice Modal -- >

  function showSubmitInvoiceModal(data) {
    let oldState = { ...submitInvoiceModal };
    oldState["data"] = data;
    oldState["show"] = true;
    setSubmitInvoiceModal(oldState);
  }
  function closeSubmitInvoiceModal() {
    setSubmitInvoiceModal((prevState) => ({ ...prevState, show: false }));
  }

  async function onLoadSubmitInvoicePreview(invoice_id) {
    const channels = ["email"];

    setIsProcessing(true);
    let query = await prepareSubmitInvoiceData(channels, invoice_id);
    api
      .postService("notification/get/invoice/submit_invoice_template", query)
      .then((response) => {
        setIsProcessing(false);

        const data = response?.data?.data;
        return processSubmitInvoiceTemplat(data);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        return resp.ErrorHandler(error);
      });
  }

  function prepareSubmitInvoiceData(channels, invoice) {
    let invoices = [];

    let body = { channels: channels, invoice: invoice };
    return body;
  }

  function processSubmitInvoiceTemplat(data) {
    showSubmitInvoiceModal(data);
  }

  // <!-- END: Submit Invoice Modal -- >

  function reloadData(filtersData = filters) {
    loadInvoices(filtersData, localUser);
  }

  async function loadInvoices(filtersData, localUserData = localUser) {
    setFilters(filtersData);
    // setTab((prevState) => ({ ...prevState, currentTab: status }));
    // cookies.set("invoice_tab", status, { path: "/" });
    setInvStatus(filtersData.status);
    // Resetting Selected Invoices
    if (state?.selected_invoices?.length > 0) {
      setState((prevState) => ({ ...prevState, selected_invoices: [] }));
    }
    setIsProcessing(true);
    await api
      .postService(`invoice/get`, { filters: filtersData })
      .then(async (response) => {
        var data = response?.data?.data;
        data = await mapData(data);
        await mapTableData(data, filtersData.status, localUserData);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      });
  }

  async function mapData(data) {
    // Extracting LR Number
    for (var i in data) {
      data[i]["lr_number"] = await commaList(
        data[i]["consignment"]["load_receipts"],
        "lr_number"
      );

      // data[i]["overdue_days"] = "";

      // if (data[i]["status"] === "submitted") {
      //   data[i]["overdue_days"] = await getDateDiff(
      //     data[i]["due_date"],
      //     new Date()
      //   );
      // }
    }
    return data;
  }

  // Formatter Functions
  function LRNumbersDisplayFormatter(props) {
    const cellData = props.cell._cell.row.data;
    let lrString = commaList(cellData?.consignment?.load_receipts, "lr_number");

    return (
      <LRNumbersDisplay
        data={lrString}
        id={cellData?.consignment?.consignment_id}
      />
    );
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    const user = props.user;
    const status = props.status;
    return (
      <InvoiceActionButtons
        // approveButton={{
        //   show:
        //     status === "waiting_for_approval" && user?.access?.approve_invoice,
        //   onClick: (e) => {
        //     onApprove(cellData?._id);
        //   },
        // }}
        generateButton={{
          show:
            status === "waiting_for_approval" && user?.access?.generate_invoice,
          onClick: (e) => {
            editRecord(
              "generate",
              cellData?._id,
              cellData?.consignment?.created_business_type
            );
          },
        }}
        downloadButton={{
          show:
            status === "invoiced" ||
            status === "submitted" ||
            status === "closed",
          onClick: (e) => {
            generateReport(cellData?._id, user);
          },
        }}
        submitButton={{
          show: status === "invoiced" && user?.access?.sumbit_invoice,
          onClick: (e) => {
            editRecord(
              "submit",
              cellData?._id,
              cellData?.consignment?.created_business_type
            );
          },
        }}
        sendAndSubmitButton={{
          show: status === "invoiced" && user?.access?.sumbit_invoice,
          onClick: (e) => {
            onLoadSubmitInvoicePreview(cellData?._id, user);
          },
        }}
        reGenerateButton={{
          show:
            (status === "invoiced" ||
              status === "submitted" ||
              status === "closed") &&
            user?.access?.cancel_invoice,
          onClick: (e) => {
            reGenerateRecord(cellData?._id, "current", user);
          },
        }}
        cancelButton={{
          show:
            (status === "invoiced" ||
              status === "submitted" ||
              status === "closed") &&
            user?.access?.cancel_invoice,
          onClick: (e) => {
            cancelRecord(cellData?._id, user);
          },
        }}
        reCreateButton={{
          show: status === "cancelled" && user?.access?.generate_invoice,
          onClick: (e) => {
            reCreateRecord(cellData?._id, "new", user);
          },
        }}

        // cancelButton={{
        //   show: status !== "cancelled" && user?.access?.cancel_invoice,
        //   onClick: (e) => {
        //     cancelRecord(cellData?._id);
        //   },
        // }}
      />
    );
  }

  // Formatter Functions

  function mapTableData(data, status, localUserData) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        hozAlign: "center",
        vertAlign: "middle",
        headerFilter: false,
        headerFilterPlaceholder: "Search...",
        resizable: false,
        headerSort: false,
        print: false,
        download: false,
      },
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false,
        print: false,
        download: false,
        visible: status === "overdue" || status === "invoiced" ? true : false,
        width: 32,
      },
      {
        title: "Cons. Date",
        field: "consignment.consignment_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
        width: 90,
      },
      {
        title: "Invoice No.",
        field: "invoice_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 75,
        visible: status === "waiting_for_approval" ? false : true,
        formatter: function (cell) {
          return `<div className="text-theme-1 font-medium">${trimInvoiceNum(
            cell?.getValue()
          )}</div>
         `;
        },
      },
      {
        title: "Cons. No.",
        field: "consignment.consignment_id",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 75,
        formatter: function (cell) {
          return trimConsNum(cell?.getValue());
        },
      },

      {
        title: "Client",
        field: "client.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 100,
        print: true,
        download: true,
        // formatter: "textarea",
      },

      {
        title: "Submitted Date", // Submitted Date
        field: "submitted_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
        width: 70,
        visible: status === "submitted" || status === "overdue" ? true : false,
      },
      {
        title: "Vehicle",
        field: "consignment.vehicle.registration_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        print: true,
        download: true,
        width: 100,
      },
      {
        title: "Origin",
        field: "consignment.origin.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        print: true,
        download: true,
      },
      {
        title: "Destination",
        field: "consignment.destination.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        print: true,
        download: true,
      },

      {
        title: "LR Number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        field: "lr_number",
        width: 80,
        headerSort: false,
        reponsive: 1,

        // formatter: reactFormatter(<LRNumbersDisplayFormatter />),
        cellClick: function (e) {
          e.stopPropagation();
        },
        print: true,
        download: true,
      },
      {
        title: "Amount",
        field: "total",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
        width: 70,
      },

      {
        title: "Overdue By",
        field: "overdue_days",
        vertAlign: "middle",
        headerSort: true,
        width: 70,
        print: true,
        download: true,
        formatter: function (cell) {
          return cell?.getValue() + " days";
        },
        visible: status === "overdue" ? true : false,
      },

      {
        title: "Actions",
        headerSort: false,
        print: false,
        download: false,
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerVertAlign: "middle",
        formatter: reactFormatter(
          <ActionButtonsLookup user={localUserData} status={status} />
        ),
        cellClick: function (e) {
          e.stopPropagation();
        },
        visible: status === "overdue" ? false : true,
      },
    ];
    setTableData({
      header: columns,
      content: data,
      title: "Invoice",
      filter: true,
      printHeader: printHeaderTitle[status],
      filterFields: [
        { label: "Consignment Id", value: "consignment.consignment_id" },
        { label: "Client", value: "client.name" },
        { label: "Date", value: "consignment.consignment_date" },
      ],
    });
    setIsProcessing(false);
  }
  function onCancel(id, cancel_notes, user) {
    var data = {
      status: "cancelled",
      cancel_notes: cancel_notes,
      cancelled_date: new Date(),
      cancelled_by: user?.userId,
    };

    setIsProcessing(true);
    api
      .putService(`invoice/cancel/${id}`, data)
      .then((response) => {
        resp.ResponseHandler(response);
        setIsProcessing(false);
        reloadData(filters);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      });
  }

  function editRecord(action, id, biz_type) {
    let url = "";
    if (biz_type === "transporter") {
      url = `/admin#/invoices/form/${action}/${id}`;
    } else {
      url = `/admin#/invoices/form-broker/${action}/${id}`;
    }

    window.location.href = url;
  }

  async function cancelRecord(id, user) {
    alertBox.confirmation({
      type: "info",
      title: "Confirm Cancellation!",
      text: "Are you sure want to cancel this Invoice?",
      footer:
        "<small>Note: You can't use this Invoice Number in the future.</small>",
      onConfirm: function () {
        onCancel(id, "", user);
      },
    });
  }

  function onGenerateInvoice(row) {
    var query = {
      invoice_number: randomNumber(),
      invoiced_date: Date.now(),
      status: "invoiced",
      creator_id: row?.creator_id,
      client: row?.client?._id,
      total: row?.total,
    };

    const id = row?._id;
    setIsProcessing(true);
    api
      .putService(`invoice/generate_number/${id}`, query)
      .then((response) => {
        resp.ResponseHandler(response);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      });
  }

  function updateRecord(id, query) {
    setIsProcessing(true);
    api
      .putService(`invoice/${id}`, query)
      .then((response) => {
        resp.ResponseHandler(response);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      });
  }

  async function generateReport(id, localUserData) {
    setIsProcessing(true);
    api.getService(`invoice/${id}`).then(
      async (result) => {
        let pdf_file = await streamInvoiceReport(result?.data, localUserData);
        // console.log("pdf_file;", pdf_file);
        // if (pdf_file) {
        //   let prevState = { ...pdfModal };
        //   prevState["show"] = true;
        //   prevState["pdfFile"] = pdf_file;
        //   setPdfModal(prevState);
        // }
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  async function reGenerateRecord(id, type, user) {
    alertBox.confirmation({
      type: "info",
      title: "Confirm Re-Generation!",
      text: "Are you sure want to regenerate this Invoice?",
      footer:
        "<small>Note: Same Invoice Number will be maintained after regeneration.<br/>If you want to Re generate with New Invoice Number, Cancel this invoice and regenerate it.</small>",
      onConfirm: function () {
        onReGenerateInvoice(id, type);
      },
    });
  }

  async function reCreateRecord(id, type) {
    alertBox.confirmation({
      type: "info",
      title: "Confirm Re-Creation!",
      text: "Are you sure want to recreate this Invoice?",
      footer:
        "<small>Note: A draft invoice will be generated with new invoice number.</small>",
      onConfirm: function () {
        onReGenerateInvoice(id, type);
      },
    });
  }

  async function onReGenerateInvoice(id, type) {
    setIsProcessing(true);
    api.putService(`invoice/regenerate/${id}/${type}`).then(
      async (result) => {
        setIsProcessing(false);

        alertBox.Show({
          type: "success",
          title: result.message,
          text: "New Invoice is available in Prepared Stage.",
        });

        reloadData(filters);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  // Escalation

  // Selecting Invoices
  function selectableRowsHighlight(row) {
    return true;
  }
  function onSelectedRowsChange(data, rows) {
    setState((prevState) => ({ ...prevState, selected_invoices: data }));
  }

  function showEmailPreview(data) {
    let prevState = { ...emailPreview };
    prevState["data"] = data;
    prevState["show"] = true;
    setEmailPreview(prevState);
  }
  function closeEmailPreview() {
    setEmailPreview((prevState) => ({ ...prevState, show: false }));
  }

  function emailPreviewCallBack(data) {
    // closeEmailPreview();
  }

  async function onEscalate(event) {
    const channels = ["email", "whatsapp"];
    event.preventDefault();
    var selectedRows = state.selected_invoices;

    if (selectedRows.length > 0) {
      setIsProcessing(true);
      let query = await prepareEscalationData(channels, selectedRows);
      api
        .postService(
          "notification/get/invoice/overdue_invoices_template",
          query
        )
        .then((response) => {
          setIsProcessing(false);
          const data = response?.data?.data;
          processEmailPreviewTemplate(data);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      resp.Error("No Data Selected.");
    }

    return;
  }

  function prepareEscalationData(channels, selectedData) {
    let invoices = [];
    for (var i in selectedData) {
      invoices.push(selectedData[i]._id);
    }
    let body = { channels: channels, invoices: invoices };
    return body;
  }

  function processEmailPreviewTemplate(data) {
    showEmailPreview(data);
  }

  // Submit Multiple Invoices

  async function onSubmitMultiple(event) {
    event.preventDefault();
    var selectedRows = state?.selected_invoices || [];

    if (selectedRows.length > 0) {
      setIsProcessing(true);
      let preparedArray = await prepareSubmitMultiData(selectedRows);
      console.log("preparedArray:", preparedArray);
      let query = { invoices: preparedArray };
      api
        .putService("invoice/submit/multiple", query)
        .then((response) => {
          setIsProcessing(false);
          resp.Success("Invoices submitted successfully.");
          reloadData(filters);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      resp.Error("No Data Selected.");
    }

    return;
  }
  function prepareSubmitMultiData(data) {
    let result = [];
    for (var i in data) {
      result.push(data[i]?._id);
    }
    return result;
  }
  return (
    <Container>
      {submitInvoiceModal?.show && (
        <SubmitInvoice
          show={submitInvoiceModal?.show}
          data={submitInvoiceModal?.data}
          subject={submitInvoiceModal?.subject}
          message={submitInvoiceModal?.message}
          onHide={closeSubmitInvoiceModal}
          callBack={closeSubmitInvoiceModal}
          id="submit_invoice"
        />
      )}

      {emailPreview?.show && (
        <EmailPreview
          show={emailPreview?.show}
          data={emailPreview?.data}
          subject={emailPreview?.subject}
          message={emailPreview?.message}
          module={"invoice"}
          category={"overdue_invoice"}
          sub_category={""}
          action_type={"escalation"}
          onHide={closeEmailPreview}
          callBack={emailPreviewCallBack}
          id="invoice_escalate"
        />
      )}

      {/* <!-- BEGIN: Header --> */}
      <Container.Header>
        <Container.Title>
          Invoices{" "}
          <HelpLinkButton href="https://corelogis.in/docs-category/app-guide-invoice/" />
        </Container.Title>

        {/* <!-- BEGIN : Action Buttons --> */}
        <div className="ml-auto flex space-x-2 justify-end items-center">
          {invStatus === "overdue" && tableData?.content?.length > 0 && (
            <button
              className="btn btn-success"
              onClick={(e) => onEscalate(e)}
              disabled={state?.selected_invoices?.length > 0 ? false : true}
            >
              <i className="fa fa-paper-plane mr-2" aria-hidden="true"></i>{" "}
              Escalate
            </button>
          )}

          {invStatus === "invoiced" && tableData?.content?.length > 0 && (
            <button
              className="btn btn-success"
              href={void 0}
              onClick={(e) => onSubmitMultiple(e)}
              disabled={state?.selected_invoices?.length > 0 ? false : true}
            >
              <i className="fa fa-check-square-o mr-2" aria-hidden="true"></i>{" "}
              Submit
            </button>
          )}
        </div>
        {/* <!-- END : Action Buttons --> */}
      </Container.Header>
      {/* <!-- END: Header --> */}

      {/*  <!-- BEGIN: Data Table -->  */}
      <Container.Body>
        {isProcessing && <ProcessLoader />}

        {pdfModal.show && (
          <PDFViewerModal
            show={pdfModal.show}
            title={pdfModal.title}
            pdfFile={pdfModal.pdfFile}
            onHide={closePdfModal}
            id={"invoice-pdf-viewer"}
          />
        )}

        {/* <!--BEGIN: PLAY AREA --> */}

        <div className="grid grid-cols-12 gap-2">
          <div
            className="col-span-12 md:col-span-3 xxl:col-span-2 mt-2"
            id="filter-area"
          >
            <Filter loadData={loadInvoices} />
          </div>

          <div className="col-span-12 md:col-span-9 xxl:col-span-10">
            <CustomDataTable
              header={tableData.header}
              content={tableData.content}
              onRowClick={(e, row) => {
                editRecord(
                  "view",
                  row?._row?.data?._id,
                  row?._row?.data?.consignment?.created_business_type
                );
              }}
              printHeader={tableData?.printHeader}
              filter={true}
              filterFields={tableData.filterFields}
              tableRef={tableRef}
              selectableRows={
                invStatus === "overdue" || invStatus === "invoiced"
              }
              selectableRowsHighlight={selectableRowsHighlight}
              onSelectedRowsChange={onSelectedRowsChange}
              layout="fitColumns"
            />
          </div>
        </div>
        {/* <!--END: PLAY AREA --> */}
      </Container.Body>
      {/*  <!-- END: Data Table -->  */}
    </Container>
  );
}
