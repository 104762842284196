const Model = {
  serial_no: "",
  mode: "",
  vehicle: "",
  brand: "",
  sub_brand: "",
  classification: "",
  images: [],
  docs: [],
  type: "",
  position: "",
  fitment_date: "",
  removal_date: "",
  removal_notes: "",
  manufactured_date: "",
  kms_covered: "",
  max_running_limit: "",
  status: "",
  purchase_date: "",
  purchased_from: "",
  purchase_ref_no: "",
  purchase_amount: "",
  warranty_start_date: "",
  warranty_end_date: "",
  warranty_kms: "",
  warranty_docs: [],
};

const ValidationErrorModel = {
  serial_no: "",
  mode: "",
  vehicle: "",
  brand: "",
  sub_brand: "",
  classification: "",
  images: [],
  docs: [],
  type: "",
  position: "",
  fitment_date: "",
  removal_date: "",
  removal_notes: "",
  manufactured_date: "",
  kms_covered: "",
  max_running_limit: "",
  status: "",
  purchase_date: "",
  purchased_from: "",
  purchase_ref_no: "",
  purchase_amount: "",
  warranty_start_date: "",
  warranty_end_date: "",
  warranty_kms: "",
  warranty_docs: [],
};

export { Model };
export { ValidationErrorModel };
