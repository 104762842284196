import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function LRNumbersDisplay(props) {
  var { data, onClick, id, ...rest } = props;

  return (
    <>
      {/* <OverlayTrigger
        key={id}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-top`}>{data}</Tooltip>}
      >
        <div className="w-auto truncate">{data}</div>
      </OverlayTrigger> */}

      <div className="w-auto truncate">
        {" "}
        <span className="tooltip" title={data}>
          {data}
        </span>{" "}
      </div>
    </>
  );
}

LRNumbersDisplay.propTypes = {
  data: PropTypes.string,
  onClick: PropTypes.func,
};

LRNumbersDisplay.defaultProps = {
  data: "",
  onClick() {},
};
