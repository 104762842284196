import React, { useState, useEffect } from "react";
import ProcessLoader from "../../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../../api_service/api";

import InfoButton from "../../htmlElements/infoButton";
import EditButton from "../../htmlElements/editButton";

const Model = {
  name: "",
  type: "",
  status: "active",
  // isDefault: true,
};

export default function Component(props) {
  const [disableType, setDisableType] = useState(props?.disableType || false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [pageTitle, setPageTitle] = useState("Charge");
  const [mode, setMode] = useState({ view: false, edit: false, create: false });
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState(Model);
  const [record_id, setRecordId] = useState();

  // Default State
  const [defaultState, setDefaultState] = useState({
    role: [],
    organization: [],
  });
  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setPageTitle("Create Charge");
        setDefaults(props);
        setMode((prevState) => ({ ...prevState, create: true }));
        break;
      case "edit":
        setPageTitle("Edit Charge");
        setMode((prevState) => ({ ...prevState, edit: true }));
        loadDataById(props.id);
        break;
      case "view":
        setPageTitle("View Charge");
        setMode((prevState) => ({ ...prevState, view: true }));
        loadDataById(props.id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  function setDefaults(props) {
    let newState = { ...Model };
    newState["type"] = props?.type ? props?.type : "addition";
    setState(newState);
  }
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`master/charges/${id}`).then(
      async (result) => {
        const data = result.data;
        setState(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  function prepareData(data) {
    return data;
  }

  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });

    api
      .postService("master/charges", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });
    api
      .putService(`master/charges/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function changeInput(event) {
    const { name, value, checked } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function enableEdit() {
    setUserAction("edit");
    setPageTitle("Edit Charge");
    setMode((prevState) => ({ ...prevState, view: false }));
    setMode((prevState) => ({ ...prevState, edit: true }));
  }

  // UI
  return (
    <div
      key="charges_modal"
      id="charges_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
        centered={true}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">
            {pageTitle}
            {mode?.view && <EditButton onClick={enableEdit} />}
          </h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            className="grid grid-cols-12 gap-4 gap-y-3"
            id="charge_form_master"
            onSubmit={
              mode.create
                ? onSubmit
                : mode.edit
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            <div className="col-span-12">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="eg: Loading Charges"
                disabled={mode.view}
                onChange={changeInput}
                value={state.name}
                required
              />
            </div>

            {!disableType && (
              <div className="col-span-12">
                <label htmlFor="type" className="form-label">
                  Charge Type
                </label>
                <div className="flex flex-col sm:flex-row mt-2">
                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="type_addition"
                      className="form-check-input"
                      type="radio"
                      name="type"
                      value="addition"
                      checked={state?.type === "addition"}
                      onChange={changeInput}
                      disabled={state?.isDefault || mode.view}
                    />{" "}
                    <label
                      className="flex form-check-label"
                      htmlFor="type_addition"
                    >
                      Expense / Charge{" "}
                      <InfoButton
                        id="type_addition_info"
                        className="inline ml-2"
                      >
                        This will be added to the Bill or Freight Amount
                      </InfoButton>
                    </label>{" "}
                  </div>

                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="type_deduction"
                      className="form-check-input"
                      type="radio"
                      name="type"
                      value="deduction"
                      checked={state?.type === "deduction"}
                      onChange={changeInput}
                      disabled={state?.isDefault || mode.view}
                    />{" "}
                    <label
                      className="flex form-check-label"
                      htmlFor="type_deduction"
                    >
                      Deduction
                      <InfoButton id="type_deduction_info" className="ml-2">
                        This will be reduced from the Bill or Freight Amount
                      </InfoButton>
                    </label>{" "}
                  </div>
                </div>
              </div>
            )}

            <input type="submit" id="save_charges_master" className="hidden" />
            <input
              type="submit"
              id="update_charges_master"
              className="hidden"
            />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {mode?.create && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_charges_master"
            >
              Create
            </label>
          )}
          {mode?.edit && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_charges_master"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
    // </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Charge",
  action: "view",
  onSave() {},
  callBack() {},
};
