import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// Sidebar Library
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import "../../assets/sass/components/_side-menu.scss";

import { MenuList } from "./menu.config.js";

export default function SideMenu(props) {
  const {
    collapsed,
    toggled,
    handleToggleSidebar,
    localUser,
    userAccessList,
    onChangeMenuLayout,
  } = props;
  // Setting Current Page
  const currentLocation = useLocation();

  const [currentPage, setCurrentPage] = useState("");
  const [state, setState] = useState({
    main_menu: "",
    sub_menu1: "",
    sub_menu2: "",
    sub_menu3: "",
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadInit = async () => {
      await initCurrentPage();
      setIsLoaded(true);
    };
    loadInit();
    // setState(item);
  }, [currentPage]);

  function initCurrentPage() {
    let path = currentLocation?.pathname;
    if (typeof path !== "undefined") {
      const pathName = path.split("/");
      if (pathName.length > 1) {
        let page = "/" + pathName[1];
        setCurrentPage(page);
        // setState((prevState) => ({ ...prevState, main_menu: pathName[1] }));
      }
    }
  }

  function activateMenu(main_menu, sub_menu1, sub_menu2, sub_menu3) {
    let prevState = { ...state };
    prevState.main_menu = main_menu;
    prevState.sub_menu1 = sub_menu1;
    prevState.sub_menu2 = sub_menu2;
    prevState.sub_menu3 = sub_menu3;

    setState(prevState);
  }

  function checkMenuActive() {}

  function menuOnClick(url) {
    console.log("url:", url);
  }

  return (
    <>
      <div id="header">
        {/* <!-- BEGIN: Menu --> */}
        <ProSidebar
          collapsed={collapsed}
          toggled={toggled}
          breakPoint="md"
          onToggle={handleToggleSidebar}
        >
          <SidebarContent>
            <Menu>
              {MenuList.map((item, index) => {
                const {
                  label,
                  Icon,
                  url,
                  children,
                  requirePermission,
                  checkPermission,
                } = item;
                const key = `${label}-${index}`;

                //////////  <!-- BEGIN:  If has children --> /////////////
                if (children) {
                  // If Permission is Required
                  if (requirePermission) {
                    if (!userAccessList.includes(checkPermission)) {
                      return <></>;
                    }
                  }
                  return (
                    <SubMenu title={label} icon={<Icon />} key={key}>
                      {/* <!--Looping Children 1 -->*/}
                      {children.map((child_item, child_index) => {
                        const {
                          label,
                          Icon,
                          url,
                          children,
                          requirePermission,
                          checkPermission,
                        } = child_item;
                        const child_key = `${label}-${child_index}`;

                        {
                          /* <!--Looping Children 2 -->*/
                        }
                        if (children) {
                          // If Permission is Required
                          if (requirePermission) {
                            if (!userAccessList.includes(checkPermission)) {
                              return <></>;
                            }
                          }

                          return (
                            <SubMenu title={label} icon={<Icon />} key={key}>
                              {/*  <!--Looping Children 2 childs --> */}
                              {children.map((item, index) => {
                                const {
                                  label,
                                  Icon,
                                  url,
                                  children,
                                  requirePermission,
                                  checkPermission,
                                } = item;
                                const key = `${label}-${index}`;

                                //* <!--Looping Children 3 -->*/

                                if (children) {
                                  // If Permission is Required
                                  if (requirePermission) {
                                    if (
                                      !userAccessList.includes(checkPermission)
                                    ) {
                                      return <></>;
                                    }
                                  }

                                  return (
                                    <SubMenu
                                      title={label}
                                      icon={<Icon />}
                                      key={key}
                                    >
                                      {/*  <!--Looping Children 2 childs --> */}
                                      {children.map((item, index) => {
                                        const {
                                          label,
                                          Icon,
                                          url,
                                          children,
                                          requirePermission,
                                          checkPermission,
                                        } = item;
                                        const key = `${label}-${index}`;

                                        // If Permission is Required
                                        if (requirePermission) {
                                          if (
                                            !userAccessList.includes(
                                              checkPermission
                                            )
                                          ) {
                                            return <></>;
                                          }
                                        }
                                        return (
                                          <MenuItem icon={<Icon />} key={key}>
                                            {label}
                                            <Link
                                              to={url}
                                              onClick={() => menuOnClick(url)}
                                            />
                                          </MenuItem>
                                        );
                                      })}
                                      {/*  <!--Looping Children 2 childs --> */}
                                    </SubMenu>
                                  );
                                }

                                //* <!--Looping Children 3 -->*/

                                // If Permission is Required
                                if (requirePermission) {
                                  if (
                                    !userAccessList.includes(checkPermission)
                                  ) {
                                    return <></>;
                                  }
                                }
                                return (
                                  <MenuItem icon={<Icon />} key={child_key}>
                                    {label}
                                    <Link to={url} />
                                  </MenuItem>
                                );
                              })}
                              {/*  <!--Looping Children 2 childs --> */}
                            </SubMenu>
                          );
                        }

                        {
                          /* <!--Looping Children 2 -->*/
                        }

                        // If Permission is Required
                        if (requirePermission) {
                          if (!userAccessList.includes(checkPermission)) {
                            return <></>;
                          }
                        }
                        return (
                          <MenuItem icon={<Icon />} key={child_key}>
                            {label}
                            <Link to={url} />
                          </MenuItem>
                        );
                      })}
                      {/*<!-- Looping Children 1 -->*/}
                    </SubMenu>
                  );
                }

                //////////  <!-- END:  If has children --> /////////////

                //////////  <!-- BEGIN:  Single Element --> /////////////

                // If Permission is Required
                if (requirePermission) {
                  if (userAccessList.includes(checkPermission)) {
                    return (
                      <MenuItem icon={<Icon />} key={key}>
                        {label}
                        <Link to={url} />
                      </MenuItem>
                    );
                  } else return <></>;
                }
                // Permission not Required
                else {
                  return (
                    <MenuItem
                      icon={<Icon />}
                      key={key}
                      active={currentPage === url}
                    >
                      {label}
                      <Link to={url} onClick={() => menuOnClick(url)} />
                    </MenuItem>
                  );
                }

                //////////  <!-- END:  Single Element --> /////////////
                // return (
                <></>;
                // <NavItem
                //   key={`${item.label}-${index}`}
                //   item={item}
                //   accessList={userAccessList}
                // />
                // );
              })}

              {/* Appearance Menu */}
              {/* <SubMenu title={"Appearance"} icon={<IconPalette />}>
                <MenuItem icon={<IconList />}>
                  <div
                    onClick={() => {
                      onChangeMenuLayout("topmenu");
                    }}
                  >
                    Top Menu
                  </div>
                </MenuItem>
              </SubMenu> */}
              {/* Appearance Menu */}
            </Menu>
          </SidebarContent>
        </ProSidebar>
        {/* <!-- END: Menu --> */}
      </div>
    </>
  );
}
