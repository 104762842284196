import React, { useState, useEffect } from "react";
import Preloader from "../../components/preloader/preloader";
import Error from "../../components/error/error";
// HTML ELEMENTS
import Container from "../../components/container";
import StageTab from "../../components/tabs/stageTab";
// PAGES
// import Startup from "./startup";
import Operational from "./operational";
import Accounts from "./accounts";
// Functions
import { alertBox } from "../../components/notification/alert";

// Cookies & Storage
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Dashboard(props) {
  console.log("props:", props);
  const isFirstTimeLogin =
    props.match?.params?.isFirstTimeLogin === "first_time_login" ? true : false;

  const [error, setError] = useState(null);
  const accontsLoad = cookies.get("dashboard_load_accounts") || false;
  const [loadAccounts, SetLoadAccounts] = useState(false);

  const [activeTab, setActiveTab] = useState(
    cookies.get("dashboard_tab") || "operational"
  );
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    loadData();
    if (isFirstTimeLogin) {
      hanldeFirstTimeLogin();
    }
  }, []);

  function loadData() {
    setIsLoaded(true);
  }

  function hanldeFirstTimeLogin() {
    alertBox.Show({
      type: "",
      title: "Welcome on board!",
      html: "You are in demo mode. You can start exploring by consignment creation:<br/><br/><b>Consignments -> Create</b>",
      footer:
        "<small>You can switch to production mode by creating a new branch from<br/><b>Branches -> New Branch</b></small>",
      imageUrl: "https://cdn-icons-png.flaticon.com/512/1054/1054436.png",
      imageHeight: 128,
      confirmButtonColor: "#00b894",
      confirmButtonText: "Explore",
    });
  }

  function onChangeToggle(value) {
    // event.preventDefault();

    cookies.set("dashboard_load_accounts", value, { path: "/" });
    console.log("value:", value);
    SetLoadAccounts(value);
  }

  function onChangeActiveTab(tab) {
    setActiveTab(tab);
    cookies.set("dashboard_tab", tab, { path: "/" });
  }

  const TabSwitcher = () => {
    return (
      <StageTab>
        <StageTab.Tab
          id="operational-dashboard"
          active={activeTab === "operational" ? true : false}
          // onClick={(e) => navToPage("/admin#/dashboard/operational")}
          onClick={(e) => onChangeActiveTab("operational")}
        >
          Operational
        </StageTab.Tab>{" "}
        <StageTab.Tab
          id="accounts-dashboard"
          active={activeTab === "accounts" ? true : false}
          // onClick={(e) => navToPage("/admin#/accounts/dashboard")}
          onClick={(e) => onChangeActiveTab("accounts")}
        >
          Accounts
        </StageTab.Tab>
      </StageTab>
    );
  };

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <Container>
        {/* <!-- BEGIN: TopBar --> */}

        <div className="flex items-center mt-2 mb-2 z-50 ml-4">
          <div id="accounts_toggle" className="flex items-center">
            <span className="text-gray-600 mr-2">Accounts</span>
            <input
              id="accounts_toggle_switch"
              name="accounts_toggle_switch"
              className="form-check-switch "
              type="checkbox"
              onChange={(e) => onChangeToggle(!loadAccounts)}
              checked={loadAccounts === true}
              value={loadAccounts}
            />
          </div>
          {/* <TabSwitcher /> */}
          {/* <WidgetOptions
            className="ml-auto"
            onSave={onLayoutSave}
            enableEdit={state?.enableEdit}
            onEnableEdit={onEnableLayoutEdit}
          /> */}
        </div>
        {/* <!-- END: TopBar --> */}

        {/* <!-- BEGIN: CONTENT -- > */}
        {loadAccounts && <Accounts />}
        {!loadAccounts && <Operational />}
        {/* {activeTab === "operational" && <Operational />}
        {activeTab === "accounts" && <Accounts />} */}
        {/* <!-- END: CONTENT -- > */}
      </Container>
    );
  }
}
