import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";

import CityMasterCustom from "./city-custom";

import AddressInput from "../htmlElements/addressInput";

import { matchData, forwardGeocode } from "../../functions/common";

import GoogleMapLocator from "./google-map-locator";

const Model = {
  state: "",
  name: "",
  place: "",
  status: "active",
  isNew: false,
  isCustom: false,
  isValid: false,
  address: "",
  // GPS Data
  label: "",
  latitude: "",
  longitude: "",
  street: "",
  city: "",
  district: "",
  country: "",
  country_code: "",
  state: "",
  state_code: "",
  continent: "",
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [saveToDB, setSaveToDB] = useState(true);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [mapState, setMapState] = useState({ showMap: false });
  const [record_id, setRecordId] = useState();
  const [defaultState, setDefaultState] = useState({
    state: [],
  });
  const [customFormModal, setCustomFormModal] = useState({
    show: false,
    action: "create",
  });

  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    setUserAction(props.action);
    await loadDefaultMetrics();
    switch (props.action) {
      case "create":
        setDefaults();
        setViewMode(false);
        break;
      case "edit":
        setViewMode(false);
        loadDataById(props.id);
        break;
      case "view":
        setViewMode(true);
        loadDataById(props.id);
        break;
      default:
        setViewMode(true);
    }
  }

  async function setDefaults() {
    let prevState = { ...Model };
    if (props?.name) prevState["name"] = props?.name;
    setState(prevState);
  }
  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`master/location/${id}`).then(
      async (result) => {
        const data = result.data;
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  async function loadDefaultMetrics() {
    if (defaultState.state.length === 0) {
      setIsProcessing(true);
      await api
        .getService("master/state")
        .then(async (response) => {
          await setDefaultState((prevState) => ({
            ...prevState,
            state: response?.data?.data,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    }
  }

  // Data Mapping
  async function mapData(data) {
    let prevState = data;
    await setState(prevState);
  }

  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    var query = { ...state };
    query["isNew"] = true;
    if (saveToDB) {
      setIsProcessing(true);
      api
        .postService("master/location", query)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      props.callBack(query);
    }
    return null;
  }

  function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .putService(`master/location/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // Google Map API FUnctions ////////////

  function changeInputAddress(address) {
    setState((prevState) => ({ ...prevState, name: address }));
    if (address.length === 0) {
      setState((prevState) => ({ ...prevState, isValid: false }));
    }
  }

  async function handleSelectAddress(address) {
    let prevState = { ...state };
    setState((prevState) => ({ ...prevState, name: address }));
    setState((prevState) => ({ ...prevState, isValid: true }));
    prevState.name = address;

    await forwardGeocode(address)
      .then(async (data) => {
        let modelData = { ...Model };
        let matchedData = await matchData(modelData, data);
        matchedData["name"] = address;
        setState(matchedData);
      })
      .catch((error) => {
        console.log("Error Parsing Address:", error);
      });
  }

  // <!-- BEGIN: Client Master Modal -- >

  const openCustomFormModal = () =>
    setCustomFormModal((prevState) => ({ ...prevState, show: true }));

  const closeCustomFormModal = () =>
    setCustomFormModal((prevState) => ({ ...prevState, show: false }));

  function customFormModalCallBack(data) {
    closeCustomFormModal();
  }

  function onPositionChange(data) {
    let matchedData = matchData({ ...state }, data);
    matchedData["name"] = matchedData["label"];
    setState(matchedData);
  }

  function enableLocateOnMap() {
    setMapState((prevState) => ({ ...prevState, showMap: true }));
  }

  // <!-- END: City Master Modal -- >

  return (
    <>
      {/* <!-- BEGIN: CustomForm Modal Modal --> */}
      {customFormModal.show && (
        <CityMasterCustom
          show={customFormModal.show}
          action={customFormModal.action}
          saveToDB={true}
          onHide={closeCustomFormModal}
          callBack={customFormModalCallBack}
        />
      )}
      {/* <!-- END: Transits Modal--> */}

      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>
        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
            id="location-master-form"
            className="grid grid-cols-12 gap-4 gap-y-3"
          >
            <div className="col-span-12">
              <label className="form-label">Address</label>
              <AddressInput
                className="form-control"
                name="name"
                value={state?.name}
                type="text"
                disabled={viewMode}
                onChange={changeInputAddress}
                onSelect={handleSelectAddress}
                required
              />
            </div>

            {/* <!-- Begin: Other Details --> */}
            <>
              {/* <div className="col-span-12">
                <div className="text-gray-600">Place:</div>
                <div className="font-medium ml-2">{state?.place}</div>
              </div> */}

              <div className="col-span-12 sm:col-span-4 flex flex-col sm:flex-row">
                <div className="text-gray-600">City:</div>
                <div className="font-medium ml-2">{state?.city}</div>
              </div>

              <div className="col-span-12 sm:col-span-4 flex flex-col sm:flex-row">
                <div className="text-gray-600">State:</div>
                <div className="font-medium ml-2">{state?.state}</div>
              </div>

              <div className="col-span-12 sm:col-span-4 flex flex-col sm:flex-row">
                <div className="text-gray-600">Country:</div>
                <div className="font-medium ml-2">{state?.country}</div>
              </div>

              <div className="col-span-12 sm:col-span-4 flex flex-col sm:flex-row">
                <div className="text-gray-600">Latitude:</div>
                <div className="font-medium ml-2">{state?.latitude}</div>
              </div>

              <div className="col-span-12 sm:col-span-4 flex flex-col sm:flex-row">
                <div className="text-gray-600">Longitude:</div>
                <div className="font-medium ml-2">{state?.longitude}</div>
              </div>
            </>
            {/* <!-- End: Other Details --> */}
            <div className="col-span-12">
              <div className="w-full border-t border-gray-200 dark:border-dark-5">
                <button
                  type="button"
                  className="w-full btn btn-secondary"
                  onClick={enableLocateOnMap}
                >
                  <i
                    className="fa fa-map-marker text-theme-6 mr-2"
                    aria-hidden="true"
                  ></i>
                  Locate on Map
                </button>
              </div>
            </div>

            {mapState?.showMap && (
              <div className="col-span-12">
                <GoogleMapLocator
                  callBack={onPositionChange}
                  show={mapState?.showMap}
                  latitude={state?.latitude}
                  longitude={state?.longitude}
                />
              </div>
            )}

            {/* <div className="col-span-12">
            <label className="form-label">State</label>
            <select
              className="form-control"
              name="state"
              value={state.state}
              onChange={changeInput}
              disabled={viewMode}
              required
            >
              <option value="" selected disabled>
                Select
              </option>
              {defaultState.state.map((item, i) => {
                return <option value={item._id}>{item.name}</option>;
              })}
            </select>
          </div>
          <div className="col-span-12">
            <label className="form-label" htmlFor="name">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="eg: Chennai"
              disabled={viewMode}
              onChange={changeInput}
              value={state.name}
              required
            />
          </div>
          <div className="col-span-12">
            <label className="form-label" htmlFor="place">
              Place
            </label>
            <input
              type="text"
              className="form-control"
              id="place"
              name="place"
              placeholder="eg: Anna Nagar"
              disabled={viewMode}
              onChange={changeInput}
              value={state.place}
              required
            />
          </div> */}
            <input type="submit" id="save_location" className="hidden" />
            <input type="submit" id="update_location" className="hidden" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_location"
              disabled={!state?.isValid}
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_location"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Location Master",
  action: "view",
  callBack() {},
};
