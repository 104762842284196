import React from "react";
import PropTypes from "prop-types";

import { formatCurrency, toFloat } from "../../../functions/common";
import DividerText from "../../../components/htmlElements/dividerText";

export default function Component(props) {
  const { className, id, name, onClick, data, ...rest } = props;

  // CASH / BANK
  let totalBalance = 0;
  let cashBalance = 0;

  // Calculating cash balance
  let cashMaster = data?.cash;
  for (var i in cashMaster) {
    let totalCash = toFloat(
      cashMaster[i].opening_balance + cashMaster[i].current_total
    );
    cashBalance += totalCash;
    totalBalance += totalCash;
  }

  // Calculating Bank Balance

  let bank_list = data?.bank;

  for (var i in bank_list) {
    totalBalance += toFloat(
      bank_list[i].opening_balance + bank_list[i].current_total
    );
    bank_list[i]["current_balance"] =
      bank_list[i].opening_balance + bank_list[i].current_total;
  }

  return (
    <div className={className} id="cash-bank-balance">
      <div className="p-5">
        <div className="mb-2">
          <div className="text-base text-gray-700 truncate inline">
            {"Cash Balance"}
          </div>
          <div className="inline text-base  font-medium  float-right">
            {formatCurrency(toFloat(cashBalance, 0))}
          </div>
        </div>

        {data?.bank.length > 0 && <DividerText>Bank Balance</DividerText>}

        {data?.bank.map((item, index) => (
          <div className="mb-1 flex" key={index} id={`bank_ac_${index}`}>
            <div className="text-gray-700 break-all inline w-3/4">
              {item?.name}
            </div>
            <div className="inline font-medium ml-auto w-1/4">
              {formatCurrency(toFloat(item?.current_balance || 0, 0))}
            </div>
          </div>
        ))}

        <div className="border-t border-gray-200 dark:border-dark-5">
          <div className="mt-5">
            <div className="text-base text-gray-700 truncate inline">
              <small>TOTAL BALANCE</small>
            </div>
            <div className="inline text-base text-theme-1 font-medium  float-right">
              {formatCurrency(toFloat(totalBalance, 0))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.number,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "",
  id: "cash_bank_balance",
  name: "",
  data: 0,
  onClick() {},
};
