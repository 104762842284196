import React, { useState, useEffect } from "react";
// HTML Elements
import ProcessLoader from "../../preloader/processLoader";
import ImageNFileInput from "../../inputs/imagenfile";
import ValidationError from "../../error/validationError";
import { Modal } from "react-bootstrap";
import KmInput from "../../htmlElements/kmInput";
import CurrencyInput from "../../htmlElements/currencyInput";
import LitreInput from "../../htmlElements/litreInput";
import TypeAhAddNewButton from "../../htmlElements/typeAhAddNewButton";
import CurrentOdometerLabel from "../../pageElements/currentOdometerLabel";
import TruckNumberDisplay from "../../consignment/truckNumberDisplay";
import ConsNumberDisplay from "../../consignment/consNumberDisplay";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

import Form from "../../form/custom";
import DividerText from "../../htmlElements/dividerText";

// Add-on Components

import VehicleMasterForm from "../vehicle";
import SupplierMasterForm from "../supplier";
import CashMasterForm from "../accounts/cash";
import BankMasterForm from "../accounts/bank";

// Functions
import { resp } from "../../../functions/responseHandler";
import { storageServices } from "../../../functions/storage";
import * as api from "../../../api_service/api";
import {
  htmlDate,
  matchData,
  JsonToFormData,
  formatTypeSearch,
  isDefined,
  toFloat,
  randomNumber,
  clearNullValues,
} from "../../../functions/common";
import * as validation from "../../../functions/validation";

// Models
import { Model, ValidationErrorModel } from "./model";

const fileBaseURL = process.env.REACT_APP_VEHICLE_URL;
export default function FuelSlipForm(props) {
  const { vehicle, consignment, request_module } = props;
  const [saveToDB, setSaveToDB] = useState(true);
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
    approve: false,
  });

  // State
  const [state, setState] = useState({ ...Model });
  const [validationError, setValidationError] = useState({
    ...ValidationErrorModel,
  });
  const [localUser, setLocalUser] = useState();

  const [disabledFields, setDisabledFields] = useState({
    vehicle: false,
    consignment: false,
  });

  const [typeAheadFields, setTypeAheadFields] = useState({
    vehicle: "",
    consignment: "",
    supplier: "",
  });

  //////////// External Modal States   ////////////
  const [vehicleMasterModal, setVehicleMasterModal] = useState({
    show: false,
    title: "Add Vehicle",
    action: "create",
    id: "",
  });

  const [supplierMasterModal, setSupplierMasterModal] = useState({
    show: false,
    title: "Add Supplier",
    action: "create",
    id: "",
    supplier_type: "",
  });

  const [cashMasterModal, setCashMasterModal] = useState({
    show: false,
    title: "Add Cash Book",
    action: "create",
    id: "",
  });

  const [bankMasterModal, setBankMasterModal] = useState({
    show: false,
    title: "Add Bank Account",
    action: "create",
    id: "",
  });

  // <!-- BEGIN: Vehicle Master Modal -- >
  const openVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeVehicleMasterModal = () =>
    setVehicleMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadVehicleMasterData(data) {
    closeVehicleMasterModal();
    let event = {};
    loadDefaultMetrics(event, "vehicle", true, data, "vehicle");
  }
  // <!-- END: Vehicle Master Modal -- >

  // <!-- BEGIN: Cash Master Modal -- >
  const openCashMasterModal = () =>
    setCashMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeCashMasterModal = () =>
    setCashMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadCashMasterData(data) {
    closeCashMasterModal();
    let event = {};
    loadDefaultMetrics(event, "cash", true);
  }
  // <!-- END: Cash Master Modal -- >

  // <!-- BEGIN: Bank Master Modal -- >
  const openBankMasterModal = () =>
    setBankMasterModal((prevState) => ({ ...prevState, show: true }));
  const closeBankMasterModal = () =>
    setBankMasterModal((prevState) => ({ ...prevState, show: false }));
  function reloadBankMasterData(data) {
    closeBankMasterModal();
    let event = {};
    loadDefaultMetrics(event, "bank", true);
  }
  // <!-- END: Bank Master Modal -- >

  // <!-- BEGIN: Petrol Master Modal -- >
  function addSupplierMaster(supplier_type) {
    let prevState = { ...supplierMasterModal };
    prevState.supplier_type = supplier_type;
    prevState.action = "create";
    prevState.show = true;
    setSupplierMasterModal(prevState);
  }
  const closeSupplierMasterModal = () =>
    setSupplierMasterModal((prevState) => ({ ...prevState, show: false }));

  function reloadSupplierMasterData(data, supplier_type) {
    closeSupplierMasterModal();
    let event = {};
    loadDefaultMetrics(event, supplier_type, true);
  }
  // <!-- END: Petrol Master Modal -- >

  //////////// External Modal States   ////////////

  const [record_id, setRecordId] = useState();
  const [defaultState, setDefaultState] = useState({
    consignment: [],
    vehicle: [],
    supplier: [],
    cash: [],
    bank: [],
  });
  const [pageTitle, setPageTitle] = useState("Fuel Slip");
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction(props);
  }, []);

  async function handleUserAction(props) {
    let action = props.action;
    let id = props.id;

    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    switch (action) {
      case "create":
        setPageTitle("Fuel Slip");
        setMode((prevState) => ({ ...prevState, create: true }));
        setDefaults(props);
        // await loadDefaultMetrics();

        break;
      case "view":
        setPageTitle("Fuel Slip");
        setMode((prevState) => ({ ...prevState, view: true }));
        // await loadDefaultMetrics();
        loadDataById(id);
        break;
      case "edit":
        setPageTitle("Fuel Slip");
        setMode((prevState) => ({ ...prevState, edit: true }));
        // await loadDefaultMetrics();
        loadDataById(id);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };

    if (isDefined(vehicle) && isDefined(vehicle["_id"])) {
      prevState["vehicle"] = vehicle;
      console.log("vehicle:", vehicle);
      let vehicle_odo = vehicle?.odometer_reading || "";
      prevState["current_odometer_reading"] = vehicle_odo;
      prevState["odometer_reading"] = vehicle_odo;
      setDisabledFields((prevState) => ({ ...prevState, vehicle: true }));
    }

    if (isDefined(consignment) && isDefined(consignment["_id"])) {
      prevState["consignment"] = consignment;
      setDisabledFields((prevState) => ({ ...prevState, consignment: true }));
    }

    // Checking Requesting Module
    if (request_module === "consignment") {
      setDisabledFields((prevState) => ({ ...prevState, vehicle: true }));
      setDisabledFields((prevState) => ({ ...prevState, consignment: true }));
    }

    prevState.date = htmlDate(new Date());
    prevState.billed_date = htmlDate(new Date());
    prevState.fuel_type = "diesel";
    prevState.filling_type = "refill";
    prevState.fuel_filling = "partial";
    prevState.payment_mode = "cash";
    prevState.slip_no = "FL-" + randomNumber(4);

    setState(prevState);
    setIsProcessing(false);
    return null;
  }

  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`fuel/slip/${id}`).then(
      async (result) => {
        const data = result.data;

        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    let matchedData = await matchData(state, data);
    matchedData = await clearNullValues(matchedData);
    matchedData.date = htmlDate(matchedData.date);
    matchedData.billed_date = htmlDate(matchedData.billed_date);
    matchedData["current_odometer_reading"] =
      matchedData?.vehicle?.odometer_reading || 0;

    // Checking Requesting Module
    if (request_module === "consignment") {
      setDisabledFields((prevState) => ({ ...prevState, vehicle: true }));
      setDisabledFields((prevState) => ({ ...prevState, consignment: true }));
    }

    await setState(matchedData);
  }

  // Input Change
  function changeTypeHeadInput(selected, name) {
    let prevState = { ...state };

    if (selected.length != 0) {
      let data = selected[0];
      prevState[name] = data;
      setState(prevState);
      if (name === "consignment") {
        setVehicleFromConsignment(data?.vehicle, prevState);
      }
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
      if (name === "consignment") {
        setDisabledFields((prevState) => ({ ...prevState, vehicle: false }));
      }
    }
    formValidation(name, selected);
  }

  // Input Change
  function changeTypeHeadInputVehicle(selected, name) {
    let vehicle_odometer_reading = 0;
    let prevState = { ...state };
    if (selected.length != 0) {
      let data = selected[0];
      prevState[name] = data;
      // Odometer Reading
      vehicle_odometer_reading = data["odometer_reading"] || 0;
    } else {
      vehicle_odometer_reading = 0;
      prevState[name] = "";
    }
    // Odometer Reading
    prevState["current_odometer_reading"] = vehicle_odometer_reading;
    prevState["odometer_reading"] = vehicle_odometer_reading;
    setState(prevState);
    formValidation(name, selected);
  }

  function setVehicleFromConsignment(data, prevState) {
    let vehicle_odometer_reading = 0;
    prevState["vehicle"] = data;
    // Odometer Reading
    vehicle_odometer_reading = data?.odometer_reading || 0;
    prevState["current_odometer_reading"] = vehicle_odometer_reading;
    prevState["odometer_reading"] = vehicle_odometer_reading;
    setState(prevState);
    setDisabledFields((prevState) => ({ ...prevState, vehicle: true }));
  }

  function changeInput(event) {
    const { name, value, required } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    // Amount Calculation
    if (name === "quantity") {
      calcAmount(value, state?.unit_price);
    }
    if (name === "unit_price") {
      calcAmount(state?.quantity, value);
    }
    formValidation(name, value);
  }

  function calcAmount(quantity, unit_price) {
    let amount = toFloat(quantity || 0) * toFloat(unit_price || 0);
    amount = toFloat(amount).toFixed(2);

    setState((prevState) => ({ ...prevState, amount: amount }));
  }

  async function changeInputImage(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImage(files[0]);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  async function changeInputFile(event) {
    const { name, files } = event.target;
    let errors = { ...validationError };
    if (files[0]) {
      let imageValidation = await validation.validateImageNFile(files[0], 5);
      if (imageValidation?.status) {
        errors[name] = imageValidation?.msg;
        setState((prevState) => ({ ...prevState, [name]: "" }));
      } else {
        errors[name] = "";
        setState((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      errors[name] = "";
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
    setValidationError(errors);
  }

  function deleteInputImage(event, name) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [name]: "" }));
  }

  // FORM VALIDATION

  function formValidation(name, value) {
    let errors = validationError;
    switch (name) {
      case "registration_number":
        errors[name] = validation.validateRegistrationNo(value);
        break;

      default:
        break;
    }
    // setValidationError(errors);
  }

  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  async function prepareData(data) {
    let newData = { ...data };
    newData.vehicle = newData?.vehicle?._id;

    // consignment
    if (isDefined(newData?.consignment)) {
      newData["consignment"] = newData?.consignment?._id;
    } else {
      newData["consignment"] = null;
    }

    // Cash
    if (isDefined(newData?.supplier)) {
      newData["supplier"] = newData?.supplier?._id;
    } else {
      newData["supplier"] = null;
    }

    // Cash
    if (newData.payment_mode === "cash" && isDefined(newData?.cash)) {
      newData["cash"] = newData?.cash?._id;
    } else {
      newData["cash"] = null;
    }
    // Bank
    if (newData.payment_mode === "bank" && isDefined(newData?.bank)) {
      newData["bank"] = newData?.bank?._id;
    } else {
      newData["bank"] = null;
    }
    return newData;
  }
  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));

      let data = await prepareData({ ...state });
      data["status"] = "active";

      console.log("data:", data);

      if (saveToDB) {
        setIsProcessing(true);
        api
          .postService("fuel/slip", data)
          .then((response) => {
            setIsProcessing(false);
            resp.Success(response?.data?.message);
            return props.callBack(data, event);
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            resp.ErrorHandler(error);
          });
      } else {
        return props.callBack(data, event);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onUpdate(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateForm(validationError)) {
      setIsValidForm((prevState) => ({ ...prevState, valid: true }));
      setIsProcessing(true);
      let data = await prepareData({ ...state });

      api
        .putService(`fuel/slip/${record_id}`, data)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function loadDefaultMetrics(event, key, reload = false) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (defaultState[key].length === 0 || reload) {
      switch (key) {
        case "consignment":
          url = "consignment";
          await fetchDefaultMetrics(url, key);
          break;

        case "vehicle":
          url = "vehicle/all/own";
          await fetchDefaultMetrics(url, key);
          break;

        case "supplier":
          url = `master/supplier/type/petrol_bunk/active`;
          await fetchDefaultMetrics(url, key);
          break;

        case "cash":
          url = `master/cash`;
          await fetchDefaultMetrics(url, key);
          break;

        case "bank":
          url = `master/bank`;
          await fetchDefaultMetrics(url, key);
          break;

        default:
          break;
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        setError(error);
      });
  }

  function mapDefaultStateData(key, data) {
    // Re-constructing Vehicle Data
    if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
    }
    return data;
  }

  function changeInputTypeaheadFields(text, field) {
    setTypeAheadFields((prevState) => ({ ...prevState, [field]: text }));
  }

  return (
    <div
      key="fuel-slip_modal"
      id="fuel-slip_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="xl"
        id="fuel-slip-modal"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
        </Modal.Header>

        {/* <!-- BEGIN: Modal Body --> */}
        <Modal.Body>
          {/* <!-- BEGIN: External Components */}
          <>
            {!mode.view && vehicleMasterModal.show && (
              <VehicleMasterForm
                show={vehicleMasterModal.show}
                action={vehicleMasterModal.action}
                title={vehicleMasterModal.title}
                onHide={closeVehicleMasterModal}
                callBack={reloadVehicleMasterData}
                saveToDB={true}
                registration_number={typeAheadFields?.vehicle}
              />
            )}

            {!mode.view && supplierMasterModal.show && (
              <SupplierMasterForm
                show={supplierMasterModal.show}
                action={supplierMasterModal.action}
                supplier_type={supplierMasterModal.supplier_type}
                title={supplierMasterModal.title}
                onHide={closeSupplierMasterModal}
                callBack={reloadSupplierMasterData}
              />
            )}

            {!mode.view && cashMasterModal.show && (
              <CashMasterForm
                show={cashMasterModal.show}
                action={cashMasterModal.action}
                title={cashMasterModal.title}
                onHide={closeCashMasterModal}
                callBack={reloadCashMasterData}
              />
            )}

            {!mode.view && bankMasterModal.show && (
              <BankMasterForm
                show={bankMasterModal.show}
                action={bankMasterModal.action}
                title={bankMasterModal.title}
                onHide={closeBankMasterModal}
                callBack={reloadBankMasterData}
              />
            )}
          </>
          {/* <!-- END: External Components */}

          {isProcessing && <ProcessLoader />}

          {/* <!-- BEGIN: Form --> */}
          <Form
            id="fuel_slip-form"
            name="fuel_slip-form"
            onSubmit={
              mode.create
                ? onSubmit
                : mode.edit
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            {/* <!-- BEGIN : Top Details  -- > */}
            <>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                {/* {!mode?.create && (
                  <div className="col-span-12">
                    <div className="flex items-center justify-center">
                      <div className="text-gray-700">Slip No.</div>
                      <div className="ml-2 font-medium">{state?.slip_no}</div>
                    </div>
                  </div>
                )} */}

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label>Slip No.</Form.Label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    id="slip_no"
                    name="slip_no"
                    placeholder=""
                    disabled={true}
                    value={state.slip_no}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label className="required">Voucher Date</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.date}
                    required
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label>Bill Ref. No.</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    id="bill_ref_no"
                    name="bill_ref_no"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.bill_ref_no}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label>Billed Date</Form.Label>
                  <Form.Input
                    type="date"
                    className="form-control"
                    id="billed_date"
                    name="billed_date"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state.billed_date}
                  />
                </div>

                {/* <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label className="required">Filling Type</Form.Label>
                  <select
                    className="form-select"
                    onChange={changeInput}
                    name="filling_type"
                    value={state.filling_type}
                    disabled={mode.view}
                    required
                  >
                    <option value="" defaultValue></option>
                    <option value="refill">Refill</option>
                    <option value="balance_update">Balance Update</option>
                  </select>

                  <ValidationError msg={validationError.filling_type} />
                </div> */}

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label>Consignment</Form.Label>
                  <Typeahead
                    clearButton
                    id="consignment"
                    name="consignment"
                    filterBy={["consignment_id"]}
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "consignment");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "consignment");
                    }}
                    options={defaultState?.consignment}
                    labelKey={(option) => `${option?.consignment_id || ""}`}
                    selected={formatTypeSearch(state?.consignment || "")}
                    placeholder="Search Consignment..."
                    disabled={mode.view || disabledFields?.consignment}
                  />
                  <ValidationError msg={validationError.consignment} />
                </div>

                {/* <!-- Vehicle Details --> */}

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label className="required">Vehicle</Form.Label>
                  <Typeahead
                    clearButton
                    id="vehicle"
                    name="vehicle"
                    filterBy={["registration_number", "vehicleType"]}
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "vehicle");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInputVehicle(selected, "vehicle");
                    }}
                    onInputChange={(text) => {
                      changeInputTypeaheadFields(text, "vehicle");
                    }}
                    options={defaultState?.vehicle}
                    labelKey={(option) =>
                      `${option?.registration_number || ""}`
                    }
                    selected={formatTypeSearch(state?.vehicle || "")}
                    inputProps={{ required: true }}
                    placeholder="Search vehicle..."
                    disabled={mode.view || disabledFields?.vehicle}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        {localUser?.module_access?.master &&
                          localUser?.access?.approve_master && (
                            <TypeAhAddNewButton
                              onClick={openVehicleMasterModal}
                            ></TypeAhAddNewButton>
                          )}

                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={index}
                          >
                            <TruckNumberDisplay
                              data={result}
                              id={"vehicle_" + result?._id}
                            />
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />
                  <ValidationError msg={validationError.vehicle} />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label className="">Odometer Reading</Form.Label>
                  <KmInput
                    className="form-control"
                    id="odometer_reading"
                    name="odometer_reading"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.odometer_reading}
                  />
                  <CurrentOdometerLabel
                    value={state?.current_odometer_reading}
                  />
                  <ValidationError msg={validationError.odometer_reading} />
                </div>

                {/* <!-- Vehicle Details --> */}

                {/* <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label className="required">Fuel Filling</Form.Label>
                  <select
                    className="form-select"
                    onChange={changeInput}
                    name="fuel_filling"
                    value={state.fuel_filling}
                    disabled={mode.view}
                    required
                  >
                    <option value="" defaultValue></option>
                    <option value="partial">Partial</option>
                    <option value="full">Full Tank</option>
                  </select>
                  <ValidationError msg={validationError.fuel_filling} />
                </div> */}

                {/* <!-- BEGIN : Price Details --> */}
                <>
                  <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                    <Form.Label>Fuel Type</Form.Label>
                    <select
                      className="form-select"
                      onChange={changeInput}
                      name="fuel_type"
                      value={state.fuel_type}
                      disabled={mode.view}
                    >
                      <option value="diesel">Diesel</option>
                      <option value="petrol">Petrol</option>
                    </select>

                    <ValidationError msg={validationError.fuel_type} />
                  </div>

                  <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                    <Form.Label className="">Litres Filled</Form.Label>
                    <LitreInput
                      className="form-control"
                      id="quantity"
                      name="quantity"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state?.quantity}
                    />
                    <ValidationError msg={validationError.quantity} />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                    <Form.Label className="">Rate/Litre</Form.Label>
                    <CurrencyInput
                      className="form-control"
                      id="unit_price"
                      name="unit_price"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state?.unit_price}
                    />
                    <ValidationError msg={validationError.unit_price} />
                  </div>

                  <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                    <Form.Label className="">Amount</Form.Label>
                    <CurrencyInput
                      className="form-control"
                      id="amount"
                      name="amount"
                      placeholder=""
                      disabled={mode.view}
                      onChange={changeInput}
                      value={state?.amount}
                    />
                    <ValidationError msg={validationError.amount} />
                  </div>
                </>
                {/* <!-- END : Price Details --> */}

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label className="">Fuel Station</Form.Label>
                  <Typeahead
                    id="supplier"
                    onFocus={(event) => {
                      loadDefaultMetrics(event, "supplier");
                    }}
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "supplier");
                    }}
                    options={defaultState?.supplier}
                    labelKey={(option) => `${option?.name}`}
                    selected={formatTypeSearch(state?.supplier)}
                    placeholder="Select Fuel Station..."
                    disabled={mode.view}
                    inputProps={{
                      required:
                        state?.payment_mode === "credit" ? true : false,
                    }}
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps}>
                        <TypeAhAddNewButton
                          onClick={(e) => addSupplierMaster("petrol_bunk")}
                        ></TypeAhAddNewButton>
                        {results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            key={index}
                          >
                            {result?.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />

                  <ValidationError msg={validationError.supplier} />
                </div>
              </div>
            </>
            {/* <!-- END :Top Details -- > */}

            {/* <!-- BEGIN : Payment Details  -- > */}
            <>
              <DividerText>Payment Details</DividerText>
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <div className="mt-3">
                    <label>Payment Mode</label>
                    <div className="flex flex-col sm:flex-row mt-2">
                      <div className="form-check mr-2">
                        {" "}
                        <input
                          id="payment_mode_cash"
                          className="form-check-input"
                          type="radio"
                          name="payment_mode"
                          value="cash"
                          checked={state?.payment_mode === "cash"}
                          onChange={changeInput}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="payment_mode_cash"
                        >
                          Cash
                        </label>{" "}
                      </div>

                      <div className="form-check mr-2">
                        {" "}
                        <input
                          id="payment_mode_bank"
                          className="form-check-input"
                          type="radio"
                          name="payment_mode"
                          value="bank"
                          checked={state?.payment_mode === "bank"}
                          onChange={changeInput}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="payment_mode_bank"
                        >
                          Bank
                        </label>{" "}
                      </div>

                      <div className="form-check mr-2">
                        {" "}
                        <input
                          id="payment_mode_credit"
                          className="form-check-input"
                          type="radio"
                          name="payment_mode"
                          value="credit"
                          checked={state?.payment_mode === "credit"}
                          onChange={changeInput}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="payment_mode_credit"
                        >
                          Credit
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- BEGIN: Payment Mode Options--> */}
                <>
                  {state?.payment_mode === "cash" && (
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                      <label htmlFor="cash_master" className="form-label">
                        Cash Book{" "}
                        <small className="text-xs text-gray-500">
                          (Optional)
                        </small>
                      </label>
                      <Typeahead
                        id="cash_master"
                        onFocus={(event) => {
                          loadDefaultMetrics(event, "cash");
                        }}
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "cash");
                        }}
                        options={defaultState?.cash}
                        labelKey={(option) => `${option?.name}`}
                        selected={formatTypeSearch(state?.cash)}
                        placeholder="Select Cash Book..."
                        disabled={mode.view}
                        renderMenu={(results, menuProps) => (
                          <Menu {...menuProps}>
                            <TypeAhAddNewButton
                              onClick={openCashMasterModal}
                            ></TypeAhAddNewButton>
                            {results.map((result, index) => (
                              <MenuItem
                                option={result}
                                position={index}
                                key={index}
                              >
                                {result?.name}
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      />
                    </div>
                  )}

                  {state?.payment_mode === "bank" && (
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                      <label htmlFor="bank_master" className="form-label">
                        Bank Account{" "}
                        <span className="text-xs text-gray-500">
                          (Optional)
                        </span>
                      </label>
                      <Typeahead
                        id="bank_master"
                        onFocus={(event) => {
                          loadDefaultMetrics(event, "bank");
                        }}
                        onChange={(selected) => {
                          changeTypeHeadInput(selected, "bank");
                        }}
                        options={defaultState?.bank}
                        labelKey={(option) => `${option?.name}`}
                        selected={formatTypeSearch(state?.bank)}
                        placeholder="Select Bank A/C..."
                        disabled={mode.view}
                        renderMenu={(results, menuProps) => (
                          <Menu {...menuProps}>
                            <TypeAhAddNewButton
                              onClick={openBankMasterModal}
                            ></TypeAhAddNewButton>
                            {results.map((result, index) => (
                              <MenuItem
                                option={result}
                                position={index}
                                key={index}
                              >
                                {result?.name}
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      />
                    </div>
                  )}
                </>
                {/* <!-- END: Payment Mode Options--> */}

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <label
                    htmlFor="payment_reference_number"
                    className="form-label"
                  >
                    {state?.payment_mode === "cash"
                      ? "Voucher No."
                      : state.payment_mode === "bank"
                      ? "Transaction Id"
                      : "Reference No."}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="payment_reference_number"
                    name="payment_reference_number"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.payment_reference_number}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="remarks"
                    name="remarks"
                    placeholder=""
                    disabled={mode.view}
                    onChange={changeInput}
                    value={state?.remarks}
                  />
                </div>
              </div>
            </>

            {/* <!-- END : Payment Details  -- > */}

            {/* <!-- BEGIN: Document Details --> */}

            <>
              <DividerText>Images</DividerText>
              {/* <!-- BEGIN : 3rd Row -- > */}
              <div className="grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                  <Form.Label>Images</Form.Label>

                  <ImageNFileInput
                    mode={mode}
                    state={state?.images}
                    name="images"
                    error_msg={validationError.images}
                    onDelete={deleteInputImage}
                    onChange={changeInputFile}
                    baseURL={fileBaseURL}
                  />
                </div>
              </div>
              {/* <!-- END : 3rd Row -- > */}
            </>

            <input type="submit" id="save_fuel_slip" className="hidden" />
            <input type="submit" id="update_fuel_slip" className="hidden" />
          </Form>
          {/* <!-- END: Form --> */}

          {/* <!-- END: Card --> */}
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}

        <Modal.Footer className="justify-end">
          <div className="">
            <div className="mr-2">
              <ValidationError
                msg={!isValidForm.valid ? isValidForm.msg : ""}
                notify={true}
              />
            </div>
            <label
              type="button"
              className="btn btn-secondary mr-2 "
              onClick={props.onHide}
            >
              Cancel
            </label>

            {mode.create && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="save_fuel_slip"
              >
                Save
              </label>
            )}
            {mode.edit && (
              <label
                type="button"
                className="btn btn-primary mr-2 "
                htmlFor="update_fuel_slip"
              >
                Update
              </label>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
