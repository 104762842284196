import React, { useState, useEffect } from "react";
import { storageServices } from "../../functions/storage";

import { Dropdown } from "react-bootstrap";
import FeatherIcons from "../../assets/js/feather";

import ProfileForm from "../profile";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const fileURI = process.env.REACT_APP_FILE_URL;

export function TopBar(props) {
  const { localUser } = props;
  const [nav, setNav] = useState([]);

  // Profile Modal
  const [profileModal, setProfileModal] = useState(false);
  const closeProfileModal = () => setProfileModal(false);
  const openProfileModal = () => setProfileModal(true);

  useEffect(() => {
    FeatherIcons();
  });

  function clock() {
    var clock = document.getElementById("live_clock");

    var h = new Date().getHours();
    var m = new Date().getMinutes();
    var s = new Date().getSeconds();
    var am = "AM";

    if (h > 12) {
      h = h - 12;
      var am = "PM";
    }

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    let currentTime = h + ":" + m + ":" + s + " " + am;

    clock.innerHTML = currentTime;
  }

  setInterval(clock, 1000);

  async function logOut() {
    cookies.remove("welcome_toast");
    await storageServices.resetCurrentUser();
    window.location.href = "login";
  }

  return (
    <>
      {profileModal && (
        <ProfileForm
          show={profileModal}
          action="edit"
          title="My Profile"
          onHide={closeProfileModal}
          callBack={closeProfileModal}
          id={localUser?.userId}
        />
      )}

      {/* <!-- BEGIN: Top Bar --> */}
      <div className="border-b border-theme-29 -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-5">
        <div className="top-bar-boxed flex items-center">
          {/* <!-- BEGIN: Logo --> */}
          <div className="-intro-x hidden md:flex">
            <img alt="CoreLogis" className="w-36" src="/images/logo-main.png" />

            {/* <img
              alt="Rubick Tailwind HTML Admin Template"
              className="w-6"
              src="images/logo.svg"
            />
            <span className="text-white text-lg ml-3">
              {" "}
              Core<span className="font-medium">Logis</span>{" "}
            </span> */}
          </div>
          {/* <!-- END: Logo --> */}
          {/* <!-- BEGIN: Breadcrumb --> */}
          <div className="-intro-x breadcrumb breadcrumb--light mr-auto">
            <img alt="" className="w-6" src="images/logo.svg" />
            <div className="text-lg font-bold text-gray-400 break-all ml-3">
              Admin Portal
            </div>{" "}
            {/* <a href={void 0}>Application</a>{" "} */}
            {/* {nav.map((item, index) => {
              return (
                <>
                  <i
                    data-feather="chevron-right"
                    className="breadcrumb__icon"
                  ></i>{" "}
                  <a
                    href={item?.active ? void 0 : item?.href}
                    className={item?.active ? "breadcrumb--active" : ""}
                  >
                    {item?.title}
                  </a>{" "}
                </>
              );
            })} */}
            {/* <a href="" className="breadcrumb--active">
              Dashboard
            </a>{" "} */}
          </div>
          {/* <!-- END: Breadcrumb --> */}

          {/* <!-- BEGIN: Notifications --> */}
          {/* <div className="intro-x dropdown mr-4 sm:mr-6">
            <div
              className="dropdown-toggle notification notification--light notification--bullet cursor-pointer"
              role="button"
              aria-expanded="false"
            >
              {" "}
              <i
                data-feather="bell"
                className="notification__icon dark:text-gray-300"
              ></i>{" "}
            </div>
            <div className="notification-content pt-2 dropdown-menu">
              <div className="notification-content__box dropdown-menu__content box dark:bg-dark-6">
                <div className="notification-content__title">Notifications</div>
                <div className="cursor-pointer relative flex items-center ">
                  <div className="w-12 h-12 flex-none image-fit mr-1">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      className="rounded-full"
                      src="images/profile-4.jpg"
                    />
                    <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <!-- END: Notifications --> */}

          {/* <!-- BEGIN: Clock --> */}
          <div id="time" className="intro-x mr-4 sm:mr-6 text-white text-sm">
            <div id="live_clock"></div>
          </div>

          {/* <!-- END: Clock --> */}

          {/* <!-- BEGIN: Logout Button --> */}
          {/* <div className="intro-x">
            <div
              className="notification notification--light cursor-pointer"
              role="button"
              aria-expanded="false"
              onClick={logOut}
            >
              {" "}
              <i
                data-feather="log-out"
                className="notification__icon dark:text-gray-300"
              ></i>{" "}
            </div>
          </div> */}

          {/* <!-- END: Logout Button --> */}

          {/* <!-- BEGIN: Dropdown --> */}
          <Dropdown className="intro-x w-8 h-8">
            <Dropdown.Toggle className="p-0" id="dropdown-basic">
              <div
                className="w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 p-0"
                role="button"
                aria-expanded="false"
              >
                {/* <img alt="" src="images/profile-1.jpg" /> */}
                <img
                  alt=""
                  src={fileURI + localUser.display_picture}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "images/user-avatar-icon.png";
                  }}
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="w-56">
                <div className="box bg-theme-26 dark:bg-dark-6 text-white">
                  {/* <!-- BEGIN: User Details --> */}
                  <div className="p-4 border-b border-theme-27 dark:border-dark-3 flex flex-wrap gap-3">
                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-white dark:bg-dark-1 bg-opacity-20 hover:bg-opacity-30 text-white">
                      {" "}
                      {/* <img src="images/user-avatar-icon.png" alt="" /> */}
                      <img
                        alt=""
                        src={fileURI + localUser.display_picture}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "images/user-avatar-icon.png";
                        }}
                      />
                    </div>
                    <div className="mr-auto">
                      <div className="font-medium">{localUser?.fullname}</div>
                    </div>
                  </div>
                  {/* <!-- END: User Details --> */}
                  <div className="p-2">
                    <a
                      href={void 0}
                      onClick={openProfileModal}
                      className="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                    >
                      {" "}
                      <i
                        className="fa fa-user-o w-4 h-4 mr-2"
                        aria-hidden="true"
                      ></i>
                      Profile{" "}
                    </a>
                  </div>
                  <div className="p-2 border-t border-theme-27 dark:border-dark-3">
                    <a
                      href={void 0}
                      onClick={logOut}
                      className="flex items-center cursor-pointer block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                    >
                      {" "}
                      <i className="fa fa-sign-out w-4 h-4 mr-2"></i> Logout{" "}
                    </a>
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {/* <!-- END: Dropdown --> */}
        </div>
      </div>
      {/* <!-- END: Top Bar --> */}
    </>
  );
}
