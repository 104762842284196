import * as React from "react";
import PropTypes from "prop-types";  

import Thumbnail from "../image/thumbnail";
import ValidationError from "../error/validationError";

export default function ImageNFileInput(props) {
  const { mode, state, name, required, error_msg, onDelete, onChange,baseURL } = props;
  return (
    <>
      {!mode.view && (state?.name || !state) ? (
        <>
          <div className="form-control custom-file">
            <label className="control-label mr-2 custom-file-label" htmlFor="customFile">
              {state?.name ? state?.name : "Choose file"}
            </label>
            <input
              type="file"
              className="custom-file-input"
              name={name}
              id={name}
              disabled={mode.view}
              onChange={onChange}
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              required={required}
            />
          </div>
          <ValidationError msg={error_msg} />
        </>
      ) : (
        ""
      )}
      {state && typeof state === "string" ? (
        <Thumbnail
          src={state}
          onDelete={(event) => onDelete(event, name)}
          name={name}
          viewMode={mode?.view}
          baseURL={baseURL}
        />
      ) : (
        ""
      )}
      {!state  && mode?.view? (
        <>
          <div className="clearfix"></div>
          <img
            className="img-fluid img-thumbnail thumbnail-image"
            src="https://cel.ac/wp-content/uploads/2016/02/placeholder-img-1.jpg"
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

ImageNFileInput.propTypes = {
  mode: PropTypes.object,
  state: PropTypes.object,
  name: PropTypes.string,
  required: PropTypes.bool,
  error_msg: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

ImageNFileInput.defaultProps = {
  mode: { view: true },
  state: {},
  name: "",
  required: false,
  error_msg: "",
  onChange() {},
  onDelete() {},
};
