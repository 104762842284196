import React, { useState, useEffect } from "react";
// Page Defaults
import ProcessLoader from "../../../components/preloader/processLoader";
// HTML Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";

// Functions
import * as api from "../../../api_service/api";
import { resp } from "../../../functions/responseHandler";
import { formatCurrency } from "../../../functions/common";

export default function Payables(props) {
  const pageTitle = "Payables";
  const [isProcessing, setIsProcessing] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setIsProcessing(true);
    await api
      .getService(`accounts/transactions/supplier/all`)
      .then(async (response) => {
        console.log("resp", response);
        var data = response?.data;
        await mapTableData(data?.data);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      });
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      // {
      //   title: "Vehicle Supplier Id",
      //   field: "supplier.supplier_id",
      //   vertAlign: "middle",
      //   headerFilter: true,
      //   headerFilterPlaceholder: "Search...",
      // },
      {
        title: "Vehicle Supplier",
        field: "supplier.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Total Amount",
        field: "total",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Total Paid",
        field: "amount_paid",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Balance Amount",
        field: "balance_amount",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    setTableData({
      header: columns,
      content: data,
      filter: true,
      filterFields: [
        { label: "Vehicle Supplier Id", value: "supplier.supplier_id" },
        { label: "Vehicle Supplier", value: "supplier.name" },
      ],
    });
  }

  function payablesFormPage(action) {
    window.location.href = `/admin#/accounts/payables/form/${action}`;
  }

  return (
    <Container>
      {/* <!-- BEGIN: Header --> */}
      <Container.Header>
        <Container.Title>{pageTitle}</Container.Title>
        <Container.HeaderActions>
          {" "}
          <a
            className="btn btn-primary shadow-md mr-2"
            href={void 0}
            onClick={(e) => {
              payablesFormPage("add");
            }}
          >
            New Payment
          </a>
        </Container.HeaderActions>
      </Container.Header>
      {/* <!-- END: Header --> */}

      {/*  <!-- BEGIN: Data Table -->  */}
      <Container.Body>
        {isProcessing ? (
          <ProcessLoader />
        ) : (
          <CustomDataTable
            header={tableData.header}
            content={tableData.content}
            filter={true}
            filterFields={tableData.filterFields}
          />
        )}
      </Container.Body>
      {/*  <!-- END: Data Table -->  */}
    </Container>
  );
}
