import React, { useState, useEffect } from "react";
import { Route, HashRouter } from "react-router-dom";
//ACCOUNTS
// import Dashboard from "./index";
import Dashboard from "../Dashboard/accounts";
import Payables from "./Payables";
import PayablesForm from "./Payables/form";

// Receivables
import Recievables from "./Recievables";
import RecievablesForm from "./Recievables/form";
import RecDebitCreditMemoForm from "./Recievables/debit_credit_memo";

import IncomeExpense from "./IncomeExpense";
import Income from "./IncomeExpense/income";
import Expense from "./IncomeExpense/expense";
import Journal from "./Journal";
import TransactionHistory from "./TransactionHistory";

export default function Router() {
  return (
    <HashRouter>
      <Route exact path="/accounts/dashboard" component={Dashboard} />
      <Route exact path="/accounts/payables" component={Payables} />
      <Route
        exact
        path="/accounts/payables/form/:action/:id?"
        component={PayablesForm}
      />
      <Route exact path="/accounts/recievables" component={Recievables} />
      <Route
        exact
        path="/accounts/recievables/form"
        component={RecievablesForm}
      />
      <Route
        exact
        path="/accounts/recievables/debit_credit_memo"
        component={RecDebitCreditMemoForm}
      />

      <Route path="/accounts/journal" component={Journal} />
      <Route exact path="/accounts/income_expense" component={IncomeExpense} />
      <Route exact path="/accounts/income_expense/income" component={Income} />
      <Route
        exact
        path="/accounts/income_expense/expense"
        component={Expense}
      />
      <Route
        path="/accounts/transaction_history"
        component={TransactionHistory}
      />
    </HashRouter>
  );
}
