import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as TruckIcon } from "../../assets/images/svg/truck.svg";

import { standardCase, htmlDateTime } from "../../functions/common";
import Tooltip from "../htmlElements/tooltip";

export default function Component(props) {
  const { className, transits, origin, destination, stage, ...rest } = props;
  const activeClassName = "w-8 h-8 rounded-full btn btn-success-soft";
  const inActiveClassName =
    "w-8 h-8 rounded-full btn btn-warning-soft dark:bg-dark-1";

  const TransitButton = (props) => {
    const { active, data, id, ...rest } = props;

    return (
      <div className="lg:text-center flex items-center lg:block flex-1 z-10">
        {stage < 3 && active ? (
          <TruckIcon width="42" height="42" className="inline" />
        ) : (
          <button className={inActiveClassName}>
            <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
          </button>
        )}
        <div className="lg:w-24 lg:mt-2 ml-3 lg:mx-auto">
          <Tooltip text={<TooltipText data={data} id={id} />} id={id}>
            <small>{standardCase(data?.place)}</small>
          </Tooltip>
        </div>
      </div>
    );
  };

  const TooltipText = (props) => {
    const { data } = props;
    return (
      <div>
        <h3 className="font-bold">{data.place}</h3>
        <p>Time: {htmlDateTime(data?.time)}</p>
        {data?.remark && <p>Remarks: {data?.remark}</p>}
      </div>
    );
  };
  const PlaceButton = (props) => {
    const { text, showTruck, ...rest } = props;
    return (
      <div className=" lg:text-center flex items-center lg:block flex-1 z-10">
        {showTruck ? (
          <TruckIcon width="42" height="42" className="inline" />
        ) : (
          <button className="w-8 h-8 rounded-full btn btn-danger-soft">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
          </button>
        )}
        <div className="lg:w-24 lg:mt-2 ml-3 lg:mx-auto break-all font-medium">
          {standardCase(text)}
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <!BEGIN: Stepper--> */}
      <div className="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20">
        {/* <!BEGIN: ORIGIN --> */}
        <PlaceButton
          text={(standardCase(origin?.place), standardCase(origin?.name))}
          type="origin"
          showTruck={false}
        />
        {/* <!END: ORIGIN --> */}

        {transits.map((item, index) => (
          <>
            <TransitButton
              data={item}
              active={transits.length === index + 1}
              id={"transit_" + index}
            />
          </>
        ))}

        {/* <!BEGIN: DESTINATION --> */}
        <PlaceButton
          text={
            (standardCase(destination?.place), standardCase(destination?.name))
          }
          type="destination"
          showTruck={stage > 2}
        />
        {/* <!END: DESTINATION --> */}

        <div className="wizard__line hidden lg:block w-2/3 bg-gray-200 dark:bg-dark-1 absolute mt-5"></div>
      </div>
      {/* <!END: Stepper--> */}
    </>
  );
}

Component.propTypes = {
  transits: PropTypes.array,
  origin: PropTypes.object,
  destination: PropTypes.object,
  className: PropTypes.string,
};

Component.defaultProps = {
  transits: [],
  origin: {},
  destination: {},
  className: "",
};
