import React from "react";
import PropTypes from "prop-types";
export default function Component(props) {
  const { className, type, id, size, color, ...rest } = props;

  return (
    <div
      className={`w-${size} h-${size} ${color} rounded-full ` + className}
    ></div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "",
  type: "",
  id: "dot",
  size: "2",
  color: "bg-gray-400",
  onClick() {},
};
