import React from "react";
import PropTypes from "prop-types";
import { isDefined } from "../../functions/common";
export default function Component(props) {
  const { data, className, ...rest } = props;
  let string = data;

  const formula = /(\s*CMT[0-9]{4}[A-Z]{2}19)/;
  const splitString = string.split(formula);
  const consEntity = isDefined(splitString[1]) ? splitString[1] : "";
  const consNo = isDefined(splitString[2]) ? splitString[2] : "";

  return (
    <div className={className}>
      <span className="text-gray-700">{consEntity}</span>
      <span className="text-theme-1">
        <b>{consNo}</b>
      </span>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  // Functions
  onClick: PropTypes.func,
};

Component.defaultProps = {
  // Variables
  className: "text-lg",
  // Functions
  onClick() {},
};
