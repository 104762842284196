import Swal from "sweetalert2";

class sweetAlert {
  constructor() {}
  Show(props) {
    var {
      type,
      title,
      text,
      html,
      footer,
      imageUrl,
      imageHeight,
      confirmButtonColor,
      confirmButtonText,
    } = {
      ...props,
    };

    var options = {
      icon: type,
      title: title,
      text: text,
      html: html,
      footer: footer,
      imageUrl: imageUrl,
      imageHeight: imageHeight,
    };
    if (confirmButtonColor) options["confirmButtonColor"] = confirmButtonColor;
    if (confirmButtonText) options["confirmButtonText"] = confirmButtonText;
    // return Swal.fire(title, text, type);

    return Swal.fire(options);
  }
  confirmDeletion(props) {
    var {
      title = "Confirm Deletion!",
      text = "Are you sure want to delete this ?",
      footer = props?.footer,
      onConfirm,
    } = { ...props };
    return Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      footer: footer,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(function (result) {
      if (result.value) {
        onConfirm();
      }
    });
  }

  confirmSuspension(props) {
    var {
      title = "Confirm Suspension!",
      text = "Are you sure want to suspend this?",
      onConfirm,
    } = { ...props };
    return Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(function (result) {
      if (result.value) {
        onConfirm();
      }
    });
  }

  confirmApproval(props) {
    var {
      title = "Confirm Approval!",
      text = "Are you sure want to approve this?",
      onConfirm,
    } = { ...props };
    return Swal.fire({
      title: title,
      text: text,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(function (result) {
      if (result.value) {
        onConfirm();
      }
    });
  }

  confirmActivation(props) {
    var {
      title = "Confirm Activation!",
      text = "Are you sure want to activate?",
      onConfirm,
    } = { ...props };
    return Swal.fire({
      title: title,
      text: text,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(function (result) {
      if (result.value) {
        onConfirm();
      }
    });
  }

  confirmation(props) {
    const { type = "info", title, text, onConfirm } = { ...props };

    return Swal.fire({
      title: title,
      text: text,
      icon: type,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      ...props,
    }).then(function (result) {
      if (result.value) {
        onConfirm();
      }
    });
  }
}

const alert = new sweetAlert();
const alertBox = new sweetAlert();
export { alert, alertBox };
