import React, { Component } from "react";

class InLineLoader extends Component {
  render() {
    return (
      <div className="col-span-12 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center ml-1">
        {/* <i data-loading-icon="tail-spin" className="w-8 h-8"></i> */}
        <i className="fa fa-cog fa-spin fa-fw"></i>
      </div>
    );
  }
}

export default InLineLoader;
