import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FeatherIcons from "../../assets/js/feather";
import { Dropdown } from "react-bootstrap";
// Icons
import { ReactComponent as RepeatIcon } from "../../assets/images/icons/repeat.svg";
import Tooltip from "../htmlElements/tooltip";

export default function InvoiceActionButtons(props) {
  const {
    generateButton,
    submitButton,
    sendAndSubmitButton,
    cancelButton,
    deleteButton,
    approveButton,
    downloadButton,
    reGenerateButton,
    reCreateButton,
    cell,
  } = props;

  // const rowData = cell?._cell?.row?.data;
  // FeatherIcons();

  useEffect(() => {
    FeatherIcons();
  });

  const css = `.action-button-display {
    position:fixed!important;
    z-index:99;
 }`;
  return (
    <div
      // className="flex lg:justify-center items-center"
      className="flex"
      aria-label="Action Buttons"
    >
      <style>{css}</style>
      {/* DELETE BUTTON */}
      {deleteButton?.show ? (
        <button
          type="button"
          className="delete flex items-center text-theme-6 ml-2"
          onClick={(event) => {
            event.preventDefault();
            deleteButton.onClick();
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Delete"
          disabled={deleteButton?.disabled}
        >
          <i data-feather="trash-2" className="w-4 h-4 mr-1"></i> Delete
        </button>
      ) : null}
      {/* DELETE BUTTON */}

      {/* Download BUTTON */}
      {downloadButton?.show ? (
        <Tooltip
          className="flex items-center"
          text="Download"
          id="download-invoice-button"
        >
          <button
            type="button"
            className="edit flex items-center text-theme-9"
            onClick={(event) => {
              event.preventDefault();
              downloadButton.onClick();
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Download"
            disabled={downloadButton?.disabled}
          >
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </Tooltip>
      ) : null}
      {/* Download BUTTON */}

      {/* Submit BUTTON */}
      {submitButton?.show ? (
        <Tooltip
          className="flex items-center"
          text="Submit Invoice"
          id="submit-invoice-button"
        >
          <button
            type="button"
            className="edit flex items-center text-theme-1 ml-2"
            onClick={(event) => {
              event.preventDefault();
              submitButton.onClick();
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Submit Invoice"
            disabled={submitButton?.disabled}
          >
            <i class="fa fa-list" aria-hidden="true"></i>
          </button>
        </Tooltip>
      ) : null}
      {/* Submit BUTTON */}

      {/* Send and Submit BUTTON */}
      {sendAndSubmitButton?.show ? (
        <Tooltip
          className="flex items-center"
          text={"Send & Submit Invoice"}
          id="send-and-submit-invoice-button"
        >
          <button
            type="button"
            className="edit flex items-center text-theme-1 ml-2 "
            onClick={(event) => {
              event.preventDefault();
              sendAndSubmitButton.onClick();
            }}
            disabled={sendAndSubmitButton?.disabled}
          >
            <i className="fa fa-share-square-o" aria-hidden="false"></i>
          </button>
        </Tooltip>
      ) : null}
      {/* Submit BUTTON */}

      {/* APPROVE BUTTON */}
      {approveButton?.show ? (
        <button
          type="button"
          className="edit flex items-center text-theme-9 ml-2"
          onClick={(event) => {
            event.preventDefault();
            approveButton.onClick();
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Approve"
          disabled={approveButton?.disabled}
        >
          <i data-feather="check-square" className="w-4 h-4 mr-1"></i> Approve
        </button>
      ) : null}
      {/* APPROVE BUTTON */}

      {/* Generate BUTTON */}
      {generateButton?.show ? (
        <button
          type="button"
          className="edit flex items-center text-theme-1 ml-2"
          onClick={(event) => {
            event.preventDefault();
            generateButton.onClick();
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Generate"
          disabled={generateButton?.disabled}
        >
          <i data-feather="printer" className="w-4 h-4 mr-1"></i> Generate
        </button>
      ) : null}
      {/* Generate BUTTON */}

      {(reGenerateButton?.show ||
        reCreateButton?.show ||
        cancelButton?.show) && (
        <Dropdown id="special_actions" className="ml-2 flex-none">
          <Dropdown.Toggle
            className=""
            id="consignment_action_buttons"
            variant="link"
          >
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="box shadow p-2 action-button-display flex flex-col"
            style={{ position: "fixed!important" }}
            align="start"
            drop="start"
            flip={true}
          >
            {/* RE-Generate BUTTON */}
            {reGenerateButton?.show ? (
              <p>
                <button
                  type="button"
                  className="btn btn-secondary w-32 mb-2"
                  onClick={(event) => {
                    event.preventDefault();
                    reGenerateButton.onClick();
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Re Generate"
                  disabled={reGenerateButton?.disabled}
                >
                  <i
                    class="fa fa-refresh mr-2 text-theme-11"
                    aria-hidden="true"
                  ></i>
                  Re-Generate
                </button>
              </p>
            ) : null}
            {/* RE Generate BUTTON */}

            {/* RE-Create BUTTON */}
            {reCreateButton?.show ? (
              <p>
                <button
                  type="button"
                  className="btn btn-secondary w-32 mb-2"
                  onClick={(event) => {
                    event.preventDefault();
                    reCreateButton.onClick();
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Re Create"
                  disabled={reCreateButton?.disabled}
                >
                  <i
                    class="fa fa-refresh mr-2 text-theme-9"
                    aria-hidden="true"
                  ></i>
                  Re-Create
                  {/* <i data-feather="repeat" className="w-4 h-4 mr-1"></i>  */}
                </button>
              </p>
            ) : null}
            {/* RE Generate BUTTON */}

            {/* Cancel BUTTON */}
            {cancelButton?.show ? (
              <p>
                <button
                  type="button"
                  className="btn btn-secondary w-32 mb-2"
                  onClick={(event) => {
                    event.preventDefault();
                    cancelButton.onClick();
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Cancel"
                  disabled={cancelButton?.disabled}
                >
                  <i
                    class="fa fa-times mr-2 text-theme-6"
                    aria-hidden="true"
                  ></i>
                  Cancel
                </button>
              </p>
            ) : null}
            {/* Cancel BUTTON */}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

InvoiceActionButtons.propTypes = {
  approveButton: PropTypes.object,
  generateButton: PropTypes.object,
  reGenerateButton: PropTypes.object,
  reCreateButton: PropTypes.object,
  submitButton: PropTypes.object,
  sendAndSubmitButton: PropTypes.object,
  cancelButton: PropTypes.object,
  deleteButton: PropTypes.object,
  downloadButton: PropTypes.object,
};

InvoiceActionButtons.defaultProps = {
  approveButton: { show: false, disabled: false, onClick() {} },
  generateButton: { show: false, disabled: true, onClick() {} },
  reGenerateButton: { show: false, disabled: true, onClick() {} },
  reCreateButton: { show: false, disabled: true, onClick() {} },
  submitButton: { show: false, disabled: true, onClick() {} },
  sendAndSubmitButton: { show: false, disabled: true, onClick() {} },
  cancelButton: { show: false, disabled: false, onClick() {} },
  deleteButton: { show: false, disabled: false, onClick() {} },
  downloadButton: { show: false, disabled: false, onClick() {} },
};
