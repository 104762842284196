import React, { useState, useEffect } from "react";
// Page Defaults
import Preloader from "../../components/preloader/preloader";
import Error from "../../components/error/error";

import { storageServices } from "../../functions/storage";
import { isEmpty } from "../../functions/common";
// Pages
import StartUp from "../StartUp";
import Dashboard from "../Dashboard";
import LandingPage from "../LandingPage";
import AppSwitcher from "./appSwitcher";

export default function Home(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [localUser, setLocalUser] = useState();

  const [state, setState] = useState({
    show_startup: false,
    show_dashboard: false,
    show_landing_page: false,
  });

  useEffect(() => {
    const loadData = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      await setLocalUser(localUserData);

      // Organization is created - Proceeding to dashboard or lander
      if (!isEmpty(localUserData.organization_id)) {
        if (localUserData?.role?.is_owner || localUserData?.role?.is_admin) {
          await setState((prevState) => ({
            ...prevState,
            show_dashboard: true,
          }));
        } else {
          await setState((prevState) => ({
            ...prevState,
            show_landing_page: true,
          }));
        }
      }
      // Organization is not created - To Startup page
      else {
        await setState((prevState) => ({
          ...prevState,
          show_startup: true,
        }));
      }

      setIsLoaded(true);
    };
    loadData();
  }, []);

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <>
        {state.show_dashboard ? (
          <Dashboard {...props}/>
        ) : state.show_landing_page ? (
          <LandingPage />
        ) : (
          <StartUp />
        )}
      </>
    );
  }
}
