//////////// VALIDATION ////////////

function trimAll(string) {
  return string.replace(/ /g, "");
}
function isEmpty(string) {
  return !string || 0 === string.length;
}

function isObject(obj) {
  if (typeof obj === "object" && obj !== null) {
    return true;
  } else {
    return false;
  }
}

export function validateForm(errors) {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));

  return valid;
}

export function validateComplexForm(errors) {
  let valid = true;

  let keys = Object.keys(errors);

  // Object.values(errors).forEach((val) => val.length > 0 && (valid = false));

  for (var i in keys) {
    if (Array.isArray(errors[keys[i]])) {
      //  let arr_keys = Object.keys(errors[keys[i]]);
      Object.values(errors[keys[i]]).forEach(
        (val) => val.length > 0 && (valid = false)
      );
    } else {
      errors[keys[i]].length > 0 && (valid = false);
    }
  }
  return valid;
}

export function validateName(data, limit = 3) {
  const error_msg = `Enter at least ${limit} characters`;
  if (isEmpty(data)) {
    return "";
  } else {
    return data.length < limit ? error_msg : "";
  }
}

export function validateInTime(from, to) {
  const error_msg = "In Time should be lesser than Out Time.";
  return new Date(from) >= new Date(to) ? error_msg : "";
}

export function validateOutTime(to, from) {
  const error_msg = "Out Time should be greater than In Time.";
  return new Date(from) >= new Date(to) ? error_msg : "";
}

export function validateFromDate(from, to) {
  const error_msg = "Date should be lesser than To Date.";
  return new Date(from) >= new Date(to) ? error_msg : "";
}

export function validateToDate(to, from) {
  const error_msg = "Date should be greater than From Date.";
  return new Date(from) >= new Date(to) ? error_msg : "";
}

// Common Validation with common messages
export function validatePAN(data) {
  const regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  const error_msg = "Invalid PAN Number.";
  if (isEmpty(data)) {
    return "";
  } else {
    return regex.test(data) ? "" : error_msg;
  }
}

export function validateGST(data) {
  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const error_msg = "Invalid GST Number.";
  if (isEmpty(data)) {
    return "";
  } else {
    return regex.test(data) ? "" : error_msg;
  }
}

export function validatePinCode(data) {
  const regex = /^[1-9]{1}[0-9]{5}$/;
  const error_msg = "Invalid Pin Code.";
  if (isEmpty(data)) {
    return "";
  } else {
    return regex.test(data) ? "" : error_msg;
  }
}

export function validateRegistrationNo(data) {
  // const regex = /^[A-Z]{2}[0-9]{2}[A-Z][A-Z]?[0-9]{4}$/;
  // const regex = /^[A-Z]{2}[A-Z]?[0-9]{2}[A-Z][A-Z]?[0-9]{4}$/;
  // const error_msg = "Inavlid Registration Number.";
  // if (isEmpty(data)) {
  //   return "";
  // } else {
  //   return regex.test(trimAll(data)) ? "" : error_msg;
  // }
  return "";
}

export function validateLicenseNo(data) {
  // var regex = /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
  // const regex = /^([A-Z]{2}[0-9]{2})(Y?)(19|20)[0-9][0-9][0-9]{7}$/;
  // const error_msg = "Inavlid Registration Number.";
  // if (isEmpty(data)) {
  //   return "";
  // } else {
  //   data = data.replace("-", "");
  //   return regex.test(trimAll(data)) ? "" : error_msg;
  // }
  return "";
}

export function validatePhoneNo(data) {
  // const error_msg = "Inavlid Phone Number.";
  // if (isEmpty(data)) {
  //   return "";
  // } else {
  //   return data.length < 10
  //       ? error_msg
  //       : "";
  // }
  return "";
}

export function validateImageSize(size, limit = 1) {
  return size / 1024 / 1024 <= limit ? true : false;
}

export function validateImage(image, limit = 1) {
  var response = { status: false, msg: "" };
  // Error Messages
  const acceptedFormats = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
  const size_error_msg = `File size should be less than ${limit} MB.`;
  const format_error_msg = "Invalid File Format.";
  const invalid_error_msg = "Invalid Image.";
  if (isObject(image)) {
    // Format Check
    if (!acceptedFormats.includes(image.type)) {
      response = { status: true, msg: format_error_msg };
    }
    // Size Check
    if (image.size / 1024 / 1024 > limit) {
      response = { status: true, msg: size_error_msg };
    }
  } else {
    response = { status: true, msg: invalid_error_msg };
  }
  return response;
}

export function validateImageNFile(file, limit = 1) {
  var response = { status: false, msg: "" };
  // Error Messages
  const acceptedFormats = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "application/pdf",
    "image/x-eps",
  ];
  const size_error_msg = `File size should be less than ${limit} MB.`;
  const format_error_msg = "Invalid File Format.";
  const invalid_error_msg = "Invalid Image.";
  if (isObject(file)) {
    // Format Check
    if (!acceptedFormats.includes(file.type)) {
      response = { status: true, msg: format_error_msg };
    }
    // Size Check
    if (file.size / 1024 / 1024 > limit) {
      response = { status: true, msg: size_error_msg };
    }
  } else {
    response = { status: true, msg: invalid_error_msg };
  }
  return response;
}

export function validateTypeAhead(data) {
  const error_msg = "Invalid Selection.";
  if (data === null || typeof data === "undefined" || data.length < 1) {
    return error_msg;
  } else {
    return "";
  }
  // return typeof data === "undefined" || data.length < 1 ? true : false;
}

//////////// VALIDATION ////////////
