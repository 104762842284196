import React from "react";
import PropTypes from "prop-types";

// Images & Icons
import { ReactComponent as ClientIcon } from "../../../assets/images/svg/client.svg";
import { ReactComponent as DriverIcon } from "../../../assets/images/svg/driver.svg";
import { ReactComponent as TruckIcon } from "../../../assets/images/svg/truck.svg";
import { ReactComponent as SupplierIcon } from "../../../assets/images/svg/supplier.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { localDate } from "../../../functions/common";

export default function Component(props) {
  const { className, id, name, onClick, data, enableEdit, ...rest } = props;

  var isActive = false;
  if (!enableEdit) isActive = true;

  function navToConsignment(id) {
    window.location.href = `/admin#/consignment/view/edit/${id}`;
  }

  // const ConsignmentCard = (props) => {
  //   const { id, data } = props;
  //   return (
  //     <div
  //       id={id}
  //       className="box cursor-pointer bg-gray-100 col-span-12"
  //       onClick={isActive ? (e) => navToConsignment(data?._id) : (e) => {}}
  //     >
  //       <div className="flex items-center px-3 py-1 border-b border-gray-200 dark:border-dark-5">
  //         <h2 className="mr-auto">{data?.consignment_id}</h2>
  //         <span className="text-gray-400">
  //           {localDate(data?.consignment_date)}
  //         </span>
  //       </div>

  //       <div
  //         class="px-3"
  //         id="consignment_details"
  //         aria-hidden="true"
  //         tabindex="-1"
  //       >
  //         <div class="flex flex-col lg:flex-row items-center pb-5">
  //           <div class="flex-1 flex flex-col sm:flex-row justify-center items-center pr-5 lg:border-r border-gray-200 dark:border-dark-5">
  //             <div class="mr-auto text-center items-center sm:text-left mt-3 sm:mt-0">
  //               <div class="break-all">{data?.origin}</div>
  //               <div>
  //                 <i
  //                   className="fa fa-angle-double-down text-gray-600 fa-2x"
  //                   aria-hidden="true"
  //                 ></i>
  //               </div>
  //               <div class="break-all">{data?.destination}</div>
  //             </div>
  //           </div>

  //           <div class="w-full lg:w-auto mt-6 lg:mt-0 pt-4 lg:pt-0 flex-1 flex flex-col justify-center items-center lg:items-start px-5 border-t lg:border-t-0 border-gray-200 dark:border-dark-5">
  //             <div class="flex items-center truncate">
  //               <div class="flex items-center justify-center mr-2 text-gray-500">
  //                 <TruckIcon
  //                   width="18"
  //                   height="18"
  //                   className="inline bg-gray-600"
  //                   fill="currentColor"
  //                 />
  //               </div>
  //               {data?.vehicle}
  //             </div>

  //             <div class="flex items-center mt-2 truncate">
  //               <div class="flex items-center justify-center mr-2 text-gray-500">
  //                 <DriverIcon
  //                   width="18"
  //                   height="18"
  //                   className="inline bg-gray-600"
  //                   fill="currentColor"
  //                 />
  //               </div>
  //               {data?.driver}
  //             </div>

  //             {data?.supplier && (
  //               <div class="flex items-center mt-2 truncate">
  //                 <div class="flex items-center justify-center mr-2 text-gray-500">
  //                   <SupplierIcon
  //                     width="18"
  //                     height="18"
  //                     className="inline bg-gray-600"
  //                     fill="currentColor"
  //                   />
  //                 </div>
  //                 {data?.supplier}
  //               </div>
  //             )}

  //             <div class="flex items-center mt-2 truncate">
  //               <div class="flex items-center justify-center mr-2 text-gray-500">
  //                 <ClientIcon
  //                   width="18"
  //                   height="18"
  //                   className="inline bg-gray-600"
  //                   fill="currentColor"
  //                 />
  //               </div>
  //               {data?.client}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const ConsignmentCard = (props) => {
    const { id, data } = props;
    return (
      <div
        id={id}
        className="box p-3 cursor-pointer bg-gray-100"
        onClick={isActive ? (e) => navToConsignment(data?._id) : (e) => {}}
      >
        <div className="text-theme-1 font-medium">{data?.consignment_id}</div>

        <div className="py-2 flex items-center justify-center">
          <p className="break-all w-1/2 font-medium text-right">{data?.origin}</p>
          <p>
            <i
              className="fa fa-angle-double-right text-gray-600 px-2 fa-2x"
              aria-hidden="true"
            ></i>
          </p>

          <p className="break-all font-medium w-1/2 text-left">
            {data?.destination}
          </p>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-2">
          <div className="flex items-center">
            <div className="flex-none image-fit mr-1">
              <TruckIcon width="24" height="24" className="inline" />
            </div>
            <div className="ml-2 overflow-hidden">{data?.vehicle}</div>
          </div>
          <div className="flex items-center">
            <div className="flex-none image-fit mr-1">
              <DriverIcon
                width="24"
                height="24"
                className="inline"
                fill="SteelBlue"
              />
            </div>
            <div className="ml-2 overflow-hidden">{data?.driver}</div>
          </div>

          {data?.supplier && (
            <div className="flex items-center mt-2">
              <div className="flex-none image-fit mr-1">
                <SupplierIcon
                  width="24"
                  height="24"
                  className="inline"
                  fill="DarkCyan"
                />
              </div>
              <div className="ml-2 overflow-hidden">{data?.supplier}</div>
            </div>
          )}
          <div className="flex items-center">
            <div className="flex-none image-fit mr-1">
              <ClientIcon width="24" height="24" className="inline" />
            </div>
            <div className="ml-2 overflow-hidden">{data?.client}</div>
          </div>
        </div>
      </div>
    );
  };

  function onClickCard(id) {}
  return (
    <div id={id} className={className}>
      <div className="text-center mb-auto">
        <div className="text-base md:text-sm font-medium truncate text-theme-1">
          In Transit Consignments
        </div>
      </div>

      <Carousel
        autoPlay={true}
        showArrows={true}
        infiniteLoop={true}
        onClickItem={onClickCard}
        stopOnHover={true}
        showStatus={false}
        showIndicators={true}
        showThumbs={false}
        className="w-full h-full flex items-center justify-center"
      >
        {data.map((item, index) => {
          return <ConsignmentCard data={item} id={index} />;
        })}
      </Carousel>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
  enableEdit: PropTypes.bool,
};

Component.defaultProps = {
  className: "w-full h-full flex flex-col items-center justify-center",
  id: "in_transit_consignments",
  name: "",
  data: [],
  onClick() {},
  enableEdit: false,
};
