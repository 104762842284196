import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import CurrencyInput from "../htmlElements/currencyInput";
import ValidationError from "../error/validationError";
import {
  validateComplexForm,
  formatCurrency,
  matchData,
  toFloat,
} from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";

const Model = {
  balance: 0,
  new_debit: 0,
  new_debit_note: "",
  new_debit_ref_no: "",

  new_credit: 0,
  new_credit_note: "",
  new_credit_ref_no: "",

  arrayIndex: "",
  apply_client_credits: false,
  new_client_credits_amount: 0,

  // CLient Credits calculation
  original_available_client_credits: 0,
  used_client_credits: 0,
  available_client_credits: 0,
};

export default function CreditDebitMemoForm(props) {
  const { title, onHide, callBack, data } = props;
  const [viewMode, setViewMode] = useState(false);
  // State
  const [isProcessing, setIsProcessing] = useState(false);
  const [state, setState] = useState({ ...Model });

  const [validationError, setValidationError] = useState({
    new_client_credits_amount: "",
  });

  useEffect(() => {
    processData();
  }, []);

  async function processData() {
    setIsProcessing(true);
    let recData = await matchData(state, data);

    let availableCredits =
      toFloat(recData.original_available_client_credits) -
      toFloat(recData.used_client_credits) -
      toFloat(recData.new_client_credits_amount);

    recData.available_client_credits = availableCredits;
    setState(recData);
    setIsProcessing(false);
  }
  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    if (validateComplexForm(validationError)) {
      callBack(state);
    }
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "new_client_credits_amount") {
      validateCreditsAmount(value);
      let availableCredits =
        toFloat(state.original_available_client_credits) -
        toFloat(state.used_client_credits) -
        toFloat(value);

      setState((prevState) => ({
        ...prevState,
        available_client_credits: availableCredits,
      }));
    }
  }

  function onChangeCheckbox(event) {
    const { name } = event.target;
    let newValue = !state.apply_client_credits;
    setState((prevState) => ({
      ...prevState,
      [name]: !state.apply_client_credits,
    }));
    if (newValue) {
      setState((prevState) => ({
        ...prevState,
        new_client_credits_amount: state.available_client_credits,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        new_client_credits_amount: 0,
      }));
    }
  }

  function validateCreditsAmount(value) {
    if (value > state.original_available_client_credits) {
      setValidationError((prevState) => ({
        ...prevState,
        new_client_credits_amount: "Should not exceed available credits.",
      }));
    } else {
      setValidationError((prevState) => ({
        ...prevState,
        new_client_credits_amount: "",
      }));
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={onHide}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h2 className="font-medium text-base mr-auto">{title}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {isProcessing && <ProcessLoader />}
          <div className="h-100">
            <form
              onSubmit={onSubmit}
              id="credit-debit-memo-form"
              className="h-100"
            >
              <div className="grid grid-cols-12 m-0">
                {/* Col -1 */}
                <div className="col-span-6 p-5 border-r border-b dark:border-dark-5">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                      <label className="control-label">Debit Amount</label>
                      <CurrencyInput
                        id="new_debit"
                        name="new_debit"
                        onChange={changeInput}
                        value={state?.new_debit}
                        required={true}
                      />
                    </div>

                    <div className="form-group col-span-6">
                      <label htmlFor="new_debit_ref_no">Ref No</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new_debit_ref_no"
                        name="new_debit_ref_no"
                        placeholder=""
                        disabled={viewMode}
                        onChange={changeInput}
                        value={state.new_debit_ref_no}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Notes / Remarks</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="new_debit_note"
                      name="new_debit_note"
                      placeholder=""
                      disabled={viewMode}
                      onChange={changeInput}
                      value={state.new_debit_note}
                    />
                  </div>
                </div>
                {/* Col -1 */}

                {/* Col -2 */}
                <div className="col-span-6 p-5 border-b dark:border-dark-5">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="form-group col-span-6">
                      <label className="control-label">Credit Amount</label>
                      <CurrencyInput
                        id="new_credit"
                        name="new_credit"
                        onChange={changeInput}
                        value={state?.new_credit}
                      />
                    </div>

                    <div className="form-group col-span-6">
                      <label htmlFor="new_credit_ref_no">Ref No</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new_credit_ref_no"
                        name="new_credit_ref_no"
                        placeholder=""
                        disabled={viewMode}
                        onChange={changeInput}
                        value={state.new_credit_ref_no}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Notes / Remarks</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="new_credit_note"
                      name="new_credit_note"
                      placeholder=""
                      disabled={viewMode}
                      onChange={changeInput}
                      value={state.new_credit_note}
                    />
                  </div>
                </div>
                {/* Col -2 */}
              </div>

              {/* 2nd row - CLient Credits */}

              <div className="row">
                <div className="col-6 p-5">
                  <div className="grid grid-cols-12 mb-2">
                    <div className="form-group col-span-6 d-flex align-items-end my-0">
                      <div className="checkbox">
                        <label className="mr-2">
                          <input
                            type="checkbox"
                            checked={state.apply_client_credits}
                            name="apply_client_credits"
                            onChange={onChangeCheckbox}
                            disabled={
                              state?.available_client_credits > 0 ? false : true
                            }
                          />{" "}
                          Apply Client Credits
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-span-6 my-0">
                      {state.apply_client_credits && (
                        <>
                          <CurrencyInput
                            id="new_client_credits_amount"
                            name="new_client_credits_amount"
                            onChange={changeInput}
                            value={state?.new_client_credits_amount}
                            size="sm"
                          />
                          <ValidationError
                            msg={validationError?.new_client_credits_amount}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <p>
                    Available credits:{" "}
                    <label className="text-success">
                      {formatCurrency(state.available_client_credits)}
                    </label>
                  </p>
                </div>
              </div>
              {/* 2nd row - CLient Credits */}
              {/* 2nd row - CLient Credits */}
              <input type="submit" id="save" className="hidden" />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary mr-2" onClick={onHide}>
            Cancel
          </button>

          <label type="button" className="btn btn-primary" htmlFor="save">
            Save
          </label>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

CreditDebitMemoForm.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  callBack: PropTypes.func,
  onHide: PropTypes.func,
};

CreditDebitMemoForm.defaultProps = {
  title: "Add Debit / Credit",
  data: { ...Model },
  onHide() {},
  callBack() {},
};
