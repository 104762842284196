import React from "react";
import PropTypes from "prop-types";
export default function Component(props) {
  const { className, type, id, name, onClick, icon, text, ...rest } = props;

  return (
    <a
      className="cursor-pointer text-sm ml-2 text-primary"
      href={void 0}
      onClick={onClick}
    >
      <i className="fa fa-pencil" aria-hidden="true"></i>
    </a>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "",
  type: "",
  id: "",
  name: "",
  icon: "fa fa-pencil",
  text: "",
  onClick() {},
};
