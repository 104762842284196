import PropTypes from "prop-types";
import BackButton from "../pageElements/backButton";

const Container = ({ children }) => {
  return <div id="main-content">{children}</div>;
};

const Header = ({ children }) => {
  return (
    <div className="flex flex-col sm:flex-row items-center mt-3 mb-2">
      {children}
    </div>
  );
};

const Body = ({ children }) => {
  return <div>{children}</div>;
};

const Title = (props) => {
  const { className, backButton } = props;
  return (
    <div className={className + " flex items-center justify-center"}>
      {backButton && <BackButton />}
      {props.children}
    </div>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  backButton: PropTypes.bool,
};

Title.defaultProps = {
  className: "text-lg font-medium", //mr-auto
  backButton: true,
};

{
  /* <!-- END: Title --> */
}

const HeaderActions = ({ children }) => {
  return (
    <div className="w-full sm:w-auto flex mt-4 sm:mt-0 ml-auto">{children}</div>
  );
};

const Footer = ({ children }) => {
  return <div className="">{children}</div>;
};

Container.Header = Header;
Container.Title = Title;
Container.HeaderActions = HeaderActions;
Container.Body = Body;
Container.Footer = Footer;

export default Container;
