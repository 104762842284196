import React, { useState, useEffect } from "react";

// Components
import Container from "../../components/container";
import Preloader from "../../components/preloader/preloader";

import { storageServices } from "../../functions/storage";

// Icons

import { ReactComponent as TruckIcon } from "../../assets/images/svg/truck.svg";
import { ReactComponent as InvoiceIcon } from "../../assets/images/svg/invoice.svg";
import { ReactComponent as CalculatorIcon } from "../../assets/images/svg/calculator.svg";

export default function LandingPage() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [localUser, setLocalUser] = useState();
  const [state, setState] = useState({ initial_setup: false });

  useEffect(() => {
    const loadData = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      await setLocalUser(localUserData);

      if (localUserData?.organization_id !== "") {
        setState((prevState) => ({ ...prevState, initial_setup: false }));
      } else if (localUserData.branch_organizations.length > 0) {
        setState((prevState) => ({ ...prevState, initial_setup: false }));
      } else {
        setState((prevState) => ({ ...prevState, initial_setup: true }));
      }

      setIsLoaded(true);
    };
    loadData();
  }, []);

  function TileCard(props) {
    return (
      <a
        href={props.url}
        className="intro-y box rounded-md zoom-in w-auto mb-2  shadow-lg"
      >
        <div className="py-10 w-80">
          <div className="block w-16 h-16 text-theme-1 dark:text-theme-10 mx-auto text-center">
            {props.icon}
          </div>
          <div className="block font-medium text-center break-all mt-3 text-lg">
            {props.title}
          </div>
        </div>
      </a>
    );
  }
  if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <>
        <Container>
          {/* <!-- BEGIN : Tiles */}
          {/* <div className="mt-16 flex items-center justify-center"> */}
          <div className="mt-16">
            <div className="flex justify-center items-center space-x-4">
              {/* <!-- BEGIN : Consignment Tile */}
              {localUser?.module_access?.consignments && (
                <TileCard
                  title="Consignments"
                  icon={<TruckIcon />}
                  url="/admin#/consignment"
                />
              )}
              {/* <!-- END : Consignment Tile  */}

              {/* <!-- BEGIN : Invoices Tile */}
              {localUser?.module_access?.invoices && (
                <TileCard
                  title="Invoices"
                  icon={<InvoiceIcon />}
                  url="/admin#/invoices/view"
                />
              )}
              {/* <!-- END : Invoices Tile  */}

              {/* <!-- BEGIN : Accounts Tile */}
              {localUser?.module_access?.accounts && (
                <TileCard
                  title="Accounts"
                  icon={<CalculatorIcon />}
                  url="/admin#/accounts/transaction_history"
                />
              )}
              {/* <!-- END : Accounts Tile  */}
            </div>
          </div>

          {/* <!-- END : Tiles */}
        </Container>
      </>
    );
  }
}
