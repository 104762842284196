import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";

// HTML Components
import { Modal } from "react-bootstrap";
// Data Table
import CustomDataTable from "../../components/tables/dataTable";
// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { commaList, localDate, getDateDiff } from "../../functions/common";
// External Components
import EmailPreview from "../messenger/email_preview";

const Model = {
  channels: [],
  selected_invoices: [],
};

export default function Component(props) {
  const tableRef = React.createRef();

  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  // State
  const [emailPreview, setEmailPreview] = useState({
    show: false,
    data: {},
    subject: "",
    message: "",
  });
  const [state, setState] = useState({ ...Model });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    loadData(props);
  }, []);
  // Loading Data
  async function loadData() {
    setIsProcessing(true);
    api.getService(`invoice/all/overdue`).then(
      async (result) => {
        console.log("result:", result);
        var data = result.data;
        data = await mapData(data.data);
        await mapTableData(data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  // Data Mapping
  async function mapData(data) {
    // Extracting LR Number
    for (var i in data) {
      data[i]["lr_number"] = await commaList(
        data[i]["consignment"]["load_receipts"],
        "lr_number"
      );

      let dateDiff = await getDateDiff(data[i]["due_date"], new Date());
      // Calculating Over due days
      data[i]["overdue_days"] = dateDiff;
    }
    return data;
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        hozAlign: "center",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        resizable: false,
        headerSort: false,
        print: false,
        download: false,
      },
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "center",
        headerSort: false,
        // cellClick: function (e, cell) {
        //   cell.getRow().toggleSelect();
        // },
        width: 100,
      },

      {
        title: "Cons. Date",
        field: "consignment.consignment_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
        width: 150,
      },
      {
        title: "Cons. No.",
        field: "consignment.consignment_id",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 180,
      },
      {
        title: "Invoice No.",
        field: "invoice_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 180,
      },

      {
        title: "Client",
        field: "client.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 150,
        print: true,
        download: true,
      },
      {
        title: "Origin",
        field: "consignment.origin.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 150,
        print: true,
        download: true,
      },
      {
        title: "Destination",
        field: "consignment.destination.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 150,
        print: true,
        download: true,
      },

      {
        title: "LR Number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        field: "lr_number",
        width: 200,
        headerSort: false,
        reponsive: 1,
        cellClick: function (e) {
          e.stopPropagation();
        },
        print: true,
        download: true,
      },
      {
        title: "Overdue By",
        field: "overdue_days",
        vertAlign: "middle",
        headerSort: true,
        width: 150,
        print: true,
        download: true,
        formatter: function (cell) {
          return cell?.getValue() + " days";
        },
      },
    ];
    setTableData({
      header: columns,
      content: data,
      title: "Invoice",
      filter: true,
      printHeader: "Overdue Invoices",
    });
    setIsProcessing(false);
  }

  // Form Actions
  async function sendNotification(event, channel) {
    // event.preventDefault();
    // var selectedRows = state.selected_invoices;
    // console.log("selectedRows:", state.selected_invoices);
    // if (selectedRows.length > 0) {
    //   setIsProcessing(true);
    //   let query = await prepareData(channel, selectedRows);
    //   api
    //     .postService("notification/invoice/overdue_invoices", query)
    //     .then((response) => {
    //       setIsProcessing(false);
    //       resp.Success(response?.data?.message);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       setIsProcessing(false);
    //       resp.ErrorHandler(error);
    //     });
    // } else {
    //   resp.Error("No Data Selected.");
    // }
    // showEmailPreview();
    return;
  }

  function showEmailPreview(data) {
    let prevState = { ...emailPreview };
    prevState["data"] = data;
    prevState["show"] = true;
    setEmailPreview(prevState);
  }
  function closeEmailPreview() {
    setEmailPreview((prevState) => ({ ...prevState, show: false }));
  }

  function prepareData(channels, selectedData) {
    let invoices = [];
    for (var i in selectedData) {
      invoices.push(selectedData[i]._id);
    }
    let body = { channels: channels, invoices: invoices };
    return body;
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function selectableRowsHighlight(row) {
    return true;
  }
  function onSelectedRowsChange(data, rows) {
    setState((prevState) => ({ ...prevState, selected_invoices: data }));
  }

  function emailPreviewCallBack(data) {
    closeEmailPreview();
  }

  async function onEscalate(event) {
    const channels = ["email", "whatsapp"];
    event.preventDefault();
    var selectedRows = state.selected_invoices;

    if (selectedRows.length > 0) {
      setIsProcessing(true);
      let query = await prepareData(channels, selectedRows);
      api
        .postService(
          "notification/get/invoice/overdue_invoices_template",
          query
        )
        .then((response) => {
          setIsProcessing(false);
          console.log("response:", response);
          console.log("response?.data:", response?.data);
          const data = response?.data?.data;
          processTemplateData(data);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      resp.Error("No Data Selected.");
    }

    return;
  }

  function processTemplateData(data) {
    showEmailPreview(data);
  }

  return (
    <>
      {emailPreview?.show && (
        <EmailPreview
          show={emailPreview?.show}
          data={emailPreview?.data}
          subject={emailPreview?.subject}
          message={emailPreview?.message}
          module={"invoice"}
          category={"overdue_invoice"}
          sub_category={""}
          action_type={"escalation"}
          onHide={closeEmailPreview}
          callBack={emailPreviewCallBack}
        />
      )}
      <Modal
        {...props}
        onHide={props.onHide}
        //   backdrop="static"
        fullscreen={true}
        keyboard={false}
        dialogClassName="modal-fit"
        //   size="xl"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>
        <Modal.Body>
          {isProcessing && <ProcessLoader />}

          <div className="w-full flex justify-end">
            <a
              className="btn btn-primary shadow-md mr-2"
              href={void 0}
              onClick={onEscalate}
            >
              Escalate
            </a>

            {/* <Dropdown className="ml-auto">
              <Dropdown.Toggle
                variant="success"
                className="w-48"
                id="dropdown-dueinvoice-notify"
              >
                <i className="fa fa-paper-plane mr-2"></i>Send Notification{" "}
                <i className="fa fa-angle-down ml-auto sm:ml-2"></i>{" "}
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow-lg">
                <Dropdown.Item
                  className="bg-gray-200 w-48 px-5 py-3"
                  href={void 0}
                  onClick={(event) => sendNotification(event, "email")}
                >
                  <i className="fa fa-envelope mr-2" aria-hidden="true"></i>
                  Email{" "}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
          <div className="mt-2">
            <CustomDataTable
              header={tableData.header}
              content={tableData.content}
              tableRef={tableRef}
              selectableRows={true}
              selectableRowsHighlight={selectableRowsHighlight}
              onSelectedRowsChange={onSelectedRowsChange}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Overdue Invoices",
  action: "view",
  callBack() {},
};
