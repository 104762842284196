import React, { useState, useEffect } from "react";
// Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import StatusBadge from "../../../components/pageElements/statusBadge";
import ActionButtons from "../../../components/pageElements/actionButtons";
import FormMaster from "../../../components/master/supplier_type"; // Form
import StageTab from "../../../components/tabs/stageTab";
// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import { htmlToString, navToPage } from "../../../functions/common";
import { reactFormatter } from "react-tabulator";
// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";

export default function SupplierMaster() {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData();
  }, []);

  // Fetching Grid Data From API
  async function loadData() {
    setIsProcessing(true);
    api.getService("master/supplier_type").then(
      async (result) => {
        const data = result.data;
        let tableData = await mapTableData(data.data);
        setTableData(tableData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Name",
        field: "name",
        responsive: 0,
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter(cell, formatterParams) {
          return `<div>
                            <div className="font-medium whitespace-nowrap">${
                              cell.getData().name
                            }</div>
                           
                        </div>`;
        },
      },
      // {
      //   title: "Type",
      //   field: "type.name",
      //           vertAlign: "middle",
      // },
      // {
      //   title: "Phone",
      //   field: "contact_number",
      //   headerSort: false,
      //           vertAlign: "middle",
      // },
      // {
      //   title: "PAN No.",
      //   field: "pan_number",
      //   headerSort: false,
      //           vertAlign: "middle",
      // },
      {
        title: "Status",
        field: "status",
        headerSort: false,
                vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",

        formatter: function (cell) {
          return htmlToString(<StatusBadge text={cell?.getValue()} />);
        },
      },

      {
        title: "Actions",
        field: "",
        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(<ActionButtonsLookup />),
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [
        { label: "Name", value: "name" },
        { label: "Vehicle Supplier Id", value: "supplier_id" },
        { label: "Type", value: "type" },
      ],
    };
    return response;
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e) => {
            editRecord(cellData);
          },
        }}
        suspendButton={{
          show: cellData?.status == "active" ? true : false,
          onClick: (e) => {
            deleteRecord(cellData);
          },
          disabled: cellData?.default ? true : false,
        }}
        activateButton={{
          // show: false,
          show: cellData?.status == "inactive" ? true : false,
          onClick: (e) => {
            activateRecord(cellData);
          },
        }}
      />
    );
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData();
  }
  // Create New Record
  async function addRecord() {
    console.log("add");
    let newState = {
      show: true,
      title: `Add Supplier Type`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(data, row) {
    console.log("row data:", data);
    let newState = {
      show: true,
      title: `View Supplier Type`,
      action: "view",
      id: data?._row?.data?._id,
    };
    setModal(newState);
  }
  // Edit Record
  async function editRecord(data) {
    let newState = {
      show: true,
      title: `Edit Supplier Type`,
      action: "edit",
      id: data?._id,
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to suspend this Type?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }
  // RE-ACTIVATION
  function activateRecord(data) {
    alert.confirmActivation({
      text: "Are you sure want to activate this Type?",
      onConfirm: function () {
        onActivate(data);
      },
    });
  }
  ////// COMMON FUNCTIONS //////

  function onActivate(data) {
    setIsProcessing(true);
    var query = {
      status: "active",
    };
    api
      .putService(`master/supplier_type/${data._id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`master/supplier_type/${data?._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  return (
    <>
      <>
        <Container>
          {/* <!-- BEGIN: Modal --> */}
          {modal.show && (
            <FormMaster
              show={modal.show}
              action={modal.action}
              title={modal.title}
              onHide={closeModal}
              callBack={reloadData}
              id={modal.id}
            />
          )}
          {/* <!-- END: Modal --> */}

          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title className="text-lg font-medium">
            Supplier Types
            </Container.Title>

            {/* <!-- BEGIN: Stage Tabs --> */}
            <div className="ml-auto flex">
              <StageTab>
                <StageTab.Tab
                  id="supplier-stage-supplier"
                  active={false}
                  onClick={(e) => navToPage("/admin#/master/supplier")}
                  width={"w-36"}
                >
                  Suppliers
                </StageTab.Tab>{" "}
                <StageTab.Tab
                  id="user-stage-supplier_type"
                  active={true}
                  onClick={(e) => navToPage("/admin#/master/supplier_type")}
                  width={"w-36"}
                >
                  Supplier Types
                </StageTab.Tab>
              </StageTab>
            </div>
            {/* <!-- END: Stage Tabs --> */}

            <Container.HeaderActions>
              {" "}
              <a
                className="btn btn-primary shadow-md mr-2"
                href={void 0}
                onClick={addRecord}
              >
                Add New
              </a>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing ? (
              <ProcessLoader />
            ) : (
              <CustomDataTable
                header={tableData.header}
                content={tableData.content}
                onRowClick={(e, row) => {
                  viewRecord(row);
                }}
                filter={true}
                filterFields={tableData.filterFields}
              />
            )}
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
