import React, { useState, useEffect } from "react";
// Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import StatusBadge from "../../../components/pageElements/statusBadge";
import ActionButtons from "../../../components/pageElements/actionButtons";
import FormMaster from "../../../components/master/vehicle";
import AddNewButton from "../../../components/htmlElements/addNewButton";
import StageTab from "../../../components/tabs/stageTab";
// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import {
  htmlToString,
  navToPage,
  JsonToFormData,
} from "../../../functions/common";
import { reactFormatter } from "react-tabulator";
// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";

export default function Vehicle(props) {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));

  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();
  const [state, setState] = useState({
    ownership_type: "all",
  });

  /////////// FUNCTIONS DECLARATION ///////////

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      // Loading Data
      loadData(state, localUserData);
    };
    getUser();
  }, []);

  // Reload Grid Data
  function modalCallBack() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData(state, localUser);
  }

  function changeInput(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState[name] = value;
    setState(prevState);
    loadData(prevState, localUser);
  }

  async function loadData(filters = state, user = localUser) {
    setIsProcessing(true);
    api.getService(`vehicle`, filters).then(
      async (result) => {
        const data = result.data;
        let tableData = await mapTableData(data.data, user);
        setTableData(tableData);
        setIsProcessing(false);
      },

      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        setError(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data, user) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Registration Number",
        field: "registration_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
      },
      {
        title: "Vehicle Type",
        field: "vehicle_type.vehicle_type",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
      },
      {
        title: "Ownership",
        field: "ownership_type",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
      },
      {
        title: "Odo Reading (Km)",
        field: "odometer_reading",
        headerSort: true,
        hozAlign: "right",
        headerSortTristate: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Fuel Balance (L)",
        field: "fuel_balance",
        hozAlign: "right",
        headerSortTristate: true,
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Avg. Mileage (kmpl)",
        field: "avg_mileage",
        hozAlign: "right",
        headerSortTristate: true,
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Status",
        field: "status",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return htmlToString(<StatusBadge text={cell?.getValue()} />);
        },
      },

      {
        title: "Actions",
        vertAlign: "middle",
        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(<ActionButtonsLookup user={user} />),
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [{ label: "Name", value: "name" }],
    };
    return response;
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    const user = props.user;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e, row) => {
            editRecord(cellData._id);
          },
        }}
        suspendButton={{
          show: cellData?.status === "active" ? true : false,
          onClick: (e) => {
            deleteRecord(cellData);
          },
        }}
        activateButton={{
          // show: false,
          show: cellData?.status === "inactive" ? true : false,
          onClick: (e) => {
            activateRecord(cellData);
          },
        }}
        // approveButton={{
        //   show:
        //     user?.access?.approve_master &&
        //     cellData?.status === "active" &&
        //     !cellData?.approved
        //       ? true
        //       : false,
        //   onClick: (e) => {
        //     approveRecord(cellData);
        //   },
        // }}
      />
    );
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData() {
    loadData(state, localUser);
  }
  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add Vehicle`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(id) {
    // let newState = {
    //   show: true,
    //   title: `View Vehicle`,
    //   action: "view",
    //   id: id,
    // };
    // setModal(newState);
    navToPage(`/admin#/master/vehicle/view/${id}`);
  }

  // Edit Record
  async function editRecord(id) {
    let newState = {
      show: true,
      title: `Edit Vehicle`,
      action: "edit",
      id: id,
    };
    setModal(newState);
  }

  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to suspend this Vehicle?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }

  // Edit Record
  async function approveRecord(data) {
    let newState = {
      show: true,
      title: `Approve Vehicle`,
      action: "approve",
      id: data?._id,
    };
    setModal(newState);
  }

  // RE-ACTIVATION
  function activateRecord(data) {
    console.log("SD");
    alert.confirmActivation({
      text: "Are you sure want to activate this Vehicle?",
      onConfirm: function () {
        onActivate(data);
      },
    });
  }

  ////// COMMON FUNCTIONS //////

  async function onActivate(data) {
    setIsProcessing(true);
    var query = {
      status: "active",
    };

    let body = await JsonToFormData(query);
    api
      .DataputService(`vehicle/${data._id}`, body)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`vehicle/${data?._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  return (
    <>
      <>
        {/* <!-- BEGIN: Modal --> */}
        {modal.show && (
          <FormMaster
            show={modal.show}
            action={modal.action}
            title={modal.title}
            onHide={closeModal}
            callBack={modalCallBack}
            id={modal.id}
          />
        )}
        {/* <!-- END: Modal --> */}

        <Container>
          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title className="text-lg font-medium">
              Vehicles
            </Container.Title>

            {/* <!-- BEGIN: Stage Tabs --> */}
            <div className="ml-auto flex">
              <StageTab>
                <StageTab.Tab
                  id="vehicles-tab"
                  active={true}
                  onClick={(e) => navToPage("/admin#/master/vehicle")}
                  width={"w-32"}
                >
                  Vehicles
                </StageTab.Tab>{" "}
                <StageTab.Tab
                  id="vehicle_type-tab"
                  active={false}
                  onClick={(e) => navToPage("/admin#/master/vehicle_type")}
                  width={"w-32"}
                >
                  Vehicle Type
                </StageTab.Tab>
              </StageTab>
            </div>
            <select
              id="ownership-filter"
              className="form-select ml-2 w-36"
              onChange={changeInput}
              name="ownership_type"
              value={state?.ownership_type}
            >
              <option id="all-vehicles" value="all">
                All Vehicles
              </option>
              <option id="own-vehicles" value="own">
                Own Vehicles
              </option>
              <option id="supplier-vehicles" value="supplier">
                Supplier Vehicles
              </option>
            </select>
            {/* <!-- END: Stage Tabs --> */}

            <Container.HeaderActions>
              <AddNewButton id="add-new-vehicle-button" onClick={addRecord}>
                Add Vehicle
              </AddNewButton>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing && <ProcessLoader />}
            <CustomDataTable
              header={tableData.header}
              content={tableData.content}
              onRowClick={(e, row) => {
                viewRecord(row._row.data._id);
              }}
              filter={true}
              filterFields={tableData.filterFields}
            />
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
