import React, { Component } from "react";

import "./loader.css";

class ProcessLoader extends Component {
  render() {
    return (
      <div className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
            <span className="spinner"></span>
          </div>
        </div>
      </div>
    );
  }
}

export default ProcessLoader;
