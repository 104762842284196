import React, { useState, useEffect } from "react";
import ProcessLoader from "../../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../../functions/responseHandler";
import { toFloat, formatTypeSearch, htmlDate } from "../../../functions/common";
import { Modal, Button } from "react-bootstrap";
import * as api from "../../../api_service/api";

import { Typeahead } from "react-bootstrap-typeahead";

const Model = {
  date: "",
  transaction_type: "contra_entry",
  transaction_context: "transfer",
  payment_from_type: "",
  payment_to_type: "",

  payment_bank: "",
  payment_cash: "",
  received_bank: "",
  received_cash: "",
  transaction_amount: "",
  payment_reference_number: "",
  remarks: "",
};

export default function Component(props) {
  const { type, action, fromId, fromName } = props;

  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });

  const [defaultState, setDefaultState] = useState({
    bank: [],
    cash: [],
  });

  const [record_id, setRecordId] = useState();
  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setState({ ...Model });
        setViewMode(false);
        loadData(props);
        break;
      case "edit":
        setViewMode(false);
        break;
      case "view":
        setViewMode(true);
        break;
      default:
        setViewMode(true);
    }
  }

  function loadData(props) {
    let prevState = state;
    switch (props.type) {
      case "load_cash":
        // prevState.payment_from_type = "bank";
        prevState.date = htmlDate(new Date());
        prevState.payment_to_type = "cash";
        // prevState.payment_cash = fromId;
        prevState.received_cash = fromId;
        setState(prevState);
        break;

      case "deposit_cash":
        // prevState.payment_from_type = "cash";
        prevState.date = htmlDate(new Date());
        prevState.payment_to_type = "bank";
        // prevState.payment_bank = fromId;
        prevState.received_bank = fromId;
        setState(prevState);
        break;

      default:
        break;
    }
  }

  async function loadCommonMetrics(metric) {
    if (defaultState[metric] && defaultState[metric].length < 1) {
      setIsProcessing(true);
      var query = { metrix: [metric] };
      await api
        .postService("common", query)
        .then((response) => {
          console.log(response);
          setDefaultState((prevState) => ({
            ...prevState,
            [metric]: response?.data[metric],
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    }
  }

  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`accounts/transaction/${id}`).then(
      async (result) => {
        console.log("View Cash data:", result);
        const data = result.data;
        // await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }

  function prepareData(data) {
    if (data.payment_from_type === "bank") {
      data.payment_bank = data?.payment_bank?._id;
      delete data.payment_cash;
    } else if (data.payment_from_type === "cash") {
      data.payment_cash = data?.payment_cash?._id;
      delete data.payment_bank;
    }
    // Other category

    if (type === "load_cash") {
      delete data.received_bank;
    }
    if (type === "deposit_cash") {
      delete data.received_cash;
    }

    return data;
  }

  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    let data = await prepareData({ ...state });
    console.log("data:", data);

    api
      .postService("accounts/transactions", data)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .putService(`master/transaction/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "opening_balance") {
      let currentBalance = toFloat(value) + toFloat(state?.current_total);
      setState((prevState) => ({
        ...prevState,
        current_balance: currentBalance,
      }));
    }
    if (name === "payment_from_type") {
      if (value === "bank" || value === "cash") {
        loadCommonMetrics(value);
      }
    }
  }

  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      let selected_data = selected[0];
      setState((prevState) => ({ ...prevState, [name]: selected_data }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>

        <Modal.Body>
          <div>
            {isProcessing && <ProcessLoader />}
            <form
              onSubmit={
                userAction === "create"
                  ? onSubmit
                  : userAction === "edit"
                  ? onUpdate
                  : (e) => e.preventDefault()
              }
              id="contra-entry-form"
              className="grid grid-cols-12 gap-4 gap-y-3"
            >
              <div className="col-span-12">
                <label htmlFor="date" className="form-label required">
                  Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  name="date"
                  placeholder=""
                  disabled={viewMode}
                  onChange={changeInput}
                  value={state.date}
                  required
                />
              </div>

              <div className="col-span-12">
                <label
                  htmlFor="payment_from_type"
                  className="form-label required"
                >
                  From
                </label>
                <select
                  className="form-control"
                  id="payment_from_type"
                  name="payment_from_type"
                  value={state.payment_from_type}
                  onChange={changeInput}
                  required
                >
                  <option value="">Choose...</option>

                  <option value="cash">Cash Book</option>
                  <option value="bank">Bank Account</option>
                  <option value="other">Other</option>
                </select>
              </div>

              {/* CASH MASTER */}
              {state.payment_from_type === "cash" && (
                <div className="col-span-12">
                  <label htmlFor="payment_cash" className="form-label">
                    Cash Book
                  </label>
                  <Typeahead
                    id="payment_cash"
                    name="payment_cash"
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "payment_cash");
                    }}
                    options={defaultState?.cash}
                    labelKey={(option) => `${option?.name}`}
                    selected={formatTypeSearch(state?.payment_cash)}
                    inputProps={{ required: true }}
                    placeholder="Choose Cash Book..."
                  />
                </div>
              )}

              {/* BANK MASTER */}

              {state.payment_from_type === "bank" && (
                <div className="col-span-12">
                  <label htmlFor="payment_bank" className="form-label required">
                    Bank Account
                  </label>
                  <Typeahead
                    id="payment_bank"
                    name="payment_bank"
                    onChange={(selected) => {
                      changeTypeHeadInput(selected, "payment_bank");
                    }}
                    options={defaultState?.bank}
                    labelKey={(option) => `${option?.name}`}
                    selected={formatTypeSearch(state?.payment_bank)}
                    inputProps={{ required: true }}
                    placeholder="Choose Bank A/C..."
                  />
                </div>
              )}

              {/* TO */}
              <div className="col-span-12">
                <label htmlFor="to" className="form-label required">
                  To
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="fromName"
                  name="fromName"
                  placeholder=""
                  disabled={true}
                  // onChange={changeInput}
                  value={fromName}
                  required
                />
              </div>
              {/* TO */}

              <div className="col-span-12">
                <label
                  htmlFor="transaction_amount"
                  className="form-label required"
                >
                  Amount
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text bg-dark">
                      <i className="fa fa-inr" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="transaction_amount"
                    name="transaction_amount"
                    placeholder=""
                    disabled={viewMode}
                    onChange={changeInput}
                    value={state.transaction_amount}
                    required
                  />
                </div>
              </div>

              <div className="col-span-12">
                <label
                  htmlFor="payment_reference_number"
                  className="form-label"
                >
                  Ref. No.
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="payment_reference_number"
                  name="payment_reference_number"
                  placeholder=""
                  disabled={viewMode}
                  onChange={changeInput}
                  value={state.payment_reference_number}
                />
              </div>

              <div className="col-span-12">
                <label htmlFor="remarks" className="form-label">
                  Remarks
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="remarks"
                  name="remarks"
                  placeholder=""
                  disabled={viewMode}
                  onChange={changeInput}
                  value={state.remarks}
                />
              </div>

              <input type="submit" id="save_contra_entry" className="hidden" />
              <input
                type="submit"
                id="update_contra_entry"
                className="hidden"
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>

          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_contra_entry"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_contra_entry"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "",
  action: "view",
  callBack() {},
};
