import React from "react";
import PropTypes from "prop-types";

export default function Component(props) {
  const { className, id, name, image, email, phone, display, ...rest } = props;

  return (
    <div className={className} id={id}>
      <div className="w-12 h-12 flex-none image-fit mr-1">
        <img alt="" className="rounded-full" src={image} />
      </div>
      <div className="ml-2 overflow-hidden">
        <div className="flex items-center">
          <a href={void 0} className="font-medium">
            {name}
          </a>
        </div>
        {display?.includes("email") && (
          <div className="w-full truncate text-gray-600 mt-0.5">{email}</div>
        )}

        {display?.includes("phone") && (
          <div className="w-full truncate text-gray-600 mt-0.5">{phone}</div>
        )}
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  display: PropTypes.array, // Entities to be shown : email,phone
};

Component.defaultProps = {
  className: "cursor-pointer box relative flex items-center p-3",
  name: "",
  image: "images/profile-pic-default.png",
  email: "",
  phone: "",
  address: "",
  display: [],
};
