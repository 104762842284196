// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// LOADERS
import ProcessLoader from "../preloader/processLoader";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";
import CurrencyInput from "../htmlElements/currencyInput";
import Form from "../form/custom";
import WeightInput from "../htmlElements/weightInput";
import DividerText from "../htmlElements/dividerText";
import ArrayAddRemoveButtons from "../htmlElements/arrayAddRemoveButtons";
// Typeahead Component
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

import "react-bootstrap-typeahead/css/Typeahead.css";
// FUNCTIONS
import { resp } from "../../functions/responseHandler";
import {
  matchData,
  htmlInputDateTime,
  toFloat,
  formatTypeaheadCommon,
} from "../../functions/common";
import * as api from "../../api_service/api";

const Model = {
  consignee: {
    name: "",
    address: "",
    gst: "",
  },

  consigner: {
    name: "",
    address: "",
    gst: "",
  },

  lr_number: "",
  in_time: "",
  out_time: "",
  invoice_number: "",
  po_number: "",
  eway_bill_no: "",

  goods: [
    {
      material_description: "",
      number_of_unit: "",
      weight_per_unit: "",
      billable_weight: "",
      billable_value: "",
    },
  ],
  unloading_goods: [
    {
      material_description: "",
      number_of_unit: "",
      weight_per_unit: "",
      billable_weight: "",
      billable_value: "",
    },
  ],
};

export default function Component(props) {
  // Defaults
  const [pageTitle, setPageTitle] = useState("Load Receipt");
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });
  // State
  const [state, setState] = useState({ ...Model });
  const [defaultState, setDefaultState] = useState({
    expense_type: [],
    material: [],
  });
  const [record_id, setRecordId] = useState();
  const [consStage, setConsStage] = useState(0);
  const [consStatus, setConsStatus] = useState();

  const [expenseTypeModal, setExpenseTypeModal] = useState({
    show: false,
    title: "Add Expense",
    action: "create",
    id: "",
    module: "consignment",
  });

  useEffect(() => {
    handleUserAction();
  }, []);

  function getModel() {
    return {
      consignee: {
        name: "",
        address: "",
        gst: "",
      },

      consigner: {
        name: "",
        address: "",
        gst: "",
      },

      lr_number: "",
      in_time: "",
      out_time: "",
      invoice_number: "",
      po_number: "",
      eway_bill_no: "",

      goods: [
        {
          material_description: "",
          number_of_unit: "",
          weight_per_unit: "",
          billable_weight: "",
          billable_value: "",
        },
      ],
      unloading_goods: [
        {
          material_description: "",
          number_of_unit: "",
          weight_per_unit: "",
          billable_weight: "",
          billable_value: "",
        },
      ],
    };
  }

  // <!--- BEGIN: FUNCTIONS -->

  async function handleUserAction() {
    setConsStage(props.cons_stage);
    setConsStatus(props.cons_status);
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setPageTitle("Add Load Receipt");
        setDefaults();
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        setPageTitle("Edit Load Receipt");
        loadData(props);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        setPageTitle("View Load Receipt");
        loadData(props);
        break;
      default:
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
    }
  }

  function setDefaults() {
    // let prevState = { ...Model };
    let prevState = getModel();
    setState(prevState);
    return null;
  }

  // <!--- END: FUNCTIONS -->

  // <!--- BEGIN: FORM CHANGE FUNCTIONS -->
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeLoadRecieptsChild(event, child_name) {
    const { name, value } = event.target;
    const prevState = { ...state };
    prevState[child_name][name] = value;
    setState(prevState);
  }

  const changeInputGoods = (event, gd_index) => {
    const { name, value } = event.target;
    const prevState = { ...state };
    prevState.goods[gd_index][name] = value;
    setState(prevState);

    if (
      name === "number_of_unit" ||
      name === "billable_weight" ||
      name === "weight_per_unit"
    ) {
      calcBillable(gd_index, name, "goods");
    }
  };

  const changeInputUnloadingGoods = (event, gd_index) => {
    const { name, value } = event.target;
    const prevState = { ...state };
    prevState.unloading_goods[gd_index][name] = value;
    setState(prevState);

    if (
      name === "number_of_unit" ||
      name === "billable_weight" ||
      name === "weight_per_unit"
    ) {
      calcBillable(gd_index, name, "unloading_goods");
    }
  };

  function calcBillable(gd_index, name, parent) {
    var prevState = { ...state };
    var number_of_unit = prevState[parent][gd_index]?.number_of_unit || 1;
    var weight_per_unit = prevState[parent][gd_index]?.weight_per_unit || 0;
    var billable_weight = prevState[parent][gd_index]?.billable_weight || 0;

    if (name === "number_of_unit") {
      if (weight_per_unit > 0) {
        billable_weight = (number_of_unit * weight_per_unit).toFixed(2);
      }
      if (billable_weight > 0) {
        weight_per_unit = (billable_weight / number_of_unit).toFixed(2);
      }
    }

    if (name === "weight_per_unit") {
      if (number_of_unit > 0) {
        billable_weight = (number_of_unit * weight_per_unit).toFixed(2);
      }
    }

    if (name === "billable_weight") {
      if (number_of_unit > 0) {
        weight_per_unit = (billable_weight / number_of_unit).toFixed(2);
      }
    }

    prevState[parent][gd_index]["number_of_unit"] = number_of_unit;
    prevState[parent][gd_index]["billable_weight"] = billable_weight;
    prevState[parent][gd_index]["weight_per_unit"] = weight_per_unit;

    // Calculating GRN Value - Unloading
    if (parent === "unloading_goods") {
      let original_value_per_weight =
        toFloat(prevState["goods"][gd_index]["billable_value"]) /
        toFloat(prevState["goods"][gd_index]["billable_weight"]);
      let grn_value_per_weight =
        toFloat(original_value_per_weight) *
        toFloat(prevState["unloading_goods"][gd_index]["billable_weight"]);
      prevState["unloading_goods"][gd_index]["billable_value"] =
        grn_value_per_weight.toFixed(2);
    }

    setState((prevState) => ({ ...prevState, [parent]: prevState[parent] }));
    // setState(prevState);
  }

  function addGoods(event) {
    const modelData = [...Model.goods];

    var list = [...state.goods];
    list.push(modelData[0]);
    setState((prevState) => ({ ...prevState, goods: list }));
  }

  function removeGood(index) {
    var list = [...state.goods];
    list.splice(index, 1);
    setState((prevState) => ({ ...prevState, goods: list }));
  }

  // <!--- END: FORM CHANGE FUNCTIONS -->

  // <!--- BEGIN: API CALLS -->

  //<!--BEGIN: Typeahead Functions -->

  async function loadDefaultMetrics(event, key, reload = false, data = {}) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: prevDefState,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "material":
            url = "load_material/master";
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultState(data, key);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function mapDefaultState(data, key) {
    let result = [];
    if (key === "material") {
      for (var i in data) {
        result.push(data[i]["name"]);
      }
    } else {
      result = data;
    }
    return result;
  }

  // Fetching Grid Data From API
  async function loadExpenseType(event, key, reload = false) {
    if (defaultState[key].length === 0 || reload) {
      setIsProcessing(true);
      api.getService("master/expense/module/consignment").then(
        async (result) => {
          const data = result.data;
          setDefaultState((prevState) => ({ ...prevState, [key]: data?.data }));
          setIsProcessing(false);
        },
        (error) => {
          console.log("error", error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        }
      );
    }
  }

  async function loadData(props) {
    let data = props?.data;
    setRecordId(props?.index);
    setIsProcessing(true);
    let matchedData = matchData({ ...state }, data);
    setState(matchedData);
    setIsProcessing(false);
  }

  async function onSubmit(event) {
    event.preventDefault();
    props.callBack(state, "create", record_id);
  }

  async function onUpdate(event) {
    event.preventDefault();
    props.callBack(state, "update", record_id);
  }

  function changeTypeHeadInputMaterial(selected, name, gd_index) {
    let value = selected.length != 0 ? selected[0] : "";
    const prevState = { ...state };
    prevState.goods[gd_index][name] = value;
    setState(prevState);
  }

  const unloadingGoodsHTML = (
    <>
      {/* <!-- BEGIN: Unloading Goods Information--> */}
      {state?.unloading_goods.map((good, gd_index) => {
        return (
          <>
            {/* <!-- BEGIN: Accordion--> */}
            <div
              id={`unl-gd-accordion`}
              className="accordion accordion-boxed w-full bg-blue-100"
            >
              <div className="accordion-item">
                {/* <!-- BEGIN: Accordion Header--> */}
                <div
                  id={`unl-gd-accordion-content-${gd_index}`}
                  className="accordion-header flex"
                >
                  {" "}
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#unl-gd-accordion-collapse-${gd_index}`}
                    aria-expanded="true"
                    aria-controls={`unl-gd-accordion-collapse-${gd_index}`}
                  >
                    Material {gd_index + 1}{" "}
                  </button>{" "}
                </div>
                {/* <!-- END: Accordion Header--> */}

                {/* <!-- BEGIN: Accordion Body--> */}
                <div
                  id={`unl-gd-accordion-collapse-${gd_index}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`unl-gd-accordion-content-${gd_index}`}
                  data-bs-parent={`#unl-gd-accordion-${gd_index}`}
                >
                  <div className="accordion-body">
                    {/* <!-- BEGIN: Row 1--> */}
                    <div className="grid grid-cols-12 gap-3">
                      <div className="col-span-12 md:col-span-4">
                        <Form.Label>Material Description</Form.Label>
                        <input
                          className="form-control"
                          name="material_description"
                          value={good?.material_description}
                          type="text"
                          placeholder=""
                          disabled={mode.view || consStatus === "invoiced"}
                          onChange={(event) => {
                            changeInputUnloadingGoods(event, gd_index);
                          }}
                        />
                      </div>

                      <div className="col-span-12 md:col-span-4">
                        <Form.Label>GRN No. of Unit</Form.Label>
                        <Form.Input
                          className={
                            good?.number_of_unit >=
                            toFloat(state?.goods[gd_index]["number_of_unit"])
                              ? "form-control text-theme-9"
                              : "form-control text-theme-6"
                          }
                          name="number_of_unit"
                          value={good?.number_of_unit}
                          type="number"
                          placeholder=""
                          disabled={mode.view || consStatus === "invoiced"}
                          onChange={(event) => {
                            changeInputUnloadingGoods(event, gd_index);
                          }}
                          min="0"
                          oninput={"validity.valid||(value='');"}
                        />
                      </div>

                      <div className="col-span-12 md:col-span-4">
                        <Form.Label>GRN Weight/unit &nbsp;(Avg.)</Form.Label>
                        <WeightInput
                          className={
                            good?.weight_per_unit >=
                            toFloat(state?.goods[gd_index]["weight_per_unit"])
                              ? "form-control text-theme-9"
                              : "form-control text-theme-6"
                          }
                          name="weight_per_unit"
                          value={good?.weight_per_unit}
                          type="number"
                          placeholder=""
                          disabled={mode.view || mode.acknowledge}
                          onChange={(event) => {
                            changeInputUnloadingGoods(
                              event,

                              gd_index
                            );
                          }}
                        />
                      </div>
                      <div className="col-span-12 md:col-span-4">
                        <Form.Label>GRN Weight &nbsp;(Avg.)</Form.Label>
                        <WeightInput
                          className={
                            good?.billable_weight >=
                            toFloat(state?.goods[gd_index]["billable_weight"])
                              ? "form-control text-theme-9"
                              : "form-control text-theme-6"
                          }
                          name="billable_weight"
                          value={good?.billable_weight}
                          type="number"
                          placeholder=""
                          disabled={mode.view || mode.acknowledge}
                          onChange={(event) => {
                            changeInputUnloadingGoods(
                              event,

                              gd_index
                            );
                          }}
                        />
                      </div>

                      <div className="col-span-12 md:col-span-4">
                        <Form.Label> GRN Value &nbsp;(approx.)</Form.Label>
                        <CurrencyInput
                          className={
                            good?.billable_value >=
                            toFloat(state?.goods[gd_index]["billable_value"])
                              ? "form-control text-theme-9"
                              : "form-control text-theme-6"
                          }
                          name="billable_value"
                          value={good?.billable_value}
                          type="number"
                          placeholder=""
                          disabled={mode.view || mode.acknowledge}
                          onChange={(event) => {
                            changeInputUnloadingGoods(
                              event,

                              gd_index
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* <!-- END: Row 2--> */}
                  </div>
                </div>
                {/* <!-- END: Accordion  Body--> */}
              </div>
            </div>
            {/* <!-- END: Accordion--> */}
          </>
        );
      })}
      {/* <!-- END: Goods Information--> */}
    </>
  );

  // <!--- END: API CALLS -->

  return (
    <>
      {/* {!mode.view && expenseTypeModal.show && (
        <ExpenseTypeForm
          show={expenseTypeModal.show}
          action={expenseTypeModal.action}
          module={expenseTypeModal.module}
          onHide={closeExpenseTypeModal}
          callBack={reloadExpenseType}
        />
      )} */}

      <div
        key="load_receipt_modal"
        id="load_receipt_modal"
        onClick={(e) => e.stopPropagation()}
      >
        {/* <!-- BEGIN: Modal Content --> */}
        <Modal
          {...props}
          onHide={props.onHide}
          backdrop="static"
          keyboard={false}
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
          </Modal.Header>

          <Modal.Body>
            {isProcessing && <ProcessLoader />}
            <form
              className=""
              onSubmit={
                userAction === "create"
                  ? onSubmit
                  : userAction === "edit"
                  ? onUpdate
                  : (e) => e.preventDefault()
              }
            >
              <div className="" id="lr_receipt">
                {/* <!--BEGIN: Main Details--> */}
                <div className="grid grid-cols-12 gap-3">
                  <div className="col-span-12 md:col-span-4">
                    <Form.Label>L.R No. / G.C No.</Form.Label>
                    <Form.Input
                      type="text"
                      name="lr_number"
                      onChange={changeInput}
                      value={state?.lr_number}
                      required
                      disabled={mode.view}
                    />
                  </div>

                  <div className="col-span-12 md:col-span-4">
                    <Form.Label>In Time</Form.Label>
                    <Form.Input
                      type="datetime-local"
                      name="in_time"
                      onChange={changeInput}
                      value={state?.in_time}
                      disabled={mode.view}
                      max={htmlInputDateTime(new Date())}
                    />
                  </div>

                  <div className="col-span-12 md:col-span-4">
                    <Form.Label>Out Time</Form.Label>
                    <Form.Input
                      type="datetime-local"
                      name="out_time"
                      onChange={changeInput}
                      value={state?.out_time}
                      disabled={mode.view}
                      max={htmlInputDateTime(new Date())}
                    />
                  </div>

                  <div className="col-span-12 md:col-span-4">
                    <Form.Label>Invoice Ref. No. / Bill No.</Form.Label>
                    <Form.Input
                      type="text"
                      name="invoice_number"
                      onChange={changeInput}
                      value={state?.invoice_number}
                      disabled={mode.view}
                    />
                  </div>

                  <div className="col-span-12 md:col-span-4">
                    <Form.Label>PO Ref. Number</Form.Label>
                    <Form.Input
                      type="text"
                      name="po_number"
                      onChange={changeInput}
                      value={state?.po_number}
                      disabled={mode.view}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <Form.Label>E-Way Bill No</Form.Label>
                    <Form.Input
                      type="text"
                      name="eway_bill_no"
                      onChange={changeInput}
                      value={state?.eway_bill_no}
                      disabled={mode.view}
                    />
                  </div>
                </div>
                {/* <!--END: Main Details--> */}

                {/* <!-- BEGIN: UnLoading Goods Information--> */}
                {consStage > 1 && (
                  <Form.Row>
                    <DividerText>Unloading Goods Information</DividerText>
                    {unloadingGoodsHTML}
                  </Form.Row>
                )}
                {/* <!-- BEGIN: UnLoading Goods Information--> */}

                {/* <!-- BEGIN: Loading Goods Information--> */}
                <Form.Row>
                  <DividerText>Loading Goods Information</DividerText>
                  {/* <!-- BEGIN: Goods Information--> */}
                  {state?.goods.map((good, gd_index) => {
                    return (
                      <>
                        {/* <!-- BEGIN: Accordion--> */}
                        <div
                          id={`gd-accordion`}
                          className="accordion accordion-boxed w-full bg-gray-200"
                        >
                          <div className="accordion-item">
                            {/* <!-- BEGIN: Accordion Header--> */}
                            <div
                              id={`gd-accordion-content-${gd_index}`}
                              className="accordion-header flex"
                            >
                              {" "}
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#gd-accordion-collapse-${gd_index}`}
                                aria-expanded="true"
                                aria-controls={`gd-accordion-collapse-${gd_index}`}
                              >
                                Material {gd_index + 1}{" "}
                              </button>{" "}
                              {/* <!-- BEGIN: Add/Remove Goods Button--> */}
                              {!mode.view && (
                                <ArrayAddRemoveButtons
                                  addFunc={addGoods}
                                  removeFunc={(index) => removeGood(gd_index)}
                                  array={state?.goods}
                                  index={gd_index}
                                />
                              )}
                              {/* <!-- END: Add/ Remove Goods Button--> */}
                            </div>
                            {/* <!-- END: Accordion Header--> */}

                            {/* <!-- BEGIN: Accordion Body--> */}
                            <div
                              id={`gd-accordion-collapse-${gd_index}`}
                              className="accordion-collapse collapse show"
                              aria-labelledby={`gd-accordion-content-${gd_index}`}
                              data-bs-parent={`#gd-accordion-${gd_index}`}
                            >
                              <div className="accordion-body">
                                {/* <!-- BEGIN: Row 1--> */}
                                <div className="grid grid-cols-12 gap-3">
                                  <div className="col-span-12 md:col-span-4">
                                    <Form.Label>
                                      Material Description
                                    </Form.Label>

                                    <Typeahead
                                      allowNew
                                      newSelectionPrefix="Add new: "
                                      clearButton
                                      id="material_description"
                                      name="material_description"
                                      onFocus={(event) => {
                                        loadDefaultMetrics(event, "material");
                                      }}
                                      onChange={(selected) => {
                                        changeTypeHeadInputMaterial(
                                          selected,
                                          "material_description",
                                          gd_index
                                        );
                                      }}
                                      options={defaultState?.material}
                                      selected={formatTypeaheadCommon(
                                        good?.material_description || ""
                                      )}
                                      placeholder=""
                                      disabled={mode.view || mode.acknowledge}
                                    />

                                    {/* <input
                                      className="form-control"
                                      name="material_description"
                                      value={good?.material_description}
                                      type="text"
                                      placeholder=""
                                      disabled={mode.view || mode.acknowledge}
                                      onChange={(event) => {
                                        changeInputGoods(
                                          event,

                                          gd_index
                                        );
                                      }}
                                    /> */}
                                  </div>

                                  <div className="col-span-12 md:col-span-4">
                                    <Form.Label>No. of Unit</Form.Label>
                                    <Form.Input
                                      className="form-control"
                                      name="number_of_unit"
                                      value={good?.number_of_unit}
                                      type="number"
                                      placeholder=""
                                      disabled={mode.view || mode.acknowledge}
                                      onChange={(event) => {
                                        changeInputGoods(
                                          event,

                                          gd_index
                                        );
                                      }}
                                      min="0"
                                      oninput={"validity.valid||(value='');"}
                                    />
                                  </div>

                                  <div className="col-span-12 md:col-span-4">
                                    <Form.Label>Weight / Unit</Form.Label>
                                    <WeightInput
                                      className="form-control"
                                      name="weight_per_unit"
                                      value={good?.weight_per_unit}
                                      type="number"
                                      placeholder=""
                                      disabled={mode.view || mode.acknowledge}
                                      onChange={(event) => {
                                        changeInputGoods(
                                          event,

                                          gd_index
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-span-12 md:col-span-4">
                                    <Form.Label>Billable Weight</Form.Label>
                                    <WeightInput
                                      className="form-control"
                                      name="billable_weight"
                                      value={good?.billable_weight}
                                      type="number"
                                      placeholder=""
                                      disabled={mode.view || mode.acknowledge}
                                      onChange={(event) => {
                                        changeInputGoods(
                                          event,

                                          gd_index
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="col-span-12 md:col-span-4">
                                    <Form.Label>Billable Value</Form.Label>
                                    <CurrencyInput
                                      className="form-control"
                                      name="billable_value"
                                      value={good?.billable_value}
                                      type="number"
                                      placeholder=""
                                      disabled={mode.view || mode.acknowledge}
                                      onChange={(event) => {
                                        changeInputGoods(
                                          event,

                                          gd_index
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* <!-- END: Row 2--> */}
                              </div>
                            </div>
                            {/* <!-- END: Accordion  Body--> */}
                          </div>
                        </div>
                        {/* <!-- END: Accordion--> */}
                      </>
                    );
                  })}
                  {/* <!-- END: Goods Information--> */}
                </Form.Row>
                {/* <!-- END: Loading Goods Information--> */}

                {/* <!-- BEGIN: Consigner / Consignee Details--> */}
                <div className="grid grid-cols-12 gap-2">
                  {/* <!-- BEGIN: Consigner--> */}
                  <div className="box border border-gray-200 shadow-lg col-span-12">
                    <div className="flex flex-col sm:flex-row items-center text-center p-2 bg-blue-300">
                      <h2 className="font-medium text-base">
                        Consigner Details
                      </h2>
                    </div>
                    <div className="p-2 grid grid-cols-12 gap-2">
                      <div className="col-span-12 md:col-span-4">
                        <Form.Label className="">Name</Form.Label>
                        <Form.Input
                          type="text"
                          className="form-control  m-b-4"
                          placeholder="Consigner name"
                          name="name"
                          id={"consigner_name"}
                          onChange={(event) => {
                            changeLoadRecieptsChild(event, "consigner");
                          }}
                          value={state?.consigner.name}
                          disabled={mode.view || mode.acknowledge}
                        />
                      </div>

                      <div className="col-span-12 md:col-span-4">
                        <Form.Label className="">GST</Form.Label>
                        <Form.Input
                          type="text"
                          className="form-control  m-b-4"
                          placeholder="Consigner GST"
                          name="gst"
                          id={"consigner_gst"}
                          onChange={(event) => {
                            changeLoadRecieptsChild(
                              event,

                              "consigner"
                            );
                          }}
                          value={state?.consigner?.gst}
                          disabled={mode.view || mode.acknowledge}
                        />
                      </div>

                      <div className="col-span-12 md:col-span-4">
                        <Form.Label className="">Address</Form.Label>
                        <textarea
                          type="text"
                          className="form-control  m-b-4"
                          placeholder="Consigner Address"
                          name="address"
                          id={"consigner_address"}
                          onChange={(event) => {
                            changeLoadRecieptsChild(
                              event,

                              "consigner"
                            );
                          }}
                          value={state?.consigner?.address}
                          disabled={mode.view || mode.acknowledge}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!-- END: Consignee--> */}

                  {/* <!-- BEGIN: Consignee--> */}
                  <div className="box border border-gray-200 shadow-lg col-span-12">
                    <div className="flex flex-col sm:flex-row items-center text-center p-2 bg-gray-500">
                      <h2 className="font-medium text-base">
                        Consignee Details
                      </h2>
                    </div>
                    <div className="p-2 grid grid-cols-12 gap-3">
                      <div className="col-span-12 md:col-span-4">
                        <Form.Label className="">Name</Form.Label>
                        <Form.Input
                          type="text"
                          className="form-control m-b-4"
                          placeholder="Consignee name"
                          name="name"
                          id={"consignee_name"}
                          onChange={(event) => {
                            changeLoadRecieptsChild(
                              event,

                              "consignee"
                            );
                          }}
                          value={state?.consignee.name}
                          disabled={mode.view || mode.acknowledge}
                        />
                      </div>
                      <div className="col-span-12 md:col-span-4">
                        <Form.Label className="">GST</Form.Label>
                        <Form.Input
                          type="text"
                          className="form-control m-b-4"
                          placeholder="Consignee GST"
                          name="gst"
                          id={"consignee_gst"}
                          onChange={(event) => {
                            changeLoadRecieptsChild(
                              event,

                              "consignee"
                            );
                          }}
                          value={state?.consignee.gst}
                          disabled={mode.view || mode.acknowledge}
                        />
                      </div>
                      <div className="col-span-12 md:col-span-4">
                        <Form.Label className="">Address</Form.Label>
                        <textarea
                          type="text"
                          className="form-control  m-b-4"
                          placeholder="Consignee Address"
                          name="address"
                          id={"consignee_address"}
                          onChange={(event) => {
                            changeLoadRecieptsChild(
                              event,

                              "consignee"
                            );
                          }}
                          value={state?.consignee.address}
                          disabled={mode.view || mode.acknowledge}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!-- END: Consignee--> */}
                </div>
                {/* <!-- END: Consigner / Consignee Details--> */}
              </div>

              <input
                type="submit"
                id="save_cons_expense_type"
                className="hidden"
              />
              <input
                type="submit"
                id="update_cons_expense_type"
                className="hidden"
              />
            </form>
          </Modal.Body>
          {/* <!-- END: Modal Body --> */}
          {/* <!-- BEGIN: Modal Footer --> */}
          <Modal.Footer>
            {" "}
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-outline-secondary w-20 mr-1"
              onClick={props.onHide}
            >
              Cancel
            </button>{" "}
            {userAction === "create" && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="save_cons_expense_type"
              >
                Save
              </label>
            )}
            {userAction === "edit" && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="update_cons_expense_type"
              >
                Update
              </label>
            )}
          </Modal.Footer>
          {/* <!-- END: Modal Footer --> */}
        </Modal>

        {/* <!-- END: Modal Content --> */}
      </div>
    </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Expense",
  action: "view",
  onSave() {},
  callBack() {},
};
