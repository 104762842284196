// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// LOADERS
import ProcessLoader from "../preloader/processLoader";
import AddressInput from "../htmlElements/addressInput";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";

// FUNCTIONS
import { resp } from "../../functions/responseHandler";
import {
  htmlInputDateTime,
  forwardGeocode,
  matchData,
} from "../../functions/common";
import * as api from "../../api_service/api";

const Model = {
  place: "",
  remark: "",
  time: htmlInputDateTime(new Date()),
  // GPS Data
  label: "",
  latitude: "",
  longitude: "",
  street: "",
  city: "",
  district: "",
  country: "",
  country_code: "",
  state: "",
  state_code: "",
  continent: "",
};

export default function Component(props) {
  // Defaults
  const [saveToDB, setSaveToDB] = useState(false);
  const [pageTitle, setPageTitle] = useState("Transit Points");
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });
  // State
  const [state, setState] = useState([]);
  const [record_id, setRecordId] = useState(); // Consignment Id

  useEffect(() => {
    handleUserAction(props);
  }, []);

  function addTransits() {
    var list = [...state];
    const modelData = { ...Model };
    modelData["time"] = htmlInputDateTime(new Date());
    list.push(modelData);
    setState(list);
  }

  function removeTransits(index) {
    var list = [...state];
    list.splice(index, 1);
    setState(list);
  }

  // <!--- BEGIN: FUNCTIONS -->
  async function handleUserAction(props) {
    setUserAction(props?.action);
    switch (props.action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setPageTitle("Transit Points");
        loadData(props);
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        setPageTitle("Transit Points");
        loadData(props);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        setPageTitle("Transit Points");
        loadData(props);
        break;
      default:
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
    }
  }

  async function prepareData(data) {
    // console.log("datasa:", typeof state_data);
    // let data = [...state_data];

    // for (var i in data) {
    //   data[i].time = mongoUTC(data[i].time);
    // }

    let response = [];
    if (typeof data === "array") {
      response = data;
    }
    if (typeof data === "object") {
      response = await objectToArray(data);
    }
    return response;
  }

  async function objectToArray(data) {
    const objArray = [];
    await Object.keys(data).forEach((key) => objArray.push(data[key]));
    return objArray;
  }
  // <!--- END: FUNCTIONS -->

  // <!--- BEGIN: FORM CHANGE FUNCTIONS -->
  const changeTransits = (event, index) => {
    const { name, value } = event.target;
    const list = [...state];
    list[index][name] = value;
    setState(list);
  };

  function changeInput(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState[name] = value;
    setState(prevState);
  }

  function changeInputAddress(address, index) {
    const list = [...state];
    list[index]["place"] = address;
    setState(list);
  }

  async function selectLocation(address, index) {
    const list = [...state];
    list[index]["place"] = address;
    setState(list);

    // Getting GPS Details

    await forwardGeocode(address)
      .then(async (data) => {
        let matchedData = await matchData(list[index], data);
        list[index] = matchedData;
        setState(list);
      })
      .catch((error) => {
        console.log("Error Parsing Address:", error);
      });

    // if (gpsData) {
    //   let matchedData = await matchData(list[index], gpsData);
    //   console.log("matchedData:",matchedData)
    //   list[index] = matchedData;
    //   setState(list);
    // }
  }
  // <!--- END: FORM CHANGE FUNCTIONS -->

  // <!--- BEGIN: API CALLS -->

  async function loadData(props) {
    setIsProcessing(true);
    let prosData = [];
    let data = props?.transits || [];
    setRecordId(props?.consignmentId);
    setSaveToDB(props?.saveToDB || false);
    if (data.length > 1) {
      for (var i in data) {
        data[i]["time"] = htmlInputDateTime(data[i]["time"]);
      }
      prosData = data;
    } else {
      const currentModel = { ...Model };
      currentModel["time"] = htmlInputDateTime(new Date());
      prosData.push(currentModel);
    }

    console.log("prosData:", typeof prosData);
    setState(prosData);
    setIsProcessing(false);
  }

  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var data = prepareData(state);
    if (saveToDB) {
      let body = { transits: data };
      api
        .putService(`consignment/${record_id}`, body)
        .then((response) => {
          setIsProcessing(false);
          resp.SuccessResponse(response);
          props.callBack(data);
        })
        .catch((error) => {
          resp.ErrorHandler(error);
          console.log(error);
          setIsProcessing(false);
        });
    } else {
      props.callBack(state);
    }
  }

  // <!--- END: API CALLS -->

  return (
    <div
      key="transits_form_modal"
      id="transits_form_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size={"lg"}
        dialogClassName="modal-xxl"
        centered
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form className="" onSubmit={onSubmit}>
            {state.map((item, index) => (
              <div
                className="box shadow-lg border border-gray-300 grid grid-cols-12 gap-2 gap-y-2 px-3 py-5 mb-2"
                id={"transit_" + index}
              >
                <div className="col-span-12 sm:col-span-4">
                  <label className="form-label">Place</label>
                  <AddressInput
                    className="form-control"
                    name="place"
                    value={item?.place}
                    type="text"
                    disabled={mode?.view}
                    onChange={(address) => {
                      changeInputAddress(address, index);
                    }}
                    onSelect={(address) => selectLocation(address, index)}
                    required
                  />
                </div>
                <div className="col-span-12 sm:col-span-3">
                  <label className="form-label">Time</label>
                  <input
                    className="form-control"
                    name="time"
                    value={item.time}
                    type="datetime-local"
                    required
                    disabled={mode?.view}
                    onChange={(event) => {
                      changeTransits(event, index);
                    }}
                  />
                </div>
                <div className="col-span-12 sm:col-span-4">
                  <label className="form-label">Remarks</label>
                  <textarea
                    className="form-control"
                    name="remark"
                    value={item.remark}
                    type="text"
                    disabled={mode?.view}
                    onChange={(event) => {
                      changeTransits(event, index);
                    }}
                  />
                </div>

                <div className="col-span-1 flex flex-col justify-center items-center">
                  {!mode?.view && (
                    <div>
                      <div className="btn-group">
                        {state.length !== 1 && (
                          <a
                            href={void 0}
                            className="btn btn-danger mr-1"
                            onClick={() => removeTransits(index)}
                          >
                            <i
                              className="fa fa-trash-o text-danger"
                              aria-hidden="true"
                            />
                          </a>
                        )}
                        {state.length - 1 === index && (
                          <a
                            href={void 0}
                            className="btn btn-primary"
                            onClick={(index) => addTransits()}
                          >
                            <i className="fa fa-plus" aria-hidden="true" />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <input type="submit" id="save_transit" className="hidden" />
            <input type="submit" id="update_transit" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_transit"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_transit"
            >
              Save
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Transit Points",
  action: "view",
  onSave() {},
  callBack() {},
};
