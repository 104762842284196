import React, { useState, useEffect } from "react";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
// HTML Elements
import BackButton from "../../components/pageElements/backButton";
import Container from "../../components/container";
import Form from "../../components/form/custom";
import WeightInput from "../../components/htmlElements/weightInput";
import KmInput from "../../components/htmlElements/kmInput";
import InfoButton from "../../components/htmlElements/infoButton";
import ConsNumberInfo from "../../components/pageElements/consNumberInfo";
import ConsNumberDisplay from "../../components/pageElements/consNumberDisplay";
import TruckNumberDisplay from "../../components/pageElements/truckNumberDisplay";
import LoadExceedAlert from "../../components/pageElements/loadExceedAlert";
import LoadShortageAlert from "../../components/pageElements/loadShortageAlert";
import SimpleTab from "../../components/tabs/simpleTab";
// Typeahead Component
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

// Add-on Components
import ConsignmentForm from "./new-form";
import ChargesForm from "../../components/consignment/chargesForm";
import Timeline from "../../components/consignment/timeline";
import TransitPointsTimeline from "../../components/consignment/transitPointsTimeline";
import TransitTimeline from "../../components/consignment/transitTimeline";
import TransitForm from "../../components/consignment/transitForm";
import LRForm from "../../components/consignment/LRForm";
import PaymentForm from "../../components/consignment/paymentForm";
import SimTrackingMap from "../../components/consignment/simTrackingMap";
// Functions
import * as api from "../../api_service/api";
import { alertBox } from "../../components/notification/alert";
import { resp } from "../../functions/responseHandler";
import {
  getDateDiff,
  matchData,
  htmlDate,
  htmlInputDateTime,
  toFloat,
  mongoUTC,
  formatCurrency,
  isEmpty,
  standardCase,
  getConsignmentStage,
  cloneObject,
  isDefined,
  formatPhoneNumber,
  formatTypeaheadCommon,
  calcTripDistance,
  calculateTripDuration
} from "../../functions/common";

import { storageServices } from "../../functions/storage";
import { loadReceiptReport } from "../../functions/reports";
import * as validation from "../../functions/validation";
import FeatherIcons from "../../assets/js/feather";

// Models
import { Model, ValidationErrorModel, ErrorMsg } from "./model";

// Images & Icons
import { ReactComponent as ClientIcon } from "../../assets/images/svg/client.svg";
import { ReactComponent as DriverIcon } from "../../assets/images/svg/driver.svg";
import { ReactComponent as TruckIcon } from "../../assets/images/svg/truck.svg";
import { ReactComponent as SupplierIcon } from "../../assets/images/svg/supplier.svg";
import { ReactComponent as AddLocationIcon } from "../../assets/images/svg/add-location.svg";
import CurrencyInput from "../../components/htmlElements/currencyInput";

export default function ConsignmentView(props) {
  const [pageTitle, setPageTitle] = useState("Consignment");
  const [consStage, setConsStage] = useState(0);
  const [currentTab, setCurrentTab] = useState("basic_details");
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [loader, setLoader] = useState({
    create: false,
    update: false,
    submit: false,
    complete: false,
    acknowledge: false,
    acknowledge_and_complete: false,
  });
  // Modes
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
    submit: false,
    complete: false,
    acknowledge: false,
    acknowledge_and_complete: false,
    acknowledge_edit: false,
  });
  // State

  const [localUser, setLocalUser] = useState();
  const [state, setState] = useState(Model());
  const [recordId, SetRecordId] = useState();
  // const [state, setState] = useState(Object.assign({}, Model));

  const [defaultState, setDefaultState] = useState({
    material: [],
  });

  //////////// External Modal States   ////////////

  const [simTrackingMapModal, setSimTrackingMapModal] = useState({
    show: false,
    query: {},
  });

  const [chargesFormModal, setChargesFormModal] = useState({
    show: false,
    action: "",
    data: {},
    id: "",
    index: "",
    charges_key: "",
    module: "",
  });

  const [LRFormModal, setLRFormModal] = useState({
    show: false,
    action: "",
    index: "",
    data: {},
    id: "",
    cons_status: "",
  });
  const [transitsFormModal, setTansitsFormModal] = useState({
    show: false,
    action: "edit",
    transits: [],
    consignmentId: "",
    cons_status: "",
  });
  const [paymentFormModal, setPaymentFormModal] = useState({
    show: false,
    action: "create",
    data: "",
    index: "",
    supplier_max_limit: 0,
    client_max_limit: 0,
    disableSupplierPayment: true,
    disableClientPayment: true,
  });

  // <!--BEGIN : Transit Timeline Modal -->
  const [transitTimelineModal, setTransitTimelineModal] = useState({
    show: false,
    data: {},
  });
  const closeTransitTimelineModal = () =>
    setTransitTimelineModal((prevState) => ({ ...prevState, show: false }));

  const openTransitTimelineModal = () =>
    setTransitTimelineModal((prevState) => ({ ...prevState, show: true }));
  // <!--END : Transit Timeline Modal -->

  // Modal Functions
  const [consFormModal, setConsFormModal] = useState({
    show: false,
    action: "",
    id: "",
  });

  const closeConsFormModal = () =>
    setConsFormModal((prevState) => ({ ...prevState, show: false }));

  function editConsignment(event) {
    event.preventDefault();
    let preFormModal = { ...consFormModal };
    preFormModal.action = "edit";
    preFormModal.show = true;
    preFormModal.id = recordId;
    setConsFormModal(preFormModal);
    return null;
  }

  function consFormCallBack(data) {
    closeConsFormModal();
    // window.location.reload();
    reloadPage();
    return null;
  }

  //////////// External Modal States   ////////////

  // Validation
  const [validationError, setValidationError] = useState(
    ValidationErrorModel()
  );
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  ////////// FUNCTIONS  //////////
  useEffect(() => {
    FeatherIcons();
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction(props);
  }, []);

  // <!-- BEGIN: SIM Tracking Map Form Modal -- >
  function openSimTrackingModal() {
    let prevState = { ...simTrackingMapModal };
    prevState.show = true;
    prevState.query = {
      phone_number: state?.driver?.contact_number,
      driver_id: state?.driver?._id,
      consignment_id: state?._id,
    };
    setSimTrackingMapModal(prevState);
  }
  const closeSimTrackingModal = () =>
    setSimTrackingMapModal((prevState) => ({ ...prevState, show: false }));
  // <!-- END: SIM Tracking Map Form Modal -- >

  // <!-- BEGIN: Charges Form Modal -- >
  function openChargesFormModal(module, charge_type) {
    let prevState = { ...chargesFormModal };
    prevState.action = "create";
    prevState.show = true;
    prevState.index = "";
    prevState.charge_type = charge_type;
    prevState.module = module;
    setChargesFormModal(prevState);
  }

  const closeChargesFormModal = () =>
    setChargesFormModal((prevState) => ({ ...prevState, show: false }));

  function chargesFormCallBack(data, action, index, charge_type, module) {
    closeChargesFormModal();
    let charges_array_key = `${module}_${charge_type}`;
    chargesFormCallBackFunction(data, action, index, charges_array_key, module);
  }

  function chargesFormCallBackFunction(
    data,
    action,
    index,
    charges_array_key,
    module
  ) {
    let prevState = { ...state };
    let array = prevState?.charges[charges_array_key] || [];

    if (action === "create") {
      array.push(data);
    }
    if (action === "update") {
      array[index] = data;
    }
    prevState.charges[charges_array_key] = array;
    setState((prevState) => ({
      ...prevState,
      charges: { ...prevState.charges, [charges_array_key]: array },
    }));

    if (module === "consignment") {
      calcConsignmentTotalAmount(prevState);
    }

    if (module === "client") {
      calcClientTotalAmount(prevState);
    }

    return null;
  }

  function editChargesItem(event, item, index, charge_type, module) {
    event.preventDefault();
    let prevState = { ...chargesFormModal };
    prevState.action = "edit";
    prevState.data = item;
    prevState.index = index;
    prevState.charge_type = charge_type;
    prevState.module = module;
    prevState.show = true;
    setChargesFormModal(prevState);
  }

  function deleteChargesItem(event, item, index, charges_array_key, module) {
    event.preventDefault();
    alertBox.confirmation({
      type: "info",
      title: "Delete Charges",
      text: "Are you sure want to delete this Charge?",
      onConfirm: function () {
        confirmDeleteChargesItem(item, index, charges_array_key, module);
      },
    });
  }

  function confirmDeleteChargesItem(item, index, charges_array_key, module) {
    let prevState = { ...state };
    var arrayElement = prevState?.charges[charges_array_key];
    arrayElement.splice(index, 1);

    prevState.charges[charges_array_key] = arrayElement;
    setState((prevState) => ({
      ...prevState,
      charges: { ...prevState.charges, [charges_array_key]: arrayElement },
    }));

    if (module === "consignment") {
      calcConsignmentTotalAmount(prevState);
    }

    if (module === "client") {
      calcClientTotalAmount(prevState);
    }
  }

  // <!-- END: Charges Form Modal -- >

  // <!-- BEGIN: Transit Form Modal -- >

  function openTransitsModal() {
    let prevState = { ...transitsFormModal };
    prevState.action = "edit";
    prevState.show = true;
    prevState.transits = [...state?.transits];
    prevState.consignmentId = state?._id;
    prevState.cons_status = state?.status;
    setTansitsFormModal(prevState);
  }

  const closeTransitsModal = () =>
    setTansitsFormModal((prevState) => ({ ...prevState, show: false }));

  function transitsModalCallBack(data) {
    if (data) {
      setState((prevState) => ({ ...prevState, transits: data }));
    }
    closeTransitsModal();
  }

  // <!-- END: Transit Form Modal -- >

  // <!-- BEGIN: LR Form Modal -- >
  function openLRFormModal() {
    let prevState = { ...LRFormModal };
    prevState.action = "create";
    prevState.show = true;
    prevState.index = 0;
    prevState.data = {};
    setLRFormModal(prevState);
  }

  const closeLRFormModal = () =>
    setLRFormModal((prevState) => ({ ...prevState, show: false }));

  function LRFormCallBack(data, action, index) {
    closeLRFormModal();
    LRFormCallBackFunction(data, action, index);
  }

  function viewLRItem(event, item, index) {
    event.preventDefault();
    let prevState = { ...LRFormModal };
    prevState.action = "view";
    prevState.data = item;
    prevState.index = index;
    prevState.show = true;
    setLRFormModal(prevState);
  }

  function editLRItem(event, item, index) {
    event.preventDefault();
    let prevState = { ...LRFormModal };
    prevState.action = "edit";
    prevState.data = item;
    prevState.index = index;
    prevState.show = true;
    setLRFormModal(prevState);
  }

  function deleteLRItem(event, item, index) {
    event.preventDefault();
    alertBox.confirmation({
      type: "info",
      title: "Delete LR",
      text: "Are you sure want to delete this LR?",
      onConfirm: function () {
        confirmDeleteLRItem(item, index);
      },
    });
  }

  function confirmDeleteLRItem(item, index) {
    let prevState = { ...state };
    var array = prevState?.load_receipts;
    array.splice(index, 1);
    prevState.load_receipts = array;
    setState((prevState) => ({ ...prevState, load_receipts: array }));
  }

  async function LRFormCallBackFunction(data, action, index) {
    let prevState = { ...state };
    let array = prevState?.load_receipts;
    if (action === "create") {
      array.push(data);
    }
    if (action === "update") {
      array[index] = data;
    }
    prevState.load_receipts = array;
    setState((prevState) => ({ ...prevState, load_receipts: array }));

    // Calculating Loading Point In Time / Out Time
    await calcInTime(array);
    await calcOutTime(array);

    // Loading Point
    sumTotalPackages(array);
    sumTotalInvoiceWeight(array);
    sumTotalMaterialValueLoading(array);

    // Unloading Point
    sumTotalPackagesReceived(array);
    sumTotalInvoiceWeightReceived(array);
    sumTotalMaterialValueUnloading(array);

    //Setting Material
    if (!state?.material) {
      setMaterialFromLR(array);
    }

    return null;
  }

  // <!-- END: LR Form Modal -- >

  // <!-- BEGIN: Payment Form Modal -- >

  const closePaymentFormModal = () =>
    setPaymentFormModal((prevState) => ({ ...prevState, show: false }));

  function paymentFormCallBack(data, type, action, index) {
    closePaymentFormModal();
    paymentFormCallBackFunction(data, type, action, index);
  }

  function addPayment(event) {
    event.preventDefault();
    let prevState = { ...paymentFormModal };
    prevState.action = "create";
    prevState.data = {};
    prevState.index = 0;
    prevState.supplier_max_limit = toFloat(state?.supplier_balance || 0);
    prevState.client_max_limit = toFloat(state?.client_balance || 0);

    // Checking Supplier Balance
    if (
      state?.supplier_balance === 0 ||
      state?.vehicle_ownership_type === "own"
    ) {
      prevState.disableSupplierPayment = true;
    } else {
      prevState.disableSupplierPayment = false;
    }

    // Checking Client  Balance
    if (state?.client_balance === 0) {
      prevState.disableClientPayment = true;
    } else {
      prevState.disableClientPayment = false;
    }

    prevState.show = true;

    setPaymentFormModal(prevState);
  }

  function editPaymentItem(event, data, index) {
    event.preventDefault();
    let prevState = { ...paymentFormModal };
    prevState.action = "edit";
    prevState.data = data;
    prevState.index = index;
    prevState.supplier_max_limit = toFloat(state?.supplier_balance || 0);
    prevState.client_max_limit = toFloat(state?.client_balance || 0);

    prevState.disableSupplierPayment = true;
    prevState.disableClientPayment = true;
    prevState.show = true;
    setPaymentFormModal(prevState);
  }

  function deletePaymentItem(event, item, index) {
    event.preventDefault();
    alertBox.confirmation({
      type: "info",
      title: "Delete Payment",
      text: "Are you sure want to delete this Payment?",
      onConfirm: function () {
        confirmDeletePaymentItem(item, index);
      },
    });
  }

  async function confirmDeletePaymentItem(item, index) {
    setIsProcessing(true);
    // Updating Flag
    item["isDeleted"] = true;

    let prevState = { ...state };
    let payablesArray = prevState?.transactions?.payables;
    let receivablesArray = prevState?.transactions?.receivables;

    // Payables
    if (item?.transaction_type === "payable") {
      payablesArray[index] = item;
      prevState.transactions.payables = payablesArray;
      prevState["supplier_balance"] = await calcSupplierBalance(prevState);
      setState(prevState);
    }

    // Receivable
    if (item?.transaction_type === "receivable") {
      receivablesArray[index] = item;
      prevState.transactions.receivables = receivablesArray;
      prevState["client_balance"] = await calcClientBalance(prevState);
      setState(prevState);
    }
    setIsProcessing(false);
    return null;
  }

  function preparePayablesData(array) {
    for (var i in array) {
      let data = array[i];

      // Payment Mode
      // Cash
      if (data.payment_mode === "cash" && isDefined(data?.payment_cash)) {
        data["payment_cash"] = data?.payment_cash?._id;
      }
      // Bank
      if (data.payment_mode === "bank" && isDefined(data?.payment_bank)) {
        data["payment_bank"] = data?.payment_bank?._id;
      }
      // Petrol Bunk
      if (data.payment_mode === "journal" && isDefined(data?.petrolbunk)) {
        data["petrolbunk"] = data?.petrolbunk?._id;
      }

      array[i] = data;
    }
    return array;
  }
  function prepareReceivablesData(array) {
    for (var i in array) {
      let data = array[i];

      // Payment Mode
      // Cash
      if (data.payment_mode === "cash" && isDefined(data?.payment_cash)) {
        data["payment_cash"] = data?.payment_cash?._id;
      }
      // Bank
      if (data.payment_mode === "bank" && isDefined(data?.payment_bank)) {
        data["payment_bank"] = data?.payment_bank?._id;
      }
      // Petrol Bunk
      if (data.payment_mode === "journal" && isDefined(data?.petrolbunk)) {
        data["petrolbunk"] = data?.petrolbunk?._id;
      }

      array[i] = data;
    }
    return array;
  }
  async function paymentFormCallBackFunction(data, type, action, index) {
    setIsProcessing(true);

    let prevState = { ...state };
    let payablesArray = prevState?.transactions?.payables;
    let receivablesArray = prevState?.transactions?.receivables;

    // Payables
    if (type === "payable") {
      // New Payment
      if (action === "create") {
        payablesArray.push(data);
      }
      // Updating Payment
      else if (action === "update") {
        data["isUpdated"] = true;
        payablesArray[index] = data;
      }

      prevState.transactions.payables = payablesArray;
      prevState["supplier_balance"] = await calcSupplierBalance(prevState);
      setState(prevState);
    }

    // Receivable
    if (type === "receivable") {
      // New Payment
      if (action === "create") {
        receivablesArray.push(data);
      }
      // Updating Payment
      else if (action === "update") {
        data["isUpdated"] = true;
        receivablesArray[index] = data;
      }
      prevState.transactions.receivables = receivablesArray;
      prevState["client_balance"] = await calcClientBalance(prevState);
      setState(prevState);
    }
    setIsProcessing(false);
    return null;
  }

  // <!-- END: Payment Form Modal -- >

  function calcTotalPayable(currentState) {
    if (
      (currentState?.status === "pending" ||
        currentState?.status === "in_progress") &&
      currentState?.charges?.consignment_hold_amount > 0
    ) {
      return toFloat(
        currentState?.charges?.consignment_total_amount -
          currentState?.charges?.consignment_hold_amount
      );
    } else {
      return currentState?.charges?.consignment_total_amount;
    }
  }
  function calcSupplierBalance(currentState) {
    let payablesArray = currentState?.transactions?.payables || [];
    let supplier_balance = toFloat(
      currentState?.charges?.consignment_total_payable
    );

    for (var i in payablesArray) {
      if (payablesArray[i]?.isDeleted) {
        supplier_balance = supplier_balance;
      } else {
        supplier_balance =
          supplier_balance - toFloat(payablesArray[i]?.transaction_amount || 0);
      }
    }

    // setState((prevState) => ({
    //   ...prevState,
    //   supplier_balance: supplier_balance,
    // }));
    return supplier_balance;
  }

  function calcClientBalance(currentState) {
    let receivablesArray = currentState?.transactions?.receivables || [];
    let total_amount = currentState?.charges?.client_total_amount;
    let client_balance = total_amount;

    for (var i in receivablesArray) {
      if (receivablesArray[i]?.isDeleted) {
        client_balance = client_balance;
      } else {
        client_balance =
          client_balance -
          toFloat(receivablesArray[i]?.transaction_amount || 0);
      }
    }
    return client_balance;
  }

  async function handleUserAction(props) {
    let action = props.match.params?.action;
    let id = props.match.params?.id;

    await loadDataById(id);

    // switch (action) {
    //   case "add":
    //     setPageTitle("Create Consignment");
    //     setDefaults();
    //     setMode((prevState) => ({ ...prevState, create: true }));
    //     break;
    //   case "edit":
    //     setPageTitle("Edit Consignment");
    //     setMode((prevState) => ({ ...prevState, edit: true }));
    //     await loadDataById(id);
    //     break;
    //   case "view":
    //     setPageTitle("View Consignment");
    //     setMode((prevState) => ({ ...prevState, view: true }));
    //     await loadDataById(id);
    //     break;
    //   case "submit":
    //     setPageTitle("Start Consignment");
    //     setMode((prevState) => ({ ...prevState, submit: true }));
    //     await loadDataById(id);
    //     break;
    //   case "acknowledge":
    //     setPageTitle("Complete Consignment");
    //     setMode((prevState) => ({ ...prevState, acknowledge: true }));
    //     await loadDataById(id);
    //     break;
    //   case "acknowledge_edit":
    //     setPageTitle("Edit Consignment");
    //     setMode((prevState) => ({ ...prevState, acknowledge_edit: true }));
    //     await loadDataById(id);
    //     break;
    //   default:
    //     setMode((prevState) => ({ ...prevState, view: true }));
    // }
  }

  async function handleUserByStatus(status) {
    switch (status) {
      case "view":
        setPageTitle("View Consignment");
        setMode((prevState) => ({ ...prevState, view: true }));
        break;
      case "pending":
        setPageTitle("Start Consignment");
        setMode((prevState) => ({ ...prevState, submit: true }));
        break;
      case "in_progress":
        setPageTitle("Complete Consignment");
        setMode((prevState) => ({ ...prevState, acknowledge: true }));
        break;
      case "completed":
        setPageTitle("Acknowledge Consignment");
        setMode((prevState) => ({ ...prevState, acknowledge: true }));
        break;
      case "acknowledged":
        setPageTitle("Edit Consignment");
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
      case "invoiced":
        setPageTitle("Edit Consignment");
        setMode((prevState) => ({ ...prevState, acknowledge_edit: true }));
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  ///////////////// Handling Input Changes //////////////////////
  function loadDataById(id) {
    SetRecordId(id);
    setIsProcessing(true);
    api.getService(`consignment/${id}`).then(
      async (result) => {
        const data = result.data?.consignment;

        await mapData(data);

        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setError(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);
  }

  function changeInputOdometer(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    formValidation(name, value);

    // Calculating Trip Distance
    if (consStage > 1) {
      if (name === "start_odometer_reading") {
        if (isDefined(state?.end_odometer_reading)) {
          let tripDistance = calcTripDistance(
            state?.end_odometer_reading,
            value
          );
          setState((prevState) => ({
            ...prevState,
            trip_distance: tripDistance,
          }));
        }
      }

      if (name === "end_odometer_reading") {
        if (isDefined(state?.start_odometer_reading)) {
          let tripDistance = calcTripDistance(
            value,
            state?.start_odometer_reading
          );
          setState((prevState) => ({
            ...prevState,
            trip_distance: tripDistance,
          }));
        }
      }
    }
  }

  function changeInputObject(event, parent) {
    const { name, value } = event.target;
    let obj = { ...state[parent] };
    obj[name] = value;
    setState((prevState) => ({ ...prevState, [parent]: obj }));

    // Loading Point
    if (parent === "loading_point" && name !== "no_of_days") {
      calcNoOfDays(obj);
    }
    if (parent === "loading_point" && name === "out_time") {
      setState((prevState) => ({ ...prevState, start_time: value }));
    }

    // Unloading Point
    if (parent === "unloading_point" && name !== "no_of_days") {
      calcNoOfDaysUnloading(obj);
    }
    if (parent === "unloading_point" && name === "out_time") {
      setState((prevState) => ({ ...prevState, end_time: value }));
    }
  }


  function calcClientTotalAmount(currentState) {
    let totalAmount = toFloat(currentState?.charges?.client_freight || 0);

    // Adding additions items - Expenses / Other
    for (var i in currentState?.charges?.client_addition) {
      totalAmount += toFloat(currentState.charges?.client_addition[i]?.amount);
    }

    // Subtracting deductions items - Deductions / Other
    for (var i in currentState?.charges?.client_deduction) {
      totalAmount =
        totalAmount -
        toFloat(currentState?.charges?.client_deduction[i]?.amount);
    }
    currentState["charges"]["client_total_amount"] = totalAmount.toFixed(2);
    setState((prevState) => ({
      ...prevState,
      charges: {
        ...prevState.charges,
        client_total_amount: totalAmount.toFixed(2),
      },
    }));
    let clientBalance = calcClientBalance(currentState);
    setState((prevState) => ({ ...prevState, client_balance: clientBalance }));
  }

  function calcConsignmentTotalAmount(currentState) {
    let totalAmount = toFloat(currentState?.charges?.consignment_freight);
    let tdsAmount = 0;

    // Adding additions items - Expenses / Other
    for (var i in currentState?.charges?.consignment_addition) {
      totalAmount += toFloat(
        currentState.charges?.consignment_addition[i]?.amount
      );
    }

    // Subtracting deductions items - Deductions / Other
    for (var i in currentState?.charges?.consignment_deduction) {
      totalAmount =
        totalAmount -
        toFloat(currentState?.charges?.consignment_deduction[i]?.amount);
    }

    if (currentState?.charges?.consignment_tds) {
      tdsAmount = toFloat(
        (currentState?.charges?.consignment_tds_percentage / 100) *
          currentState?.charges?.consignment_freight
      );
      totalAmount = totalAmount - tdsAmount;
      currentState["charges"]["consignment_tds_amount"] = tdsAmount.toFixed(2);
      setState((prevState) => ({
        ...prevState,
        charges: {
          ...prevState.charges,
          consignment_tds_amount: tdsAmount.toFixed(2),
        },
      }));
    }
    currentState["charges"]["consignment_total_amount"] =
      totalAmount.toFixed(2);

    setState((prevState) => ({
      ...prevState,
      charges: {
        ...prevState.charges,
        consignment_total_amount: totalAmount.toFixed(2),
      },
    }));

    let totalPayable = calcTotalPayable(currentState);
    currentState["charges"]["consignment_total_payable"] = totalPayable;
    setState((prevState) => ({
      ...prevState,
      charges: {
        ...prevState.charges,
        consignment_total_payable: totalPayable,
      },
    }));
    setState((prevState) => ({
      ...prevState,
      supplier_balance: calcSupplierBalance(currentState),
    }));

    return;
  }
  function calcTdsAmount(currentState) {
    let tdsAmount = 0;
    tdsAmount = toFloat(
      ((currentState?.tds_percentage | 0) / 100) *
        currentState?.charges?.consignment_freight
    );

    return tdsAmount;
  }

  function setMaterialFromLR(load_receipts_list) {
    // Setting First LR Material as common material
    if (load_receipts_list.length > 0) {
      let firstLR = load_receipts_list[0];
      if (firstLR?.goods.length > 0) {
        let firstGoods = firstLR?.goods[0];
        let material = firstGoods?.material_description || "";
        setState((prevState) => ({ ...prevState, material: material }));
      }
    }
  }

  function sumTotalPackages(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].goods) {
        sum += parseInt(load_receipts_list[i].goods[j].number_of_unit || 0);
      }
    }
    setState((prevState) => ({ ...prevState, total_no_Of_packages: sum }));
  }

  function sumTotalInvoiceWeight(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].goods) {
        sum =
          parseFloat(sum) +
          parseFloat(load_receipts_list[i].goods[j]?.billable_weight || 0);
        sum = sum.toFixed(2);
      }
    }
    setState((prevState) => ({ ...prevState, total_invoice_weight: sum }));
  }
  function sumTotalMaterialValueLoading(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].goods) {
        sum =
          parseFloat(sum) +
          parseFloat(load_receipts_list[i].goods[j]?.billable_value || 0);
        sum = sum.toFixed(2);
      }
    }
    setState((prevState) => ({
      ...prevState,
      total_material_value_loading: sum,
    }));
  }

  // Calclutation Total Packages & Invoice - Unloading Point
  function sumTotalPackagesReceived(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].unloading_goods) {
        sum += parseInt(
          load_receipts_list[i].unloading_goods[j].number_of_unit || 0
        );
      }
    }
    setState((prevState) => ({
      ...prevState,
      total_no_Of_packages_received: sum,
    }));
  }

  function sumTotalInvoiceWeightReceived(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].unloading_goods) {
        sum =
          parseFloat(sum) +
          parseFloat(
            load_receipts_list[i].unloading_goods[j].billable_weight || 0
          );
        sum = sum.toFixed(2);
      }
    }
    setState((prevState) => ({
      ...prevState,
      total_invoice_weight_received: sum,
    }));
  }

  function calcLoadShortage(loadedValue, unLoadedValue) {
    let response = "";
    if (loadedValue && unLoadedValue) {
      let calcValue = loadedValue - unLoadedValue;
      response = toFloat(calcValue);
    }
    return response;
  }

  function calcPackageShortage(loadedValue, unLoadedValue) {
    let response = "";
    if (loadedValue && unLoadedValue) {
      let calcValue = loadedValue - unLoadedValue;
      response = toFloat(calcValue);
    }
    return response;
  }

  function sumTotalMaterialValueUnloading(load_receipts_list) {
    let sum = 0;
    for (var i in load_receipts_list) {
      for (var j in load_receipts_list[i].unloading_goods) {
        sum =
          parseFloat(sum) +
          parseFloat(
            load_receipts_list[i].unloading_goods[j]?.billable_value || 0
          );
        sum = sum.toFixed(2);
      }
    }
    setState((prevState) => ({
      ...prevState,
      total_material_value_unloading: sum,
    }));
  }

  function calcInTime(load_receipts_list) {
    let newInTime = new Date(
      Math.min.apply(
        null,
        load_receipts_list.map(function (e) {
          return new Date(e.in_time);
        })
      )
    );

    let obj = { ...state.loading_point };

    let htmlTime = htmlInputDateTime(newInTime);
    // obj["in_time"] = localTime(newInTime);
    // obj["in_time"] = htmlTime;

    setState((prevState) => ({
      ...prevState,
      loading_point: {
        ...prevState.loading_point,
        in_time: htmlTime,
      },
    }));
    calcNoOfDays(obj);
  }

  function calcOutTime(load_receipts_list) {
    let newOutTime = new Date(
      Math.max.apply(
        null,
        load_receipts_list.map(function (e) {
          return new Date(e.out_time);
        })
      )
    );

    let formattedTime = htmlInputDateTime(newOutTime);

    let obj = { ...state.loading_point };
    obj["out_time"] = formattedTime;
    setState((prevState) => ({
      ...prevState,
      loading_point: {
        ...prevState.loading_point,
        out_time: formattedTime,
      },
    }));
    setState((prevState) => ({ ...prevState, start_time: formattedTime }));
    calcNoOfDays(obj);
  }

  function calcNoOfDays(loading_point) {
    if (loading_point.in_time && loading_point.out_time) {
      let days = getDateDiff(loading_point.in_time, loading_point.out_time);
      loading_point["no_of_days"] = days;
      setState((prevState) => ({
        ...prevState,
        loading_point: {
          ...prevState.loading_point,
          no_of_days: days,
        },
      }));
    }
  }

  function calcNoOfDaysUnloading(unloading_point) {
    if (unloading_point.in_time && unloading_point.out_time) {
      let days = getDateDiff(unloading_point.in_time, unloading_point.out_time);
      unloading_point["no_of_days"] = days;

      setState((prevState) => ({
        ...prevState,
        unloading_point: {
          ...prevState.unloading_point,
          no_of_days: days,
        },
      }));
    }
  }

  ///////////////// Handling Input Changes //////////////////////

  async function mapData(data) {
    let prevState = { ...state };
    let newState = await matchData(prevState, data);

    // Setting Stage
    let cons_stage = getConsignmentStage(newState?.status);
    setConsStage(cons_stage);

    newState.consignment_date = htmlDate(newState.consignment_date);

    let loadReceipts = newState.load_receipts;
    // Error Model
    var errorList = [...validationError["load_receipts"]];
    let new_error_model = ValidationErrorModel();
    let lr_error_model = new_error_model["load_receipts"][0];

    for (var i in loadReceipts) {
      // Adding ValidationErrorModel
      for (var j in loadReceipts[i].goods) {
        lr_error_model.goods.push(lr_error_model.goods);
      }
      // Adding ValidationErrorModel

      loadReceipts[i].in_time = htmlInputDateTime(loadReceipts[i].in_time);
      loadReceipts[i].out_time = htmlInputDateTime(loadReceipts[i].out_time);

      // Prefilling Unloading Goods

      if (newState.status === "in_progress") {
        for (var j in loadReceipts[i].goods) {
          try {
            // Checking If Index Exists
            if (isDefined(loadReceipts[i].unloading_goods[j])) {
              // Checking if data exists
              if (
                isEmpty(
                  loadReceipts[i].unloading_goods[j]?.material_description
                )
              ) {
                loadReceipts[i].unloading_goods[j] = {
                  ...loadReceipts[i].goods[j],
                };
              }
            } else {
              loadReceipts[i].unloading_goods.push(loadReceipts[i].goods[j]);
            }
          } catch (e) {
            console.log("Cant copy unloading goods data.");
          }
        }
      }

      errorList.push(lr_error_model);
    }

    newState.load_receipts = loadReceipts;

    // Error Validation
    setValidationError((prevState) => ({
      ...prevState,
      load_receipts: errorList,
    }));

    newState.loading_point.in_time = htmlInputDateTime(
      newState?.loading_point?.in_time
    );
    newState.loading_point.out_time = htmlInputDateTime(
      newState?.loading_point?.out_time
    );

    newState.unloading_point.in_time = htmlInputDateTime(
      newState.unloading_point.in_time
    );
    newState.unloading_point.out_time = htmlInputDateTime(
      newState.unloading_point.out_time
    );

    // Cons Start Time / End Time

    // Delay Duration - Checking
    if (cons_stage > 1 && newState["expected_delivery_time"]) {
      newState["delay_duration"] = calcDelayDuration(newState);
    }

    newState.start_time = htmlInputDateTime(newState?.start_time);
    newState.end_time = htmlInputDateTime(newState?.end_time);
    newState.expected_delivery_time = htmlInputDateTime(
      newState?.expected_delivery_time
    );

    // await setReportData(newState);

    // Obj to String

    // newState.tds = data.vehicle?.tds;
    // newState.tds_percentage = data.vehicle?.tds_percentage;
    // Calculating TDS Amount
    newState["tds_amount"] = 0;
    if (newState?.tds) {
      newState["tds_amount"] = calcTdsAmount(newState);
    }
    // Calculating Total Payable
    newState["charges"]["consignment_total_payable"] = await calcTotalPayable(
      newState
    );
    // Calculating Supplier Balance & Client Balance
    newState["supplier_balance"] =await calcSupplierBalance(newState);
    newState["client_balance"] = await calcClientBalance(newState);

    // Max Vehicle Capacity
    newState["max_vehicle_capacity"] =
      newState?.vehicle?.vehicle_type?.max_vehicle_capacity || "";

    // Calculating Threshold
    if (cons_stage > 1 && cons_stage < 4) {
      if (
        newState?.load_shortage_weight > 0 &&
        !newState?.load_shortage_amount
      ) {
        let deductions = await calcLoadShortageThreshold(newState);
        if (deductions) {
          newState["load_shortage_amount"] = deductions["amount"];
          alertBox.confirmation({
            text: "Load shortage is exceeding threshold value. Do you want to calculate the deduction value?",
            onConfirm: function () {
              onConfirmShortageDeduction(newState, deductions);
            },
          });
        }
      }
      // sumTotalPackagesReceived(loadReceipts);
      // sumTotalInvoiceWeightReceived(loadReceipts);
    }

    // Fleet Details
  
      if (cons_stage > 1 && cons_stage < 4) {
        // Fleet Data
        newState["trip_duration"] = calculateTripDuration(
          newState?.start_time,
          newState?.end_time
        );
      }
    

    await setState(newState);

    handleUserByStatus(newState.status);
  }

  function calcDelayDuration(data) {
    let delayDuration = "";
    if (data["expected_delivery_time"]) {
      let expectedTime = new Date(data["expected_delivery_time"]);
      let actualTime = new Date();
      if (data["end_time"]) {
        actualTime = new Date(data["end_time"]);
      }
   
      if (actualTime > expectedTime) {
        delayDuration = calculateTripDuration(expectedTime, actualTime);
      }
      
    }
    return delayDuration;
  }

  async function onConfirmShortageDeduction(prevState, deductions) {
    if (prevState["charges"] && prevState["charges"]["consignment_deduction"]) {
      setIsProcessing(true);
      prevState["charges"]["consignment_deduction"].push(deductions);

      // Re-calculating freight amount
      let totalAmount = toFloat(
        prevState["charges"]["consignment_total_amount"]
      );
      // Subtracting deductions items - Deductions / Other
      for (var i in prevState?.charges?.consignment_deduction) {
        totalAmount =
          totalAmount -
          toFloat(prevState?.charges?.consignment_deduction[i]?.amount);
      }
      prevState["charges"]["consignment_total_amount"] = totalAmount;

      prevState["charges"]["consignment_total_payable"] =
        await calcTotalPayable(prevState);

      setState((prevState) => ({
        ...prevState,
        charges: prevState["charges"],
      }));

      setIsProcessing(false);
    }
  }

  function getStateData() {
    return { ...state };
  }

  async function prepareData() {
    var query = getStateData();
    query.client = query?.client;
    query.origin = query?.origin;
    query.destination = query?.destination;

    // Driver Optional
    if (isDefined(query.driver)) {
      query.driver = query?.driver;
    } else {
      delete query.driver;
    }

    // Setting consingment type

    if (query.load_receipts.length > 1) {
      query.consignment_type = "multiple";
    } else {
      query.consignment_type = "single";
    }

    // Checking vehicle type
    if (query.vehicle?.ownership_type === "supplier") {
      query.supplier = query?.supplier;
    } else {
      delete query.supplier;
    }
    query.vehicle = query?.vehicle;
    // Converting Time to UTC
    let loadReceipts = query.load_receipts;
    for (var i in loadReceipts) {
      delete loadReceipts[i]?._id;
      loadReceipts[i].in_time = mongoUTC(loadReceipts[i].in_time);
      loadReceipts[i].out_time = mongoUTC(loadReceipts[i].out_time);
    }

    for (var i in query?.transits) {
      delete query?.transits[i]?._id;
    }

    query.load_receipts = loadReceipts;

    query.loading_point.in_time = mongoUTC(query.loading_point.in_time);
    query.loading_point.out_time = mongoUTC(query.loading_point.out_time);

    query.unloading_point.in_time = mongoUTC(query.unloading_point.in_time);
    query.unloading_point.out_time = mongoUTC(query.unloading_point.out_time);

    query.start_time = mongoUTC(query.start_time);
    query.end_time = mongoUTC(query.end_time);
    query["expected_delivery_time"] = mongoUTC(query?.expected_delivery_time);

    // Additions
    for (var i in query?.charges?.consignment_addition) {
      query.charges.consignment_addition[i]["date"] = mongoUTC(
        query.charges.consignment_addition[i]["date"]
      );
      query.charges.consignment_addition[i]["type"] =
        query.charges.consignment_addition[i]?.type?._id;
    }

    for (var i in query?.charges?.client_addition) {
      query.charges.client_addition[i]["date"] = mongoUTC(
        query.charges.client_addition[i]["date"]
      );
      query.charges.client_addition[i]["type"] =
        query.charges.client_addition[i]?.type?._id;
    }

    // Deductions
    for (var i in query?.charges?.consignment_deduction) {
      query.charges.consignment_deduction[i]["date"] = mongoUTC(
        query.charges.consignment_deduction[i]["date"]
      );
      query.charges.consignment_deduction[i]["type"] =
        query.charges.consignment_deduction[i]?.type?._id;
    }

    for (var i in query?.charges?.client_deduction) {
      query.charges.client_deduction[i]["date"] = mongoUTC(
        query.charges.client_deduction[i]["date"]
      );
      query.charges.client_deduction[i]["type"] =
        query.charges.client_deduction[i]?.type?._id;
    }

    //TDS
    if (query?.tds && query?.tds_percentage > 0) {
      query.tds = true;
    } else {
      query.tds = false;
      query.tds_percentage = 0;
    }

    // Receivables And Payables
    query.transactions["payables"] = await preparePayablesData(
      query.transactions["payables"]
    );
    query.transactions["receivables"] = await prepareReceivablesData(
      query.transactions["receivables"]
    );

    // calculating load shortage
    if (consStage > 1) {
      query["load_shortage_weight"] = calcLoadShortage(
        query?.total_invoice_weight,
        query?.total_invoice_weight_received
      );

      query["load_shortage_package"] = calcPackageShortage(
        query?.total_no_Of_packages,
        query?.total_no_Of_packages_received
      );
    }
    return query;
  }

  function reloadPage() {
    // window.location.reload();
    loadDataById(recordId);
  }
  async function onUpdate(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      setLoader((prevState) => ({ ...prevState, update: true }));
      var query = await prepareData({ ...state });

      api
        .putService(`consignment/${recordId}`, query)
        .then((response) => {
          console.log("response:", response);
          setLoader((prevState) => ({ ...prevState, update: false }));
          resp.SuccessResponse(response);
          reloadPage();
        })
        .catch((error) => {
          console.log(error);
          setLoader((prevState) => ({ ...prevState, update: false }));
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onAcknowledgeEdit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      setIsProcessing(true);
      var query = await prepareData({ ...state });
      console.log("query", query);
      api
        .putService(`consignment/${recordId}`, query)
        .then((response) => {
          setIsProcessing(false);
          resp.SuccessResponse(response);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onSubmitEnd(event) {
    setLoader((prevState) => ({ ...prevState, submit: true }));
    var query = await prepareData({ ...state });
    query.status = "in_progress";
    if (!isDefined(query.start_time)) {
      query.start_time = mongoUTC(new Date());
    }

    api
      .putService(`consignment/${recordId}`, query)
      .then((response) => {
        setLoader((prevState) => ({ ...prevState, submit: false }));
        resp.Success("Consignment Started.");
        // window.location.href = `/admin#/consignment/view/acknowledge/${recordId}`;
        reloadPage();
      })
      .catch((error) => {
        console.log(error);
        setLoader((prevState) => ({ ...prevState, submit: false }));
        resp.ErrorHandler(error);
      });
  }

  async function onSubmit(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      // Checking Business Rule
      if (localUser?.business_rules?.consignment_approval) {
        // Checking Approval Access
        if (localUser?.access?.approve_consignment) {
          if (mode?.create) {
            // onCreate(event, "in_progress");
          } else {
            onSubmitEnd(event);
          }
        } else {
          resp.Information("Consignment submission needs approval.");
        }
      } else {
        onSubmitEnd(event);
      }
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onComplete(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      setLoader((prevState) => ({ ...prevState, complete: true }));
      var query = await prepareData({ ...state });
      query["status"] = "completed";
      query["completed_at"] = await mongoUTC(new Date());

      // Checking end time
      if (!isDefined(query["end_time"])) {
        query["end_time"] = await mongoUTC(new Date());
      }

      api
        .putService(`consignment/${recordId}`, query)
        .then((response) => {
          setLoader((prevState) => ({ ...prevState, complete: false }));
          resp.Success("Consignment completed.");
          reloadPage();
        })
        .catch((error) => {
          console.log(error);
          setLoader((prevState) => ({ ...prevState, complete: false }));
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onAcknowledge(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      setLoader((prevState) => ({ ...prevState, acknowledge: true }));
      var query = await prepareData({ ...state });
      query["status"] = "acknowledged";
      query["action"] = "generate_invoice";
      query["acknowledged_at"] = await mongoUTC(new Date());
      // Checking end time
      if (!isDefined(query["end_time"])) {
        query["end_time"] = await mongoUTC(new Date());
      }

      api
        .putService(`consignment/${recordId}`, query)
        .then((response) => {
          setLoader((prevState) => ({ ...prevState, acknowledge: false }));
          resp.Success("Consignment Acknowledged.");
          reloadPage();
        })
        .catch((error) => {
          console.log(error);
          setLoader((prevState) => ({ ...prevState, acknowledge: false }));
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  async function onAcknowledgeAndComplete(event) {
    event.preventDefault();
    await finalValidation();
    if (validation.validateComplexForm(validationError)) {
      setLoader((prevState) => ({
        ...prevState,
        acknowledge_and_complete: true,
      }));
      var query = await prepareData({ ...state });
      query["status"] = "acknowledged";
      query["action"] = "generate_invoice";
      query["completed_at"] = await mongoUTC(new Date());
      query["acknowledged_at"] = await mongoUTC(new Date());
      // Checking end time
      if (!isDefined(query["end_time"])) {
        query["end_time"] = await mongoUTC(new Date());
      }

      api
        .putService(`consignment/${recordId}`, query)
        .then((response) => {
          setLoader((prevState) => ({
            ...prevState,
            acknowledge_and_complete: false,
          }));
          resp.Success("Consignment completed & Acknowledged.");
          reloadPage();
        })
        .catch((error) => {
          console.log(error);
          setLoader((prevState) => ({
            ...prevState,
            acknowledge_and_complete: false,
          }));
          resp.ErrorHandler(error);
        });
    } else {
      setIsValidForm((prevState) => ({ ...prevState, valid: false }));
    }
  }

  function validateLRNumber(lr_numbers) {
    const lr_error_msg = "LR Number already exists!";
    let newValidationError = { ...validationError };
    for (var i in lr_numbers) {
      let index = parseInt(lr_numbers[i]);
      newValidationError.load_receipts[index].lr_number = lr_error_msg;
    }
    setValidationError(newValidationError);
  }

  // FORM VALIDATION
  async function finalValidation() {
    let obj = { ...state };
    Object.keys(obj).forEach(function (key) {
      formValidation(`${key}`, obj[key]);
    });
  }

  // Input Changes
  function formValidation(name, value, array = "", index = 0, object = "") {
    let errors = validationError;
    var gstFormat = new RegExp(
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
    );

    switch (name) {
      case "in_time":
        errors[array][index][name] = validation.validateInTime(
          value,
          state[array][index]["out_time"]
        );
        errors[array][index]["out_time"] = validation.validateOutTime(
          state[array][index]["out_time"],
          value
        );
        break;
      case "out_time":
        errors[array][index][name] = validation.validateOutTime(
          value,
          state[array][index]["in_time"]
        );
        errors[array][index]["in_time"] = validation.validateInTime(
          state[array][index]["in_time"],
          value
        );
        break;

      // case "gst":
      //   errors[array][index][object][name] = gstFormat.test(value)
      //     ? ""
      //     : "GST Should be 15 digits.";
      //   break;
      default:
        break;
    }
  }

  // Report

  async function generateLRReport(event, lr_index) {
    // event.preventDefault();
    setIsProcessing(true);

    const data = cloneObject(state);
    await loadReceiptReport(data, lr_index, localUser?.customer_account);
    setIsProcessing(false);

    // openModal();
  }

  // Loading Point Details
  const LoadingPointHTML = (
    <>
      {/* <!-- BEGIN: Loading Point--> */}
      <Form.Row>
        <div className="grid grid-cols-12 gap-2 gap-y-3">
          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>In Time</Form.Label>
            <Form.Input
              className="form-control"
              name="in_time"
              value={state.loading_point.in_time}
              type="datetime-local"
              onChange={(event) => {
                changeInputObject(event, "loading_point");
              }}
              disabled={mode.view}
              max={htmlInputDateTime(new Date())}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Out Time</Form.Label>
            <Form.Input
              className="form-control"
              name="out_time"
              value={state.loading_point.out_time}
              type="datetime-local"
              onChange={(event) => {
                changeInputObject(event, "loading_point");
              }}
              disabled={mode.view}
              max={htmlInputDateTime(new Date())}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Consignment Start Time</Form.Label>
            <Form.Input
              className="form-control"
              name="start_time"
              value={state?.start_time}
              type="datetime-local"
              disabled={mode.view}
              onChange={changeInput}
              max={htmlInputDateTime(new Date())}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Total Weight</Form.Label>
            <WeightInput
              className="form-control"
              name="total_invoice_weight"
              value={state?.total_invoice_weight}
              type="number"
              disabled={mode.view}
              onChange={changeInput}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Total Packages</Form.Label>
            <Form.Input
              className="form-control"
              name="total_no_Of_packages"
              value={state?.total_no_Of_packages}
              type="number"
              disabled={mode.view}
              onChange={changeInput}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Total Material Value</Form.Label>
            <CurrencyInput
              className="form-control"
              name="total_material_value_loading"
              value={state?.total_material_value_loading}
              disabled={mode.view}
              onChange={changeInput}
            />
          </div>

          {state?.max_vehicle_capacity && (
            <div className="col-span-12">
              <LoadExceedAlert
                weight={state?.total_invoice_weight}
                max_weight={state?.max_vehicle_capacity}
              />
            </div>
          )}
        </div>
      </Form.Row>
      {/* <!-- END: Loading Point--> */}
    </>
  );

  // Unloading Point Details
  const UnloadingPointHTML = (
    <>
      {/* <!-- BEGIN: Unloading Point--> */}
      <Form.Row>
        <div className="grid grid-cols-12 gap-2 gap-y-3">
          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>In Time</Form.Label>
            <Form.Input
              className="form-control"
              name="in_time"
              value={state.unloading_point.in_time}
              type="datetime-local"
              onChange={(event) => {
                changeInputObject(event, "unloading_point");
              }}
              disabled={mode.view}
              max={htmlInputDateTime(new Date())}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Out Time</Form.Label>
            <Form.Input
              className="form-control"
              name="out_time"
              value={state.unloading_point.out_time}
              type="datetime-local"
              onChange={(event) => {
                changeInputObject(event, "unloading_point");
              }}
              disabled={mode.view}
              max={htmlInputDateTime(new Date())}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Consignment End Time</Form.Label>
            <Form.Input
              className="form-control"
              name="end_time"
              value={state?.end_time}
              type="datetime-local"
              disabled={mode.view}
              onChange={changeInput}
              max={htmlInputDateTime(new Date())}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Total Weight Received</Form.Label>
            <WeightInput
              className="form-control"
              name="total_invoice_weight_received"
              value={state?.total_invoice_weight_received}
              type="number"
              disabled={mode.view}
              onChange={changeInput}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Total Packages Received</Form.Label>
            <CurrencyInput
              className="form-control"
              name="total_no_Of_packages_received"
              value={state?.total_no_Of_packages_received}
              type="number"
              disabled={mode.view}
              onChange={changeInput}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Total Material Value</Form.Label>
            <CurrencyInput
              className="form-control"
              name="total_material_value_unloading"
              value={state?.total_material_value_unloading}
              type="number"
              disabled={mode.view}
              onChange={changeInput}
            />
          </div>

          <div className="col-span-12">
            <LoadShortageAlert
              load_shortage_weight={state?.load_shortage_weight}
              load_shortage_weight_unit={state?.load_shortage_weight_unit}
              load_shortage_amount={state?.load_shortage_amount}
            />
          </div>
        </div>
      </Form.Row>
      {/* <!-- END: Unloading Point--> */}
    </>
  );

  // VehicleFleetDetailsHTML
  const VehicleFleetDetailsHTML = (
    <>
      <div className="col-span-12 sm:col-span-6 lg:col-span-4">
        <Form.Label>Start odometer</Form.Label>
        <KmInput
          className="form-control"
          name="start_odometer_reading"
          value={state?.start_odometer_reading}
          onChange={changeInputOdometer}
          disabled={mode.view}
        />
      </div>
      {consStage > 1 && (
        <>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>End Odometer</Form.Label>
            <KmInput
              type="number"
              id="end_odometer_reading"
              name="end_odometer_reading"
              onChange={changeInputOdometer}
              value={state?.end_odometer_reading}
              disabled={mode.view}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4">
            <Form.Label>Trip Distance</Form.Label>
            <KmInput
              type="number"
              id="trip_distance"
              name="trip_distance"
              onChange={changeInputOdometer}
              value={state?.trip_distance}
              disabled={mode.view}
            />
          </div>
        </>
      )}
      {/* <!-- END: Vehicle Fleet Fields--> */}
    </>
  );

  const materialDetailsHTML = (
    <div className="grid grid-cols-12 gap-3 gap-y-3">
      <div className="col-span-12 sm:col-span-6 lg:col-span-4">
        <Form.Label>Primary LR No.</Form.Label>
        <Form.Input
          className="form-control"
          type="text"
          name="primary_lr_number"
          id="primary_lr_number"
          onChange={changeInput}
          value={state.primary_lr_number}
          disabled={mode.view}
        />
      </div>

      <div className="col-span-12 sm:col-span-6 lg:col-span-4">
        <Form.Label>Material Description</Form.Label>
        <Typeahead
          allowNew
          newSelectionPrefix="Add new: "
          clearButton
          id="material"
          name="material"
          onFocus={(event) => {
            loadDefaultMetrics(event, "material");
          }}
          onChange={(selected) => {
            changeTypeHeadInput(selected, "material");
          }}
          options={defaultState?.material}
          selected={formatTypeaheadCommon(state?.material || "")}
          placeholder=""
          disabled={mode.view}
        />
      </div>

      <div className="col-span-12 sm:col-span-6 lg:col-span-4">
        <Form.Label>Expected Delivery Time</Form.Label>
        <Form.Input
          className="form-control"
          name="expected_delivery_time"
          value={state?.expected_delivery_time}
          type="datetime-local"
          disabled={mode.view}
          onChange={changeInput}
        />
      </div>

      {state?.vehicle?.ownership_type === "own" && VehicleFleetDetailsHTML}

      <div className="col-span-12 md:col-span-6">
        <Form.Label>Remarks</Form.Label>
        <textarea
          className="form-control"
          type="text"
          name="remarks"
          id="remarks"
          onChange={changeInput}
          value={state.remarks}
          disabled={mode.view}
        />
      </div>
    </div>
  );

  const formButtons = (
    <div id="form-buttons-footer">
      {(localUser?.access?.create_consignment ||
        localUser?.access?.acknowledge_consignment ||
        localUser?.access?.approve_consignment ||
        localUser?.role?.is_owner) && (
        <label
          type="button"
          htmlFor="update_consignment"
          className="btn btn-primary w-24"
        >
          Update
        </label>
      )}

      {!mode?.view &&
        state?.status === "pending" &&
        (localUser?.access?.create_consignment ||
          localUser?.role?.is_owner) && (
          <label
            type="button"
            className="btn btn-success ml-2"
            htmlFor="submit_consignment"
            name="submit_consignment"
            disabled={loader?.submit}
          >
            Start Trip
          </label>
        )}

      {!mode?.view &&
        state?.status === "in_progress" &&
        (localUser?.access?.create_consignment ||
          localUser?.role?.is_owner) && (
          <label
            type="button"
            className="btn btn-success ml-2"
            htmlFor="complete_consignment"
            name="complete_consignment"
            disabled={loader?.complete}
          >
            Complete
          </label>
        )}

      {!mode?.view &&
        state?.status === "completed" &&
        (localUser?.access?.acknowledge_consignment ||
          localUser?.role?.is_owner) && (
          <label
            type="button"
            className="btn btn-success ml-2"
            htmlFor="acknowledge_consignment"
            name="acknowledge_consignment"
            disabled={loader?.acknowledge}
          >
            Acknowledge
          </label>
        )}

      {!mode?.view &&
        state?.status === "in_progress" &&
        (localUser?.access?.acknowledge_consignment ||
          localUser?.role?.is_owner) && (
          <label
            type="button"
            className="btn btn-success ml-2 "
            htmlFor="acknowledge_and_complete_consignment"
            name="acknowledge_and_complete_consignment"
            disabled={loader?.acknowledge_and_complete}
          >
            Acknowledge {"&"} Complete{" "}
          </label>
        )}

      {/* {!mode?.view &&
        (mode.acknowledge_edit || state?.status === "acknowledged") &&
        (localUser?.role?.is_owner || localUser?.role?.is_admin) && (
          <label
            type="button"
            className="btn btn-primary ml-2 "
            htmlFor="acknowledge_save_consignment"
            name="acknowledge_save_consignment"
          >
            Save
          </label>
        )} */}
    </div>
  );

  function saveForm(event) {
    event.preventDefault();
    let submit_action = event.nativeEvent.submitter.name;
    switch (submit_action) {
      case "update_consignment":
        onUpdate(event);
        break;

      case "submit_consignment":
        onSubmit(event);
        break;

      case "complete_consignment":
        onComplete(event);
        break;

      case "acknowledge_consignment":
        onAcknowledge(event);
        break;

      case "acknowledge_and_complete_consignment":
        onAcknowledgeAndComplete(event);
        break;

      case "acknowledge_save_consignment":
        onAcknowledgeEdit(event);
        break;

      default:
        break;
    }
  }

  //<!--BEGIN: Typeahead Functions -->

  async function loadDefaultMetrics(event, key, reload = false, data = {}) {
    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (reload) {
      let prevDefState = [...defaultState[key]];
      prevDefState.push(data);
      setDefaultState((prevState) => ({
        ...prevState,
        [key]: prevDefState,
      }));
    } else {
      if (defaultState[key].length === 0 || reload) {
        switch (key) {
          case "material":
            url = "load_material/master";
            await fetchDefaultMetrics(url, key);
            break;

          default:
            break;
        }
      }
    }
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultState(data, key);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function mapDefaultState(data, key) {
    let result = [];
    if (key === "material") {
      for (var i in data) {
        result.push(data[i]["name"]);
      }
    } else {
      result = data;
    }
    return result;
  }

  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  //<!--END: Typeahead Functions -->

  // <!-- BEGIN : Checking Load shortage Threshold -->
  async function calcLoadShortageThreshold(data) {
    let response = null;
    const consData = data;
    let query = {
      client: data?.client?._id,
      material: data?.material || "",
    };
    await api.postService(`load_material/get/threshold/material`, query).then(
      async (result) => {
        const thresholdData = result?.data?.data;
        if (thresholdData) {
          response = await processThresholdData(consData, thresholdData);
        }
      },
      (error) => {
        console.log("error", error);
        // resp.ErrorHandler(error);
      }
    );
    return response;
  }

  function processThresholdData(consData, thresholdData) {
    let result = null;
    const thresholdPercentage = thresholdData["threshold_percentage"] || 0;
    const loadedWeight = consData["total_invoice_weight"] || 0;
    const shortageWeight = consData["load_shortage_weight"] || 0;
    const materialValue = consData["total_material_value_loading"] || 0;

    const thresholdWeight = toFloat(thresholdPercentage * loadedWeight);

    if (thresholdWeight > 0 && shortageWeight > thresholdWeight) {
      const loadDiff = toFloat(shortageWeight - thresholdWeight);
      const materialValuePerUnit = toFloat(materialValue / loadedWeight);
      const materialLossAmount = toFloat(loadDiff * materialValuePerUnit);

      // Calculating Rate
      let charge_type_data = thresholdData["material_loss_charges_data"];
      // Setting Charges
      result = {
        charge_type: "deduction", // addition or deduction
        date: new Date(),
        type: charge_type_data, // charge type master
        amount: materialLossAmount,
        no_of_units: loadDiff,
        rate_per_unit: materialValuePerUnit,
        unit_measure: charge_type_data?.unit_measure || "kg",
        remarks: "Material Loss Calcualted from threshold value.",
      };
    }
    return result;
  }

  // <!-- END : Checking Load shortage Threshold -->

  function onChangeTab(value) {
    setCurrentTab(value);
  }

  const TrpiDetialsItemInline = (props) => {
    const { label, value, showIcon = true, className = "" } = props;

    return (
      <div
        className={
          "truncate sm:whitespace-normal flex items-center " + className
        }
      >
        {showIcon && (
          <i
            className="fa fa-circle w-2 h-2 mr-2 text-theme-1"
            aria-hidden="true"
          ></i>
        )}

        <span className="text-gray-700">{label}</span>
        <span className="ml-2 font-bold">{value}</span>
      </div>
    );
  };

  return (
    <Container>
      {/* EXTERNAL COMPONENTS */}

      <>
        {/* <!-- BEGIN: Transits Modal --> */}
        {transitsFormModal.show && (
          <TransitForm
            show={transitsFormModal.show}
            action={transitsFormModal.action}
            transits={transitsFormModal.transits}
            consignmentId={transitsFormModal.consignmentId}
            cons_status={transitsFormModal.cons_status}
            saveToDB={false}
            onHide={closeTransitsModal}
            callBack={transitsModalCallBack}
          />
        )}
        {/* <!-- END: Transits Modal--> */}

        {/* <!-- BEGIN: Timeline Modal --> */}
        {transitTimelineModal.show && (
          <TransitTimeline
            show={transitTimelineModal.show}
            vehicle={state?.vehicle}
            consStage={consStage}
            consData={{ ...state }}
            onHide={closeTransitTimelineModal}
          />
        )}
        {/* <!-- END: Timeline Modal--> */}

        {/* <!-- BEGIN: Consignment Form Modal --> */}
        {consFormModal.show && (
          <ConsignmentForm
            show={consFormModal.show}
            action={consFormModal.action}
            onHide={closeConsFormModal}
            callBack={consFormCallBack}
            id={consFormModal.id}
            business_type="transporter"
          />
        )}
        {/* <!-- END: Consignment Form Modal --> */}

        {LRFormModal.show && (
          <LRForm
            show={LRFormModal.show}
            action={LRFormModal.action}
            onHide={closeLRFormModal}
            callBack={LRFormCallBack}
            data={LRFormModal?.data}
            index={LRFormModal?.index}
            cons_status={state?.status}
            cons_stage={consStage}
          />
        )}

        {!mode.view && chargesFormModal.show && (
          <ChargesForm
            show={chargesFormModal.show}
            action={chargesFormModal.action}
            index={chargesFormModal?.index}
            charge_type={chargesFormModal?.charge_type}
            data={chargesFormModal?.data}
            consData={{ ...state }}
            onHide={closeChargesFormModal}
            callBack={chargesFormCallBack}
            module={chargesFormModal?.module}
          />
        )}

        {paymentFormModal.show && (
          <PaymentForm
            show={paymentFormModal.show}
            action={paymentFormModal.action}
            data={paymentFormModal?.data}
            index={paymentFormModal?.index}
            supplier_max_limit={state?.supplier_balance}
            client_max_limit={state?.client_balance}
            disableSupplierPayment={paymentFormModal?.disableSupplierPayment}
            disableClientPayment={true}
            onHide={closePaymentFormModal}
            callBack={paymentFormCallBack}
            saveToDB={false}
            consData={state}
            consId={recordId}
            organizationId={localUser?.organization_id}
          />
        )}

        {simTrackingMapModal?.show && (
          <SimTrackingMap
            show={simTrackingMapModal?.show}
            query={simTrackingMapModal?.query}
            onHide={closeSimTrackingModal}
          />
        )}
      </>
      {/* EXTERNAL COMPONENTS */}

      {/*  <!-- BEGIN: Form -->  */}

      <Container.Body>
        {isProcessing ||
        loader?.update ||
        loader?.acknowledge ||
        loader?.create ||
        loader?.submit ||
        loader?.complete ||
        loader?.acknowledge_and_complete ? (
          <ProcessLoader />
        ) : null}

        {/* <!--BEGIN: Floating Action Buttons --> */}
        {consStage < 4 && !mode?.view && (
          <div className="float-container">
            <a
              href={"tel:" + state?.driver?.contact_number}
              className="icon one"
            >
              <i className="fa fa-phone mr-5" aria-hidden="true"></i> Call
              Driver
            </a>
            {/* <a href="#" className="icon">
              <i className="fa fa-commenting mr-5" aria-hidden="true"></i> Message
              Driver
            </a> */}
            {!mode?.create &&
              consStage < 3 &&
              state?.driver?.sim_tracking_status === "ALLOWED" && (
                <a
                  type="button"
                  href={void 0}
                  className="cursor-pointer icon"
                  onClick={openSimTrackingModal}
                >
                  <i className="fa fa-map-marker mr-5" aria-hidden="true"></i>
                  SIM Tracking
                </a>
              )}
          </div>
        )}
        {/* <!--END: Floating Action Buttons --> */}

        <form onSubmit={saveForm} id="consignment-transporter-form">
          {/* <!-- BEGIN: Header --> */}
          <div className="box mt-3">
            <div className="flex flex-col sm:flex-row items-center p-2">
              <div className="flex-none mr-auto">
                <div className="flex items-center">
                  <div>
                    <BackButton />
                  </div>
                  <div>
                    <h2 className="font-medium text-base">
                      Consignment Details
                      <div id="cons_action_buttons" className="inline ml-2">
                        {consStage < 3 && !mode?.view && (
                          <button
                            className="btn btn-sm btn-outline-primary inline-block mr-2"
                            onClick={editConsignment}
                          >
                            <i
                              className="fa fa-pencil mr-1"
                              aria-hidden="true"
                            ></i>{" "}
                            Edit
                          </button>
                        )}
                      </div>
                    </h2>
                    <div className="flex items-center">
                      <ConsNumberDisplay data={state?.consignment_id} />
                      <InfoButton className="ml-2" id="consignment_id">
                        <ConsNumberInfo data={state?.consignment_id} />
                      </InfoButton>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-auto flex items-center">
                {/* <!--BEGIN : Action Buttons --> */}
                <>
                  {consStage < 4 && !mode?.view && (
                    <div
                      id="expense_button"
                      className="cursor-pointer btn btn-secondary text-center flex-col mr-2 px-1 w-28 h-16"
                      onClick={(e) =>
                        openChargesFormModal("consignment", "addition")
                      }
                    >
                      <i
                        className="fa fa-plus fa-2x text-green-500 mb-1"
                        aria-hidden="true"
                      ></i>
                      <span className="block font-medium text-gray-700">
                        Add Charges
                      </span>
                    </div>
                  )}

                  {consStage < 4 && !mode?.view && (
                    // Original w-28 h-16
                    <div
                      id="expense_button"
                      className="cursor-pointer btn btn-secondary text-center flex-col mr-2 px-1 w-28 h-16"
                      onClick={(e) =>
                        openChargesFormModal("consignment", "deduction")
                      }
                    >
                      <i
                        className="fa fa-minus-circle fa-2x text-red-500 mb-1"
                        aria-hidden="true"
                      ></i>
                      <span className="block font-medium text-gray-700">
                        Add Deduction
                      </span>
                    </div>
                  )}

                  {localUser?.module_access?.accounts &&
                    state?.payables_status !== "paid" &&
                    state?.vehicle_ownership_type === "supplier" &&
                    (state?.supplier_balance > 0 ||
                      state?.client_balance > 0) && (
                      <div
                        id="payment_button"
                        className="cursor-pointer btn btn-secondary text-center flex-col mr-2 px-1 w-28 h-16"
                        onClick={addPayment}
                      >
                        <i
                          className="fa fa-google-wallet fa-2x text-blue-500 mb-1"
                          aria-hidden="true"
                        ></i>
                        <span className="block font-medium text-gray-700">
                          Add Payment
                        </span>
                      </div>
                    )}

                  {!mode?.create && !mode?.view && consStage < 3 && (
                    <div
                      id="lr_button"
                      className="cursor-pointer btn btn-secondary text-center flex-col mr-2 px-1 w-28 h-16"
                      onClick={openLRFormModal}
                    >
                      <i
                        className="fa fa-file-text fa-2x text-yellow-500 mb-1"
                        aria-hidden="true"
                      ></i>
                      <span className="block font-medium text-gray-700">
                        Add Load Receipt
                      </span>
                    </div>
                  )}
                  {!mode?.create &&
                    !mode?.view &&
                    consStage > 1 &&
                    consStage < 4 && (
                      <div
                        id="transits_button"
                        className="cursor-pointer btn btn-secondary text-center flex-col mr-2 px-1 w-28 h-16"
                        onClick={openTransitsModal}
                      >
                        <AddLocationIcon
                          width="30"
                          height="30"
                          className="text-theme-6 inline mb-1"
                          fill="rgb(239, 68, 68)"
                        />
                        <span className="block font-medium text-gray-700">
                          Add Transit Points
                        </span>
                      </div>
                    )}

                  {/* Transit Timeline Button */}
                  {consStage > 1 && consStage < 4 && (
                    <div
                      id="transit_timeline_button"
                      className="cursor-pointer btn btn-secondary text-center flex-col mr-2 px-1 w-28 h-16"
                      onClick={openTransitTimelineModal}
                    >
                      <i
                        className="fa fa-eye fa-2x text-gray-600 mb-1"
                        aria-hidden="true"
                      ></i>
                      <span className="block font-medium text-gray-700">
                        View Running Status
                      </span>
                    </div>
                  )}
                  {/* Transit Timeline Button */}

                  {/* {!mode?.create &&
                    !mode?.view &&
                    consStage < 3 &&
                    state?.driver?.sim_tracking_status === "ALLOWED" && (
                      <div
                        id="sim_tracking_button"
                        className="cursor-pointer btn btn-secondary text-center flex-col mr-2 px-1 w-28 h-16"
                        onClick={openSimTrackingModal}
                      >
                        <GPSIcon
                          width="30"
                          height="30"
                          className="text-theme-6 inline mb-1"
                          fill="rgb(239, 68, 68)"
                        />

                        <span className="block font-medium text-gray-700">
                          SIM Tracking
                        </span>
                      </div>
                    )} */}
                </>
                {/* <!--END : Action Buttons --> */}
              </div>

              <div className="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
                {!mode?.view && !error && formButtons}
              </div>
            </div>
          </div>
          {/* <!-- END: Header --> */}

          {/* <!-- BEGIN: Body --> */}
          <div className="flex flex-col h-screen">
            <header className="py-2 bg-gray-300 text-center">
              <Timeline status={state?.status} />
            </header>

            {/* {state?.transits.length > 1 && (
              <header className="grid grid-flow-col bg-gray-100 text-center">
                <div className="col-span-12 py-3 items-center justify-center">
                  <TransitPointsTimeline
                    transits={state?.transits}
                    origin={state?.origin}
                    destination={state?.destination}
                    stage={consStage}
                  />
                </div>
              </header>
            )} */}
            <main className="flex-1 overflow-y-auto bg-white p-3">
              {/* <!-- BEGIN: Row --> */}

              <div className="grid grid-cols-12 gap-3">
                {/* <!-- BEGIN: COL-1 --> */}
                <div className="md:col-span-6 sm:col-span-12">
                  {/* <!-- BEGIN: Basic Details Card --> */}
                  <div className="box shadow mb-3">
                    {/* <!-- BEGIN: CARD Header --> */}
                    <div className="flex flex-col sm:flex-row justify-start items-center px-2 border-b">
                      {/* <!-- BEGIN: Tab Switcher --> */}
                      <SimpleTab>
                        <SimpleTab.Tab
                          id="basic_details"
                          active={currentTab === "basic_details" ? true : false}
                          onClick={(e) => onChangeTab("basic_details")}
                        >
                          Basic Info
                        </SimpleTab.Tab>
                        <SimpleTab.Tab
                          id="loading_point"
                          active={currentTab === "loading_point" ? true : false}
                          onClick={(e) => onChangeTab("loading_point")}
                        >
                          Loading Point
                        </SimpleTab.Tab>
                        {consStage > 1 && (
                          <SimpleTab.Tab
                            id="unloading_point"
                            active={
                              currentTab === "unloading_point" ? true : false
                            }
                            onClick={(e) => onChangeTab("unloading_point")}
                          >
                            Unloading Point
                          </SimpleTab.Tab>
                        )}
                      </SimpleTab>
                      {/* <!-- END: Tab Switcher --> */}
                    </div>
                    {/* <!-- END: CARD Header --> */}

                    {/* <!-- BEGIN: CARD Body --> */}
                    <div className="p-5" id="card-body">
                      {currentTab == "basic_details" && (
                        <>
                          <div id="material_details">{materialDetailsHTML}</div>
                          {/* <!-- BEGIN: Trip Info --> */}
                          {consStage > 1 && (
                            <div className="border-t grid grid-cols-12 gap-3 pt-2">
                              {consStage > 2 && (
                                <>
                                  <div className="md:col-span-4 sm:col-span-6 p-2">
                                    <TrpiDetialsItemInline
                                      id="trip_duration"
                                      label="Trip Duration"
                                      value={state?.trip_duration}
                                      showIcon={false}
                                    />
                                  </div>
                                </>
                              )}
                              {state?.delay_duration && (
                                <div className="md:col-span-4 sm:col-span-6 p-2">
                                  <TrpiDetialsItemInline
                                    id="delay_duration"
                                    label="Delay by"
                                    value={state?.delay_duration}
                                    showIcon={false}
                                  />
                                </div>
                              )}

                              {state?.load_shortage_weight && (
                                <div className="md:col-span-4 sm:col-span-6 p-2">
                                  <TrpiDetialsItemInline
                                    id="load_shortage_weight"
                                    label="Load Shortage"
                                    value={
                                      state?.load_shortage_weight +
                                      " " +
                                      state?.load_shortage_weight_unit
                                    }
                                    showIcon={false}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          {/* <!-- END: Trip Info --> */}
                        </>
                      )}
                      {currentTab == "loading_point" && (
                        <div id="loading_point_details">{LoadingPointHTML}</div>
                      )}
                      {currentTab == "unloading_point" && (
                        <div id="unloading_point_details">
                          {consStage > 1 && UnloadingPointHTML}
                        </div>
                      )}
                    </div>
                    {/* <!-- END: CARD Body --> */}
                  </div>
                  {/* <!-- END: Basic Details Card --> */}

                  {/* <!-- BEGIN: Load Receipts --> */}
                  {state?.load_receipts.length > 0 && (
                    <div className="box shadow">
                      {/* <!-- BEGIN: Load Receipts Card Header--> */}
                      <div className="p-3 border-b border-gray-200 dark:border-dark-5">
                        <h2 className="font-medium text-base">
                          Load Receipts - {state?.load_receipts?.length}
                        </h2>
                      </div>
                      {/* <!-- END: Load Receipts Card Header--> */}

                      {/* <!-- BEGIN: Load Receipts Card Body--> */}
                      <div
                        className="grid grid-cols-12 gap-3 p-3"
                        id="load_receipts"
                      >
                        {state?.load_receipts.map((item, index) => (
                          <div
                            className="col-span-6"
                            key={`lr_${index}`}
                            id={`lr_${index}`}
                          >
                            {/* <!-- BEGIN: Load Receipt Element--> */}

                            <div className="alert alert-outline-primary show flex items-center mb-2 p-2">
                              <a
                                className="font-bold text-base mr-auto text-theme-1 cursor-pointer"
                                onClick={(e) => viewLRItem(e, item, index)}
                                href={void 0}
                              >
                                <i
                                  className="fa fa-caret-right mr-2"
                                  aria-hidden="true"
                                ></i>{" "}
                                LR-{item?.lr_number}
                              </a>

                              {/* <div className="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"> */}
                              <div className="preview ml-auto">
                                {!mode?.view && (
                                  <>
                                    <button
                                      className="btn btn-outline-primary  inline-block mr-1 ml-0 sm:ml-2"
                                      onClick={(e) =>
                                        editLRItem(e, item, index)
                                      }
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </button>

                                    <button
                                      className="btn btn-outline-danger  inline-block mr-1 ml-0 sm:ml-2"
                                      onClick={(e) =>
                                        deleteLRItem(e, item, index)
                                      }
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </button>
                                  </>
                                )}

                                {!item?.isNew && (
                                  <button
                                    className="btn btn-secondary  inline-block ml-2"
                                    onClick={(e) => generateLRReport(e, index)}
                                  >
                                    <i
                                      className="fa fa-download mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    PDF
                                  </button>
                                )}
                              </div>
                            </div>
                            {/* <!-- END: Load Receipt Element--> */}
                          </div>
                        ))}
                      </div>
                      {/* <!-- END: Load Receipts Card Body--> */}

                      {/* <!-- BEGIN: Load Receipts Card Footer--> */}
                      {/* <div className="flex flex-col sm:flex-row items-center p-5 border-t border-gray-200 dark:border-dark-5">
                        <div className="dark:text-gray-300">
                          Total invoice weight :{" "}
                          <strong>{state?.total_invoice_weight} kg</strong>
                        </div>

                        <div className="sm:ml-5  mt-2 sm:mt-0 dark:text-gray-300">
                          Total no of packages:{" "}
                          <strong>{state?.total_no_Of_packages}</strong>
                        </div>
                      </div>

                      {consStage > 2 && (
                        <div className="flex flex-col sm:flex-row items-center p-5 border-t border-gray-200 dark:border-dark-5">
                          <div className="dark:text-gray-300">
                            Total weight Received:{" "}
                            <strong>
                              {state?.total_invoice_weight_received} kg
                            </strong>
                          </div>

                          <div className="sm:ml-5  mt-2 sm:mt-0 dark:text-gray-300">
                            Total no of packages Received:{" "}
                            <strong>
                              {state?.total_no_Of_packages_received}
                            </strong>
                          </div>
                        </div>
                      )} */}
                      {/* <!-- BEGIN: Load Receipts Card Footer--> */}
                    </div>
                  )}
                  {/* <!-- END: Load Receipts --> */}
                </div>
                {/* <!-- END: COL-1 --> */}

                {/* <!-- BEGIN: COL-2 --> */}
                <div className="md:col-span-6 sm:col-span-12 ">
                  {/* <!-- BEGIN: Supplier Summary Details Card --> */}
                  <div className="box shadow mb-3">
                    {/* <!-- BEGIN: CARD Header- Route --> */}
                    <div
                      className="flex flex-col sm:flex-row justify-center items-center p-2 
                  bg-gray-800 text-white"
                    >
                      <div
                        id="route_origin"
                        className="text-white w-1/2 text-right"
                      >
                        <h2 className="leading-none break-all">
                          {state?.origin?.name}
                        </h2>
                      </div>

                      <div id="route_icon" className="mx-3">
                        <i
                          className="fa fa-angle-double-right mx-2 fa-2x text-yellow-600 "
                          aria-hidden="true"
                        ></i>
                      </div>

                      <div
                        id="route_destination"
                        className="text-white w-1/2 text-left"
                      >
                        <h2 className="leading-none break-all">
                          {state?.destination?.name}
                        </h2>
                      </div>
                    </div>
                    {/* <!-- END: CARD Header - Route --> */}

                    {/* <!-- BEGIN: CARD Header - Client / Driver Details --> */}
                    <div className="flex flex-col sm:flex-row items-center p-2  bg-theme-16 justify-between">
                      <div
                        id="highlight_client"
                        className="flex justify-start items-center"
                      >
                        <ClientIcon width="24" height="24" className="inline" />
                        <div id="client_details" className="ml-1">
                          <h2 className="font-medium break-all text-white">
                            {" "}
                            {state?.client?.name}
                          </h2>
                          {state?.client?.contact_number && (
                            <div className="text-gray-300">
                              {state?.client?.contact_number}
                            </div>
                          )}
                        </div>
                      </div>

                      <div id="highlight_driver_truck" className="">
                        <div
                          id="highlight_truck"
                          className="flex items-center justify-start"
                        >
                          <TruckIcon
                            width="24"
                            height="24"
                            className="inline"
                          />
                          <TruckNumberDisplay
                            data={state?.vehicle}
                            className="ml-2"
                            displayBadge={true}
                          />
                        </div>
                      </div>
                      <div id="driver_details">
                        <div
                          className="flex items-center justify-start"
                          id="driver_supplier_details"
                        >
                          {/* <-- BEGIN : Driver Details --> */}
                          {state?.driver && (
                            <div
                              id="driver_detail"
                              className="truncate flex justify-start items-center text-theme-12"
                            >
                              <DriverIcon
                                width="24"
                                height="24"
                                className="inline"
                                fill="currentColor"
                              />
                              <div className="text-white ml-2">
                                <p>{standardCase(state?.driver?.name)}</p>
                                {state?.driver?.contact_number && (
                                  <p className="text-gray-400">
                                    {formatPhoneNumber(
                                      state?.driver?.contact_number
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                          {/* <-- END : Driver Details --> */}
                        </div>
                      </div>
                      {/* <-- BEGIN : Supplier Details --> */}
                      {state?.vehicle_ownership_type === "supplier" &&
                        state?.supplier && (
                          <div id="supplier_details">
                            <div
                              id="supplier_detail"
                              className="truncate text-theme-18 flex justify-start items-center"
                            >
                              <SupplierIcon
                                width="24"
                                height="24"
                                className="inline"
                                fill="currentColor"
                              />
                              <div className="ml-1 text-white">
                                <p>{standardCase(state?.supplier?.name)}</p>
                                {state?.supplier?.contact_number && (
                                  <p className="text-gray-400">
                                    {formatPhoneNumber(
                                      state?.supplier?.contact_number
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      {/* <-- END : Supplier Details --> */}
                    </div>
                    {/* <!-- END: CARD Header Client ? Driver Details --> */}

                    {/* <!-- BEGIN:Freight Details Card--> */}
                    {(localUser?.access?.create_consignment ||
                      localUser?.access?.approve_consignment ||
                      localUser?.access?.acknowledge_consignment ||
                      localUser?.role?.is_owner) && (
                      <>
                        {/* <!-- BEGIN: CARD Header --> */}
                        <div className="flex flex-col sm:flex-row items-center p-3 border-b border-gray-200 dark:border-dark-5">
                          <h2 className="font-medium text-base mr-auto text-theme-1">
                            Freight Summary
                          </h2>
                        </div>
                        {/* <!-- END: CARD Header --> */}

                        {/* <!-- BEGIN: Card Body --> */}
                        <div id="summary_details_card_body" className="p-5">
                          {/* <!-- BEGIN: Summary INFO --> */}
                          {/* <div className="col-span-12 my-2">
                        <h3 className="text-lg font-medium">
                          Supplier Freight Summary
                        </h3>
                      </div> */}

                          <div className="col-span-12">
                            <div className="flex justify-between m-2">
                              <div className="font-bold">Freight Amount</div>
                              <div className="font-bold">
                                {formatCurrency(
                                  state?.charges?.consignment_freight
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <!-- BEGIN : Hold Amount -- > */}
                          {consStage < 3 &&
                            state?.charges?.consignment_hold_amount > 0 && (
                              <div className="col-span-12">
                                <div className="flex justify-between m-2">
                                  <div className="flex">
                                    Hold Amount
                                    <InfoButton className="ml-2">
                                      Hold Amount will be released after
                                      completing the consignment.
                                    </InfoButton>
                                  </div>
                                  <div>
                                    -{" "}
                                    {formatCurrency(
                                      state?.charges?.consignment_hold_amount
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* <!-- END : Hold Amount -- > */}

                          {state?.charges?.consignment_tds &&
                            state?.charges?.consignment_tds_amount > 0 && (
                              <div className="col-span-12">
                                <div className="flex justify-between m-2">
                                  <div>
                                    TDS (
                                    {state?.charges?.consignment_tds_percentage}{" "}
                                    %)
                                  </div>
                                  <div>
                                    -{" "}
                                    {formatCurrency(
                                      state?.charges?.consignment_tds_amount
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* <!-- END: Summary INFO  --> */}

                          {/* <!-- BEGIN: Expense INFO --> */}
                          {state?.charges?.consignment_addition?.length > 0 && (
                            <>
                              <div className="col-span-12 my-2">
                                <h3 className="text-lg font-medium">
                                  Expenses
                                </h3>
                              </div>

                              <div className="col-span-12">
                                {state?.charges?.consignment_addition.map(
                                  (item, index) => (
                                    <div
                                      className="flex justify-between m-2"
                                      key={`consignment_addition_${index}`}
                                      id={`consignment_addition_${index}`}
                                    >
                                      <div>
                                        {item?.type?.name}
                                        {!isEmpty(item?.no_of_days) && (
                                          <span className="ml-1">
                                            - {item?.no_of_days} day(s)
                                          </span>
                                        )}
                                        {!mode?.view && (
                                          <>
                                            <button
                                              type="button"
                                              className="btn  btn-outline-primary inline-block mx-1"
                                              onClick={(e) =>
                                                editChargesItem(
                                                  e,
                                                  item,
                                                  index,
                                                  "addition",
                                                  "consignment"
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                              ></i>
                                            </button>

                                            <button
                                              type="button"
                                              className="btn  btn-outline-danger inline-block mx-1"
                                              onClick={(e) =>
                                                deleteChargesItem(
                                                  e,
                                                  item,
                                                  index,
                                                  "consignment_addition",
                                                  "consignment"
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </>
                                        )}
                                      </div>
                                      <div className="preview">
                                        {formatCurrency(item?.amount)}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          )}
                          {/* <!-- END: Expense INFO  --> */}

                          {/* <!-- BEGIN: Deduction INFO --> */}
                          {state?.charges?.consignment_deduction?.length >
                            0 && (
                            <>
                              <div className="col-span-12 my-2">
                                <h3 className="text-lg font-medium">
                                  Deductions
                                </h3>
                              </div>

                              <div className="col-span-12">
                                {state?.charges?.consignment_deduction?.map(
                                  (item, index) => (
                                    <div
                                      className="flex justify-between m-2"
                                      key={`consignment_deduction${index}`}
                                      id={`consignment_deduction${index}`}
                                    >
                                      <div>
                                        {item?.type?.name}&nbsp;
                                        {(item?.type?.ref === "commission" ||
                                          item?.type?.ref === "tds") && (
                                          <>({item?.percentage}%)</>
                                        )}
                                        {!isEmpty(item?.no_of_days) && (
                                          <span className="ml-1">
                                            - {item?.no_of_days} day(s)
                                          </span>
                                        )}
                                        {!mode?.view && (
                                          <>
                                            <button
                                              type="button"
                                              className="btn  btn-outline-primary inline-block mx-1"
                                              onClick={(e) =>
                                                editChargesItem(
                                                  e,
                                                  item,
                                                  index,
                                                  "deduction",
                                                  "consignment"
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                              ></i>
                                            </button>

                                            <button
                                              type="button"
                                              className="btn  btn-outline-danger inline-block mx-1"
                                              onClick={(e) =>
                                                deleteChargesItem(
                                                  e,
                                                  item,
                                                  index,
                                                  "consignment_deduction",
                                                  "consignment"
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </>
                                        )}
                                      </div>
                                      <div className="preview">
                                        - {formatCurrency(item?.amount)}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          )}
                          {/* <!-- END: Deduction INFO  --> */}

                          {/* <!-- BEGIN: Total Calculation INFO  --> */}
                          <div className=" border-t border-gray-200 dark:border-dark-5 ">
                            <div className="flex justify-between mt-2">
                              <div className="text-lg text-theme-1 font-medium">
                                Total
                              </div>
                              <div className="text-lg text-theme-1 font-medium">
                                {formatCurrency(
                                  state?.charges?.consignment_total_payable
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <!-- END: Total Calculation INFO  --> */}
                        </div>
                        {/* <!-- END: Card Body  --> */}
                      </>
                    )}
                    {/* <!-- END:Freight Details Card--> */}
                  </div>
                  {/* <!-- END: Supplier Summary Details Card --> */}

                  {/* <!-- BEGIN: Supplier Payment Breakage --> */}
                  {state?.vehicle_ownership_type === "supplier" &&
                    localUser?.module_access?.consignments &&
                    state?.transactions?.payables?.length > 0 && (
                      <div className="box shadow p-5 mb-3 bg-gray-200">
                        {/* <!-- BEGIN: Supplier Payment INFO --> */}
                        <div className="col-span-12 my-2">
                          <h3 className="text-lg font-medium">
                            Supplier Payment
                          </h3>
                        </div>

                        {/* <!-- BEGIN: Supplier Payment Freight --> */}
                        <div className="col-span-12">
                          <div className="flex justify-between m-2">
                            <div className="font-bold">
                              Total Freight Amount
                            </div>
                            <div className="preview font-bold">
                              {formatCurrency(
                                state?.charges?.consignment_total_payable
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <!-- END: Supplier Payment Freight --> */}

                        {/* <!-- BEGIN: Supplier Payment Items --> */}
                        <div className="col-span-12">
                          {state?.transactions?.payables?.map((item, index) =>
                            item?.isDeleted ? null : (
                              <div
                                className="flex justify-between m-2"
                                key={`payables_${index}`}
                                id={`payables_${index}`}
                              >
                                <div>Payment</div>
                                <div className="preview">
                                  {/* <!-- BEGIN: Action Buttons --> */}
                                  {!mode?.view &&
                                    localUser?.module_access?.accounts && (
                                      <>
                                        <button
                                          type="button"
                                          className="btn  btn-outline-danger inline-block mx-1"
                                          onClick={(e) =>
                                            deletePaymentItem(e, item, index)
                                          }
                                        >
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn  btn-outline-primary inline-block mx-1"
                                          onClick={(e) =>
                                            editPaymentItem(e, item, index)
                                          }
                                        >
                                          <i
                                            className="fa fa-pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </>
                                    )}
                                  {/* <!-- END: Action Buttons --> */}-{" "}
                                  {formatCurrency(item?.transaction_amount)}
                                </div>
                              </div>
                            )
                          )}
                        </div>

                        {/* <!-- END: Supplier Payment Items --> */}

                        {/* <!-- END: Supplier Payment INFO  --> */}

                        {/* <!-- BEGIN: Supplier Balance INFO  --> */}
                        <div className=" border-t border-gray-400 dark:border-dark-5 ">
                          <div className="flex justify-between mt-2">
                            <div className="text-lg text-theme-1 font-medium">
                              Supplier Balance
                            </div>
                            <div className="text-lg text-theme-1 font-medium">
                              {formatCurrency(state?.supplier_balance)}
                            </div>
                          </div>
                        </div>
                        {/* <!-- END: Total Calculation INFO  --> */}
                      </div>
                    )}
                  {/* <!-- END: Supplier Payment Breakage --> */}

                  {/* <!-- BEGIN: Client Payment Breakage --> */}
                  {(localUser?.module_access?.invoices ||
                    localUser?.module_access?.accounts) &&
                    state?.transactions?.receivables?.length > 0 && (
                      <div className="box shadow p-5 mb-3 bg-gray-200">
                        {/* <!-- BEGIN: Client Payment INFO --> */}
                        <div className="col-span-12 my-2">
                          <h3 className="text-lg font-medium">
                            Client Payment
                          </h3>
                        </div>

                        {/* <!-- BEGIN: Client Payment Freight --> */}
                        <div className="col-span-12">
                          <div className="flex justify-between m-2">
                            <div className="font-bold">
                              Total Freight Amount
                            </div>
                            <div className="preview font-bold">
                              {formatCurrency(
                                state?.charges?.client_total_amount
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <!-- END: Supplier Payment Freight --> */}

                        {/* <!-- BEGIN: Client Payment Items --> */}
                        <div className="col-span-12">
                          {state?.transactions?.receivables?.map(
                            (item, index) =>
                              item?.isDeleted ? null : (
                                <div
                                  className="flex justify-between m-2"
                                  key={`receivables_${index}`}
                                >
                                  <div>
                                    {item?.transaction_context === "advance"
                                      ? "Advance"
                                      : "Payment"}
                                  </div>
                                  <div className="preview">
                                    {/* <!-- BEGIN : Action Buttons --> */}
                                    {!mode?.view &&
                                      localUser?.module_access?.accounts && (
                                        <>
                                          <button
                                            type="button"
                                            className="btn  btn-outline-danger inline-block mx-1"
                                            onClick={(e) =>
                                              deletePaymentItem(e, item, index)
                                            }
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn  btn-outline-primary inline-block mx-1"
                                            onClick={(e) =>
                                              editPaymentItem(e, item, index)
                                            }
                                          >
                                            <i
                                              className="fa fa-pencil"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </>
                                      )}
                                    - {formatCurrency(item?.transaction_amount)}
                                  </div>
                                </div>
                              )
                          )}
                        </div>

                        {/* <!-- END: Client Payment Items --> */}

                        {/* <!-- END: Client Payment INFO  --> */}

                        {/* <!-- BEGIN: Client Balance INFO  --> */}
                        <div className=" border-t border-gray-400 dark:border-dark-5 ">
                          <div className="flex justify-between mt-2">
                            <div className="text-lg text-theme-1 font-medium">
                              Client Balance
                            </div>
                            <div className="text-lg text-theme-1 font-medium">
                              {formatCurrency(state?.client_balance)}
                            </div>
                          </div>
                        </div>
                        {/* <!-- END: Total Calculation INFO  --> */}
                      </div>
                    )}
                  {/* <!-- END: Client Payment Breakage --> */}
                </div>
                {/* <!-- END: COL-2 --> */}
              </div>

              {/* <!-- END: ROW--> */}
            </main>

            {/* <!-- BEGIN: Footer --> */}
            <footer className="bg-white flex flex-col sm:flex-row items-center p-5 border-t border-gray-200 dark:border-dark-5 justify-end">
              {!mode?.view && formButtons}
            </footer>
            {/* <!-- END: Footer --> */}
          </div>
          {/* <!-- END: Body --> */}
          <div id="submit_hidden_inputs">
            <input
              type="submit"
              id="save_consignment"
              name="save_consignment"
              className="hidden"
            />
            <input
              type="submit"
              id="update_consignment"
              name="update_consignment"
              className="hidden"
              disabled={loader?.update}
            />
            <input
              type="submit"
              id="submit_consignment"
              name="submit_consignment"
              className="hidden"
            />
            <input
              type="submit"
              id="complete_consignment"
              name="complete_consignment"
              className="hidden"
            />
            <input
              type="submit"
              id="acknowledge_consignment"
              name="acknowledge_consignment"
              className="hidden"
            />
            <input
              type="submit"
              id="acknowledge_and_complete_consignment"
              name="acknowledge_and_complete_consignment"
              className="hidden"
            />

            <input
              type="submit"
              id="acknowledge_save_consignment"
              name="acknowledge_save_consignment"
              className="hidden"
            />
          </div>
        </form>
      </Container.Body>
      {/*  <!-- END: Form -->  */}
    </Container>
  );
}
