import React, { useEffect, useState } from "react";
// Components
import { Modal } from "react-bootstrap";
// Loaders
import ProcessLoader from "../preloader/processLoader";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
// const mapStyles = require("./mapStyles.json");

// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { htmlDateTime } from "../../functions/common";

// Constants

const GOOGLEMAP_API_KEY = process.env.REACT_APP_GOOGLEMAP_KEY;
// const GOOGLEMAP_API_KEY = "AIzaSyBfcKNyGB9Z0OxQSrAnWcL_nDzR_ec8xN8";

var lastPosition = {};
const currentTime = new Date();

const ErrorHTML = () => {
  return <div className="font-medium">Oops! Something went wrong.</div>;
};

const NoDataHTML = () => {
  return <div className="font-medium">No Data Available.</div>;
};

const MarkerInfoHTML = (props) => {
  const { data } = props;

  return (
    <div className="">
      <p className="mb-2">
        Time :{" "}
        <span className="flex text-theme-1 font-bold">
          {htmlDateTime(data?.timestamp)}
        </span>
      </p>

      <p className="">
        Address : <span className="font-bold">{data?.address}</span>
      </p>
    </div>
  );
};

const Map = ({ query }) => {
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [lastPosition, setLastPosition] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState();
  const [activeMarker, setActiveMarker] = useState();
  const [gpsData, setGpsData] = useState([]);

  const [state, setState] = useState({
    lat: "",
    lng: "",
  });

  function handleActiveMarker(marker) {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  }
  async function loadData() {
    setIsProcessing(true);

    await api
      .postService(`sim_tracking/consignment`, query)
      .then(async (response) => {
        const data = response.data;
        let recData = await mapData(data.data);
        setGpsData(recData);
        setDefaultCenter({
          lat: parseFloat(recData?.latitude),
          lng: parseFloat(recData?.longitude),
        });

        setIsProcessing(false);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setError(true);
        setIsProcessing(false);
        setIsLoaded(true);
      });
  }

  function mapData(data) {
    return data;
  }

  useEffect(() => {
    loadData();
  }, []);

  const icon = {
    url: "/images/truck-top.png",
    scaledSize: new window.google.maps.Size(30, 30),
    anchor: { x: 10, y: 10 },
  };

  if (error) {
    return <ErrorHTML />;
  } else {
    return (
      <>
        {isProcessing && <ProcessLoader />}
        {noData ? (
          <NoDataHTML />
        ) : isLoaded ? (
          <>
            <GoogleMap
              defaultZoom={15}
              defaultCenter={defaultCenter}
              // defaultOptions={{
              //   // styles: mapStyles,
              //   fullscreenControl: false,
              //   mapTypeControl: false,
              //   streetViewControl: false,
              // }}
            >
              <>
                {/* <!--BEGIN : Marker --> */}
                <Marker
                  position={defaultCenter}
                  id="final_position"
                  onClick={(e) => handleActiveMarker("final_position")}
                >
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <MarkerInfoHTML data={gpsData} />
                  </InfoWindow>
                </Marker>
                {/* <!--END : Marker --> */}
              </>
              {/* )} */}
            </GoogleMap>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
};

const MapComponent = withScriptjs(withGoogleMap(Map));

export default function View(props) {
  return (
    <>
      <div
        key="sim_tracking_map_form_modal"
        id="sim_tracking_map_form_modal"
        onClick={(e) => e.stopPropagation()}
      >
        <Modal
          {...props}
          onHide={props.onHide}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-xxl"
          centered
        >
          <Modal.Header closeButton>
            <h2 className="font-medium text-base mr-auto">SIM Tracking</h2>
          </Modal.Header>

          <Modal.Body className="p-5">
            <div className="flex flex-grow h-full">
              <MapComponent
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAP_API_KEY}&v=3.exp&libraries=geometry,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div style={{ height: `75vh`, width: "100%" }} />
                }
                // containerElement={
                //   <div
                //     style={{
                //       position: "relative",
                //       margin: "auto",
                //       width: "100%",
                //       height: "100%",
                //     }}
                //   />
                // }
                mapElement={<div style={{ height: `100%` }} />}
                query={props.query}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-outline-secondary w-20 mr-1"
              onClick={props.onHide}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
