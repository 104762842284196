import React, { useEffect } from "react";
import PropTypes from "prop-types";

import PlacesAutocomplete from "react-places-autocomplete";

export default function Component(props) {
  const {
    className,
    type,
    id,
    name,
    onClick,
    onChange,
    onSelect,
    value,
    required,
    fetchDetails,
    ...rest
  } = props;

  useEffect(() => {}, []);

  return (
    <>
      <PlacesAutocomplete
        value={value}
        name={name}
        onChange={onChange}
        onSelect={onSelect} // Local Function - To Fetch Details
        className={className}
        required={required}
        id={id}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Search Address ...",
                className: "form-control location-search-input",
              })}
              required={required}
            />
            {(loading || suggestions.length > 0) && (
              <div className="box p-3 autocomplete-dropdown-container shadow">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <>
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        id={"google_map_suggestion_" + index}
                        key={"google_map_suggestion_" + index}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.any,
  fetchDetails: PropTypes.bool,
  required: PropTypes.bool,
};

Component.defaultProps = {
  className: "",
  type: "",
  id: "",
  name: "",
  value: "",
  fetchDetails: false,
  required: false,
  icon: "fa fa-plus",
  text: "Add New",
  onClick() {},
  onChange() {},
  onSelect() {},
};
