import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as AxleImage } from "../../assets/images/svg/axle.svg";

export default function Component(props) {
  const { className, size, ...rest } = props;
  // const styleCss = {
  //   marginRight: "-28px",
  //   marginLeft: "-28px",
  //   marginTop: "12px",
  // };

  const styleCss = {
    "marginRight": "30px",
    "marginLeft": "-34px",
    marginTop: "12px",
  };

  return (
    <>
      <div
        style={styleCss}
        className={`w-${size} bg-gray-700 dark:bg-gray-200 mt-5 h-1 `}
      ></div>
    </>
  );
}
Component.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

Component.defaultProps = {
  // Variables
  id: "vehicle-tyres-view",
  className: "w-full bg-gray-700 dark:bg-gray-200 mt-5 h-1",
  size: "48",
};
