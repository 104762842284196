import React, { useState, useEffect } from "react";
import { trimConsNum } from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";

export default function Template(props) {
  const Title = "Driver Performance";
  const tableRef = React.createRef();
  const { data, onRowClick } = props;
  const [state, setState] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    loadData(data);
  }, [data]);

  async function loadData(data) {
    setIsProcessing(true);
    let tableData = await mapTableData(data);
    setTableData(tableData);
    setIsProcessing(false);
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 90,
      },
      {
        title: "Cons. No.",
        field: "consignment_id",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 75,
        formatter: function (cell) {
          return trimConsNum(cell?.getValue());
        },
      },
      {
        title: "Driver",
        field: "driver",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vehicle",
        field: "vehicle",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 100,
      },
      {
        title: "Client",
        field: "client",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 150,
      },
      {
        title: "Origin",
        field: "origin",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Destination",
        field: "origin",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Freight Amount",
        field: "consignment_total_amount",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 90,
      },
      {
        title: "Transit Hours",
        field: "transit_hours",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 90,
      },
      {
        title: "Delay Hours",
        field: "delay_hours",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 90,
      },
      {
        title: "Mileage",
        field: "trip_mileage",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 90,
      },
      {
        title: "Load Shortage Weight",
        field: "load_shortage_weight",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 90,
      },
      {
        title: "Load Shortage Amount",
        field: "load_shortage_amount",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 90,
      },
    
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(row) {}

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Data Table --> */}
        <ReportsDataTable
          title={Title}
          header={tableData.header}
          content={tableData.content}
          downloadFileName={Title}
          tableRef={tableRef}
        />
        {/* <!-- END: Data Table --> */}
      </div>
    </>
  );
}
