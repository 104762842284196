import React, { useState, useEffect } from "react";
// HTML ELEMENTS
import Error from "../../../components/error/error";
import ProcessLoader from "../../../components/preloader/processLoader";
import CustomDataTable from "../../../components/tables/customDataTable";
import Container from "../../../components/container";
import Card from "../../../components/card/custom";
import AddNewButton from "../../../components/htmlElements/addNewButton";
import ActionButtons from "../../../components/pageElements/actionButtons";
// Components
import FormMaster from "../../../components/master/client_agreements/transaction";
// Functions
import { reactFormatter } from "react-tabulator";
import { resp } from "../../../functions/responseHandler";
import * as api from "../../../api_service/api";
import {
  formatCurrency,
  matchData,
  localDate,
} from "../../../functions/common";
// Toaster & Notification
import { alertBox } from "../../../components/notification/alert";

const Model = {
  name: "",
  client: {
    _id: "",
    name: "",
  },
  start_date: "",
  end_date: "",
  status: "",
  items: [],
};

export default function ClientAgreementView(props) {
  // Loaders
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(false);
  // States
  const [state, setState] = useState({ ...Model });
  const [tableData, setTableData] = useState([]);
  const [pageTitle, setPageTitle] = useState("Client Agreement");
  const [recordId, SetRecordId] = useState(props?.agreement_id || "");
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
    agreement_id: "",
  });
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));

  useEffect(() => {
    handleUserAction(props);
  }, []);

  async function handleUserAction(props) {
    let id = props.match.params?.id;
    loadData(id);
  }

  function loadData(id = recordId) {
    SetRecordId(id);
    setIsProcessing(true);
    api.getService(`client_agreements/master/${id}`).then(
      async (result) => {
        const data = result.data?.data;

        await mapData(data, id);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setError(error);
        setIsProcessing(false);
      }
    );
  }

  async function mapData(data, agreeementId) {
    let prevState = { ...state };
    let newState = await matchData(prevState, data);
    let tableData = await mapTableData(newState.items, agreeementId);
    setTableData(tableData);
    setPageTitle(newState?.name);
    setState(newState);
  }

  // Mapping Grid Data From API
  function mapTableData(data, agreeementId) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Origin",
        field: "origin.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Destination",
        field: "destination.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Vehicle Type",
        field: "vehicle_type.vehicle_type",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Effective From",
        field: "effect_from",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Cost",
        field: "cost",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Actions",
        field: "",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",

        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(
          <ActionButtonsLookup agreeementId={agreeementId} />
        ),
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    };
    return response;
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    const agreeementId = props?.agreeementId || "";
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e, row) => {
            editRecord(cellData?._id, agreeementId);
          },
        }}
        deleteButton={{
          show: cellData?.status == "active" ? true : false,
          onClick: (e) => {
            deleteRecord(cellData?._id, agreeementId);
          },
        }}
        // activateButton={{
        //   // show: false,
        //   show: cellData?.status == "inactive" ? true : false,
        //   onClick: (e) => {
        //     // activateRecord(cellData);
        //   },
        // }}
      />
    );
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData(id) {
    let newState = {
      show: false,
      title: "",
      action: "",
      id: "",
      agreement_id: "",
      agreement_data: {},
    };
    setModal(newState);
    loadData(recordId);
  }

  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add Client Agreement`,
      action: "create",
      id: "",
      agreement_id: recordId,
      agreement_data: { ...state },
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(id) {
    let newState = {
      show: true,
      title: `View Client Agreement`,
      action: "view",
      id: id,
      agreement_id: recordId,
      agreement_data: { ...state },
    };
    setModal(newState);
  }
  // Edit Record
  async function editRecord(id, agreeementId) {
    let newState = {
      show: true,
      title: `Edit Client Agreement`,
      action: "edit",
      id: id,
      agreement_id: agreeementId,
      agreement_data: { ...state },
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(id, agreeementId) {
    alertBox.confirmDeletion({
      text: "Are you sure want to delete this Item?",
      onConfirm: function () {
        onDelete(id, agreeementId);
      },
    });
  }
  ////// COMMON FUNCTIONS //////

  function onDelete(id, agreeementId) {
    setIsProcessing(true);
    api
      .deleteService(`client_agreements/transaction/${id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        loadData(agreeementId);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }
  if (error) return <Error />;
  else {
    return (
      <>
        <Container>
          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title>{pageTitle}</Container.Title>

            <Container.HeaderActions>
              <AddNewButton onClick={addRecord} text="Add Item" />
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}
          {/* <!-- BEGIN: Container Body --> */}
          <Container.Body>
            {isProcessing && <ProcessLoader />}
            {/* <!-- BEGIN: Modal --> */}
            {modal.show && (
              <FormMaster
                show={modal.show}
                action={modal.action}
                onHide={closeModal}
                callBack={reloadData}
                id={modal.id}
                agreement_id={modal?.agreement_id}
                agreement_data={modal?.agreement_data}
              />
            )}
            {/* <!-- END: Modal --> */}

            <Card>
              <Card.Header>
                <div className="w-1/2">
                  <div className="flex">
                    <div className="text-gray-600">Client:</div>
                    <div className="font-medium ml-2">
                      {state?.client?.name}
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="text-gray-600">Status:</div>
                    <div className="font-medium ml-2">{state?.status}</div>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="flex">
                    <div className="text-gray-600">Start Date:</div>
                    <div className="font-medium ml-2">
                      {localDate(state?.start_date)}
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="text-gray-600">End Date:</div>
                    <div className="font-medium ml-2">
                      {localDate(state?.end_date)}
                    </div>
                  </div>
                </div>
              </Card.Header>

              <Card.Body className="p-0">
                <CustomDataTable
                  header={tableData.header}
                  content={tableData.content}
                  // onRowClick={(e, row) => {
                  //   editRecord(row._row.data._id);
                  // }}
                  filter={true}
                  filterFields={tableData.filterFields}
                />
              </Card.Body>
            </Card>
          </Container.Body>
          {/* <!-- END: Container Body --> */}
        </Container>
      </>
    );
  }
}
