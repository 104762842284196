import React from "react";
import PropTypes from "prop-types";
export default function PercentageInput(props) {
  const {
    type,
    id,
    name,
    onChange,
    value,
    required,
    size,
    placeholder,
    disabled,
    ...rest
  } = props;
  return (
    <>
      <div className="input-group">
        <input
          type={type}
          className={
            size === "sm" ? "form-control form-control-sm" : "form-control"
          }
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          step="any"
        />
        <div id={id} className="input-group-text">
          %
        </div>
      </div>
    </>
  );
}

PercentageInput.propTypes = {
  placeholder: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
};

PercentageInput.defaultProps = {
  placeholder: "",
  size: "",
  type: "number",
  id: "",
  name: "",
  onChange() {},
  value: "",
  required: false,
};
