import React from "react";
import PropTypes from "prop-types";

import { formatCurrency } from "../../../functions/common";

//Icons
import { ReactComponent as Icon } from "../../../assets/images/icons/loader.svg";

export default function Component(props) {
  const { className, id, name, onClick, data, ...rest } = props;

  return (
    <div className={className} id={id}>
      <div class="flex flex-wrap gap-3 w-full">
        <div class="mr-auto">
          <div class="text-gray-600 flex items-center leading-3">
            Unpaid Amount
          </div>
          <div class="relative text-2xl font-medium leading-5 mt-3">
            {formatCurrency(data)}
          </div>
        </div>
        <a
          class="flex items-center justify-center text-theme-11 dark:bg-dark-1 ml-auto"
          href=""
        >
          {" "}
          <Icon
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus w-12 h-12"
          />
        </a>
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.number,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "w-full h-full flex items-center",
  id: "receivables",
  name: "",
  data: 0,
  onClick() {},
};
