import React, { useState, useEffect } from "react";
// HTML Components
import Container from "../../../components/container";
import ActionButtons from "../../../components/pageElements/actionButtons";
import { reactFormatter } from "react-tabulator";

import Error from "../../../components/error/error";
import CustomDataTable from "../../../components/tables/customDataTable";
import MasterForm from "../../../components/master/accounts/bank";
import ContraEntry from "../../../components/master/accounts/contra_entry";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";
// Loaders
import Preloader from "../../../components/preloader/preloader";
import ProcessLoader from "../../../components/preloader/processLoader";
// Functions
import { toFloat, formatCurrency } from "../../../functions/common";
import * as api from "../../../api_service/api";
const ModuleName = "Bank Master";
export default function Master() {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [enableAdd, setEnableAdd] = useState(false);
  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));

  // Contra Entry Modal
  const [contraEntryModal, setContraEntryModal] = useState({
    show: false,
    title: "",
    action: "",
    type: "",
    fromId: "",
    fromName: "",
  });
  const openContraEntryModal = () =>
    setContraEntryModal((prevState) => ({ ...prevState, show: true }));
  const closeContraEntryModal = () =>
    setContraEntryModal((prevState) => ({ ...prevState, show: false }));

  // Data

  const [tableData, setTableData] = useState([]);

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    setIsLoaded(true);
    loadData();
  }, []);
  // Fetching Grid Data From API
  async function loadData() {
    setIsProcessing(true);
    api.getService("master/bank").then(
      async (result) => {
        const data = result.data;
        console.log("result", result);
        let tableData = await mapTableData(data.data);
        setTableData(tableData);
        if (data.data.length === 0) {
          setEnableAdd(true);
        } else {
          setEnableAdd(false);
        }
        setIsProcessing(false);
      },

      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        setError(error);
      }
    );
  }
  // Mapping Grid Data From API
  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Name",
        field: "name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "A/C No.",
        field: "account_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Bank Name",
        field: "bank_name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Opening Balance",
        field: "opening_balance",
        headerSort: false,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatCurrency(toFloat(cell.getValue()));
        },
      },
      {
        title: "Current Balance",
        headerSort: false,
        vertAlign: "middle",
        formatter: function (cell) {
          const row = cell?._cell?.row?.data;
          return formatCurrency(
            toFloat(row?.opening_balance || 0) +
              toFloat(row?.current_total || 0)
          );
        },
      },

      {
        title: "Actions",
        field: "",
        headerSort: false,
        print: false,
        download: false,
        responsive: 1,
        formatter: reactFormatter(<ActionButtonsLookup />),
        cellClick: function (e) {
          e.stopPropagation();
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      title: "",
    };
    return response;
  }

  ////// COMMON FUNCTIONS //////

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e) => {
            editRecord(cellData);
          },
        }}
        depositCashButton={{
          show: true,
          onClick: (e) => {
            createContraEntry(cellData);
          },
        }}
      />
    );
  }

  // Reload Grid Data
  function reloadData() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData();
  }
  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add ${ModuleName}`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(data) {
    let newState = {
      show: true,
      title: `View ${ModuleName}`,
      action: "view",
      id: data?._id,
    };
    setModal(newState);
  }
  // Edit Record
  async function editRecord(data) {
    let newState = {
      show: true,
      title: `Edit ${ModuleName}`,
      action: "edit",
      id: data?._id,
    };
    setModal(newState);
  }
  // Delete Record
  async function deleteRecord(data) {
    alert.confirmDeletion({
      onConfirm: function () {
        onDelete(data);
      },
    });
  }
  ////// COMMON FUNCTIONS //////

  // Contra entry

  function reloadContraEntry() {
    let newState = {
      show: false,
      title: `Contra Entry - Load Cash`,
      action: "",
      type: "",
      fromId: "",
      fromName: "",
    };
    setContraEntryModal(newState);
    loadData();
  }

  async function createContraEntry(data) {
    let newState = {
      show: true,
      title: `Contra Entry - Deposit Cash`,
      action: "create",
      type: "deposit_cash",
      fromId: data?._id,
      fromName: data?.name,
    };
    setContraEntryModal(newState);
  }

  // Delete Record
  function onDelete(data) {
    let query = { status: "inactive" };
    setIsProcessing(true);
    api
      .putService(`master/bank/${data._id}`, query)
      .then((response) => {
        console.log("response:", response);
        resp.SuccessResponse(response);
        setIsProcessing(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <Container>
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title>{ModuleName}</Container.Title>
          <Container.HeaderActions>
            {" "}
            <a
              className="btn btn-primary shadow-md mr-2"
              href={void 0}
              onClick={addRecord}
            >
              Add New
            </a>
          </Container.HeaderActions>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/*  <!-- BEGIN: Data Table -->  */}
        <Container.Body>
          {isProcessing && <ProcessLoader />}
          <CustomDataTable
            header={tableData.header}
            content={tableData.content}
            onRowClick={(e, row) => {
              viewRecord(row);
            }}
            filter={true}
            filterFields={tableData.filterFields}
          />
        </Container.Body>
        {/*  <!-- END: Data Table -->  */}

        {modal.show && (
          <MasterForm
            show={modal.show}
            action={modal.action}
            title={modal.title}
            onHide={closeModal}
            callBack={reloadData}
            id={modal.id}
          />
        )}

        {/* CONTRA ENTRY */}
        {contraEntryModal.show && (
          <ContraEntry
            show={contraEntryModal.show}
            action={contraEntryModal.action}
            title={contraEntryModal.title}
            type={contraEntryModal.type}
            fromId={contraEntryModal.fromId}
            fromName={contraEntryModal.fromName}
            onHide={closeContraEntryModal}
            callBack={reloadContraEntry}
          />
        )}
        {/* CONTRA ENTRY */}
      </Container>
    );
  }
}
