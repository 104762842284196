import React, { useState, useEffect } from "react";
import Preloader from "../../components/preloader/preloader";
import ProcessLoader from "../../components/preloader/processLoader";
import Error from "../../components/error/error";
import { Dropdown } from "react-bootstrap";
// HTML ELEMENTS
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import Widget from "../../components/dashboard/widget";
import WidgetOptions from "../../components/dashboard/widgetOptions";
import StageTab from "../../components/tabs/stageTab";
// Widgets Contents
import WCOverdueInvoices from "../../components/dashboard/operational_widgets_content/overdue_invoices";
import WCClientAgreements from "../../components/dashboard/operational_widgets_content/client_agreements";
import WCDriverLicenses from "../../components/dashboard/operational_widgets_content/driver_licenses";
import WCConsignmentStatus from "../../components/dashboard/operational_widgets_content/consignment_status";
import WCInvoiceStatus from "../../components/dashboard/operational_widgets_content/invoice_status";
import WCTopClients from "../../components/dashboard/operational_widgets_content/top_clients";
import WCVehicleExpiry from "../../components/dashboard/operational_widgets_content/vehicle_expiry";
import WCInTransitConsignments from "../../components/dashboard/operational_widgets_content/in_transit_consignments";
import WCVehicleCurrentLocation from "../../components/dashboard/operational_widgets_content/vehicles_current_location";
import WCVehiclesCount from "../../components/dashboard/operational_widgets_content/vehicles_count";

// Functions
import { isDefined, matchData } from "../../functions/common";
import { storageServices } from "../../functions/storage";
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { Model } from "./model";

// Responsive Grid System
const initialLayouts = {
  lg: [
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "overdue_invoices",
    },
    {
      w: 2,
      h: 2,
      x: 6,
      y: 0,
      i: "driver_licenses",
    },
    {
      w: 6,
      h: 4,
      x: 0,
      y: 2,
      i: "vehicle_expiry",
    },
    {
      w: 2,
      h: 4,
      x: 6,
      y: 2,
      i: "consignment_status",
    },
    {
      w: 3,
      h: 4,
      x: 0,
      y: 6,
      i: "invoice_status",
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 0,
      i: "vehicles_count",
    },
    {
      w: 2,
      h: 2,
      x: 4,
      y: 0,
      i: "client_agreements",
    },
    {
      w: 5,
      h: 4,
      x: 3,
      y: 6,
      i: "top_clients",
    },
    {
      w: 4,
      h: 4,
      x: 8,
      y: 0,
      i: "in_transit_consignments",
    },
    {
      w: 4,
      h: 6,
      x: 8,
      y: 4,
      i: "vehicles_current_location",
    },
  ],
  xxs: [
    {
      w: 1,
      h: 2,
      x: 0,
      y: 0,
      i: "overdue_invoices",
    },
    {
      w: 1,
      h: 2,
      x: 0,
      y: 2,
      i: "driver_licenses",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 8,
      i: "vehicle_expiry",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 12,
      i: "consignment_status",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 16,
      i: "invoice_status",
    },
    {
      w: 1,
      h: 2,
      x: 1,
      y: 2,
      i: "vehicles_count",
    },
    {
      w: 1,
      h: 2,
      x: 1,
      y: 0,
      i: "client_agreements",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 4,
      i: "top_clients",
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 20,
      i: "in_transit_consignments",
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 25,
      i: "vehicles_current_location",
    },
  ],
  md: [
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "overdue_invoices",
    },
    {
      w: 2,
      h: 2,
      x: 4,
      y: 0,
      i: "client_agreements",
    },
    {
      w: 2,
      h: 2,
      x: 6,
      y: 0,
      i: "driver_licenses",
    },
    {
      w: 5,
      h: 4,
      x: 3,
      y: 6,
      i: "top_clients",
    },
    {
      w: 6,
      h: 4,
      x: 0,
      y: 2,
      i: "vehicle_expiry",
    },
    {
      w: 2,
      h: 4,
      x: 6,
      y: 2,
      i: "consignment_status",
    },
    {
      w: 3,
      h: 4,
      x: 0,
      y: 6,
      i: "invoice_status",
    },
    {
      w: 4,
      h: 4,
      x: 6,
      y: 10,
      i: "in_transit_consignments",
    },
    {
      w: 3,
      h: 6,
      x: 7,
      y: 14,
      i: "vehicles_current_location",
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 0,
      i: "vehicles_count",
    },
  ],
  sm: [
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "overdue_invoices",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 6,
      i: "driver_licenses",
    },
    {
      w: 4,
      h: 4,
      x: 2,
      y: 0,
      i: "vehicle_expiry",
    },
    {
      w: 2,
      h: 4,
      x: 4,
      y: 4,
      i: "consignment_status",
    },
    {
      w: 2,
      h: 4,
      x: 2,
      y: 4,
      i: "invoice_status",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 2,
      i: "vehicles_count",
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 4,
      i: "client_agreements",
    },
    {
      w: 3,
      h: 4,
      x: 0,
      y: 8,
      i: "top_clients",
    },
    {
      w: 3,
      h: 4,
      x: 3,
      y: 8,
      i: "in_transit_consignments",
    },
    {
      w: 6,
      h: 8,
      x: 0,
      y: 12,
      i: "vehicles_current_location",
    },
  ],
  xs: [
    {
      w: 1,
      h: 2,
      x: 0,
      y: 0,
      i: "overdue_invoices",
    },
    {
      w: 1,
      h: 2,
      x: 1,
      y: 0,
      i: "driver_licenses",
    },
    {
      w: 2,
      h: 3,
      x: 0,
      y: 2,
      i: "vehicle_expiry",
    },
    {
      w: 2,
      h: 3,
      x: 2,
      y: 2,
      i: "consignment_status",
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 5,
      i: "invoice_status",
    },
    {
      w: 1,
      h: 2,
      x: 2,
      y: 0,
      i: "vehicles_count",
    },
    {
      w: 1,
      h: 2,
      x: 3,
      y: 0,
      i: "client_agreements",
    },
    {
      w: 2,
      h: 4,
      x: 2,
      y: 5,
      i: "top_clients",
    },
    {
      w: 2,
      h: 7,
      x: 0,
      y: 9,
      i: "in_transit_consignments",
    },
    {
      w: 2,
      h: 7,
      x: 2,
      y: 9,
      i: "vehicles_current_location",
    },
  ],
};
const widgetComponents = {
  overdue_invoices: WCOverdueInvoices,
  client_agreements: WCClientAgreements,
  driver_licenses: WCDriverLicenses,
  top_clients: WCTopClients,
  vehicle_expiry: WCVehicleExpiry,
  consignment_status: WCConsignmentStatus,
  invoice_status: WCInvoiceStatus,
  in_transit_consignments: WCInTransitConsignments,
  vehicles_current_location: WCVehicleCurrentLocation,
  vehicles_count: WCVehiclesCount,
};

const originalWidgetsList = [
  {
    id: "overdue_invoices",
    widget_id: "overdue_invoices",
    widget_name: "Overdue Invoices",
  },
  {
    id: "client_agreements",
    widget_id: "client_agreements",
    widget_name: "Expiring Client Agreements",
    // component: WCClientAgreements,
    // params: {},
  },
  {
    id: "driver_licenses",
    widget_id: "driver_licenses",
    widget_name: "Driver Licenses",
  },
  {
    id: "top_clients",
    widget_id: "top_clients",
    widget_name: "Top Clients",
  },
  {
    id: "vehicle_expiry",
    widget_id: "vehicle_expiry",
    widget_name: "Vehicle Expiry",
  },
  {
    id: "consignment_status",
    widget_id: "consignment_status",
    widget_name: "Consignment Status",
  },
  {
    id: "invoice_status",
    widget_id: "invoice_status",
    widget_name: "Invoice Status",
  },
  {
    id: "in_transit_consignments",
    widget_id: "in_transit_consignments",
    widget_name: "In Transit Consignments",
  },
  {
    id: "vehicles_current_location",
    widget_id: "vehicles_current_location",
    widget_name: "Vehicles Current Location",
  },
  {
    id: "vehicles_count",
    widget_id: "vehicles_count",
    widget_name: "Vehicles Count",
  },
];

function Component({ size: { width } }) {
  const [state, setState] = useState();
  const [localUser, setLocalUser] = useState();
  const [enableEdit, setEnableEdit] = useState(false);
  const [layouts, setLayouts] = useState();
  const [widgetsList, SetWidgetsList] = useState(originalWidgetsList);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      await loadDashboardData(localUserData, localUserData?.organization_id);
    };
    loadData();
  }, []);

  async function loadLayout(localUserData) {
    // let savedLayout = await storageServices.getStoredData(
    //   "operational_dash_layout"
    // );
    // console.log("savedLayout:", savedLayout);
    // if (savedLayout) {
    //   setLayouts(savedLayout);
    // } else {
    //   setLayouts(initialLayouts);
    // }
    // setIsLoaded(true);
    // setIsProcessing(false);
    // return;

    setIsProcessing(true);
    await api
      .getService(
        `user_preferences/${localUserData?.userId}/operational_dashboard_layout`
      )
      .then(async (response) => {
        const data = response.data;

        if (data.data && typeof data?.data["value"] !== "undefined") {
          var prefData = data.data.value;
          // Setting Layouts
          if (isDefined(prefData["layouts"])) {
            setLayouts(prefData["layouts"]);
          } else {
            setLayouts(initialLayouts);
          }
          // Setting Widgets
          if (isDefined(prefData["widgets"])) {
            SetWidgetsList(prefData["widgets"]);
          } else {
            SetWidgetsList(originalWidgetsList);
          }
        } else {
          setLayouts(initialLayouts);
          SetWidgetsList(originalWidgetsList);
        }

        setIsLoaded(true);
        setIsProcessing(false);
        return;
      })
      .catch((error) => {
        setLayouts(initialLayouts);
        SetWidgetsList(originalWidgetsList);
        console.log(error);

        resp.Error("Error Loading Layout Preferences.");
        setIsLoaded(true);
        setIsProcessing(false);
        return;
      });
  }

  async function loadDashboardData(
    localUserData = localUser,
    organization_id,
    reload = false
  ) {
    setIsProcessing(true);
    await api
      .getService(`dashboard/operational/${organization_id}`)
      .then(async (response) => {
        const data = response.data;

        let newState = await mapApiData(data);

        // newState.invoices = await mapInvoiceData(data?.invoices);
        // newState.organization_id = organization_id;

        setState(newState);
        if (reload) {
          setIsLoaded(true);
          setIsProcessing(false);
        } else {
          await loadLayout(localUserData);
        }
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setError(true);
        setIsLoaded(true);
        setIsProcessing(false);
      });
  }

  async function reloadDashboardData(event) {
    const { name, value } = event.target;
    console.log("value:", value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    loadDashboardData(localUser, value, true);
  }

  async function mapApiData(data) {
    let newState = await matchData(Model(), data);
    // newState["overdue_invoices"]["count"] = data?.invoice_status?.overdue || 0;
    return newState;
  }

  // Responsive Grid System

  function onEnableLayoutEdit() {
    setEnableEdit(true);
  }

  const onLayoutChange = (_, allLayouts) => {
    // setEnableEdit(true);
    setLayouts(allLayouts);
  };
  const onLayoutSave = async (layoutData) => {
    // storageServices.storeData("operational_dash_layout", layouts);
    let query = {
      client: "web",
      type: "operational_dashboard_layout",
      user: localUser?.userId,
      value: { layouts: layouts, widgets: widgetsList },
    };
    setIsProcessing(true);
    await api
      .putService(`user_preferences`, query)
      .then(async (response) => {
        console.log("response:", response);
        resp.Success("Layout Saved");
        setIsProcessing(false);
        setEnableEdit(false);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      });
  };

  const onRemoveWidget = (widgetId) => {
    setEnableEdit(true);
    SetWidgetsList(widgetsList.filter((i) => i?.widget_id !== widgetId));
  };
  const onAddWidget = async (itemId) => {
    setEnableEdit(true);
    let widObj = await originalWidgetsList.find((x) => x.widget_id === itemId);
    console.log("widObj:", widObj);
    SetWidgetsList([...widgetsList, widObj]);
  };

  function navToPage(page) {
    window.location.href = page;
  }
  const TabSwitcher = () => {
    return (
      <StageTab>
        <StageTab.Tab
          id="operational-dashboard"
          active={true}
          onClick={(e) => navToPage("/admin#/dashboard/operational")}
        >
          Operational
        </StageTab.Tab>{" "}
        <StageTab.Tab
          id="accounts-dashboard"
          active={false}
          onClick={(e) => navToPage("/admin#/accounts/dashboard")}
        >
          Accounts
        </StageTab.Tab>
      </StageTab>
    );
  };

  const WidgetComponent = React.memo(({ widget_id, data }) => {
    let WidgetContent = widgetComponents[widget_id];

    return (
      <div
        key={widget_id}
        id={widget_id}
        className="box shadow widget"
        data-grid={{ w: 3, h: 2, x: 0, y: 99999 }}
      >
        <Widget
          key={widget_id}
          id={widget_id} //dont edit this
          enableEdit={enableEdit}
          onRemove={onRemoveWidget}
        >
          <WidgetContent
            id={"widget_content_" + widget_id}
            data={data}
            enableEdit={enableEdit}
          />
        </Widget>
      </div>
    );
  });

  const WidgetWrapper = React.memo(({ list, data }) => {
    return list.map((item, index) => (
      <WidgetComponent
        key={item?.widget_id}
        id={item?.widget_id}
        value={item?.widget_id}
        widget_id={item?.widget_id}
        data={data[item?.widget_id]}
      />
    ));
  });

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href={void 0}
      className="cursor-pointer"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    // return <Preloader />;
    return <Preloader />;
  } else {
    return (
      <>
        {isProcessing && <ProcessLoader />}
        {/* <!-- BEGIN: CONTENT -- > */}

        {/* <!-- BEGIN: TopBar --> */}

        <div className="flex items-center justify-end -mt-9">
          {/* <TabSwitcher /> */}
          {/* <!--BEGIN:Org Switch for Biz Owner -->*/}
          {/* {localUser?.role?.is_owner && (
              <div className="mr-2">
                <select
                  id="organization_id"
                  name="organization_id"
                  className="form-select"
                  aria-label=".form-select-sm"
                  onChange={reloadDashboardData}
                  value={state?.organization_id}
                >
                  {localUser?.branch_organizations?.map((item, i) => {
                    return (
                      <option key={i} value={item?._id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )} */}
          {/* <!--END:Org Switch for Biz Owner -->*/}

          {enableEdit && (
            <div id="save_edit_widgets_option" className="mr-2">
              <button
                className="btn btn-success-soft px-2 py-1"
                type="button"
                onClick={onLayoutSave}
              >
                <i className="fa fa-check mr-1" aria-hidden="true"></i>Save
              </button>
            </div>
          )}

          <WidgetOptions
            id="widget_options"
            className="relative z-20"
            onSave={onLayoutSave}
            enableEdit={enableEdit}
            onEnableEdit={onEnableLayoutEdit}
            onAddItem={onAddWidget}
            onRemoveItem={onRemoveWidget}
            originalItems={originalWidgetsList}
            currentItems={widgetsList}
          />
        </div>

        {/* <!-- END: TopBar --> */}
        <div>
          <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            onLayoutChange={onLayoutChange}
            // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            breakpoints={{ lg: 1280, md: 1024, sm: 768, xs: 640, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            rowHeight={60}
            width={width}
            isDraggable={enableEdit}
            isResizable={enableEdit}
            isDroppable={false}
            autoSize={true}
          >
            {/* {widgetsList.map((item, index) => {
              return (
                <>
                  <div
                    key={item?.widget_id}
                    id={item?.widget_id}
                    className="box shadow widget"
                    data-grid={{ w: 3, h: 2, x: 0, y: Infinity }}
                  >
                    <WidgetComponent
                      key={item?.widget_id}
                      widget_id={item?.widget_id}
                      data={state[item?.widget_id]}
                    />
                  </div>
                </>
              );
            })} */}
            {/* {WigetItems} */}
            {/* <WidgetWrapper list={widgetsList} data={state} /> */}
            {widgetsList.map((item, index) => {
              let WidgetContent = widgetComponents[item?.widget_id];

              return (
                <div
                  key={item?.widget_id}
                  id={item?.widget_id}
                  className="box shadow widget"
                  // data-grid={item}
                  data-grid={{ w: 3, h: 2, x: 0, y: 99999 }}
                >
                  <Widget
                    key={item?.widget_id}
                    id={item?.widget_id} //dont edit this
                    enableEdit={enableEdit}
                    onRemove={onRemoveWidget}
                  >
                    <WidgetContent
                      id={"widget_content_" + item?.widget_id}
                      data={state[item?.widget_id]}
                      enableEdit={enableEdit}
                    />
                  </Widget>
                </div>
              );
            })}
          </ResponsiveGridLayout>
          {/* <!-- END: CONTENT -- > */}
        </div>
      </>
    );
  }
}
// export default Component()
export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
  Component
);
