import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { matchData } from "../../functions/common";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";

import GoogleMapLocator from "./google-map-locator";

const Model = {
  state: "",
  name: "",
  place: "",
  status: "active",
  isNew: false,
  latitude: "",
  longitude: "",

  //
  label: "",
  region: "",
  name: "",
  county: "",
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [saveToDB, setSaveToDB] = useState(true);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  const [currentLocation, setCurrentLocation] = useState();
  const [defaultState, setDefaultState] = useState({
    state: [],
  });
  useEffect(() => {
    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    // Handling Call Back
    if (props?.saveToDB === false) {
      setSaveToDB(false);
    }

    setUserAction(props.action);
    await loadDefaultMetrics();
    switch (props.action) {
      case "create":
        setState({ ...Model });
        setViewMode(false);

        break;
      case "edit":
        setViewMode(false);
        loadDataById(props.id);
        break;
      case "view":
        setViewMode(true);
        loadDataById(props.id);
        break;
      default:
        setViewMode(true);
    }
  }

  // Loading Data
  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`master/city/${id}`).then(
      async (result) => {
        console.log("result:", result);
        const data = result.data;
        await mapData(data.data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  async function loadDefaultMetrics() {
    if (defaultState.state.length === 0) {
      setIsProcessing(true);
      await api
        .getService("master/state")
        .then(async (response) => {
          await setDefaultState((prevState) => ({
            ...prevState,
            state: response?.data?.data,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    }
  }

  // Data Mapping
  async function mapData(data) {
    let prevState = data;
    prevState.state = data?.state?._id;
    await setState(prevState);
  }

  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    var query = { ...state };

    query["isNew"] = true;

    if (saveToDB) {
      setIsProcessing(true);
      api
        .postService("master/city", query)
        .then((response) => {
          setIsProcessing(false);
          resp.Success(response?.data?.message);
          props.callBack(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      props.callBack(query);
    }
    return null;
  }

  function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .putService(`master/city/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // Google Map

  function onPositionChange(data) {
    console.log("change data:", data);
    let matchedData = matchData({ ...state }, data);
    setState(matchedData);
  }

  return (
    <Modal
      {...props}
      onHide={props.onHide}
      backdrop="static"
      keyboard={false}
      id="city_master_modal"
      size="lg"
    >
      <Modal.Header closeButton={true}>
        <h2 className="font-medium text-base mr-auto">{props.title}</h2>
      </Modal.Header>
      <Modal.Body>
        {isProcessing && <ProcessLoader />}
        <form
          onSubmit={
            userAction === "create"
              ? onSubmit
              : userAction === "edit"
              ? onUpdate
              : (e) => e.preventDefault()
          }
          id="city-form"
          className="grid grid-cols-12 gap-4 gap-y-3"
        >
          <div className="col-span-12">
            <label className="form-label" htmlFor="name">
              Place
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="eg: Anna Nagar"
              disabled={viewMode}
              onChange={changeInput}
              value={state.name}
              required
            />
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="form-label" htmlFor="county">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="county"
              name="county"
              placeholder="eg: Chennai"
              disabled={viewMode}
              onChange={changeInput}
              value={state.county}
              required
            />
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="form-label" htmlFor="region">
              State
            </label>
            <input
              type="text"
              className="form-control"
              id="region"
              name="region"
              placeholder="eg: Tamil Nadu"
              disabled={viewMode}
              onChange={changeInput}
              value={state.region}
              required
            />
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="form-label" htmlFor="latitude">
              Latitude
            </label>
            <input
              type="text"
              className="form-control"
              id="latitude"
              name="latitude"
              placeholder=""
              disabled={viewMode}
              onChange={changeInput}
              value={state.latitude}
            />
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="form-label" htmlFor="longitude">
              Longitude
            </label>
            <input
              type="text"
              className="form-control"
              id="longitude"
              name="longitude"
              placeholder=""
              disabled={viewMode}
              onChange={changeInput}
              value={state.longitude}
            />
          </div>

          <div className="col-span-12">
            <label className="form-label" htmlFor="locate_map">
              Locate on Map
            </label>

            <GoogleMapLocator callBack={onPositionChange} />
          </div>

          {/* <div className="col-span-12">
            <label className="form-label" htmlFor="latitude">
              Latitude
            </label>
            <input
              type="number"
              className="form-control"
              id="latitude"
              name="latitude"
              placeholder=""
              disabled={viewMode}
              onChange={changeInput}
              value={state.latitude}
            />
          </div>

          <div className="col-span-12">
            <label className="form-label" htmlFor="longitude">
              Longitude
            </label>
            <input
              type="number"
              className="form-control"
              id="longitude"
              name="longitude"
              placeholder=""
              disabled={viewMode}
              onChange={changeInput}
              value={state.longitude}
            />
          </div> */}

          <input type="submit" id="save_city" className="hidden" />
          <input type="submit" id="update_city" className="hidden" />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          data-dismiss="modal"
          className="btn btn-outline-secondary w-20 mr-1"
          onClick={props.onHide}
        >
          Cancel
        </button>{" "}
        {userAction === "create" && (
          <label
            type="button"
            className="btn btn-primary w-20 "
            htmlFor="save_city"
          >
            Save
          </label>
        )}
        {userAction === "edit" && (
          <label
            type="button"
            className="btn btn-primary w-20 "
            htmlFor="update_city"
          >
            Update
          </label>
        )}
      </Modal.Footer>
    </Modal>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "City Master",
  action: "view",
  callBack() {},
};
