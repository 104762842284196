import React, { useState, useEffect } from "react";
// Page Defaults
import Preloader from "../../../components/preloader/preloader";
import ProcessLoader from "../../../components/preloader/processLoader";
import Error from "../../../components/error/error";
// Page Elements
import ValidationError from "../../../components/error/validationError";
// HTML ELEMENTS
import CurrencyInput from "../../../components/htmlElements/currencyInput";
import ArrayAddRemoveButtons from "../../../components/htmlElements/arrayAddRemoveButtons";
import Container from "../../../components/container";
import Card from "../../../components/card/custom";
import Form from "../../../components/form/custom";

// Functions
import * as api from "../../../api_service/api";
import { resp } from "../../../functions/responseHandler";
import {
  formatCurrency,
  toFloat,
  localDate,
  htmlDate,
  formatTypeSearch,
  validateComplexForm,
} from "../../../functions/common";
// Model
import { Model, ValidationErrorModel } from "./model";
// HTML ELEMENTS
import { Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

// External components
import MemoPreview from "../../../components/Accounts/memo_preview";

// Valiadtion
import * as validation from "../../../functions/validation";

// CSS
import "./form.css";
import "./debit_credit_memo.css";
import DividerText from "../../../components/htmlElements/dividerText";

// Loadash
var _ = require("lodash");

export default function RecDebitCreditMemoForm(props) {
  const [pageTitle, setPageTitle] = useState("Credit / Debit Memo");
  // Loaders
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // Validation
  const [validationError, setValidationError] = useState(
    ValidationErrorModel()
  );
  const [isValidForm, setIsValidForm] = useState({
    valid: true,
    msg: "Please fix the errors!",
  });

  const [state, setState] = useState(Model());

  const [defaultState, setDefaultState] = useState({
    bank: [],
    receivable: [],
    client: [],
    cash: [],
    advance_reference: [],
    invoices: [],
  });

  // Modal Functions
  const [memoPreview, setMemoPreview] = useState({
    show: false,
    data: {},
  });

  //////// INVOICE TABLE /////////
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState({
    count: 0,
    data: [],
  });
  //////// INVOICE TABLE /////////

  useEffect(() => {
    loadDefaultMetrics("client");
    setIsLoaded(true);
  }, []);

  // Modal Functions

  function closeMemoPreviewModal() {
    setMemoPreview((prevState) => ({ ...prevState, show: false }));
  }

  function openCreditDebitMemoModal(data) {
    setMemoPreview((prevState) => ({ ...prevState, data: data }));
    setMemoPreview((prevState) => ({ ...prevState, show: true }));
  }

  const changeInputArrayObject = async (event, array_name, array_index) => {
    const { name, value } = event.target;
    const prevState = { ...state };
    prevState[array_name][array_index][name] = value;

    // if (
    //   (name == "credit" || name == "credit") &&
    //   !state.enablePaymentMode
    // ) {
    //   prevState.enablePaymentMode = true;
    // }
    setState(prevState);
  };

  function formValidation(
    name,
    value,
    array_name = "",
    index = 0,
    data,
    state
  ) {
    let errors = validationError;
    let total = 0;
    switch (name) {
      case "client":
        errors[name] = validation.validateTypeAhead(value);
        break;
      default:
        break;
    }
  }

  //////////////// CALCULATIONS ////////////////////

  function calcTotalTransaction(list, advance_amount) {
    let newAmount = 0;
    for (var i in list) {
      newAmount += toFloat(list[i]["new_amount_received"]);
    }
    setState((prevState) => ({
      ...prevState,
      total_transaction_amount: newAmount,
    }));

    if (state?.transaction_context === "journal") {
      if (typeof advance_amount == "undefined") {
        advance_amount = state?.advance_reference?.available_balance;
      }
      let availableAmount = toFloat(advance_amount || 0) - newAmount;
      setState((prevState) => ({
        ...prevState,
        available_advance_amount: availableAmount,
      }));
    }
  }

  function calcTotalBalance(data) {
    let balance = 0;
    let total_received =
      toFloat(data?.total_amount_received || 0) +
      toFloat(data?.total_debit || 0);
    balance = data?.total + data?.total_credit - total_received;
    return balance;
  }

  async function processInvoices(data) {
    for (var i in data) {
      data[i]["debit"] = 0;
      data[i]["credit"] = 0;

      data[i]["credit_ref_no"] = "";
      data[i]["debit_ref_no"] = "";

      data[i]["credit_note"] = "";
      data[i]["debit_note"] = "";

      data[i]["remarks"] = "";
      data[i]["selected"] = false;
    }
    return data;
  }
  //////////////// CALCULATIONS ////////////////////

  function addInvoice(event) {
    event.preventDefault();
    let prevList = [...state.invoices];
    const model = Model();
    prevList.push(model.invoices[0]);

    setState((prevState) => ({
      ...prevState,
      invoices: prevList,
    }));
  }

  function removeInvoice(event, index) {
    event.preventDefault();
    let prevList = [...state.invoices];
    prevList.splice(index, 1);

    setState((prevState) => ({
      ...prevState,
      invoices: prevList,
    }));
  }

  // Indirect Memo

  function addIndirectMemo(event) {
    event.preventDefault();
    let prevList = [...state.indirect_memo];
    const model = Model();
    prevList.push(model.indirect_memo[0]);

    setState((prevState) => ({
      ...prevState,
      indirect_memo: prevList,
    }));
  }

  function removeIndirectMemo(event, index) {
    event.preventDefault();
    let prevList = [...state.indirect_memo];
    prevList.splice(index, 1);

    setState((prevState) => ({
      ...prevState,
      indirect_memo: prevList,
    }));
  }

  function changeInput(event) {
    const { name, value } = event.target;
    if (name === "total_transaction_amount") {
      // calcInvoice(value);
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeDistributionType(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (value === "manual") {
      splitMultiInvoices();
    }
    if (value === "equal") {
      splitMultiInvoicesEqual();
    }
  }

  async function splitMultiInvoicesEqual() {
    let prevSate = { ...state };
    if (prevSate.multi_invoices.length > 0) {
      setIsProcessing(true);

      let distInvoices = await getDistMultiInvoices(prevSate);

      setState((prevState) => ({
        ...prevState,
        invoices: [...prevState.invoices, ...distInvoices],
      }));
      setIsProcessing(false);
    } else {
      resp.Error("No valid invoices found.");
    }
  }

  function splitMultiInvoices() {
    let prevSate = { ...state };
    if (prevSate.multi_invoices.length > 0) {
      let validInvoices = [];
      for (var i in prevSate.multi_invoices) {
        if (!isInInvoiceList(prevSate.multi_invoices[i].invoice_number)) {
          validInvoices.push(prevSate.multi_invoices[i]);
        }
      }
      setState((prevState) => ({
        ...prevState,
        invoices: [...prevState.invoices, ...validInvoices],
      }));
    } else {
      let newRecord = {
        _id: "",
        client: "",
        status: "",

        invoice_number: "",
        invoiced_date: "",
        total: 0,
        total_balance: 0,
        total_credit: 0,
        total_debit: 0,
      };
      setState((prevState) => ({
        ...prevState,
        invoices: [...prevState.invoices, newRecord],
      }));
    }
  }

  function enableMiscEntry(event) {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, enableIndrirectMemo: true }));
  }

  function isInInvoiceList(value) {
    var found = false;
    for (var i = 0; i < state.invoices.length; i++) {
      if (state.invoices[i].invoice_number == value) {
        found = true;
        break;
      }
    }
    return found;
  }

  function changeInputType(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changePaymentMethod(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (value === "bank" || value === "cash") {
      loadDefaultMetrics(value);
    }
  }

  function changeInputPaymentMaster(event) {
    const { name, value } = event.target;
    let parsedValue = JSON.parse(value);
    setState((prevState) => ({ ...prevState, [name]: parsedValue }));
  }

  async function onSubmit(event) {
    event.preventDefault();
    let data = await prepareData();
    console.log("data:", data);

    if (data["direct_memo"].length < 1 && data["indirect_memo"].length < 1) {
      resp.Error("No valid memo found.");
    } else {
      openCreditDebitMemoModal(data);
    }
  }

  async function getFinalInvoices(data) {
    // Direct Memo
    let singleInvoices = [];
    let multipleInvoices = [];
    // Single Invoices
    for (var i in data.invoices) {
      if (
        typeof data.invoices[i]._id !== "undefined" &&
        data.invoices[i]?._id !== ""
      ) {
        singleInvoices.push(data.invoices[i]);
      }
    }
    // result = await _.unionBy(
    //   singleInvoices,
    //   multipleInvoices,
    //   "invoice_number"
    // );
    return singleInvoices;
  }
  async function getDistMultiInvoices(data) {
    let invoices = data.multi_invoices;
    let totalDebit = parseFloat(data?.multi_invoice_debit || 0);
    let totalCredit = parseFloat(data?.multi_invoice_credit || 0);
    let distDebit = 0;
    let distCredit = 0;
    // Calculating debit for idividual records
    if (totalDebit > 0) {
      distDebit = totalDebit / invoices.length;
    }
    // Calculating Credit for idividual records
    if (totalCredit > 0) {
      distCredit = totalCredit / invoices.length;
    }

    for (var i in invoices) {
      if (totalDebit > 0) {
        invoices[i]["debit"] = toFloat(distDebit);
      }
      if (totalCredit > 0) {
        invoices[i]["credit"] = toFloat(distCredit);
      }
    }
    return invoices;
  }

  function getFinalIndirectMemo(data) {
    let result = [];
    for (var i in data) {
      if (data[i].payment_reference_number != "") {
        result.push(data[i]);
      }
    }
    return result;
  }
  async function prepareData() {
    let data = { ...state };
    // Merging single and multi invoices
    data["direct_memo"] = await getFinalInvoices(data);
    data["indirect_memo"] = await getFinalIndirectMemo(data.indirect_memo);

    return data;
  }

  function changeClient(selected, name, type) {
    if (selected.length != 0) {
      let selected_data = selected[0];
      setState((prevState) => ({ ...prevState, [name]: selected_data }));

      loadInvoice(selected_data);
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function changeInvoiceNumber(selected, index) {
    let prevState = { ...state };
    let prevDefaultInvoices = [...defaultState.invoices];
    if (selected.length != 0) {
      let selected_data = selected[0];
      prevState.invoices[index] = selected_data;

      // Disabling selected invoices
      // let objIndex = prevDefaultInvoices.findIndex(
      //   (obj) => obj._id === selected_data._id
      // );

      // prevDefaultInvoices[objIndex]["disabled"] = true;
      // setDefaultState((prevState) => ({
      //   ...prevState,
      //   invoices: prevDefaultInvoices,
      // }));
    } else {
      prevState.invoices[index] = "";
    }
    setState(prevState);
  }

  async function loadDefaultMetrics(metric) {
    if (defaultState[metric] && defaultState[metric].length < 1) {
      setIsProcessing(true);
      var query = { metrix: [metric] };
      await api
        .postService("common", query)
        .then((response) => {
          console.log(response);
          const data = response.data;
          if (metric === "cash") {
            // Setting default value if one record is found
            if (data[metric].length == 1) {
              setState((prevState) => ({
                ...prevState,
                payment_cash: data[metric][0],
              }));
            }
          }

          if (metric === "bank") {
            // Setting default value if one record is found
            if (data[metric].length == 1) {
              setState((prevState) => ({
                ...prevState,
                payment_bank: data[metric][0],
              }));
            }
          }

          setDefaultState((prevState) => ({
            ...prevState,
            [metric]: response?.data[metric],
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    }
  }

  async function loadInvoice(client) {
    if (client !== "" && typeof client?._id !== "undefined") {
      setIsProcessing(true);
      await api
        .getService(
          `accounts/transactions/invoices/memo_listing/${client?._id}`
        )
        .then(async (response) => {
          const data = response.data;
          console.log("data", data);
          let invoice = await processInvoices(data.data);

          await setDefaultState((prevState) => ({
            ...prevState,
            invoices: invoice,
          }));

          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    } else {
      resp.Error("Please select client.");
    }
  }

  function goBack() {
    window.location.href = "/admin#/accounts/recievables";
  }

  async function handleKeyDown(event) {
    const { name, value } = event.target;
    setIsProcessing(true);
    if (["Enter", "Tab", ","].includes(event.key)) {
      var data = value.trim();
      // Checking if invoice number is valid
      let invoiceRecord = await isInvoiceNoValid(data);
      console.log("invoiceRecord:", invoiceRecord);
      if (invoiceRecord) {
        // Checking duplicate entry
        if (isInMultiInvoiceList(invoiceRecord?.invoice_number)) {
          setState((prevState) => ({
            ...prevState,
            multi_invoices_error: "Duplicate Entry.",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            multi_invoices: [...prevState.multi_invoices, invoiceRecord],
          }));
          setState((prevState) => ({ ...prevState, multi_invoices_value: "" }));
          setState((prevState) => ({
            ...prevState,
            multi_invoices_error: null,
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          multi_invoices_error: "Invalid Invoice number",
        }));
      }
    }
    setIsProcessing(false);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, multi_invoices_value: value }));

    setState((prevState) => ({
      ...prevState,
      multi_invoices_error: null,
    }));
    // checking if client is selected
    if (typeof state.client?._id == "undefined") {
      setState((prevState) => ({
        ...prevState,
        multi_invoices_error: "Please select client.",
      }));
    }
  }

  const handleDelete = (item) => {
    setState((prevState) => ({
      ...prevState,
      multi_invoices: state.multi_invoices.filter(
        (i) => i.invoice_number !== item.invoice_number
      ),
    }));
  };

  const handlePaste = async (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData("text");
    setState((prevState) => ({ ...prevState, multi_invoices_value: paste }));
    setIsProcessing(true);

    var invoiceNoList = paste.split(",");
    invoiceNoList = await invoiceNoList.filter((item) => item);
    var inavlidInvoicesList = [];
    // Errors
    let err_duplicate_docs = [];
    let err_invalid_docs = [];
    //
    let valid_docs = [];
    for (var i in invoiceNoList) {
      let record = await isInvoiceNoValid(invoiceNoList[i]);
      if (record) {
        if (isInMultiInvoiceList(record?.invoice_number)) {
          err_duplicate_docs.push(invoiceNoList[i]);
          inavlidInvoicesList.push(invoiceNoList[i]);
        } else {
          valid_docs.push(record);
        }
      } else {
        err_invalid_docs.push(invoiceNoList[i]);
        inavlidInvoicesList.push(invoiceNoList[i]);
      }
    }

    let err_msg = <></>;
    if (err_invalid_docs.length > 0) {
      err_msg = <>Invalid Invoice Numbers : {err_invalid_docs.toString()}</>;
    }
    if (err_duplicate_docs.length > 0) {
      // err_msg += <br /> + "Duplicate Entry : " + err_duplicate_docs.toString();
      err_msg = (
        <>
          {err_msg}
          <br />
          Duplicate Entry : {err_duplicate_docs.toString()}
        </>
      );
    }

    // // Setting State

    setState((prevState) => ({
      ...prevState,
      multi_invoices: [...prevState.multi_invoices, ...valid_docs],
    }));
    setState((prevState) => ({
      ...prevState,
      multi_invoices_value: inavlidInvoicesList.toString(),
    }));
    setState((prevState) => ({
      ...prevState,
      multi_invoices_error: err_msg,
    }));

    setIsProcessing(false);
  };

  async function isInvoiceNoValid(value) {
    let obj = await defaultState.invoices.find(
      (o) => o?.invoice_number == value
    );
    return obj;
    // let obj;
    // for (var i = 0; i < defaultState.invoices; i++) {
    //   if (defaultState.invoices[i].invoice_number == value) {
    //     obj = defaultState.invoices[i];
    //     console.log("result",obj)
    //     break;
    //   }
    // }
    // console.log("obj:",obj)
    // return obj;

    // return defaultState.invoices.find((o) => o.invoice_number === value);
  }

  function isInMultiInvoiceList(value) {
    var found = false;
    for (var i = 0; i < state.multi_invoices.length; i++) {
      if (state.multi_invoices[i].invoice_number === value) {
        found = true;
        break;
      }
    }
    return found;

    // return defaultState.invoices.includes(value);
  }

  function memoPreviewCallBack(response) {
    if (response) {
      closeMemoPreviewModal();
      window.location.reload();
    } else {
      console.log("Cancelled.");
    }
  }

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <Container>
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title>{pageTitle}</Container.Title>
        </Container.Header>
        {/* <!-- END: Header --> */}
        {/* <!-- BEGIN: Container Body --> */}
        <Container.Body>
          {/* <!-- BEGIN: Card --> */}
          <Card className="mt-5">
            {isProcessing && <ProcessLoader />}

            {/* MODAL */}
            {memoPreview.show && (
              <MemoPreview
                show={memoPreview.show}
                data={memoPreview.data}
                onHide={closeMemoPreviewModal}
                callBack={memoPreviewCallBack}
              />
            )}
            {/* MODAL */}

            {/* <!-- BEGIN: Card Body --> */}
            <Card.Body>
              {/* <!-- BEGIN: Form --> */}
              <Form id="recievables-form" onSubmit={onSubmit}>
                <Form.Row className="justify-center">
                  <Form.Col>
                    <Form.Label>Date of receipt</Form.Label>
                    <Form.Input
                      type="date"
                      max={htmlDate(new Date())}
                      className="form-control"
                      id="date"
                      name="date"
                      onChange={changeInput}
                      value={state?.date}
                      required
                    />
                  </Form.Col>

                  <Form.Col>
                    <Form.Label>Client</Form.Label>
                    <Typeahead
                      id="client"
                      name="client"
                      onChange={(selected) => {
                        changeClient(selected, "client");
                      }}
                      options={defaultState?.client}
                      labelKey={(option) => `${option?.name}`}
                      selected={formatTypeSearch(state?.client)}
                      inputProps={{ required: true }}
                      placeholder="Search client..."
                    />
                  </Form.Col>

                  <Form.Col>
                  <Form.Label>Invoice Numbers</Form.Label>
                    {/*  MULTI INVOICE ENTRY */}
                    <div id="multi_invoice_input">
                      {/* MULTI INVOICE INPUT */}
                      <textarea
                        id={
                          "chip-input " +
                          (state.multi_invoices_error && " has-error")
                        }
                        type="text"
                        name="multi_invoices_value"
                        className="form-control"
                        placeholder="Type or paste invoice numbers..."
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        value={state.multi_invoices_value}
                        onPaste={handlePaste}
                      />
                      {/* MULTI INVOICE INPUT */}
                      {state.multi_invoices_error && (
                        <p id="chip-input-error">
                          {state.multi_invoices_error}
                        </p>
                      )}
                    </div>

                    <div className="mt-3">
                      <p className="">
                        {state.multi_invoices.map((item, index) => (
                          <label id="chip-input-tag-item" key={index}>
                            {item?.invoice_number}
                            <button
                              type="button"
                              className="button"
                              onClick={() => handleDelete(item)}
                            >
                              &times;
                            </button>
                          </label>
                        ))}
                      </p>
                    </div>

                    {/*  MULTI INVOICE ENTRY */}
                  </Form.Col>
                </Form.Row>

                <Form.Row className="justify-center my-auto">
                  <Form.Col>
                    <Form.Label>Debit</Form.Label>
                    <CurrencyInput
                      id="multi_invoice_debit"
                      name="multi_invoice_debit"
                      placeholder="Debit"
                      onChange={changeInput}
                      value={state?.multi_invoice_debit}
                    />
                  </Form.Col>

                  <Form.Col>
                    <Form.Label>Credit</Form.Label>
                    <CurrencyInput
                      id="multi_invoice_credit"
                      name="multi_invoice_credit"
                      placeholder="Credit"
                      onChange={changeInput}
                      value={state?.multi_invoice_credit}
                    />
                  </Form.Col>
                  <Form.Col className="my-auto">
                    <div className="form-check mx-2 radio-bg">
                      <input
                        label="Distribute equally"
                        type="radio"
                        id="distribution_type_equal"
                        className={
                          state?.distribution_type == "equal"
                            ? "btn btn-dark"
                            : "btn btn-secondary"
                        }
                        className="btn btn-secondary"
                        name="distribution_type"
                        value="equal"
                        checked={state?.distribution_type == "equal"}
                        onChange={changeDistributionType}
                        // disabled={state?.distribution_type == "manual"}
                      />
                      <input
                        label="Enter manually"
                        type="radio"
                        id="distribution_type_manual"
                        name="distribution_type"
                        value="manual"
                        className={
                          state?.distribution_type == "manual"
                            ? "btn btn-dark"
                            : "btn btn-secondary"
                        }
                        checked={state?.distribution_type == "manual"}
                        onChange={changeDistributionType}
                      />
                    </div>
                  </Form.Col>
                </Form.Row>
                {/* <!-- BEGIN: MULTI INVOICE ENTRY --> */}
                <div id="memo_manual_entry" className="mt-5">
                  <div className="w-full flex justify-end">
                    <button
                      type="button"
                      className="btn btn-facebook w-32 mr-2 mb-2"
                      onClick={enableMiscEntry}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      &nbsp;Misc Entry
                    </button>
                  </div>

                  {/* INVOICE LIST - TABLE */}
                  <table className="table">
                    <thead className="bg-gray-200 text-gray-700">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Invoice No.</th>
                        <th scope="col" colSpan="2">
                          Summary
                        </th>
                        <th scope="col">Debit</th>
                        <th scope="col">Debit Ref No</th>
                        <th scope="col">Credit</th>
                        <th scope="col">Credit Ref No</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.invoices?.map((row, index) => {
                        return (
                          <tr className="" key={index}>
                            {/* S.No */}
                            <td className="border-b dark:border-dark-5">
                              {index + 1}
                            </td>
                            {/* S.No */}

                            {/* Invoice No */}

                            <td className="border-b dark:border-dark-5">
                              <Typeahead
                                id="invoice_number"
                                name="invoice_number"
                                onChange={(selected) => {
                                  changeInvoiceNumber(selected, index);
                                }}
                                // options={state?.invoices}
                                options={defaultState?.invoices}
                                labelKey={(option) =>
                                  `${option?.invoice_number}`
                                }
                                selected={formatTypeSearch(
                                  state?.invoices[index]
                                )}
                                // inputProps={{ required: true }}
                                placeholder="Search Invoice No..."
                              />
                            </td>

                            <td
                              colSpan="2"
                              className="border-b dark:border-dark-5"
                            >
                              {/* Past payments */}
                              <div className="" id="payment_details">
                                <div id="group1">
                                  <div className="d-inline mr-3">
                                    <label className="m-0 text-dark">
                                      <small>Billed : </small>
                                      <strong>
                                        {formatCurrency(row?.total)}{" "}
                                      </strong>
                                    </label>
                                  </div>
                                </div>

                                <div id="group2 ">
                                  <div className="d-inline mr-3">
                                    <label className="m-0">
                                      <small>Total Debit : </small>

                                      <strong className="text-danger">
                                        {"-"}&nbsp;
                                        {formatCurrency(row?.total_debit)}{" "}
                                      </strong>
                                    </label>
                                  </div>

                                  <div className="d-inline">
                                    <label className="m-0">
                                      <small>Total Credit : </small>
                                      <strong className="text-success">
                                        {"+"}&nbsp;
                                        {formatCurrency(row?.total_credit)}{" "}
                                      </strong>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {/* Past payments */}
                            </td>

                            {/* Debit / Credit Input */}

                            <td className="border-b dark:border-dark-5">
                              <CurrencyInput
                                id="debit"
                                name="debit"
                                onChange={(event) => {
                                  changeInputArrayObject(
                                    event,
                                    "invoices",
                                    index
                                  );
                                }}
                                value={row?.debit}
                              />
                            </td>

                            {/* Debit Note */}
                            <td className="border-b dark:border-dark-5">
                              <input
                                type="text"
                                className="form-control"
                                id="debit_ref_no"
                                name="debit_ref_no"
                                onChange={(event) => {
                                  changeInputArrayObject(
                                    event,
                                    "invoices",
                                    index
                                  );
                                }}
                                value={row?.debit_ref_no}
                              />
                            </td>
                            {/* Debit Note */}

                            <td className="border-b dark:border-dark-5">
                              <CurrencyInput
                                id="credit"
                                name="credit"
                                onChange={(event) => {
                                  changeInputArrayObject(
                                    event,
                                    "invoices",
                                    index
                                  );
                                }}
                                value={row?.credit}
                              />
                            </td>

                            {/* Credit Note */}
                            <td className="border-b dark:border-dark-5">
                              <input
                                type="text"
                                className="form-control"
                                id="credit_ref_no"
                                name="credit_ref_no"
                                onChange={(event) => {
                                  changeInputArrayObject(
                                    event,
                                    "invoices",
                                    index
                                  );
                                }}
                                value={row?.credit_ref_no}
                              />
                            </td>
                            {/* Credit Note */}

                            <td className="border-b dark:border-dark-5">
                              <ArrayAddRemoveButtons
                                addFunc={addInvoice}
                                removeFunc={(event) =>
                                  removeInvoice(event, index)
                                }
                                array={state?.invoices}
                                index={index}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* INVOICE LIST - TABLE */}
                </div>
                {/* <!-- END: MULTI INVOICE ENTRY --> */}

                {/* <!-- BEGIN:  Indriect Memo Entry --> */}
                {state?.enableIndrirectMemo && (
                  <div className="mt-2">
                    <DividerText>Misc Entry</DividerText>
                    <div className="table p-15">
                      <div className="table-responsive">
                        {/* INVOICE LIST - TABLE */}
                        <table className="table" id="indirect_memo_list_table">
                          <thead className="bg-gray-400 text-gray-600">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Ref No.</th>
                              <th scope="col">Debit</th>
                              <th scope="col">Debit Ref No</th>
                              <th scope="col">Credit</th>
                              <th scope="col">Credit Ref No</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {state?.indirect_memo?.map((row, index) => {
                              return (
                                <tr className="" key={index}>
                                  {/* S.No */}
                                  <td className="border-b dark:border-dark-5">
                                    {index + 1}
                                  </td>
                                  {/* S.No */}

                                  {/* Ref No */}

                                  <td className="border-b dark:border-dark-5">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="payment_reference_number"
                                      name="payment_reference_number"
                                      onChange={(event) => {
                                        changeInputArrayObject(
                                          event,
                                          "indirect_memo",
                                          index
                                        );
                                      }}
                                      value={row?.payment_reference_number}
                                    />
                                  </td>

                                  {/* Debit / Credit Input */}

                                  <td className="border-b dark:border-dark-5">
                                    <CurrencyInput
                                      id="debit"
                                      name="debit"
                                      onChange={(event) => {
                                        changeInputArrayObject(
                                          event,
                                          "indirect_memo",
                                          index
                                        );
                                      }}
                                      value={row?.debit}
                                    />
                                  </td>

                                  {/* Debit Note */}
                                  <td className="border-b dark:border-dark-5">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="debit_ref_no"
                                      name="debit_ref_no"
                                      onChange={(event) => {
                                        changeInputArrayObject(
                                          event,
                                          "indirect_memo",
                                          index
                                        );
                                      }}
                                      value={row?.debit_ref_no}
                                    />
                                  </td>
                                  {/* Debit Note */}

                                  <td className="border-b dark:border-dark-5">
                                    <CurrencyInput
                                      id="credit"
                                      name="credit"
                                      onChange={(event) => {
                                        changeInputArrayObject(
                                          event,
                                          "indirect_memo",
                                          index
                                        );
                                      }}
                                      value={row?.credit}
                                    />
                                  </td>

                                  {/* Credit Note */}
                                  <td className="border-b dark:border-dark-5">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="credit_ref_no"
                                      name="credit_ref_no"
                                      onChange={(event) => {
                                        changeInputArrayObject(
                                          event,
                                          "indirect_memo",
                                          index
                                        );
                                      }}
                                      value={row?.credit_ref_no}
                                    />
                                  </td>
                                  {/* Credit Note */}

                                  <td className="border-b dark:border-dark-5">
                                    <ArrayAddRemoveButtons
                                      addFunc={addIndirectMemo}
                                      removeFunc={(event) =>
                                        removeIndirectMemo(event, index)
                                      }
                                      array={state?.indirect_memo}
                                      index={index}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* INVOICE LIST - TABLE */}
                      </div>
                    </div>
                  </div>
                )}
                {/* <!-- END:  Indriect Memo Entry --> */}
                <input type="submit" id="save_button" className="hidden" />
              </Form>
              {/* <!-- END: Form --> */}
            </Card.Body>
            {/* <!-- END: Card Body --> */}

            {/* <!-- BEGIN: Card Footer --> */}
            <Card.Footer className="justify-end">
              <button className="btn btn-secondary mr-2" onClick={goBack}>
                Cancel
              </button>

              <label
                type="button"
                className="btn btn-primary"
                htmlFor="save_button"
              >
                Create Transaction
              </label>
            </Card.Footer>

            {/* <!-- END: Card Footer --> */}
          </Card>
          {/* <!-- END: Card --> */}
        </Container.Body>
        {/* <!-- END: Container Body --> */}
      </Container>
    );
  }
}
