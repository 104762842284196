const Model = {
  consignment_approval: false,
  invoice_approval: false,
  driver_approval: false,
  vehicle_approval: false,
  business_segment_type: "",
  overdue_invoice_send_to: "",
};

export { Model };
