import React from "react";
import PropTypes from "prop-types";

export default function Component(props) {
  const { data, className, id, image, email, display, ...rest } = props;

  return (
    <div className={className} id={id}>
      {/* <div className="w-8 h-8 flex-none image-fit mr-2">
        <img alt="" className="rounded-full" src={image} />
      </div> */}
      <div className="">
        <label className="font-medium break-all">
          {data?.name}
        </label>

        {display?.includes("email") && data?.email && (
          <div className="w-full truncate text-gray-600 mt-0.5">
            <i class="fa fa-envelope text-red-400 mr-1" aria-hidden="true"></i>
            {data?.email}
          </div>
        )}

        {display?.includes("phone") && data?.contact_number && (
          <div className="w-full truncate mt-0.5">
            <i class="fa fa-phone text-blue-400 mr-1" aria-hidden="true"></i>{" "}
            {data?.contact_number}
          </div>
        )}
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  image: PropTypes.string,
  display: PropTypes.array, // Entities to be shown : email,phone
};

Component.defaultProps = {
  className: "cursor-pointer relative flex items-center",
  data: { name: "", contact_number: "", email: "" },
  image: "images/profile-pic-default.png",
  display: [],
};
