import React from "react";
import PropTypes from "prop-types";

export default function Component(props) {
  const { data, className, id, size, ...rest } = props;

  return (
    <div className={className} id={id}>
      <div id="tyre_details">
        <div className="font-medium mb-2">{data?.serial_no}</div>

        <div id="vehicle_type" className="flex">
          {data?.brand && (
            <small className="text-gray-600">{data?.brand}</small>
          )}
          {data?.sub_brand && (
            <small className="ml-2 text-gray-600">• {data?.sub_brand}</small>
          )}
        </div>
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.object,
  size: PropTypes.string,
  // Functions
  onClick: PropTypes.func,
};

Component.defaultProps = {
  // Variables
  className: "box",
  object: {
    registration_number: "",
    ownership_type: "",
    vehicle_type: { vehicle_type: "" },
    vehicleType: "",
  },
  size: "", // sm,lg,xl
  // Functions
  onClick() {},
};
