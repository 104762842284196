import React from "react";
import PropTypes from "prop-types";
export default function AddNewButton(props) {
  const { className, type, id, name, onClick, icon, text, ...rest } = props;

  return (
    <button type="button" className="w-full btn btn-primary-soft" onClick={onClick}>
      <i className={icon + " mr-1"} aria-hidden="true"></i>
      {text}
    </button>
  );
}

AddNewButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

AddNewButton.defaultProps = {
  className: "",
  type: "",
  id: "",
  name: "",
  icon: "fa fa-plus",
  text: "Add New",
  onClick() {},
};
