import PropTypes from "prop-types";

/* <!-- BEGIN: Card --> */
const Card = (props) => {
  const { className } = props;
  return <div className={"box " + className}>{props.children}</div>;
};
Card.propTypes = {
  className: PropTypes.string,
};
Card.defaultProps = {
  className: "",
};

/* <!-- END: Card --> */

/* <!-- BEGIN: Header --> */
const Header = (props) => {
  const { className, size } = props;
  let addOnClass = size === "sm" ? " p-2" : " p-5";
  return <div className={className + addOnClass}>{props.children}</div>;
};
Header.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};
Header.defaultProps = {
  className:
    "flex flex-col sm:flex-row items-center border-b border-gray-200 dark:border-dark-5 ",
  size: "",
};

/* <!-- END: Header --> */

/* <!-- BEGIN: Footer --> */
const Footer = (props) => {
  const { className } = props;
  const defaultClass =
    "flex flex-col sm:flex-row items-center p-5 border-t border-gray-200 dark:border-dark-5 ";
  return <div className={defaultClass + className}>{props.children}</div>;
};
Footer.propTypes = {
  className: PropTypes.string,
};
Footer.defaultProps = {
  className: "",
};

/* <!-- END: Footer --> */

/* <!-- BEGIN: Body --> */
const Body = (props) => {
  const { className } = props;
  return <div className={className}>{props.children}</div>;
};
Body.propTypes = {
  className: PropTypes.string,
};
Body.defaultProps = {
  className: "p-5",
};

/* <!-- END: Body --> */

/* <!-- BEGIN: Title --> */
const Title = (props) => {
  const { className } = props;
  return <h2 className={className}>{props.children}</h2>;
};
Title.propTypes = {
  className: PropTypes.string,
};
Title.defaultProps = {
  className: "font-medium text-base mr-auto",
};

/* <!-- END: Title --> */

/* <!-- BEGIN: HeaderActions --> */
const HeaderActions = (props) => {
  const { className } = props;
  return <h2 className={className}>{props.children}</h2>;
};
HeaderActions.propTypes = {
  className: PropTypes.string,
};
HeaderActions.defaultProps = {
  className: "w-full sm:w-auto flex mt-4 sm:mt-0 justify-center",
};

/* <!-- END: HeaderActions --> */

Card.Header = Header;
Card.Footer = Footer;
Card.Body = Body;
Card.Title = Title;
Card.HeaderActions = HeaderActions;

export default Card;
