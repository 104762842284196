import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// Components
import ProcessLoader from "../../components/preloader/processLoader";
// import Modal from "react-bootstrap/Modal";
import { Modal } from "react-bootstrap"; // Slider

import { Chrono } from "react-chrono"; // Timeline Chart
import Tooltip from "../htmlElements/tooltip";
// Images & Icons
import { ReactComponent as TruckIcon } from "../../assets/images/svg/truck.svg";
import { ReactComponent as GoogleMapIcon } from "../../assets/images/svg/google-map.svg";
// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { isDefined, htmlDateTime } from "../../functions/common";

// External Components

import GPSMap from "../../components/consignment/gpsMap";

// Constants Declaration
const activeClassName = "w-8 h-8 rounded-full btn btn-success-soft";
const inActiveClassName =
  "w-8 h-8 rounded-full btn btn-warning-soft dark:bg-dark-1";

export default function Component(props) {
  const { className, consData, consStage, vehicle, ...rest } = props;
  const origin = `${consData?.origin?.name}`;
  const destination = `${consData?.destination?.name}`;

  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [gpsData, setGpsData] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [transitsData, setTransitsData] = useState([]);

  // <!--BEGIN : GPS Modal -->
  const [gpsMapModal, setGpsMapModal] = useState({
    show: false,
    vehicle: "",
    consData: {},
  });
  const closeGpsMapModal = () =>
    setGpsMapModal((prevState) => ({ ...prevState, show: false }));

  const openGpsMapModal = () =>
    setGpsMapModal((prevState) => ({ ...prevState, show: true }));
  // <!--END : GPS Modal -->

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    // Checking GPS
    if (consData?.vehicle?.gps_enabled) {
      await loadGpsData();
    } else {
      console.log("else");
      setIsProcessing(true);
      var transits = consData?.transits || [];
      await mapTimelineData(transits);
      setIsProcessing(false);
      setIsLoaded(true);
    }
  }

  function getFilterQuery() {
    let query = {};
    query["vehicle_number"] = consData?.vehicle?.registration_number || "";
    query["start_time"] = isDefined(consData?.start_time)
      ? new Date(consData?.start_time)
      : new Date();

    query["end_time"] = isDefined(consData?.end_time)
      ? new Date(consData?.end_time)
      : new Date();
    return query;
  }

  async function loadGpsData() {
    setIsProcessing(true);
    let query = await getFilterQuery();
    await api
      .gpsPostService(`gps/get_location_data`, query)
      .then(async (response) => {
        const data = response.data;
        // Checking If Data Exists

        let recData = await mapData(data.data);
        setGpsData(data.data);

        setIsProcessing(false);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setError(true);
        setIsProcessing(false);
        setIsLoaded(true);
      });
  }

  async function mapData(data) {
    let locationData = [];
    let transits = [];
    for (var i in data) {
      for (var j in data[i]["LocationData"]) {
        // Renaming Fields
        data[i]["LocationData"][j]["lat"] =
          data[i]["LocationData"][j]["latitude"];
        data[i]["LocationData"][j]["lng"] =
          data[i]["LocationData"][j]["longitude"];

        data[i]["LocationData"][j]["time"] =
          data[i]["LocationData"][j]["gps_datetime"];

        data[i]["LocationData"][j]["address"] =
          data[i]["LocationData"][j]["addressLineOne"];

        locationData.push(data[i]["LocationData"][j]);
      }
    }

    transits = await getTransitPoints(locationData);

    // Adding Manually entered Data
    transits.push(consData?.transits);

    await mapTimelineData(transits);
    return locationData;
  }

  async function mapTimelineData(transits) {
    // Pushing Transits to Timeline Data
    var timeline_data = [];
    // Adding Origin

    timeline_data.push({
      title: origin,
      cardTitle: htmlDateTime(consData?.start_time || ""),
      cardSubtitle: "Consignment Started",
    });

    // Adding Transit Points
    for (var i in transits) {
      timeline_data.push({
        title: transits[i]?.place,
        cardTitle: htmlDateTime(transits[i]?.time),
        cardSubtitle: transits[i]?.remark || "",
      });
    }

    // Adding Destination

    timeline_data.push({
      title: destination,
      cardTitle: htmlDateTime(consData?.end_time || ""),
      cardSubtitle: consStage > 2 ? "Truck reached destination." : "",
    });

    await setTransitsData(transits);
    await setTimelineData(timeline_data);
    return;
  }
  function getTransitPoints(data) {
    let transits = [];
    // Pushing to Transits
    for (var i in data) {
      if (i % 50 === 0) {
        transits.push({
          place: data[i]?.addressLineOne,
          time: data[i]?.gps_datetime,
          remark: "",
        });
      }
    }

    transits.push({
      place: data[data.length - 1]?.addressLineOne,
      time: data[data.length - 1]?.gps_datetime,
      remark: "",
    });
    return transits;
  }

  return (
    <div className="z-1">
      {/* <!-- BEGIN: GPS MAP Modal --> */}
      {gpsMapModal.show && (
        <GPSMap
          show={gpsMapModal.show}
          vehicle={vehicle}
          consData={consData}
          consStage={consStage}
          onHide={closeGpsMapModal}
        />
      )}
      {/* <!-- END: GPS MAP Modal--> */}

      {/* <!--BEGIN: Slider --> */}
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop={false}
        size="lg"
        scroll={true}
      >
        {/* <!--BEGIN: Slider Header --> */}
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">
            {"Transits Timeline"}
          </h2>
        </Modal.Header>
        {/* <!--END:Slider  Header --> */}

        {/* <!--BEGIN: Slider Body --> */}
        <Modal.Body>
          {isProcessing && <ProcessLoader />}

          {consStage > 1 && vehicle?.gps_enabled && (
            <div className="w-full text-center">
              <div
                id="map_button"
                className="cursor-pointer btn btn-secondary text-center flex mr-2 px-1"
                onClick={openGpsMapModal}
              >
                <GoogleMapIcon width="30" height="30" className="inline mb-1" />
                <span className="ml-2 block font-medium text-gray-700">
                  View on Map
                </span>
              </div>
            </div>
          )}

          {isLoaded ? (
            <Chrono
              items={timelineData}
              mode="VERTICAL_ALTERNATING"
              activeItemIndex={timelineData.length - 2}
            />
          ) : null}
        </Modal.Body>
        {/* <!--END: Slider Body --> */}
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* <!--END: Slider --> */}
    </div>
  );
}

Component.propTypes = {
  transits: PropTypes.array,
  origin: PropTypes.object,
  destination: PropTypes.object,
  className: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  transits: [],
  origin: {},
  destination: {},
  className: "",
  callBack() {},
};
