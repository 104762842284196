import React from "react";
import PropTypes from "prop-types";

import { formatCurrency } from "../../../functions/common";

//Icons
import { ReactComponent as Icon } from "../../../assets/images/icons/card.svg";

export default function Component(props) {
  const { className, id, name, onClick, data, ...rest } = props;

  return (
    <div className={className} id={id}>
      <Icon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-credit-card report-box__icon text-theme-12"
      />

      <div className="text-3xl font-medium leading-8 mt-6">
        {formatCurrency(data)}
      </div>
      <div className="text-base text-gray-600 mt-1">Supplier Payments</div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.number,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "",
  id: "supplier_payments",
  name: "",
  data: 0,
  onClick() {},
};
