import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackButtonIcon } from "../../assets/images/icons/back-button.svg";
export default function Component(props) {
  const history = useHistory();
  const { routeToPage, pageUrl, backFunc, className } = props;

  function goBackFunction(event) {
    event.preventDefault();
    if (routeToPage) {
      window.location.href = pageUrl;
    } else {
      try {
        history.goBack();
      } catch (e) {
        console.error("Inavlid Routing.Couldn't go back.");
      }
    }
  }

  return (
    <a
      className="cursor-pointer mr-2 btn btn-outline-secondary px-2 py-1"
      href={void 0}
      onClick={goBackFunction}
    >
      {/* <i class="fa fa-arrow-left" aria-hidden="true"></i> */}
      <BackButtonIcon className="w-4 h-4" />
    </a>
  );
}

Component.propTypes = {
  routeToPage: PropTypes.bool,
  pageUrl: PropTypes.string,
  className: PropTypes.string,
  backFunc: PropTypes.func,
};

Component.defaultProps = {
  routeToPage: false,
  pageUrl: "",
  className: "mr-2",
  backFunc: () => {
    return false;
  },
};
