import React, { useState, useEffect } from "react";

import { GoogleLogin } from "react-google-login";
import jwt_decode from "jwt-decode";

// Toaster & Notification
import { resp } from "../../functions/responseHandler";
import { alertBox } from "../../components/notification/alert";

// External Components
import InLineLoader from "../../components/preloader/inLineLoader";
import ForgotPassword from "../../components/login/forgot_password";
import CreatePassword from "../../components/login/create_password";
import SwitchOrg from "../../components/switchOrganization";

// Functions
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";
import { formatUserAccess, formatModuleAccess } from "../../functions/common";

// import "./style.scss";

export default function Login() {
  const google = window.google;

  /////////// VARIABLES DECLARATION ///////////
  const [user, setUser] = useState({});

  const [state, setState] = useState({
    email: "",
    password: "",
    remember_me: false,
    user_data: {},
  });
  // Loaders
  const [isProcessing, setIsProcessing] = useState(false);
  // Forgot Password Modal
  const [showForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const closeForgotPassworModal = () => setForgotPasswordModal(false);
  const openForgotPasswordModal = () => setForgotPasswordModal(true);

  // Create Password Modal
  const [showCreatePasswordModal, setCreatePasswordModal] = useState(false);
  const closeCreatePasswordModal = () => setCreatePasswordModal(false);
  const openCreatePasswordModal = () => setCreatePasswordModal(true);

  // Switch Org Modal
  const [switchOrgModal, setSwitchOrgModal] = useState(false);
  const closeSwitchOrgModal = () => setSwitchOrgModal(false);
  const openSwitchOrgModal = () => setSwitchOrgModal(true);

  /////////// FUNCTIONS DECLARATION ///////////
  // Constructor
  useEffect(() => {
    checkRememberMe();
    // Google Sign-In
    try{
      google.accounts.id.initialize({
        client_id:
          "897958136347-ousf9c5otkhgnrn4gtep5f3s9lqnd2t2.apps.googleusercontent.com",
        callback: handleGoogleCallbackResponse,
      });
  
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: "large",
      });
    }
   catch(e){
    console.log("Initializing google sign in failed.")
   }
  }, []);
  // Checking Remember Me Option

  // Google Sign-In
  function handleGoogleCallbackResponse(response) {
    var userObject = jwt_decode(response.credential);
    console.log(userObject);
    setUser(userObject);
    let query = {
      email: userObject?.email,
      fullname: userObject?.name,
      contact_person: userObject?.name,
      login_type: "google",
      profile_picture: userObject.picture,
    };

    onCommonLogin(query);
  }

  function handleGoogleSignOut() {
    setUser({});
    document.getElementById("signInDiv").hidden = false;
  }

  async function checkRememberMe() {
    let data = await storageServices.getStoredData("login");
    if (data && data?.remember_me) {
      let prevState = { ...state };
      prevState.email = data?.email;
      prevState.password = data?.password;
      prevState.remember_me = data?.remember_me;
      setState(prevState);
    }
  }
  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }
  function onChangeCheckbox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !state.remember_me }));
  }

  // Form Actions

  async function onSubmit(event) {
    event.preventDefault();
    if (state.remember_me && state.email) {
      await storageServices.storeData("login", state);
    } else {
      await localStorage.removeItem("login");
    }
    var query = {
      email: state.email.trim().toLowerCase(),
      password: state.password,
      login_type: "standard",
    };
    onCommonLogin(query);
  }

  async function onCommonLogin(query) {
    setUser(query);
    setIsProcessing(true);
    api
      .commonLoginService(query)
      .then(async (response) => {
        processResponse(response, query);
      })
      .catch((error) => {
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function standardLogin(query) {
    setIsProcessing(true);
    api
      .loginService(query)
      .then(async (response) => {
        if (response.status == 200) {
          processResponse(response, query);
        } else {
          setIsProcessing(false);
          resp.ErrorHandler(response);
        }
      })
      .catch((error) => {
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function processResponse(response, query) {
    console.log("response:", response);
    var data = response.data;
    if (query.login_type === "google" && response.status === 201) {
      setIsProcessing(false);
      document.getElementById("signInDiv").hidden = true;
      return standardLogin(query);
    }

    let authToken = data?.token;
    let userData = data?.data;

    userData["token"] = authToken;

    // To local format
    userData["access"] = await formatUserAccess(userData?.role?.access);
    userData["module_access"] = await formatModuleAccess(
      userData?.role?.module_access
    );
    setState((prevState) => ({ ...prevState, user_data: userData }));

    await storageServices.setCurrentUser(userData);
    setIsProcessing(false);
    // Page Navigation
    // Checking customer_account

    // else if (userData?.customer_account?.activated && !userData.activated) {
    //   openCreatePasswordModal();
    // }

    if (!userData?.customer_account?.approved) {
      alertBox.Show({
        type: "info",
        title: "Getting ready...",
        text: "Your workspace is getting ready.For more contact administrator.",
      });
    } else if (!userData.activated) {
      openCreatePasswordModal();
    } else if (
      userData?.customer_account?.approved &&
      !userData?.customer_account?.activated
    ) {
      navToSetup(userData?.customer_id);
    }
    // Checking User
    else if (userData?.customer_account?.activated && userData.activated) {
      // Selecting Organization
      if (
        userData?.branch_organizations.length > 1 &&
        typeof userData?.preferences?.default_organization === "undefined"
      ) {
        openSwitchOrgModal();
      } else {
        navToDashboard();
      }
    } else {
      alertBox.Show({
        type: "error",
        title: "Account not active",
        text: "Please contact administrator.",
      });
    }
  }
  function navToDashboard() {
    closeCreatePasswordModal();
    window.location.href = "/admin#/home";
  }

  function passwordCreationCallBack() {
    closeCreatePasswordModal();
  }

  function navToPSAdmin() {
    window.location.href = "/ps_admin#/dashboard";
  }

  function navToSetup(org_id) {
    window.location.href = `/setup_wizard/${org_id}`;
  }

  function navToSignup(event) {
    event.preventDefault();
    window.location.href = "/signup";
  }

  function switchOrgCallback() {
    navToDashboard();
  }

  const responseGoogle = (response) => {
    console.log(response);
  };

  return (
    <>
      <div className="">
        <div className="container sm:px-10">
          <div className="block grid-cols-2 gap-4">
            {/* <!-- BEGIN: Login Form --> */}
            {/* <form onSubmit={onSubmit}> */}
            <form className="h-screen flex py-5" onSubmit={onSubmit}>
              <div className="my-auto mx-auto w-full">
                {/* <!-- BEGIN: Logo --> */}
                <div className="flex justify-center items-center py-5">
                  <img
                    alt="CoreLogis"
                    className="w-48"
                    src="/images/logo-main.png"
                  />
                </div>

                {/* <!-- END: Logo --> */}
                <div className="my-auto mx-auto bg-white dark:bg-dark-1 px-5 sm:px-8 py-8 rounded-md shadow-md w-full sm:w-3/4 lg:w-2/4 xl:w-96">
                  <h2 className="intro-x font-bold text-2xl text-center">
                    Sign In
                  </h2>

                  <div className="intro-x mt-8">
                    <input
                      type="text"
                      className="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                      placeholder="Email"
                      name="email"
                      onChange={changeInput}
                      value={state.email}
                      required
                    />
                    <input
                      type="password"
                      className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                      placeholder="Password"
                      name="password"
                      onChange={changeInput}
                      value={state.password}
                      required
                    />
                  </div>
                  <div className="intro-x flex text-gray-700 dark:text-gray-600 sm:text-sm mt-4">
                    <div className="flex items-center mr-auto">
                      <input
                        id="remember-me"
                        type="checkbox"
                        className="form-check-input border mr-2"
                        checked={state.remember_me}
                        name="remember_me"
                        onChange={onChangeCheckbox}
                      />
                      <label
                        className="cursor-pointer select-none"
                        htmlFor="remember-me"
                      >
                        Remember me
                      </label>
                    </div>
                    <a
                      href={void 0}
                      className="cursor-pointer"
                      onClick={openForgotPasswordModal}
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <div className="intro-x mt-5 text-center">
                    <button
                      className="btn btn-primary py-3 px-4 w-full align-top"
                      type="submit"
                    >
                      Login {isProcessing ? <InLineLoader /> : null}
                    </button>

                    <div id="google-signin" className="mt-5 w-full">
                      {/* <GoogleLogin
                        className="w-full text-center"
                        clientId="897958136347-ousf9c5otkhgnrn4gtep5f3s9lqnd2t2.apps.googleusercontent.com"
                        buttonText="Sign In with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        // cookiePolicy={"single_host_origin"}
                      /> */}

                      <div
                        id="signInDiv"
                        className="w-full flex justify-center items-center"
                      ></div>

                      {/* <button onclick="handleSignout">Signout</button> */}
                    </div>
                    {/* <button
                    className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
                    type="button"
                    onClick={navToSignup}
                  >
                    Sign up
                  </button> */}
                  </div>
                </div>
              </div>
            </form>
            {/* </form> */}
            {/* <!-- END: Login Form --> */}

            {/*<!-- BEGIN: External Modals -- > */}

            {/*Forgot Password Modal */}

            {showForgotPasswordModal && (
              <ForgotPassword
                show={showForgotPasswordModal}
                onHide={closeForgotPassworModal}
                callBack={closeForgotPassworModal}
              />
            )}

            {/*Create Password Modal */}

            {showCreatePasswordModal && (
              <CreatePassword
                show={showCreatePasswordModal}
                onHide={closeCreatePasswordModal}
                email={state.email}
                callBack={passwordCreationCallBack}
                action="create_password"
              />
            )}

            {/* Switch Org Modal */}

            {switchOrgModal && (
              <SwitchOrg
                show={switchOrgModal}
                onHide={closeSwitchOrgModal}
                branch_organizations={state?.user_data?.branch_organizations}
                localUserData={state?.user_data}
                callBack={switchOrgCallback}
              />
            )}

            {/*<!-- END: External Modals -- > */}
          </div>
        </div>
      </div>
    </>
  );
}
