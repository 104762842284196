import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Tooltip from "../../../components/htmlElements/tooltip";
import OverdueInvoiceModal from "../../../components/dashboard/overdue_invoice";
// Functions
import { formatCurrency, toFloat } from "../../../functions/common";

const activeClassName = "cursor-pointer";
const inActiveClassName = "";
export default function Component(props) {
  const { className, id, name, onClick, data, enableEdit, ...rest } = props;
  var isActive = false;
  if (!enableEdit && data?.count > 0) isActive = true;

  const [overdueInvoiceModal, setOverdueInvoiceModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });

  useEffect(() => {}, []);

  const openOverdueInvoiceModal = () =>
    setOverdueInvoiceModal((prevState) => ({ ...prevState, show: true }));
  const closeOverdueInvoiceModal = () =>
    setOverdueInvoiceModal((prevState) => ({ ...prevState, show: false }));
  function overdueInvoiceCallback() {}

  function handleOnClick() {
    openOverdueInvoiceModal();
  }

  // function handleOnClick() {
  //   window.location.href = "/admin#/invoices/view/overdue";
  // }

  const TooltipText = (props) => {
    const { data, id } = props;

    return (
      <div class="flex" id={id}>
        <div className="w-24">
          <div class="text-theme-9 dark:text-gray-300 text-lg font-medium">
            {formatCurrency(data?.amount_received)}
          </div>
          <div class="mt-0.5">Received</div>
        </div>
        <div class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-5"></div>
        <div className="w-24">
          <div class="text-theme-6 dark:text-gray-600 text-lg font-medium flex">
            {formatCurrency(toFloat(data?.outstanding_balance, 0))}
          </div>
          <div class="mt-0.5">Balance</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id={id} className={className}>
        {/* <!-- BEGIN: Overdue Invoice Modal --> */}
        {overdueInvoiceModal.show && (
          <OverdueInvoiceModal
            show={overdueInvoiceModal.show}
            onHide={closeOverdueInvoiceModal}
            callBack={overdueInvoiceCallback}
          />
        )}
        {/* <!-- END: Overdue Invoice Modal --> */}

        <div className="flex items-center justify-center w-full h-full">
          {/* <!--BEGIN : RECEIVED --> */}
          <div
            className={isActive ? "text-right cursor-pointer" : "text-right"}
            onClick={isActive ? (e) => handleOnClick(e) : () => {}}
          >
            <div className="dark:text-gray-300 text-lg font-medium">
              {formatCurrency(toFloat(data?.amount_received, 0))}
            </div>
            <div className="truncate  text-gray-600 dark:text-gray-600">
              <small>RECEIVED</small>
            </div>
          </div>
          {/* <!--END : RECEIVED --> */}
          {/* <!--BEGIN : Seperator Line --> */}
          <div className="w-px h-12 border border-r border-dashed border-gray-400 dark:border-dark-5 mx-4 xl:mx-6"></div>
          {/* <!--END : Seperator Line --> */}
          {/* <!--BEGIN : BALANCE --> */}
          <div
            className={isActive ? "cursor-pointer" : ""}
            onClick={isActive ? handleOnClick : () => {}}
          >
            <div className="dark:text-gray-300 text-lg font-medium">
              {formatCurrency(toFloat(data?.outstanding_balance, 0))}
            </div>
            <div className="truncate  text-gray-600 dark:text-gray-600">
              <small>BALANCE</small>
            </div>
          </div>
          {/* <!--END : BALANCE --> */}
        </div>

        <div className="text-center mt-auto">
          <div className="text-base md:text-sm font-medium truncate text-theme-1">
            Overdue Invoices - {data.count}
          </div>
        </div>
      </div>
    </>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  enableEdit: PropTypes.bool,
};

Component.defaultProps = {
  className: "w-full h-full flex flex-col items-center justify-center",
  id: "overdue_invoice_content",
  name: "",
  data: { count: 0 },
  enableEdit: false,
  onClick() {},
};
