export function Model() {
  return {
    // _id: "",
    date: "",
    transaction_type: "",
    transaction_context: "",
    payment_mode: "",
    payment_from_type: "",
    payment_to_type: "",

    payment_cash: {},
    payment_bank: {},
    payment_bank_balance: "",
    payment_bank_name: "",

    cash_balance: "",
    balance: "",

    total_transaction_amount: 0,
    total_debit_amount: 0,
    total_credit_amount: 0,

    transaction_amount: 0,

    payment_reference_number: "",
    input_bank: {},
    input_bank_balance: "",

    advance_id: "",
    advance_status: "",
    remarks: "",
    // Client Details
    client: "",
    total_balance: "",
    total_amount_received: "",

    //Selected advance reference
    advance_reference: "",
    available_advance_amount: "",
    // invoices:[],
    invoices: [],

    // invoices:[],
    selected_invoices: [],
    tags: [],

    // Multi Invoices
    multi_invoices: [],
    multi_invoices_error: "",
    multi_invoices_value: "",

    multi_invoice_debit: 0,
    multi_invoice_credit: 0,
    distribution_type: "",
    is_distribute_equal: false,
    is_distribute_manual: false,
    enablePaymentMode: false,
    enableIndrirectMemo: false,

    indirect_memo: [
      {
        payment_reference_number: "",
        debit: "",
        debit_note: "",
        debit_ref_no: "",
        credit: "",
        credit_note: "",
        credit_ref_no: "",
      },
    ],
  };
}

export function ValidationErrorModel() {
  return {
    client: "",
    invoices: [],
    total_transaction_amount: "",
    available_advance_amount: "",
  };
}
