import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

export default function Component(props) {
  const { className, id, name, onClick, data, ...rest } = props;

  // Chart Data
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },

    // indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 10,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const plugins = {
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
    },
  };

  let chartData = mapData(data);

  function mapData(data) {
    let labels = [];
    let barData = [];
    for (var i in data) {
      labels.push(data[i].client?.name);
      barData.push(data[i].total_amount);
    }

    let chart_data = {
      labels: labels,
      datasets: [
        {
          data: barData,
          barPercentage: 0.5,
          barThickness: 20,
          maxBarThickness: 24,
          minBarLength: 4,
          backgroundColor: ["#23608fa6", "#23608f", "#215d8b"],
          borderWidth: 5,
        },
      ],
    };

    return chart_data;
  }

  return (
    <div id={id} className={className}>
      {/* <!--BEGIN: Chart --> */}
      <div
        style={{
          position: "relative",
          margin: "auto",
          width: "100%",
          height: "100%",
        }}
      >
        <Bar data={chartData} options={options} plugins={plugins} />
      </div>
      {/* <!--END: Chart --> */}

      <div className="mt-auto">
        <div className="text-base md:text-sm font-medium truncate text-theme-1">
          Top Clients
        </div>
      </div>
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  className: "w-full h-full flex flex-col items-center justify-center",
  id: "top_clients_content",
  name: "",
  data: [],
  onClick() {},
};
