// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// LOADERS
import ProcessLoader from "../preloader/processLoader";
// HTML ELEMENTS
import { Modal } from "react-bootstrap";
import CurrencyInput from "../htmlElements/currencyInput";
// FUNCTIONS

import { toFloat, htmlDate, matchData } from "../../functions/common";

const Model = {
  _id: "",
  date: "",
  transaction_type: "",
  transaction_context: "advance",
  payment_mode: "",
  payment_from_type: "",
  payment_to_type: "",
  consignment: "",
  supplier: "",
  client: "",
  transaction_amount: "",
  remarks: "",
  payment_reference_number: "",
  organization: "",
  //Flags
  isNew: false,
  isUpdated: false,
  isDeleted: false,
};

export default function Component(props) {
  // Defaults
  const [pageTitle, setPageTitle] = useState("Advance Payment");
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAction, setUserAction] = useState();
  const [mode, setMode] = useState({
    view: false,
    edit: false,
    create: false,
  });
  // State
  const [state, setState] = useState({ ...Model });

  const [localState, setLocalState] = useState({
    //
    supplier_max_limit: 0,
    client_max_limit: 0,
    disableSupplierPayment: false,
    disableClientPayment: false,
  });

  const [record_id, setRecordId] = useState();

  useEffect(() => {
    handleUserAction();
  }, []);
  // <!--- BEGIN: FUNCTIONS -->
  async function handleUserAction() {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setMode((prevState) => ({ ...prevState, create: true }));
        setPageTitle("Add Advance");
        setDefaults(props);
        break;
      case "edit":
        setMode((prevState) => ({ ...prevState, edit: true }));
        setPageTitle("Edit Advance");
        loadData(props);
        break;
      case "view":
        setMode((prevState) => ({ ...prevState, view: true }));
        setPageTitle("View Advance");
        loadData(props);
        break;
      default:
        setMode((prevState) => ({ ...prevState, edit: true }));
        break;
    }
  }

  function setDefaults(props) {
    setIsProcessing(true);
    let prevState = { ...Model };
    prevState.date = htmlDate(new Date());
    prevState.payment_mode = "cash";
    prevState["isNew"] = true;

    prevState["organization"] = props?.organizationId;
    prevState["client"] = props?.consData?.client?._id;
    prevState["consignment"] = props?.consId;

    prevState["supplier"] = props?.consData?.supplier?._id || "";

    // Enable Supplier Payment
    if (!props?.disableSupplierPayment) {
      prevState.transaction_type = "payable";
      prevState.payment_from_type = "self";
      prevState.payment_to_type = "supplier";
      prevState.transaction_amount = 0;
    }
    // Enable Client Payment
    else if (!props?.disableClientPayment) {
      prevState.transaction_type = "receivable";
      prevState.payment_from_type = "client";
      prevState.payment_to_type = "self";
      prevState.transaction_amount = 0;
    }

    setState(prevState);

    // Setting Local State
    let prevLocalState = { ...localState };
    prevLocalState.supplier_max_limit = toFloat(props?.supplier_max_limit || 0);
    prevLocalState.client_max_limit = toFloat(props?.client_max_limit || 0);
    prevLocalState.disableSupplierPayment = props?.disableSupplierPayment;
    prevLocalState.disableClientPayment = props?.disableClientPayment;
    setLocalState(prevLocalState);

    setIsProcessing(false);
    return null;
  }

  function prepareData(data) {
    return data;
  }
  // <!--- END: FUNCTIONS -->

  // <!--- BEGIN: FORM CHANGE FUNCTIONS -->
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeTypeHeadInput(selected, name) {
    if (selected.length != 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  function changePaymentTo(event, party) {
    let prevState = { ...state };
    if (party === "supplier") {
      prevState.transaction_type = "payable";
      prevState.payment_from_type = "self";
      prevState.payment_to_type = "supplier";
    }

    if (party === "client") {
      prevState.transaction_type = "receivable";
      prevState.payment_from_type = "client";
      prevState.payment_to_type = "self";
    }

    setState(prevState);
  }

  // <!--- END: FORM CHANGE FUNCTIONS -->

  // <!--- BEGIN: API CALLS -->

  async function loadData(props) {
    let data = props?.data;
    setRecordId(props?.index);

    let matchedData = await matchData(state, data);
    setState(matchedData);

    // Setting Local State
    let prevLocalState = { ...localState };
    prevLocalState.supplier_max_limit = toFloat(props?.supplier_max_limit || 0);
    prevLocalState.client_max_limit = toFloat(props?.client_max_limit || 0);
    prevLocalState.disableSupplierPayment = props?.disableSupplierPayment;
    prevLocalState.disableClientPayment = props?.disableClientPayment;
    setLocalState(prevLocalState);
  }

  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);

    var data = prepareData({ ...state });

    props.callBack(data, state?.transaction_type, "create", record_id);
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var data = prepareData({ ...state });
    props.callBack(data, state?.transaction_type, "update", record_id);
  }

  // <!--- END: API CALLS -->

  return (
    <div
      key="consignment_advance_modal"
      id="consignment_advance_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{pageTitle}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            {/* <!--BEGIN: Tab Select --> */}
            {mode?.create && (
              <div className="col-span-12">
                <div
                  className="nav nav-tabs flex-col sm:flex-row justify-center w-full"
                  role="tablist"
                >
                  {" "}
                  <a
                    id="client_payment-tab"
                    data-toggle="tab"
                    data-target="#client_payment"
                    href={void 0}
                    class={
                      state?.payment_from_type === "client" &&
                      state?.payment_to_type === "self"
                        ? "mx-3 text-theme-1 active cursor-pointer"
                        : "mx-3 cursor-pointer"
                    }
                    role="tab"
                    aria-controls="client_payment"
                    aria-selected="false"
                    aria-current={localState?.disableClientPayment}
                    disabled={localState?.disableClientPayment}
                    onClick={(e) =>
                      localState?.disableClientPayment
                        ? e.preventDefault()
                        : changePaymentTo(e, "client")
                    }
                  >
                    Client Advance
                  </a>{" "}
                  <a
                    id="supplier_payment-tab"
                    data-toggle="tab"
                    data-target="#supplier_payment"
                    href={void 0}
                    class={
                      state?.payment_from_type === "self" &&
                      state?.payment_to_type === "supplier"
                        ? "mx-3 text-theme-1 active cursor-pointer"
                        : "mx-3 cursor-pointer"
                    }
                    role="tab"
                    aria-selected="false"
                    aria-current={localState?.disableSupplierPayment}
                    onClick={(e) =>
                      localState?.disableSupplierPayment
                        ? e.preventDefault()
                        : changePaymentTo(e, "supplier")
                    }
                    disabled={localState?.disableSupplierPayment}
                  >
                    Supplier Advance
                  </a>{" "}
                </div>
              </div>
            )}
            {/* <!--END: Tab Select --> */}

            <div className="col-span-12">
              <label htmlFor="name" className="form-label required">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.date}
                defaultValue={htmlDate(new Date())}
                required
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="name" className="form-label required">
                Amount
              </label>
              <CurrencyInput
                type="number"
                className="form-control"
                id="transaction_amount"
                name="transaction_amount"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.transaction_amount}
                required
              />
            </div>
            <div className="col-span-12">
              <div className="mt-3">
                {" "}
                <label>Payment Mode</label>
                <div className="flex flex-col sm:flex-row mt-2">
                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="payment_mode_cash"
                      className="form-check-input"
                      type="radio"
                      name="payment_mode"
                      value="cash"
                      checked={state?.payment_mode === "cash"}
                      onChange={changeInput}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="payment_mode_cash"
                    >
                      Cash
                    </label>{" "}
                  </div>

                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="payment_mode_bank"
                      className="form-check-input"
                      type="radio"
                      name="payment_mode"
                      value="bank"
                      checked={state?.payment_mode === "bank"}
                      onChange={changeInput}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="payment_mode_bank"
                    >
                      Bank
                    </label>{" "}
                  </div>

                  <div className="form-check mr-2">
                    {" "}
                    <input
                      id="payment_mode_journal"
                      className="form-check-input"
                      type="radio"
                      name="payment_mode"
                      value="journal"
                      checked={state?.payment_mode === "journal"}
                      onChange={changeInput}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="payment_mode_journal"
                    >
                      Fuel
                    </label>{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12">
              <label htmlFor="payment_reference_number" className="form-label">
                {state?.payment_mode === "cash"
                  ? "Voucher No."
                  : state.payment_mode === "bank"
                  ? "Transaction Id"
                  : "Reference No."}
              </label>
              <input
                type="text"
                className="form-control"
                id="payment_reference_number"
                name="payment_reference_number"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.payment_reference_number}
              />
            </div>

            <div className="col-span-12">
              <label htmlFor="remarks" className="form-label">
                Remarks
              </label>
              <textarea
                type="text"
                className="form-control"
                id="remarks"
                name="remarks"
                placeholder=""
                disabled={mode.view}
                onChange={changeInput}
                value={state?.remarks}
              />
            </div>

            <input type="submit" id="save_cons_advance" className="hidden" />
            <input type="submit" id="update_cons_advance" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" &&
            (!localState?.disableClientPayment ||
              !localState?.disableSupplierPayment) && (
              <label
                type="button"
                className="btn btn-primary w-20 "
                htmlFor="save_cons_advance"
              >
                Save
              </label>
            )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_cons_advance"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Advance",
  action: "view",
  onSave() {},
  callBack() {},
};
