import React, { useEffect, useState } from "react";
// Components
import { Modal } from "react-bootstrap";
import OnlinePulse from "../../htmlElements/onlinePulse";
// Loaders
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
// const mapStyles = require("./mapStyles.json");

// Functions
import * as api from "../../../api_service/api";
import { htmlDateTime } from "../../../functions/common";

// Constants

// const GOOGLEMAP_API_KEY = process.env.REACT_APP_GOOGLEMAP_KEY;
const GOOGLEMAP_API_KEY = "AIzaSyBfcKNyGB9Z0OxQSrAnWcL_nDzR_ec8xN8";

var count = 1;
var lastPosition = {};
const currentTime = new Date();

const ErrorHTML = () => {
  return <div className="font-medium">Oops! Something went wrong.</div>;
};

const NoDataHTML = () => {
  return <div className="font-medium">No Data Available.</div>;
};

const MarkerInfoHTML = (props) => {
  const { consignment_data, location_data, ...data } = props?.data;
  return (
    <div className="">
      <div className="">
        <div className="flex text-theme-1 font-bold font-medium">
          {data?.in_transit && <OnlinePulse className="mr-1" />}
          {data?.registration_number}
        </div>
      </div>
      <div className="mt-2">
        Last Updated Time :{" "}
        <p className="flex font-bold">
          {htmlDateTime(location_data?.gps_datetime)}
        </p>
      </div>
      <div className="mt-2">
        Location : <p className="font-bold">{location_data?.address}</p>
      </div>

      {data?.in_transit ? (
        <>
          <p className="my-2">Trip Details:</p>
          {consignment_data.map((item, i) => {
            return (
              <div className="box p-2 shadow text-center">
                <a
                  className="text-theme-1 font-medium"
                  href={`/admin#/consignment/view/edit/${item?._id}`}
                  target="_blank"
                >
                  {item?.consignment_id}
                </a>
                <div className="mt-2">
                  <p className="font-bold">{item?.origin?.name}</p>
                  <p>
                    <i
                      className="fa fa-arrow-circle-down my-2 text-gray-500 text-center"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <p className="font-bold">{item?.destination?.name}</p>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

const Map = ({ vehicle }) => {
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [lastPosition, setLastPosition] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState();
  const [activeMarker, setActiveMarker] = useState();
  const [gpsData, setGpsData] = useState([]);
  const [progress, setProgress] = useState([]);

  const [state, setState] = useState({
    total_distance: 0,
    distance_covered: 0,
    expected_arrival: "",
    isTripCompleted: false,
    initial_position: { lat: "", lng: "" },
    final_position: { lat: "", lng: "" },
  });

  // const [count, setCount] = useState(1);
  const path = [];

  function handleActiveMarker(marker) {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  }

  function reloadData() {
    loadData();
  }
  async function loadData() {
    setIsProcessing(true);

    await api
      .postService(`dashboard/get_vehicle_current_position`)
      .then(async (response) => {
        const data = response.data;
        console.log("data:",data)
        // Checking If Data Exists
        if (data.data && data.data.length > 0) {
          let recData = await mapData(data.data);
          lastPosition = recData[recData.length - 1]["location_data"];
          setDefaultCenter({ lat: lastPosition.lat, lng: lastPosition.lng });
          setGpsData(recData);
        } else {
          setNoData(true);
        }
        setIsProcessing(false);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);

        setError(true);
        setIsProcessing(false);
        setIsLoaded(true);
      });
  }

  function mapData(data) {
    for (var i in data) {
      // Checking for InTransit Consignments
      if (data[i]["consignment_data"].length > 0) {
        data[i]["in_transit"] = true;
      } else {
        data[i]["in_transit"] = false;
      }

      // Re-assigning keys
      data[i]["location_data"]["lat"] = data[i]["location_data"]["latitude"];
      data[i]["location_data"]["lng"] = data[i]["location_data"]["longitude"];
      data[i]["location_data"]["time"] =
        data[i]["location_data"]["gps_datetime"];
      data[i]["location_data"]["address"] =
        data[i]["location_data"]["addressLineOne"];
    }
    return data;
  }

  const getDistance = (velocity = 30) => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - currentTime) / 8000; // pass to seconds
    return differentInTime * velocity;
  };

  function startTracking() {
    const interval = window.setInterval(moveObject, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }

  function moveObject() {
    const distance = getDistance();
    if (!distance) {
      return;
    }

    let progressData = gpsData.filter(
      (coordinates) => coordinates.distance < distance
    );

    const nextLine = gpsData.find(
      (coordinates) => coordinates.distance > distance
    );
    if (!nextLine) {
      setProgress(progressData);
      return; // it's the end!
    }
    const lastLine = progressData[progressData.length - 1];

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    progressData = progressData.concat(position);
    setProgress(progressData);

    // Calculating Angle

    const angle = window.google.maps.geometry.spherical.computeHeading(
      lastLineLatLng,
      nextLineLatLng
    );

    // const actualAngle = angle - 35;
    const actualAngle = angle;

    const markerUrl = "/images/truck-top.png";
    const item = document.querySelector(`[src="${markerUrl}"]`);

    if (item) {
      // when it hasn't loaded, it's null
      item.style.transform = `rotate(${actualAngle}deg)`;
    }
  }

  useEffect(() => {
    // const interval = window.setInterval(moveObject, 1000);
    // return () => {
    //   window.clearInterval(interval);
    // };
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const activeVehicleIcon = {
    url: "/images/truck-top.png",
    scaledSize: new window.google.maps.Size(30, 30),
    anchor: { x: 10, y: 10 },
  };
  const inActiveVehicleIcon = {
    url: "/images/truck-top.png",
    scaledSize: new window.google.maps.Size(30, 30),
    anchor: { x: 10, y: 10 },
  };

  if (error) {
    return <ErrorHTML />;
  } else {
    return (
      <>
        {/* {isProcessing && <ProcessLoader />} */}
        {noData ? (
          <NoDataHTML />
        ) : isLoaded ? (
          error ? (
            <ErrorHTML />
          ) : (
            <>
              <GoogleMap
                defaultZoom={12}
                // defaultCenter={path[path.length - 1]}
                // defaultCenter={{
                //   lat: path[path.length - 1]?.lat,
                //   lng: path[path.length - 1]?.lng,
                // }}
                defaultCenter={defaultCenter}
                // defaultOptions={{
                //   // styles: mapStyles,
                //   fullscreenControl: false,
                //   mapTypeControl: false,
                //   streetViewControl: false,
                // }}
              >
                {/* {progress && ( */}
                <>
                  {/* <!--BEGIN : Truck Marker --> */}
                  {gpsData.map((item, i) => {
                    return (
                      <Marker
                        icon={
                          item?.in_transit
                            ? activeVehicleIcon
                            : inActiveVehicleIcon
                        }
                        position={item["location_data"]}
                        onClick={(e) => handleActiveMarker(item._id)}
                        id={item._id}
                        key={item._id}
                        className="animate-ping bg-green-400"
                      >
                        {activeMarker === item._id ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                          >
                            <MarkerInfoHTML data={item} />
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    );
                  })}

                  {/* <!--END : Truck Marker --> */}
                </>
                {/* )} */}
              </GoogleMap>
            </>
          )
        ) : (
          ""
        )}
      </>
    );
  }
};

const MapComponent = withScriptjs(withGoogleMap(Map));

export default function View(props) {
  return (
    <>
      <div
        key="vehicle_current_position"
        id="vehicle_current_position"
        onClick={(e) => e.stopPropagation()}
        className="flex flex-grow"
      >
        <MapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAP_API_KEY}&v=3.exp&libraries=geometry,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          // containerElement={<div style={{ height: `35vh`, width: "100%" }} />}
          containerElement={
            <div
              style={{
                position: "relative",
                margin: "auto",
                width: "100%",
                height: "100%",
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    </>
  );
}
