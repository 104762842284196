import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import DateRangePicker from "react-bootstrap-daterangepicker";

// Components
import TruckNumberDisplay from "../../components/consignment/truckNumberDisplay";
import DriversInList from "../../components/consignment/driversInList";
import ClientsInList from "../../components/consignment/clientsInList";

// Typeahead Component
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

// Loaders
import ProcessLoader from "../../components/preloader/processLoader";

// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { localDate, isDefined } from "../../functions/common";

// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";

const Model = {
  status: "waiting_for_approval",
  client: [],
  vehicle: [],
  total: {},
  consignment_date: { startDate: "", endDate: "" },
  overdue_days: { operator: "gt", value: "" },
  overdue_days_operator: "gt",
  overdue_days_value: "",
};

const StatusList = [
  {
    value: "waiting_for_approval",
    label: "Prepared",
  },
  {
    value: "invoiced",
    label: "Dispatch",
  },
  {
    value: "submitted",
    label: "Submitted",
  },
  {
    value: "closed",
    label: "Closed",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "overdue",
    label: "Overdue",
  },
];

const AmountQuickActionList = [
  {
    id: "less-than-10k",
    label: "Under ₹10,0000",
    min: 0,
    max: 10000,
  },
  {
    id: "10k-50k",
    label: "₹10,000 - ₹50,000",
    min: 10000,
    max: 50000,
  },
  {
    id: "50k-1L",
    label: "₹50,000 to ₹1Lakh",
    min: 50000,
    max: 100000,
  },
  {
    id: ">1L",
    label: "Over ₹1Lakh",
    min: 100000,
    max: 99999999999,
  },
];

const OverdueQuickActionList = [
  {
    id: "greater-than-15-days",
    label: "> 15 days",
    operator: "gt",
    value: 15,
  },
  {
    id: "greater-than-1-month",
    label: "> 1 month",
    operator: "gt",
    value: 30,
  },
  {
    id: "greater-than-3-months",
    label: "> 3 months",
    operator: "gt",
    value: 90,
  },
  {
    id: "greater-than-100-days",
    label: "> 100 days",
    operator: "gt",
    value: 100,
  },
];

export default function Component(props) {
  const { id, className, filters, onChangeFilters, loadData, status, ...rest } =
    props;

  var api_load_count = { client: 0, vehicle: 0, driver: 0, supplier: 0 };

  // Loaders
  const [isProcessing, setIsProcessing] = useState(false);

  const [state, setState] = useState(Model);

  const [defaultState, setDefaultState] = useState({
    supplier: [],
    vehicle: [],
    client: [],
    driver: [],
  });

  useEffect(() => {}, []);

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeOverdueDaysInput(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState[name] = value;
    if (name === "overdue_days_operator") {
      prevState["overdue_days"] = {
        [name]: prevState["overdue_days_value"] || "",
      };
    }
    if (name === "overdue_days_value") {
      prevState["overdue_days"] = {
        [prevState["overdue_days_operator"]]: value,
      };
    }

    setState(prevState);
  }

  function changeInvoiceAmount(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState["total"][name] = value;
    setState(prevState);
  }

  function changeInvoiceAmount(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState["total"][name] = value;
    setState(prevState);
  }

  function onStatusChange(value) {
    let prevState = { ...state };
    prevState["status"] = value;
    setState(prevState);
    reloadData({ ...prevState });
  }

  function reloadData(filters) {
    // if (filters.status === "overdue") {
    //   if (
    //     isDefined(filters.overdue_days["value"]) &&
    //     filters.overdue_days["value"] > 0
    //   ) {
    //     filters["overdue_days"] = filters["overdue_days"];
    //   } else {
    //     delete filters["overdue_days"];
    //   }
    // }
    return loadData(filters);
  }

  function onAmountFilterSubmit(event) {
    event.preventDefault();
    reloadData({ ...state });
  }
  function onOverdueDaysSubmit(event) {
    event.preventDefault();
    reloadData({ ...state });
  }

  //   <!BEGIN: Typeahead Functions - Field Filters -->

  // Input Change
  function changeTypeHeadInputMultiple(selected, name) {
    setState((prevState) => ({ ...prevState, [name]: selected }));
    // Getting All Id's
    let IdArray = [];
    for (var i in selected) {
      IdArray.push(selected[i]["_id"]);
    }
    let filterCopy = { ...state };
    filterCopy[name] = IdArray;
    reloadData({ ...filterCopy });
  }

  async function loadDefaultMetrics(event, key, reload = false) {
    let maxReloadLimit = 4;

    try {
      event.preventDefault();
    } catch (e) {}

    let url = "";

    if (defaultState[key].length === 0 || reload) {
      switch (key) {
        case "client":
          url = "client/status/active";
          await fetchDefaultMetrics(url, key);
          break;
        case "vehicle":
          url = "vehicle/all/all";
          await fetchDefaultMetrics(url, key);
          break;
        default:
          break;
      }
    }
  }

  function mapDefaultStateData(key, data) {
    // Re-constructing Vehicle Data
    if (key === "vehicle") {
      for (var i in data) {
        data[i]["vehicleType"] = data[i]?.vehicle_type?.vehicle_type || "";
      }
    }
    return data;
  }

  async function fetchDefaultMetrics(url, key) {
    setIsProcessing(true);
    await api
      .getService(url)
      .then(async (response) => {
        const data = response?.data?.data;
        let mappedData = await mapDefaultStateData(key, data);
        await setDefaultState((prevState) => ({
          ...prevState,
          [key]: mappedData,
        }));
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }
  //   <!END: Typeahead Functions - Field Filters -->
  //   Unit Components
  const StageRadioButton = (props) => {
    const { id, active, value, label } = props;

    var icon = active ? (
      <i class="fa fa-check-circle-o mr-2" aria-hidden="true"></i>
    ) : (
      <i class="fa fa-circle-thin mr-2" aria-hidden="true"></i>
    );

    var className = active
      ? "btn btn-success-soft w-24 py-1.5 px-1 flex items-center justify-start"
      : "btn btn-secondary-soft w-24 p-1 py-1.5 px-1 flex items-center justify-start";
    return (
      <button
        id={id}
        className={className}
        onClick={(e) => {
          onStatusChange(value);
        }}
      >
        {icon}
        {label}
      </button>
    );
  };

  function onSetFrieghtAmount(minAmount, maxAmount) {
    let obj = { minAmount: minAmount, maxAmount: maxAmount };
    let prevState = { ...state };
    prevState["total"]["minAmount"] = minAmount;
    prevState["total"]["maxAmount"] = maxAmount;
    setState(prevState);
    reloadData({ ...prevState });
  }

  function onSetOverdueDays(operator, value) {
    let obj = { [operator]: value };
    let prevState = { ...state };
    prevState["overdue_days"] = obj;
    setState(prevState);
    reloadData({ ...prevState });
  }

  const AmountQuickAction = (props) => {
    const { id, min, max, label } = props;

    return (
      <a
        href={void 0}
        id={id}
        className="cursor-pointer mb-2 text-gray-700"
        onClick={(e) => {
          onSetFrieghtAmount(min, max);
        }}
      >
        <i class="fa fa-circle mr-2 text-gray-400" aria-hidden="true"></i>
        {label}
      </a>
    );
  };

  const OverdueQuickAction = (props) => {
    const { id, operator, value, label } = props;

    return (
      <a
        href={void 0}
        id={id}
        className="cursor-pointer mb-2 text-gray-700"
        onClick={(e) => {
          onSetOverdueDays(operator, value);
        }}
      >
        {label}
      </a>
    );
  };

  function handleDatePickerApply(event, picker) {
    var startDate = picker.startDate;
    var endDate = picker.endDate;
    let prevState = { ...state };
    prevState["consignment_date"]["startDate"] = startDate;
    prevState["consignment_date"]["endDate"] = endDate;
    setState(prevState);
    reloadData(prevState);
  }

  function toDateString(data) {
    const { startDate, endDate } = data;

    let response = "";
    if (startDate && endDate) {
      response = localDate(startDate) + " - " + localDate(endDate);
    }
    return response;
  }
  return (
    <div className="box p-2" id={id}>
      {isProcessing && <ProcessLoader />}

      {/* <!--BEGIN: Status List --> */}
      <div
        id="stage-filter"
        // class="flex flex-wrap space-x-2 justify-center items-center"
        class="flex flex-wrap gap-2 justify-center py-2"
      >
        {StatusList.map((item, index) => {
          return (
            <StageRadioButton
              key={item?.value}
              id={item?.value}
              value={item?.value}
              label={item?.label}
              active={state.status === item?.value}
            />
          );
        })}
      </div>
      {/* <!--END: Status List --> */}

      {/* <!--BEGIN: Date Selector --> */}
      <div
        id="date-filter"
        className="border-t border-gray-200 dark:border-dark-5 mt-2 py-2"
      >
        <h3 className="font-medium mb-1">Cons. Date</h3>

        <DateRangePicker
          initialSettings={{
            autoUpdateInput: false,
            locale: {
              cancelLabel: "Clear",
            },
          }}
          onApply={handleDatePickerApply}
          //   onApply={handleApply}
          //   onCancel={handleCancel}
        >
          <input
            type="text"
            className="form-control"
            value={toDateString(state?.consignment_date)}
          />
        </DateRangePicker>
      </div>
      {/* <!--END: Date Selector --> */}

      {state.status === "overdue" && (
        <>
          {/* <!--BEGIN: Overdue Days Selector --> */}
          <div
            id="overdue-filter"
            className="border-t border-gray-200 dark:border-dark-5 mt-2 py-2"
          >
            <h3 className="font-medium mb-1">Overdue Days</h3>

            <div id="overdue-custom-fields" className="flex flex-col mt-2">
              {OverdueQuickActionList.map((item, index) => {
                return <OverdueQuickAction key={item?.id + index} {...item} />;
              })}

              {/* <form
                onSubmit={onOverdueDaysSubmit}
                id="overdue-days-form"
                className="flex space-x-1"
              >
                <div className="input-group">
                  <div id={id} className="input-group-text p-0">
                    <select
                      id="overdue_days_operator"
                      name="overdue_days_operator"
                      value={state?.overdue_days_operator}
                      onChange={changeOverdueDaysInput}
                    >
                      <option id="greater-than" value="gt">
                        {">"}
                      </option>
                      <option id="lesser-than" value="lt">
                        {"<"}
                      </option>
                    </select>
                  </div>
                  <input
                    className={"form-control"}
                    id={id}
                    name={"overdue_days_value"}
                    onChange={changeOverdueDaysInput}
                    value={state?.overdue_days_value}
                    placeholder={""}
                    required={true}
                  />
                </div>
                <button type="submit" className="btn btn-secondary">
                  Go
                </button>
              </form> */}
            </div>
          </div>
          {/* <!--END: Amount Selector --> */}
        </>
      )}

      {/* <!--BEGIN: Amount Selector --> */}
      <div
        id="amount-filter"
        className="border-t border-gray-200 dark:border-dark-5 mt-2 py-2"
      >
        <h3 className="font-medium mb-1">Invoice Amount</h3>

        <div id="custom-fields" className="flex flex-col mt-2">
          {AmountQuickActionList.map((item, index) => {
            return (
              <AmountQuickAction
                id={item?.id}
                label={item?.label}
                min={item?.min}
                max={item?.max}
              />
            );
          })}

          <form onSubmit={onAmountFilterSubmit} className="flex space-x-1">
            <input
              className="form-control"
              placeholder="₹ Min"
              value={state?.total?.minAmount}
              name="minAmount"
              onChange={changeInvoiceAmount}
              required={true}
            />
            <input
              className="form-control"
              placeholder="₹ Max"
              value={state?.total?.maxAmount}
              name="maxAmount"
              onChange={changeInvoiceAmount}
              required={true}
            />
            <button type="submit" className="btn btn-secondary">
              Go
            </button>
          </form>
        </div>
      </div>
      {/* <!--END: Amount Selector --> */}

      {/* <!--BEGIN: Field Selector --> */}
      <div
        id="field-filter"
        className="border-t border-gray-200 dark:border-dark-5 mt-2 py-2"
      >
        <div id="client-filter" className="">
          <h3 className="font-medium mb-1">Client</h3>
          <Typeahead
            multiple
            clearButton
            id="client"
            name="client"
            key="client"
            filterBy={["name", "contact_number"]}
            onFocus={(event) => {
              loadDefaultMetrics(event, "client");
            }}
            onChange={(selected) => {
              changeTypeHeadInputMultiple(selected, "client");
            }}
            options={defaultState?.client}
            labelKey={"name"}
            selected={state?.client}
            placeholder="Filter client"
            renderMenu={(results, menuProps) => (
              <Menu {...menuProps}>
                {results.map((result, index) => (
                  <MenuItem option={result} position={index} key={index}>
                    <ClientsInList
                      data={result}
                      id={"client_" + index}
                      display={["phone"]}
                    />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
        </div>

        <div id="vehicle-filter" className="mt-1">
          <h3 className="font-medium mb-1">Vehicle</h3>
          <Typeahead
            multiple
            clearButton
            id="vehicle"
            name="vehicle"
            key="vehicle"
            filterBy={["registration_number", "vehicleType", "ownership_type"]}
            onFocus={(event) => {
              loadDefaultMetrics(event, "vehicle");
            }}
            onChange={(selected) => {
              changeTypeHeadInputMultiple(selected, "vehicle");
            }}
            options={defaultState?.vehicle}
            labelKey={"registration_number"}
            selected={state?.vehicle}
            placeholder="Filter vehicle"
            renderMenu={(results, menuProps) => (
              <Menu {...menuProps}>
                {results.map((result, index) => (
                  <MenuItem option={result} position={index} key={index}>
                    <TruckNumberDisplay
                      data={result}
                      key={"vehicle_" + result?._id}
                      id={"vehicle_" + result?._id}
                      hideOwnership={true}
                    />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
        </div>
      </div>
      {/* <!--BEGIN: Fiel Selector --> */}
    </div>
  );
}

Component.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  loadData: PropTypes.func,
};

Component.defaultProps = {
  id: "consignment-filter",
  className: "",
  loadData: () => {},
};
