import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";
import DividerText from "../htmlElements/dividerText";
import PhoneInput from "../htmlElements/phoneInput";
import { Model } from "./model";

import { formatTypeaheadCommon, matchData } from "../../functions/common";

// Typeahead Component
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

const countryListFile = require("../../utils/country_state_list.json");
const countryStateList = countryListFile.countries;
// const countryList = countryStateList.map((a) => a.country);
const countryList = ["India"];

export default function Component(props) {
  // Modes
  const [isProcessing, setIsProcessing] = useState(false);

  const [viewMode, setViewMode] = useState(false);
  const [userAction, setUserAction] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  const [localUser, setLocalUser] = useState();
  // Default State
  const [defaultState, setDefaultState] = useState({
    state: [],
    country: countryList,
  });

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction();
  }, []);

  async function setDefaults() {
    const defaultCountry = "India";
    setState((prevState) => ({
      ...prevState,
      country: defaultCountry,
    }));

    let obj = await countryStateList.find((o) => o.country === defaultCountry);

    if (typeof obj !== "undefined" && obj.states.length > 0) {
      setDefaultState((prevState) => ({
        ...prevState,
        state: obj.states,
      }));
    }
  }

  async function handleUserAction() {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setState({ ...Model });
        setDefaults();
        setViewMode(false);
        break;
      case "edit":
        setViewMode(false);
        loadDataById(props.id);
        break;
      case "view":
        setViewMode(true);
        loadDataById(props.id);
        break;
      default:
        setViewMode(true);
    }
  }

  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    api.getService(`organization/${id}`).then(
      async (result) => {
        const data = result.data;
        let mappedData = await mapData(data.data);

        setState(mappedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  async function mapData(data) {
    let matchedData = matchData(state, data);

    // Setting States List
    let obj = await countryStateList.find((o) => o.country === data.country);
    if (typeof obj !== "undefined" && obj.states.length > 0) {
      setDefaultState((prevState) => ({
        ...prevState,
        state: obj.states,
      }));
    }

    return matchedData;
  }

  function prepareData(data) {
    data["type"] = data?.type?._id;
    return data;
  }
  async function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });

    api
      .postService("organization", query)
      .then(async (response) => {
        console.log("response:", response);
        let newData = response?.data?.data;
        if (localUser?.role?.is_owner) {
          await saveBranchInStorage(newData);
        }

        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack(newData);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function saveBranchInStorage(branchData) {
    let localUserData = await storageServices.getCurrentUser();
    localUserData.branch_organizations.push(branchData);
    await storageServices.setCurrentUser(localUserData);
    return null;
  }

  async function updateBranchInStorage(branchData) {
    let localUserData = await storageServices.getCurrentUser();

    let objIndex = await localUserData.branch_organizations.findIndex(
      (obj) => obj._id === branchData._id
    );
    if (objIndex) {
      localUserData.branch_organizations[objIndex] = branchData;
    }

    await storageServices.setCurrentUser(localUserData);
    return null;
  }

  async function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = prepareData({ ...state });
    api
      .putService(`organization/${record_id}`, query)
      .then(async (response) => {
        let newData = response?.data?.data;
        await updateBranchInStorage(newData);
        setIsProcessing(false);
        resp.Success(response?.data?.message);

        props.callBack(newData);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function changeInput(event) {
    const { name, value, checked } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "application_user") {
      setState((prevState) => ({ ...prevState, [name]: checked }));
    }
  }

  function changeCheckBox(event) {
    const { name } = event.target;

    let prevState = { ...state };
    let value = !state[name];
    prevState[name] = value;

    if (value) {
      prevState.phone = localUser?.customer_account?.phone;
      prevState.email = localUser?.customer_account?.email;
      prevState.gst = localUser?.customer_account?.gst;
      prevState.pan_number = localUser?.customer_account?.pan_number;
      setState(prevState);
    } else {
      prevState.phone = "";
      prevState.email = "";
      prevState.gst = "";
      prevState.pan_number = "";
      setState(prevState);
    }
  }

  // Input Change
  async function changeTypeHeadInput(selected, name) {
    if (selected.length !== 0) {
      setState((prevState) => ({ ...prevState, [name]: selected[0] }));

      // ------ Loading States -------
      if (name === "country") {
        let obj = await countryStateList.find((o) => o.country === selected[0]);
        if (typeof obj !== "undefined" && obj.states.length > 0) {
          setDefaultState((prevState) => ({
            ...prevState,
            state: obj.states,
          }));
        }
      }
      // ------ Loading States -------
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  }

  // UI
  return (
    // <div onClick={(e) => e.stopPropagation()}>
    // <>
    <div
      key="branch_modal"
      id="branch_modal"
      onClick={(e) => e.stopPropagation()}
    >
      {/* <!-- BEGIN: Modal Content --> */}
      <Modal
        {...props}
        size="lg"
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            className="grid grid-cols-12 gap-4 gap-y-3"
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            <div className="col-span-12 sm:col-span-12">
              <label htmlFor="name" className="form-label required">
                Branch Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.name}
                required
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="address_line_1" className="form-label required">
                Address Line 1
              </label>
              <input
                type="text"
                className="form-control"
                id="address_line_1"
                name="address_line_1"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.address_line_1}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="address_line_2" className="form-label">
                Address Line 2
              </label>
              <input
                type="text"
                className="form-control"
                id="address_line_2"
                name="address_line_2"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.address_line_2}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="city" className="form-label required">
                City
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.city}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="state" className="form-label required">
                State
              </label>
              <Typeahead
                id="city"
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "state");
                }}
                options={defaultState.state}
                labelKey={(option) => `${option}`}
                selected={formatTypeaheadCommon(state?.state)}
                inputProps={{ required: true }}
                disabled={viewMode}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="country" className="form-label required">
                Country
              </label>
              <Typeahead
                id="country"
                onChange={(selected) => {
                  changeTypeHeadInput(selected, "country");
                }}
                options={defaultState.country}
                labelKey={(option) => `${option}`}
                selected={formatTypeaheadCommon(state?.country)}
                inputProps={{ required: true }}
                disabled={viewMode}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="zip_code" className="form-label">
                Pin Code
              </label>
              <input
                type="number"
                className="form-control"
                id="zip_code"
                name="zip_code"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.zip_code}
              />
            </div>

            <div className="col-span-12">
              <DividerText>Other Details</DividerText>
            </div>

            {!viewMode && (
              <div className="col-span-12">
                <div className="w-full mx-auto flex text-center">
                  <div className="form-check">
                    <input
                      id="use_parent"
                      className="form-check-input"
                      type="checkbox"
                      name="use_parent"
                      onChange={changeCheckBox}
                      checked={state.use_parent}
                      disabled={viewMode}
                    />
                    <label className="form-check-label" htmlFor="use_parent">
                      Use Parent Details
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="contact_person" className="form-label">
                Primary Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.email}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="contact_person" className="form-label">
                Phone
              </label>
              <PhoneInput
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.phone}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="contact_person" className="form-label">
                GST
              </label>
              <input
                type="text"
                className="form-control"
                id="gst"
                name="gst"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.gst}
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="contact_person" className="form-label">
                PAN
              </label>
              <input
                type="text"
                className="form-control"
                id="pan_number"
                name="pan_number"
                placeholder=""
                disabled={viewMode}
                onChange={changeInput}
                value={state.pan_number}
              />
            </div>

            <input type="submit" id="save_branch" className="hidden" />
            <input type="submit" id="update_branch" className="hidden" />
          </form>
        </Modal.Body>
        {/* <!-- END: Modal Body --> */}
        {/* <!-- BEGIN: Modal Footer --> */}
        <Modal.Footer>
          {" "}
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-outline-secondary w-20 mr-1"
            onClick={props.onHide}
          >
            Cancel
          </button>{" "}
          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="save_branch"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary w-20 "
              htmlFor="update_branch"
            >
              Update
            </label>
          )}
        </Modal.Footer>
        {/* <!-- END: Modal Footer --> */}
      </Modal>

      {/* <!-- END: Modal Content --> */}
    </div>
    // </>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Branch",
  action: "view",
  onSave() {},
  callBack() {},
};
