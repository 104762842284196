import React from "react";
import PropTypes from "prop-types";
export default function ValidationError(props) {
  const { msg } = props;
  return (
    <>
      {msg?.length > 0 && (
        <div className="error text-theme-6 intro-y  mt-2">{msg}</div>
      )}
    </>
  );
}

ValidationError.propTypes = {
  msg: PropTypes.string,
  notify: PropTypes.bool,
};

ValidationError.defaultProps = {
  msg: "",
  notify: false,
};
