import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";
import DividerText from "../htmlElements/dividerText";
import EditButton from "../htmlElements/editButton";

import { storageServices } from "../../functions/storage";
import { matchData } from "../../functions/common";

const _ = require("lodash");

const Model = {
  name: "",
  access: [],
  module_access: [],
  page_access: [],
  status: "active",
  is_admin: false,
  is_owner: false,
};

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [userAction, setUserAction] = useState();
  const [pageTitle, setPageTitle] = useState();
  // State
  const [state, setState] = useState({ ...Model });
  const [record_id, setRecordId] = useState();
  const [defaultState, setDefaultState] = useState({
    user_access: [],
    module_access: [],
  });
  const [localUser, setLocalUser] = useState();
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();

    handleUserAction(props);
  }, []);
  async function handleUserAction(props) {
    setUserAction(props.action);
    switch (props.action) {
      case "create":
        setPageTitle("Create Role");
        setState(Model);
        setViewMode(false);
        break;
      case "edit":
        setPageTitle("Edit Role");
        setViewMode(false);
        await loadDataById(props.id);
        break;
      case "view":
        setPageTitle("View Role");
        setViewMode(true);
        await loadDataById(props.id);

        break;
      default:
        setViewMode(true);
    }
    await loadDefaultMetrics();
  }

  function enableEdit() {
    setUserAction("edit");
    setPageTitle("Edit Role");
    setViewMode(false);
  }

  async function loadDataById(id) {
    setRecordId(id);
    setIsProcessing(true);
    await api.getService(`user_role/${id}`).then(
      async (result) => {
        console.log("result:", result);
        const data = result.data;
        let matchedData = await matchData(state, data.data);
        await setState(matchedData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
      }
    );
  }
  function onSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .postService("user_role", query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function onUpdate(event) {
    event.preventDefault();
    setIsProcessing(true);
    var query = { ...state };
    api
      .putService(`user_role/${record_id}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        props.callBack();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  async function loadDefaultMetrics() {
    if (defaultState.user_access.length == 0) {
      setIsProcessing(true);
      var query = { metrix: ["user_access", "module_access"] };
      await api
        .postService("common", query)
        .then(async (response) => {
          console.log("response:", response);
          await setDefaultState((prevState) => ({
            ...prevState,
            user_access: response?.data?.user_access?.value,
            module_access: response?.data?.module_access?.value,
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    }
  }

  async function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    const boolValue = !state[name];
    if (name == "is_admin" || name == "is_owner") {
      setState((prevState) => ({ ...prevState, [name]: boolValue }));
    }

    // AutoFill function
    switch (name) {
      case "is_admin":
        let adminAccessList = ["user_management", "branch_management"];
        var accessArray = [...state.module_access];
        if (boolValue) {
          accessArray = accessArray.concat(adminAccessList);
        } else {
          // Removing elements
          accessArray = await accessArray.filter(
            (el) => !adminAccessList.includes(el)
          );
        }
        setState((prevState) => ({
          ...prevState,
          module_access: accessArray,
        }));
        break;

      default:
        break;
    }
  }

  async function changeUserAccess(event, parent) {
    const { name, value, checked } = event.target;
    const access = state.access;
    let index;
    if (checked) {
      access.push(value);
    } else {
      index = access.indexOf(value);
      access.splice(index, 1);
    }
    setState((prevState) => ({ ...prevState, access: access }));

    // AutoFilling Module Access
    var module_access = [...state.module_access];

    switch (parent) {
      case "consignment":
        let consArrayElements = [
          "create_consignment",
          "approve_consignment",
          "acknowledge_consignment",
          "delete_consignment",
          "create_transits",
        ];

        if (checked) {
          if (!module_access.includes("consignments")) {
            module_access.push("consignments");
          }
        } else {
          // Checking for other values in access array
          let found = await consArrayElements.some((item) =>
            access.includes(item)
          );
          if (found) {
            if (!module_access.includes("consignments")) {
              module_access.push("consignments");
            }
          } else {
            // Removing Consignment from module access
            module_access = _.pull(module_access, "consignments");
          }
        }

        setState((prevState) => ({
          ...prevState,
          module_access: module_access,
        }));
        break;

      case "invoice":
        let invoiceArrayElements = [
          "create_invoice",
          "approve_invoice",
          "generate_invoice",
          "sumbit_invoice",
          "cancel_invoice",
        ];

        if (checked) {
          if (!module_access.includes("invoices")) {
            module_access.push("invoices");
          }
        } else {
          // Checking for other values in access array
          let found = await invoiceArrayElements.some((item) =>
            access.includes(item)
          );
          if (found) {
            if (!module_access.includes("invoices")) {
              module_access.push("invoices");
            }
          } else {
            // Removing Consignment from module access
            module_access = _.pull(module_access, "invoices");
          }
        }

        setState((prevState) => ({
          ...prevState,
          module_access: module_access,
        }));
        break;

      case "master":
        let masterArrayElements = ["approve_master"];
        if (checked) {
          if (!module_access.includes("master")) {
            module_access.push("master");
          }
        }

        setState((prevState) => ({
          ...prevState,
          module_access: module_access,
        }));
        break;

      default:
        break;
    }
  }

  async function changeModuleAccess(event) {
    const { name, value, checked } = event.target;
    const module_access = [...state.module_access];
    let index;
    if (checked) {
      module_access.push(value);
    } else {
      index = module_access.indexOf(value);
      module_access.splice(index, 1);
    }
    setState((prevState) => ({ ...prevState, module_access: module_access }));

    // AUTOFILL

    let enableAdmin = false;
    if (
      module_access.includes("user_management") &&
      module_access.includes("branch_management")
    ) {
      enableAdmin = true;
    } else {
      enableAdmin = false;
    }

    switch (name) {
      case "user_management":
        if (checked) {
          if (enableAdmin) {
            setState((prevState) => ({ ...prevState, is_admin: true }));
          }
        } else {
          // Checking for other values in access array
          if (enableAdmin) {
            setState((prevState) => ({ ...prevState, is_admin: true }));
          } else {
            setState((prevState) => ({ ...prevState, is_admin: false }));
          }
        }
        break;
      case "branch_management":
        if (checked) {
          if (enableAdmin) {
            setState((prevState) => ({ ...prevState, is_admin: true }));
          }
        } else {
          // Checking for other values in access array
          if (enableAdmin) {
            setState((prevState) => ({ ...prevState, is_admin: true }));
          } else {
            setState((prevState) => ({ ...prevState, is_admin: false }));
          }
        }
        break;
      case "consignments":
        let consArrayElements = [
          "create_consignment",
          "approve_consignment",
          "acknowledge_consignment",
          "delete_consignment",
          "create_transits",
        ];
        var accessArray = [...state.access];
        if (checked) {
          accessArray = accessArray.concat(consArrayElements);
        } else {
          // Removing elements
          accessArray = await accessArray.filter(
            (el) => !consArrayElements.includes(el)
          );
        }
        setState((prevState) => ({
          ...prevState,
          access: accessArray,
        }));
        break;

      case "invoices":
        let invoiceArrayElements = [
          "create_invoice",
          "approve_invoice",
          "generate_invoice",
          "sumbit_invoice",
          "cancel_invoice",
        ];
        var accessArray = [...state.access];
        if (checked) {
          accessArray = accessArray.concat(invoiceArrayElements);
        } else {
          // Removing elements
          accessArray = await accessArray.filter(
            (el) => !invoiceArrayElements.includes(el)
          );
        }
        setState((prevState) => ({
          ...prevState,
          access: accessArray,
        }));
        break;

      case "master":
        let masterArrayElements = ["approve_master"];
        var accessArray = [...state.access];
        if (checked) {
          accessArray = accessArray.concat(masterArrayElements);
        } else {
          // Removing elements
          accessArray = await accessArray.filter(
            (el) => !masterArrayElements.includes(el)
          );
        }
        setState((prevState) => ({
          ...prevState,
          access: accessArray,
        }));
        break;

      default:
        break;
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">
            {pageTitle}
            {viewMode && <EditButton onClick={enableEdit} />}
          </h2>
        </Modal.Header>
        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          <form
            onSubmit={
              userAction === "create"
                ? onSubmit
                : userAction === "edit"
                ? onUpdate
                : (e) => e.preventDefault()
            }
          >
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="name">
                Role Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter user role"
                disabled={viewMode}
                onChange={changeInput}
                value={state.name}
                required
              />
            </div>

            {/* <!-- BEGIN: Admin Group --> */}

            <DividerText className="my-3">Admin</DividerText>

            {/* <!-- BEGIN: Role Access --> */}
            {localUser?.role?.is_admin && (
              <div className="mb-3">
                <div className="col-span-12 mb-2">
                  <input
                    id="is_admin"
                    name="is_admin"
                    className="form-check-input"
                    type="checkbox"
                    onChange={changeInput}
                    checked={state.is_admin}
                    disabled={viewMode}
                  />

                  <label htmlFor="is_admin" className="form-check-label">
                    Root Access
                  </label>
                </div>

                <div className="py-3 border">
                  <div className={"flex flex-wrap"}>
                    {defaultState?.module_access?.map((item, i) => {
                      return (
                        <>
                          {(item.value === "user_management" ||
                            item.value === "branch_management") && (
                            <div className="w-full md:w-auto mb-6 md:mb-2 mx-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={item.name}
                                value={item.value}
                                name={item.value}
                                onChange={changeModuleAccess}
                                checked={state.module_access.includes(
                                  item.value
                                )}
                                disabled={viewMode}
                              />
                              <label
                                className="form-check-label"
                                for={item.name}
                              >
                                {item.name}
                              </label>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {/* <!-- END: Role Access --> */}

            {/* <!-- BEGIN: Is Owner --> */}
            {(localUser?.role?.is_admin || localUser?.role?.is_owner) && (
              <div className="col-span-12 mb-2">
                <input
                  id="is_owner"
                  name="is_owner"
                  className="form-check-input"
                  type="checkbox"
                  onChange={changeInput}
                  checked={state.is_owner}
                  disabled={viewMode}
                />

                <label htmlFor="is_owner" className="form-check-label">
                  Owner Access
                </label>
              </div>
            )}
            {/* <!-- END: Is Owner  --> */}

            {/* <!-- END: Admin Group --> */}

            {/* <!-- BEGIN: Operational Group --> */}
            <DividerText className="my-3">Operational</DividerText>
            {/* <!-- BEGIN: Consignment --> */}
            <div className="mb-3">
              <div className="col-span-12 mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"consignments"}
                  value={"consignments"}
                  name="consignments"
                  onChange={changeModuleAccess}
                  checked={state.module_access.includes("consignments")}
                  disabled={viewMode}
                />
                <label htmlFor="consignments" className="form-check-label">
                  Consignments
                </label>
              </div>

              <div className="py-3 border">
                <div className={"flex flex-wrap"}>
                  {defaultState?.user_access?.map((item, i) => {
                    return (
                      <>
                        {item?.module === "consignment" && (
                          <div className="w-full md:w-auto mb-6 md:mb-2 mx-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item.name}
                              value={item.value}
                              onChange={(e) =>
                                changeUserAccess(e, "consignment")
                              }
                              checked={state.access.includes(item.value)}
                              disabled={viewMode}
                            />
                            <label className="form-check-label" for={item.name}>
                              {item.name}
                            </label>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <!-- END: Consignment --> */}

            {/* <!-- BEGIN: Invoice --> */}
            <div className="mb-3">
              <div className="col-span-12 mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"invoices"}
                  name="invoices"
                  value={"invoices"}
                  onChange={changeModuleAccess}
                  checked={state.module_access.includes("invoices")}
                  disabled={viewMode}
                />
                <label htmlFor="invoices" className="form-check-label">
                  Invoices
                </label>
              </div>

              <div className="py-3 border">
                <div className={"flex flex-wrap"}>
                  {defaultState?.user_access?.map((item, i) => {
                    return (
                      <>
                        {item?.module === "invoice" && (
                          <div className="w-full md:w-auto mb-6 md:mb-2 mx-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item.name}
                              value={item.value}
                              onChange={(e) => changeUserAccess(e, "invoice")}
                              checked={state.access.includes(item.value)}
                              disabled={viewMode}
                            />
                            <label className="form-check-label" for={item.name}>
                              {item.name}
                            </label>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <!-- END: Invoice --> */}

            {/* <!-- BEGIN: Master --> */}
            <div className="mb-3">
              <div className="col-span-12 mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"master"}
                  name="master"
                  value={"master"}
                  onChange={changeModuleAccess}
                  checked={state.module_access.includes("master")}
                  disabled={viewMode}
                />
                <label htmlFor="master" className="form-check-label">
                  Master
                </label>
              </div>

              <div className="py-3 border">
                <div className={"flex flex-wrap"}>
                  {defaultState?.user_access?.map((item, i) => {
                    return (
                      <>
                        {item?.module === "master" && (
                          <div className="w-full md:w-auto mb-6 md:mb-2 mx-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item.name}
                              value={item.value}
                              onChange={(e) => changeUserAccess(e, "master")}
                              checked={state.access.includes(item.value)}
                              disabled={viewMode}
                            />
                            <label className="form-check-label" for={item.name}>
                              {item.name}
                            </label>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <!-- END: Master --> */}

            <div className={"flex flex-wrap"}>
              <div className="w-full md:w-auto mb-6 md:mb-2 mx-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"business_party"}
                  value={"business_party"}
                  onChange={changeModuleAccess}
                  checked={state.module_access.includes("business_party")}
                  disabled={viewMode}
                />
                <label className="form-check-label" for={"business_party"}>
                  Business Parties
                </label>
              </div>

              <div className="w-full md:w-auto mb-6 md:mb-2 mx-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"accounts"}
                  value={"accounts"}
                  onChange={changeModuleAccess}
                  checked={state.module_access.includes("accounts")}
                  disabled={viewMode}
                />
                <label className="form-check-label" for={"accounts"}>
                  Accounting
                </label>
              </div>
            </div>

            {/* <!-- END: Operational Group --> */}

            <input type="submit" id="save_user_roles" className="hidden" />
            <input type="submit" id="update_user_roles" className="hidden" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary mr-2 "
            onClick={props.onHide}
          >
            Cancel
          </button>

          {userAction === "create" && (
            <label
              type="button"
              className="btn btn-primary float-right mr-2 "
              htmlFor="save_user_roles"
            >
              Save
            </label>
          )}
          {userAction === "edit" && (
            <label
              type="button"
              className="btn btn-primary float-right mr-2 "
              htmlFor="update_user_roles"
            >
              Update
            </label>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  onSave: PropTypes.func,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "User Roles",
  action: "view",
  onSave() {},
  callBack() {},
};
