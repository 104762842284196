import PropTypes from "prop-types";

/* <!-- BEGIN: StageTab --> */
const StageTab = (props) => {
  const { className } = props;
  return (
    <div className={className} role="tablist">
      {props.children}
    </div>
  );
};
StageTab.propTypes = {
  className: PropTypes.string,
};
StageTab.defaultProps = {
  className:
    "boxed-tabs justify-center items-center bg-theme-8 bg-opacity-50 text-gray-600 dark:bg-dark-2 dark:text-gray-500 rounded-md p-0",
    //"boxed-tabs nav nav-tabs justify-center bg-theme-8 bg-opacity-50 text-gray-600 dark:bg-dark-2 dark:text-gray-500 rounded-md p-1",
};

/* <!-- BEGIN: StageTab --> */

/* <!-- BEGIN: Tab --> */
const Tab = (props) => {
  const { active, className, id, onClick, width } = props;
  let modClass = className + width;
  return (
    <a
      data-toggle="tab"
      role="tab"
      href={void 0}
      onClick={onClick}
      //className={active ? modClass + " active" : modClass}
      className={active ? modClass + " bg-theme-1 text-white" : modClass}
      id={id}
      aria-selected={active}
    >
      {props.children}
    </a>
  );
};
Tab.propTypes = {
  id: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
};
Tab.defaultProps = {
  id: "tab",
  active: false,
  width: "w-24",
  //className: "btn border-0 shadow-none py-1.5 px-2 truncate ",
  className: "btn border-0 shadow-none py-1.5 px-1 ",
  onClick() {},
};

/* <!-- BEGIN: Element --> */

StageTab.Tab = Tab;

export default StageTab;
