import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TyreItem from "./tyreItem";
import AxleItem from "./axleImage";
import Draggable, { DraggableCore } from "react-draggable";
import { toLowerScore, isDefined } from "../../functions/common";
const tyrePositionObj = require("./tyre_position_obj.json");

export default function Component(props) {
  // const sampleData = [
  //   { _id: "1231", serial_no: "ABC123", position: "Left Front", active: true },
  //   { _id: "", serial_no: "", position: "Right Front", active: false },
  // ];

  // useEffect(() => {}, [props?.data]);

  const { data, no_of_tyres, no_of_axles, onClick, id, className, ...rest } =
    props;

  // var data = sampleData;
  //   Mapping Data
  var tyreData = tyrePositionObj;

  //  Logics & Functions
  for (var i in data) {
    let key = toLowerScore(data[i]["position"]);
    if (isDefined(data[i]["_id"])) {
      data[i]["active"] = true;
    } else {
      data[i]["active"] = false;
    }

    tyreData[key] = data[i];
  }

  const TwoTyreItem = (props) => {
    const { left, Right, ...rest } = props;
    return (
      <div className="flex items-center justify-center my-1">
        {props?.left}
        <AxleItem />
        {props?.right}
      </div>
    );
  };

  const FourTyreItem = (props) => {
    const { leftOut, leftIn, rightIn, rightOut, ...rest } = props;
    return (
      <div className="flex items-center justify-center my-1">
        {props?.leftOut}
        {props?.leftIn}

        <AxleItem />
        {props?.rightIn}
        {props?.rightOut}
      </div>
    );
  };

  const FrontContainer = (props) => {
    return (
      <div className="">
        <p className="text-gray-600 mb-2">FRONT</p>
        {props?.children}
      </div>
    );
  };

  const RearContainer = (props) => {
    return (
      <div className="mt-4">
        <p className="text-gray-600 mb-2">REAR</p>
        {props?.children}
      </div>
    );
  };

  const SpareContainer = (props) => {
    return (
      <div className="mt-4 flex flex-col justify-center items-center">
        <p className="text-gray-600 mb-2">SPARE</p>
        {props?.children}
      </div>
    );
  };

  // Truck Types Patterns
  const FourWheelerTruckPattern = (props) => {
    const { data, ...rest } = props;

    return (
      <div id="four-wheeler-truck-pattern">
        <FrontContainer id={"front"}>
          <TwoTyreItem
            left={<TyreItem data={data?.left_front} onClick={onClick} />}
            right={<TyreItem data={data?.right_front} onClick={onClick} />}
          />
        </FrontContainer>
        <RearContainer id={"rear"}>
          <TwoTyreItem
            left={<TyreItem data={data?.left_rear} onClick={onClick} />}
            right={<TyreItem data={data?.right_rear} onClick={onClick} />}
          />
        </RearContainer>
        <SpareContainer>
          <TyreItem isSpare={true} data={data?.spare} onClick={onClick} />
        </SpareContainer>
      </div>
    );
  };

  const SixWheelerTruckPattern = (props) => {
    const { data, ...rest } = props;

    return (
      <div id="six-wheeler-truck-pattern">
        <FrontContainer id={"front"}>
          <TwoTyreItem
            left={<TyreItem data={data?.left_front} onClick={onClick} />}
            right={<TyreItem data={data?.right_front} onClick={onClick} />}
          />
        </FrontContainer>
        <RearContainer id={"rear"}>
          <FourTyreItem
            leftIn={<TyreItem data={data?.left_rear_inner} onClick={onClick} />}
            leftOut={
              <TyreItem data={data?.left_rear_outer} onClick={onClick} />
            }
            rightIn={
              <TyreItem data={data?.right_rear_inner} onClick={onClick} />
            }
            rightOut={
              <TyreItem data={data?.right_rear_outer} onClick={onClick} />
            }
          />
        </RearContainer>
        <SpareContainer>
          <TyreItem isSpare={true} data={data?.spare} onClick={onClick} />
        </SpareContainer>
      </div>
    );
  };

  const TenWheelerTruckPattern = (props) => {
    const { data, ...rest } = props;

    return (
      <div id="ten-wheeler-truck-pattern">
        <FrontContainer id={"front"}>
          <TwoTyreItem
            left={<TyreItem data={data?.left_front} onClick={onClick} />}
            right={<TyreItem data={data?.right_front} onClick={onClick} />}
          />
        </FrontContainer>
        <RearContainer id={"rear"}>
          <FourTyreItem
            leftIn={
              <TyreItem data={data["2_left_rear_inner"]} onClick={onClick} />
            }
            leftOut={
              <TyreItem data={data["2_left_rear_outer"]} onClick={onClick} />
            }
            rightIn={
              <TyreItem data={data["2_right_rear_inner"]} onClick={onClick} />
            }
            rightOut={
              <TyreItem data={data["2_right_rear_outer"]} onClick={onClick} />
            }
          />
          <FourTyreItem
            leftIn={
              <TyreItem data={data["3_left_rear_inner"]} onClick={onClick} />
            }
            leftOut={
              <TyreItem data={data["3_left_rear_outer"]} onClick={onClick} />
            }
            rightIn={
              <TyreItem data={data["3_right_rear_inner"]} onClick={onClick} />
            }
            rightOut={
              <TyreItem data={data["3_right_rear_outer"]} onClick={onClick} />
            }
          />
        </RearContainer>
        <SpareContainer>
          <TyreItem isSpare={true} data={data?.spare} onClick={onClick} />
        </SpareContainer>
      </div>
    );
  };

  const TwelveWheelerTruckPattern = (props) => {
    const { data, ...rest } = props;

    return (
      <div id="ten-wheeler-truck-pattern">
        <FrontContainer id={"front"}>
          <TwoTyreItem
            left={<TyreItem data={data?.left_front} onClick={onClick} />}
            right={<TyreItem data={data?.right_front} onClick={onClick} />}
          />
          <TwoTyreItem
            left={<TyreItem data={data["2_left_front"]} onClick={onClick} />}
            right={<TyreItem data={data["2_right_front"]} onClick={onClick} />}
          />
        </FrontContainer>
        <RearContainer id={"rear"}>
          <FourTyreItem
            leftIn={
              <TyreItem data={data["3_left_rear_inner"]} onClick={onClick} />
            }
            leftOut={
              <TyreItem data={data["3_left_rear_outer"]} onClick={onClick} />
            }
            rightIn={
              <TyreItem data={data["3_right_rear_inner"]} onClick={onClick} />
            }
            rightOut={
              <TyreItem data={data["3_right_rear_outer"]} onClick={onClick} />
            }
          />
          <FourTyreItem
            leftIn={
              <TyreItem data={data["4_left_rear_inner"]} onClick={onClick} />
            }
            leftOut={
              <TyreItem data={data["4_left_rear_outer"]} onClick={onClick} />
            }
            rightIn={
              <TyreItem data={data["4_right_rear_inner"]} onClick={onClick} />
            }
            rightOut={
              <TyreItem data={data["4_right_rear_outer"]} onClick={onClick} />
            }
          />
        </RearContainer>
        <SpareContainer>
          <TyreItem isSpare={true} data={data?.spare} onClick={onClick} />
        </SpareContainer>
      </div>
    );
  };

  const FourteenWheelerTruckPattern = (props) => {
    const { data, ...rest } = props;

    return (
      <div id="ten-wheeler-truck-pattern">
        <FrontContainer id={"front"}>
          <TwoTyreItem
            left={<TyreItem data={data?.left_front} onClick={onClick} />}
            right={<TyreItem data={data?.right_front} onClick={onClick} />}
          />
          <TwoTyreItem
            left={<TyreItem data={data["2_left_front"]} onClick={onClick} />}
            right={<TyreItem data={data["2_right_front"]} onClick={onClick} />}
          />
        </FrontContainer>
        <RearContainer id={"rear"}>
          <FourTyreItem
            leftIn={
              <TyreItem data={data["3_left_rear_inner"]} onClick={onClick} />
            }
            leftOut={
              <TyreItem data={data["3_left_rear_outer"]} onClick={onClick} />
            }
            rightIn={
              <TyreItem data={data["3_right_rear_inner"]} onClick={onClick} />
            }
            rightOut={
              <TyreItem data={data["3_right_rear_outer"]} onClick={onClick} />
            }
          />
          <FourTyreItem
            leftIn={
              <TyreItem data={data["4_left_rear_inner"]} onClick={onClick} />
            }
            leftOut={
              <TyreItem data={data["4_left_rear_outer"]} onClick={onClick} />
            }
            rightIn={
              <TyreItem data={data["4_right_rear_inner"]} onClick={onClick} />
            }
            rightOut={
              <TyreItem data={data["4_right_rear_outer"]} onClick={onClick} />
            }
          />
        </RearContainer>
        <SpareContainer>
          <TyreItem isSpare={true} data={data?.spare} onClick={onClick} />
        </SpareContainer>
      </div>
    );
  };

  function getTemplateName(tyres, axles, type) {
    var template_name = "";
    if (tyres === 4) template_name = "FourWheelerTruckPattern";
    if (tyres === 6) template_name = "SixWheelerTruckPattern";
    if (tyres === 10) template_name = "TenWheelerTruckPattern";
    if (tyres === 12) template_name = "TwelveWheelerTruckPattern";
    return template_name;
  }

  const RenderTemplate = () => {
    var templateName = getTemplateName(no_of_tyres, no_of_axles);

    switch (templateName) {
      case "FourWheelerTruckPattern":
        return <FourWheelerTruckPattern data={tyreData} />;

      case "SixWheelerTruckPattern":
        return <SixWheelerTruckPattern data={tyreData} />;

      case "TenWheelerTruckPattern":
        return <TenWheelerTruckPattern data={tyreData} />;

      case "TwelveWheelerTruckPattern":
        return <TwelveWheelerTruckPattern data={tyreData} />;
      default:
        return <NullTemplate />;
    }
  };

  const NullTemplate = () => {
    return <div>No Data Found.</div>;
  };

  return (
    <div id="vehicle-tyre-view" className="grid grid-cols-12 gap-5">
      <div className="col-span-8">
        <RenderTemplate />
      </div>
      <div className="col-span-12 md:col-span-4">
        <div className="border border-gray-400 shadow p-5">
          <TyreItem
            data={{
              _id: "",
              serial_no: "",
              active: true,
              position: "Right Rear Inner",
              type: "",
              kms_covered: "",
              max_running_limit: "",
            }}
          />
        </div>
      </div>
    </div>
  );
}

Component.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  no_of_tyres: PropTypes.number,
  no_of_axles: PropTypes.number,

  // Functions
  onClick: PropTypes.func,
};

Component.defaultProps = {
  // Variables
  id: "vehicle-tyres-view",
  className: "",
  data: [],
  no_of_tyres: 0,
  no_of_axles: 0,
  // Functions
  onClick() {},
};
