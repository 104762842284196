const Model = {
  name: "",
  connection_url: "",
  username: "",
  password: "",
  active: false,
  remarks: "",
  status: "",
};
const ValidationErrorModel = {
  name: "",
};

export { Model };
export { ValidationErrorModel };
