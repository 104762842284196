import React from "react";
import PropTypes from "prop-types";
export default function CurrencyInput(props) {
  const {
    currency,
    type,
    id,
    name,
    onChange,
    onBlur,
    value,
    required,
    size,
    placeholder,
    disabled,
    allowedValue,
    min,
    max,
    ...rest
  } = props;
  return (
    <>
      <div className="input-group">
        <div id={id} className="input-group-text">
          ₹
        </div>
        <input
          type={type}
          className={
            size === "sm" ? "form-control form-control-sm" : "form-control"
          }
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          min={min}
          max={max}
          step="any"
          oninput={
            allowedValue === "positive" ? "validity.valid||(value='');" : ""
          }
        />
      </div>
    </>
  );
}

CurrencyInput.propTypes = {
  placeholder: PropTypes.string,
  currency: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  allowedValue: PropTypes.string,
};

CurrencyInput.defaultProps = {
  currency: "inr",
  placeholder: "",
  size: "",
  type: "number",
  id: "",
  name: "",
  onChange() {},
  onBlur() {},
  value: "",
  required: false,
  min: "0",
  max: "",
  allowedValue: "positive",
};
