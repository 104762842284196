import React, { useEffect, useState } from "react";
// Components
import Container from "../../components/container";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker,
} from "react-google-maps";
// const mapStyles = require("./mapStyles.json");

// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { localeData } from "moment";

const sampleData = [
  { lat: 28.539533333333335, lng: 77.05334444444445 },
  { lat: 28.539581666666667, lng: 77.05323333333334 },
  { lat: 28.539614999999998, lng: 77.05313333333334 },
  { lat: 28.539766666666665, lng: 77.05258166666667 },
  { lat: 28.539884444444443, lng: 77.05252666666667 },
  { lat: 28.539884444444443, lng: 77.05252666666667 },
  { lat: 28.542425, lng: 77.05253666666667 },
  { lat: 28.544408333333333, lng: 77.05254333333333 },
  { lat: 28.544445, lng: 77.052655 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544383333333332, lng: 77.05419333333333 },
  { lat: 28.544439999999998, lng: 77.05512 },
  { lat: 28.544561666666667, lng: 77.055295 },
  { lat: 28.546363333333336, lng: 77.05680833333334 },
  { lat: 28.54712166666667, lng: 77.05741277777777 },
  { lat: 28.547226666666667, lng: 77.05737 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.54752166666667, lng: 77.05704 },
  { lat: 28.547706666666667, lng: 77.05692833333333 },
  { lat: 28.548081666666665, lng: 77.05644666666666 },
  { lat: 28.548235000000002, lng: 77.05629 },
  { lat: 28.548235000000002, lng: 77.05629 },
  { lat: 28.548571666666668, lng: 77.05574333333333 },
  { lat: 28.548655, lng: 77.05571166666667 },
  { lat: 28.548745, lng: 77.05563666666667 },
  { lat: 28.55049, lng: 77.05438 },
  { lat: 28.550714999999997, lng: 77.05413666666666 },
  { lat: 28.55175, lng: 77.05356833333333 },
  { lat: 28.553496666666668, lng: 77.05223166666667 },
  { lat: 28.553915, lng: 77.05173833333333 },
];

var count = 1;
var lastPosition = {};
const currentTime = new Date();
const Map = ({ deviceId }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [lastPosition, setLastPosition] = useState([]);
  const [gpsData, setGpsData] = useState([]);
  const [progress, setProgress] = useState([]);
  // const [count, setCount] = useState(1);
  const path = [];

  async function loadData() {
    setIsProcessing(true);
    let query = { truck_number: "TN34Q7538", limit: 50 };
    await api
      .gpsPostService(`last_position`, query)
      .then(async (response) => {
        const data = response.data;

        let recData = await mapData(data.data);
        console.log("mapData:", recData);
        lastPosition = recData[recData.length - 1];
        path.push(recData[recData.length - 1]);

        console.log("path", path[path.length - 1]);

        setGpsData(recData);
        setIsProcessing(false);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
        setIsLoaded(true);
      });
  }

  function mapData(data) {
    let locationData = [];
    for (var i in data) {
      for (var j in data[i]["LocationData"]) {
        // Renaming Fields
        data[i]["LocationData"][j]["lat"] =
          data[i]["LocationData"][j]["latitude"];
        data[i]["LocationData"][j]["lng"] =
          data[i]["LocationData"][j]["longitude"];
        locationData.push(data[i]["LocationData"][j]);
      }
    }

    // Calculating Distance

    for (var i in locationData) {
      if (i === 0) {
        locationData[i]["distance"] = 0;
      } else {
        const { lat: lat1, lng: lng1 } = locationData[i];
        const latLong1 = new window.google.maps.LatLng(lat1, lng1);

        const { lat: lat2, lng: lng2 } = locationData[0];
        const latLong2 = new window.google.maps.LatLng(lat2, lng2);

        // in meters:
        const distance =
          window.google.maps.geometry.spherical.computeDistanceBetween(
            latLong1,
            latLong2
          );
        locationData[i]["distance"] = distance;
      }
    }

    return locationData;
  }

  const getInitialPositions = async () => {
    // let from = new Date();
    // let milliseconds = Date.parse(from);
    // milliseconds = milliseconds - 1 * 60 * 1000;
    // from = new Date(milliseconds).toISOString();
    // console.log(from);
    // const to = new Date().toISOString();
    // console.log(to);
    // const response = await fetch(
    //   `/api/positions/?deviceId=${37}&from=${from}&to=${to}`,
    //   {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //   }
    // );
    // const items = await response.json();

    // console.log(items);
    // items.map((item) => {
    //   path.push({ lat: item.latitude, lng: item.longitude });
    //   return path;
    // });
    // console.log(path);

    path.push(sampleData);
    console.log(path);
  };

  const getPositions30Seconds = async () => {
    // let from = new Date();
    // let milliseconds = Date.parse(from);
    // milliseconds = milliseconds - 0.5 * 60 * 1000;
    // from = new Date(milliseconds).toISOString();
    // console.log(from);
    // const to = new Date().toISOString();
    // console.log(to);
    // const response = await fetch(
    //   `/api/positions/?deviceId=14&from=${from}&to=${to}`,
    //   {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //   }
    // );
    // const items = await response.json();
    // console.log(items);
    // items.map((item) => {
    //   path.push({ lat: item.latitude, lng: item.longitude });
    //   return path;
    // });
    // var tempCount = count;
    if (typeof sampleData[count] !== "undefined") {
      path.push(sampleData[count]);
      // progress.push(sampleData[count]);

      // Progress
      console.log("sampleData[count]['lat']:", sampleData[count]["lng"]);

      const position = window.google.maps.geometry.spherical.interpolate(
        sampleData[count]["lat"],
        sampleData[count]["lng"],
        2
      );

      let prevProgress = [...progress];
      prevProgress.concat(position);
      setProgress(prevProgress);
    }
    // setCount(tempCount + 1);

    count++;
    console.log(count);
  };

  const getDistance = (velocity = 30) => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - currentTime) / 8000; // pass to seconds
    return differentInTime * velocity;
  };

  function startTracking() {
    const interval = window.setInterval(moveObject, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }

  function moveObject() {
    const distance = getDistance();
    if (!distance) {
      return;
    }

    let progressData = gpsData.filter(
      (coordinates) => coordinates.distance < distance
    );

    const nextLine = gpsData.find(
      (coordinates) => coordinates.distance > distance
    );
    if (!nextLine) {
      setProgress(progressData);
      return; // it's the end!
    }
    const lastLine = progressData[progressData.length - 1];

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    progressData = progressData.concat(position);
    setProgress(progressData);

    // Calculating Angle

    const angle = window.google.maps.geometry.spherical.computeHeading(
      lastLineLatLng,
      nextLineLatLng
    );

    // const actualAngle = angle - 35;
    const actualAngle = angle;

    const markerUrl = "/images/truck-top.png";
    const item = document.querySelector(`[src="${markerUrl}"]`);

    if (item) {
      // when it hasn't loaded, it's null
      item.style.transform = `rotate(${actualAngle}deg)`;
    }
  }

  useEffect(() => {
    // const interval = window.setInterval(moveObject, 1000);
    // return () => {
    //   window.clearInterval(interval);
    // };
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const icon = {
    url: "/images/truck-top.png",
    scaledSize: new window.google.maps.Size(30, 30),
    anchor: { x: 10, y: 10 },
  };
  return (
    <>
      <button className="btn btn-primary" onClick={startTracking}>
        Start Tracking
      </button>
      {isProcessing && <ProcessLoader />}

      {isLoaded && (
        <GoogleMap
          defaultZoom={18}
          // defaultCenter={path[path.length - 1]}
          // defaultCenter={{
          //   lat: path[path.length - 1]?.lat,
          //   lng: path[path.length - 1]?.lng,
          // }}
          defaultCenter={{
            lat: 11.252761111111111,
            lng: 77.56259555555556,
          }}
          // defaultOptions={{
          //   // styles: mapStyles,
          //   fullscreenControl: false,
          //   mapTypeControl: false,
          //   streetViewControl: false,
          // }}
        >
          {progress && (
            <>
              <Polyline
                path={progress}
                geodesic={true}
                options={{
                  strokeColor: "#2196f3",
                  strokeOpacity: 0.5,
                  strokeWeight: 8,
                }}
              />

              <Marker icon={icon} position={progress[progress.length - 1]} />
            </>
          )}
        </GoogleMap>
      )}
    </>
  );
};

const MapComponent = withScriptjs(withGoogleMap(Map));

export default function View(props) {
  return (
    <>
      <Container>
        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title className="text-lg font-medium">
            GPS Tracker
          </Container.Title>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/*  <!-- BEGIN: Container Body -->  */}

        <Container.Body>
          <div className="grid grid-cols-12 gap-6">
            {/*  <!-- BEGIN: Left Area -->  */}
            <div className="col-span-12 xl:col-span-3">
              <div className="box shadow-lg">
                <div class="box px-5 py-3 mb-3 flex items-center zoom-in">
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">{lastPosition?.assetId}</div>
                    <div class="text-gray-600  mt-0.5">Truck Number</div>
                  </div>
                  {lastPosition?.inmotion === "notinmotion" && (
                    <div class="text-theme-6 flex items-center">
                      <div class="w-2 h-2 bg-theme-6 rounded-full mr-3"></div>
                      <span class="truncate">Halted</span>
                    </div>
                  )}
                  {lastPosition?.inmotion === "inmotion" && (
                    <div class="text-theme-9 flex items-center">
                      <div class="w-2 h-2 bg-theme-9 rounded-full mr-3"></div>
                      <span class="truncate">Running</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*  <!-- END: Left Area -->  */}
            {/*  <!-- BEGIN: Right Area -->  */}
            <div className="col-span-12 xl:col-span-9">
              <div className="box shadow-lg">
                <MapComponent
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB8A1IgLGjN_2nv-cvLqhdh85wbMsh9ldM&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div style={{ height: `75vh`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </div>
            {/*  <!-- END: Right Area -->  */}
          </div>
        </Container.Body>

        {/*  <!-- END: Container Body -->  */}
      </Container>
    </>
  );
}
