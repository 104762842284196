import React, { useState, useEffect } from "react";

// Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";

import { matchData } from "../../functions/common";

// HTML Components
import { Modal } from "react-bootstrap";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

// Functions
import { resp } from "../../functions/responseHandler";
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";
import { getInvoiceReportPdf } from "../../functions/reports";

// Images

import { ReactComponent as GmailIcon } from "../../assets/images/svg/gmail-icon.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/images/svg/whatsapp-icon.svg";
import { ReactComponent as Telegram } from "../../assets/images/svg/telegram-icon.svg";

const msgTabActiveClass =
  "cursor-pointer w-full sm:w-40 py-4 text-center flex justify-center items-center active";
const msgTabInactiveClass =
  "cursor-pointer w-full sm:w-40 py-4 text-center flex justify-center items-center bg-gray-300";

const msgTabBodyActiveClass = "tab-pane p-5 active";
const msgTabBodyInactiveClass = "tab-pane p-5";

const Model = {
  email_channel: true,
  whatsapp_channel: false,
  to: "",
  subject: "",
  email_msg: "",
  whatsapp_msg: "",
};

export default function Component(props) {
  const [invoiceData, setInvoiceData] = useState();
  const [msgTab, setMsgTab] = useState("email");
  const [localUser, setLocalUser] = useState();
  const [textEditor, setTextEditor] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [defaultState, setDefaultState] = useState({ users: [] });
  const [state, setState] = useState({ ...Model });
  const [mode, setMode] = useState({ view: false, edit: false });
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      loadData(props, localUserData);
    };
    getUser();
  }, []);
  async function loadData(props, localUserData = localUser) {
    setIsProcessing(true);
    const matchedData = await mapData(props.data, localUserData);
    setState(matchedData);

    // Setting Data to CK Editor
    await ClassicEditor.create(document.querySelector("#email_editor"))
      .then((editor) => {
        setTextEditor(editor);
        editor.setData(matchedData["email_msg"]); // Save for later use.
      })
      .catch((error) => {
        console.error(error);
      });

    setIsProcessing(false);
  }

  async function mapData(data, localUserData = localUser) {
    let prevState = { ...state };
    var inv_data = {};
    for (var i in data) {
      // Parsing Email Content
      if (data[i]["channel"] === "email") {
        prevState["to"] = data[i]?.data?.data?.client?.email_invoice;
        prevState["subject"] = data[i]?.data["subject"];
        prevState["email_msg"] = data[i]?.data["html"];

        inv_data = setInvoiceData(data[i]?.data?.data);
      }
      // Parsing whatsapp Content
      if (data[i]["channel"] === "whatsapp") {
        prevState["whatsapp_msg"] = data[i]?.data["text"];
      }
    }

    // console.log("inv_data:", inv_data);
    // let invoice_pdf = await getInvoiceReportPdf(inv_data, localUserData).then(
    //   (result) => {
    //     console.log("result pdf:", result);
    //   }
    // );
    return prevState;
  }

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeEditorInput(event, editor) {
    const data = editor.getData();
    console.log("data:", typeof data);
    setState((prevState) => ({ ...prevState, email_msg: data }));
  }

  function formatEmailData(to) {
    let response = [
      {
        email: to,
        user_type: "cient",
        user_id: invoiceData?.client?._id,
      },
    ];

    return response;
  }

  async function onSubmit(event) {
    event.preventDefault();

    setIsProcessing(true);
    let body = await prepareData();

    console.log("body:", body);

    api.postService(`notification/message`, body).then(
      async (result) => {
        resp.SuccessResponse(result);
        setIsProcessing(false);
        props.callBack(state);
      },
      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  async function prepareData() {
    let response = [];
    let stateData = { ...state };
    // Email Channel
    if (stateData?.email_channel) {
      let to_emails = await formatEmailData(stateData?.to);

      let email_body = {
        channel: "email",
        data: [
          {
            channel: "email",
            recipient: "client",
            recipient_id: invoiceData?.client?._id,
            from_title: localUser?.customer_account?.name, // Company Name
            to: to_emails,
            subject: stateData?.subject,
            text: stateData?.subject,
            html: stateData?.email_msg,
            module: "invoice",
            category: "submit_invoice",
            action_type: "send_email_to_client",
            invoices: [invoiceData?._id],
          },
        ],
      };
      response.push(email_body);
    }

    // Whatsapp Channel
    if (stateData?.whatsapp_channel) {
      // let phones = await stateData.to.map((a) => a?.phone);
      // console.log("phones:", phones);
      for (var i in stateData.to) {
        let whatsapp_body = {
          channel: "whatsapp",
          data: [
            {
              channel: "whatsapp",
              recipient: "user",
              recipient_id: stateData?.to[i]?._id,
              to: stateData.to[i]?.phone,
              text: stateData?.whatsapp_msg,
            },
          ],
        };
        response.push(whatsapp_body);
      }
    }

    return response;
  }

  function onChangeCheckbox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: !state[name],
    }));
  }

  function loadUsers(reload = false) {
    if (defaultState?.users.length === 0 || reload) {
      setIsProcessing(true);
      api.getService(`user/status/active`).then(
        async (result) => {
          const data = result.data;
          console.log("user fetch", data);
          setDefaultState((prevState) => ({
            ...prevState,
            users: data.userAll,
          }));
          setIsProcessing(false);
        },
        (error) => {
          console.log("error", error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
          setError(error);
        }
      );
    }
  }

  async function changeTypeHeadInputUsers(selected, name) {
    setState((prevState) => ({ ...prevState, [name]: selected }));
  }

  function changeMsgTab(channel) {
    setMsgTab(channel);
  }

  async function generateReport(id, localUserData) {
    setIsProcessing(true);
    api.getService(`invoice/${id}`).then(
      async (result) => {
        // await invoiceReport(result?.data, localUserData);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  return (
    <Modal
      {...props}
      onHide={props.onHide}
      backdrop="static"
      fullscreen={true}
      keyboard={false}
      size="xl"
      id="submit_invoice_modal"
    >
      <Modal.Header closeButton>
        <h2 className="font-medium text-base mr-auto">{"Submit Invoice"}</h2>
      </Modal.Header>
      <Modal.Body>
        {isProcessing && <ProcessLoader />}

        <form
          onSubmit={onSubmit}
          id="submit-invoice-form"
          className="grid grid-cols-12 gap-4 gap-y-3"
        >
          {/* <div className="col-span-12 flex items-center justify-center">
            <div className="checkbox mr-2">
              <label className="mr-2">
                <input
                  type="checkbox"
                  checked={state.email_channel}
                  name="email_channel"
                  onChange={onChangeCheckbox}
                />{" "}
                Email
              </label>
            </div>

            <div className="checkbox">
              <label className="mr-2">
                <input
                  type="checkbox"
                  checked={state.whatsapp_channel}
                  name="whatsapp_channel"
                  onChange={onChangeCheckbox}
                />{" "}
                Whatsapp
              </label>
            </div>
          </div> */}

          <div className="col-span-12">
            <label className="form-label" htmlFor="to">
              To
            </label>
            <input
              type="text"
              className="form-control"
              id="to"
              name="to"
              placeholder=""
              onChange={changeInput}
              value={state.to}
              required
            />
            {/* <Typeahead
              clearButton
              multiple
              filterBy={["fullname", "email"]}
              id="to"
              name="to"
              key="to"
              onFocus={(e) => loadUsers(false)}
              onChange={(selected) => {
                changeTypeHeadInputUsers(selected, "to");
              }}
              labelKey={(option) => `${option?.fullname}`}
              options={defaultState?.users}
              selected={state?.to}
              placeholder=""
              disabled={mode.view}
              renderMenu={(results, menuProps) => (
                <Menu {...menuProps}>
                  {results.map((result, index) => (
                    <MenuItem option={result} position={index} key={index}>
                      <div class="intro-x cursor-pointer box relative flex items-center p-3 ">
                        <div class="w-12 h-12 flex-none image-fit mr-1">
                          <img
                            alt=""
                            class="rounded-full"
                            src="images/profile-pic-default.png"
                          />
                        </div>
                        <div class="ml-2 overflow-hidden">
                          <div class="flex items-center">
                            <a href="javascript:;" class="font-medium">
                              {result?.fullname}
                            </a>
                          </div>
                          <div class="w-full truncate text-gray-600 mt-0.5">
                            {result?.email}
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                </Menu>
              )}
            /> */}
          </div>

          <div className="col-span-12">
            <label className="form-label" htmlFor="subject">
              Subject / Title
            </label>
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              placeholder=""
              disabled={mode?.view}
              onChange={changeInput}
              value={state.subject}
              required
            />
          </div>

          <div className="col-span-12">
            {/* <label className="form-label" htmlFor="message">
              Message
            </label> */}
            {/* <CKEditor
              // id="html"
              // id="email_editor"
              // name="email_editor"
              editor={ClassicEditor}
              initData={state?.html}
              data={state?.html}
              onReady={(editor) => {
                setTextEditor(editor);
                // You can store the "editor" and use when it is needed.
                // console.log("Editor is ready to use!", editor);
              }}
              onChange={changeEditorInput}
            /> */}
          </div>

          {/* <!-- BEGIN: Message Container --> */}
          <div className="col-span-12 post overflow-hidden box mt-3 shadow">
            {/* <!-- BEGIN: Channel Tab Headers --> */}
            <div
              class="post__tabs nav nav-tabs flex-col sm:flex-row  dark:bg-dark-2 text-gray-600"
              role="tablist"
            >
              <a
                data-toggle="tab"
                data-target="#email_preview"
                href={void 0}
                className={
                  msgTab === "email" ? msgTabActiveClass : msgTabInactiveClass
                }
                onClick={(e) => changeMsgTab("email")}
                id="email_preview-tab"
                role="tab"
                aria-controls="email_preview"
                aria-selected="true"
                disabled={!state?.email_channel}
              >
                {/* <GmailIcon width="32" height="32" className="inline mr-2" />{" "} */}
                Message
              </a>
              {/* <a
                data-toggle="tab"
                data-target="#whatsapp_preview"
                href={void 0}
                onClick={(e) => changeMsgTab("whatsapp")}
                className={
                  msgTab === "whatsapp"
                    ? msgTabActiveClass
                    : msgTabInactiveClass
                }
                id="whatsapp_preview-tab"
                role="tab"
                aria-selected="false"
                disabled={!state?.whatsapp_channel}
              >
                <WhatsappIcon width="32" height="32" className="inline mr-2" />{" "}
                Whatsapp Content
              </a> */}
            </div>
            {/* <!-- END: Channel Tab Headers --> */}

            {/* <!-- BEGIN: Channel Tab Body --> */}
            <div className="col-span-12 post__content tab-content">
              {/* <!-- BEGIN: Email Preview --> */}
              <div
                id="email_preview"
                className={
                  msgTab === "email"
                    ? msgTabBodyActiveClass
                    : msgTabBodyInactiveClass
                }
                role="tabpanel"
                aria-labelledby="email_preview-tab"
              >
                <textarea
                  id="email_editor"
                  disabled={!state?.email_channel}
                ></textarea>
              </div>
              {/* <!-- END: Email Preview --> */}

              {/* <!-- BEGIN: Whatsapp Preview --> */}
              {/* <div
                id="whatsapp_preview"
                className={
                  msgTab === "whatsapp"
                    ? msgTabBodyActiveClass
                    : msgTabBodyInactiveClass
                }
                role="tabpanel"
                aria-labelledby="whatsapp_preview-tab"
              >
                <textarea
                  id="whatsapp_editor"
                  type="text"
                  name="whatsapp_msg"
                  className="w-full"
                  rows={12}
                  cols={12}
                  onChange={changeInput}
                  value={state?.whatsapp_msg}
                  disabled={!state?.whatsapp_channel}
                ></textarea>
              </div> */}
              {/* <!-- END: Whatsapp Preview --> */}
            </div>
            {/* <!-- END: Channel Tab Body --> */}
          </div>
          {/* <!-- END: Message Container --> */}
          <input type="submit" id="send_email_preview" className="hidden" />
        </form>
      </Modal.Body>
      {/* <!-- BEGIN: Modal Footer --> */}
      <Modal.Footer>
        <button
          type="button"
          data-dismiss="modal"
          className="btn btn-secondary w-20 mr-2"
          onClick={props.onHide}
        >
          Cancel
        </button>{" "}
        <label
          type="button"
          className="btn btn-primary w-20 "
          htmlFor="send_email_preview"
        >
          Send
        </label>
      </Modal.Footer>
      {/* <!-- END: Modal Footer --> */}
    </Modal>
  );
}
