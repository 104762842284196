import React, { Component } from "react";

class Error extends Component {
  render() {
    return (
      <div className="h-screen">
        <div className="intro-y box py-10 sm:py-20 mt-5  w-3/4  text-center my-auto mx-auto ">
          <div className="">
            {/* <h1>500</h1> */}
            <h3 className="text-uppercase text-lg mb-5">
              Internal Server Error !{" "}
            </h3>
            <p className="text-gray-500 mt-30 mb-30">
              Please try after some time
            </p>
            {/* <a className="btn btn-primary btn-rounded waves-effect waves-light m-b-40" href="#">Back to home</a>  */}
          </div>
        </div>
      </div>
    );
  }
}
export default Error;
