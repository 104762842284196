import React, { useState, useEffect } from "react";
import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";
import { resp } from "../../functions/responseHandler";
import { validateForm } from "../../functions/common";
import { Modal } from "react-bootstrap";
import * as api from "../../api_service/api";

import { storageServices } from "../../functions/storage";

const Model = { email: "", password: "", confirm_password: "" };

export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  // State
  const [state, setState] = useState(Model);
  const [validationError, setValidationError] = useState({
    confirm_password: "",
    password: "",
  });

  useEffect(() => {
    let prevState = { ...state };
    prevState.email = props?.email;
    setState(prevState);
  }, []);

  // Form Actions
  function onSubmit(event) {
    event.preventDefault();
    if (validateForm(validationError)) {
      setIsProcessing(true);
      var query = { ...state };
      api
        .putService("user/create_password", query)
        .then(async (response) => {
          // let localLogin = await storageServices.getStoredData("login");
          // if (typeof localLogin != "undefined") {
          //   if (localLogin?.remember_me) {
          //     localLogin.password = state?.password;
          //     await storageServices.storeData("login", localLogin);
          //   }
          // }

          // Resetting Token
          await storageServices.resetCurrentUser();
          setIsProcessing(false);
          resp.Success(
            "Password Updated! Please login using your new password."
          );
          props.callBack();
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
        });
    } else {
      console.log("Validation Failed.");
    }
  }

  // Input Changes
  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    let errors = validationError;

    switch (name) {
      case "password":
        errors.password =
          value.length < 8 ? "Password must be 8 characters long!" : "";
        break;
      case "confirm_password":
        errors.confirm_password =
          value !== state.password
            ? "Confirm password should match the password!"
            : "";
        break;
      default:
        break;
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
        size="sm"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            {isProcessing && <ProcessLoader />}
            <form
              onSubmit={onSubmit}
              id="create_password-form"
              className="grid grid-cols-12 gap-4 gap-y-3"
            >
              <div className="col-span-12">
                <label htmlFor="password" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder=""
                  onChange={changeInput}
                  value={state.password}
                  required
                />
                {validationError.password.length > 0 && (
                  <span className="error text-danger animated fadeInDown ">
                    {validationError.password}
                  </span>
                )}
              </div>
              <div className="col-span-12">
                <label htmlFor="confirm_password" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirm_password"
                  name="confirm_password"
                  placeholder=""
                  onChange={changeInput}
                  value={state.confirm_password}
                  required
                />
                {validationError.confirm_password.length > 0 && (
                  <span className="error text-danger animated fadeInDown ">
                    {validationError.confirm_password}
                  </span>
                )}
              </div>

              <input
                type="submit"
                id="submit-create-password-form"
                className="hidden"
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-secondary w-20 mr-2"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <label
            type="button"
            className="btn btn-primary"
            htmlFor="submit-create-password-form"
          >
            Save
          </label>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  title: PropTypes.string,
  email: PropTypes.string,
  callBack: PropTypes.func,
};

Component.defaultProps = {
  title: "Create Password",
  email: "",
  callBack() {},
};
