import feather from "feather-icons";
    
export default function FeatherIcons(){
    // Feather Icons
    feather.replace({
        "stroke-width": 1.5,
    });
    window.feather = feather;
}
  
