import React, { useState, useEffect } from "react";
import {
  formatKm,
  localDate,
  trimConsNum,
  navToPage,
  calculateTripDuration,
} from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import ReportsDataTable from "../tables/reportsDataTable";
import { storageServices } from "../../functions/storage";

export default function Component(props) {
  const Title = "Trip History Report";
  const tableRef = React.createRef();
  const { data } = props;

  const [isProcessing, setIsProcessing] = useState(false);
  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData(props.data);
  }, [props.data]);

  async function loadData(data) {
    setIsProcessing(true);
    let mappedData = await mapData(data);
    let tableData = await mapTableData(mappedData);
    setTableData(tableData);

    setIsProcessing(false);
  }

  function mapData(data) {
    for (var i in data) {
      data[i]["trip_duration"] = calculateTripDuration(
        data[i]?.start_time,
        data[i]?.end_time
      );
    }
    return data;
  }

  function mapTableData(data) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },

      {
        title: "Cons. Date",
        field: "consignment_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Cons. No.",
        field: "consignment_id",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return trimConsNum(cell?.getValue());
        },
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vehicle No.",
        field: "vehicle.registration_number",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Driver",
        field: "driver.name",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Origin",
        field: "origin.name",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Destination",
        field: "destination.name",
        headerSort: true,
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Trip Distance",
        field: "trip_distance",
        headerSort: true,
        vertAlign: "middle",
        formatter: function (cell) {
          return formatKm(cell?.getValue());
        },
      },
      {
        title: "Trip Duration",
        field: "trip_duration",
        headerSort: true,
        vertAlign: "middle",
      },
    ];
    let response = {
      header: columns,
      content: data,
    };
    return response;
  }

  function viewRecord(id) {
    navToPage(`/admin#/consignment/edit/${id}`);
  }

  return (
    <>
      <div>
        {isProcessing && <ProcessLoader />}

        {/* <!-- BEGIN: Data Table --> */}
        <ReportsDataTable
          title={Title}
          header={tableData.header}
          content={tableData.content}
          onRowClick={(e, row) => {
            viewRecord(row._row.data._id);
          }}
          downloadFileName={Title}
          tableRef={tableRef}
        />
        {/* <!-- END: Data Table --> */}
      </div>
    </>
  );
}
