import React, { useState, useEffect } from "react";
// Loaders
import ProcessLoader from "../../preloader/processLoader";
import PropTypes from "prop-types";
// HTML Components
import { resp } from "../../../functions/responseHandler";
import { Modal } from "react-bootstrap";
import DividerText from "../../htmlElements/dividerText";
// Functions
import * as api from "../../../api_service/api";
export default function Component(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [state, setState] = useState();

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    setState(props?.data);
  }
  async function checkConsentStatus(recheck = false) {
    let query = {
      phone_number: state?.contact_number,
      driver_id: props?.recordId,
    };
    setIsProcessing(true);
    api.postService(`sim_tracking/consent_status`, query).then(
      async (result) => {
        const data = result.data;
        let status = data.data["sim_tracking_status"];
        setState((prevState) => ({
          ...prevState,
          sim_tracking_status: status,
        }));
        setIsProcessing(false);
        if (recheck) {
          resp.Information(`Status is ${status}`);
        }
      },
      (error) => {
        console.log("error", error);
        resp.Error("Status check failed!");
        setIsProcessing(false);
      }
    );
  }

  async function enableTracking() {
    let query = {
      phone_number: state?.contact_number,
      driver_id: props?.recordId,
    };
    setIsProcessing(true);
    api.postService(`sim_tracking/enable_tracking`, query).then(
      async (result) => {
        console.log("result:", result);
        const data = result.data;
        setState((prevState) => ({
          ...prevState,
          sim_tracking_status: data.data["sim_tracking_status"],
        }));
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.Error("Something went wrong!");
        setIsProcessing(false);
      }
    );
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={props.onHide}
        id="sim_tracking_status_modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">SIM Tracking</h2>
        </Modal.Header>

        <Modal.Body>
          {isProcessing && <ProcessLoader />}
          {state?.sim_tracking_status === "PENDING" && (
            <div className="text-center">
              <div id="pending_consent_info" className="text-center mb-3">
                <p className="mb-2">
                  <span className="text-gray-600 inline mr-2">Status</span>
                  <span className="bg-theme-11 text-center text-white px-2 py-1  rounded mb-2 text-sm">
                    {state?.sim_tracking_status}
                  </span>
                </p>
                <p className="text-lg">
                  Ask <b>{state?.name}</b> to give permission for SIM Tracking
                  by replying <b>Y</b> to the consent message
                </p>

                <DividerText className="my-3">OR</DividerText>

                <p className="text-lg">
                  Ask {state?.name} to call Toll-Free Number and press 1
                </p>
                <h3 class="text-2xl font-medium leading-none mt-3">
                  7303777719
                </h3>
              </div>

              <button
                class="btn btn-outline-primary inline-block mt-4"
                onClick={(e) => checkConsentStatus(true)}
              >
                <i class="fa fa-refresh mr-1" aria-hidden="true"></i>Recheck
                Status
              </button>

              <DividerText className="my-2"></DividerText>
              <p className="mt-3">
                <span className="text-gray-500">Note:</span>
                <span className="ml-2 text-gray-700">
                  Once the consent is given, It will take min. 20 minutes to
                  enable tracking.
                </span>
              </p>
            </div>
          )}

          {state?.sim_tracking_status === "ALLOWED" && (
            <div>
              {state?.name} {state?.contact_number} is enabled for SIM Tracking.
              You can start tracking.
            </div>
          )}

          {state?.sim_tracking_status === "NOT_INITIATED" && (
            <button
              class="btn btn-primary inline-block mb-2"
              onClick={(e) => enableTracking()}
            >
              Enable SIM Tracking
            </button>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
