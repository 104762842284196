import React, { useState, useEffect } from "react";

// HTML Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import StatusBadge from "../../../components/pageElements/statusBadge";
import ActionButtons from "../../../components/pageElements/actionButtons";
import StageTab from "../../../components/tabs/stageTab";
import AddNewButton from "../../../components/htmlElements/addNewButton";

// External Components
import FormMaster from "../../../components/master/tyre";
import TyreServiceEntryForm from "../../../components/repair/tyre";

// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import { htmlToString, navToPage, formatKm } from "../../../functions/common";

// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";
// Sample Data
const sampleData = require("./sample_data.json");
export default function View(props) {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));

  // Tyre Repair Modal Functions
  const [tyreServiceEntryModal, setTyreServiceEntryModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });

  function openTyreServiceEntryModal() {
    let prevState = { ...tyreServiceEntryModal };
    prevState["action"] = "create";
    prevState["show"] = true;
    setTyreServiceEntryModal(prevState);
  }
  const closeTyreServiceEntryModal = () =>
    setTyreServiceEntryModal((prevState) => ({ ...prevState, show: false }));

  function tyreServiceEntryModalCallBack() {
    return null;
  }

  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  /////////// FUNCTIONS DECLARATION ///////////

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      // Loading Data
      loadData(localUserData);
    };
    getUser();
  }, []);

  // Reload Grid Data
  function modalCallBack() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData(localUser);
  }

  async function loadData(user = localUser) {
    setIsProcessing(true);
    api.getService(`tyre`).then(
      async (result) => {
        const data = result.data;
        console.log("api data", data);
        let tableData = await mapTableData(data.data, user);
        setTableData(tableData);
        setIsProcessing(false);
      },

      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data, user) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Serial No.",
        field: "serial_no",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },

      {
        title: "Mode",
        field: "mode",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vehicle No.",
        field: "vehicle.registration_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Brand",
        field: "brand",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Kms Covered",
        field: "kms_covered",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return `${formatKm(cell?.getValue())}`;
        },
      },
      {
        title: "Status",
        field: "status",
        headerSort: true,
        vertAlign: "right",
        formatter: function (cell) {
          return htmlToString(<StatusBadge text={cell?.getValue()} />);
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    };
    return response;
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    const user = props.user;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e, row) => {
            editRecord(cellData._id);
          },
        }}
        suspendButton={{
          show: cellData?.status === "active" ? true : false,
          onClick: (e) => {
            deleteRecord(cellData);
          },
        }}
      />
    );
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData() {
    loadData(localUser);
  }
  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add Tyre`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(id) {
    // let newState = {
    //   show: true,
    //   title: `View Tyre`,
    //   action: "edit",
    //   id: id,
    // };
    // setModal(newState);
    navToPage(`/admin#/tyre/view/${id}`);
  }

  // Edit Record
  async function editRecord(id) {
    let newState = {
      show: true,
      title: `Edit Type`,
      action: "edit",
      id: id,
    };
    setModal(newState);
  }

  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to Suspend this Tyre?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }

  // Edit Record
  async function approveRecord(data) {
    let newState = {
      show: true,
      title: `Approve Vehicle`,
      action: "approve",
      id: data?._id,
    };
    setModal(newState);
  }

  ////// COMMON FUNCTIONS //////

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`vehicle/${data?._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function changeFilter(event) {
    const { name, value } = event.target;
    loadData(value, localUser);
  }

  return (
    <>
      <>
        {/* <!-- BEGIN: Master Modal --> */}
        {modal.show && (
          <FormMaster
            show={modal.show}
            action={modal.action}
            title={modal.title}
            onHide={closeModal}
            callBack={modalCallBack}
            id={modal.id}
          />
        )}
        {/* <!-- END: Master Modal --> */}

        {/* <!-- BEGIN: Tyre Service Modal --> */}
        {tyreServiceEntryModal.show && (
          <TyreServiceEntryForm
            show={tyreServiceEntryModal.show}
            action={tyreServiceEntryModal.action}
            onHide={closeTyreServiceEntryModal}
            callBack={tyreServiceEntryModalCallBack}
            id={tyreServiceEntryModal.id}
          />
        )}
        {/* <!-- END: Master Modal --> */}

        <Container>
          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title>Tyre</Container.Title>

            {/* <!-- BEGIN: Stage Tabs --> */}
            <div className="ml-auto flex">
              <StageTab>
                <StageTab.Tab
                  id="tyre-master-tab"
                  active={true}
                  onClick={(e) => navToPage("/admin#/tyre/master")}
                  width={"w-32"}
                >
                  Masters
                </StageTab.Tab>{" "}
                <StageTab.Tab
                  id="tyre-service-tab"
                  active={false}
                  onClick={(e) => navToPage("/admin#/tyre/services")}
                  width={"w-32"}
                >
                  Services
                </StageTab.Tab>
              </StageTab>
            </div>
            {/* <!-- END: Stage Tabs --> */}

            <Container.HeaderActions>
              <AddNewButton id="add-tyre-button" onClick={addRecord}>
                Add Tyre
              </AddNewButton>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing && <ProcessLoader />}
            <CustomDataTable
              header={tableData.header}
              content={tableData.content}
              onRowClick={(e, row) => {
                viewRecord(row._row.data._id);
              }}
              filter={true}
              filterFields={tableData.filterFields}
            />
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
