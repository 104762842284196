import React, { useState, useEffect, useRef } from "react";
import { Route, HashRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Preloader from "../components/preloader/preloader";

// <!--------------PAGES----------------->
import Wrapper from "../components/wrapper";

// Dashboard
import Home from "../view/Home";
import Dashboard from "../view/Dashboard";
import SamplePage from "../view/Sample";
import MyOrganization from "../view/MyOrganization";
import BusinessRules from "../view/BusinessRules";
import Subscriptions from "../view/Subscriptions";

////////////// BEGIN : MASTER //////////////
import Supplier from "../view/Master/Supplier";
import Supplier_type from "../view/Master/Supplier_Type";
import Vehicle from "../view/Master/Vehicle";
import VehicleView from "../view/Master/Vehicle/vehicle-view";
// import VehicleForm from "../components/master/vehicle/form";
import VehicleType from "../view/Master/VehicleType";
import Location from "../view/Master/Location";
import Client from "../view/Master/Client";
import Client_Agreements from "../view/Master/Client_Agreements";
import Client_Agreements_View from "../view/Master/Client_Agreements/view";
import Client_Agreements_Price_History from "../view/Master/Client_Agreements/priceHistory";
import Branches from "../view/Branches";
import Driver from "../view/Master/Driver";
import LoadMaterial from "../view/Master/LoadMaterial";
import LoadMaterialThreshold from "../view/Master/LoadMaterialThreshold";

// User Management
import Users from "../view/User Management/Users";
import UserRoles from "../view/User Management/User Roles";
import Designation from "../view/User Management/Designation";

// Accounts
import Bank from "../view/Master/Accounts/Bank";
import Cash from "../view/Master/Accounts/Cash";
import Income_Master from "../view/Master/Accounts/Income";
import Expense_Master from "../view/Master/Accounts/Expense";
import ChargesMaster from "../view/Master/Accounts/Charges";

////////////// END : MASTER //////////////

////////////// MAIN //////////////
//Consignment
import Consignment from "../view/Consignment";
import ConsignmentView from "../view/Consignment/new-view";
// Broker View - Consignments
import ConsignmentBrokerView from "../view/Consignment/new-view-broker";

// Inovoices
import Invoices from "../view/Invoices";
import FormInvoice from "../view/Invoices/formInovice";
import FormInvoiceBroker from "../view/Invoices/formInovice-broker";

//ACCOUNTS
import Accounts from "../view/Accounts/router";

// Reports
import Reports from "../view/Reports";
import ReportsDataExport from "../view/Reports/data_export";
import ReportsWorkbench from "../view/Reports/workbench";

//GPS Tracker
import GPSTracker from "../view/GPS";
import SimTrackingView from "../view/Map-View/simTracking";

// Fleet
import Tyre from "../view/Fleet/Tyre";
import TyreView from "../view/Fleet/Tyre/view";
import TyreRepairSlip from "../view/Fleet/TyreRepairSlip";
import Fuel from "../view/Fleet/Fuel";

// APP
import HelpGuide from "../view/HelpGuide";
////////////// MAIN //////////////

// <!--------------PAGES----------------->

// <!--------------COMPONENTS----------------->
import { TopBar } from "../components/topbar";
import TopMenu from "../components/menubar/topmenu";
import SideMenu from "../components/menubar/sidemenu";
// <!--------------COMPONENTS----------------->

// <!--------------FUNCTIONS----------------->
import { storageServices } from "../functions/storage";
import { getUserAccessList } from "../functions/common";
// <!--------------FUNCTIONS----------------->

// <!--------------CSS----------------->
// import "../assets/sass/admin.scss";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";

// <!--------------CSS----------------->

// Cookies & Storage
import Cookies from "universal-cookie";
const cookies = new Cookies();

async function logOut() {
  await storageServices.resetCurrentUser();
  window.location.href = "login";
}

async function checkLogin() {
  var user = await storageServices.getCurrentUser();
  if (user === null || typeof user === "undefined") {
    logOut();
  }
}

export default function Admin(props) {
  var isReload = props?.reload || false;
  const [menuLayout, setMenuLayout] = useState(
    cookies.get("menu_layout") || "sidemenu"
  );
  const [accessList, setAccessList] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [localUser, setLocalUser] = useState();
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  useEffect(() => {
    checkLogin();
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      let list = await getUserAccessList(localUserData);
      await setAccessList(list);
      setIsLoaded(true);
    };
    getUser();
  }, []);

  function onChangeMenuLayout(layout) {
    cookies.set("menu_layout", layout, { path: "/" });
    window.location.reload();
  }

  if (localUser === null || typeof localUser === "undefined") {
    return null;
  }

  const handleToggleSidebar = (event) => {
    event.preventDefault();
    setToggled(!toggled);
    setCollapsed(!collapsed);
  };

  if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <HashRouter>
        <div className="body-content main">
          <Helmet>
            {/* <!-- BEGIN: ChatBot - DialogFlow --> */}
            <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>

            {/* <!-- END: ChatBot - DialogFlow --> */}
          </Helmet>

          <TopBar
            localUser={localUser}
            // reload={isReload}
            handleToggleSidebar={handleToggleSidebar}
            menuLayout={menuLayout}
            onChangeMenuLayout={onChangeMenuLayout}
          />

          <div className={menuLayout === "sidemenu" ? "flex" : ""}>
            {menuLayout === "topmenu" ? (
              <TopMenu
                localUser={localUser}
                userAccessList={accessList}
                reload={false}
                menuLayout={menuLayout}
                onChangeMenuLayout={onChangeMenuLayout}
              />
            ) : (
              <SideMenu
                collapsed={collapsed}
                toggled={toggled}
                localUser={localUser}
                userAccessList={accessList}
                reload={false}
                menuLayout={menuLayout}
                onChangeMenuLayout={onChangeMenuLayout}
              />
            )}

            {/* <MenuBar localUser={localUser} reload={isReload} /> */}

            {/* ChatBot */}

            <df-messenger
              // intent="WELCOME"
              chat-icon="https://i.ibb.co/5kh3vtZ/chatbot-1.png"
              chat-title="Ask Diya"
              agent-id="4bbbbd12-ccab-4a12-9533-e4d2dba99876"
              language-code="en"
              user-id={localUser?.token}
              accessToken={localUser?.token}
              // session-id="3435235"
            ></df-messenger>

            {/* <df-messenger
          intent="WELCOME"
          chat-title="Chatbot"
          agent-id="6cd75f02-99ef-4ddc-8489-422887947be3"
          language-code="en"
          user-id="33434"
          session-id="3435235"
        ></df-messenger> */}

            {/* <!-- BEGIN: ROUTES --> */}
            <Wrapper>
              <Route path="/home/:isFirstTimeLogin?" component={Home} />

              <Route
                path="/dashboard/operational"
                render={(props) => <Dashboard {...props} />}
                exact
              />
              <Route path="/sample_page" component={SamplePage} />
              <Route path="/my_organization" component={MyOrganization} />
              <Route path="/business_rules" component={BusinessRules} />
              <Route path="/subscriptions" component={Subscriptions} />
              {/* <!-- BEGIN: Master --> */}
              <Route path="/master/supplier" component={Supplier} />
              <Route path="/master/supplier_type" component={Supplier_type} />
              <Route path="/master/vehicle_type" component={VehicleType} />
              <Route path="/master/vehicle/:type?" component={Vehicle} exact />
              <Route
                path="/master/vehicle/view/:vehicle_id"
                component={VehicleView}
                exact
              />
              {/* <Route
          path="/master/vehicle/form/:action/:id?"
          component={VehicleForm}
        /> */}
              <Route path="/master/location" component={Location} />
              <Route path="/master/client" component={Client} exact />

              <Route
                path="/master/client/agreement"
                component={Client_Agreements}
                exact
              />
              <Route
                path="/price_history"
                component={Client_Agreements_Price_History}
                exact
              />
              <Route
                path="/master/client/agreement/:id"
                component={Client_Agreements_View}
                exact
              />

              {/* <Route
                path="/master/client/agreement/:action/:id?"
                component={Client_Agreement_Form}
              /> */}

              <Route path="/master/driver" component={Driver} />
              <Route path="/master/load_material" component={LoadMaterial} />
              <Route
                path="/master/load_material_threshold"
                component={LoadMaterialThreshold}
              />

              {/* ACCOUNTS */}
              <Route path="/master/cash" component={Cash} />
              <Route path="/master/bank" component={Bank} />
              <Route path="/master/income" component={Income_Master} />
              <Route path="/master/expense" component={Expense_Master} />
              <Route path="/master/charges" component={ChargesMaster} />
              {/* ACCOUNTS */}

              {/* <!-- END: Master --> */}

              {/* <!-- BEGIN : Main Components --> */}

              <Route path="/user_management/users/:action?" component={Users} />
              <Route
                path="/user_management/user_roles/:action?"
                component={UserRoles}
              />
              <Route
                path="/user_management/designation/:action?"
                component={Designation}
              />

              <Route path="/branch/:action?" component={Branches} />

              <Route
                exact
                path="/consignment/:status?"
                component={Consignment}
              />

              <Route
                exact
                path="/consignment/view/:action/:id?"
                component={ConsignmentView}
              />

              <Route
                exact
                path="/consignment/broker-view/:action/:id?"
                component={ConsignmentBrokerView}
              />

              <Route
                exact
                path="/sim_tracking/:driver_id/:consignment_id/:phone_number"
                component={SimTrackingView}
              />

              {/* INVOICES */}
              {/* <Route
          exact
          path="/invoices/consignments"
          component={InovicesConsignments}
        /> */}
              <Route
                exact
                path="/invoices/view/:status?"
                component={Invoices}
              />
              <Route
                exact
                path="/invoices/form/:action/:id?/:consignment_id?"
                component={FormInvoice}
              />
              <Route
                exact
                path="/invoices/form-broker/:action/:id?/:consignment_id?"
                component={FormInvoiceBroker}
              />
              {/* INVOICES */}

              {/*<!-- BEGIN: Reports --> */}
              <Route exact path="/reports" component={Reports} />
              <Route
                exact
                path="/reports/data_export"
                component={ReportsDataExport}
              />
              <Route
                exact
                path="/reports/workbench"
                component={ReportsWorkbench}
              />
              {/*<!-- END: Reports --> */}

              <Route exact path="/gps_tracker" component={GPSTracker} />

              {/* <!-- END : Main Components --> */}

              {/* <!-- BEGIN : Addon Components --> */}

              {/* ACCOUNTS - Having Seperate Router */}
              <Route path="/accounts" component={Accounts} />

              {/* <!-- END : Addon Components --> */}

              {/* <!-- BEGIN : Fleet Components --> */}
              <Route path="/tyre/services" component={TyreRepairSlip} exact />
              <Route path="/tyre/master" component={Tyre} exact />
              <Route path="/tyre/view/:tyre_id" component={TyreView} exact />

              <Route path="/fuel" component={Fuel} exact />

              {/* <!-- END : Fleet Components --> */}

              {/* <!-- BEGIN : App Components --> */}
              <Route path="/app/help_guide" component={HelpGuide} exact />

              {/* <!-- END : App Components --> */}

              {/* <Route path="" component={NotFound} exact={true} /> */}
            </Wrapper>
            {/* <!-- END: ROUTES --> */}
          </div>
        </div>
      </HashRouter>
    );
  }
}
