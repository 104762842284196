import React, { useState, useEffect } from "react";

// Components
import Container from "../../../components/container";
import CustomDataTable from "../../../components/tables/customDataTable";
import ActionButtons from "../../../components/pageElements/actionButtons";
import FormMaster from "../../../components/master/fuel_slip";
import AddNewButton from "../../../components/htmlElements/addNewButton";
// Functions
import * as api from "../../../api_service/api";
import { storageServices } from "../../../functions/storage";
import {
  navToPage,
  localDate,
  formatCurrency,
} from "../../../functions/common";

// Loaders
import ProcessLoader from "../../../components/preloader/processLoader";
// Toaster & Notification
import { alert } from "../../../components/notification/alert";
import { resp } from "../../../functions/responseHandler";
// Sample Data
const sampleData = require("./sample_data.json");
export default function View(props) {
  /////////// VARIABLES DECLARATION ///////////
  // Loaders
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Modal Functions
  const [modal, setModal] = useState({
    show: false,
    title: "",
    action: "",
    id: "",
  });
  const openModal = () =>
    setModal((prevState) => ({ ...prevState, show: true }));
  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, show: false }));

  // Data
  const [tableData, setTableData] = useState([]);
  const [localUser, setLocalUser] = useState();

  /////////// FUNCTIONS DECLARATION ///////////

  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      // Loading Data
      loadData(localUserData);
    };
    getUser();
  }, []);

  // Reload Grid Data
  function modalCallBack() {
    let newState = { show: false, title: "", action: "", id: "" };
    setModal(newState);
    loadData(localUser);
  }

  async function loadData(user = localUser) {
    setIsProcessing(true);
    api.getService(`fuel/slip`).then(
      async (result) => {
        const data = result.data;
        console.log("api data", data);
        let tableData = await mapTableData(data.data, user);
        setTableData(tableData);
        setIsProcessing(false);
      },

      (error) => {
        console.log("error", error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
        setError(error);
      }
    );
  }

  // Mapping Grid Data From API
  function mapTableData(data, user) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        align: "center",
        resizable: false,
        headerSort: false,
      },
      {
        title: "Date",
        field: "date",
        vertAlign: "middle",
        headerSort: true,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
      },
      {
        title: "Slip No.",
        field: "slip_no",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vehicle No.",
        field: "vehicle.registration_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Vendor",
        field: "supplier.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Litres Filled",
        field: "quantity",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return `<p>${cell?.getValue()} L</p>`;
        },
      },
      {
        title: "Rate/Litre",
        field: "unit_price",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
      {
        title: "Amount",
        field: "amount",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
      },
    ];
    let response = {
      header: columns,
      content: data,
      filter: true,
      filterFields: [],
    };
    return response;
  }

  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    const user = props.user;
    return (
      <ActionButtons
        editButton={{
          show: true,
          onClick: (e, row) => {
            editRecord(cellData._id);
          },
        }}
        suspendButton={{
          show: cellData?.status === "active" ? true : false,
          onClick: (e) => {
            deleteRecord(cellData);
          },
        }}
      />
    );
  }

  ////// COMMON FUNCTIONS //////
  // Reload Grid Data
  function reloadData() {
    loadData(localUser);
  }
  // Create New Record
  async function addRecord() {
    let newState = {
      show: true,
      title: `Add Fuel Slip`,
      action: "create",
      id: "",
    };
    setModal(newState);
  }

  // View Record
  async function viewRecord(id) {
    let newState = {
      show: true,
      title: `Fuel Slip`,
      action: "edit",
      id: id,
    };
    setModal(newState);
  }

  // Edit Record
  async function editRecord(id) {
    let newState = {
      show: true,
      title: `View Fuel Slip`,
      action: "edit",
      id: id,
    };
    setModal(newState);
  }

  // Delete Record
  async function deleteRecord(data) {
    alert.confirmSuspension({
      text: "Are you sure want to delete this Slip?",
      onConfirm: function () {
        onDelete(data);
      },
    });
  }

  ////// COMMON FUNCTIONS //////

  function onDelete(data) {
    setIsProcessing(true);
    api
      .deleteService(`fuel/${data?._id}`)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function changeFilter(event) {
    const { name, value } = event.target;
    loadData(value, localUser);
  }

  return (
    <>
      <>
        {/* <!-- BEGIN: Modal --> */}
        {modal.show && (
          <FormMaster
            show={modal.show}
            action={modal.action}
            title={modal.title}
            onHide={closeModal}
            callBack={modalCallBack}
            id={modal.id}
          />
        )}
        {/* <!-- END: Modal --> */}

        <Container>
          {/* <!-- BEGIN: Header --> */}
          <Container.Header>
            <Container.Title className="text-lg font-medium">
              Fuel History
            </Container.Title>

            <Container.HeaderActions>
              {" "}
              <AddNewButton id="add-fuel-slip-button" onClick={addRecord}>
                Create Slip
              </AddNewButton>
            </Container.HeaderActions>
          </Container.Header>
          {/* <!-- END: Header --> */}

          {/*  <!-- BEGIN: Data Table -->  */}
          <Container.Body>
            {isProcessing && <ProcessLoader />}
            <CustomDataTable
              header={tableData.header}
              content={tableData.content}
              onRowClick={(e, row) => {
                viewRecord(row._row.data._id);
              }}
              filter={true}
              filterFields={tableData.filterFields}
            />
          </Container.Body>
          {/*  <!-- END: Data Table -->  */}
        </Container>
      </>
    </>
  );
}
