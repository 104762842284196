import React, { useState, useEffect } from "react";

// Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ProcessLoader from "../preloader/processLoader";
import PropTypes from "prop-types";

import {
  matchData,
  validateEmail,
  validatePhone,
} from "../../functions/common";

// HTML Components
import { Modal } from "react-bootstrap";
import {
  Typeahead,
  Menu,
  MenuItem,
  TypeaheadLabelKey,
} from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import UserCardInList from "../pageElements/userCardInList";

// Functions
import { resp } from "../../functions/responseHandler";
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";

// Images

import { ReactComponent as GmailIcon } from "../../assets/images/svg/gmail-icon.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/images/svg/whatsapp-icon.svg";
import { ReactComponent as Telegram } from "../../assets/images/svg/telegram-icon.svg";

const msgTabActiveClass =
  "cursor-pointer w-full sm:w-40 py-4 text-center flex justify-center items-center active";
const msgTabInactiveClass =
  "cursor-pointer w-full sm:w-40 py-4 text-center flex justify-center items-center";

const msgTabBodyActiveClass = "tab-pane p-5 active";
const msgTabBodyInactiveClass = "tab-pane p-5";

const Model = {
  email_channel: true,
  whatsapp_channel: false,
  enableCC: false,
  enableBCC: false,
  to: [],
  cc: [],
  bcc: [],
  subject: "",
  email_msg: "",
  whatsapp_msg: "",
  invoices: [],
};

export default function Component(props) {
  const [msgTab, setMsgTab] = useState("email");
  const [localUser, setLocalUser] = useState();
  const [textEditor, setTextEditor] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [defaultState, setDefaultState] = useState({ users: [] });
  const [state, setState] = useState({ ...Model });
  const [mode, setMode] = useState({ view: false, edit: false });
  useEffect(() => {
    const getUser = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
    };
    getUser();
    loadData(props);
  }, []);
  async function loadData(props) {
    setIsProcessing(true);
    const matchedData = await mapData(props.data);
    setState(matchedData);

    // Setting Data to CK Editor
    await ClassicEditor.create(document.querySelector("#email_editor"))
      .then((editor) => {
        setTextEditor(editor);
        editor.setData(matchedData["email_msg"]); // Save for later use.
      })
      .catch((error) => {
        console.error(error);
      });

    setIsProcessing(false);
  }

  function mapData(data) {
    let prevState = { ...state };
    console.log("data:", data);
    for (var i in data) {
      // Parsing Email Content
      if (data[i]["channel"] === "email") {
        prevState["subject"] = data[i]?.data["subject"];
        prevState["email_msg"] = data[i]?.data["html"];
        prevState["invoices"] = data[i]?.data["invoices"];
      }
      // Parsing whatsapp Content
      if (data[i]["channel"] === "whatsapp") {
        prevState["whatsapp_msg"] = data[i]?.data["text"];
        prevState["invoices"] = data[i]?.data["invoices"];
      }
    }
    return prevState;
  }

  function changeInput(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function changeEditorInput(event, editor) {
    const data = editor.getData();
    console.log("data:", typeof data);
    setState((prevState) => ({ ...prevState, email_msg: data }));
  }

  async function onSubmit(event) {
    event.preventDefault();

    setIsProcessing(true);
    let body = await prepareData();
    console.log("body:", body);

    if (body.length > 0) {
      api.postService(`notification/message`, body).then(
        async (result) => {
          resp.SuccessResponse(result);
          setIsProcessing(false);
          props.callBack(state);
        },
        (error) => {
          console.log("error", error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
          props.callBack(state);
        }
      );
    } else {
      resp.ErrorHandler("No Valid Email or Phone Number Found.");
    }
  }

  function formatEmailData(arrayData) {
    let response = [];
    for (var i in arrayData) {
      let to_data = "";
      let body = { email: "", user_type: "" };
      // Checking Ananymous User
      if (arrayData[i]?.customOption) {
        to_data = arrayData[i]?.fullname;
        body["user_type"] = "anonymous"; // contains no recipient_id
      } else {
        to_data = arrayData[i]?.email;
        body["user_type"] = "system_user";
        body["user_id"] = arrayData[i]?._id;
      }
      body["email"] = to_data;
      // Pushing Only Vaid Email Id
      if (validateEmail(to_data)) response.push(body);
    }
    return response;
  }

  function formatPhoneData(arrayData) {
    let response = [];
    for (var i in arrayData) {
      let to_data = "";
      let body = { phone: "", user_type: "" };
      // Checking Ananymous User
      if (arrayData[i]?.customOption) {
        to_data = arrayData[i]?.fullname;
        body["user_type"] = "anonymous"; // contains no recipient_id
      } else {
        to_data = arrayData[i]?.phone;
        body["user_type"] = "system_user";
        body["user_id"] = arrayData[i]?._id;
      }
      body["phone"] = to_data;
      // Pushing Only Vaid Phone
      if (validatePhone(to_data)) response.push(body);
    }
    return response;
  }

  async function prepareData() {
    let response = [];
    let stateData = { ...state };

    // Email Channel
    if (stateData?.email_channel) {
      let to_emails = await formatEmailData(stateData?.to);
      let email_json = {
        to: to_emails,
        channel: "email",
        module: props?.module,
        category: props?.category,
        sub_category: props?.sub_category,
        action_type: props?.action_type,
        from_title: localUser?.customer_account?.name, // Company Name
        subject: stateData?.subject,
        text: stateData?.subject,
        html: stateData?.email_msg,
        invoices: stateData?.invoices,
      };
      // Adding CC
      if (stateData?.cc?.length > 0) {
        email_json["cc"] = formatEmailData(stateData?.cc);
      }
      // Adding Bcc
      if (stateData?.bcc?.length > 0) {
        email_json["bcc"] = formatEmailData(stateData?.bcc);
      }

      let email_body = {
        channel: "email",
        data: [email_json],
      };

      if (to_emails.length > 0) {
        response.push(email_body);
      }
    }

    // Whatsapp Channel
    if (stateData?.whatsapp_channel) {
      let to_phones = await formatPhoneData(stateData?.to);
      let whatsapp_json = {
        channel: "whatsapp",
        to: to_phones,
        module: props?.module,
        category: props?.category,
        sub_category: props?.sub_category,
        action_type: props?.action_type,
        text: stateData?.whatsapp_msg,
        invoices: stateData?.invoices,
      };

      let whatsapp_body = {
        channel: "whatsapp",
        data: [whatsapp_json],
      };
      if (to_phones.length > 0) {
        response.push(whatsapp_body);
      }
    }

    return response;
  }

  // async function prepareData() {
  //   let response = [];
  //   let stateData = { ...state };

  //   // Email Channel
  //   if (stateData?.email_channel) {
  //     for (var i in stateData.to) {
  //       let email_json = {
  //         channel: "email",
  //         module: props?.module,
  //         category: props?.category,
  //         sub_category: props?.sub_category,
  //         action_type: props?.action_type,
  //         from_title: localUser?.customer_account?.name, // Company Name
  //         subject: stateData?.subject,
  //         text: stateData?.subject,
  //         html: stateData?.email_msg,
  //         invoices: stateData?.invoices,
  //       };
  //       let to_address = "";
  //       let cc_address = [];
  //       let bcc_address = [];
  //       // Checking for ananymous email
  //       if (stateData?.to[i]?.customOption) {
  //         to_address = stateData?.to[i]?.fullname;
  //         email_json["recipient"] = "anonymous"; // contains no recipient_id
  //       } else {
  //         to_address = stateData?.to[i]?.email;
  //         email_json["recipient"] = "user";
  //         email_json["recipient_id"] = stateData?.to[i]?._id;
  //       }
  //       email_json["to"] = to_address;

  //       // Adding CC
  //       if (stateData?.cc?.length > 0) {
  //         for (var i in stateData.cc) {

  //           if (validateEmail(stateData.cc[i]?.fullname))
  //             cc_address.push(stateData.cc[i]?.fullname);
  //         }
  //       }
  //       if (cc_address.length > 0) email_json["cc"] = cc_address;
  //       // Adding Bcc
  //       if (stateData?.bcc?.length > 0) {
  //         for (var i in stateData.bcc) {
  //           if (validateEmail(stateData.bcc[i]?.fullname))
  //             bcc_address.push(stateData.bcc[i]?.fullname);
  //         }
  //       }
  //       if (bcc_address.length > 0) email_json["bcc"] = bcc_address;

  //       let email_body = {
  //         channel: "email",
  //         data: [email_json],
  //       };
  //       // Pushing Only Vaid Email Id
  //       if (validateEmail(to_address)) response.push(email_body);
  //     }
  //   }

  //   // Whatsapp Channel
  //   if (stateData?.whatsapp_channel) {
  //     // let phones = await stateData.to.map((a) => a?.phone);
  //     // console.log("phones:", phones);
  //     for (var i in stateData.to) {
  //       let whatsapp_json = {
  //         channel: "whatsapp",
  //         module: props?.module,
  //         category: props?.category,
  //         sub_category: props?.sub_category,
  //         action_type: props?.action_type,
  //         text: stateData?.whatsapp_msg,
  //         invoices: stateData?.invoices,
  //       };
  //       let to_phone = "";
  //       // Checking for ananymous Phone
  //       if (stateData?.to[i]?.customOption) {
  //         to_phone = stateData?.to[i]?.fullname;
  //         whatsapp_json["recipient"] = "anonymous"; // contains no recipient_id
  //       } else {
  //         to_phone = stateData?.to[i]?.phone;
  //         whatsapp_json["recipient"] = "user";
  //         whatsapp_json["recipient_id"] = stateData?.to[i]?._id;
  //       }
  //       whatsapp_json["to"] = to_phone;

  //       let whatsapp_body = {
  //         channel: "whatsapp",
  //         data: [whatsapp_json],
  //       };
  //       // Pushing Only Vaid Phone
  //       if (validatePhone(to_phone)) response.push(whatsapp_body);
  //     }
  //   }

  //   return response;
  // }

  function onChangeCheckbox(event) {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: !state[name],
    }));
  }

  function loadUsers(reload = false) {
    if (defaultState?.users.length === 0 || reload) {
      setIsProcessing(true);
      api.getService(`user/status/active`).then(
        async (result) => {
          const data = result.data;
          console.log("user fetch", data);
          setDefaultState((prevState) => ({
            ...prevState,
            users: data.userAll,
          }));
          setIsProcessing(false);
        },
        (error) => {
          console.log("error", error);
          setIsProcessing(false);
          resp.ErrorHandler(error);
          setError(error);
        }
      );
    }
  }

  async function changeTypeHeadInputUsers(selected, name) {
    setState((prevState) => ({ ...prevState, [name]: selected }));
  }

  function changeMsgTab(channel) {
    setMsgTab(channel);
  }

  function switchInput(event) {
    const { name } = event.target;
    setState((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  }
  return (
    <Modal
      {...props}
      onHide={props.onHide}
      backdrop="static"
      fullscreen={true}
      keyboard={false}
      size="xl"
      id="email-preview-modal"
    >
      <Modal.Header closeButton>
        <h2 className="font-medium text-base mr-auto">{"Draft"}</h2>
      </Modal.Header>
      <Modal.Body>
        {isProcessing && <ProcessLoader />}

        <form
          onSubmit={onSubmit}
          id="email-preview-form"
          className="grid grid-cols-12 gap-4 gap-y-3"
        >
          <div className="col-span-12 flex items-center justify-center">
            <div className="checkbox mr-2">
              <label className="mr-2">
                <input
                  type="checkbox"
                  checked={state.email_channel}
                  name="email_channel"
                  onChange={onChangeCheckbox}
                />{" "}
                Email
              </label>
            </div>

            <div className="checkbox">
              <label className="mr-2">
                <input
                  type="checkbox"
                  checked={state.whatsapp_channel}
                  name="whatsapp_channel"
                  onChange={onChangeCheckbox}
                />{" "}
                Whatsapp
              </label>
            </div>
          </div>

          {/* <!--BEGIN: TO --> */}
          <div className="col-span-12">
            <label className="form-label" htmlFor="to">
              To
            </label>
            <div className="input-group space-x-2 items-center">
              <Typeahead
                clearButton
                allowNew
                newSelectionPrefix="Add New: "
                multiple
                filterBy={["fullname", "email", "phone"]}
                id="to"
                name="to"
                key="to"
                onFocus={(e) => loadUsers(false)}
                onChange={(selected) => {
                  changeTypeHeadInputUsers(selected, "to");
                }}
                // labelKey={(option) => `${option?.fullname}`}
                labelKey={"fullname"}
                options={defaultState?.users}
                selected={state?.to}
                placeholder=""
                disabled={mode.view}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, index) => (
                      <MenuItem option={result} position={index} key={index}>
                        <UserCardInList
                          id={`to_user_${index}`}
                          display={["email", "phone"]}
                          name={result?.fullname}
                          email={result?.email}
                          phone={result?.phone}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
              {!state?.enableCC && (
                <a
                  href={void 0}
                  className="text-gray-600 cursor-pointer font-semibold underline"
                  onClick={switchInput}
                  name="enableCC"
                  id="enableCC_button"
                >
                  Cc
                </a>
              )}
              {!state?.enableBCC && (
                <a
                  href={void 0}
                  className="text-gray-600 cursor-pointer font-semibold underline"
                  onClick={switchInput}
                  name="enableBCC"
                  id="enableBCC_button"
                >
                  Bcc
                </a>
              )}
            </div>
          </div>
          {/* <!--END: TO --> */}

          {/* <!--BEGIN: CC --> */}
          {state?.enableCC && (
            <div className="col-span-12">
              <label className="form-label" htmlFor="cc">
                CC
              </label>
              <Typeahead
                clearButton
                multiple
                allowNew
                newSelectionPrefix="Add New: "
                filterBy={["fullname", "email", "phone"]}
                id="cc"
                name="cc"
                key="cc"
                onFocus={(e) => loadUsers(false)}
                onChange={(selected) => {
                  changeTypeHeadInputUsers(selected, "cc");
                }}
                labelKey={"fullname"}
                options={defaultState?.users}
                selected={state?.cc}
                placeholder=""
                disabled={mode.view}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, index) => (
                      <MenuItem option={result} position={index} key={index}>
                        <UserCardInList
                          id={`cc_user_${index}`}
                          display={["email", "phone"]}
                          name={result?.fullname}
                          email={result?.email}
                          phone={result?.phone}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
            </div>
          )}
          {/* <!--END: CC --> */}

          {/* <!--BEGIN: Bcc --> */}
          {state?.enableBCC && (
            <div className="col-span-12">
              <label className="form-label" htmlFor="bcc">
                Bcc
              </label>
              <Typeahead
                clearButton
                allowNew
                newSelectionPrefix="Add New: "
                multiple
                filterBy={["fullname", "email", "phone"]}
                id="bcc"
                name="bcc"
                key="bcc"
                onFocus={(e) => loadUsers(false)}
                onChange={(selected) => {
                  changeTypeHeadInputUsers(selected, "bcc");
                }}
                labelKey={"fullname"}
                options={defaultState?.users}
                selected={state?.bcc}
                placeholder=""
                disabled={mode.view}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, index) => (
                      <MenuItem option={result} position={index} key={index}>
                        <UserCardInList
                          id={`bcc_user_${index}`}
                          display={["email", "phone"]}
                          name={result?.fullname}
                          email={result?.email}
                          phone={result?.phone}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
            </div>
          )}
          {/* <!--END: CC --> */}

          <div className="col-span-12">
            <label className="form-label" htmlFor="subject">
              Subject / Title
            </label>
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              placeholder=""
              disabled={mode?.view}
              onChange={changeInput}
              value={state.subject}
              required
            />
          </div>

          <div className="col-span-12">
            {/* <label className="form-label" htmlFor="message">
              Message
            </label> */}
            {/* <CKEditor
              // id="html"
              // id="email_editor"
              // name="email_editor"
              editor={ClassicEditor}
              initData={state?.html}
              data={state?.html}
              onReady={(editor) => {
                setTextEditor(editor);
                // You can store the "editor" and use when it is needed.
                // console.log("Editor is ready to use!", editor);
              }}
              onChange={changeEditorInput}
            /> */}
          </div>

          {/* <!-- BEGIN: Message Container --> */}
          <div className="col-span-12 post overflow-hidden box mt-3 shadow">
            {/* <!-- BEGIN: Channel Tab Headers --> */}
            <div
              class="pos__tabs nav nav-tabs flex-col sm:flex-row  dark:bg-dark-2 text-gray-600"
              role="tablist"
            >
              <a
                data-toggle="tab"
                data-target="#email_preview"
                href={void 0}
                className={
                  msgTab === "email" ? msgTabActiveClass : msgTabInactiveClass
                }
                onClick={(e) => changeMsgTab("email")}
                id="email_preview-tab"
                role="tab"
                aria-controls="email_preview"
                aria-selected="true"
                disabled={!state?.email_channel}
              >
                <GmailIcon width="32" height="32" className="inline mr-2" />{" "}
                Email Content
              </a>
              <a
                data-toggle="tab"
                data-target="#whatsapp_preview"
                href={void 0}
                onClick={(e) => changeMsgTab("whatsapp")}
                className={
                  msgTab === "whatsapp"
                    ? msgTabActiveClass
                    : msgTabInactiveClass
                }
                id="whatsapp_preview-tab"
                role="tab"
                aria-selected="false"
                disabled={!state?.whatsapp_channel}
              >
                <WhatsappIcon width="32" height="32" className="inline mr-2" />{" "}
                Whatsapp Content
              </a>
            </div>
            {/* <!-- END: Channel Tab Headers --> */}

            {/* <!-- BEGIN: Channel Tab Body --> */}
            <div className="col-span-12 post__content tab-content">
              {/* <!-- BEGIN: Email Preview --> */}
              <div
                id="email_preview"
                className={
                  msgTab === "email"
                    ? msgTabBodyActiveClass
                    : msgTabBodyInactiveClass
                }
                role="tabpanel"
                aria-labelledby="email_preview-tab"
              >
                <textarea
                  id="email_editor"
                  disabled={!state?.email_channel}
                ></textarea>
              </div>
              {/* <!-- END: Email Preview --> */}

              {/* <!-- BEGIN: Whatsapp Preview --> */}
              <div
                id="whatsapp_preview"
                className={
                  msgTab === "whatsapp"
                    ? msgTabBodyActiveClass
                    : msgTabBodyInactiveClass
                }
                role="tabpanel"
                aria-labelledby="whatsapp_preview-tab"
              >
                <textarea
                  id="whatsapp_editor"
                  type="text"
                  name="whatsapp_msg"
                  className="w-full"
                  rows={12}
                  cols={12}
                  onChange={changeInput}
                  value={state?.whatsapp_msg}
                  disabled={!state?.whatsapp_channel}
                ></textarea>
              </div>
              {/* <!-- END: Whatsapp Preview --> */}
            </div>
            {/* <!-- END: Channel Tab Body --> */}
          </div>
          {/* <!-- END: Message Container --> */}
          <input type="submit" id="send_email_preview" className="hidden" />
        </form>
      </Modal.Body>
      {/* <!-- BEGIN: Modal Footer --> */}
      <Modal.Footer>
        <button
          type="button"
          data-dismiss="modal"
          className="btn btn-secondary w-20 mr-2"
          onClick={props.onHide}
        >
          Cancel
        </button>{" "}
        <label
          type="button"
          className="btn btn-primary w-20 "
          htmlFor="send_email_preview"
        >
          Send
        </label>
      </Modal.Footer>
      {/* <!-- END: Modal Footer --> */}
    </Modal>
  );
}
