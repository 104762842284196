import React, { useState, useEffect } from "react";
// HTML Components
import Container from "../../components/container";
import ConsActionButtons from "../../components/pageElements/consActionButtons";
import TruckNumberDisplay from "../../components/pageElements/truckNumberDisplay";
import ConsStatusBadge from "../../components/consignment/statusBadge";
import CustomDataTable from "../../components/tables/customDataTable";
import Error from "../../components/error/error";
import HelpLinkButton from "../../components/htmlElements/helpLinkButton";
// Toaster & Notification
import { alert } from "../../components/notification/alert";
import { resp } from "../../functions/responseHandler";
// Loaders
import Preloader from "../../components/preloader/preloader";
import ProcessLoader from "../../components/preloader/processLoader";

// Functions
import * as api from "../../api_service/api";
import { storageServices } from "../../functions/storage";
import { reactFormatter } from "react-tabulator";
import {
  localDate,
  formatCurrency,
  mongoUTC,
  commaList,
  trimConsNum,
  navToPage,
  getConsignmentStage,
} from "../../functions/common";

// External Components
// import ConsignmentForm from "./new-form";
// import ConsignmentForm from "./new-form-broker";
import Filter from "./filter";
import ConsignmentForm from "./form-router";
import TransitTimeline from "../../components/consignment/transitTimeline";

// Model
import { Model } from "./model";

// Cookies & Storage
import Cookies from "universal-cookie";
const cookies = new Cookies();

const model = Model();

const filters = { status: "pending" };

const printHeaderTitle = {
  drafted: "Drafted Consignments",
  pending: "Prepared Consignments",
  in_progress: "In Transit Consignments",
  completed: "Completed Consignments",
  acknowledged: "Completed Consignments",
  all: "Consignments",
  "": "",
};

export default function Consignment(props) {
  const [localUser, setLocalUser] = useState();

  const [options, setOptions] = useState({
    showTab: false,
    show_amount: false,
    allowClick: false,
  });

  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSavingTransit, setIsSavingTransit] = useState(false);
  const [ip_transits, setIpTransits] = useState([...model.transits]);
  const [viewModeTransits, setViewModeTransits] = useState(false);
  const [transitsRecordId, setTransitsRecordId] = useState();

  const [tab, setTab] = useState({
    currentTab: "pending",
    key: "all",
    activeClass:
      "nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0 active",
    inActiveClass: "nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0",
  });

  // Modal Functions

  // Modal Functions
  const [formModal, setFormModal] = useState({
    show: false,
    action: "",
    id: "",
    biz_type: "",
  });

  function createConsignment(event) {
    event.preventDefault();
    let preFormModal = { ...formModal };
    preFormModal.action = "create";
    preFormModal.biz_type =
      localUser?.customer_account?.business_type || "broker";
    preFormModal.show = true;
    setFormModal(preFormModal);
    return null;
  }

  function createMultipleConsignments(event) {
    event.preventDefault();
    navToPage("/admin#/consignment/workbench/form-multiple");
  }

  const closeFormModal = () =>
    setFormModal((prevState) => ({ ...prevState, show: false }));

  function consFormCallBack(id, biz_type, action) {
    closeFormModal();
    // handleInit(localUser);
    if (action === "create") {
      let url = "";
      if (biz_type === "transporter") {
        url = `/admin#/consignment/view/submit/${id}`;
      } else {
        url = `/admin#/consignment/broker-view/submit/${id}`;
      }
      window.location.href = url;
    } else {
      handleInit(localUser);
    }

    return null;
  }

  function editConsignment(consData) {
    let preFormModal = { ...formModal };
    preFormModal.action = "edit";
    preFormModal.show = true;
    preFormModal.id = consData?._id;
    preFormModal.biz_type = consData?.created_business_type;
    setFormModal(preFormModal);
    return null;
  }

  function cloneConsignment(consData) {
    let preFormModal = { ...formModal };
    preFormModal.action = "clone";
    preFormModal.show = true;
    preFormModal.id = consData?._id;
    preFormModal.biz_type = consData?.created_business_type;
    setFormModal(preFormModal);
    return null;
  }

  //Modal
  const [status, setStatus] = useState();

  // <!--BEGIN : Transit Timeline Modal -->
  const [transitTimelineModal, setTransitTimelineModal] = useState({
    show: false,
    vehicle: {},
    consStage: 0,
    consData: {},
  });
  const closeTransitTimelineModal = () =>
    setTransitTimelineModal((prevState) => ({ ...prevState, show: false }));

  const openTransitTimelineModal = () =>
    setTransitTimelineModal((prevState) => ({ ...prevState, show: true }));
  // <!--END : Transit Timeline Modal -->

  useEffect(() => {
    const loadData = async () => {
      // Getting Local User Data
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);

      setIsLoaded(true);

      handleInit(localUserData);

      // loadConsignment("pending", "single", localUserData?.access);
    };
    loadData();
  }, []);

  function handleInit(localUserData) {
    var initFilter = { status: "pending" };
    // let prevStatus = cookies.get("consignment_status_filter");
    // if (prevStatus) {
    //   initFilter["status"] = prevStatus;
    // }

    loadConsignment(initFilter);
    // Checking Access
    // if (
    //   localUserData?.access?.create_consignment ||
    //   localUserData?.access?.approve_consignment ||
    //   localUserData?.access?.acknowledge_consignment ||
    //   localUserData?.role?.is_owner
    // ) {
    //   prevOptions.showTab = true;
    //   prevOptions.show_amount = true;
    //   prevOptions.allowClick = true;
    //   setOptions(prevOptions);
    //   loadAllTabs(localUserData);
    // } else if (localUserData?.access?.create_transits) {
    //   prevOptions.showTab = false;
    //   prevOptions.show_amount = false;
    //   prevOptions.allowClick = false;
    //   setOptions(prevOptions);
    //   loadConsignment("in_progress", "all", localUserData);
    // }
  }

  function loadAllTabs(localUserData) {
    let prevTab = cookies.get("consignment_tab");
    let prevType = cookies.get("consignment_type_tab");

    let status = props.match.params?.status;
    if (status) {
      prevTab = status;
      prevType = "all";
      loadConsignment(prevTab, prevType, localUserData);
    } else if (
      typeof prevTab !== "undefined" &&
      typeof prevType !== "undefined"
    ) {
      loadConsignment(prevTab, prevType, localUserData);
    } else {
      loadConsignment("pending", "all", localUserData);
    }
  }

  const changeTransits = (event, index) => {
    const { name, value } = event.target;
    const list = [...ip_transits];
    list[index][name] = value;
    setIpTransits(list);
    // setState((prevState) => ({ ...prevState, load_receipts: list }));
  };

  function removeTransits(index) {
    var list = [...ip_transits];
    list.splice(index, 1);
    setIpTransits(list);
  }

  function addTransits() {
    var list = [...ip_transits];
    const model = Model();
    list.push(model.transits[0]);
    setIpTransits(list);
  }

  function saveTransits(event) {
    event.preventDefault();
    setIsSavingTransit(true);
    var data = [...ip_transits];
    for (var i in data) {
      data[i].time = mongoUTC(data[i].time);
    }
    let body = { transits: data };
    api
      .putService(`consignment/${transitsRecordId}`, body)
      .then((response) => {
        setIsSavingTransit(false);
        resp.SuccessResponse(response);
      })
      .catch((error) => {
        resp.ErrorHandler(error);
        console.log(error);
        setIsSavingTransit(false);
      });
  }

  async function onComplete(id) {
    setIsProcessing(true);

    let body = {
      status: "completed",
      completed_at: await mongoUTC(new Date()),
    };
    api
      .putService(`consignment/${id}`, body)
      .then((response) => {
        resp.ResponseHandler(response);
        setIsProcessing(false);
      })
      .catch((error) => {
        resp.ErrorHandler(error);
        console.log(error);
        setIsProcessing(false);
      });
  }

  // Delete Record
  async function deleteRecord(data) {
    alert.confirmDeletion({
      text: "Are you sure want to delete this consignment?",
      onConfirm: function () {
        onDelete(data._id, localUser);
      },
    });
  }

  async function completeRecord(id, access) {
    alert.confirmation({
      type: "info",
      title: "Confirm Completion!",
      text: "Are you sure want to complete this consignment?",
      onConfirm: function () {
        onComplete(id, access);
      },
    });
  }

  function onDelete(id) {
    setIsProcessing(true);
    // if (access?.delete_consignment) {
    api
      .deleteService(`consignment/${id}`)
      .then((response) => {
        resp.SuccessResponse(response);
        setIsProcessing(false);
        loadConsignment(filters);
      })
      .catch((error) => {
        resp.ErrorHandler(error);
        console.log(error);
        setIsProcessing(false);
      });
    // } else {
    //   setIsProcessing(false);
    //   resp.Error("You don't have permission to delete.");
    // }
  }

  function viewRecord(action, consData) {
    let id = consData?._id;
    let status = consData?.status;
    let biz_type = consData?.created_business_type;

    // event.preventDefault();

    if (status === "drafted") {
      editConsignment(consData);
    } else {
      let url = "";
      if (biz_type === "transporter") {
        url = `/admin#/consignment/view/${action}/${id}`;
      } else {
        url = `/admin#/consignment/broker-view/${action}/${id}`;
      }
      window.location.href = url;
    }
  }

  function onRunningStatusClick(consData) {
    let prevState = { ...transitTimelineModal };
    prevState["vehicle"] = consData?.vehicle;
    prevState["consStage"] = getConsignmentStage(consData?.status);
    prevState["consData"] = consData;
    prevState["show"] = true;
    setTransitTimelineModal(prevState);
  }
  function ActionButtonsLookup(props) {
    const cellData = props.cell._cell.row.data;
    const status = cellData?.status;
    const consStage = getConsignmentStage(status);

    return (
      <ConsActionButtons
        callDriverButton={{
          show: consStage < 4,
          phone_number: cellData?.driver?.contact_number,
          onClick: (e) => {},
        }}
        runningStatusButton={{
          show: status === "in_progress",
          onClick: (e) => {
            onRunningStatusClick(cellData);
          },
        }}
        editButton={{
          show: consStage < 3,
          onClick: (e) => {
            editConsignment(cellData);
          },
        }}
        cloneButton={{
          show: consStage > 0,
          onClick: (e) => {
            cloneConsignment(cellData);
          },
        }}
        deleteButton={{
          show: consStage < 3,
          onClick: (e) => {
            deleteRecord(cellData, localUser);
          },
        }}
      />
    );
  }

  async function mapTableData(data, status, localUserData) {
    const columns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        hozAlign: "center",
        vertAlign: "middle",
        resizable: false,
        headerSort: false,
        print: false,
        download: false,
      },
      {
        title: "Date",
        field: "consignment_date",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return localDate(cell?.getValue());
        },
        width: 90,
        sorter: "date",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
      },
      {
        title: "Cons. No.",
        field: "consignment_id",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 75,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        // formatter: reactFormatter(<ConsNumberCell onClick={editRecord}/>),

        cellClick: function (e, cell) {
          var rowData = cell.getData();
          viewRecord("edit", rowData);
        },

        formatter: function (cell) {
          return `<div class="text-theme-1 font-medium">${trimConsNum(
            cell?.getValue()
          )}</div>
         `;
        },
      },

      {
        title: "Client",
        field: "client.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        width: 150,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        // formatter: "textarea",
      },
      {
        title: "Vehicle",
        field: "vehicle.registration_number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        width: 100,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        // formatter: reactFormatter(<TruckNumberFormatter />),

        // formatter(cell, formatterParams) {
        //   var rowData = cell.getData();

        //   return `<div><div class="font-medium">${
        //     rowData?.vehicle?.registration_number || ""
        //   }</div>
        //   <div class=" text-gray-600">${
        //     rowData?.vehicle?.vehicle_type?.vehicle_type || ""
        //   }</div>
        //   </div>`;
        // },
      },

      // {
      //   title: "Vehicle Supplier",
      //   field: "supplier.name",
      //           vertAlign: "middle",
      // },
      {
        title: "Origin",
        field: "origin.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        // width: 150,
        // formatter: "textarea",
      },
      {
        title: "Destination",
        field: "destination.name",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        // width: 150,
        // formatter: "textarea",
      },

      {
        title: "LR Number",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        field: "lr_number",
        width: 80,
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        headerSort: false,
        print: false,
        download: false,
        reponsive: 2,
        // formatter: reactFormatter(<LRNumbersDisplayFormatter />),
        // formatter(cell, formatterParams) {
        //   return commaList(cell.getValue(), "lr_number");
        // },

        cellClick: function (e) {
          e.stopPropagation();
        },
      },

      {
        title: "LR No.",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        field: "load_receipts",
        print: true,
        download: true,
        visible: false,
        formatter(cell, formatterParams) {
          return commaList(cell.getValue(), "lr_number");
        },
      },

      {
        title: "Amount",
        field: "charges.consignment_total_amount",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        formatter: function (cell) {
          return formatCurrency(cell?.getValue());
        },
        width: 90,
        // visible: options?.show_amount,
        visible: true,
        reponsive: 2,
      },
      {
        title: "Status",
        field: "status",
        vertAlign: "middle",
        headerFilter: true,
        headerFilterPlaceholder: "Search...",
        download: false,
        formatter: reactFormatter(<ConsStatusBadge module="tabulator" />),
        visible: status === "all" ? true : false,
        reponsive: 2,
        cellClick: function (e) {
          e.stopPropagation();
        },
      },

      // {
      //   title: "Actions",
      //   headerSort: false,
      //   print: false,
      //   download: false,
      //   visible: status === "invoiced" ? false : true,
      //   reponsive: 4,
      //   // responsive: 1,
      //   // width:250,
      //   formatter: reactFormatter(
      //     <ActionButtonsLookup user={localUserData} status={status} />
      //   ),
      //   cellClick: function (e) {
      //     e.stopPropagation();
      //   },
      // },

      {
        title: "Actions",
        field: "",
        vertAlign: "middle",
        headerFilter: false,
        download: false,
        headerSort: false,
        formatter: reactFormatter(
          <ActionButtonsLookup user={localUserData} status={status} />
        ),
        cellClick: function (e) {
          e.stopPropagation();
        },
        minWidth: 40,
        maxWidth: 60,
      },
    ];

    setTableData({
      header: columns,
      content: data,
      filter: true,
      printHeader: printHeaderTitle[status],
    });
  }

  function TruckNumberFormatter(props) {
    const cellData = props?.cell?._cell?.row?.data;

    return (
      <TruckNumberDisplay
        data={cellData?.vehicle}
        id={"vehicle_" + cellData?.vehicle?._id}
      />
    );
  }

  // Formatter Functions

  function addPage() {
    window.location.href = "/admin#/consignment/form/add";
  }
  async function loadConsignment(filters, localUserData = localUser) {
    cookies.set("consignment_status_filter", filters?.status, { path: "/" });
    // cookies.set("consignment_type_tab", type, { path: "/" });

    setIsProcessing(true);
    // let oldTab = { ...tab };
    // oldTab.currentTab = status;
    // oldTab.key = type;
    // setTab(oldTab);
    await api
      .postService(`consignment/get`, { filters: filters })
      .then(async (response) => {
        var data = response?.data?.data;
        data = await mapData(data);
        await mapTableData(data, status, localUserData);
        setIsProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      });
  }

  async function mapData(data) {
    // Extracting LR Number
    for (var i in data) {
      data[i]["lr_number"] = await commaList(
        data[i]["load_receipts"],
        "lr_number"
      );
    }
    return data;
  }

  function changeConsType(event) {
    const { name, value } = event.target;
    loadConsignment(tab.currentTab, value);
  }

  if (error) {
    return <Error />;
  } else if (!isLoaded) {
    return <Preloader />;
  } else {
    return (
      <Container>
        {/* <!-- BEGIN: Form Modal --> */}
        {formModal.show && (
          <ConsignmentForm
            show={formModal.show}
            action={formModal.action}
            onHide={closeFormModal}
            callBack={consFormCallBack}
            id={formModal.id}
            biz_type={formModal?.biz_type}
          />
        )}
        {/* <!-- END: Form Modal --> */}

        {/* <!-- BEGIN: Header --> */}
        <Container.Header>
          <Container.Title className="text-lg font-medium">
            Consignment
            <HelpLinkButton href="https://corelogis.in/docs-category/app-guide-consignment/" />
          </Container.Title>

          <Container.HeaderActions>
            {localUser?.access?.create_consignment && (
              <a
                className="btn btn-outline-primary shadow-md py-1.5"
                href={void 0}
                onClick={createMultipleConsignments}
              >
                Trip Planning
              </a>
            )}

            {localUser?.access?.create_consignment && (
              <a
                className="btn btn-primary shadow-md ml-2 py-1.5"
                href={void 0}
                onClick={createConsignment}
              >
                <i className="fa fa-plus mr-2" aria-hidden="true"></i> Create
              </a>
            )}

            {/* <!-- END: Add Button --> */}
          </Container.HeaderActions>
        </Container.Header>
        {/* <!-- END: Header --> */}

        {/*  <!-- BEGIN: Data Table -->  */}
        <Container.Body>
          {isProcessing && <ProcessLoader />}

          {/*  <!-- BEGIN: External Components -->  */}
          <>
            {/* <!-- BEGIN: Timeline Modal --> */}
            {transitTimelineModal.show && (
              <TransitTimeline
                show={transitTimelineModal.show}
                vehicle={transitTimelineModal?.vehicle}
                consStage={transitTimelineModal?.consStage}
                consData={transitTimelineModal?.consData}
                onHide={closeTransitTimelineModal}
              />
            )}
            {/* <!-- END: Timeline Modal--> */}
          </>
          {/*  <!-- END: External Components -->  */}

          {/* <!--BEGIN: PLAY AREA --> */}

          <div className="grid grid-cols-12 gap-2">
            <div
              className="col-span-12 md:col-span-3 xxl:col-span-2 mt-2"
              id="filter-area"
            >
              <Filter loadData={loadConsignment} />
            </div>

            <div className="col-span-12 md:col-span-9 xxl:col-span-10">
              <CustomDataTable
                header={tableData.header}
                content={tableData.content}
                printHeader={tableData.printHeader}
                onRowClick={(e, row) => {
                  viewRecord("edit", row?._row?.data);
                }}
                filter={true}
                filterFields={tableData.filterFields}
                layout="fitColumns"
              />
            </div>
          </div>
          {/* <!--END: PLAY AREA --> */}
        </Container.Body>
        {/*  <!-- END: Data Table -->  */}
      </Container>
    );
  }
}
