import React from "react";
import PropTypes from "prop-types";

export default function Component(props) {
  const { data, className, id, size, ...rest } = props;

  return (
    <div className={className} id={id}>
      <div id="reg_no_own_type">
        <div className="font-medium mb-1">
          {/* <i className="fa fa-truck text-theme-3 mr-1" aria-hidden="true"></i> */}
          {data?.registration_number}
        </div>

        {data?.vehicle_type?.vehicle_type && (
          <div id="vehicle_type">
            <small className="ml-1 text-gray-600">
              {data?.vehicle_type?.vehicle_type}
            </small>
          </div>
        )}
      </div>

      {data?.ownership_type && (
        <div className="ml-auto" id="vehicle_ownership_type">
          <small
            className={
              data?.ownership_type === "supplier"
                ? "bg-theme-17 text-theme-11 rounded uppercase px-2 py-1"
                : "bg-theme-14 text-theme-10 rounded uppercase px-2 py-1"
            }
          >
            {data?.ownership_type === "supplier"
              ? "Supplier"
              : data?.ownership_type}
          </small>
        </div>
      )}
    </div>
  );
}

Component.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.object,
  size: PropTypes.string,
  // Functions
  onClick: PropTypes.func,
};

Component.defaultProps = {
  // Variables
  className: "flex",
  object: {
    registration_number: "",
    ownership_type: "",
    vehicle_type: { vehicle_type: "" },
    vehicleType: "",
  },
  size: "", // sm,lg,xl
  // Functions
  onClick() {},
};
