import * as React from "react";
import PropTypes from "prop-types";
import "./thumbnail.css";
const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL;
export default function Thumbnail(props) {
  function getExtension(string) {
    return string.split(".").pop();
  }
  const { src, name, viewMode, onDelete, baseURL } = props;
  var fileSrc = "";

  if (baseURL) {
    fileSrc = baseURL + src;
  } else {
    fileSrc = REACT_APP_FILE_URL + src;
  }

  var fileType = getExtension(src);
  try {
    fileType = fileType.toLowerCase();
  } catch {}
 
  return (
    <>
      <div className="clearfix"></div>
      <div className="thumbnail-container">
        {(fileType === "jpeg" ||
          fileType === "jpg" ||
          fileType === "png" ||
          fileType === "gif") && (
          <img
            className="img-fluid img-thumbnail thumbnail-image"
            name={name}
            src={fileSrc}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg";
            }}
          />
        )}
        {fileType === "pdf" && (
          <embed
            className="img-fluid img-thumbnail thumbnail-image overflow-hidden"
            name="plugin"
            src={fileSrc}
            type="application/pdf"
          ></embed>
        )}

        <div className="overlay">
          <div className="button-group d-flex justify-content-center">
            {!viewMode && (
              <button
                className="btn btn-danger rounded-circle mr-2"
                onClick={(event) => onDelete(event, name)}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            )}

            <a
              download
              href={fileSrc}
              className="btn btn-success rounded-circle mr-2"
            >
              <i className="fa fa-download" aria-hidden="true"></i>
              {/* <button type="button" className="btn btn-dark rounded-circle">
            <i className="fa fa-download" aria-hidden="true"></i>
          </button> */}
            </a>

            <a
              href={fileSrc}
              target="_blank"
              className="btn btn-primary rounded-circle"
            >
              <i className="fa fa-external-link" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

Thumbnail.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  viewMode: PropTypes.bool,
  onDelete: PropTypes.func,
};

Thumbnail.defaultProps = {
  src: "",
  name: "",
  viewMode: false,
  onDelete() {},
};
