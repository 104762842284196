import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { capitalizeRmScore } from "../../functions/common";
import FeatherIcons from "../../assets/js/feather";
export default function StatusBadge(props) {
  const { text, onClick } = props;

  // const className = {
  //   ACTIVE: "badge badge-success",
  //   INACTIVE: "badge badge-secondary",
  // };
  // let data = toUpperRmScore(text);
  // return <span className={className[data]}>{data}</span>;

  const className = {
    ACTIVE: "flex items-center lg:justify-center text-theme-9",
    INACTIVE: "flex items-center lg:justify-center text-theme-6",
    Active: "flex items-center lg:justify-center text-theme-9",
    Inactive: "flex items-center lg:justify-center text-theme-6",
    active: "flex items-center lg:justify-center text-theme-9",
    inactive: "flex items-center lg:justify-center text-theme-6",
  };
  let data = capitalizeRmScore(text);

  useEffect(() => {
    FeatherIcons();
  });

  return (
    <div className={className[data]}>
      <i className="fa fa-check-square-o w-4 h-4 mr-2" aria-hidden="true"></i>
      {/* <i data-feather="check-square" className="w-4 h-4 mr-2"></i> */}
      {data}
    </div>
  );
}

StatusBadge.propTypes = {
  //Variables
  text: PropTypes.string,
  // Functions
  onClick: PropTypes.func,
};

StatusBadge.defaultProps = {
  // Variables
  text: "",
  // Functions
  onClick() {},
};
