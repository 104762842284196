import PropTypes from "prop-types";

/* <!-- BEGIN: Form --> */
const Form = (props) => {
  const { id, className, onSubmit } = props;
  return (
    <form className={className} id={id} onSubmit={onSubmit}>
      {props.children}
    </form>
  );
};
Form.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};
Form.defaultProps = {
  id: "custom-form",
  className: "w-full",
  onSubmit() {},
};

/* <!-- BEGIN: Form --> */

/* <!-- BEGIN: Row --> */
const Row = (props) => {
  const { className, id } = props;
  return (
    <div className={"flex flex-wrap -mx-3 mb-5 " + className}>
      {props.children}
    </div>
  );
};
Row.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};
Row.defaultProps = {
  id: "row",
  className: "",
};
/* <!-- BEGIN: Row --> */

/* <!-- BEGIN: Col --> */
const Col = (props) => {
  const { className, id } = props;
  return (
    <div className={"w-full md:w-auto px-2 mb-6 md:mb-3 " + className}>
      {props.children}
    </div>
  );
};
Col.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};
Col.defaultProps = {
  id: "col",
  className: "",
};
/* <!-- BEGIN: Col --> */

/* <!-- BEGIN: Label --> */
const Label = (props) => {
  const { className, htmlFor, required } = props;
  return (
    <label htmlFor={htmlFor} className={"form-label " + className}>
      {props.children}
      {/* {required && <span className="text-theme-6">*</span>} */}
    </label>
  );
};
Label.propTypes = {
  htmlFor: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
};
Label.defaultProps = {
  htmlFor: "",
  className: "",
  required: false,
};
/* <!-- BEGIN: Label --> */

/* <!-- BEGIN: Input --> */
const Input = (props) => {
  const {
    className,
    type,
    name,
    id,
    onChange,
    value,
    required,
    disabled,
    placeholder,
    max,
    min,
    checked,
  } = props;
  return (
    <input
      className={className}
      type={type}
      name={name}
      id={id}
      onChange={onChange}
      value={value}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      max={max}
      min={min}
      checked={checked}
    />
  );
};
Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  placeholder: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
};
Input.defaultProps = {
  className: "form-control",
  placeholder: "",
  type: "",
  name: "",
  id: "",
  onChange() {},
  value: "",
  required: false,
  disabled: false,
  checked: false,
  max: "",
  min: "",
};
/* <!-- BEGIN: Input --> */

Form.Row = Row;
Form.Col = Col;
Form.Label = Label;
Form.Input = Input;

export default Form;
