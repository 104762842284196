import React, { useState, useEffect } from "react";
// Loaders
import ProcessLoader from "../../components/preloader/processLoader";
import InLineLoader from "../../components/preloader/inLineLoader";
// HTML Elements

import Container from "../../components/container";
import Card from "../../components/card/custom";
import DividerText from "../../components/htmlElements/dividerText";
import CurrencyInput from "../../components/htmlElements/currencyInput";
import WeightInput from "../../components/htmlElements/weightInput";
import InfoButton from "../../components/htmlElements/infoButton";
import LoadShortageAlert from "../../components/pageElements/loadShortageAlert";
// Functions
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";
import { storageServices } from "../../functions/storage";
import {
  htmlDateTime,
  matchData,
  localDate,
  htmlDate,
  getAddressHtmlString,
  getAddressString,
  formatCurrency,
  mongoUTC,
  toFloat,
  getInvoiceStage,
  isDefined,
} from "../../functions/common";
import { invoiceReport } from "../../functions/reports";
import { alertBox } from "../../components/notification/alert";
// Models
import { Model } from "./model-invoice";
import * as consModel from "../Consignment/model";
// CSS
import "./style.css";

import ChargesForm from "../../components/consignment/chargesForm";

const fileURL = process.env.REACT_APP_FILE_URL;

const fieldLabels = {
  unit_measure: {
    fixed: "",
    per_ton: "Ton",
    per_kg: "Kg",
    per_km: "Km",
    per_trip: "Trip",
    per_day: "Day",
    per_hour: "Hour",
    per_litre: "Litres",
    per_bag: "Bags",
  },
  billing_unit: {
    fixed: "Fixed",
    per_ton: "Per Ton",
    per_kg: "Per Kg",
    per_km: "Per Km",
    per_trip: "Per Trip",
    per_day: "Per Day",
    per_hour: "Per Hour",
    per_litre: "Per Litre",
    per_bag: "Per Bag",
  },
  unit_price: {
    fixed: "",
    per_ton: "Rate Per Ton",
    per_kg: "Rate Per Kg",
    per_km: "Rate Per Km",
    per_trip: "Rate Per Trip",
    per_day: "Rate Per Day",
    per_hour: "Rate Per Hour",
    per_litre: "Rate Per Litre",
    per_bag: "Rate Per Bag",
  },

  total_units: {
    fixed: "",
    per_ton: "Total Tons",
    per_kg: "Total Kg",
    per_km: "Total Km",
    per_trip: "Total Trips",
    per_day: "Total Days",
    per_hour: "Total Hours",
    per_litre: "Total Litres",
    per_bag: "Total Bags",
  },
};

export default function FormInvoice(props) {
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [localUser, setLocalUser] = useState();
  const [organization, setOrganization] = useState();

  const [pageTitle, setPageTitle] = useState("Invoice");
  const [submitMode, setSubmitMode] = useState(false);
  const [userAction, setUserAction] = useState();
  const [recordId, setRecordId] = useState();

  const [state, setState] = useState(Model());
  const [invoiceStage, setInvoiceStage] = useState("");
  const [loader, setLoader] = useState({
    save: false,
    approve: false,
    generate: false,
    submit: false,
  });

  // Modes
  const [mode, setMode] = useState({
    edit: false,
    generate: false,
    approve: false,
    submit: false,
    cancelled: false,
    view: false,
  });

  const [rawData, setRawData] = useState();
  // Consignment State
  const [stateCons, setStateCons] = useState(consModel.Model());

  const [chargesFormModal, setChargesFormModal] = useState({
    show: false,
    action: "",
    data: {},
    id: "",
    index: "",
    charges_key: "",
    module: "",
  });

  // <!-- BEGIN: Charges Form Modal -- >
  function openChargesFormModal(module, type) {
    let prevState = { ...chargesFormModal };
    prevState.action = "create";
    prevState.show = true;
    prevState.index = "";
    prevState.charge_type = type;
    prevState.module = module;
    setChargesFormModal(prevState);
  }

  const closeChargesFormModal = () =>
    setChargesFormModal((prevState) => ({ ...prevState, show: false }));

  function chargesFormCallBack(data, action, index, charge_type, module) {
    closeChargesFormModal();
    let charges_array_key = `${module}_${charge_type}`;
    chargesFormCallBackFunction(data, action, index, charges_array_key, module);
  }

  function chargesFormCallBackFunction(
    data,
    action,
    index,
    charges_array_key,
    module
  ) {
    let prevState = { ...state };
    let array = prevState[charges_array_key] || [];

    if (action === "create") {
      array.push(data);
    }
    if (action === "update") {
      array[index] = data;
    }
    prevState[charges_array_key] = array;
    setState((prevState) => ({
      ...prevState,
      [charges_array_key]: array,
    }));

    calcTotalBillAmt(prevState);

    return null;
  }

  function editChargesItem(event, item, index, charge_type, module) {
    event.preventDefault();
    let prevState = { ...chargesFormModal };
    prevState.action = "edit";
    prevState.data = item;
    prevState.index = index;
    prevState.charge_type = charge_type;
    prevState.module = module;
    prevState.show = true;
    setChargesFormModal(prevState);
  }

  function deleteChargesItem(event, item, index, charges_array_key, module) {
    event.preventDefault();
    alertBox.confirmation({
      type: "info",
      title: "Delete Charges",
      text: "Are you sure want to delete this Charge?",
      onConfirm: function () {
        confirmDeleteChargesItem(item, index, charges_array_key, module);
      },
    });
  }

  function confirmDeleteChargesItem(item, index, charges_array_key, module) {
    let prevState = { ...state };
    var arrayElement = prevState[charges_array_key];
    arrayElement.splice(index, 1);

    prevState[charges_array_key] = arrayElement;
    setState((prevState) => ({
      ...prevState,
      [charges_array_key]: arrayElement,
    }));
    calcTotalBillAmt(prevState);
  }

  // <!-- END: Charges Form Modal -- >

  useEffect(() => {
    const getUser = async () => {
      let localUserData = await storageServices.getCurrentUser();
      setLocalUser(localUserData);
      localUserData.organization.logo =
        fileURL + localUserData.organization.logo;
      setOrganization(localUserData.organization);
    };
    getUser();
    handleUserAction(props);
    // state.invoice_number = randomNumber();

    // setState(stateValue);
  }, []);

  async function handleUserAction(props) {
    let action = props.match.params?.action;
    let id = props.match.params?.id;
    setRecordId(id);
    setUserAction(action);
    loadInvoiceById(id);

    // switch (action) {
    //   case "generate":
    //     setPageTitle("Generate Invoice");
    //     loadInvoiceByIdCons(id);
    //     break;

    //   // case "create":
    //   //   setPageTitle("Create Invoice");
    //   //   loadConsignmentById(id);
    //   //   break;
    //   case "view":
    //     setPageTitle("View Invoice");
    //     setViewMode(true);
    //     loadInvoiceById(id);
    //     break;
    //   // case "generate":
    //   //   setPageTitle("Generate Invoice");
    //   //   loadInvoiceById(id);
    //   //   break;
    //   case "submit":
    //     setPageTitle("Submit Invoice");
    //     setViewMode(true);
    //     setSubmitMode(true);
    //     loadInvoiceById(id);
    //     break;
    //   default:
    //     setViewMode(true);
    // }
  }

  async function loadAgreement(data) {
    let freight_amount = null;
    const consData = data?.consignment;
    let query = {
      client: data?.client?._id,
      agreement_info: {
        $elemMatch: {
          origin: consData?.origin?._id,
          destination: consData?.destination?._id,
          vehicle_type: consData?.vehicle?.vehicle_type?._id,
        },
      },
    };
    await api
      .postService(`client_agreements/master/get_agreement_info`, query)
      .then(
        async (result) => {
          const agreementData = result?.data?.data;
          if (agreementData) {
            freight_amount = await processAgreement(agreementData);
          } else {
            resp.Information("Party level agreement not found!");
          }
        },
        (error) => {
          console.log("error", error);
          resp.ErrorHandler(error);
        }
      );
    return freight_amount;
  }
  function processAgreement(data) {
    let freight_amount = null;
    let agreement_info = data["agreement_info"];
    if (agreement_info && agreement_info.length > 0) {
      freight_amount = agreement_info[0]?.cost;
    }
    return freight_amount;
  }

  async function loadInvoiceById(id) {
    setIsProcessing(true);
    api.getService(`invoice/${id}`).then(
      async (result) => {
        let data = result.data;

        setRawData(data);
        await handleDataByStatus(data.status, data);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        setError(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      }
    );
  }

  async function handleDataByStatus(status, data) {
    switch (status) {
      case "waiting_for_approval":
        setPageTitle("Generate Invoice");
        setMode((prevState) => ({ ...prevState, generate: true }));

        // Regenerating Invoice
        if (data?.regenerate || isDefined(data?.invoice_number)) {
          await mapInvoiceData(data);
        } else {
          await mapInvoiceConsignmentData(data);
        }

        break;
      case "approved":
        setPageTitle("Generate Invoice");
        setMode((prevState) => ({ ...prevState, generate: true }));
        // Regenerating Invoice
        if (data?.regenerate || isDefined(data?.invoice_number)) {
          await mapInvoiceData(data);
        } else {
          await mapInvoiceConsignmentData(data);
        }

        break;
      case "invoiced":
        setPageTitle("Submit Invoice");
        setMode((prevState) => ({ ...prevState, submit: true }));
        await mapInvoiceData(data);
        break;
      case "submitted":
        setPageTitle("Invoice");
        setMode((prevState) => ({ ...prevState, view: true }));
        await mapInvoiceData(data);
        break;
      case "cancelled":
        setPageTitle("Invoice");
        setMode((prevState) => ({ ...prevState, view: true }));
        setMode((prevState) => ({ ...prevState, cancelled: true }));
        await mapInvoiceData(data);
        break;
      default:
        setMode((prevState) => ({ ...prevState, view: true }));
    }
  }

  async function mapInvoiceConsignmentData(data) {
    let invoice_stage = getInvoiceStage(data?.status);
    setInvoiceStage(invoice_stage);

    setStateCons(data?.consignment);

    let prevState = { ...state };
    const consData = data?.consignment;

    prevState.client_name = data?.client?.name;
    prevState.address = getAddressString(data.client);
    prevState.email = data?.client?.email_invoice | "";

    prevState.consignment = consData?._id;

    prevState.actual_weight = consData?.total_invoice_weight;
    prevState.billable_weight = consData?.total_invoice_weight;

    prevState.max_vehicle_capacity =
      consData.vehicle?.vehicle_type?.max_vehicle_capacity || 0;

    prevState.loading_point = consData?.loading_point;

    //   ****** NEW CHANGES ********
    prevState.loading_point = consData?.loading_point || {};
    prevState.unloading_point = consData?.unloading_point || {};

    // Addition / Deduction

    prevState["client_addition"] =
      consData?.charges?.consignment_addition || [];
    prevState["client_deduction"] =
      consData?.charges?.consignment_deduction || [];

    // Getting Freight Amount
    let freightAmount = await loadAgreement(data);
    if (freightAmount) {
      prevState.client_billing_unit = "fixed";
      prevState.client_freight = freightAmount;
    } else {
      // Freight Charges
      prevState.client_freight = consData?.charges?.consignment_freight || 0;
      prevState.client_billing_unit =
        consData?.charges?.consignment_billing_unit;
      prevState.client_unit_price = consData?.charges?.consignment_unit_price;
      prevState.client_total_units = consData?.charges?.consignment_total_units;
      prevState.client_total_amount =
        consData?.charges?.consignment_total_amount;
    }

    setState(prevState);

    calcFreight(prevState);

    return prevState;
  }

  async function mapInvoiceData(data) {
    // Getting Stage Number
    let invoice_stage = getInvoiceStage(data?.status);
    setInvoiceStage(invoice_stage);

    setStateCons(data?.consignment);

    var matchedInvoice = matchData(state, data);

    // Submitting Invoice
    if (matchedInvoice.status === "invoiced") {
      matchedInvoice.submitted_date = htmlDate(new Date());
      matchedInvoice.cutoff_days = matchedInvoice?.client?.cutoff_days || 7;
      matchedInvoice.due_date = calcDueDate(matchedInvoice);
    }

    // Submitted Date
    if (matchedInvoice.status === "submitted") {
      matchedInvoice.submitted_date = htmlDate(matchedInvoice.submitted_date);
      matchedInvoice.due_date = htmlDate(matchedInvoice.due_date);
    }
    setState(matchedInvoice);
    return matchedInvoice;
  }

  function changeInput(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState[name] = value;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "billable_weight") {
      calcFreight(prevState);
    } else if (name === "submitted_date") {
      calcDueDate(prevState);
    } else if (name === "total_freight_amount") {
      calcTotalBillAmt(prevState);
    } else if (name === "client_billing_unit") {
      calcFreight(prevState);
    }
  }

  function changeChargesInput(event, index, key) {
    const { name, value } = event.target;
    let prevState = { ...state };
    let array = prevState[key];
    array[index][name] = value;
    prevState[key] = array;
    setState((prevState) => ({ ...prevState, [key]: array }));

    // Calculating Amount From Rate and No. of days
    if (name === "rate_per_day" || name === "no_of_days") {
      array[index]["amount"] = calcAmountRatePerDay(prevState[key][index]);
      setState((prevState) => ({ ...prevState, [key]: array }));
    }

    // Calculating Units and Rate per Unit
    if (name === "rate_per_unit" || name === "no_of_units") {
      array[index]["amount"] = calcAmountRatePerUnit(prevState[key][index]);
      setState((prevState) => ({ ...prevState, [key]: array }));
    }

    calcTotalBillAmt(prevState);
  }

  function calcAmountRatePerDay(data) {
    let amount = data?.amount || 0;
    let no_of_days = data?.no_of_days || 0;
    let rate_per_day = data?.rate_per_day || 0;

    amount = no_of_days * rate_per_day;

    try {
      amount = amount.toFixed(2);
    } catch (e) {}

    return amount;
  }

  function calcAmountRatePerUnit(data) {
    let amount = data?.amount || 0;
    let no_of_units = data?.no_of_units || 0;
    let rate_per_unit = data?.rate_per_unit || 0;

    amount = no_of_units * rate_per_unit;

    try {
      amount = amount.toFixed(2);
    } catch (e) {}

    return amount;
  }

  function calcFreight(prevState) {
    let freight_amount = prevState?.client_freight || 0;

    if (prevState.client_billing_unit === "fixed") {
      let billable_weight = prevState?.billable_weight || 0;
      let maxVehicleCapacity = prevState?.max_vehicle_capacity || 1;

      let new_total_freight_amount = (
        (parseInt(freight_amount || 0) / maxVehicleCapacity) *
        parseInt(billable_weight || 0)
      ).toFixed(2);

      freight_amount = new_total_freight_amount;
    } else {
      freight_amount = freight_amount;
    }

    prevState.total_freight_amount = freight_amount;
    setState((prevState) => ({
      ...prevState,
      total_freight_amount: freight_amount,
    }));
    calcTotalBillAmt(prevState);
  }

  function calcDueDate(prevState) {
    let due_days = prevState.cutoff_days || 7;
    var due_date = new Date(prevState.submitted_date);
    due_date.setDate(due_date.getDate() + due_days);
    setState((prevState) => ({ ...prevState, due_date: htmlDate(due_date) }));
    return htmlDate(due_date);
  }

  function changeInputCharges(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState[name] = value;
    setState((prevState) => ({ ...prevState, [name]: value }));
    calcFreight(prevState);
  }
  function calcTotalBillAmt(data) {
    let inv_billable_total = 0;
    let freightTotal = parseFloat(data.total_freight_amount || 0);
    let clientTotal = parseFloat(data.client_freight || 0);

    // Adding Additions/ Expenses
    for (var i in data.client_addition) {
      freightTotal =
        freightTotal + parseFloat(data.client_addition[i]?.amount || 0);
      clientTotal =
        clientTotal + parseFloat(data.client_addition[i]?.amount || 0);
    }

    // Subtracting Deductions
    for (var i in data.client_deduction) {
      freightTotal =
        freightTotal - parseFloat(data.client_deduction[i]?.amount || 0);
      clientTotal =
        clientTotal - parseFloat(data.client_deduction[i]?.amount || 0);
    }

    if (data.client_billing_unit === "fixed") {
      inv_billable_total = freightTotal;
    } else {
      inv_billable_total = clientTotal;
    }

    inv_billable_total = inv_billable_total.toFixed(2);
    setState((prevState) => ({ ...prevState, total: inv_billable_total }));

    // Calculating client total - to  be updated in charges

    setState((prevState) => ({
      ...prevState,
      ...prevState.charges,
      client_total_amount: clientTotal,
    }));
  }

  function onUpdateInvoice(event) {
    event.preventDefault();
    setIsProcessing(true);
    var prevState = { ...state };

    let creator_id = localUser.userId;

    let query = {
      status: "invoiced",
      payment_status: "pending",
      address: prevState.address,
      email: prevState.email,
      total: prevState.total,
      creator_id: creator_id,
      total: prevState.total,
      consignment: prevState.consignment,
      actual_weight: prevState.actual_weight,
      billable_weight: prevState.billable_weight,
      total_freight_amount: prevState.total_freight_amount,
      max_vehicle_capacity: prevState.max_vehicle_capacity,

      invoice_number: prevState?.invoice_number || "",
      invoiced_date: Date.now(),
    };

    api
      .postService(`invoice/generate_number/${recordId}`, query)
      .then((response) => {
        setIsProcessing(false);
        resp.ResponseHandler(response, "/admin#/invoices/view");
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  function prepareDataOnGenerate() {
    const prevState = { ...state };
    let creator_id = localUser.userId;

    var additions = prevState["client_addition"];
    var deductions = prevState["client_deduction"];

    for (var i in additions) {
      delete additions[i]?._id;
      additions[i]["date"] = mongoUTC(additions[i]["date"]);
      additions[i]["type"] = additions[i]?.type?._id;
    }

    for (var i in deductions) {
      delete deductions[i]._id;
      deductions[i]["date"] = mongoUTC(deductions[i]["date"]);
      deductions[i]["type"] = deductions[i]?.type?._id;
    }

    let charges = {
      _id: stateCons?.charges?._id,
      client_billing_unit: prevState.client_billing_unit,
      client_unit_price: prevState.client_unit_price,
      client_total_units: prevState.client_total_units,
      client_freight: prevState.client_freight,
      client_addition: additions,
      client_deduction: deductions,
      client_total_amount: prevState.client_total_amount,
    };

    return {
      consignment: stateCons?._id,
      status: "invoiced",
      payment_status: "pending",
      client_name: prevState.client_name,
      address: prevState.address,
      email: prevState.email,
      creator_id: creator_id,

      actual_weight: prevState.actual_weight,
      billable_weight: prevState.billable_weight,
      total_freight_amount: prevState.total_freight_amount,
      total: prevState.total,
      max_vehicle_capacity: prevState.max_vehicle_capacity,

      invoice_number: prevState?.invoice_number || "",
      invoiced_date: mongoUTC(Date.now()),
      regenerate: prevState?.regenerate || false,
      // Charges
      client_billing_unit: prevState.client_billing_unit,
      client_unit_price: prevState.client_unit_price,
      client_total_units: prevState.client_total_units,
      client_freight: prevState.client_freight,
      client_addition: additions,
      client_deduction: deductions,
      // Consignment Charges to be updated
      charges: charges,
    };
  }

  function onGenerateInvoiceEnd() {
    setLoader((prevState) => ({ ...prevState, generate: true }));
    var preparedData = prepareDataOnGenerate();

    api
      .putService(`invoice/generate_number/${recordId}`, preparedData)
      .then((response) => {
        setLoader((prevState) => ({ ...prevState, generate: false }));
        resp.ResponseHandler(response, "/admin#/invoices/view");
      })
      .catch((error) => {
        console.log(error);
        setLoader((prevState) => ({ ...prevState, generate: false }));
        resp.ErrorHandler(error);
      });
  }

  function onGenerateInvoice(event) {
    event.preventDefault();
    // Checking Business Rule
    if (localUser?.business_rules?.invoice_approval) {
      // Checking Approval Access
      if (localUser?.access?.approve_invoice) {
        onGenerateInvoiceEnd();
      } else {
        resp.Information("Invoice generation needs approval.");
      }
    } else {
      onGenerateInvoiceEnd();
    }
  }

  function onApprove(event) {
    event.preventDefault();
    setLoader((prevState) => ({ ...prevState, approve: true }));

    var query = {
      approved_date: Date.now(),
      approver_id: localUser.userId,
      status: "approved",
      creator_id: state.creator_id?._id,
    };
    const id = state._id;
    api
      .putService(`invoice/${id}`, query)
      .then((response) => {
        setLoader((prevState) => ({ ...prevState, approve: false }));
        resp.ResponseHandler(response, "/admin#/invoices/view");
      })
      .catch((error) => {
        console.log(error);
        setLoader((prevState) => ({ ...prevState, approve: false }));
        resp.ErrorHandler(error);
      });
  }

  function onSubmit(event) {
    event.preventDefault();
    setLoader((prevState) => ({ ...prevState, submit: true }));
    var query = {
      consignment: stateCons?._id,
      submitted_date: state.submitted_date,
      due_date: state.due_date,
      submitter_id: localUser.userId,
      status: "submitted",
    };

    const id = state?._id;
    api
      .putService(`invoice/${id}`, query)
      .then((response) => {
        setLoader((prevState) => ({ ...prevState, submit: true }));
        resp.ResponseHandler(response, "/admin#/invoices/view");
      })
      .catch((error) => {
        console.log(error);
        setLoader((prevState) => ({ ...prevState, submit: true }));
        resp.ErrorHandler(error);
      });
  }

  function goBack() {
    window.location.href = "/admin#/invoices/view";
  }

  async function generateReport(event, id, user = localUser) {
    event.preventDefault();
    setIsProcessing(true);
    api.getService(`invoice/${id}`).then(
      async (result) => {
        await invoiceReport(result?.data, user);
        setIsProcessing(false);
      },
      (error) => {
        console.log("error", error);
        resp.ErrorHandler(error);
        setIsProcessing(false);
      }
    );
  }

  function changeInputClientUnitFields(event) {
    const { name, value } = event.target;
    let prevState = { ...state };
    prevState[name] = value;

    let total =
      toFloat(prevState?.client_unit_price || 0) *
      toFloat(prevState?.client_total_units || 0);
    total = total.toFixed(2);

    prevState["client_freight"] = total;
    prevState["total_freight_amount"] = total;
    setState(prevState);
    calcTotalBillAmt(prevState);
  }

  function saveForm(event) {
    event.preventDefault();
    let submit_action = event.nativeEvent.submitter.name;

    switch (submit_action) {
      case "save_invoice":
        onUpdateInvoice(event);
        break;

      case "approve_invoice":
        onApprove(event);
        break;

      case "generate_invoice":
        onGenerateInvoice(event);
        break;

      case "submit_invoice":
        onSubmit(event);
        break;

      default:
        break;
    }
  }

  return (
    <Container>
      {chargesFormModal.show && (
        <ChargesForm
          show={chargesFormModal.show}
          action={chargesFormModal.action}
          index={chargesFormModal?.index}
          charge_type={chargesFormModal?.charge_type}
          data={chargesFormModal?.data}
          consData={stateCons}
          onHide={closeChargesFormModal}
          callBack={chargesFormCallBack}
          module={"client"}
        />
      )}

      {/* <!-- BEGIN: Container Header --> */}
      <Container.Header>
        <Container.Title backButton={true}>{pageTitle}</Container.Title>
        <Container.HeaderActions>
          {invoiceStage > 2 && (
            <>
              <button
                className="btn  btn-primary"
                type="button"
                onClick={(e) => generateReport(e, state?._id)}
              >
                <i className="fa fa-download mr-2" aria-hidden="true"></i>PDF
              </button>
            </>
          )}
        </Container.HeaderActions>
      </Container.Header>
      {/* <!-- END: Container Header --> */}

      {/* <!-- BEGIN: Container Body --> */}
      <Container.Body>
        {/* <!-- BEGIN: Card --> */}
        <Card className="my-3">
          {isProcessing && <ProcessLoader />}
          {/* <!-- BEGIN: Card Header--> */}
          <Card.Header>
            <Card.Title className="text-lg font-medium text-theme-1">
              {localUser?.customer_account?.name}
            </Card.Title>
            <div className="mx-auto">
              <div className="">
                <span className="text-gray-600 mr-1">Invoice No:</span>
                <span className="font-bold">{state?.invoice_number}</span>
                {state?.status === "cancelled" && (
                  <div
                    class="bg-theme-6 p-2 rounded text-center text-white inline ml-2"
                    role="alert"
                  >
                    CANCELLED
                  </div>
                )}
              </div>
              <div className="mt-1">
                <span className="text-gray-600 mr-1">Invoiced Date:</span>
                <span className="font-bold">
                  {localDate(state?.invoiced_date)}
                </span>
              </div>
            </div>
            <Card.HeaderActions>
              <span className="text-gray-600 mr-1">Consignment No:</span>
              <span className="font-bold">{stateCons?.consignment_id}</span>
            </Card.HeaderActions>
          </Card.Header>
          {/* <!-- BEGIN: Card Header--> */}

          {/* <!-- BEGIN: Card Body --> */}
          <Card.Body>
            {/* <!-- BEGIN: Invoice Form --> */}
            <form id="invoice-form" className="" onSubmit={saveForm}>
              {/* <!-- BEGIN: Invoice --> */}
              <div className="overflow-hidden">
                {/* <!-- BEGIN: Address --> */}
                <div className="flex flex-col lg:flex-row px-5 sm:px-20 py-3 sm:pb-5 text-center sm:text-left">
                  <div className="lg:w-1/2">
                    <div className="text-base text-gray-600">From</div>

                    <div id="address">
                      <div className="text-lg font-medium text-theme-1 dark:text-theme-10 mt-2">
                        {localUser?.customer_account?.name}
                      </div>
                      <div className="mt-1 truncate">
                        {getAddressHtmlString(organization)}
                      </div>
                    </div>

                    <div id="pan" className="mt-2">
                      <div className="text-md font-medium dark:text-theme-10 mt-2">
                        PAN:
                      </div>
                      <div className="mt-1 break-all">
                        {organization?.pan_number}
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 lg:mt-0 lg:w-1/2 lg:text-left">
                    <div className="text-base text-gray-600">To</div>

                    <div id="to">
                      <div className="form-group mt-1">
                        <label htmlFor="client" className="form-label">
                          Client
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="client_name"
                          value={state?.client_name}
                          onChange={changeInput}
                          disabled={mode?.view}
                          required
                        />
                      </div>

                      <div className="form-group mt-1">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="address"
                          value={state?.address}
                          onChange={changeInput}
                          disabled={mode?.view}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- END: Address --> */}

                {/* <!-- BEGIN: Consignment Details --> */}
                <div className="overflow-x-auto px-5 pb-5">
                  {" "}
                  <table className="table">
                    {" "}
                    <thead>
                      {" "}
                      <tr>
                        {" "}
                        <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                          Consignment date
                        </th>{" "}
                        <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                          Dispatch from
                        </th>{" "}
                        <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                          Dispatch to
                        </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      <tr>
                        {" "}
                        <td className="border">
                          {localDate(stateCons?.consignment_date)}
                        </td>{" "}
                        <td className="border">{stateCons?.origin?.name}</td>{" "}
                        <td className="border">
                          {" "}
                          {stateCons?.destination?.name}
                        </td>{" "}
                      </tr>{" "}
                    </tbody>{" "}
                  </table>{" "}
                </div>
                {/* <!-- END: Consignment Details --> */}

                {/* <!-- BEGIN: Load Receipts --> */}
                <DividerText>Load Receipts</DividerText>
                <div className="px-5 pb-5">
                  {/* <!-- BEGIN: LR Details --> */}
                  <div className="overflow-x-auto mb-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            LR No.
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Consignee
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Consigner
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            P.O.No
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            e-Way Bill No
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Goods Invoice No
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stateCons.load_receipts.map((lr, lr_index) => {
                          return (
                            <tr>
                              <td className="border">{lr?.lr_number}</td>
                              <td className="border">{lr?.consignee?.name}</td>
                              <td className="border">{lr?.consigner?.name}</td>
                              <td className="border">{lr?.po_number}</td>
                              <td className="border">{lr?.eway_bill_no}</td>
                              <td className="border">{lr?.invoice_number}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* <!-- END: LR Details  --> */}

                  {/* <!-- BEGIN: Package Details --> */}
                  <div className="overflow-x-auto mb-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Total no of PKG
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Total Weight
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Arrived date
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Delivered date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border">
                            {stateCons?.total_no_Of_packages}
                          </td>
                          <td className="border">
                            {stateCons?.total_invoice_weight} Kg
                          </td>
                          <td className="border">
                            {htmlDateTime(stateCons?.loading_point?.in_time)}
                          </td>
                          <td className="border">
                            {htmlDateTime(stateCons?.unloading_point?.out_time)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <!-- END: Package Details  --> */}

                  {/* <!-- BEGIN: Vehicle Details --> */}
                  <div className="overflow-x-auto mb-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Vehicle
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Vehicle Type
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Material
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Billed Weight
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border">
                            {stateCons?.vehicle?.registration_number}
                          </td>
                          <td className="border">
                            {stateCons?.vehicle?.vehicle_type?.vehicle_type}
                          </td>
                          <td className="border">{stateCons?.material}</td>
                          <td className="border">
                            {stateCons?.total_invoice_weight} Kg
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <!-- END: Vehicle Details  --> */}

                  {/* <!-- BEGIN: Loading Point / Unloading Point Details --> */}
                  <div className="overflow-x-auto">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Loading Point
                          </th>
                          <th className="border border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Unloading Point
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border" id="loading_point_time">
                            <p>
                              In Time:{" "}
                              {htmlDateTime(stateCons?.loading_point?.in_time)}
                            </p>
                            <p>
                              Out Time:{" "}
                              {htmlDateTime(stateCons?.loading_point?.out_time)}
                            </p>
                          </td>

                          <td className="border" id="unloading_point_time">
                            <p>
                              In Time:{" "}
                              {htmlDateTime(
                                stateCons?.unloading_point?.in_time
                              )}
                            </p>
                            <p>
                              Out Time:{" "}
                              {htmlDateTime(
                                stateCons?.unloading_point?.out_time
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <!-- END: Loading Point / Unloading Point Details --> */}
                </div>
                {/* <!-- END: Load Receipts --> */}

                {/* <!-- BEGIN: Load Shortage --> */}
                <div className="mb-3" id="load-shortage">
                  <LoadShortageAlert
                    load_shortage_weight={stateCons?.load_shortage_weight}
                    load_shortage_weight_unit={
                      stateCons?.load_shortage_weight_unit
                    }
                    load_shortage_amount={stateCons?.load_shortage_amount}
                  />
                </div>
                {/* <!-- END: Load Shortage --> */}

                {/* <!-- BEGIN: Expense Button --> */}

                {invoiceStage < 3 && !mode.view && (
                  <div className="px-2 flex justify-end">
                    <button
                      type="button"
                      className="btn btn-outline-primary inline-block"
                      onClick={(e) =>
                        openChargesFormModal("invoice", "addition")
                      }
                    >
                      <i className="fa fa-inr mr-1" aria-hidden="true"></i> Add
                      Charges
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-danger inline-block ml-2"
                      onClick={(e) =>
                        openChargesFormModal("invoice", "deduction")
                      }
                    >
                      <i
                        className="fa fa-minus-circle mr-1"
                        aria-hidden="true"
                      ></i>
                      Add Deduction
                    </button>
                  </div>
                )}

                {/* <!-- END: Expense Button --> */}

                {/* <!-- BEGIN: Invoice Items --> */}
                <div className="px-5 sm:px-10 py-10 sm:py-10">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full">
                      <thead>
                        <tr>
                          <th className="border-b-2 dark:border-dark-5 whitespace-nowrap text-left">
                            DESCRIPTION
                          </th>
                          <th className="border-b-2 dark:border-dark-5 whitespace-nowrap text-left">
                            {/* No of days */}
                          </th>
                          <th className="border-b-2 dark:border-dark-5 whitespace-nowrap text-left">
                            {/* Rate/day */}
                          </th>
                          <th className="border-b-2 dark:border-dark-5 whitespace-nowrap text-right">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <!-- BEGIN: Freight Amounts --> */}
                        <>
                          <tr>
                            <td
                              className="border-b dark:border-dark-5 py-3 items-center"
                              colSpan={
                                state?.client_billing_unit !== "fixed" ? 1 : 3
                              }
                            >
                              <span className="inline-block">
                                Base Freight charge
                              </span>
                              <div
                                id="client_billing_unit_preview"
                                className="inline-block ml-1 w-40"
                              >
                                {invoiceStage < 3 && !mode.view ? (
                                  <select
                                    className="form-select inline-block"
                                    onChange={changeInput}
                                    id="client_billing_unit"
                                    name="client_billing_unit"
                                    value={state?.client_billing_unit}
                                    disabled={mode?.view}
                                    required
                                  >
                                    <option value="fixed">
                                      {fieldLabels.billing_unit["fixed"]}
                                    </option>

                                    <option value="per_ton">
                                      {fieldLabels.billing_unit["per_ton"]}
                                    </option>

                                    <option value="per_kg">
                                      {fieldLabels.billing_unit["per_kg"]}
                                    </option>

                                    <option value="per_km">
                                      {fieldLabels.billing_unit["per_km"]}
                                    </option>

                                    <option value="per_trip">
                                      {fieldLabels.billing_unit["per_trip"]}
                                    </option>

                                    <option value="per_day">
                                      {fieldLabels.billing_unit["per_day"]}
                                    </option>

                                    <option value="per_hour">
                                      {fieldLabels.billing_unit["per_hour"]}
                                    </option>

                                    <option value="per_litre">
                                      {fieldLabels.billing_unit["per_litre"]}
                                    </option>

                                    <option value="per_bag">
                                      {fieldLabels.billing_unit["per_bag"]}
                                    </option>
                                  </select>
                                ) : (
                                  <span className="inline-block px-2 bg-theme-9 text-white">
                                    {
                                      fieldLabels.billing_unit[
                                        state?.client_billing_unit
                                      ]
                                    }
                                  </span>
                                )}
                              </div>
                            </td>

                            {/* If Not Fixed */}
                            {state?.client_billing_unit !== "fixed" && (
                              <>
                                <td className="text-right border-b dark:border-dark-5 py-3 w-48 px-2">
                                  {invoiceStage < 3 && !mode.view ? (
                                    <div id="unit_price_input">
                                      <label className="">
                                        {
                                          fieldLabels?.unit_price[
                                            state?.client_billing_unit
                                          ]
                                        }
                                      </label>
                                      <CurrencyInput
                                        type="number"
                                        className="form-control"
                                        id="client_unit_price"
                                        name="client_unit_price"
                                        placeholder=""
                                        disabled={mode?.view}
                                        onChange={changeInputClientUnitFields}
                                        value={state?.client_unit_price}
                                      />
                                    </div>
                                  ) : (
                                    <div id="unit_price label">
                                      <label className="">
                                        {
                                          fieldLabels?.unit_price[
                                            state?.client_billing_unit
                                          ]
                                        }
                                      </label>
                                      <p className="font-medium text-lg">
                                        {formatCurrency(
                                          state?.client_unit_price
                                        )}
                                      </p>
                                    </div>
                                  )}
                                </td>

                                <td className="text-right border-b dark:border-dark-5 py-3 w-48 px-2">
                                  {invoiceStage < 3 && !mode.view ? (
                                    <div id="total_units_input">
                                      <label className="">
                                        {
                                          fieldLabels?.total_units[
                                            state?.client_billing_unit
                                          ]
                                        }
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="client_total_units"
                                        name="client_total_units"
                                        placeholder=""
                                        disabled={mode?.view}
                                        onChange={changeInputClientUnitFields}
                                        value={state?.client_total_units}
                                      />
                                    </div>
                                  ) : (
                                    <div id="total_unit_label">
                                      <label className="">
                                        {
                                          fieldLabels?.total_units[
                                            state?.client_billing_unit
                                          ]
                                        }
                                      </label>
                                      <p className="font-medium text-lg">
                                        {state?.client_total_units}{" "}
                                        {
                                          fieldLabels?.unit_measure[
                                            state?.client_billing_unit
                                          ]
                                        }
                                      </p>
                                    </div>
                                  )}
                                </td>
                              </>
                            )}

                            <td className="text-right border-b dark:border-dark-5 w-32 py-3">
                              {invoiceStage < 3 && !mode.view ? (
                                <CurrencyInput
                                  type="number"
                                  className="form-control"
                                  name="client_freight"
                                  onChange={changeInputCharges}
                                  value={state.client_freight}
                                  disabled={
                                    mode?.view ||
                                    state?.client_billing_unit !== "fixed"
                                  }
                                  required
                                />
                              ) : (
                                <p className="font-medium text-lg">
                                  {formatCurrency(state?.client_freight)}
                                </p>
                              )}
                            </td>
                          </tr>

                          {/* <!-- BEGIN : Fixed Condition Fields --> */}
                          {state?.client_billing_unit === "fixed" && (
                            <>
                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-b dark:border-dark-5 py-3"
                                >
                                  Actual weight
                                </td>
                                <td className="text-right border-b dark:border-dark-5 w-32 font-medium py-3">
                                  <p className="font-medium text-lg">
                                    {state?.actual_weight} Kg
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-b dark:border-dark-5 py-3"
                                >
                                  Billable weight
                                </td>
                                <td className="text-right border-b dark:border-dark-5 w-32 font-medium py-3">
                                  {" "}
                                  {invoiceStage < 3 && !mode.view ? (
                                    <WeightInput
                                      type="number"
                                      className="form-control"
                                      name="billable_weight"
                                      value={state?.billable_weight}
                                      onChange={changeInput}
                                      required
                                      disabled={mode?.view}
                                    />
                                  ) : (
                                    <div className="break-all">
                                      <p className="font-medium text-lg">
                                        {state.billable_weight} Kg
                                      </p>
                                    </div>
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan="3"
                                  className="border-b dark:border-dark-5 py-3"
                                >
                                  Actual Freight Amount
                                </td>
                                <td className="text-right border-b dark:border-dark-5 w-32 font-medium py-3">
                                  {invoiceStage < 3 && !mode.view ? (
                                    <CurrencyInput
                                      type="number"
                                      className="form-control"
                                      name="total_freight_amount"
                                      onChange={changeInput}
                                      value={state?.total_freight_amount}
                                      disabled={mode?.view}
                                      required
                                    />
                                  ) : (
                                    <>
                                      {formatCurrency(
                                        state?.total_freight_amount
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                          {/* <!-- END : Fixed Condition Fields --> */}
                        </>
                        {/* <!-- END: Freight Amounts --> */}

                        {/* <!-- BEGIN: Additions --> */}
                        {state?.client_addition?.length > 0 && (
                          <>
                            <tr className="text-center">
                              <td
                                colSpan="4"
                                className="py-2 font-medium text-center"
                              >
                                <div className="flex items-center">
                                  <h3 className="text-lg">CHARGES</h3>
                                  <InfoButton
                                    className="ml-2"
                                    id="additions_info"
                                  >
                                    These Charges will be added to the Bill
                                  </InfoButton>
                                </div>
                              </td>
                            </tr>

                            {state?.client_addition?.map((item, index) => (
                              <tr>
                                <td className="border-b dark:border-dark-5 py-3">
                                  {item?.type?.name}
                                  {invoiceStage < 3 && !mode.view && (
                                    <div className="inline" id="action_buttons">
                                      <>
                                        <button
                                          type="button"
                                          className="btn  btn-outline-primary inline-block mx-1"
                                          onClick={(e) =>
                                            editChargesItem(
                                              e,
                                              item,
                                              index,
                                              "addition",
                                              "client"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </button>

                                        <button
                                          type="button"
                                          className="btn  btn-outline-danger inline-block mx-1"
                                          onClick={(e) =>
                                            deleteChargesItem(
                                              e,
                                              item,
                                              index,
                                              "client_addition",
                                              "client"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </>
                                    </div>
                                  )}
                                </td>

                                {/* <!-- No of Days / Rate per Day Field */}

                                {item?.type?.require_no_of_days_field &&
                                item?.type?.require_rate_per_day_field ? (
                                  <>
                                    <td className="text-right border-b dark:border-dark-5 py-3 w-48 px-2">
                                      <label className="">No. Of Days</label>
                                      {invoiceStage < 3 && !mode.view ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="no_of_days"
                                          value={item.no_of_days}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_addition"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      ) : (
                                        <p className="font-medium text-lg">
                                          {item.no_of_days}
                                        </p>
                                      )}
                                    </td>

                                    <td className="text-right border-b dark:border-dark-5 py-3 w-48 px-2">
                                      <label className="">Rate per Day</label>
                                      {invoiceStage < 3 && !mode.view ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="rate_per_day"
                                          value={item.rate_per_day}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_addition"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      ) : (
                                        <p className="font-medium text-lg">
                                          {formatCurrency(item.rate_per_day)}
                                        </p>
                                      )}
                                    </td>
                                  </>
                                ) : item?.type?.no_of_units_field &&
                                  item?.type?.rate_per_unit_field ? (
                                  <>
                                    <td className="text-right border-b dark:border-dark-5 py-3 px-2 w-48">
                                      <label className="">
                                        {item?.type?.no_of_units_field_label}
                                      </label>
                                      {invoiceStage < 3 && !mode?.view ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="no_of_units"
                                          value={item?.no_of_units}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_addition"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      ) : (
                                        <p className="font-medium text-lg">
                                          {item.no_of_units}
                                        </p>
                                      )}
                                    </td>

                                    <td className="text-right border-b dark:border-dark-5 py-3 px-2 w-48">
                                      <label className="">
                                        {item?.type?.rate_per_unit_field_label}
                                      </label>
                                      {invoiceStage < 3 && !mode?.view ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="rate_per_unit"
                                          value={item.rate_per_unit}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_addition"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      ) : (
                                        <p className="font-medium text-lg">
                                          {formatCurrency(item.rate_per_unit)}
                                        </p>
                                      )}
                                    </td>
                                  </>
                                ) : (
                                  <td
                                    className="text-right border-b dark:border-dark-5 py-3 w-48 px-2"
                                    colSpan="2"
                                  >
                                    {invoiceStage < 3 && !mode.view ? (
                                      <>
                                        <label className="">Remarks</label>
                                        <textarea
                                          type="number"
                                          className="form-control"
                                          name="remarks"
                                          value={item.remarks}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_addition"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      </>
                                    ) : (
                                      <div className="break-all">
                                        {item.remarks}
                                      </div>
                                    )}
                                  </td>
                                )}

                                <td className="text-right border-b dark:border-dark-5 py-3">
                                  {invoiceStage < 3 && !mode.view ? (
                                    <CurrencyInput
                                      type="number"
                                      className="form-control"
                                      name="amount"
                                      value={item.amount}
                                      onChange={(event) => {
                                        changeChargesInput(
                                          event,
                                          index,
                                          "client_addition"
                                        );
                                      }}
                                      disabled={mode?.view}
                                    />
                                  ) : (
                                    <p className="font-medium text-lg">
                                      + {formatCurrency(item?.amount)}
                                    </p>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                        {/* <!-- END: Additions --> */}

                        {/* <!-- BEGIN: Deductions --> */}
                        {state?.client_deduction?.length > 0 && (
                          <>
                            <tr className="text-center">
                              <td
                                colSpan="4"
                                className="py-2 font-medium text-center"
                              >
                                <div className="flex items-center">
                                  <h3 className="text-lg">DEDUCTIONS</h3>
                                  <InfoButton
                                    className="ml-2"
                                    id="deductions_info"
                                  >
                                    These Charges will be reduced from the Bill
                                  </InfoButton>
                                </div>
                              </td>
                            </tr>

                            {state?.client_deduction?.map((item, index) => (
                              <tr>
                                <td className="border-b dark:border-dark-5 py-3">
                                  {item?.type?.name}
                                  {invoiceStage < 3 && !mode?.view && (
                                    <div className="inline" id="action_buttons">
                                      <>
                                        <button
                                          type="button"
                                          className="btn  btn-outline-primary inline-block mx-1"
                                          onClick={(e) =>
                                            editChargesItem(
                                              e,
                                              item,
                                              index,
                                              "deduction",
                                              "client"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </button>

                                        <button
                                          type="button"
                                          className="btn  btn-outline-danger inline-block mx-1"
                                          onClick={(e) =>
                                            deleteChargesItem(
                                              e,
                                              item,
                                              index,
                                              "client_deduction",
                                              "client"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </>
                                    </div>
                                  )}
                                </td>

                                {/* <!-- No of Days / Rate per Day Field */}

                                {item?.type?.require_no_of_days_field &&
                                item?.type?.require_rate_per_day_field ? (
                                  <>
                                    <td className="text-right border-b dark:border-dark-5 py-3 px-2 w-48">
                                      <label className="">No. Of Days</label>
                                      {invoiceStage < 3 && !mode?.view ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="no_of_days"
                                          value={item.no_of_days}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_deduction"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      ) : (
                                        <p className="font-medium text-lg">
                                          {item.no_of_days}
                                        </p>
                                      )}
                                    </td>

                                    <td className="text-right border-b dark:border-dark-5 py-3 px-2 w-48">
                                      <label className="">Rate per Day</label>
                                      {invoiceStage < 3 && !mode?.view ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="rate_per_day"
                                          value={item.rate_per_day}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_deduction"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      ) : (
                                        <p className="font-medium text-lg">
                                          {formatCurrency(item.rate_per_day)}
                                        </p>
                                      )}
                                    </td>
                                  </>
                                ) : item?.type?.no_of_units_field &&
                                  item?.type?.rate_per_unit_field ? (
                                  <>
                                    <td className="text-right border-b dark:border-dark-5 py-3 px-2 w-48">
                                      <label className="">
                                        {item?.type?.no_of_units_field_label}
                                      </label>
                                      {invoiceStage < 3 && !mode?.view ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="no_of_units"
                                          value={item?.no_of_units}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_deduction"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      ) : (
                                        <p className="font-medium text-lg">
                                          {item.no_of_units}
                                        </p>
                                      )}
                                    </td>

                                    <td className="text-right border-b dark:border-dark-5 py-3 px-2 w-48">
                                      <label className="">
                                        {item?.type?.rate_per_unit_field_label}
                                      </label>
                                      {invoiceStage < 3 && !mode?.view ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="rate_per_unit"
                                          value={item.rate_per_unit}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_deduction"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      ) : (
                                        <p className="font-medium text-lg">
                                          {formatCurrency(item.rate_per_unit)}
                                        </p>
                                      )}
                                    </td>
                                  </>
                                ) : (
                                  <td
                                    className="text-right border-b dark:border-dark-5 w-32 py-3"
                                    colSpan="2"
                                  >
                                    {invoiceStage < 3 && !mode?.view ? (
                                      <>
                                        <label className="">Remarks</label>
                                        <textarea
                                          type="number"
                                          className="form-control"
                                          name="remarks"
                                          value={item.remarks}
                                          onChange={(event) => {
                                            changeChargesInput(
                                              event,
                                              index,
                                              "client_deduction"
                                            );
                                          }}
                                          disabled={mode?.view}
                                        />
                                      </>
                                    ) : (
                                      <div className="break-all">
                                        {item.remarks}
                                      </div>
                                    )}
                                  </td>
                                )}

                                <td className="text-right border-b dark:border-dark-5 py-3 font-medium">
                                  {invoiceStage < 3 && !mode?.view ? (
                                    <CurrencyInput
                                      type="number"
                                      className="form-control"
                                      name="amount"
                                      value={item.amount}
                                      onChange={(event) => {
                                        changeChargesInput(
                                          event,
                                          index,
                                          "client_deduction"
                                        );
                                      }}
                                      disabled={mode?.view}
                                    />
                                  ) : (
                                    <span className="font-medium text-lg">
                                      - {formatCurrency(item?.amount)}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                        {/* <!-- END: Deductions --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <!-- END: Invoice Items --> */}

                {/* <!-- Begin: Total Billable Amount --> */}
                <div className="px-5 sm:px-20 pb-3 sm:pb-5 flex flex-col-reverse sm:flex-row">
                  <div className="text-center sm:text-right sm:ml-auto">
                    <div className="text-base text-gray-600">
                      Total Billable Amount
                    </div>
                    <div className="text-xl text-theme-1 dark:text-theme-10 font-medium mt-2">
                      {formatCurrency(state.total)}
                    </div>
                  </div>
                </div>

                {/* <!-- END: Total Billable Amount --> */}

                {/* <!-- BEGIN: Submitted Date --> */}
                {(userAction === "submit" ||
                  state.status === "submitted" ||
                  mode?.submit) && (
                  <div className="px-5 sm:px-20 pb-3 sm:pb-5 flex flex-col-reverse sm:flex-row">
                    <div className="sm:ml-auto">
                      <div className="grid grid-cols-12 gap-2">
                        <div className="form-control col-span-6">
                          <label
                            htmlFor="submitted_date"
                            className="form-label"
                          >
                            Submitted Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="submitted_date"
                            onChange={changeInput}
                            value={state.submitted_date}
                            disabled={mode.view}
                            required
                          />
                        </div>
                        <div className="form-control col-span-6">
                          <label htmlFor="due_date" className="form-label">
                            Due date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="due_date"
                            onChange={changeInput}
                            value={state.due_date}
                            disabled={mode.view}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <!-- END: Submitted Date --> */}
              </div>
              {/* <!-- END: Invoice --> */}

              <input
                type="submit"
                id="save_invoice"
                name="save_invoice"
                className="hidden"
              />
              <input
                type="submit"
                id="approve_invoice"
                name="approve_invoice"
                className="hidden"
              />
              <input
                type="submit"
                id="generate_invoice"
                name="generate_invoice"
                className="hidden"
              />
              <input
                type="submit"
                id="submit_invoice"
                name="submit_invoice"
                className="hidden"
              />
            </form>
            {/* <!-- END: Invoice Form --> */}
          </Card.Body>
          {/* <!-- END: Card Body --> */}

          {/* <!-- BEGIN: Card Footer --> */}
          <Card.Footer className="justify-end">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-outline-secondary w-32 mr-1"
              onClick={goBack}
            >
              <i className="fa fa-chevron-left mr-1" aria-hidden="true"></i>{" "}
              Back
            </button>

            {userAction === "create" && (
              <label
                type="button"
                className="btn btn-primary w-20"
                form="invoice-form"
                htmlFor="save_invoice"
              >
                Save {loader?.save ? <InLineLoader /> : null}
              </label>
            )}

            {(userAction === "generate" || mode?.generate) && (
              <label
                type="button"
                className="btn btn-success w-32"
                form="invoice-form"
                htmlFor="generate_invoice"
              >
                Generate {loader?.generate ? <InLineLoader /> : null}
              </label>
            )}

            {(userAction === "approve" || mode?.approve) && (
              <label
                type="button"
                className="btn btn-success w-32"
                form="invoice-form"
                htmlFor="approve_invoice"
              >
                Approve {loader?.approve ? <InLineLoader /> : null}
              </label>
            )}

            {(userAction === "submit" || mode?.submit) && (
              <label
                type="button"
                className="btn btn-success w-32"
                form="invoice-form"
                htmlFor="submit_invoice"
              >
                Submit {loader?.submit ? <InLineLoader /> : null}
              </label>
            )}
          </Card.Footer>
          {/* <!-- END: Card Footer --> */}
        </Card>
        {/* <!-- END: Card --> */}
      </Container.Body>
      {/* <!-- END: Container Body --> */}
    </Container>
  );
}
