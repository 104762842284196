import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { matchData } from "../../functions/common";
import ProcessLoader from "../preloader/processLoader";
import * as api from "../../api_service/api";
import { resp } from "../../functions/responseHandler";

const Model = {
  date: "",
  client: "",
  direct_memo: [],
  indirect_memo: [],
  enablePaymentMode: false,

  // Payment
  payment_mode: "",
  payment_cash: {},
  payment_bank: {},

  // Default values
  transaction_type: "receivable",
  transaction_context: "",
  payment_from_type: "client",
  payment_to_type: "self",
};

export default function MemoPreview(props) {
  const { title, onHide, callBack, data } = props;
  // State
  const [isProcessing, setIsProcessing] = useState(false);
  const [state, setState] = useState({ ...Model });

  const [defaultState, setDefaultState] = useState({
    bank: [],
    cash: [],
  });

  useEffect(() => {
    processData();
  }, []);

  async function processData() {
    setIsProcessing(true);
    let recData = await matchData(state, data);
    // Checking for credit value
    let creditAvailable = await checkCredit(recData);
    if (creditAvailable) {
      recData["enablePaymentMode"] = true;
    }
    setState(recData);
    setIsProcessing(false);
  }

  function checkCredit(data) {
    let found = false;
    for (var i in data?.direct_memo) {
      if (data?.direct_memo[i].credit > 0) {
        found = true;
        break;
      }
    }
    if (!found) {
      for (var j in data?.indirect_memo) {
        if (data?.indirect_memo[j].credit > 0) {
          found = true;
          break;
        }
      }
    }
    return found;
  }
  async function prepareData(data) {
    let directMemoResponse = [];
    let indirectMemoResponse = [];
    let direct_memo = data.direct_memo;
    let indirect_memo = data.indirect_memo;
    // Building Direct Memo Response
    for (var i in direct_memo) {
      if (direct_memo[i].credit > 0 || direct_memo[i].debit > 0) {
        let response = {
          date: data.date,
          client: data.client?._id,
          invoice: direct_memo[i]._id,
          transaction_type: "receivable",
          transaction_context: "direct_memo",
          payment_from_type: "client",
          payment_to_type: "self",
          transaction_amount: 0,
        };
        // Debit
        if (direct_memo[i].debit > 0) {
          response["debit"] = direct_memo[i].debit;
          response["debit_ref_no"] = direct_memo[i].debit_ref_no;
          response["debit_note"] = direct_memo[i].debit_note;
        }
        // Credit
        if (direct_memo[i].credit > 0) {
          response["credit"] = direct_memo[i].credit;
          response["credit_ref_no"] = direct_memo[i].credit_ref_no;
          response["credit_note"] = direct_memo[i].credit_note;
          // Payment
          response["payment_mode"] = data.payment_mode;
          if (data.payment_mode === "cash") {
            response["payment_cash"] = data?.payment_cash?._id;
          }
          if (data.payment_mode === "bank") {
            response["payment_bank"] = data?.payment_bank?._id;
          }
        }
        directMemoResponse.push(response);
      }
    }

    // Building InDirect Memo Response
    for (var i in indirect_memo) {
      if (indirect_memo[i].credit > 0 || indirect_memo[i].debit > 0) {
        let response = {
          date: data.date,
          client: data.client?._id,
          payment_reference_number: indirect_memo[i].payment_reference_number,
          transaction_type: "receivable",
          transaction_context: "indirect_memo",
          payment_from_type: "client",
          payment_to_type: "self",
          transaction_amount: 0,
        };
        // Debit
        if (indirect_memo[i].debit > 0) {
          response["debit"] = indirect_memo[i].debit;
          response["debit_ref_no"] = indirect_memo[i].debit_ref_no;
          response["debit_note"] = indirect_memo[i].debit_note;
        }
        // Credit
        if (indirect_memo[i].credit > 0) {
          response["credit"] = indirect_memo[i].credit;
          response["credit_ref_no"] = indirect_memo[i].credit_ref_no;
          response["credit_note"] = indirect_memo[i].credit_note;
          // Payment
          response["payment_mode"] = data.payment_mode;
          if (data.payment_mode === "cash") {
            response["payment_cash"] = data?.payment_cash?._id;
          }
          if (data.payment_mode === "bank") {
            response["payment_bank"] = data?.payment_bank?._id;
          }
        }
        indirectMemoResponse.push(response);
      }
    }

    return {
      direct_memo: directMemoResponse,
      indirect_memo: indirectMemoResponse,
    };
  }
  // Form Actions
  async function onSubmit(event) {
    event.preventDefault();
    let data = await prepareData({ ...state });

    setIsProcessing(true);
    api
      .postService("accounts/transactions/receivable/credit_debit_memo", data)
      .then((response) => {
        setIsProcessing(false);
        resp.Success(response?.data?.message);
        callBack(true);
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        resp.ErrorHandler(error);
      });
  }

  // Input Changes

  function changePaymentMethod(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (value === "bank" || value === "cash") {
      loadDefaultMetrics(value);
    }
  }

  async function loadDefaultMetrics(metric) {
    if (defaultState[metric] && defaultState[metric].length < 1) {
      setIsProcessing(true);
      var query = { metrix: [metric] };
      await api
        .postService("common", query)
        .then((response) => {
          const data = response.data;
          if (metric === "cash") {
            // Setting default value if one record is found
            if (data[metric].length === 1) {
              setState((prevState) => ({
                ...prevState,
                payment_cash: data[metric][0],
              }));
            }
          }

          if (metric === "bank") {
            // Setting default value if one record is found
            if (data[metric].length === 1) {
              setState((prevState) => ({
                ...prevState,
                payment_bank: data[metric][0],
              }));
            }
          }

          setDefaultState((prevState) => ({
            ...prevState,
            [metric]: response?.data[metric],
          }));
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
        });
    }
  }

  function changeInputPaymentMaster(event) {
    const { name, value } = event.target;
    let parsedValue = JSON.parse(value);
    setState((prevState) => ({ ...prevState, [name]: parsedValue }));
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {isProcessing && <ProcessLoader />}
      <Modal
        {...props}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={onHide}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="content h-100 container-fluid">
            <form onSubmit={onSubmit} id="memo-preview-form" className="h-100">
              {/* Row -1 */}
              <div className="form-row mb-3">
                <div className="form-group col-md-6">
                  <label className="control-label">Date</label>
                  <input
                    type="text"
                    className="form-control"
                    id="date"
                    name="date"
                    placeholder=""
                    disabled={true}
                    value={state.date}
                    readOnly={true}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">Client</label>
                  <input
                    type="text"
                    className="form-control"
                    id="client"
                    name="client"
                    placeholder=""
                    disabled={true}
                    value={state.client?.name}
                    readOnly={true}
                  />
                </div>
              </div>
              {/* Row -1 */}
              {/* Row -2 */}
              {state?.enablePaymentMode && (
                <div className="form-row mb-3">
                  <>
                    {/* Payment Mode */}
                    <div className="col-md-6">
                      <label htmlFor="payment_mode">Credit payment mode</label>
                      <select
                        id="payment_mode"
                        name="payment_mode"
                        className="form-control"
                        onChange={changePaymentMethod}
                        value={state.payment_mode}
                        required
                      >
                        <option value="" defaultValue="" disabled>
                          Choose...
                        </option>
                        <option value="cash">Cash</option>
                        <option value="bank">Bank</option>
                      </select>
                    </div>

                    {/* CASH MASTER */}
                    {state.payment_mode === "cash" && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="payment_cash">Cash</label>
                          <select
                            id="payment_cash"
                            name="payment_cash"
                            className="form-control"
                            onChange={changeInputPaymentMaster}
                            value={JSON.stringify(state.payment_cash)}
                            required
                          >
                            <option value={"{}"} defaultValue={true} disabled>
                              Choose Cash...
                            </option>
                            {defaultState.cash.map((item, index) => {
                              return (
                                <option
                                  value={JSON.stringify(item)}
                                  key={index}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </>
                    )}
                    {/* CASH MASTER */}

                    {/* BANK MASTER */}
                    {state.payment_mode === "bank" && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="payment_bank">Bank</label>
                          <select
                            id="payment_bank"
                            name="payment_bank"
                            className="form-control"
                            onChange={changeInputPaymentMaster}
                            value={JSON.stringify(state.payment_bank)}
                            required
                          >
                            <option value="{}" defaultValue disabled>
                              Choose Bank...
                            </option>
                            {defaultState.bank.map((item, index) => {
                              return (
                                <option
                                  value={JSON.stringify(item)}
                                  key={index}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </>
                    )}
                    {/* BANK MASTER */}
                  </>
                </div>
              )}
              {/* Row -2 */}

              {/* ROW 3 - Direct Memo */}
              {state?.direct_memo.length > 0 && (
                <div className="form-row mb-3">
                  <>
                    <p>
                      <b>Invoices Entry</b>
                    </p>
                    <hr />
                    <div className="table p-15">
                      <div className="table-responsive" id="direct_memo_table">
                        {/* INVOICE LIST - TABLE */}
                        <table className="table">
                          <thead className="bg-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Invoice No.</th>
                              <th scope="col">Debit</th>
                              <th scope="col">Debit Ref No</th>
                              <th scope="col">Credit</th>
                              <th scope="col">Credit Ref No</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state?.direct_memo?.map((row, index) => {
                              return (
                                <tr className="" key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{row?.invoice_number}</td>
                                  <td>{row?.debit}</td>
                                  <td>{row?.debit_ref_no}</td>
                                  <td>{row?.credit}</td>
                                  <td>{row?.credit_ref_no}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* INVOICE LIST - TABLE */}
                      </div>
                    </div>
                  </>
                </div>
              )}
              {/* ROW 3 - Direct Memo */}

              {/* ROW 4 - In Direct Memo */}
              {state?.indirect_memo.length > 0 && (
                <div className="form-row mb-3">
                  <>
                    <p>
                      <b>Misc Entry</b>
                    </p>
                    <hr />
                    <div className="table p-15">
                      <div
                        className="table-responsive"
                        id="indirect_memo_list_table"
                      >
                        <table className="table">
                          <thead className="bg-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Ref No.</th>
                              <th scope="col">Debit</th>
                              <th scope="col">Debit Ref No</th>
                              <th scope="col">Credit</th>
                              <th scope="col">Credit Ref No</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state?.indirect_memo?.map((row, index) => {
                              return (
                                <tr className="" key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{row?.payment_reference_number}</td>
                                  <td>{row?.debit}</td>
                                  <td>{row?.debit_ref_no}</td>
                                  <td>{row?.credit}</td>
                                  <td>{row?.credit_ref_no}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                </div>
              )}
              {/* ROW 4 - In Direct Memo */}

              <input type="submit" id="save_memo" className="hidden" />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>

          <label
            type="button"
            className="btn btn-success float-right mr-2 "
            htmlFor="save_memo"
          >
            Confirm {"&"} Save
          </label>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

MemoPreview.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  callBack: PropTypes.func,
  onHide: PropTypes.func,
};

MemoPreview.defaultProps = {
  title: "Memo Preview",
  data: { ...Model },
  onHide() {},
  callBack() {},
};
