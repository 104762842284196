import React from "react";
import PropTypes from "prop-types";
export default function Component(props) {
  const { className, weight, max_weight, ...rest } = props;

  let showBadge = false;
  let weight_difference = 0;
  if (weight > max_weight) {
    showBadge = true;
    weight_difference = weight - max_weight;
  }

  return (
    showBadge && (
      <div className={className} id="load_exceed_alert">
        <div
          class="alert alert-warning-soft show flex items-center"
          role="alert"
        >
          <i class="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>
          Weight Exceeded Vehicle Max. Vehicle Capacity - {max_weight} Kg.
          Excess Weight - {weight_difference} Kg.
        </div>
      </div>
    )
  );
}

Component.propTypes = {
  className: PropTypes.string,
  weight: PropTypes.number,
  max_weight: PropTypes.number,
};

Component.defaultProps = {
  className: "",
  weight: 0,
  max_weight: 0,
};
