import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Component(props) {
  const {
    show,
    title,
    pdfFile,
    onDownloadClick,
    onPrintClick,
    onHide,
    id,
    size,
  } = props;

  return (
    <div onClick={(e) => e.stopPropagation()} id={id}>
      <Modal
        {...props}
        show={show}
        scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={onHide}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <h2 className="font-medium text-base mr-auto">{title}</h2>
        </Modal.Header>
        <Modal.Body className="scroll overflow-x-auto overflow-y-auto">
          <Document file={pdfFile} className="flex justify-center text-center">
            <Page pageNumber={1} />
          </Document>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-secondary mr-2 "
            htmlFor="print_pdf"
            onClick={(e) => {
              onPrintClick();
            }}
          >
            <i class="fa fa-print mr-2" aria-hidden="true"></i> Print
          </button>

          <button
            type="button"
            className="btn btn-outline-secondary mr-2 "
            htmlFor="download_pdf"
            onClick={(e) => {
              onDownloadClick();
            }}
          >
            <i class="fa fa-download mr-2" aria-hidden="true"></i> Download
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Component.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  pdfFile: PropTypes.any,
  onDownloadClick: PropTypes.func,
  onPrintClick: PropTypes.func,
  onHide: PropTypes.func,
  id: PropTypes.string,
  size: PropTypes.string,
};

Component.defaultProps = {
  show: false,
  title: "View PDF",
  pdfFile: "",
  id: "",
  size: "",
  onDownloadClick: () => {},
  onPrintClick: () => {},
  onHide: () => {},
};
