import React from "react";
import PropTypes from "prop-types";
export default function BillingTypeButton(props) {
  const {
    className,
    type,
    id,
    name,
    onClick,
    icon,
    text,
    size,
    selected,
    value,
    activeClassName,
    ...rest
  } = props;

  return (
    <a
      href={void 0}
      id={id}
      name={name}
      value={value}
      className={
        selected
          ? `${activeClassName + " " + size}`
          : `${className + " " + size}`
      }
      onClick={onClick}
    >
      {/* <i className={icon + " m-1"} aria-hidden="true"></i> */}
      {props.children}
    </a>
  );
}

BillingTypeButton.propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

BillingTypeButton.defaultProps = {
  className: "btn btn-outline-secondary mr-2 mb-2",
  activeClassName: "btn btn-success mr-2 mb-2",
  size: "",
  type: "",
  id: "",
  name: "",
  value: "",
  icon: "fa fa-plus",
  text: "",
  selected: false,
  onClick() {},
};
